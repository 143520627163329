import { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import { shallowEqual } from 'react-redux'
import { useAppSelector, useAppDispatch } from 'src/hooks'
import { useDebouncedCallback } from 'use-debounce'
import {
  useForm, useController, useWatch, useFormContext, FormProvider,
} from 'react-hook-form'

import Modal from 'src/editor/modal'

import { addNotification, NotificationType } from 'src/editor/notification'
import { quantizeColor2 } from 'src/data/image/resample'
// import { ViewMode } from 'src/data/time-needle/view-mode'
// // import sidebarcss from 'src/styles/toolbox.module.scss'
// import { deferActions, setActionHandler } from 'src/editor/time-needle/slice'
// import { centerHome, Select } from 'src/editor/time-needle/action'

import 'src/styles/image-upload.scss'

enum ResizeType {
  EXACTW = '0',
  EXACTH = '1',
  NORESAMPLE = '2',
  FIT = '3'
}

const Slider = function ({
  control, name, min, max, step,
}) {
  const {
    field: {
      value, onChange, onBlur, ref,
    },
  } = useController({
    name,
    control,
  })

  return (
    <div className="range-group">
      <label>{name} {value}</label>
      <input
        value={value}
        onBlur={onBlur}
        onChange={onChange} // send value to hook form
        name={name} // send down the input name
        ref={ref} // send input ref, so we can focus on input when error appear
        type="range"
        min={min}
        max={max}
        step={step}
      />
    </div>
  )
}

const processImage = ({
  buffer, colors, grayscale, brightness, contrast, canvasWidth, canvasHeight, aspect, dimensions, rowsPer10CM, stitchesPer10CM,
}, callback) => {
  if(buffer) {
    const size = [canvasWidth, canvasHeight]
    if(dimensions != 0) {
      if([ResizeType.EXACTW, ResizeType.EXACTH].includes(aspect)) {
        size[+aspect] = +dimensions
      }
    }
    quantizeColor2(
      buffer,
      +colors,
      !!grayscale,
      brightness / 100,
      contrast / 100,
      size as [number, number],
      aspect == ResizeType.FIT || dimensions != 0,
      null,
      aspect == ResizeType.NORESAMPLE ? 1.0 : rowsPer10CM / stitchesPer10CM,
      [ResizeType.EXACTW, ResizeType.EXACTH, ResizeType.FIT].includes(aspect),
      callback,
    )
  }
}

const ImagePreview = function ({ control, img, colorTable }) {
  const {
    canvasWidth, canvasHeight, rowsPer10CM, stitchesPer10CM,
  } = useAppSelector(({
    canvas: { width, height },
    settings: {
      columnsPerCm, rowsPerCm,
    },
  }) => ({
    canvasWidth: width,
    canvasHeight: height,
    rowsPer10CM: rowsPerCm * 10.0,
    stitchesPer10CM: columnsPerCm * 10.0,
  }), shallowEqual)

  const { setValue } = useFormContext()
  const [buffer, setBuffer] = useState<Buffer>()

  const image = useWatch({ control, name: 'image'})
  const brightness = useWatch({ control, name: 'brightness'})
  const contrast = useWatch({ control, name: 'contrast'})
  const colors = useWatch({ control, name: 'colors'})
  const grayscale = useWatch({ control, name: 'grayscale'})
  const aspect = useWatch({ control, name: 'aspect'})
  const dimensions = useWatch({ control, name: 'dimensions'})

  const [preViewValue, setImagePreviewValue] = useState<string>('')
  const debouncedProcessImage = useDebouncedCallback(processImage, 100)

  useEffect(() => {
    if(image) {
      const reader = new FileReader()
      const load = () => {
        const result = reader.result as string
        const buffer = Buffer.from(result.split('base64,').pop(), 'base64')
        setBuffer(buffer)
      }
      reader.addEventListener('load', load)
      const file = image[0]
      if(file) {
        reader.readAsDataURL(file)
      }
      return () => {
        reader.removeEventListener('load', load)
      }
    }
  }, [image])

  useEffect(() => {
    if(buffer) {
      debouncedProcessImage(
        {
          buffer, colors, grayscale, brightness, contrast, canvasWidth, canvasHeight, aspect, dimensions, rowsPer10CM, stitchesPer10CM,
        },
        (base64, info, resimg, newColorTable, colorCount) => {
          img.current = resimg
          colorTable.current = newColorTable
          setValue('colors', `${colorCount >= 2 ? colorCount : 2}`)
          setImagePreviewValue(base64)
        },
      )
    }
  }, [buffer, colors, grayscale, brightness, contrast, canvasWidth, canvasHeight, aspect, dimensions])

  return preViewValue ? <div className="image-preview"><img src={preViewValue} alt="preview" /></div> : <div className="image-preview">please select an image</div>
}

const FileInput = function ({ control, name }) {
  const {
    field: {
      onChange, onBlur, ref,
    },
  } = useController({
    name,
    control,
  })

  return (
    <input
      type="file"
      accept="image/png, image/gif, image/jpeg, image/bmp, image/tiff"
      name={name}
      ref={ref}
      onBlur={onBlur}
      onChange={({target: {files}}) => onChange(files)}
    />
  )
}

const ImageUpload = function () {
  const {
    tnimage, rowsPer10CM, stitchesPer10CM,
  } = useAppSelector(({
    canvas: { tnimage },
    settings: { columnsPerCm, rowsPerCm },
  }) => ({
    tnimage,
    rowsPer10CM: rowsPerCm * 10.0,
    stitchesPer10CM: columnsPerCm * 10.0,
  }), shallowEqual)

  const dispatch = useAppDispatch()
  const [userDialog, setUserDialog] = useState(false)

  const defaultValues = {
    image: '',
    colors: 6,
    contrast: 0,
    brightness: 0,
    aspect: ResizeType.EXACTW,
    grayscale: false,
    dimensions: 0,
  }

  const formMethods = useForm({ defaultValues })
  const {
    register, handleSubmit, reset, control,
  } = formMethods
  const colorTable = useRef(null)
  const img = useRef(null)

  useEffect(() => {
    if(userDialog == false) {
      reset(defaultValues)
    }
  }, [userDialog])

  const onSubmit = ({ aspect }) => {
    // XXX actual undoable snapshot action
    // actionStack.addAction(makeSnapshotOp(tnimage))

    if([ResizeType.EXACTH, ResizeType.EXACTW, ResizeType.NORESAMPLE].includes(aspect)) {
      if(img.current.width === 252) {
        dispatch(
          addNotification(
            'Your image was resized to 252 stitches wide',
            NotificationType.INFO,
            5000,
          ),
        )
      }
    }
    setUserDialog(false)

    // re-implement this when the Jacquard macro is available
    /*
    const [tool, pastePreview] = Select.withPaste(
      canvasImageToLayered(img.current, ViewMode.PATTERN),
    )
    dispatch(setActionHandler(tool))
    dispatch(deferActions([
      centerHome,
      pastePreview,
    ]))
    */
    throw 'The image upload mechanism is not re-implemented yet'
  }

  return (
    <>
      <div
        className={classNames('tool', 'image')}
        onClick={() => setUserDialog(!userDialog)}
        data-tooltip="Import Image"
      />
      {userDialog && (
        <Modal>
          <div
            className={classNames('overlay', 'image-upload')}
            tabIndex={-1}
            ref={(el) => setTimeout(() => el?.focus())}
            onKeyDown={({ key }) => key == 'Escape' && setUserDialog(false)}
            onClick={({ target, currentTarget }) => {
              target === currentTarget && setUserDialog(false)
            }}
          >
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)} className="form">
                <label className="title">Import image</label>
                <FileInput control={control} name="image" />
                <ImagePreview control={control} img={img} colorTable={colorTable} />
                <div className="radio-group">
                  <label className="title">Resizing Options</label>
                  <div className="inline-group">
                    <select {...register('aspect')}>
                      <option value={ResizeType.EXACTW}>Preserve Width</option>
                      <option value={ResizeType.EXACTH}>Preserve Height</option>
                      <option value={ResizeType.NORESAMPLE}>One pixel per stitch</option>
                      <option value={ResizeType.FIT}>Fit</option>
                    </select>
                    <label>Dimensions: <input type="text" {...register('dimensions')} /></label>
                  </div>
                </div>
                <div className="input-group">
                  <label className="title">Adjustments</label>
                  <label className="checkbox-input"><input type="checkbox" {...register('grayscale')} /> Grayscale</label>
                  <Slider name="colors" min="2" max="6" step="1" control={control} />
                  <div className="inline-group">
                    <Slider name="brightness" min="-100" max="100" step="1" control={control} />
                    <Slider name="contrast" min="-100" max="100" step="1" control={control} />
                  </div>
                </div>
                <div className="button-group">
                  <input type="button" value="Cancel" onClick={() => setUserDialog(false)} />
                  <input type="button" value="Reset" onClick={() => reset(defaultValues)} />
                  <input type="submit" value="Add Image" />
                </div>
              </form>
            </FormProvider>
          </div>

        </Modal>
      )}
    </>
  )
}

export default ImageUpload
