import { createContext, ReactNode, useContext } from 'react'
import { usePrompt, ShowPrompt } from 'use-prompt'
import Modal from 'src/editor/modal'

export const Context = createContext<ShowPrompt>(() => Promise.resolve())

export function useDialog() {
  return useContext(Context)
}

export function DialogProvider({children}: { children: ReactNode}) {
  const [dialog, showPrompt, visible, clearPrompt] = usePrompt()
  return (
    <Context.Provider value={showPrompt}>{visible && (
      <Modal>
        <div
          className="overlay"
          tabIndex={-1}
          ref={(el) => setTimeout(() => el?.focus())}
          onKeyDown={({ key }) => key == 'Escape' && clearPrompt()}
          onClick={({ target, currentTarget }) => target === currentTarget && clearPrompt()}
        >
          <div className="dialog">
            <button className="close" onClick={() => clearPrompt()}>✕</button>
            {dialog}
          </div>
        </div>
      </Modal>
    )}{children}
    </Context.Provider>
  )
}
