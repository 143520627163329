import {
  NeedleCode,
  NeedleTransferCode,
  StitchCode,
  NumStitchCodes,
  isNeedleTransferCode,
  isNeedleCarrierCode,
  isNeedleYarnCode,
} from './codes'
/**
 * Decomposition of stitch code into a pair of needle codes
 *
 * /!\ Every stitch code maps to a needle code pair,
 * but not every pair of needle codes corresponds to a valid stitch code.
 */
export type NeedleCodePair = readonly [ NeedleCode, NeedleCode ]

// map from stitch code to needle code pair
const StitchCodeToNeedleCodePairs = {
  // miss codes
  [StitchCode.MISS]: [NeedleCode.MISS, NeedleCode.MISS],
  [StitchCode.XMISS]: [NeedleCode.XMISS, NeedleCode.MISS],
  // two-bed codes
  [StitchCode.BOTH_KNIT]: [NeedleCode.KNIT, NeedleCode.KNIT],
  [StitchCode.BOTH_TUCK]: [NeedleCode.TUCK, NeedleCode.TUCK],
  [StitchCode.BOTH_DROP]: [NeedleCode.DROP, NeedleCode.DROP],
  [StitchCode.FRNT_KNIT_REAR_TUCK]: [NeedleCode.KNIT, NeedleCode.TUCK],
  [StitchCode.FRNT_TUCK_REAR_KNIT]: [NeedleCode.TUCK, NeedleCode.KNIT],
  // front-only codes
  [StitchCode.FRNT_KNIT]: [NeedleCode.KNIT, NeedleCode.MISS],
  [StitchCode.FRNT_TUCK]: [NeedleCode.TUCK, NeedleCode.MISS],
  [StitchCode.FRNT_SPLIT]: [NeedleCode.SPLT, NeedleCode.MISS],
  [StitchCode.FRNT_DROP]: [NeedleCode.DROP, NeedleCode.MISS],
  [StitchCode.FRNT_XFER]: [NeedleCode.XFER, NeedleCode.MISS],
  [StitchCode.FRNT_XFERL1]: [NeedleCode.XFERL1, NeedleCode.MISS],
  [StitchCode.FRNT_XFERL2]: [NeedleCode.XFERL2, NeedleCode.MISS],
  [StitchCode.FRNT_XFERL3]: [NeedleCode.XFERL3, NeedleCode.MISS],
  [StitchCode.FRNT_XFERL4]: [NeedleCode.XFERL4, NeedleCode.MISS],
  [StitchCode.FRNT_XFERR1]: [NeedleCode.XFERR1, NeedleCode.MISS],
  [StitchCode.FRNT_XFERR2]: [NeedleCode.XFERR2, NeedleCode.MISS],
  [StitchCode.FRNT_XFERR3]: [NeedleCode.XFERR3, NeedleCode.MISS],
  [StitchCode.FRNT_XFERR4]: [NeedleCode.XFERR4, NeedleCode.MISS],
  // front-only codes
  [StitchCode.REAR_KNIT]: [NeedleCode.MISS, NeedleCode.KNIT],
  [StitchCode.REAR_TUCK]: [NeedleCode.MISS, NeedleCode.TUCK],
  [StitchCode.REAR_SPLIT]: [NeedleCode.MISS, NeedleCode.SPLT],
  [StitchCode.REAR_DROP]: [NeedleCode.MISS, NeedleCode.DROP],
  [StitchCode.REAR_XFER]: [NeedleCode.MISS, NeedleCode.XFER],
  [StitchCode.REAR_XFERL1]: [NeedleCode.MISS, NeedleCode.XFERL1],
  [StitchCode.REAR_XFERL2]: [NeedleCode.MISS, NeedleCode.XFERL2],
  [StitchCode.REAR_XFERL3]: [NeedleCode.MISS, NeedleCode.XFERL3],
  [StitchCode.REAR_XFERL4]: [NeedleCode.MISS, NeedleCode.XFERL4],
  [StitchCode.REAR_XFERR1]: [NeedleCode.MISS, NeedleCode.XFERR1],
  [StitchCode.REAR_XFERR2]: [NeedleCode.MISS, NeedleCode.XFERR2],
  [StitchCode.REAR_XFERR3]: [NeedleCode.MISS, NeedleCode.XFERR3],
  [StitchCode.REAR_XFERR4]: [NeedleCode.MISS, NeedleCode.XFERR4],
  // invalid case should still generate something
  [StitchCode.INVALID]: [NeedleCode.INVALID, NeedleCode.INVALID],
} as { readonly [code in StitchCode]: NeedleCodePair }

// the list of needle code pairs, ordered like the stitch codes
const NeedleCodePairs = Array.from({ length: NumStitchCodes }, (_, sc) => StitchCodeToNeedleCodePairs[sc as StitchCode])

/**
 * Convert from stitch code to needle code pair
 */
export function getNeedleCodePair(code: StitchCode): NeedleCodePair {
  return NeedleCodePairs[code ?? StitchCode.INVALID]
}

/**
 * Convert from needle code pair to stitch code.
 *
 * If the pair is not valid `StitchCode.INVALID` is returned.
 */
export function getStitchCode([fc, rc]: NeedleCodePair): StitchCode {
  const idx = NeedleCodePairs.findIndex((ncp) => ncp[0] === fc && ncp[1] === rc)
  return idx === -1 ? StitchCode.INVALID : idx as StitchCode
}

/**
 * The needle transfer codes, ordered by shift (-4 to +4)
 */
const NeedleTransferCodes = [
  NeedleCode.XFERL4,
  NeedleCode.XFERL3,
  NeedleCode.XFERL2,
  NeedleCode.XFERL1,
  NeedleCode.XFER,
  NeedleCode.XFERR1,
  NeedleCode.XFERR2,
  NeedleCode.XFERR3,
  NeedleCode.XFERR4,
] as const

// the index of the 0-offset transfer code
const NeedleTransferOffset = NeedleTransferCodes.indexOf(NeedleCode.XFER)
console.assert(NeedleTransferOffset !== -1, 'Missing needle transfer without shift')

/**
 * Return the needle transfer code corresponding to a given offset
 * or `NeedleCode.INVALID` if the offset is not valid.
 *
 * @param offset an integer offset between -4 and +4, both included
 * @returns the corresponding needle code or `NeedleCode.INVALID`
 */
export function getNeedleTransferCode(offset: number) {
  return NeedleTransferCodes[offset + NeedleTransferOffset] ?? NeedleCode.INVALID
}

/**
 * Compute the shift associated with a needle transfer code
 *
 * @see {@link NeedleTransferCode}
 */
export function getNeedleTransferShift(nc: NeedleTransferCode): number {
  return NeedleTransferCodes.indexOf(nc) - NeedleTransferOffset
}

/**
 * Computes the stitch code corresponding
 * to a source bed and transfer offset.
 *
 * @param srcSide the side the transfer originates from
 * @param offset the offset of the transfer
 * @returns the corresponding stitch code
 */
export function getStitchTransferCode(srcSide: 0 | 1, offset: number) {
  const nc = getNeedleTransferCode(offset)
  return getStitchCode(srcSide === 0 ? [nc, NeedleCode.MISS] : [NeedleCode.MISS, nc])
}

/**
 * Check whether a stitch code corresponds to a transfer.
 *
 * This is true if any of the underlying needle codes are transfers.
 *
 * @see {@link isNeedleTransferCode}
 */
export function isStitchTransferCode(sc: StitchCode): boolean {
  const [fc, rc] = getNeedleCodePair(sc)
  return isNeedleTransferCode(fc) || isNeedleTransferCode(rc)
}

/**
 * Check whether a stitch code corresponds to a carrier action.
 *
 * This is true if any of the underlying needle codes involves a carrier.
 *
 * @see {@link isNeedleCarrierCode}
 */
export function isStitchCarrierCode(sc: StitchCode): boolean {
  const [fc, rc] = getNeedleCodePair(sc)
  return isNeedleCarrierCode(fc) || isNeedleCarrierCode(rc)
}

/**
 * Check whether a stitch code corresponds to a yarn action.
 *
 * This is true if any of the underlying needle codes involves the yarn.
 *
 * @see {@link isNeedleYarnCode}
 */
export function isStitchYarnCode(sc: StitchCode): boolean {
  const [fc, rc] = getNeedleCodePair(sc)
  return isNeedleYarnCode(fc) || isNeedleYarnCode(rc)
}

/**
 * Mirror a stitch code between left and right sides
 */
export function mirrorStitchCode(sc: StitchCode): StitchCode {
  const [fc, rc] = getNeedleCodePair(sc)
  if(isNeedleTransferCode(fc)) {
    const off = getNeedleTransferShift(fc)
    const rfc = getNeedleTransferCode(-off)
    return getStitchCode([rfc, rc])
  } if(isNeedleTransferCode(rc)) {
    const off = getNeedleTransferShift(rc)
    const rrc = getNeedleTransferCode(-off)
    return getStitchCode([fc, rrc])
  }
  // no mirroring needed
  return sc
}

/**
 * Swap the bed semantic of a stitch code
 */
export function swapStitchCodeBed(sc: StitchCode): StitchCode {
  const [fc, rc] = getNeedleCodePair(sc)
  return getStitchCode([rc, fc])
}

/**
 * Transform the stitch codes on a bed slice
 * by racking the rear bed by a given offset
 * and shifting the needle operations of the rear bed.
 *
 * Invalid combinations get transformed into `StitchCode.INVALID`.
 *
 * @param bed a stitch code bed
 * @param rackOffset the racking offset to apply
 * @returns an updated version of the bed
 */
export function rackRearNeedles(bed: ArrayLike<StitchCode>, rackOffset = 0) {
  const intRack = Math.floor(rackOffset)
  const pairs = Array.from(bed, (sc) => getNeedleCodePair(sc))
  return pairs.map(([fc], i) => {
    const rc = pairs[i - intRack][1]
    return getStitchCode([fc, rc])
  })
}
