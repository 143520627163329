import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useStore } from 'react-redux'
import { captureMessage } from '@sentry/browser'
import { useForm } from 'react-hook-form'

import { useDialog } from 'src/editor/dialog'
import { Serialize } from 'src/data/serialization'
import type { RootState } from 'src/store'
import { makeDesign, submitBugReport } from 'src/api'
import Palette from 'src/data/time-needle/palette'
import { getThumbnail } from 'src/data/time-needle/thumbnail'
import { ViewMode } from 'src/data/time-needle/view-mode'

const MESSAGE_ON_FATAL = `
  An error has occured inside the compiler.
  It will help us greatly to fix the problem if you share a copy of this design.
  If you give permission, we will save the current design, and send a copy to the engineering team.
  The copy will be used only for development purposes and will be deleted as soon as the bug is fixed.
`
const MESSAGE_ON_REPORT = `
  To fix a novel bug,
  it may be crucial for the engineering team to reproduce the problem using your design.
  If you give permission, we will save the current design,
  and send a copy to the engineering team.
  The copy will be used only for development purposes and will be deleted as soon as the bug is fixed.
`

const palette = Palette.default()

const SubmitBug = () => {
  const { id } = useParams()
  const showDialog = useDialog()
  const { register, handleSubmit} = useForm()
  const fatal = false
  const store = useStore()

  const { getAccessTokenSilently } = useAuth0()

  const showBugDialog = async () => {
    const {description, canCopy} = await showDialog(({resolve}) => (
      <form className="form" onSubmit={handleSubmit(resolve)}>
        <div className="title">{fatal ? 'Fatal Error' : 'Bug Report'}</div>
        <textarea rows={5} placeholder="Description (required)" {...register('description', { required: true })} />
        <span>{fatal ? MESSAGE_ON_FATAL : MESSAGE_ON_REPORT}</span>
        <label className="checkbox-input"><input type="checkbox" {...register('canCopy')} /> Send a copy of my design along with the bug report</label>
        <input type="submit" />
      </form>
    ))

    if(fatal) {
      captureMessage('Compiler panic (fatal)', 'fatal')
    }

    const {
      canvas: { tnimage },
      design: { title: name },
      settings: { columnsPerCm, rowsPerCm },
    } = store.getState() as RootState

    const design = !canCopy ? null : makeDesign(
      id ? name : 'untitled',
      Serialize(tnimage),
      palette.toColorArray(),
      tnimage.cdata.width * columnsPerCm,
      tnimage.cdata.height * rowsPerCm,
      columnsPerCm,
      rowsPerCm,
      getThumbnail(tnimage, ViewMode.PATTERN),
    )
    await submitBugReport(id, await getAccessTokenSilently(), design, description)
  }

  if(fatal) {
    showBugDialog()
  }

  return <button onClick={showBugDialog}>Bug Report</button>
}

export default SubmitBug
