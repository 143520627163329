/* eslint-disable camelcase */

import type {
  WebGLXRenderingContext,
  WebGLXTexture,
} from './types';
import { checkGLError } from './utils';

export type ChannelCount = 1 | 2 | 3 | 4

export interface TextureInitData {
  width: number
  height: number
  channels: ChannelCount
  data?: ArrayBufferView
}

// basic conversion from channel count to texture format
// this assumes uint8 data that interleaves the channels
// as consecutive cells in the uint8 array
const channelsToTexFormat = [
  WebGLRenderingContext.NONE,
  WebGLRenderingContext.LUMINANCE,
  WebGLRenderingContext.LUMINANCE_ALPHA,
  WebGLRenderingContext.RGB,
  WebGLRenderingContext.RGBA,
]

export function initTexture(
  gl: WebGLXRenderingContext,
  texData: TextureInitData,
  repGLTex: WebGLXTexture | null,
  errCtx = '?',
): WebGLXTexture {
  // allocate texture if necessary
  const [glTex, newTex] = repGLTex ? [
    repGLTex,
    false,
  ] : [
    gl.createTexture() as WebGLXTexture,
    true,
  ]

  // XXX this may not be necessary
  gl.activeTexture(gl.TEXTURE0)

  gl.bindTexture(gl.TEXTURE_2D, glTex)

  const {
    width, height, channels, data = null,
  } = texData

  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    channelsToTexFormat[channels],
    width,
    height,
    0,
    channelsToTexFormat[channels],
    gl.UNSIGNED_BYTE,
    data,
  )

  // set associated parameters for new textures
  if(newTex) {
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST)
  }

  gl.bindTexture(gl.TEXTURE_2D, null)

  // validate that things are fine
  checkGLError(gl, `Error initializing texture (ctx: ${errCtx})`)

  return glTex
}

export interface TextureData {
  width: number
  height: number
  channels: ChannelCount
  data: ArrayBufferView
}

export function updateTexture(
  gl: WebGLXRenderingContext,
  glTex: WebGLXTexture,
  texData: TextureData,
  errCtx = '?',
  createIfEmpty = true,
): WebGLXTexture {
  if(!texData) {
    console.error('Cannot update texture: no data available')
    return glTex
  }
  if(!glTex) {
    if(createIfEmpty) {
      // initialize texture
      return initTexture(
        gl,
        texData,
        null,
        errCtx,
      )
    }
    // invalid state
    console.error(
      `Texture should have been allocated already (ctx: ${errCtx})`,
    )
    return null
  }

  // XXX ideally, we'd trigger the texture unit that is bound to that texture
  // but we only know that for rendering, so maybe it's fine to use unit 0 for data transfer
  gl.activeTexture(gl.TEXTURE0)

  gl.bindTexture(gl.TEXTURE_2D, glTex)

  const {
    width, height, channels, data,
  } = texData

  try {
    gl.texImage2D(
      gl.TEXTURE_2D,
      0, // update is for non-mipmap => using level 0
      channelsToTexFormat[channels],
      width,
      height,
      0, // no border
      channelsToTexFormat[channels],
      gl.UNSIGNED_BYTE, // data is assumed to be U8 data
      data,
    );
    if(gl.getError() !== gl.NO_ERROR) {
      console.warn(`Error during texture update (ctx: ${errCtx})`)
    }
  } catch(err) {
    console.error(`Failed to update texture (ctx: ${errCtx})`)
  }

  gl.bindTexture(gl.TEXTURE_2D, null)

  return glTex
}

export interface TextureSubData {
  x: number
  y: number
  width?: number
  height?: number
  channels: ChannelCount
  data: ArrayBufferView
}

export function updateTextureRegion(
  gl: WebGLXRenderingContext,
  glTex: WebGLXTexture,
  texData: TextureSubData,
  errCtx = '?',
  checkErrors = false,
) {
  if(!glTex) {
    console.error('Cannot update texture region: not allocated')
    return
  }
  if(!texData) {
    console.error('Cannot update texture region: no data available')
    return
  }

  // XXX ideally, we'd trigger the texture unit that is bound to that texture
  // but we only know that for rendering, so maybe it's fine to use unit 0 for data transfer
  gl.activeTexture(gl.TEXTURE0)

  gl.bindTexture(gl.TEXTURE_2D, glTex)

  const {
    x, y, width = 1, height = 1, channels, data,
  } = texData
  gl.texSubImage2D(
    gl.TEXTURE_2D,
    0,
    x,
    y,
    width,
    height,
    channelsToTexFormat[channels],
    gl.UNSIGNED_BYTE,
    data,
  )

  gl.bindTexture(gl.TEXTURE_2D, null)

  if(checkErrors) {
    checkGLError(gl, `Error updating region (ctx: ${errCtx})`)
  }
}

export type RegionDataFunc = (r: TextureSubData) => void
export type LocalTextureFunc = (f: RegionDataFunc) => void

export function updateTextureRegions(
  gl: WebGLXRenderingContext,
  glTex: WebGLXTexture,
  regionFunc: LocalTextureFunc,
  errCtx = '?',
  checkErrors = false,
) {
  if(!glTex) {
    console.error('Cannot update texture region: not allocated')
    return
  }

  // XXX ideally, we'd trigger the texture unit that is bound to that texture
  // but we only know that for rendering, so maybe it's fine to use unit 0 for data transfer
  gl.activeTexture(gl.TEXTURE0)

  gl.bindTexture(gl.TEXTURE_2D, glTex)

  // invoke region function that will trigger
  // the individual region calls to texSubImage2D
  regionFunc(({
    x, y, width = 1, height = 1, channels, data,
  }: TextureSubData) => {
    gl.texSubImage2D(
      gl.TEXTURE_2D,
      0,
      x,
      y,
      width,
      height,
      channelsToTexFormat[channels],
      gl.UNSIGNED_BYTE,
      data,
    )
  })

  gl.bindTexture(gl.TEXTURE_2D, null)

  if(checkErrors) {
    checkGLError(gl, `Error updating region (ctx: ${errCtx})`)
  }
}

export function getAnisotropicExtension(gl: WebGLXRenderingContext): EXT_texture_filter_anisotropic | null {
  // @see https://developer.mozilla.org/en-US/docs/Web/API/EXT_texture_filter_anisotropic
  for(const extName of [
    'EXT_texture_filter_anisotropic',
    'MOZ_EXT_texture_filter_anisotropic',
    'WEBKIT_EXT_texture_filter_anisotropic',
  ]) {
    const ext = gl.getExtension(extName)
    if(ext) {
      return ext
    }
  }
  return null
}

export function loadTextureFromURL(
  gl: WebGLXRenderingContext,
  url: string,
  mipmap = true,
  anisotropy = 0,
): WebGLXTexture {
  const tex = gl.createTexture() as WebGLXTexture
  const greyPixel = new Uint8Array([127, 127, 127, 255]);

  gl.bindTexture(gl.TEXTURE_2D, tex);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.RGBA,
    1,
    1,
    0,
    gl.RGBA,
    gl.UNSIGNED_BYTE,
    greyPixel,
  );

  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  if(mipmap) {
    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
  } else {
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  }
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

  // anisotropic filtering extension
  if(anisotropy) {
    const ext = getAnisotropicExtension(gl)
    if(ext) {
      const max = gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT) as number
      // console.log('anistropy max', max) // got max = 16
      gl.texParameterf(gl.TEXTURE_2D, ext.TEXTURE_MAX_ANISOTROPY_EXT, Math.min(anisotropy, max))
    }
  }

  gl.bindTexture(gl.TEXTURE_2D, null);

  // asynchronous url loading and texture update
  const image = new Image();
  image.onload = (): void => {
    gl.bindTexture(gl.TEXTURE_2D, tex);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      image,
    );

    if(mipmap) {
      gl.generateMipmap(gl.TEXTURE_2D)
    }
    gl.bindTexture(gl.TEXTURE_2D, null)
  };
  image.src = url

  // return allocated gl texture already
  return tex
}
