import {
  isYarnIndex,
} from 'src/data/time-needle/options'
import {
  generateBasicFoundation,
  generateBindoff,
  generateDecreaseTiling,
  generateDecreaseTransfers,
  generateSideTransfers,
  generateWideRibsFoundation,
  generateTransfersToSide,
  generateWaste,
  parseWasteOptions,
  parseShortRowOptions,
  generateShortRows,
  parseDecreaseOptions,
  parseDecreaseTransferOptions,
} from 'src/macros'
import {
  useAppSelector, useRawMacro, useUserEntry,
} from 'src/hooks'
import { SelectorLike, TimeNeedleSelector } from 'src/macros/common'
import ContextMenuItem, { Separator } from './menu-item'

export default function GenerateMenu() {
  const rawMacro = useRawMacro()
  const applyMacro = (macroFun: (s: TimeNeedleSelector) => SelectorLike | void) => rawMacro((tni, sel) => macroFun(TimeNeedleSelector.fromRegion(tni, sel)))
  const sel = useAppSelector((state) => state.canvas.selection)
  const [tilingDefault, setTilingDefault] = useUserEntry('decr-tiling', 'repeat 10 decrease each 2 left,right at 6')
  const [decreaseDefault, setDecreaseDefault] = useUserEntry('decr', 'both at 6')
  const [sideDefault, setSideDefault] = useUserEntry('side', 'front')
  const [shortrowDefault, setShortrowDefault] = useUserEntry('shortrow', 'both by 4 insert')
  const [bindoffDefault, setBindoffDefault] = useUserEntry('bindoff', 'front 3 ltr skip clear reverse')
  const [wasteDefault, setWasteDefault] = useUserEntry('waste', 'draw 1 waste 6 body 4')
  const [lastYarn, setLastYarn] = useUserEntry('yarn', '3')
  const hasHeight = sel[3] - sel[1] > 1
  const hasWidth = sel[2] - sel[0] > 1
  return (
    <ContextMenuItem
      name="Generate"
      menu
    >
      <ContextMenuItem
        name="Decrease tiling"
        prompt={[(
          <>
            Decrease tiling (two forms):
            <ul>
              <li>repeat (N) decrease each (P) (SIDES) at (M1[,M2]) [insert]</li>
              <li>decrease by (D) over (N) (SIDES) at (M1[,M2]) [insert]</li>
            </ul>
          </>
        ), tilingDefault]}
        parser={(tilingStr: string) => {
          if(tilingStr.length === 0) {
            setTilingDefault(void 0) // reset to default
            return
          }
          return parseDecreaseOptions(tilingStr, sel)
        }}
        onParse={(opts, tilingStr) => {
          setTilingDefault(tilingStr)
          // apply macro
          applyMacro((s) => generateDecreaseTiling(s, opts))
        }}
      />
      <ContextMenuItem
        name="Decrease transfers"
        prompt={['Decrease: side at offset [layered] [left-racking] [right-racking] (e.g., left at 5)', decreaseDefault]}
        parser={parseDecreaseTransferOptions}
        onParse={(opts, decreaseStr) => {
          setDecreaseDefault(decreaseStr)
          applyMacro((s) => generateDecreaseTransfers(s, opts))
        }}
        disabled={!hasHeight}
      />
      <ContextMenuItem
        name="Transfers to side"
        prompt={['Transfer to which side? (front or rear)', sideDefault]}
        onInput={(sideStr) => {
          if(!['front', 'rear'].includes(sideStr)) {
            alert(`Invalid side ${sideStr}, should be front or rear`)
            return
          }
          const side = sideStr === 'front' ? 0 : 1
          setSideDefault(sideStr)
          applyMacro((s) => generateTransfersToSide(s, side))
        }}
        disabled={!hasWidth || hasHeight}
      />
      <ContextMenuItem
        name="Side transfers between rows"
        onClick={() => {
          applyMacro((s) => generateSideTransfers(s))
        }}
        disabled={!hasWidth || !hasHeight}
      />
      <ContextMenuItem
        name="Short-row tiling"
        prompt={['Short-rows: SIDES by STEP [over WIDTH] [inward] [insert] [RND]', shortrowDefault]}
        parser={(srStr: string) => {
          if(srStr.length === 0) {
            setTilingDefault(void 0) // reset to default
            return
          }
          return parseShortRowOptions(srStr)
        }}
        onParse={(opts, srStr) => {
          setShortrowDefault(srStr)
          applyMacro((s) => generateShortRows(s, opts))
        }}
        disabled={!hasWidth}
      />
      <Separator />
      <ContextMenuItem
        name="Bindoff"
        prompt={['Bindoff: side, yarn, direction, [skip] [clear] [reverse]', bindoffDefault]}
        onInput={(bindoffStr) => {
          const [sideStr = 'front', yarnStr = '3', dirStr = 'ltr', ...rest] = bindoffStr.split(/[^0-9a-z]+/)
          const side = sideStr === 'front' ? 0 : 1
          const yarn = parseInt(yarnStr, 10)
          if(!isYarnIndex(yarn)) {
            alert(`Invalid yarn ${yarnStr}, should be in [1;6]`)
            return
          }
          const dir = dirStr !== 'rtl' ? 0 : 1
          const deleteAbove = rest.includes('clear')
          const knitFirstStitch = !rest.includes('skip')
          const reverseKnit = rest.includes('reverse')
          setBindoffDefault(bindoffStr)
          applyMacro((s) => generateBindoff(s, {
            side, yarn, direction: dir, deleteAbove, knitFirstStitch, reverseKnit,
          }))
        }}
      />
      <ContextMenuItem
        name="Waste"
        prompt={['Waste: [waste yarn] [draw yarn] [body yarn1 yarn2 ...] [local]', wasteDefault]}
        parser={parseWasteOptions}
        onParse={(opts, wasteStr) => {
          setWasteDefault(wasteStr)
          applyMacro((s) => generateWaste(s, opts))
        }}
      />
      <Separator />
      <ContextMenuItem
        name="Foundation rows (basic)"
        prompt={['Yarn number [1 to 6] or none', lastYarn]}
        onInput={(yarnStr) => {
          const yarn = parseInt(yarnStr, 10)
          if(!isYarnIndex(yarn)) {
            return
          }
          setLastYarn(yarnStr)
          applyMacro((s) => generateBasicFoundation(s, yarn, true))
        }}
      />
      <ContextMenuItem
        name="Foundation rows (tubular)"
        prompt={['Yarn number [1 to 6] or none', lastYarn]}
        onInput={(yarnStr) => {
          const yarn = parseInt(yarnStr, 10)
          if(!isYarnIndex(yarn)) {
            return
          }
          setLastYarn(yarnStr)
          applyMacro((s) => generateBasicFoundation(s, yarn))
        }}
      />
      <ContextMenuItem
        name="Foundation rows (wide ribs)"
        prompt={['Yarn number [1 to 6] or none', lastYarn]}
        onInput={(yarnStr) => {
          const yarn = parseInt(yarnStr, 10)
          if(!isYarnIndex(yarn)) {
            return
          }
          setLastYarn(yarnStr)
          applyMacro((s) => generateWideRibsFoundation(s, yarn))
        }}
      />
    </ContextMenuItem>
  )
}
