export const createExternalCallback = function () {
  let handler: Callback
  return [
    () => (handler ? handler() : void 0),
    (h: Callback) => {
      handler = h
    },
  ] as [Callback, CallbackSetter]
}

export const createExternalHandler = function<EventType> () {
  let handler: EventHandler<EventType>
  return [
    (e: EventType) => (handler ? handler(e) : void 0),
    (h: typeof handler) => {
      handler = h
    },
  ] as [EventHandler<EventType>, EventHandlerSetter<EventType>]
}
