import { RFV4 } from 'src/common/types';
import {
  CanvasImage,
  copyCanvasImage,
  copyImageToImage as copyCanvasImageToCanvasImage,
  deleteColumns as deleteCanvasColumns,
  deleteColumnSet as deleteCanvasColumnSet,
  deleteRows as deleteCanvasRows,
  deleteRowSet as deleteCanvasRowSet,
  insertColumns as insertCanvasColumns,
  insertColumnSet as insertCanvasColumnSet,
  insertRows as insertCanvasRows,
  insertRowSet as insertCanvasRowSet,
  ReadonlyCanvasImage,
  sameCanvasImages,
} from 'src/data/image';
import type MachineType from './machine-type'
import {
  createOptionsImage,
  getDefaultOptionsRow,
  ReadonlyTimeNeedleOptions,
  TimeNeedleOptions,
} from './options';

/**
 * Number of code channels (1).
 *
 * Each pixel represent a single CStitch code (fits in 8 bits).
 */
export type CodeChannels = 1
/**
 * Needle action data (the cells of the time-needle image)
 */
export type TimeNeedleCode = CanvasImage<CodeChannels>
export type ReadonlyTimeNeedleCode = ReadonlyCanvasImage<CodeChannels>

/**
 * List of strings that are referenced by the options data
 */
export type StringStorage = string[]
export type ReadonlyStringStorage = readonly string[]

/**
 * The edited content of the time-needle editor
 */
export default interface TimeNeedleImage {
  cdata: TimeNeedleCode
  odata: TimeNeedleOptions
  sdata: StringStorage
  type?: MachineType // defaults to kniterate for now
  // XXX add children layers for per-needle options
}
export interface ReadonlyTimeNeedleImage {
  readonly cdata: ReadonlyTimeNeedleCode
  readonly odata: ReadonlyTimeNeedleOptions
  readonly sdata: ReadonlyStringStorage
  readonly type?: MachineType
}

/**
 * Create a deep copy of a time-needle image.
 *
 * This can be computationally expensive.
 */
export function copyTimeNeedleImage({
  cdata, odata, sdata, type,
}: ReadonlyTimeNeedleImage): TimeNeedleImage {
  return {
    cdata: copyCanvasImage(cdata),
    odata: copyCanvasImage(odata),
    sdata: sdata.slice(),
    type,
  }
}

/**
 * Create a shallow copy of a time-needle image.
 * This is computationally inexpensive since the underlying data is copied by references.
 *
 * It is useful when creating a new wrapper in redux.
 *
 * The read-onlyness of the output is the same as that of the input.
 * Thus it can be used to make copies of both writeable and readable-only images.
 *
 * @see {@link copyTimeNeedleImage}
 */
export function copyTimeNeedleImageByRef(img: TimeNeedleImage): TimeNeedleImage
export function copyTimeNeedleImageByRef(img: ReadonlyTimeNeedleImage): ReadonlyTimeNeedleImage
export function copyTimeNeedleImageByRef({
  cdata, odata, sdata, type,
}: TimeNeedleImage | ReadonlyTimeNeedleImage): TimeNeedleImage | ReadonlyTimeNeedleImage {
  return {
    cdata, odata, sdata, type,
  }
}

/**
 * Allocate a time-needle image
 *
 * @param width its width
 * @param height its height
 * @param type the machine type
 * @param initOpts whether to initialize the option data to default data
 * @returns the allocated image
 */
export function createTimeNeedleImage(
  width: number,
  height: number,
  type: MachineType = 'kniterate',
  initOpts = true,
): TimeNeedleImage {
  return {
    cdata: {
      width, height, channels: 1, data: new Uint8Array(width * height),
    },
    odata: createOptionsImage(height, type, initOpts),
    sdata: [],
    type,
  }
}

/**
 * Verifies whether two time-needle images have the same data
 */
export function sameTimeNeedleImages(
  {
    cdata: cdata1,
    odata: odata1,
    sdata: sdata1,
    type: type1 = 'kniterate',
  }: ReadonlyTimeNeedleImage,
  {
    cdata: cdata2,
    odata: odata2,
    sdata: sdata2,
    type: type2 = 'kniterate',
  }: ReadonlyTimeNeedleImage,
): boolean {
  return (
    type1 === type2 &&
    sdata1.length === sdata2.length &&
    sdata1.every((s, i) => sdata2[i] === s) &&
    sameCanvasImages(odata1, odata2) &&
    sameCanvasImages(cdata1, cdata2)
  )
}

/**
 * Check whether a time-needle image is empty
 */
export function isEmptyTimeNeedleImage({ cdata: { width, height }}: ReadonlyTimeNeedleImage) {
  return width === 0 || height === 0
}

/**
 * Creates an empty time-needle image
 */
export function emptyTimeNeedleImage(type: MachineType = 'kniterate'): TimeNeedleImage {
  return createTimeNeedleImage(0, 0, type, false)
}

/**
 * Create a new image by inserting a number of columns into an image
 *
 * @param srcImg the source image, untouched
 * @param colIdx the column index to insert before
 * @param numCols the number of columns to insert
 * @param cfill an optional per-pixel filling value for the code data
 * @returns the new image
 * @see {@link insertCanvasColumns}
 */
export function insertColumns(
  srcImg: ReadonlyTimeNeedleImage,
  colIdx: number,
  numCols: number,
  cfill?: ArrayLike<number>,
): TimeNeedleImage {
  const cdata = insertCanvasColumns(srcImg.cdata, colIdx, numCols, cfill)
  const odata = copyCanvasImage(srcImg.odata)
  return {
    cdata, odata, sdata: srcImg.sdata.slice(), type: srcImg.type,
  }
}

/**
 * Create a new image by inserting a set of columns into an image
 *
 * @param srcImg the source image, untouched
 * @param colSet the set of insertion entries `[colIdx, numCols]`
 * @param cfill an optional per-pixel filling value for the code data
 * @returns the new image
 * @see {@link insertColumns}
 * @see {@link insertCanvasColumnSet}
 */
export function insertColumnSet(
  srcImg: ReadonlyTimeNeedleImage,
  colSet: Iterable<readonly [number, number]>,
  cfill?: ArrayLike<number>,
): TimeNeedleImage {
  const cdata = insertCanvasColumnSet(srcImg.cdata, colSet, cfill)
  const odata = copyCanvasImage(srcImg.odata)
  return {
    cdata, odata, sdata: srcImg.sdata.slice(), type: srcImg.type,
  }
}

/**
 * Create a new image by inserting a number of rows into an image
 *
 * @param srcImg the source image, untouched
 * @param rowIdx the row index to insert before
 * @param numRows the number of rows to insert
 * @param cfill an optional per-pixel or per-row filling value for the code data
 * @param ofill an optional per-pixel or per-row filling value for the option data
 * @returns the new image
 * @see {@link insertCanvasRows}
 */
export function insertRows(
  srcImg: ReadonlyTimeNeedleImage,
  rowIdx: number,
  numRows: number,
  cfill?: ArrayLike<number>,
  ofill: ArrayLike<number> = getDefaultOptionsRow(srcImg.type),
): TimeNeedleImage {
  const cdata = insertCanvasRows(srcImg.cdata, rowIdx, numRows, cfill)
  const odata = insertCanvasRows(srcImg.odata, rowIdx, numRows, ofill)
  return {
    cdata, odata, sdata: srcImg.sdata.slice(), type: srcImg.type,
  }
}

/**
 * Create a new image by inserting a set of columns into an image
 *
 * @param srcImg the source image, untouched
 * @param rowSet the set of insertion entries `[rowIdx, numRows]`
 * @param cfill an optional per-pixel or per-row filling value for the code data
 * @param ofill an optional per-pixel or per-row filling value for the option data
 * @returns the new image
 * @see {@link insertRows}
 * @see {@link insertCanvasRowSet}
 */
export function insertRowSet(
  srcImg: ReadonlyTimeNeedleImage,
  rowSet: Iterable<readonly [number, number]>,
  cfill?: ArrayLike<number>,
  ofill: ArrayLike<number> = getDefaultOptionsRow(srcImg.type),
): TimeNeedleImage {
  const cdata = insertCanvasRowSet(srcImg.cdata, rowSet, cfill)
  const odata = insertCanvasRowSet(srcImg.odata, rowSet, ofill)
  return {
    cdata, odata, sdata: srcImg.sdata.slice(), type: srcImg.type,
  }
}

/**
 * Create a new image by deleting a number of columns
 *
 * @param srcImg the source image, untouched
 * @param colIdx the column index to delete from
 * @param numCols the number of columns to delete
 * @returns the new image
 * @see {@link deleteCanvasColumns}
 */
export function deleteColumns(
  srcImg: ReadonlyTimeNeedleImage,
  colIdx: number,
  numCols = 1,
): TimeNeedleImage {
  const cdata = deleteCanvasColumns(srcImg.cdata, colIdx, numCols)
  const odata = copyCanvasImage(srcImg.odata)
  return {
    cdata, odata, sdata: srcImg.sdata.slice(), type: srcImg.type,
  }
}

/**
 * Create a new image by deleting a set of columns
 *
 * @param srcImg the source image, untouched
 * @param colSet the set of column indices (can be repeated)
 * @returns the new image
 * @see {@link deleteColumns}
 * @see {@link deleteCanvasColumnSet}
 */
export function deleteColumnSet(
  srcImg: ReadonlyTimeNeedleImage,
  colSet: Iterable<number>,
): TimeNeedleImage {
  const cdata = deleteCanvasColumnSet(srcImg.cdata, colSet)
  const odata = copyCanvasImage(srcImg.odata)
  return {
    cdata, odata, sdata: srcImg.sdata.slice(), type: srcImg.type,
  }
}

/**
 * Create a new image by deleting a number of rows
 *
 * @param srcImg the source image, untouched
 * @param rowIdx the row index to delete from
 * @param numRows the number of rows to delete
 * @returns the new image
 * @see {@link deleteCanvasRows}
 */
export function deleteRows(
  srcImg: ReadonlyTimeNeedleImage,
  rowIdx: number,
  numRows = 1,
): TimeNeedleImage {
  const cdata = deleteCanvasRows(srcImg.cdata, rowIdx, numRows)
  const odata = deleteCanvasRows(srcImg.odata, rowIdx, numRows)
  return {
    cdata, odata, sdata: srcImg.sdata.slice(), type: srcImg.type,
  }
}

/**
 * Create a new image by deleting a set of rows
 *
 * @param srcImg the source image, untouched
 * @param rowSet the set of row indices (can be repeated)
 * @returns the new image
 * @see {@link deleteRows}
 * @see {@link deleteCanvasRowSet}
 */
export function deleteRowSet(
  srcImg: ReadonlyTimeNeedleImage,
  rowSet: Iterable<number>,
): TimeNeedleImage {
  const cdata = deleteCanvasRowSet(srcImg.cdata, rowSet)
  const odata = deleteCanvasRowSet(srcImg.odata, rowSet)
  return {
    cdata, odata, sdata: srcImg.sdata.slice(), type: srcImg.type,
  }
}

// let a: TimeNeedleImage
// let b: ReadonlyTimeNeedleImage
// b = a
// a = b
// b.cdata.data[0] = 1
// a.cdata.data[0] = 1

export function copyImageToImage(
  srcImg: ReadonlyTimeNeedleImage,
  srcRect: RFV4,
  trgImg: TimeNeedleImage,
  trgRect: RFV4,
): void {
  copyCanvasImageToCanvasImage(srcImg.cdata, srcRect, trgImg.cdata, trgRect)
  const [_sl, srcBottom, _sr, srcTop] = srcRect
  const [_tl, trgBottom, _tr, trgTop] = trgRect
  copyCanvasImageToCanvasImage(
    srcImg.odata,
    [0, srcBottom, srcImg.odata.width, srcTop],
    trgImg.odata,
    [0, trgBottom, trgImg.odata.width, trgTop],
  )
}
