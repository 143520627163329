import { useEffect } from 'react'
import { shallowEqual } from 'react-redux'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/hooks'

import FPSCounter from 'src/common/fps-counter'

import Viewer from 'src/editor/viewer'
import Modal from 'src/editor/modal'
import Header from 'src/editor/header'
import Ribbon, {
  Actions, Checks, Scripts, Settings,
} from 'src/editor/ribbon'
import Toolbox from 'src/editor/sidebar/toolbox'
import YarnSelector from 'src/editor/sidebar/yarn-selector'
import StitchSelector from 'src/editor/sidebar/stitch-selector'
import ViewControl from 'src/editor/view-control'
import ContextMenu from 'src/editor/context-menu'
import Notifier from 'src/editor/notification/notifier'
import { createStarterImage } from 'src/data/time-needle/starter-image'
import { ViewMode } from 'src/data/time-needle/view-mode'
import SimulationWindow from 'src/editor/simulation/window'
import { deferActions, setActiveViewMode, setTimeNeedleImage } from './time-needle/slice'
import { centerHome, updateThumbnails } from './time-needle/action'
import Axes from './axes'

import 'src/styles/forms.scss'

const Editor = function () {
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if(['/app', '/app/'].includes(location.pathname)) {
      // This is the default stored in the initialState CanvasState (move somewhere more sensible)
      const tnimage = createStarterImage(64, 153)
      dispatch(setTimeNeedleImage(tnimage))
      dispatch(deferActions([
        centerHome, updateThumbnails(),
      ]))
      dispatch(setActiveViewMode(ViewMode.PATTERN))
    }
  }, [])

  const {
    activeView, enabled, editing,
  } = useAppSelector(({
    canvas: {
      activeView, enabled,
      isEditing, pasteData,
      previewData: [previewLayer, previewData],
    },
  }) => ({
    activeView,
    enabled,
    editing: (
      isEditing ||
        (previewLayer === activeView && !!previewData) ||
        !!pasteData
    ),
  }), shallowEqual)

  return (
    <>
      {!enabled && <Modal><div className="overlay" /></Modal>}
      <Header />
      <main className={classNames('editor', { editing })}>
        <ViewControl />
        <Viewer />
        <div className="sidebar">
          <Toolbox />
          <StitchSelector />
          <YarnSelector />
        </div>
        <Ribbon>
          <Actions title="actions" />
          <Checks title="checks" />
          <Scripts title="scripts" />
          <Settings title="settings" />
        </Ribbon>

        <ContextMenu />
        <Notifier />

        <Axes activeView={activeView} />

        <SimulationWindow />

        {process.env.NODE_ENV === 'development' && <section className="fps-stats-counter"><FPSCounter top="auto" left="auto" bottom={10} right={10} /></section>}
      </main>
    </>
  )
}

export default Editor
