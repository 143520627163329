import {
  computeSafeZoomFromLevel,
  getCoord,
} from 'src/common/math'
import TimeNeedleCanvas, { TimeNeedleCanvasProps } from './canvas'
import { ActionContext, ActionHandler } from './action'

export interface ViewerProps {
  context: ActionContext
  action?: ActionHandler
}

export default function TimeNeedleViewer({
  // for interaction
  context: ctx,
  action,
  // external webgl handlers
  onInit,
  onResize,
  onRender,
  // external DOM handlers
  onMouseDown,
  onMouseUp,
  onMouseMove,
  onWheel,
  onKeyDown,
  onKeyUp,
  // remaining properties
  ...props
}: TimeNeedleCanvasProps & ViewerProps) {
  return (
    <TimeNeedleCanvas
      // the properties from the state
      {...props}
      //
      // event handlers
      onMouseDown={(e) => {
        onMouseDown?.(e)
        // delegate to action
        action?.onMouseDown(ctx, props.mouseCoord[0], props.mouseCoord[1], e.button)
        ctx.updateEditing(true)
      }}
      onMouseUp={(e) => {
        onMouseUp?.(e)
        // delegate to action
        action?.onMouseUp(ctx, props.mouseCoord[0], props.mouseCoord[1], e.button)
        ctx.updateEditing(false)
      }}
      onMouseMove={(e) => {
        onMouseMove?.(e)
        const { offsetX: x, offsetY: y } = e.nativeEvent
        if(e.button === 1) {
          e.preventDefault()
        }

        // always update mouseCoord, irrespective of action
        const {
          M, transform, setMouseCoord, updateEditing,
        } = ctx
        const [cx, cy] = getCoord([x, y], M, transform.pageDims, Math.floor)
        setMouseCoord([x, y, cx, cy])

        // editing as long as a button is pressed
        updateEditing(e.buttons !== 0)

        // delegated to action handler
        action?.onMouseMove(ctx, x, y, e.buttons)
        return true
      }}
      onWheel={(e) => {
        onWheel?.(e)
        // let any action consume the wheel
        if(action) {
          const { offsetX, offsetY } = e.nativeEvent
          action.onWheel(ctx, e.deltaY, e.deltaMode, offsetX, offsetY)
        }
      }}
      onKeyDown={(e) => {
        onKeyDown?.(e)
        const ctrl = e.ctrlKey || e.metaKey
        const shift = e.shiftKey
        action?.onKeyDown(ctx, e.keyCode, {
          ctrl,
          shift,
          preventDefault: () => e.preventDefault(),
        })
      }}
      onKeyUp={(e) => {
        onKeyUp?.(e)
        const ctrl = e.ctrlKey || e.metaKey
        const shift = e.shiftKey
        action?.onKeyUp(ctx, e.keyCode, {
          ctrl,
          shift,
          preventDefault: () => e.preventDefault(),
        })
      }}
      onInit={(w, h) => {
        onInit?.(w, h)
        const { zoomData, transform, updateTransform } = ctx
        updateTransform({
          ...transform,
          zoom: computeSafeZoomFromLevel(zoomData, h, transform.pageDims[1]),
          viewDims: [w, h],
        })
      }}
      onResize={(w, h, rect) => {
        onResize?.(w, h, rect)
        const { zoomData, transform, updateTransform } = ctx
        updateTransform({
          ...transform,
          zoom: computeSafeZoomFromLevel(zoomData, h, transform.pageDims[1]),
          viewDims: [w, h],
        })
      }}
      onRender={(rctx) => {
        onRender?.(rctx)
        action?.onRender(ctx, rctx)
      }}
    />
  )
}
