import {
  getSelectionType,
  parseUserOptions,
  RowOptions,
  TimeNeedleTransformer,
} from './common'

export type GradedOptions = {
  [P in Exclude<keyof RowOptions, 'carrier' | 'stitchSize' | 'direction' | 'racking'>]: [] | [RowOptions[P]] | [RowOptions[P], RowOptions[P]]
}

export function createGradedOptions(): GradedOptions {
  return {
    speed: [],
    roller: [],
    frontStitchSize: [],
    rearStitchSize: [],
  }
}

export interface LocalGradedOptions {
  knit: GradedOptions
  xfer: GradedOptions
  local?: boolean
}

export function parseLocalGradedOptions(input: string): LocalGradedOptions | string {
  const res: LocalGradedOptions = {
    knit: createGradedOptions(),
    xfer: createGradedOptions(),
    local: false,
  }
  let opts: GradedOptions = res.knit
  let to = false
  const pushArg = (arr: number[], arg: number): string | undefined => {
    let valid = true
    switch(arr.length) {
    case 0:
      if(to) {
        valid = false
      }
      arr.push(arg)
      break
    case 1:
      if(!to) {
        valid = false
      }
      arr.push(arg)
      to = false
      break
    default:
      valid = false
      break
    }
    if(!valid) {
      return 'Invalid graded format, should be specified as: V1 to V2'
    }
  }
  const error = parseUserOptions(input, {
    knit: () => { opts = res.knit },
    xfer: () => { opts = res.knit },
    local: () => { res.local = true },
    global: () => { res.local = false },
    to: () => { to = true },
  }, {
    roller: (token: string) => pushArg(opts.roller, parseInt(token, 10)),
    speed: (token: string) => pushArg(opts.speed, parseInt(token, 10)),
    stitch: (token: string) => {
      const size = parseInt(token, 10)
      let err = pushArg(opts.frontStitchSize, size)
      if(err) { return err }
      return pushArg(opts.rearStitchSize, size)
    },
    frontStitch: (token: string) => pushArg(opts.frontStitchSize, parseInt(token, 10)),
    rearStitch: (token: string) => pushArg(opts.rearStitchSize, parseInt(token, 10)),
  }, null)
  if(error) {
    return error
  }
  return res
}

export function localGradedOptions(s: TimeNeedleTransformer, { knit, xfer, local = false }: LocalGradedOptions) {
  const [start, end] = s.getRowExtents() as [number, number]
  // only keep rows that have a knit or transfer
  const baseRows = (
    local ? s.splitByRow() : Array.from({ length: end - start + 1 }, (_, i) => s.fullCourse(i + start))
  ).filter((row) => row.some((c) => c.isKnit() || c.isTransfer()))
  if(!baseRows.length) {
    return // no row => no block
  }
  // group as blocks of rows of the same type
  const getType = (row: TimeNeedleTransformer) => {
    const { hasKnit, hasTransfer } = getSelectionType(row)
    return hasKnit ? 0 : hasTransfer ? 1 : 2
  }
  const setOpt = (opts: RowOptions, key: keyof GradedOptions, gradedOpts: GradedOptions, r: number) => {
    const vals = gradedOpts[key]
    if(vals.length === 1) {
      opts[key] = vals[0]
    } else if(vals.length === 2) {
      const [start, end] = vals
      opts[key] = Math.round(start + (end - start) * r)
    }
  }
  const groups = {
    0: new Array<TimeNeedleTransformer>(),
    1: new Array<TimeNeedleTransformer>(),
    2: new Array<TimeNeedleTransformer>(),
  }
  for(const row of baseRows) {
    const type = getType(row)
    const group = groups[type]
    group.push(row)
  }

  // apply options to each block
  for(const { rows, stepOpts } of [
    { rows: groups['0'], stepOpts: knit },
    { rows: groups['1'], stepOpts: xfer },
  ]) {
    for(let i = 0; i < rows.length; ++i) {
      // generate per-row options
      const opts = {} as RowOptions
      for(const key of [
        'speed', 'roller', 'frontStitchSize', 'rearStitchSize',
      ] as (keyof GradedOptions)[]) {
        setOpt(opts, key, stepOpts, i / (rows.length - 1))
      } // endfor key of keyof StepOptions

      // set options
      rows[i].first().options(opts)
    }
  }
}
