import { addNotification, NotificationType } from 'src/editor/notification';
import { exportFile } from 'src/common/files';
import { useAppDispatch } from 'src/hooks';
import {
  fromPanelToKnitout,
  fromPanelToKCode,
  KnitoutOptions,
  Issue,
  IssueLevel,
} from 'src/data/compiler';
import { ReadonlyTimeNeedleImage } from 'src/data/time-needle/time-needle-image';

export type ExportFunction = (tni: ReadonlyTimeNeedleImage, fname?: string) => void

export function useKnitoutExport(opts?: KnitoutOptions): ExportFunction {
  const dispatch = useAppDispatch()
  return (tni: ReadonlyTimeNeedleImage, fname = 'panel.k') => {
    const {
      lines,
      valid,
      issues,
    } = fromPanelToKnitout(tni, {minimal: false, ...opts})

    // either export file or display first error
    if(valid) {
      exportFile(fname, lines.join('\n'))
      dispatch(addNotification(
        'Knitout file exported',
        NotificationType.SUCCESS,
        2000,
      ))
    } else {
      dispatch(addNotification(
        issues[0].message,
        NotificationType.ERROR,
        2000,
      ))
    }
  }
}

export function summarizeIssues(issues: Issue[], errNoun = 'issue'): string {
  if(issues.length === 1) {
    return issues[0].message
  }
  return `'${issues[0].message}"\n and ${issues.length - 1} other ${errNoun}(s)`
}

export function useKCodeExport(): ExportFunction {
  const dispatch = useAppDispatch()
  return (tni: ReadonlyTimeNeedleImage, fname = 'command.kc') => {
    const {
      kcodeIsValid,
      output,
      issues,
    } = fromPanelToKCode(tni, { fname })

    // export file if kcode is valid
    if(kcodeIsValid) {
      exportFile('command.kc', output)
    }

    // if there are errors or warnings, display summary
    const errors = issues.filter((i) => i.level !== IssueLevel.WARNING)
    const warnings = issues.filter((i) => i.level === IssueLevel.WARNING)
    if(errors.length > 0) {
      dispatch(addNotification(
        summarizeIssues(errors, 'error'),
        NotificationType.ERROR,
        5000,
      ))
    } else if(warnings.length > 0) {
      dispatch(addNotification(
        summarizeIssues(warnings, 'warning'),
        NotificationType.WARNING,
        5000,
      ))
    } else {
      dispatch(addNotification(
        'KCode exported without issue',
        NotificationType.SUCCESS,
        3000,
      ))
    }
  }
}
