/**
 * Per-needle action code
 */
export enum NeedleCode {
  // miss codes
  MISS = 0,
  XMISS,
  // base action codes
  KNIT,
  TUCK,
  SPLT,
  DROP,
  // transfer codes
  XFER,
  XFERL1,
  XFERL2,
  XFERL3,
  XFERL4,
  XFERR1,
  XFERR2,
  XFERR3,
  XFERR4,
  // special ending code
  INVALID,
}

/**
 * Needle codes that represent carrier-free transfers
 */
export type NeedleTransferCode = (
  NeedleCode.XFER |
  NeedleCode.XFERL1 |
  NeedleCode.XFERL2 |
  NeedleCode.XFERL3 |
  NeedleCode.XFERL4 |
  NeedleCode.XFERR1 |
  NeedleCode.XFERR2 |
  NeedleCode.XFERR3 |
  NeedleCode.XFERR4
)

/**
 * Needle codes that represent a yarn action
 */
export type NeedleYarnCode = (
  NeedleCode.KNIT |
  NeedleCode.TUCK |
  NeedleCode.SPLT
)

/**
 * Needle codes that represent a carrier action
 */
export type NeedleCarrierCode = NeedleYarnCode | NeedleCode.XMISS

/**
 * The number of needle codes
 */
export const NumNeedleCodes = NeedleCode.INVALID

/**
 * Test whether a needle code corresponds to a transfer semantically
 */
export function isNeedleTransferCode(nc: NeedleCode): nc is NeedleTransferCode {
  return nc >= NeedleCode.XFER && nc <= NeedleCode.XFERR4
}

/**
 * Test whether a needle code corresponds to a carrier action
 */
export function isNeedleYarnCode(nc: NeedleCode): nc is NeedleYarnCode {
  return nc >= NeedleCode.KNIT && nc <= NeedleCode.SPLT
}

/**
 * Test whether a needle code corresponds to a carrier action
 */
export function isNeedleCarrierCode(nc: NeedleCode): nc is NeedleCarrierCode {
  return nc >= NeedleCode.XMISS && nc <= NeedleCode.SPLT
}

/**
 * List of needle codes
 */
const NeedleCodes = Array.from({ length: NumNeedleCodes }, (_, i) => i as NeedleCode)

/**
 * Convert a number into a needle code
 *
 * @returns the corresponding needle code or `NeedleCode.INVALID`
 */
export function asNeedleCode(c: number): NeedleCode {
  return NeedleCodes[c] ?? NeedleCode.INVALID
}

/**
 * Stitch code (time-needle image content)
 *
 * `MISS` / `XMISS`:
 * - miss is the default no-op
 * - xmiss assumes a carrier movement that includes its needle
 *
 * `INVALID`: a special code that represents an invalid code.
 */
export enum StitchCode {
  // no needle action
  MISS = 0,
  XMISS,
  // both sides active
  BOTH_KNIT,
  BOTH_TUCK,
  BOTH_DROP,
  FRNT_KNIT_REAR_TUCK,
  FRNT_TUCK_REAR_KNIT,
  // front side active
  FRNT_KNIT,
  FRNT_TUCK,
  FRNT_SPLIT,
  FRNT_DROP,
  FRNT_XFER,
  FRNT_XFERL1,
  FRNT_XFERL2,
  FRNT_XFERL3,
  FRNT_XFERL4,
  FRNT_XFERR1,
  FRNT_XFERR2,
  FRNT_XFERR3,
  FRNT_XFERR4,
  // rear side active
  REAR_KNIT,
  REAR_TUCK,
  REAR_SPLIT,
  REAR_DROP,
  REAR_XFER,
  REAR_XFERL1,
  REAR_XFERL2,
  REAR_XFERL3,
  REAR_XFERL4,
  REAR_XFERR1,
  REAR_XFERR2,
  REAR_XFERR3,
  REAR_XFERR4,
  // special invalid code
  INVALID,
}

/**
 * The number of valid stitch codes
 */
export const NumStitchCodes = StitchCode.INVALID as number

/**
 * List of stitch codes
 */
const StitchCodes = Array.from({ length: NumStitchCodes }, (_, i) => i as StitchCode)

/**
 * Convert a number into a stitch code
 *
 * @returns the corresponding stitch code or `StitchCode.INVALID` if invalid
 */
export function asStitchCode(c: number) {
  return StitchCodes[c] ?? StitchCode.INVALID
}
