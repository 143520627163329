import { addRows, addRowsAboveTop } from 'src/data/time-needle/topology'
import {
  getKnittingSides,
  isYarnIndex,
  KnittingSide,
  otherDir,
  parseUserOptions,
  RowOptions,
  SelectorLike,
  ShapingSide,
  SingleShapingSide,
  TimeNeedleCellReader,
  TimeNeedleTransformer,
  YarnIndex,
} from './common'

enum RelativeDirection {
  OUTWARD = 0,
  INWARD = 1,
}

export interface ShortRowOptions {
  sides: ShapingSide | [SingleShapingSide, SingleShapingSide]
  xStep: number
  width?: number
  yarn?: YarnIndex
  tuckDir: RelativeDirection
  insert: boolean
  round: (x:number) => number
}

export function parseShortRowOptions(str: string): ShortRowOptions | string {
  const opts: ShortRowOptions = {
    sides: 2,
    xStep: 4,
    tuckDir: RelativeDirection.OUTWARD,
    insert: false,
    round: Math.round,
  }
  // Short-rows: SIDES by STEP [over WIDTH] [inward] [insert] [RND]
  const error = parseUserOptions(str, {
    // simple keywords
    inward: () => { opts.tuckDir = RelativeDirection.INWARD },
    outward: () => { opts.tuckDir = RelativeDirection.OUTWARD },
    insert: () => { opts.insert = true },
    ...['round', 'ceil', 'floor'].reduce((props, key) => ({ ...props, [key]: () => { opts.round = Math[key] } }), {}),
  }, {
    // context arguments
    // - short-row side
    sides: (token: string) => {
      switch(token) {
      case 'left':
        if(opts.sides === ShapingSide.RIGHT) {
          opts.sides = [ShapingSide.RIGHT, ShapingSide.LEFT]
        } else {
          opts.sides = ShapingSide.LEFT
        }
        break
      case 'right':
        if(opts.sides === 0) {
          opts.sides = [ShapingSide.LEFT, ShapingSide.RIGHT]
        } else {
          opts.sides = ShapingSide.RIGHT
        }
        break
      case 'both':
        opts.sides = ShapingSide.BOTH
        break
      default:
        return `Invalid side token ${token}`
      }
    },
    // width
    over: (token: string) => {
      opts.width = parseInt(token, 10)
      if(opts.width <= 0 || isNaN(opts.width)) {
        return `Invalid width token ${token}`
      }
    },
    // x-step
    by: (token: string) => {
      opts.xStep = parseFloat(token)
      if(opts.xStep <= 0 || isNaN(opts.xStep)) {
        return `Invalid x-step token ${token}`
      }
    },
    // yarn
    yarn: (token: string) => {
      opts.yarn = parseInt(token, 10) as YarnIndex
      if(!isYarnIndex(opts.yarn)) {
        return `Invalid yarn token ${token}`
      }
    },
  }, 'sides')
  return error ?? opts
}

export function generateShortRows(
  s: SelectorLike,
  {
    sides, xStep, width,
    tuckDir, insert, yarn,
    round,
  }: ShortRowOptions,
) {
  const {
    left, right, top,
    width: tileWidth,
    height: tileHeight,
  } = s.getExtents()
  const tileRows = s.splitByRow()
  const tileOpts = tileRows.map((row) => row.first().getOptions()[0])
  const tileSides = tileRows.map((row) => getKnittingSides(row, 'below'))

  // measure step information
  let decrWidth: number
  let sideWidth: number
  let numSides: 1 | 2
  let firstSide: SingleShapingSide
  if(width === undefined) {
    width = tileWidth
  }
  if(sides === ShapingSide.BOTH_SIDES || Array.isArray(sides)) {
    if(Array.isArray(sides)) {
      firstSide = sides[0]
    } else {
      firstSide = ShapingSide.LEFT
    }
    decrWidth = Math.min(tileWidth, 2 * width)
    sideWidth = Math.floor(decrWidth / 2)
    numSides = 2
  } else {
    firstSide = sides
    decrWidth = Math.min(tileWidth, width)
    sideWidth = decrWidth
    numSides = 1
  }
  const numSteps = Math.floor(sideWidth / xStep) - 1
  const yStep = Math.max(tileHeight, 2) + numSides
  const numRows = numSteps * yStep

  // compute yarn if not provided
  if(yarn === undefined) {
    yarn = s.getOptions().reduce((yarn: YarnIndex, opts: RowOptions) => {
      if(yarn === undefined) {
        const y = opts.carrier
        if(isYarnIndex(y)) {
          return y
        }
      }
      return yarn
    }, yarn) ?? 3 // enforce we get some yarn
  }

  // allocate needed space
  let t: TimeNeedleTransformer
  let rowBlock: TimeNeedleTransformer
  if(insert) {
    // insert new rows above
    [t, rowBlock] = addRows(s.topmost(), 'above', numRows)
    //
  } else {
    // ensure we have enough to write on
    const missingRows = top + numRows - s.height
    if(missingRows > 0) {
      [t] = addRowsAboveTop(s, missingRows)
      rowBlock = t.fullCourses([top, top + numRows]).wales([left, right])
    } else {
      t = TimeNeedleTransformer.fromSelector(s)
      rowBlock = t.fullCourses([top, top + numRows]).wales([left, right])
    }
    // clear block
    // /!\ we do not clear outside the wale range so as to
    // allow parallel computations to be done independently
    rowBlock.miss()
  }
  const rows = rowBlock.splitByRow()
  if(rows.length !== numRows) {
    throw `Invalid number of rows: got ${rows.length}, expected ${numRows}`
  }

  // generate short-rows
  const secondSide = firstSide === ShapingSide.LEFT ? ShapingSide.RIGHT : ShapingSide.LEFT
  const firstDir: 0|1 = firstSide === ShapingSide.LEFT ? 1 : 0
  const tileDirs = Array.from({ length: Math.max(2, tileHeight) }, (_, ty) => ((firstDir + ty) % 2) as 0 | 1)
  const leftDecreasing = sides !== ShapingSide.RIGHT
  const rightDecreasing = sides !== ShapingSide.LEFT
  let copyLeft = left
  let copyRight = right
  for(let y0 = 0, i = 1; y0 < numRows; y0 += yStep, ++i) {
    // copy rows and options from tile
    // while inserting tucks where neede
    for(let r = 0, ty = 0; r < yStep; ++r, ++ty) {
      let tuckSide = -1
      if(ty === 0) {
        if(firstSide === ShapingSide.LEFT && leftDecreasing) {
          copyLeft = left + round(i * xStep)
          tuckSide = 0
        } else if(firstSide === ShapingSide.RIGHT && rightDecreasing) {
          copyRight = right - round(i * xStep)
          tuckSide = 1
        }
      } else if(ty === 1) {
        if(secondSide === ShapingSide.LEFT && leftDecreasing) {
          copyLeft = left + round(i * xStep)
          tuckSide = 0
        } else if(secondSide === ShapingSide.RIGHT && rightDecreasing) {
          copyRight = right - round(i * xStep)
          tuckSide = 1
        }
      }
      // clear row content first
      rows[y0 + r].miss()

      // copy information from
      const waleRange = [copyLeft, copyRight]
      rows[y0 + r].wales(waleRange)
        .copy(tileRows[ty].wales(waleRange))
        .options(tileOpts[ty])
        .options({ direction: tileDirs[ty] })
      // tuck row
      if(tuckSide !== -1) {
        const tuckRow = rows[y0 + r + 1]
        tuckRow.miss().first().options(tileOpts[ty])
        if(tuckDir === RelativeDirection.INWARD) {
          tuckRow.first().options({ direction: otherDir(tileDirs[ty]) })
        } else {
          tuckRow.first().options({ direction: tileDirs[ty] })
        }
        ++r

        // actual tuck
        const x = tuckSide === 0 ? copyLeft - 1 : copyRight
        const tside = tileSides[ty][x - left] === KnittingSide.FRONT ? 0 : 1
        tuckRow.wales([x, x + 1]).asCell().tuck(tside, yarn)
      }
    }
  }

  return t.union(rowBlock)
}
