import { shallowEqual } from 'react-redux'
import { RFV4 } from 'src/common/types'
import TimeNeedleTransformer, { isSelectorLike, SelectorLike } from 'src/data/time-needle/transformer'
import { useAppDispatch, useAppSelector } from 'src/hooks/redux'
import { deferAction, setSelection } from 'src/editor/time-needle/slice'
import { copyTimeNeedleImage, ReadonlyTimeNeedleImage } from 'src/data/time-needle/time-needle-image'
import { addUndoableAction } from 'src/undo'
import { UndoableSnapshotAction, updateThumbnails } from 'src/editor/time-needle/action'
import TimeNeedleSelector from 'src/data/time-needle/selector'

/**
 * Hook returning a method for applying a macro
 * using an instance of {@link TimeNeedleTransformer}
 * on a copy of the current time-needle image.
 *
 * The method `applyMacro(macroFun, updateSel)` that is returned takes
 * a macro function `macroFun` that receives a writeable {@link TimeNeedleTransformer}.
 *
 * After the macro function terminates, its result is used
 * to determine the new time-needle image:
 * - if nothing is returned, then the image of the input transformer
 * - if a transformer is returned, then its underlying image
 *
 * /!\ BEWARE!!! If you plan to modify the topology, then
 * you **must** return the corresponding new transformer
 * for it to be applied properly.
 *
 * The second argument `updateSel` determines whether
 * the user selection should be updated to match the return value.
 * This is the case by default (`true`).
 *
 * @returns whether the macro code updated the time-needle image
 */
export function useApplyMacro() {
  const dispatch = useAppDispatch()
  const {
    selection,
    tnimage,
  } = useAppSelector(({ canvas: { selection, tnimage }}) => ({ selection, tnimage }), shallowEqual)
  return (
    macroFun: (s: TimeNeedleTransformer) => TimeNeedleTransformer | void,
    updateSelection = true,
  ) => {
    const wtni = copyTimeNeedleImage(tnimage)
    const s = TimeNeedleTransformer.fromRegion(wtni, selection)
    const ret = macroFun(s)

    // used returned value (or initial writeable image otherwise) as update result
    const rtni = ret ? ret.image : s.image

    // compute snapshot action
    const undoable = UndoableSnapshotAction.from(tnimage, rtni)
    if(undoable) {
      // trigger snapshot update
      dispatch(addUndoableAction(undoable))
      dispatch(deferAction(updateThumbnails()))
    }

    // if the selection has changed, then update the seleciton too
    if(updateSelection && ret) {
      const {
        left, bottom, right, top,
      } = ret.getExtents()
      // XXX maybe this should be part of the undoable snapshot
      dispatch(setSelection([left, bottom, right, top]))
    }
    return !!undoable
  }
}

/**
 * Hook returning a method for applying a macro
 * using an instance of {@link TimeNeedleSelector}
 * on the current read-only time-needle image.
 *
 * The method `selectMacro(macroFun)` that is returned takes
 * a macro function `macroFun` that receives a readable {@link TimeNeedleSelector}.
 *
 * After the macro function terminates, its optional result
 * is used to update the current selection (if any).
 *
 * @see useApplyMacro
 * @returns the result of the selection macro
 */
export function useSelectMacro() {
  const dispatch = useAppDispatch()
  const {
    selection,
    tnimage,
  } = useAppSelector(({ canvas: { selection, tnimage }}) => ({ selection, tnimage }), shallowEqual)
  return (
    macroFun: (s: TimeNeedleSelector) => SelectorLike | void,
  ) => {
    const s = TimeNeedleSelector.fromRegion(tnimage, selection)
    const ret = macroFun(s)

    // if a selector is returned, update the selection to match it
    if(ret) {
      const {
        left, bottom, right, top,
      } = ret.getExtents()
      // XXX maybe this should be part of the undoable snapshot
      dispatch(setSelection([left, bottom, right, top]))
    }
    return ret
  }
}

/**
 * Hook returning a method for applying macro code.
 *
 * The method `rawMacro(macroFun)` that is returned takes
 * a macro function `macroFun` that receives the current time-needle image
 * and selections (both are read-only).
 *
 * After the macro function terminates, its result is used
 * to update the editor state:
 * - if a selector is returned, then the time-needle image and selection are updated as necessary
 * - if an image is returned, then the time-needle image only is upsed as necessary
 * - if nothing is returned, then no update happens
 *
 * @returns whether the macro code updated the time-needle image
 */
export function useRawMacro() {
  const dispatch = useAppDispatch()
  const {
    selection,
    tnimage,
  } = useAppSelector(({ canvas: { selection, tnimage }}) => ({ selection, tnimage }), shallowEqual)
  return (
    macroFun: (tni: ReadonlyTimeNeedleImage, sel: RFV4) => ReadonlyTimeNeedleImage | SelectorLike | void,
  ) => {
    const ret = macroFun(tnimage, selection)

    // used returned value
    const rtni = ret ? isSelectorLike(ret) ? ret.image : ret : null

    // compute snapshot action
    const undoable = rtni ? UndoableSnapshotAction.from(tnimage, rtni) : null
    if(undoable) {
      // trigger snapshot update
      dispatch(addUndoableAction(undoable))
      dispatch(deferAction(updateThumbnails()))
    }

    // if the selection has changed, then update the seleciton too
    if(isSelectorLike(ret)) {
      const {
        left, bottom, right, top,
      } = ret.getExtents()
      // XXX maybe this should be part of the undoable snapshot
      dispatch(setSelection([left, bottom, right, top]))
    }
    return !!undoable
  }
}
