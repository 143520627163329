import { shallowEqual } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import {
  showOptionsColumn,
  setColumnsPerCm, setRowsPerCm,
  setMaxFloatDistance, setStoppingDistance, setCarrierSpacing,
} from 'src/editor/settings'
import { AllowedCarrierSpacings, AllowedStoppingDistances } from 'src/data/compiler'

import 'src/styles/ribbon-settings.scss'

interface EnumSelectProps<T> {
  values: readonly T[]
  current: T
  names?: string[]
  onChange?: (newValue: T) => void
}

function EnumSelect<T extends number | string>({
  values,
  current,
  names = [],
  onChange,
}: EnumSelectProps<T>) {
  return (
    <select
      value={current}
      onChange={(e) => {
        const newValue = e.target.value
        if(typeof current === 'number') {
          onChange(parseFloat(newValue) as T)
        } else {
          onChange(newValue as T)
        }
      }}
    >
      { values.map((value, i) => (
        <option
          value={value}
          key={i}
        >{
            names[i] ?? value
          }
        </option>
      ))}
    </select>
  )
}

function Settings({ title = '' }) {
  const dispatch = useAppDispatch()
  const {
    showOptionsColumn: visibleOptionsColumn,
    columnsPerCm,
    rowsPerCm,
    maxFloatDistance,
    carrierSpacing,
    stoppingDistance,
  } = useAppSelector(({ settings }) => settings, shallowEqual)
  return (
    <div className="settings-form">
      <label className="title">Settings</label>
      <label className="section">Display options</label>
      <label className="setting">
        Show options column
        <input
          type="checkbox"
          checked={visibleOptionsColumn}
          onChange={({ target }) => dispatch(showOptionsColumn(target.checked))}
        />
      </label>
      <label className="setting">
        Columns per cm
        <input
          type="number"
          value={columnsPerCm}
          min="1"
          max="99"
          step="0.1"
          onChange={({ target }) => dispatch(setColumnsPerCm(Math.max(1, parseFloat(target.value) || 1)))}
        />
      </label>
      <label className="setting">
        Rows per cm
        <input
          type="number"
          value={rowsPerCm}
          min="1"
          max="99"
          step="0.1"
          onChange={({ target }) => dispatch(setRowsPerCm(Math.max(1, parseFloat(target.value) || 1)))}
        />
      </label>
      <label className="section">Knitout options</label>
      <label className="setting" title="Carrier spacing in needles">
        Carrier spacing
        <EnumSelect
          values={AllowedCarrierSpacings}
          current={carrierSpacing}
          onChange={(newValue) => {
            dispatch(setCarrierSpacing(newValue))
          }}
        />
      </label>
      <label className="setting" title="Carrier stopping distance">
        Stopping distance
        <EnumSelect
          values={AllowedStoppingDistances}
          current={stoppingDistance}
          onChange={(newValue) => {
            dispatch(setStoppingDistance(newValue))
          }}
        />
      </label>
      <label className="section">Validation options</label>
      <label className="setting">
        Max float distance
        <input
          type="number"
          value={maxFloatDistance}
          min="2"
          max="252"
          step="0.5"
          onChange={({ target }) => dispatch(setMaxFloatDistance(parseFloat(target.value) || 2))}
        />
      </label>
    </div>
  )
}

export default Settings
