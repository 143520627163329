import TimeNeedleTransformer from 'src/data/time-needle/transformer';

export function mirror(s: TimeNeedleTransformer) {
  const rows = s.splitByRow()
  for(const row of rows) {
    const N = row.length
    const H = Math.floor(N / 2)
    for(let l = 0, r = N - 1; l < H; ++l, --r) {
      const left = row.getCell(l)
      const right = row.getCell(r)
      left.swapWith(right)
      left.mirror() // in-place stitch mirroring
      right.mirror() // in-place stitch mirroring
    }
  }
}
