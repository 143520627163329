/*
Code adapted from C for TypeScript from here:
https://rosettacode.org/wiki/Color_quantization

Based on the implementation in Graphics Gems:
https://www.academia.edu/14281934/A_simple_method_for_color_quantization_octree_quantization
 */

import { colorToRGBString } from 'src/common/math/Color';
import { CanvasImage } from './image';

type rgbStringPalette = [string, string, string, string, string, string];

interface Node {
    r: number;
    g: number;
    b: number;
    count: number;
    idx: number;
    childCount: number;
    childIdx: number;
    inHeap: boolean;
    depth: number;
    children: [Node, Node, Node, Node, Node, Node, Node, Node];
    parent: Node;
}

function makeNode(
  r?: number,
  g?: number,
  b?: number,
  count?: number,
  idx?: number,
): Node {
  return {
    r: r || 0,
    g: g || 0,
    b: b || 0,
    count: count || 0,
    idx: idx || 0,
    childCount: 0,
    childIdx: 0,
    inHeap: false,
    depth: 0,
    children: [null, null, null, null, null, null, null, null],
    parent: null,
  };
}

interface Heap {
    length: number;
    buffer: Node[];
}

function makeHeap(count?: number): Heap {
  return {
    length: 0,
    buffer: new Array<Node>(count || 0),
  };
}

/*
Compare the two nodes:

The node with the highest number of pixels wins or, in the event of a tie
the highest count at the lowest depth wins.
 */

function cmpNode(A: Node, B: Node): number {
  if(A.childCount < B.childCount) return -1;
  if(A.childCount > B.childCount) return 1;

  const AC = A.count >> A.depth;
  const BC = B.count >> B.depth;

  return AC < BC ? -1 : (AC > BC ? 1 : 0);
}

// Note: Modifies curr & heap (based on C code pointers)
function downHeap(heap: Heap, curr: Node): void {
  let n = curr.idx; let
    m;
  while(true) {
    m = n * 2;
    if(m >= heap.length) break;
    if(m + 1 < heap.length && cmpNode(heap.buffer[m], heap.buffer[m + 1]) > 0) m++;
    if(cmpNode(curr, heap.buffer[m]) <= 0) break;
    heap.buffer[n] = heap.buffer[m];
    heap.buffer[n].idx = n;
    n = m;
  }
  heap.buffer[n] = curr;
  curr.idx = n;
}

function upHeap(heap: Heap, curr: Node): void {
  let n = curr.idx;
  let prev: Node = null;

  while(n > 1) {
    prev = heap.buffer[Math.floor(n / 2)];
    if(cmpNode(curr, prev) >= 0) break;
    heap.buffer[n] = prev;
    prev.idx = n;
    n = Math.floor(n / 2);
  }
  heap.buffer[n] = curr;
  curr.idx = n;
}

function heapAdd(heap: Heap, curr: Node): void {
  if(curr.inHeap) {
    downHeap(heap, curr);
    upHeap(heap, curr);
    return;
  }

  curr.inHeap = true;
  if(heap.length === 0) heap.length = 1;
  curr.idx = heap.length;
  heap.buffer[curr.idx] = curr;
  heap.length++;
  upHeap(heap, curr);
}

function popHeap(heap: Heap): Node {
  if(heap.length <= 1) return null;

  const ret = heap.buffer[1];
  heap.buffer[1] = heap.buffer[--heap.length];
  heap.buffer[heap.length] = null;
  heap.buffer[1].idx = 1;
  downHeap(heap, heap.buffer[1]);
  return ret;
}

let pool: Node[] = null;
let len = 0;

function newNode(idx: number, depth: number, parent: Node = null, reserve?: number): Node {
  if(!pool) {
    pool = new Array<Node>(reserve || 2048);
    pool[0] = makeNode();
    pool[0].parent = null;
    len = reserve ? reserve - 1 : 2047;
  }

  const newNode = makeNode();
  newNode.childIdx = idx;
  newNode.depth = depth;
  newNode.parent = parent;
  if(parent) parent.childCount++;
  pool[len--] = newNode;
  return newNode;
}

/*
insertNode will index the colour vector by examining each bit of each component
of the vector and using this the bit to select the right branch of the octree.
 */

function insertNode(root: Node, px: Uint8Array): Node {
  let i: number; let bit: number; let
    depth = 0;
  for(bit = 1 << 7; ++depth < 8; bit >>= 1) {
    // Green is bit 4, Red bit 2, and Blue bit 1 for 8 different values
    i = (px[1] & bit ? 4 : 0) + (px[0] & bit ? 2 : 0) + (px[2] & bit ? 1 : 0);
    if(!root.children[i]) root.children[i] = newNode(i, depth, root);
    root = root.children[i];
  }

  // Add the pixel to the total for averaging later
  root.r += px[0];
  root.g += px[1];
  root.b += px[2];
  root.count++;
  return root;
}

function nodeFold(curr: Node): Node {
  if(curr.childCount) {
    // Cannot fold a non-leaf node!
    console.log('Current Node:', curr);
    throw { err: 'No!' };
  }
  const n = curr.parent;
  n.count += curr.count;

  n.r += curr.r;
  n.g += curr.g;
  n.b += curr.b;
  n.childCount--;
  n.children[curr.childIdx] = null;
  return n;
}

function replaceColor(root: Node, px: Uint8Array): void {
  let i: number; let
    bit: number;
  for(bit = 1 << 7; bit; bit >>= 1) {
    i = (px[1] & bit ? 4 : 0) + (px[0] & bit ? 2 : 0) + (px[2] & bit ? 1 : 0);
    if(!root.children[i]) break;
    root = root.children[i];
  }

  px[0] = root.r;
  px[1] = root.g;
  px[2] = root.b;
}

export function quantizeImage(img: CanvasImage, colorCount: number, dither: boolean): [rgbStringPalette, Uint8Array[]] {
  const T = img.width * img.height;
  const heap = makeHeap(T);
  const root = newNode(0, 0, null, T); let
    ret;
  for(let i = 0; i !== T; ++i) {
    heapAdd(heap, insertNode(root, img.data.slice(3 * i, 3 * i + 3)));
  }

  while(heap.length > colorCount + 1) {
    heapAdd(heap, nodeFold(popHeap(heap)));
  }

  let c: number;
  for(let i = 1; i !== heap.length; ++i) {
    ret = heap.buffer[i];
    c = ret.count;
    ret.r = ret.r / c + 0.5;
    ret.g = ret.g / c + 0.5;
    ret.b = ret.b / c + 0.5;
  }

  const colArr = new Array<[Uint8Array, number]>();

  const cacheColor = (px: Uint8Array): boolean => {
    for(let i = 0; i !== colArr.length; ++i) {
      if(colArr[i][0][0] === px[0] && colArr[i][0][1] === px[1] && colArr[i][0][2] === px[2]) {
        colArr[i][1]++;
        return true
      }
    }
    colArr.push([px, 0]);
    return false;
  };

  for(let i = 0; i !== T; ++i) {
    const px = img.data.slice(3 * i, 3 * i + 3);
    replaceColor(root, px);
    img.data.set(px, 3 * i);
    cacheColor(px);
  }

  const colorTbl: rgbStringPalette = [null, null, null, null, null, null];

  colArr.sort((a, b): number => b[1] - a[1]);

  // This is (an) optimal order for the spools
  const sortOrder = [2, 3, 1, 4, 0, 5];

  for(let i = 0; i !== colArr.length && i !== 6; ++i) {
    colorTbl[sortOrder[i]] = colorToRGBString(colArr[i][0]);
  }

  return [colorTbl, colArr.map((val) => val[0])];
}
