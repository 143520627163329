import { isYarnIndex } from 'src/data/time-needle/options'
import { useSelectMacro, useUserEntry } from 'src/hooks'
import ContextMenuItem from './menu-item'

export default function QueryMenu() {
  const selectMacro = useSelectMacro()
  const [lastYarn, setLastYarn] = useUserEntry('yarn', '3')
  const [lastDir, setLastDir] = useUserEntry('dir', 'ltr')
  return (
    <ContextMenuItem
      name="Query"
      menu
    >
      <ContextMenuItem
        name="Yarn in panel"
        prompt={['Yarn [1-6] to find:', lastYarn]}
        onInput={(yarnStr) => {
          const yarn = parseInt(yarnStr, 10)
          if(!isYarnIndex(yarn)) {
            alert('Invalid yarn')
            return
          }
          setLastYarn(yarnStr)
          selectMacro((s) => {
            const sel = s.all().filter((c) => (c.getPattern() === yarn))
            return sel.isEmpty() ? void 0 : sel
          })
        }}
        chainable
      />
      <ContextMenuItem
        name="Yarn in selection"
        prompt={['Yarn [1-6] to find:', lastYarn]}
        onInput={(yarnStr) => {
          const yarn = parseInt(yarnStr, 10)
          if(!isYarnIndex(yarn)) {
            alert('Invalid yarn')
            return
          }
          setLastYarn(yarnStr)
          selectMacro((s) => {
            const sel = s.filter((c) => (c.getPattern() === yarn))
            return sel.isEmpty() ? void 0 : sel
          })
        }}
        chainable
      />
      <ContextMenuItem
        name="Direction in panel"
        prompt={['User direction [ltr, rtl, any]:', lastDir]}
        onInput={(dirStr) => {
          const dir = ['ltr', 'rtl', 'any'].indexOf(dirStr)
          if(dir === -1) {
            alert('Invalid user direction')
            return
          }
          setLastDir(dirStr)
          selectMacro((s) => {
            const rows = s.all().getOptions().flatMap((opts, row) => (opts.direction === dir ? [row] : []))
            return rows.length ? s.fullCourses([rows[0], rows[rows.length - 1] + 1]) : void 0
          })
        }}
        chainable
      />
      <ContextMenuItem
        name="Direction in selection"
        prompt={['User direction [ltr, rtl, any]:', lastDir]}
        onInput={(dirStr) => {
          const dir = ['ltr', 'rtl', 'any'].indexOf(dirStr)
          if(dir === -1) {
            alert('Invalid user direction')
            return
          }
          setLastDir(dirStr)
          selectMacro((s) => {
            const [fromRow = 0] = s.getRowExtents()
            const rows = s.getOptions().flatMap((opts, row) => (opts.direction === dir ? [fromRow + row] : []))
            return rows.length ? s.fullCourses([rows[0], rows[rows.length - 1] + 1]) : void 0
          })
        }}
        chainable
      />
      <ContextMenuItem
        name="Count knits"
        onClick={() => {
          selectMacro((s) => {
            const n = s.reduce((sum, c) => (c.isKnit() ? sum + 1 : sum), 0)
            alert(`Knits: ${n}`)
          })
        }}
        chainable
      />
      <ContextMenuItem
        name="Count knitting rows"
        onClick={() => {
          selectMacro((s) => {
            const { left, width } = s.getExtents()
            const [max] = s.reduce(([max, perWaleCount], c) => {
              const idx = c.column - left
              if(c.isKnit() || c.isTuck() || c.isKnitTuck() || c.isTuckKnit() || c.isSplit()) {
                perWaleCount[idx] += 1
                return [
                  Math.max(max, perWaleCount[idx]),
                  perWaleCount,
                ]
              }
              return [max, perWaleCount]
            }, [0, Array.from({ length: width }, () => 0)])
            alert(`Knitting rows: ${max}`)
          })
        }}
        chainable
      />
    </ContextMenuItem>
  )
}
