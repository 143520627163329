import { useEffect, useState } from 'react'
import {
  combinedAR,
  DetailLevel,
  makeProjectionMatrix,
  ProjectionMatrix,
  resetZoomData,
  RFV2,
  RFV4,
  TransformData,
} from 'src/common/math'
import { LocalTextureFunc, TextureData } from 'src/common/webgl/texture'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { AppDispatch } from 'src/store'
import { ActionContext, ActionHandler } from './action'
import {
  CanvasKeys,
  CanvasState,
  CanvasStateEntry,
  CursorMode,
  getDefaultState,
  setCanvasState,
} from './slice'
import { WebGLDataProps } from './canvas'

export enum Source {
  LOCAL = 0,
  REDUX,
  ARGUMENT,
}

export type CanvasStateConfig = {
  [K in keyof CanvasState]?: Source
}

export function createConfig(
  baseConf: CanvasStateConfig,
  defSrc: Source,
) {
  const conf: CanvasStateConfig = {
    ...baseConf,
  }
  for(const key of CanvasKeys) {
    if(!(key in conf)) {
      conf[key] = defSrc
    }
  }
  return conf
}

export type CanvasArgs = {
  [K in keyof CanvasState]?: CanvasState[K]
}

export type CanvasSetter<K extends keyof CanvasState> = (
  (newVal: CanvasState[K]) => void
)

export function useConfState<K extends keyof CanvasState>(
  key: K,
  config: CanvasStateConfig,
  args: CanvasArgs,
): [CanvasState[K], CanvasSetter<K>] {
  const source = config[key]
  const arg = args[key]
  // rely on argument if available
  if(arg !== undefined) {
    if(source === Source.ARGUMENT) {
      return [
        arg as CanvasState[K], // XXX why is typescript uncertain here?
        () => {},
      ]
    }
    console.error(`Passed argument ${key}, but source is ${Source[source]}`)
  } else if(source === Source.ARGUMENT) {
    console.error(`Argument ${key} is missing, will use local data`)
  }
  // otherwise either local or redux
  if(source === Source.REDUX) {
    const dispatch = useAppDispatch()
    const val = useAppSelector((state) => state.canvas[key])
    return [
      val,
      (newVal) => {
        dispatch(setCanvasState([key, newVal] as CanvasStateEntry))
      },
    ]
  }
  // use local state information
  return useState<CanvasState[K]>(() => getDefaultState(key))
}

export type TransformUpdateHandler = (
  (lastTrans: TransformData, newTrans: TransformData, newM: ProjectionMatrix, dispatch: AppDispatch) => void
)

export function useCanvasState(
  conf: CanvasStateConfig,
  args: CanvasArgs = {},
  onTransformChange?: TransformUpdateHandler,
): [WebGLDataProps, ActionContext, ActionHandler] {
  // general data
  const aspectRatio = useAppSelector(({ settings }) => settings.aspectRatio)
  const dispatch = useAppDispatch()
  const [tnimage] = useConfState('tnimage', conf, args)
  const [activeView] = useConfState('activeView', conf, args)
  const [activeStitch] = useConfState('activeStitch', conf, args)
  const [mouseCoord, setMouseCoord] = useConfState('mouseCoord', conf, args)
  const [selection, setSelection] = useConfState('selection', conf, args)
  const [selectMode, setSelectMode] = useConfState('selectMode', conf, args)
  const [zoomData, setZoomData] = useConfState('zoomData', conf, args)
  const [offset, setOffset] = useConfState('offset', conf, args)
  const [zoom, setZoom] = useConfState('zoom', conf, args)
  const [configData] = useConfState('configData', conf, args)
  const [errorData] = useConfState('errorData', conf, args)
  const [markerData, setMarkerData] = useConfState('markerData', conf, args)
  const [pasteData, setPasteData] = useConfState('pasteData', conf, args)
  const [[previewView, previewData], setPreviewData] = useConfState('previewData', conf, args)
  const [action, setAction] = useConfState('action', conf, args)
  const [isEditing, setEditing] = useConfState('isEditing', conf, args)

  // transformation (partially derived)
  const [transform, setTransform] = useConfState('transform', conf, args)
  const [M, setProjectionMatrix] = useConfState('M', conf, args)

  // derived
  const [combAR, setCombinedAR] = useState<number>(() => combinedAR(transform))
  const updateTransform = (
    newTrans: TransformData,
    newM = makeProjectionMatrix(newTrans),
  ) => {
    setTransform(newTrans)
    setCombinedAR(combinedAR(newTrans))
    setProjectionMatrix(newM)
    if(transform.pageDims[1] !== newTrans.pageDims[1]) {
      setZoomData(resetZoomData(zoomData, newTrans.pageDims[1]))
    }
    if(zoom !== newTrans.zoom) setZoom(newTrans.zoom)
    if(offset !== newTrans.offset) setOffset(newTrans.offset)
    onTransformChange?.(transform, newTrans, newM, dispatch)
    return newM
  }

  // webgl data
  const props: WebGLDataProps = {
    // uniforms
    viewMode: activeView,
    viewZoom: transform.zoom,
    pageOffset: transform.offset,
    pageDims: transform.pageDims,
    selectAABB: (
      selectMode === CursorMode.PASTING ?
        [0, 0, pasteData.width, pasteData.height] :
        selection
    ),
    selectMode,
    mouseCoord,
    configData,
    errorData,
    markerData,
    combinedAR: combAR,
    detailLevel: zoomData.detailLevel ?? 1 as DetailLevel,
    // panel data
    cdata: tnimage?.cdata,
    odata: tnimage?.odata,
    pasteData,
    previewData, // : activeView === previewView ? previewData : null,
  }

  // transformation update from redux
  const panelWidth = tnimage?.cdata.width ?? transform.pageDims[0]
  const panelHeight = tnimage?.cdata.height ?? transform.pageDims[1]
  useEffect(() => {
    if(
      aspectRatio !== transform.cellAR ||
      panelWidth !== transform.pageDims[0] ||
      panelHeight !== transform.pageDims[1] ||
      zoom !== transform.zoom ||
      offset[0] !== transform.offset[0] ||
      offset[1] !== transform.offset[1]
    ) {
      // update transform from settings
      updateTransform({
        ...transform,
        offset,
        zoom,
        pageDims: [panelWidth, panelHeight],
        cellAR: aspectRatio,
      })
    }
  }, [aspectRatio, panelWidth, panelHeight, zoom, offset, transform])

  // action context
  const ctx: ActionContext = {
    activeView,
    activeStitch,
    tnimage,
    selection,
    transform,
    M,
    zoomData,
    clearSelection: (clrMode = true) => {
      setSelection([0, 0, 0, 0])
      if(clrMode) setSelectMode(CursorMode.DEFAULT)
    },
    setMouseCoord,
    setAction,
    updateEditing: (b: boolean) => {
      if(b !== isEditing) {
        setEditing(b)
      }
    },
    updateTransform,
    updateSelection: (sel: RFV4, selMode = true) => {
      setSelection(sel)
      if(selMode) setSelectMode(CursorMode.SELECTING)
    },
    updateZoomData: setZoomData,
    updatePasteData: (data: TextureData) => {
      setPasteData(data)
      if(data) {
        setSelectMode(CursorMode.PASTING)
      } else if(selectMode === CursorMode.PASTING) {
        setSelectMode(CursorMode.DEFAULT)
        setSelection([0, 0, 0, 0])
      }
    },
    // /!\ wrapping is necessary because
    // useState's dispatches have a lazy functional variant
    updatePreviewData: (arg: LocalTextureFunc) => {
      setPreviewData([activeView, arg])
    },
    updateMarkerData: setMarkerData,
    toggleMarker: ([x, y]: RFV2) => {
      if(markerData[0]) {
        setMarkerData([0, 0, 0])
      } else {
        setMarkerData([1, x, y])
      }
    },
    dispatch,
  }

  // action transition
  const [lastAction, setLastAction] = useState<ActionHandler>(null)
  useEffect(() => {
    if(action !== lastAction) {
      setLastAction(action)
      // action transition
      lastAction?.onActionChange(ctx, action)
    }
  })

  return [props, ctx, action]
}
