import {
  DefaultPaletteTexture,
} from 'src/data/time-needle/palette'
import {
  StitchCodeToColorTexture,
  StitchCodeToGlyphTexture,
} from 'src/data/time-needle/stitch-code'
import { initTexture, loadTextureFromURL } from 'src/common/webgl/texture'
import { WebGLXRenderingContext } from 'src/common/webgl/types'

// texture data
import stitchAtlas from 'src/assets/stitchAtlas.png';

export function emptyData() {
  return {
    width: 1,
    height: 1,
    channels: 1,
    data: new Uint8Array([0]),
  } as const
}

export function allocateTextures(gl: WebGLXRenderingContext) {
  // dummy texture data for allocation
  const initData = emptyData()
  return {
    atlasTex: loadTextureFromURL(gl, stitchAtlas),
    pasteTex: initTexture(gl, initData, null),
    stitchTex: initTexture(gl, initData, null),
    optColTex: initTexture(gl, initData, null),
    dataTex: initTexture(gl, initData, null),
    stitchColorTex: initTexture(
      gl,
      StitchCodeToColorTexture,
      null,
      'stitch-to-color',
    ),
    stitchGlyphTex: initTexture(
      gl,
      StitchCodeToGlyphTexture,
      null,
      'stitch-to-glyph',
    ),
    yarnColorTex: initTexture(
      gl,
      DefaultPaletteTexture,
      null,
      'yarn-color',
    ),
  }
}

export type TextureMap = ReturnType<typeof allocateTextures>
