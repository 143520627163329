import { clamp } from 'src/common/math'
import { IdentityMapping, IndexMapping } from './selector'
import {
  deleteColumnSet,
  deleteRows as deleteTimeNeedleRows,
  deleteRowSet,
  insertRowSet,
} from './time-needle-image'
import TimeNeedleTransformer, { SelectorLike } from './transformer'

/**
 * The row insertion side
 */
export type RowSide = 'above' | 'below'

/**
 * Add a number of rows above or below each selected row
 * of a given selector / transformer.
 *
 * /!\ This generates a transformer pair, both of which
 * are bound to a new time-needle image.
 *
 * @param s the selector to modify
 * @param side either `'above'` or `'below'`
 * @param numRows the number of rows to add or a tiling of numbers from bottom to top
 * @returns `[newSel, newRows, indexMap]`
 */
export function addRows(
  s: SelectorLike,
  side: RowSide,
  numRows: number | number[],
): [TimeNeedleTransformer, TimeNeedleTransformer, IndexMapping] {
  // compute the row insertion sequence
  const srcRowSeq = Array.from(s.getRowSet()).sort(
    (a, b) => a - b,
  ).flatMap((ridx, i) => {
    const num = Array.isArray(numRows) ? numRows[i % numRows.length] : numRows
    return num > 0 ? [[side === 'above' ? ridx + 1 : ridx, num] as const] : []
  })

  // no-row-added edge case
  if(srcRowSeq.length === 0) {
    const t = TimeNeedleTransformer.fromSelector(s)
    return [t, t.empty(), IdentityMapping]
  }

  // compute the new image after insertion
  const newImg = insertRowSet(s.image, srcRowSeq)

  // compute row mapping and the set of new rows
  const oldRowToNewRow = Array.from({ length: s.height }, (_, i) => i)
  const newRowSet = new Set<number>()
  for(let r = 0, i = 0; r < oldRowToNewRow.length; ++r) {
    const [rowIdx, numRows] = srcRowSeq[i] ?? [Infinity, 0]
    const last = r > 0 ? oldRowToNewRow[r - 1] : -1
    if(r === rowIdx) {
      oldRowToNewRow[r] = last + 1 + numRows
      // keep record of new rows
      for(let nr = 0; nr < numRows; ++nr) {
        newRowSet.add(last + 1 + nr)
      }
      // switch to next row insertion
      ++i
    } else {
      oldRowToNewRow[r] = last + 1
    }
  }
  // add new rows in case the last insertion happens
  // completely above the image
  const [lastIdx, lastNum] = srcRowSeq[srcRowSeq.length - 1]
  if(lastIdx === s.height) {
    for(let i = 0; i < lastNum; ++i) {
      newRowSet.add(newImg.cdata.height - 1 - i)
    }
  }

  // return new transformers
  const transformer = TimeNeedleTransformer.from(newImg)
  const indexMap = (idx: number) => {
    const oldRow = Math.floor(idx / s.width)
    const newRow = oldRowToNewRow[oldRow]
    return idx + (newRow - oldRow) * s.width
  }
  return [
    // old selection in new transformer
    transformer.withSelection(s.selection.map(indexMap)),
    // selection of new rows
    transformer.filter((c) => newRowSet.has(c.row)),
    // index mapping
    indexMap,
  ]
}

/**
 * Add a number of rows above the topmost row of the selection
 *
 * @param s the selector to modify
 * @param numRows the number of rows to add
 * @returns `[newSel, newRows, indexMap]`
 * @see {@link addRows}
 */
export function addRowsAboveTop(
  s: SelectorLike,
  numRows: number,
): [TimeNeedleTransformer, TimeNeedleTransformer, IndexMapping] {
  // catch empty edge case
  if(s.isEmpty()) {
    const t = TimeNeedleTransformer.fromSelector(s)
    return [t, t, IdentityMapping]
  }
  const rows = s.splitByRow()
  const [newSel, newRows, map] = addRows(rows[rows.length - 1], 'above', numRows)
  return [
    newSel.union(rows.slice(0, -1)),
    newRows,
    map,
  ]
}

/**
 * Add a number of rows below the bottommost row of the selection
 *
 * @param s the selector
 * @param numRows the number of rows to add
 * @returns `[newSel, newRows, indexMap]`
 * @see {@link addRows}
 */
export function addRowsBelowBottom(
  s: SelectorLike,
  numRows: number,
): [TimeNeedleTransformer, TimeNeedleTransformer, IndexMapping] {
  // catch empty edge case
  if(s.isEmpty()) {
    const t = TimeNeedleTransformer.fromSelector(s)
    return [t, t, IdentityMapping]
  }
  const [firstRow, ...remRows] = s.splitByRow()
  const [newFirstRow, newRows, map] = addRows(firstRow, 'below', numRows)
  return [
    newFirstRow.union(remRows.map((row) => new TimeNeedleTransformer(
      newFirstRow.image,
      // shift selection upward by the new number of rows
      row.selection.map((idx) => idx + numRows * row.width),
    ))),
    newRows,
    map,
  ]
}

/**
 * Delete all the selected columns of a selector
 *
 * This method returns a new transformer with the new image
 * and an empty selection.
 *
 * @param s the selector
 */
export function deleteColumns(s: SelectorLike) {
  if(s.isEmpty()) {
    return TimeNeedleTransformer.fromSelector(s)
  }
  const colSet = s.getColumnSet()
  const newImg = deleteColumnSet(s.image, colSet)
  return new TimeNeedleTransformer(newImg, new Uint32Array())
}

/**
 * Delete all the selected rows and return a new transformer
 * with the new image and an empty selection.
 *
 * @param s the selector
 */
export function deleteRows(s: SelectorLike) {
  if(s.isEmpty()) {
    return TimeNeedleTransformer.fromSelector(s)
  }
  const rowSet = s.getRowSet()
  const newImg = deleteRowSet(s.image, rowSet)
  return new TimeNeedleTransformer(newImg, new Uint32Array())
}

/**
 * Delete a number of rows above the top of the selection
 * and return a new transformer for the new image, while
 * keeping the same selection.
 *
 * If the number of rows `numRows` is negative, it is replaced
 * with
 * ```
 * height - lastRow + numRows
 * ```
 *
 * Thus, the default value `-1` removes all rows above
 * the current seleciton.
 *
 * A value of `-2` would remove all rows but the last.
 *
 * @param s the selector
 * @param numRows the number of rows to delete
 * @returns the new image's transformer with same selection
 */
export function deleteRowsAbove(s: SelectorLike, numRows = -1) {
  // empty selection case
  if(s.isEmpty()) {
    return TimeNeedleTransformer.fromSelector(s) // do nothing
  }

  // get latest row
  const { row: lastRow } = s.topmost().last().asCell()
  const fromRow = lastRow + 1
  if(fromRow === s.height) {
    return TimeNeedleTransformer.fromSelector(s) // nothing to remove
  }

  // normalize number of rows
  if(numRows < 0) {
    numRows = s.height - lastRow + numRows
  }

  // clamp to meaningful values
  numRows = clamp(numRows, 0, s.height - fromRow)

  // if empty, then do nothing
  if(numRows === 0) {
    return TimeNeedleTransformer.fromSelector(s)
  }

  // delete the requested rows
  const newImg = deleteTimeNeedleRows(s.image, fromRow, numRows)
  return new TimeNeedleTransformer(newImg, s.selection)
}

/**
 * Delete a number of rows below the selection
 *
 * If the number of rows `numRows` is negative,
 * it is replaced with
 * ```
 * firstRow + 1 + numRows
 * ```
 *
 * Thus, the default value `-1` effectively
 * deletes all the rows below the current selection.
 *
 * @param numRows the number of rows to delete
 * @returns
 */
export function deleteRowsBelow(
  s: SelectorLike,
  numRows = -1,
) {
  if(s.isEmpty()) {
    return TimeNeedleTransformer.fromSelector(s) // do nothing
  }
  // get first row
  const { row: firstRow } = s.first().asCell()
  if(firstRow === 0) {
    return TimeNeedleTransformer.fromSelector(s) // nothing to remove
  }

  // normalize rows
  if(numRows < 0) {
    numRows = firstRow + 1 + numRows
  }

  // lower + upper bound on number one can delete below
  numRows = clamp(numRows, 0, firstRow)
  if(numRows === 0) {
    return TimeNeedleTransformer.fromSelector(s) // still nothing after normalization
  }

  // measure deletion row start
  const fromRow = Math.max(firstRow - numRows, 0)

  // measure number of actually deleted rows
  console.assert(numRows <= firstRow, 'Deletion row range is invalid')

  // compute new image
  const newImg = deleteTimeNeedleRows(s.image, fromRow, numRows)

  // return new transformer with remapped selection
  const idxOffset = numRows * s.width
  return new TimeNeedleTransformer(
    newImg,
    s.selection.map((idx) => idx - idxOffset),
  )
}
