import { ReadonlyCanvasImage } from 'src/data/image';
import { createCanvasImage } from 'src/data/image/image';
import { NumStitchCodes, StitchCode } from './codes';

// glyph color
const BLK = 0x00
const WHT = 0xFF

const both = (f: number, r = f) => [f, BLK, r, BLK] as const
const frnt = (n: number, c = BLK) => [n, c, 0, BLK] as const
const rear = (n: number, c = BLK) => [0, BLK, n, c] as const

/**
 * The raw mapping from stitch code to glyph information
 */
const StitchToGlyphsAndColors = {
  // miss codes
  [StitchCode.MISS]: both(0),
  [StitchCode.XMISS]: both(4), // [4, BLK, 4, BLK],
  // both-side actions
  [StitchCode.BOTH_KNIT]: both(5), // [5, BLK, 5, BLK],
  [StitchCode.BOTH_TUCK]: both(6), // [6, BLK, 6, BLK]
  [StitchCode.BOTH_DROP]: both(3),
  [StitchCode.FRNT_KNIT_REAR_TUCK]: both(5, 6),
  [StitchCode.FRNT_TUCK_REAR_KNIT]: both(6, 5),
  // front-side actions
  [StitchCode.FRNT_KNIT]: frnt(5),
  [StitchCode.FRNT_TUCK]: frnt(6),
  [StitchCode.FRNT_SPLIT]: frnt(10),
  [StitchCode.FRNT_DROP]: frnt(3),
  [StitchCode.FRNT_XFER]: frnt(8),
  [StitchCode.FRNT_XFERL1]: frnt(12),
  [StitchCode.FRNT_XFERL2]: frnt(13),
  [StitchCode.FRNT_XFERL3]: frnt(14, WHT),
  [StitchCode.FRNT_XFERL4]: frnt(15, WHT),
  [StitchCode.FRNT_XFERR1]: frnt(16),
  [StitchCode.FRNT_XFERR2]: frnt(17),
  [StitchCode.FRNT_XFERR3]: frnt(18, WHT),
  [StitchCode.FRNT_XFERR4]: frnt(19, WHT),
  // rear-side actions
  [StitchCode.REAR_KNIT]: rear(5),
  [StitchCode.REAR_TUCK]: rear(6),
  [StitchCode.REAR_SPLIT]: rear(10),
  [StitchCode.REAR_DROP]: rear(3),
  [StitchCode.REAR_XFER]: rear(8),
  [StitchCode.REAR_XFERL1]: rear(20),
  [StitchCode.REAR_XFERL2]: rear(21),
  [StitchCode.REAR_XFERL3]: rear(22, WHT),
  [StitchCode.REAR_XFERL4]: rear(23, WHT),
  [StitchCode.REAR_XFERR1]: rear(24),
  [StitchCode.REAR_XFERR2]: rear(25),
  [StitchCode.REAR_XFERR3]: rear(26, WHT),
  [StitchCode.REAR_XFERR4]: rear(27, WHT),
} as { [code in StitchCode]: [number, number, number, number] }

/**
 * The texture data holding the conversion table
 * from stitch code to glyph information.
 * The channels are, in order:
 * - [0] = front glyph
 * - [1] = front color
 * - [2] = rear glyph
 * - [3] = rear color
 */
export const StitchCodeToGlyphTexture: ReadonlyCanvasImage<4> = createCanvasImage(
  NumStitchCodes,
  1,
  4,
  Array.from({ length: NumStitchCodes }).flatMap(
    (_, sc) => StitchToGlyphsAndColors[sc],
  ),
)
