import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum NotificationType {
  SUCCESS = 0,
  INFO,
  WARNING,
  ERROR,
}

export interface Notification {
  message: string
  type: NotificationType
  duration: number
  source?: string
  time: number
}

const initialState = {
  current: null as Notification,
  future: new Array<Notification>(),
}

const notifySlice = createSlice({
  name: 'notify',
  initialState,
  reducers: {
    addNotification: {
      reducer(state, { payload }: PayloadAction<Notification>) {
        return {
          ...state,
          current: state.current ?? payload,
          future: state.future.concat([payload]),
        }
      },
      prepare(
        message: string,
        type: NotificationType,
        duration = 3000,
        source?: string,
      ) {
        return {
          payload: {
            message,
            type,
            duration,
            source,
            time: Date.now(),
          },
        }
      },
    },
    dismissNotification: {
      reducer(state, { payload }: PayloadAction<Notification>) {
        if(payload && state.current !== payload) {
          return state
        }
        return {
          ...state,
          current: state.future[1],
          future: state.future.slice(1),
        }
      },
      prepare(payload: Notification = null) {
        return { payload }
      },
    },
  },
})

export type NotificationState = typeof initialState

export const {
  addNotification,
  dismissNotification,
} = notifySlice.actions

export default notifySlice.reducer
