import Jimp from 'jimp';
import { eq, parseRGBString } from 'src/common/math';
import { CanvasImage } from './image';
import { quantizeImage } from './quantize-image';
import { adjustBC, convertToGrayscale } from './utils';

type Palette = [string, string, string, string, string, string];
type quantizeCallback = (
    base64: string,
    imgInfo: ImageInfo,
    img: CanvasImage,
    colors: Palette,
    colorCount: number
) => void;

interface ImageInfo {
  width: number;
  height: number;
  channels: number;
  mimeType: string; // The Jimp MIME type string
}

function makeImageInfo(
  width: number,
  height: number,
  channels: number,
  mimeType: string,
): ImageInfo {
  return {
    width,
    height,
    channels,
    mimeType,
  };
}

function makeCanvasImage(
  data: Uint8Array,
  width: number,
  height: number,
  channels: number,
): CanvasImage {
  return {
    data, width, height, channels,
  }
}

/*
This function takes an input image, resizes it to the desired dimensions, and quantises the colour of the image
to the desired colour count.
 */
function quantize(
  img: any,
  colorCount: number,
  colorTable: Palette,
  cellAR: number,
  preserveARWidth: boolean,
  cb: quantizeCallback,
): void {
  const [w, h] = [img.bitmap.width, img.bitmap.height];

  const info = makeImageInfo(w, h, img.hasAlpha() ? 4 : 3, Jimp.MIME_BMP);

  // Convert to CanvasImage
  let cImg = makeCanvasImage(new Uint8Array(3 * w * h), w, h, 3);

  for(let r = 0; r !== h; ++r) {
    const r1 = h - r - 1;
    for(let c = 0; c !== w; ++c) {
      const idx = 4 * (r1 * w + c);
      cImg.data.set(img.bitmap.data.slice(idx, idx + 3), 3 * (r * w + c));
    }
  }

  const palette = quantizeImage(cImg, colorCount, false);

  let colTbl: Palette;

  if(!colorTable) colTbl = palette[0];
  else {
    colTbl = colorTable;
    const current = palette[1];
    const inputTbl = colorTable.map(parseRGBString);

    const eq = (A: Uint8Array, B: Uint8Array): boolean => A[0] === B[0] && A[1] === B[1] && A[2] === B[2];

    const getReplacementColor = (col: Uint8Array): Uint8Array => {
      for(let i = 0; i !== current.length; ++i) {
        if(eq(col, current[i])) return inputTbl[i];
      }
      return new Uint8Array([0, 0, 0]);
    };

    for(let r = 0; r !== h; ++r) {
      for(let c = 0; c !== w; ++c) {
        const idx = 3 * (r * w + c);
        cImg.data.set(
          getReplacementColor(cImg.data.slice(idx, idx + 3)),
          idx,
        );
      }
    }
  }

  // Transform the source AR into the target AR
  // Do not resample when the AR is set to 1.
  const oldBuf = cImg.data;

  if(!eq(cellAR, 1)) {
    const [resampled, [W, H]] = preserveARWidth ?
      resampleARWidth(cImg, w, h, cellAR, ResampleMethod.RM_NEAREST) :
      resampleARHeight(cImg, w, h, cellAR, ResampleMethod.RM_NEAREST);

    cImg = makeCanvasImage(resampled, W, H, 3);
  }

  // Convert back to image type for preview
  for(let r = 0; r !== h; ++r) {
    const r1 = h - r - 1;
    for(let c = 0; c !== w; ++c) {
      const idx = 3 * (r1 * w + c);
      img.bitmap.data.set(oldBuf.slice(idx, idx + 3), 4 * (r * w + c));
    }
  }
  // Convert the GLCanvas Image into a 1 channel texture
  const out = palettize(cImg, colTbl);

  img.getBase64(Jimp.MIME_BMP, (err, src) => {
    cb(src, info, out, colTbl, palette[1].length);
  });
}

export function palettize(img: CanvasImage, palette: Palette): CanvasImage {
  const tbl = palette.map(parseRGBString);
  const eq = (A: Uint8Array, B: Uint8Array): boolean => B && A[0] === B[0] && A[1] === B[1] && A[2] === B[2];

  const findIdx = (px: Uint8Array): number => {
    for(let i = 0; i !== palette.length; ++i) {
      if(eq(px, tbl[i])) return i;
    }
    return -1;
  };

  const out = makeCanvasImage(
    new Uint8Array(img.width * img.height),
    img.width,
    img.height,
    1,
  );

  for(let i = 0; i !== img.data.length; i += 3) {
    const idx = findIdx(img.data.slice(i, i + 3));
    // if(idx === -1) console.log('error:', i % img.width, Math.floor(i / img.width));
    out.data[Math.floor(i / 3)] = idx;
  }

  return out;
}

const MAX_IMAGE_WIDTH = 252;

/*
This is a conglomerate image processing function and takes care of applying the settings
in ImageImportDialog to the provided image.  This function is an attempt to unify and
speed up as many of the functions as possible so that the image is processed in near real-time.
 */
// TODO: Fix this hot mess
export function quantizeColor2(
  buffer: Buffer, // The input node Buffer
  colorCount: number, // The fixed number of colours to quantise
  grayscale: boolean, // Whether to convert the image to grayscale before processing or not
  brightness: number, // The range over which to brighten the image [-1 .. +1]
  contrast: number, // The range over which to change the contrast [-1 .. +1]
  dims: [number, number], // The output size of the image
  resize: boolean, // Whether to resize the image to the dims or not (otherwise it might clamp to 252)
  colorTable: Palette, // The input colour palette (6 colours)
  cellAR: number, // The aspect ratio of the current grid (stitches/rows)
  preserveARWidth: boolean, // Preserve the AR width or alternatively off, or height
  cb: quantizeCallback,
): void {
  if(colorCount > 6) {
    console.log('Error, cannot reduce colors to more than 6');
    return;
  }

  Jimp.read(buffer).then((img) => {
    // Calculate the desired Image dimensions
    let outH: number
    let outW = Math.min(
      MAX_IMAGE_WIDTH,
      resize ? dims[0] : img.bitmap.width,
    )

    const AR = img.bitmap.height / img.bitmap.width;
    outH = Math.round(AR * outW);

    const input = makeCanvasImage(
      img.bitmap.data,
      img.bitmap.width,
      img.bitmap.height,
      4,
    );

    const out = resize || img.bitmap.width !== outW ?
      resizeImage(input, outW, outH) :
      input;

    if(grayscale) convertToGrayscale(out.data, out.width, out.height, 4);

    if(brightness !== 0 || contrast !== 0) adjustBC(out.data, out.width, out.height, 4, brightness, contrast);

    // Convert the output to the required image output for now
    img.bitmap.width = out.width;
    img.bitmap.height = out.height;
    img.bitmap.data = Buffer.from(out.data);

    quantize(img, colorCount, colorTable, cellAR, preserveARWidth, cb);
  });
}

export function resizeImage(
  img: CanvasImage,
  width: number,
  height: number,
): CanvasImage {
  if(width === img.width && height === img.height) return img;

  if(width <= img.width && height <= img.height) {
    return downsample(img, width, height);
  }
  return upsample(img, width, height);
}

// This is an accurate downsampling function that avoids repeated application and is best suited for use
// with photographs/real-world images.  Note that if using line drawings, a different downsampling method
// should be used.

export function downsample(
  img: CanvasImage,
  width: number,
  height: number,
): CanvasImage {
  const { channels } = img;
  const RGBCh = Math.min(img.channels, 3);
  const srcIdx = (c: number, r: number): number => channels * (img.width * r + c);
  const trg = new Uint8Array(width * height * channels);
  const trgIdx = (c: number, r: number): number => channels * (width * r + c);
  const fW = img.width / width;
  const fH = img.height / height;
  const bW = Math.ceil(fW) + 1;
  const bH = Math.ceil(fH) + 1;
  const buf = new Float32Array(bW * bH * channels);
  const factors = new Float32Array(bW * bH);

  const facIdx = (c: number, r: number): number => bW * r + c;
  const bufIdx = (c: number, r: number): number => channels * (bW * r + c);

  for(let r = 0; r !== height; ++r) {
    for(let c = 0; c !== width; ++c) {
      const startF = [c * fW, r * fH];
      const start = startF.map(Math.floor);
      const endF = [(c + 1) * fW, (r + 1) * fH];
      const end = endF.map(Math.floor);

      buf.fill(0);
      factors.fill(0);

      for(
        let rr = 0;
        rr !== bH &&
        start[1] + rr <= end[1] &&
        start[1] + rr !== img.height;
        ++rr
      ) {
        let rowFactor: number;
        if(rr === 0) rowFactor = startF[1] > start[1] ? start[1] - startF[1] + 1 : 1;
        else if(start[1] + rr === end[1]) rowFactor = endF[1] > end[1] ? endF[1] - end[1] : 1;
        else rowFactor = 1;

        for(
          let cc = 0;
          cc !== bW &&
          start[0] + cc <= end[0] &&
          start[0] + cc !== img.width;
          ++cc
        ) {
          if(cc === 0) {
            factors[facIdx(cc, rr)] = rowFactor * (startF[0] > start[0] ?
              start[0] - startF[0] + 1 :
              1
            );
          } else if(start[0] + cc === end[0]) {
            factors[facIdx(cc, rr)] = rowFactor * (endF[0] > end[0] ? endF[0] - end[0] : 1);
          } else {
            factors[facIdx(cc, rr)] = rowFactor;
          }
          for(let ch = 0; ch !== RGBCh; ++ch) {
            buf[bufIdx(cc, rr) + ch] = factors[facIdx(cc, rr)] *
              img.data[srcIdx(start[0] + cc, start[1] + rr) + ch];
          }
        }
      }

      const px = [0, 0, 0];
      for(let i = 0; i !== buf.length; i += channels) {
        px[0] += buf[i];
        px[1] += buf[i + 1];
        px[2] += buf[i + 2];
      }

      const scale = factors.reduce((prev, curr) => prev + curr);
      const invScale = 1 / scale;

      trg[trgIdx(c, r)] = Math.round(px[0] * invScale);
      trg[trgIdx(c, r) + 1] = Math.round(px[1] * invScale);
      trg[trgIdx(c, r) + 2] = Math.round(px[2] * invScale);
      if(channels > RGBCh) trg[trgIdx(c, r) + 3] = 255;
    }
  }

  return makeCanvasImage(trg, width, height, channels);
}

// Upsample method is bilinear/bicubic for now, perhaps Lanczos at a later state...
// Fortunately, this is much, MUCH simpler than the downsampling case

export function upsample(
  img: CanvasImage,
  width: number,
  height: number,
): CanvasImage {
  return img;
}

// Nearest neighbour resampling for comparison
export function nearestNeighbour(
  img: CanvasImage,
  width: number,
  height: number,
): CanvasImage {
  const { channels } = img;
  const sW = img.width / width;
  const sH = img.height / height;
  const trg = new Uint8Array(width * height * channels);

  const srcIdx = (c: number, r: number) => channels * (img.width * r + c);
  const trgIdx = (c: number, r: number) => channels * (width * r + c);

  for(let r = 0; r !== height; ++r) {
    for(let c = 0; c !== width; ++c) {
      for(let ch = 0; ch !== channels; ++ch) {
        trg[trgIdx(c, r) + ch] = img.data[srcIdx(Math.floor(c * sW), Math.floor(r * sH)) + ch];
      }
    }
  }

  return makeCanvasImage(trg, width, height, channels);
}

/*
The input image has a width and height which need to be resampled in a different
AR which redistributes the contribution of each pixel to the overlaid new AR grid.
The choice, initially, is bilinear interpolation because it's easy and fast, but if
this results in insufficient quality, it will be converted to bicubic interpolation.

The incoming AR is 1 : 1 and the required AR is (say) 6./5.; (rows in cm/stitches in cm)
 */

export enum ResampleMethod {
    RM_NEAREST,
    RM_BILINEAR
}

export function resampleARWidth(
  srcImage: CanvasImage,
  width: number,
  height: number,
  AR: number,
  method: ResampleMethod,
): [Uint8Array, [number, number]] {
  const srcW = srcImage.width;
  const srcH = srcImage.height;
  const trgW = srcImage.width;
  const trgH = Math.floor(srcImage.height * AR);

  const invW = 1 / trgW;
  const invH = AR / trgH;
  const buf = new Uint8Array(trgW * trgH * 3);

  const trgToUV = (c: number, r: number): [number, number] => [c * invW * srcW, (r * invH * srcH) / AR];

  const trgToSrc = (c: number, r: number): number[] => trgToUV(c, r).map(Math.round);

  const lerpU8 = (
    A: Uint8Array,
    B: Uint8Array,
    value: number,
  ): Uint8Array => {
    const trg = new Uint8Array(3);
    trg[0] = (1 - value) * A[0] + value * B[0];
    trg[1] = (1 - value) * A[1] + value * B[1];
    trg[2] = (1 - value) * A[2] + value * B[2];
    return trg;
  };

  const srcWm1 = srcW - 1;
  const srcHm1 = srcH - 1;
  const getPx = (c: number, r: number): Uint8Array => {
    const idx = 3 * (srcW * Math.min(r, srcHm1) + Math.min(c, srcWm1));
    return srcImage.data.slice(idx, idx + 3);
  };

  // Bilinear interpolation to be used when sampling the source.
  // But can only be run before the quantiser because it will create colours by interpolating
  const bilinear = (c: number, r: number): Uint8Array => {
    const uv = trgToUV(c, r);
    const floor = uv.map(Math.floor);
    const ceil = floor.map((x) => x + 1);
    const c00 = getPx(floor[0], floor[1]);
    const c10 = getPx(ceil[0], floor[1]);
    const c01 = getPx(floor[0], ceil[1]);
    const c11 = getPx(ceil[0], ceil[1]);
    const pxA = lerpU8(c00, c10, uv[0] - floor[0]);
    const pxB = lerpU8(c01, c11, uv[0] - floor[0]);
    return lerpU8(pxA, pxB, uv[1] - floor[1]);
  };

  const nearest = (c: number, r: number): Uint8Array => {
    const coord = trgToSrc(c, r);
    const offset = 3 * (srcW * coord[1] + coord[0]);
    return srcImage.data.slice(offset, offset + 3);
  };

  if(method === ResampleMethod.RM_NEAREST) {
    for(let r = 0; r !== trgH; ++r) {
      for(let c = 0; c !== trgW; ++c) {
        buf.set(nearest(c, r), 3 * (trgW * r + c));
      }
    }
  } else if(method === ResampleMethod.RM_BILINEAR) {
    for(let r = 0; r !== trgH; ++r) {
      for(let c = 0; c !== trgW; ++c) {
        buf.set(bilinear(c, r), 3 * (trgW * r + c));
      }
    }
  }

  return [buf, [trgW, trgH]];
}

export function resampleARHeight(
  srcImage: CanvasImage,
  width: number,
  height: number,
  AR: number,
  method: ResampleMethod,
): [Uint8Array, [number, number]] {
  const srcW = srcImage.width;
  const srcH = srcImage.height;
  const trgW = Math.floor(srcImage.width / AR);
  const trgH = srcImage.height;

  const invW = 1 / (trgW * AR);
  const invH = 1 / trgH;
  const buf = new Uint8Array(trgW * trgH * 3);

  const trgToUV = (c: number, r: number): [number, number] => [c * invW * srcW * AR, r * invH * srcH];

  const trgToSrc = (c: number, r: number): number[] => trgToUV(c, r).map(Math.round);

  const lerpU8 = (
    A: Uint8Array,
    B: Uint8Array,
    value: number,
  ): Uint8Array => {
    const trg = new Uint8Array(3);
    trg[0] = (1 - value) * A[0] + value * B[0];
    trg[1] = (1 - value) * A[1] + value * B[1];
    trg[2] = (1 - value) * A[2] + value * B[2];
    return trg;
  };

  const srcWm1 = srcW - 1;
  const srcHm1 = srcH - 1;
  const getPx = (c: number, r: number): Uint8Array => {
    const idx = 3 * (srcW * Math.min(r, srcHm1) + Math.min(c, srcWm1));
    return srcImage.data.slice(idx, idx + 3);
  };

  // Bilinear interpolation to be used when sampling the source.
  // But can only be run before the quantiser because it will create colours by interpolating
  const bilinear = (c: number, r: number): Uint8Array => {
    const uv = trgToUV(c, r);
    const floor = uv.map(Math.floor);
    const ceil = floor.map((x) => x + 1);
    const c00 = getPx(floor[0], floor[1]);
    const c10 = getPx(ceil[0], floor[1]);
    const c01 = getPx(floor[0], ceil[1]);
    const c11 = getPx(ceil[0], ceil[1]);
    const pxA = lerpU8(c00, c10, uv[0] - floor[0]);
    const pxB = lerpU8(c01, c11, uv[0] - floor[0]);
    return lerpU8(pxA, pxB, uv[1] - floor[1]);
  };

  const nearest = (c: number, r: number): Uint8Array => {
    const coord = trgToSrc(c, r);
    const offset = 3 * (srcW * coord[1] + coord[0]);
    return srcImage.data.slice(offset, offset + 3);
  };

  if(method === ResampleMethod.RM_NEAREST) {
    for(let r = 0; r !== trgH; ++r) {
      for(let c = 0; c !== trgW; ++c) {
        buf.set(nearest(c, r), 3 * (trgW * r + c));
      }
    }
  } else if(method === ResampleMethod.RM_BILINEAR) {
    for(let r = 0; r !== trgH; ++r) {
      for(let c = 0; c !== trgW; ++c) {
        buf.set(bilinear(c, r), 3 * (trgW * r + c));
      }
    }
  }

  return [buf, [trgW, trgH]];
}
