import {
  applySnapshot, getSnapshot, isEmptySnapshot, TimeNeedleSnapshot,
} from 'src/data/time-needle/snapshot'
import { ReadonlyTimeNeedleImage } from 'src/data/time-needle/time-needle-image'
import { RootState } from 'src/store'
import { UndoableAction, UndoDomain } from 'src/undo'

/**
 * An undoable snapshot action.
 *
 * This can be used in two ways:
 * - `new UndoableSnapshotAction(snapshot)` creates an undoable snapshot
 *   that gets applied to the current time-needle image and can be undone
 * - `new UndoableSnapshotAction(snapshot, result)` assumes that `snapshot`
 *   is the undo snapshot that results in transforming the `result` image
 *   back into its original time-needle image
 *
 * @see {@link UndoableSnapshotAction.from}
 * @see {@link getSnapshot}
 */
export class UndoableSnapshotAction
implements UndoableAction {
  readonly domain = UndoDomain.EDITOR

  constructor(
    protected snapshot: TimeNeedleSnapshot,
    protected result: ReadonlyTimeNeedleImage = null,
  ) {
    console.assert(
      snapshot && !isEmptySnapshot(snapshot),
      'Empty or null snapshot',
    )
  }

  /**
    * Compute the snapshot to transform one time-needle image back into another
    * and return an undoable snapshot action for it.
    *
    * The resulting action updates the time-needle image to `after` while
    * providing an undo snapshot that allows undoing to the current time-needle image.
    *
    * @param before the initial time-needle image
    * @param after the updated time-needle image
    * @returns an undoable snapshot action whose undo goes back from `after` to `before`
    */
  static from(before: ReadonlyTimeNeedleImage, after: ReadonlyTimeNeedleImage) {
    const snap = getSnapshot(after, before)
    return snap ? new UndoableSnapshotAction(snap, after) : null
  }

  applyTo(state: RootState): [RootState, UndoableAction] {
    let tnimage: ReadonlyTimeNeedleImage
    let undo: TimeNeedleSnapshot
    // two cases
    if(this.result) {
      // the snapshot is already an undo snapshot
      // whereas the application result is provided
      tnimage = this.result
      undo = this.snapshot
    } else {
      // the snapshot is to be applied (no result yet)
      ({ tnimage, undo } = applySnapshot(state.canvas.tnimage, this.snapshot))
    }
    return [
      {
        ...state,
        canvas: {
          ...state.canvas,
          tnimage,
          width: tnimage.cdata.width,
          height: tnimage.cdata.height,
        },
      },
      new UndoableSnapshotAction(undo),
    ]
  }
}
