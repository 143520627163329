import Palette from '../time-needle/palette';
import {
  CanvasImage, createCanvasImage, getPixel, getPixelChannel, getPixelRef, ReadonlyCanvasImage, setPixel, setPixelChannel,
} from './image';
import { flipY } from './utils';

export function toPNGDataUrl({
  width, height, channels, data,
}: ReadonlyCanvasImage): string {
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')
  const imageData = ctx.createImageData(width, height)
  const flipped = flipY(data, width, height, channels)
  const end = Math.floor(data.length / channels)
  for(let i = 0; i !== end; ++i) {
    const srcOff = channels * i
    const trgOff = 4 * i
    imageData.data[trgOff] = flipped[srcOff]
    imageData.data[trgOff + 1] = flipped[srcOff + 1]
    imageData.data[trgOff + 2] = flipped[srcOff + 2]
    imageData.data[trgOff + 3] = 255
  }

  ctx.putImageData(imageData, 0, 0)
  const url = canvas.toDataURL('image/png')
  canvas.remove()
  return url
}

export function exportPNG(
  data: Uint8Array,
  width: number,
  height: number,
  channels = 3,
  fname = 'downloag.png',
): void {
  const url = toPNGDataUrl({
    data, width, height, channels,
  })
  const link = document.createElement('a')
  link.download = fname
  link.href = url
  document.body.appendChild(link)

  link.click()
  link.remove()
}

export function loadImageData(file: Blob, bottomToTop = true) {
  return new Promise<ImageData>((accept, reject) => {
    const url = URL.createObjectURL(file)
    const img = new Image()
    img.onload = () => {
      URL.revokeObjectURL(img.src)
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      if(bottomToTop) {
        // default canvas viewport is topToBottom
        // => translate and invert Y
        ctx.translate(0, img.height)
        ctx.scale(1, -1)
      }
      ctx.drawImage(img, 0, 0)
      const data = ctx.getImageData(0, 0, img.width, img.height)
      accept(data)
    }
    img.src = url
    img.onabort = reject
    img.onerror = reject
  })
}

export type RGBAChannel = 0 | 1 | 2 | 3

export type ChannelSet<NC extends 1 | 2 | 3 | 4> = (
  NC extends 1 ? readonly [RGBAChannel]
  : NC extends 2 ? readonly [RGBAChannel, RGBAChannel]
    : NC extends 3 ? readonly [RGBAChannel, RGBAChannel, RGBAChannel]
      : readonly [RGBAChannel, RGBAChannel, RGBAChannel, RGBAChannel]
)

export interface ImportOptions<NC extends 1 | 2 | 3 | 4> {
  keepChannel?: ChannelSet<NC>
  fromPalette?: boolean
  alphaAsMask?: boolean
  bottomToTop?: boolean
}
export function importImage<NC extends 1 | 2 | 3 | 4>(
  channels: NC,
  {
    keepChannel,
    fromPalette = false,
    alphaAsMask = false,
    bottomToTop = true,
  }: ImportOptions<NC> = {},
) {
  if(!keepChannel) {
    const kch = [0, 1, 2, 3].slice(0, channels) as unknown
    keepChannel = kch as ChannelSet<NC>
  }
  return new Promise<CanvasImage<NC>>((accept, reject) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.hidden = true
    document.body.appendChild(input)
    input.onchange = async (e) => {
      if(input.files.length && input.files[0]) {
        try {
          const data = await loadImageData(input.files[0], bottomToTop)
          const raw = createCanvasImage(
            data.width,
            data.height,
            4,
            data.data,
          )
          const { width, height } = raw
          if(channels < 4 && alphaAsMask) {
            // apply alpha channel as mask on other channels
            const px = new Uint8Array(4)
            for(let y = 0; y < height; ++y) {
              for(let x = 0; x < width; ++x) {
                const alpha = getPixelChannel(raw, [x, y], 3)
                if(alpha === 0) {
                  // clear the rest
                  setPixel(raw, [x, y], px)
                }
              }
            }
          }
          // process data to match channel count
          if(channels === 4) {
            accept(raw as CanvasImage<NC>)
          } else {
            // create image
            const img = createCanvasImage(width, height, channels)
            for(let y = 0; y < height; ++y) {
              for(let x = 0; x < width; ++x) {
                const px = getPixelRef(raw, [x, y])
                if(channels === 1 && fromPalette) {
                  const [r, g, b] = px
                  const palette = Palette.default()
                  const idx = palette.rgbU8.findIndex(([rp, gp, bp]) => r === rp && g === gp && b === bp)
                  if(idx === -1) {
                    reject(`Pixel (x=${x + 1}, y=${y + 1}) has a non-palette value: r=${r}, g=${g}, b=${b}`)
                    return
                  }
                  setPixelChannel(img, [x, y], 0, idx + 1)
                } else {
                  // use `keepChannel` to remap the channels of px
                  setPixel(img, [x, y], keepChannel.map((c) => px[c]))
                }
              }
            }
            accept(img)
          }
        } catch(err) {
          reject(err)
        }
      } else {
        reject('No file passed by user')
      }
    }
    input.click()
    input.remove()
  })
}
