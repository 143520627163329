import { clamp } from 'src/common/math'
import { getPixelChannel, setPixelChannel } from 'src/data/image'
import {
  BedSide,
  BedSideOrBoth,
  BedSideOrNone,
  BedSidesOrNone,
  setCarrier,
  setRacking,
  YarnIndex,
  YarnIndexOrNone,
  getOption, KniterateOptions, getRacking,
} from './options'
import {
  getNeedleCodePair,
  getStitchCode,
  getStitchTransferCode,
  isStitchTransferCode,
  mirrorStitchCode,
  NeedleCode,
  StitchCode,
  swapStitchCodeBed,
} from './stitch-code'
import { NeedleCodePair } from './stitch-code/convert'
import TimeNeedleImage, { ReadonlyTimeNeedleImage } from './time-needle-image'

/**
 * Helper to access a time-needle cell and its neighborhood
 */
export class TimeNeedleCellReader {
  constructor(
    public readonly parent: ReadonlyTimeNeedleImage,
    public readonly index: number,
  ) {}

  static from(img: ReadonlyTimeNeedleImage, index: number) {
    return new TimeNeedleCellReader(img, index)
  }

  get row(): number {
    return Math.floor(this.index / this.parent.cdata.width)
  }

  get y(): number { return this.row }

  get column(): number {
    return this.index % this.parent.cdata.width
  }

  get x(): number { return this.column }

  // neighbors
  neighbor(dx: number, dy: number): TimeNeedleCellReader | null {
    if(dx === 0 && dy === 0) {
      return this
    }
    const x = this.x + dx
    const width = this.parent.cdata.width
    if(!(x >= 0 && x < width)) {
      return null
    }
    const y = this.y + dy
    if(!(y >= 0 && y < this.parent.cdata.height)) {
      return null
    }
    return new TimeNeedleCellReader(this.parent, y * width + x)
  }

  right() { return this.neighbor(1, 0) }

  left() { return this.neighbor(-1, 0) }

  up() { return this.neighbor(0, 1) }

  down() { return this.neighbor(0, -1) }

  // getters
  getCode() {
    return getPixelChannel(this.parent.cdata, this.index, 0) as StitchCode
  }

  getNeedleCodes() {
    return getNeedleCodePair(this.getCode())
  }

  getPattern() {
    return getOption(this.parent.odata, this.row, KniterateOptions.carrier) as YarnIndexOrNone
  }

  isSidedStitch(nc: NeedleCode, side: BedSidesOrNone = -1) {
    const ncs = this.getNeedleCodes()
    switch(side) {
    case -1:
      return ncs.includes(nc)
    case 0:
    case 1:
      return ncs[side] === nc
    case 2:
      return ncs[0] === nc && ncs[1] === nc
    default:
      throw `Invalid side argument: must be either -1, 0, 1 or 2; was ${side}`
    }
  }

  isKnit(side: BedSidesOrNone = -1) {
    return this.isSidedStitch(NeedleCode.KNIT, side)
  }

  isTuck(side: BedSidesOrNone = -1) {
    return this.isSidedStitch(NeedleCode.TUCK, side)
  }

  isKnitTuck() {
    return this.getCode() === StitchCode.FRNT_KNIT_REAR_TUCK
  }

  isTuckKnit() {
    return this.getCode() === StitchCode.FRNT_TUCK_REAR_KNIT
  }

  isMiss() { return this.getCode() === StitchCode.MISS }

  isDrop(side: BedSidesOrNone = -1) {
    return this.isSidedStitch(NeedleCode.DROP, side)
  }

  isSplit(side: BedSideOrNone = -1) {
    return this.isSidedStitch(NeedleCode.SPLT, side)
  }

  isExplicitMiss() { return this.getCode() === StitchCode.XMISS }

  isCarrierAction() {
    return this.isKnit() || this.isTuck() || this.isSplit() || this.isExplicitMiss()
  }

  isTransfer() {
    return isStitchTransferCode(this.getCode())
  }
}

export class TimeNeedleCellWriter extends TimeNeedleCellReader {
  constructor(
    public readonly parent: TimeNeedleImage,
    index: number,
  ) {
    super(parent, index)
  }

  static from(img: TimeNeedleImage, index: number) {
    return new TimeNeedleCellWriter(img, index)
  }

  at(idx: number | undefined | null) {
    if(typeof idx === 'number') {
      return new TimeNeedleCellWriter(this.parent, idx)
    }
    return idx
  }

  /**
   * Update the stitch code of a cell.
   * /!\ A proper yarn carrier must be set in the options.
   *
   * @param sc the desired stitch code
   * @param halfRacking whether to update half-racking as necessary
   */
  code(sc: StitchCode, halfRacking?: boolean) {
    setPixelChannel(this.parent.cdata, this.index, 0, sc)
    if(halfRacking !== undefined) this.halfRack(halfRacking)
    return this
  }

  /**
   * Update the associated racking to be either
   * - half-racked (`true`) or
   * - integer-racked (`false)
   */
  halfRack(setHalfRacked: boolean) {
    // update the racking so that we get a proper
    // half-racked value (true) or integer-racked (false)
    const racking = getRacking(this.parent, this.row)
    const isIntRacking = Number.isInteger(racking)
    if(isIntRacking === setHalfRacked) {
      const rackShift = setHalfRacked ? 0.5 : -0.5
      // XXX should machine-dependent racking min/max
      const newRacking = clamp(racking + rackShift, -4, 4)
      setRacking(this.parent, this.row, newRacking)
    }
    return this
  }

  /**
   * Update one needle code.
   *
   * Beware that this can obviously lead to invalid codes
   * and should ideally be dealt with in the context.
   *
   * @param nc the new needle code to use
   * @param side its side (`0` for front, `1` for rear)
   * @param isc the invalid stitch code to use (`INVALID` by default)
   */
  ncode(nc: NeedleCode, side: BedSide, isc: StitchCode = StitchCode.INVALID) {
    const [fnc, rnc] = this.getNeedleCodes()
    return this.ncodes(side === 0 ? [nc, rnc] : [fnc, nc], isc)
  }

  /**
   * Update the stitch code by using a pair of needle codes.
   *
   * Beware that not all pairs of needle codes form valid stitch codes.
   *
   * @param ncp the pair of needle codes
   * @param isc the invalid stitch code to use (`INVALID` by default)
   */
  ncodes(ncp: NeedleCodePair, isc: StitchCode = StitchCode.INVALID) {
    const sc = getStitchCode(ncp)
    return this.code(sc === StitchCode.INVALID ? isc : sc)
  }

  /**
   * Update the yarn carrier associated with a cell's row.
   *
   * @param yarn the yarn carrier to use
   * @depreated this should be done per row, not per cell
   */
  pattern(yarn: YarnIndexOrNone) {
    setCarrier(this.parent, this.row, yarn)
  }

  // short-hand actions
  // - knit
  knit(side: BedSideOrBoth, yarn?: YarnIndex) {
    if(yarn !== undefined) this.pattern(yarn)
    return this.code(
      [StitchCode.FRNT_KNIT, StitchCode.REAR_KNIT, StitchCode.BOTH_KNIT][side],
      side === 2 || void 0,
    )
  }

  fknit(yarn?: YarnIndex) { return this.knit(0, yarn) }

  frontKnit(yarn?: YarnIndex) { return this.knit(0, yarn) }

  rknit(yarn?: YarnIndex) { return this.knit(1, yarn) }

  rearKnit(yarn?: YarnIndex) { return this.knit(1, yarn) }

  fbknit(yarn?: YarnIndex) { return this.knit(2, yarn) }

  bothKnit(yarn?: YarnIndex) { return this.knit(2, yarn) }

  // - tuck
  tuck(side: BedSideOrBoth, yarn?: YarnIndex) {
    if(yarn !== undefined) this.pattern(yarn)
    return this.code(
      [StitchCode.FRNT_TUCK, StitchCode.REAR_TUCK, StitchCode.BOTH_TUCK][side],
      side === 2 || undefined,
    )
  }

  ftuck(yarn?: YarnIndex) { return this.tuck(0, yarn) }

  frontTuck(yarn?: YarnIndex) { return this.tuck(0, yarn) }

  rtuck(yarn?: YarnIndex) { return this.tuck(1, yarn) }

  rearTuck(yarn?: YarnIndex) { return this.tuck(1, yarn) }

  fbtuck(yarn?: YarnIndex) { return this.tuck(2, yarn) }

  bothTuck(yarn?: YarnIndex) { return this.tuck(2, yarn) }

  // - mixed knit/tuck
  knitTuck(yarn?: YarnIndex) {
    if(yarn !== undefined) this.pattern(yarn)
    return this.code(StitchCode.FRNT_KNIT_REAR_TUCK, true)
  }

  tuckKnit(yarn?: YarnIndex) {
    if(yarn !== undefined) this.pattern(yarn)
    return this.code(StitchCode.FRNT_TUCK_REAR_KNIT, true)
  }

  // - miss
  miss(yarn?: YarnIndexOrNone) {
    if(yarn !== undefined) this.pattern(yarn)
    return this.code(StitchCode.MISS)
  }

  // - xmiss
  xmiss(yarn?: YarnIndex) {
    if(yarn !== undefined) this.pattern(yarn)
    return this.code(StitchCode.XMISS)
  }

  // - drop
  drop(side: BedSideOrBoth) {
    this.pattern(0)
    return this.code(
      [StitchCode.FRNT_DROP, StitchCode.REAR_DROP, StitchCode.BOTH_DROP][side],
      side === 2 || undefined,
    )
  }

  fdrop() { return this.drop(0) }

  frontDrop() { return this.drop(0) }

  rdrop() { return this.drop(1) }

  rearDrop() { return this.drop(1) }

  fbdrop() { return this.drop(2) }

  bothDrop() { return this.drop(2) }

  // - split
  split(side: BedSide, yarn?: YarnIndex) {
    if(yarn !== undefined) this.pattern(yarn)
    return this.code(
      [StitchCode.FRNT_SPLIT, StitchCode.REAR_SPLIT][side],
      false, // must NOT be half-racked
    )
  }

  fsplit(yarn?: YarnIndex) { return this.split(0, yarn) }

  frontSplitToRear(yarn?: YarnIndex) { return this.split(0, yarn) }

  rsplit(yarn?: YarnIndex) { return this.split(1, yarn) }

  rearSplitToFront(yarn?: YarnIndex) { return this.split(1, yarn) }

  // - transfer
  transfer(srcBed: BedSide, dx = 0) {
    this.pattern(0)
    return this.code(
      getStitchTransferCode(srcBed, dx),
      dx !== 0 ? false : void 0,
    )
  }

  xfer(srcBed: BedSide, dx = 0) { return this.transfer(srcBed, dx) }

  // neighbors
  neighbor(dx: number, dy: number): TimeNeedleCellWriter | null {
    return this.at(super.neighbor(dx, dy)?.index)
  }

  right() { return this.at(super.right()?.index) }

  left() { return this.at(super.left()?.index) }

  up() { return this.at(super.up()?.index) }

  down() { return this.at(super.down()?.index) }

  // helpers
  copy(c: TimeNeedleCellReader, withYarn = true) {
    if(c) {
      this.code(c.getCode())
      if(withYarn) {
        this.pattern(c.getPattern())
      }
    }
    return this
  }

  swapWith(that: TimeNeedleCellWriter, withYarn = true) {
    if(that) {
      const thisCS = this.getCode()
      const thatCS = that.getCode()
      this.code(thatCS)
      that.code(thisCS)
      if(withYarn) {
        const thisYM = this.getPattern()
        const thatYM = that.getPattern()
        this.pattern(thatYM)
        that.pattern(thisYM)
      }
    }
    return this
  }

  mirror() {
    const c = this.getCode()
    const mc = mirrorStitchCode(c)
    return this.code(mc)
  }

  mirrorBed() {
    const c = this.getCode()
    const bmc = swapStitchCodeBed(c)
    return this.code(bmc)
  }
}
