import { addRows } from 'src/data/time-needle/topology'
import {
  BedSideOrBoth,
  getKnittingSides,
  KnittingSide,
  SelectorLike,
  YarnIndex,
} from './common'
import {
  dropRest,
  fullKnit,
  halfKnitFromRight,
  halfKnitFromRight2,
} from './knits'
import { shiftRearSymbols } from './shift-rear-symbols'

export function generateBasicFoundation(
  s: SelectorLike,
  yarn: YarnIndex,
  baseFirst = false,
) {
  const { left, bottom, right } = s.getExtents()
  const range = [left, right] as [number, number]
  const baseRow = s.bottommost()
  const baseSides = getKnittingSides(baseRow, 'above')

  // add necessary rows
  const numRows = 5
  const [, rows] = addRows(baseRow, 'below', numRows)

  // generate basic hem
  rows.perRowOptions((row: number) => ({
    speed: 100,
    roller: (baseFirst ? [400, 200, 200, 400, 0] : [200, 200, 400, 400, 0])[row - bottom],
    stitchSize: [4, 4, 4, 4, 2][row - bottom],
  }))
  let row = bottom
  if(baseFirst) fullKnit(rows, row++, range, baseSides, yarn)
  halfKnitFromRight(rows, row++, range, baseSides, yarn)
  halfKnitFromRight2(rows, row++, range, baseSides, yarn)
  if(!baseFirst) fullKnit(rows, row++, range, baseSides, yarn)
  fullKnit(rows, row++, range, baseSides, yarn)
  dropRest(rows, row++, range, baseSides, true)
  // check that we have the proper number of rows
  if((row - bottom) !== 5) throw `Invalid number of rows generated: expected 5, got ${row - bottom}`

  return rows
}

export function generateWideRibsFoundation(
  s: SelectorLike,
  yarn: YarnIndex,
) {
  const { left, bottom, right } = s.getExtents()
  const baseRow = s.bottommost()
  const baseSides = getKnittingSides(baseRow, 'above')

  // add necessary rows
  const numRows = 5
  const [, rows] = addRows(baseRow, 'below', numRows)

  // generate basic hem
  let hasBothKnit = false
  for(let x = left; x < right; ++x) {
    const side = baseSides[x - left]
    if(side !== KnittingSide.NONE) {
      // first pass at racking=1.5
      rows.cellAt(bottom, x).knit(side as BedSideOrBoth, yarn)
      // second pass at racking=0.5 for rear
      if(side === KnittingSide.REAR || side === KnittingSide.BOTH) {
        rows.cellAt(bottom + 1, x).knit(1, yarn)
      }
      // third pass at racking=0.5 for front
      if(side === KnittingSide.FRONT || side === KnittingSide.BOTH) {
        rows.cellAt(bottom + 2, x).knit(0, yarn)
      }
      // last pass
      rows.cellAt(bottom + 3, x).knit(side as BedSideOrBoth, yarn)
      // discover racking for last pass
      if(side === KnittingSide.BOTH) hasBothKnit = true
    }
  }
  rows.perRowOptions((row: number) => ({
    speed: 50,
    roller: [400, 200, 200, 400, 0][row - bottom],
    stitchSize: [3, 3, 3, 3, 2][row - bottom],
    racking: [1.5, 0.5, 0.5, hasBothKnit ? 0.5 : 0.0][row - bottom],
  }))
  // shift the rear needle symbols for the first row
  shiftRearSymbols(rows.bottommost(), 1)

  // drop pass
  dropRest(rows, bottom + 4, [left, right], baseSides, true)

  return rows
}
