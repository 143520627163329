import {
  getSelectionType,
  parseUserOptions,
  RowOptions,
  TimeNeedleTransformer,
  UserDirection,
} from './common'

export type StepOptions = {
  [P in Exclude<keyof RowOptions, 'carrier' | 'stitchSize'>]: RowOptions[P][]
}

export function createStepOptions(): StepOptions {
  return {
    direction: new Array<UserDirection>(),
    racking: new Array<number>(),
    speed: new Array<number>(),
    roller: new Array<number>(),
    frontStitchSize: new Array<number>(),
    rearStitchSize: new Array<number>(),
  }
}

export interface LocalStepOptions {
  knit: StepOptions
  xfer: StepOptions
  local?: boolean
}

export function parseLocalStepOptions(input: string): LocalStepOptions | string {
  const knitOpts = createStepOptions()
  const xferOpts = createStepOptions()
  const res: LocalStepOptions = {
    knit: knitOpts,
    xfer: xferOpts,
    local: false,
  }
  let opts: StepOptions = knitOpts
  let multiply = false
  const pushArg = (arr: number[], arg: number): string | undefined => {
    if(multiply) {
      if(arg <= 1) {
        return 'Multiplier must be larger than 1'
      }
      // multiply last value
      for(let i = arg - 1; i > 0; --i) {
        arr.push(arr[arr.length - 1])
      }
      multiply = false
    } else {
      // add single
      arr.push(arg)
    }
  }
  const error = parseUserOptions(input, {
    knit: () => { opts = knitOpts },
    xfer: () => { opts = xferOpts },
    local: () => { res.local = true },
    global: () => { res.local = false },
    over: () => { multiply = true },
  }, {
    dir: (token: string) => {
      if(opts === knitOpts) {
        if(multiply || ['0', '1', '2'].includes(token)) {
          return pushArg(opts.direction, parseInt(token, 10))
        }
        return `Invalid direction token ${token}`
      } if(token === '2') {
        opts.direction = [2]
      } else {
        return 'Cannot set direction for transfers'
      }
    },
    racking: (token: string) => pushArg(opts.racking, parseFloat(token)),
    roller: (token: string) => pushArg(opts.roller, parseInt(token, 10)),
    speed: (token: string) => pushArg(opts.speed, parseInt(token, 10)),
    stitch: (token: string) => {
      const size = parseInt(token, 10)
      let err = pushArg(opts.frontStitchSize, size)
      if(err) { return err }
      return pushArg(opts.rearStitchSize, size)
    },
    frontStitch: (token: string) => pushArg(opts.frontStitchSize, parseInt(token, 10)),
    rearStitch: (token: string) => pushArg(opts.rearStitchSize, parseInt(token, 10)),
  }, null)
  if(error) {
    return error
  }
  return res
}

export function localStepOptions(s: TimeNeedleTransformer, { knit, xfer, local = false }: LocalStepOptions) {
  const [start, end] = s.getRowExtents() as [number, number]
  // only keep rows that have a knit or transfer
  const baseRows = (
    local ? s.splitByRow() : Array.from({ length: end - start + 1 }, (_, i) => s.fullCourse(i + start))
  ).filter((row) => row.some((c) => c.isKnit() || c.isTransfer()))
  if(!baseRows.length) {
    return // no row => no block
  }
  // group as blocks of rows of the same type
  const getType = (row: TimeNeedleTransformer) => {
    const { hasKnit, hasTransfer } = getSelectionType(row)
    return hasKnit ? 0 : hasTransfer ? 1 : 2
  }
  const setOpt = (opts: RowOptions, key: keyof StepOptions, blockSide: StepOptions, i: number) => {
    if(key === 'direction') {
      const dirs = blockSide[key]
      opts.direction = dirs[i] ?? dirs[dirs.length - 1]
    } else {
      const vals = blockSide[key]
      opts[key] = vals[i] ?? vals[vals.length - 1]
    }
  }
  const groups = {
    0: new Array<TimeNeedleTransformer>(),
    1: new Array<TimeNeedleTransformer>(),
    2: new Array<TimeNeedleTransformer>(),
  }
  for(const row of baseRows) {
    const type = getType(row)
    const group = groups[type]
    group.push(row)
  }

  // apply options to each block
  for(const { rows, stepOpts } of [
    { rows: groups['0'], stepOpts: knit },
    { rows: groups['1'], stepOpts: xfer },
  ]) {
    for(let i = 0; i < rows.length; ++i) {
      // generate per-row options
      const opts = {} as RowOptions
      for(const key of [
        'direction', 'speed', 'roller', 'racking', 'frontStitchSize', 'rearStitchSize',
      ] as (keyof StepOptions)[]) {
        setOpt(opts, key, stepOpts, i)
      } // endfor key of keyof StepOptions

      // set options
      rows[i].first().options(opts)
    }
  }
}
