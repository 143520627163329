import TimeNeedleSelector from 'src/data/time-needle/selector'
import { deleteColumns, deleteRows } from 'src/data/time-needle/time-needle-image'
import { deleteTransferRows } from 'src/macros'
import { useRawMacro } from 'src/hooks'
import ContextMenuItem from './menu-item'

export default function DeleteMenu() {
  const rawMacro = useRawMacro()
  return (
    <ContextMenuItem
      name="Delete ..."
      menu
    >
      <ContextMenuItem
        name="Delete rows"
        onClick={() => {
          rawMacro((tni, [, b, , t]) => deleteRows(tni, b, t - b))
        }}
      />
      <ContextMenuItem
        name="Delete columns"
        onClick={() => {
          rawMacro((tni, [l, , r]) => deleteColumns(tni, l, r - l))
        }}
      />
      <ContextMenuItem
        name="Delete transfer rows"
        onClick={() => {
          rawMacro((tni, sel) => deleteTransferRows(TimeNeedleSelector.fromRegion(tni, sel)))
        }}
      />
    </ContextMenuItem>
  )
}
