import { CanvasImage } from './image';

/*
Downsample the input image using 2x2 averaging
The scale contains the count of the number of times to halve the image size (i.e. lg(n))
 */
export function avgDownsample(
  scale: number,
  arr: Uint8Array,
  width: number,
  height: number,
  channels = 3,
): CanvasImage {
  if(scale > 0 && width > 1 && height > 1) {
    const newWidth = Math.floor(width / 2);
    const newHeight = Math.floor(height / 2);
    const newImg = new Uint8Array(newWidth * newHeight * channels);
    const buffer = new Uint16Array(newWidth * newHeight * channels);
    buffer.fill(0);

    for(let r = 0; r !== height; ++r) {
      const rowOffset = r * width * channels;
      const nRowOffset = Math.floor(r / 2) * newWidth * channels;

      for(let c = 0; c !== width; ++c) {
        const modC = Math.floor(c / 2);
        if(modC >= newWidth) continue;
        const offset = rowOffset + channels * c;
        const nOffset = nRowOffset + channels * modC;

        for(let ch = 0; ch !== channels; ++ch) {
          buffer[nOffset + ch] += arr[offset + ch];
        }
      }
    }

    for(let i = 0; i !== newImg.length; ++i) newImg[i] = Math.floor(buffer[i] / 4);

    scale--;
    return avgDownsample(scale, newImg, newWidth, newHeight, channels);
  }

  return {
    width, height, channels, data: arr,
  };
}
