import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getCarrierSpacing, setCarrierSpacing as setKnitoutCarrierSpacing,
  getStoppingDistance, setStoppingDistance as setKnitoutStoppingDistance,
  CarrierSpacing, StoppingDistance,
} from 'src/data/compiler'

const initialState = {
  // ui
  showOptionsColumn: true,
  columnsPerCm: 5,
  rowsPerCm: 6,
  aspectRatio: 5 / 6,
  // validation
  maxFloatDistance: 7,
  // knitout
  carrierSpacing: getCarrierSpacing() as CarrierSpacing,
  stoppingDistance: getStoppingDistance() as StoppingDistance,
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    showOptionsColumn(state, { payload }: PayloadAction<boolean>) {
      return {
        ...state,
        showOptionsColumn: payload,
      }
    },
    setAspectRatio(state, { payload }: PayloadAction<[number, number]>) {
      return {
        ...state,
        columnsPerCm: payload[0],
        rowsPerCm: payload[1],
        aspectRatio: payload[0] / payload[1],
      }
    },
    setColumnsPerCm(state, { payload }: PayloadAction<number>) {
      return {
        ...state,
        columnsPerCm: payload,
        aspectRatio: payload / state.rowsPerCm,
      }
    },
    setRowsPerCm(state, { payload }: PayloadAction<number>) {
      return {
        ...state,
        rowsPerCm: payload,
        aspectRatio: state.columnsPerCm / payload,
      }
    },
    setMaxFloatDistance(state, { payload }: PayloadAction<number>) {
      return {
        ...state,
        maxFloatDistance: Math.max(2, payload),
      }
    },
    setCarrierSpacing(state, { payload }: PayloadAction<CarrierSpacing>) {
      setKnitoutCarrierSpacing(payload)
      return {
        ...state,
        carrierSpacing: payload,
      }
    },
    setStoppingDistance(state, { payload }: PayloadAction<StoppingDistance>) {
      setKnitoutStoppingDistance(payload)
      return {
        ...state,
        stoppingDistance: payload,
      }
    },
  },
})

export const {
  showOptionsColumn,
  setAspectRatio, setColumnsPerCm, setRowsPerCm,
  setMaxFloatDistance,
  setCarrierSpacing,
  setStoppingDistance,
} = settingsSlice.actions
export default settingsSlice.reducer
export type SettingsState = typeof initialState
