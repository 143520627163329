import {
  MenuHeader,
  MenuDivider,
  ControlledMenu,
} from '@szhsin/react-menu'

import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

// import UndoRedo from './undo-redo'
// import CopyPaste from './copy-paste'
// import Marker from './marker'
// import Racking from './racking'

const ContextMenu = (props) => <>nope</>
// const canvas = window.canvasRef
// const selection = canvas ? canvas.getSelectionRect() : new AABB(0, 0, 0, 0)
// const activeCell = canvas ? canvas.getActiveCell() : new Vec2(0, 0)
// const isSingle = selection.width() * selection.height() < 2
// return (
//   <ControlledMenu {...props}>
//     <MenuHeader>
//       { isSingle ? (
//         <>
//           Column: { activeCell.x() + 1 }, Row: { activeCell.y() + 1 }
//         </>
//       ) : (
//         <>
//           Cols: { selection.left() + 1 } - { selection.right() },
//           Rows: { selection.bottom() + 1 } - { selection.top() }
//         </>
//       )}
//     </MenuHeader>
//     <MenuDivider />
//     <UndoRedo />
//     <CopyPaste />
//     <Marker />
//     <MenuDivider />
//     {isSingle && <Racking />}
//     <MenuDivider />
//   </ControlledMenu>
// )

export default ContextMenu
