import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react'
import classnames from 'classnames'
import { setActiveStitch } from 'src/editor/time-needle/slice'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { StitchCode } from 'src/data/time-needle/stitch-code'

import 'src/styles/stitch-selector.scss'

const MAX_STITCH_HISTORY = 2

const StitchCodeToClassName = {
  [StitchCode.REAR_KNIT]: 'rear-knit-icon',
  [StitchCode.REAR_TUCK]: 'rear-tuck-icon',
  [StitchCode.REAR_DROP]: 'rear-drop-icon',
  [StitchCode.REAR_SPLIT]: 'rear-split-icon',
  [StitchCode.FRNT_KNIT]: 'front-knit-icon',
  [StitchCode.FRNT_TUCK]: 'front-tuck-icon',
  [StitchCode.FRNT_DROP]: 'front-drop-icon',
  [StitchCode.FRNT_SPLIT]: 'front-split-icon',
  [StitchCode.BOTH_KNIT]: 'knit-both-icon',
  [StitchCode.BOTH_TUCK]: 'tuck-both-icon',
  [StitchCode.FRNT_TUCK_REAR_KNIT]: 'tuck-knit-icon',
  [StitchCode.FRNT_KNIT_REAR_TUCK]: 'knit-tuck-icon',
  [StitchCode.BOTH_DROP]: 'drop-both-icon',
  [StitchCode.MISS]: 'clear-icon',
  [StitchCode.XMISS]: 'miss-icon',
  [StitchCode.REAR_XFERL1]: 'rl1-icon',
  [StitchCode.REAR_XFERL2]: 'rl2-icon',
  [StitchCode.REAR_XFERL3]: 'rl3-icon',
  [StitchCode.REAR_XFERL4]: 'rl4-icon',
  [StitchCode.REAR_XFERR1]: 'rr1-icon',
  [StitchCode.REAR_XFERR2]: 'rr2-icon',
  [StitchCode.REAR_XFERR3]: 'rr3-icon',
  [StitchCode.REAR_XFERR4]: 'rr4-icon',
  [StitchCode.REAR_XFER]: 'r0-icon',
  [StitchCode.FRNT_XFER]: 'f0-icon',
  [StitchCode.FRNT_XFERL1]: 'fl1-icon',
  [StitchCode.FRNT_XFERL2]: 'fl2-icon',
  [StitchCode.FRNT_XFERL3]: 'fl3-icon',
  [StitchCode.FRNT_XFERL4]: 'fl4-icon',
  [StitchCode.FRNT_XFERR1]: 'fr1-icon',
  [StitchCode.FRNT_XFERR2]: 'fr2-icon',
  [StitchCode.FRNT_XFERR3]: 'fr3-icon',
  [StitchCode.FRNT_XFERR4]: 'fr4-icon',
}

interface StitchButtonProps {
  updateHistory: Dispatch<SetStateAction<Set<StitchCode>>>
  code: StitchCode
  onClick?: () => void
}

const StitchButton = function ({ updateHistory, code, onClick }: StitchButtonProps) {
  const dispatch = useAppDispatch()
  const activeStitch = useAppSelector((state) => state.canvas.activeStitch)

  return (
    <button
      className={classnames(StitchCodeToClassName[code], { active: activeStitch === code })}
      onClick={onClick || (() => {
        if(activeStitch !== code) {
          updateHistory((history) => {
            history.delete(code)
            if(history.size >= MAX_STITCH_HISTORY) {
              const toDelete = history.values().next().value
              history.delete(toDelete)
            }
            return history.add(activeStitch)
          })
          dispatch(setActiveStitch(code))
        }
      })}
    />
  )
}

export default function StitchSelector() {
  const [open, setOpen] = useState(true)
  const [selector, setSelector] = useState(false)
  const [stitchHistory, updateHistory] = useState(new Set<StitchCode>())
  const [activeStitch, activeView] = useAppSelector((state) => [
    state.canvas.activeStitch,
    state.canvas.activeView,
  ])
  useEffect(() => { setSelector(false) }, [activeStitch])

  return (
    <div className={classnames('stitchbox', { open, faded: stitchHistory.size })}>
      <div
        className="title"
        // onClick={() => {
        //   open && setSelector(false)
        //   setOpen((open) => !open)
        // }}
      >
        Stitch
      </div>
      {open && (
        <>
          <StitchButton updateHistory={updateHistory} code={activeStitch} onClick={() => setSelector(!selector)} />
          {[...stitchHistory].reverse().map((stitch) => <StitchButton updateHistory={updateHistory} key={`stitch-${stitch}`} code={stitch} />)}
        </>
      )}
      {selector &&
        (
          <div className="stitch-selector">
            <div className="basic-knitting">
              <div className="top-knit">Knit</div>
              <div className="top-tuck">Tuck</div>
              <div className="top-split">Split</div>
              <div className="top-drop">Drop</div>
              <div className="clear-label">Clear</div>
              <div className="miss-label">Miss</div>
              <div className="knit-both">Knit Both</div>
              <div className="tuck-knit">Tuck &amp; Knit</div>
              <div className="drop-both">Drop Both</div>
              <div className="tuck-both">Tuck Both</div>
              <div className="knit-tuck">Knit &amp; Tuck</div>
              <div className="first-row-label">Rear</div>
              <div className="second-row-label">Front</div>
              <div className="right-label">Basic</div>

              <StitchButton updateHistory={updateHistory} code={StitchCode.REAR_KNIT} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.REAR_TUCK} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.REAR_SPLIT} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.REAR_DROP} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.FRNT_KNIT} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.FRNT_TUCK} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.FRNT_SPLIT} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.FRNT_DROP} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.MISS} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.XMISS} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.BOTH_KNIT} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.BOTH_TUCK} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.FRNT_TUCK_REAR_KNIT} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.FRNT_KNIT_REAR_TUCK} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.BOTH_DROP} />
            </div>
            <div className="transfer">
              <div className="rack-left">Rack Left</div>
              <div className="rack-right">Rack Right</div>
              <div className="first-row-label">Rear to Front</div>
              <div className="second-row-label">Front to Rear</div>
              <div className="right-label">Transfer</div>

              <StitchButton updateHistory={updateHistory} code={StitchCode.REAR_XFERL1} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.REAR_XFERL2} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.REAR_XFERL3} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.REAR_XFERL4} />

              <StitchButton updateHistory={updateHistory} code={StitchCode.REAR_XFERR1} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.REAR_XFERR2} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.REAR_XFERR3} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.REAR_XFERR4} />

              <StitchButton updateHistory={updateHistory} code={StitchCode.REAR_XFER} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.FRNT_XFER} />

              <StitchButton updateHistory={updateHistory} code={StitchCode.FRNT_XFERL1} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.FRNT_XFERL2} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.FRNT_XFERL3} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.FRNT_XFERL4} />

              <StitchButton updateHistory={updateHistory} code={StitchCode.FRNT_XFERR1} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.FRNT_XFERR2} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.FRNT_XFERR3} />
              <StitchButton updateHistory={updateHistory} code={StitchCode.FRNT_XFERR4} />
            </div>
            {/* <div className="knit-transfer">
              <div className="rack-left">Rack Left</div>
              <div className="rack-right">Rack Right</div>
              <div className="first-row-label">Knit Rear &amp; Transfer</div>
              <div className="second-row-label">Knit Front &amp; Transfer</div>
              <div className="right-label">Knit &amp; Transfer</div>

              <button className="rfl-4-icon" />
              <button className="rfl-3-icon" />
              <button className="rfl-2-icon" />
              <button className="rfl-1-icon" />

              <button className="frl-4-icon" />
              <button className="frl-3-icon" />
              <button className="frl-2-icon" />
              <button className="frl-1-icon" />

              <button className="down-icon" />
              <button className="up-icon" />

              <button className="rfr-1-icon" />
              <button className="rfr-2-icon" />
              <button className="rfr-3-icon" />
              <button className="rfr-4-icon" />

              <button className="frr-1-icon" />
              <button className="frr-2-icon" />
              <button className="frr-3-icon" />
              <button className="frr-4-icon" />
            </div> */}
          </div>
        )}
    </div>
  )
}
