import classnames from 'classnames'
import { ViewMode } from 'src/data/time-needle/view-mode'
import { setActiveViewMode } from 'src/editor/time-needle/slice'
import { useAppDispatch, useAppSelector } from 'src/hooks'

import 'src/styles/mode-selector.scss'

const ViewSelector = function () {
  const dispatch = useAppDispatch()
  const view = useAppSelector(({ canvas: { activeView } }) => activeView)
  const setMode = (newMode: ViewMode) => {
    dispatch(setActiveViewMode(newMode))
  }
  return (
    <div className={classnames({
      'mode-selector': true,
      [ViewMode[view].toLowerCase()]: true,
    })}
    >
      {/* <div
        className={classnames('item', 'shape', { active: RenderMode.RM_TEMPLATE == mode })}
        // onClick={() => setMode(RenderMode.RM_TEMPLATE)}
        title="shape"
      >
        shape
      </div> */}
      <div
        className={classnames('item', 'pattern', { active: view === ViewMode.PATTERN })}
        onClick={() => setMode(ViewMode.PATTERN)}
        title="pattern"
      />
      <div
        className={classnames('item', 'stitch', { active: view === ViewMode.STITCH })}
        onClick={() => setMode(ViewMode.STITCH)}
        title="stitch"
      />
    </div>
  )
}

export default ViewSelector
