import { clearColumns, clearRows } from 'src/macros'
import { useApplyMacro } from 'src/hooks'
import ContextMenuItem from './menu-item'

export default function ClearMenu() {
  const applyMacro = useApplyMacro()
  return (
    <ContextMenuItem
      name="Clear ..."
      menu
    >
      <ContextMenuItem
        name="Clear rows"
        onClick={() => {
          applyMacro((s) => clearRows(s))
        }}
      />
      <ContextMenuItem
        name="Clear columns"
        onClick={() => {
          applyMacro((s) => clearColumns(s))
        }}
      />
      <ContextMenuItem
        name="Clear selection"
        onClick={() => {
          applyMacro((s) => void s.miss())
        }}
      />
    </ContextMenuItem>
  )
}
