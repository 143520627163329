import {
  useEffect, useRef, useState,
} from 'react'
import ForceGraph2D, { ForceGraphMethods } from 'react-force-graph-2d'
import { shallowEqual } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import Palette from 'src/data/time-needle/palette'
import { pad } from 'src/common/math'
import { deferAction, setErrorCell, setRelatedCell } from 'src/editor/time-needle/slice'
import { jumpTo } from 'src/editor/time-needle/action'
import { clearData, LinkType, SimulationNode } from './data'
import PortalWindow, { PortalWindowProps } from './portal-window'

const palette = Palette.default()

export interface SimulationWindowProps extends Omit<PortalWindowProps, 'children'> {
  width?: number
  height?: number
}

interface Node extends SimulationNode {
  x: number
  y: number
  color: string
}

interface Link {
  type: LinkType
  source: Node
  target: Node
  color: string
}

function hex2(n: number, shift: boolean) {
  return pad((n >> (shift ? 1 : 0)).toString(16), 2)
}

function getNodeColor(cs: string[], rear: boolean) {
  const colIdx = '123456'.indexOf(cs[0]) // XXX should mix colors for multi-carrier loops?
  const [r, g, b] = palette.rgbU8[colIdx] ?? [0, 0, 0]
  return `#${hex2(r, rear)}${hex2(g, rear)}${hex2(b, rear)}`
}

function getLinkColor(n1: SimulationNode, n2: SimulationNode) {
  if(n1.carriers[0] === n2.carriers[0]) {
    return getNodeColor(n1.carriers, n1.needle.inBack())
  }
  return '#999'
}

const BaseDist = 30

function SimulationWindow({ width = 600, height = 400, ...props }: SimulationWindowProps = {}) {
  const dispatch = useAppDispatch()
  const {
    nodes,
    links,
    dataId,
  } = useAppSelector(({ simulation: { nodes, links, dataId }}) => ({ nodes, links, dataId }), shallowEqual)
  const [graphData, setGraphData] = useState<{nodes: Node[], links: Link[]}>({ nodes: [], links: [] })
  const [hover, setHover] = useState(false)
  const ref = useRef<ForceGraphMethods>()
  useEffect(() => {
    if(!ref.current) {
      return
    }
    // console.log('dataId', dataId)
    const newNodes = nodes.map(({ source, ...node }) => ({
      ...node,
      x: source[1] * BaseDist,
      y: -source[0] * BaseDist, // reverse y for 2D layout
      source,
      color: getNodeColor(node.carriers, node.needle.inBack()),
    }))
    const newLinks = links.map(({ source, target, ...link }) => ({
      ...link,
      source: newNodes[source],
      target: newNodes[target],
      color: getLinkColor(nodes[source], nodes[target]),
    }))
    setGraphData({ nodes: newNodes, links: newLinks })
  }, [dataId])
  useEffect(() => {
    if(!ref.current) {
      return
    }
    /*
      backgroundColor('#eee')
      .linkWidth(5)
      .nodeRelSize(7);
    // force settings
    fg.d3AlphaDecay(0.0128) // 0.0228);
      .d3VelocityDecay(0.1); // 05);
    let charge = fg.d3Force('charge');
    charge.strength(-20);
    charge.distanceMax(1000);
      */
    const charge = ref.current.d3Force('charge')
    charge.strength(-BaseDist)
    // charge.distanceMax(100)
    const linkLink = ref.current.d3Force('link')
    linkLink.distance(BaseDist).iterations(10) // .strength(1).iterations(10)
    // const collide = ref.current.d3Force('collide') ?? forceCollide(BaseDist / 5)
    // ref.current.d3Force('collide', collide)
  }, [graphData])
  return (nodes.length > 0 && (
    <PortalWindow
      {...props}
      width={width + 20}
      height={height + 20}
      onClose={() => {
        if(ref.current) {
          ref.current.pauseAnimation()
          // request clearing of data
          // which effectively removes the portal window component
          dispatch(clearData())
        }
        props.onClose && props.onClose()
      }}
    >
      <div style={{
        cursor: hover ? 'pointer' : 'default',
      }}
      >
        <ForceGraph2D
          backgroundColor="#eee"
          linkWidth={4}
          nodeRelSize={BaseDist / 3}
          d3AlphaDecay={0.0128}
          d3VelocityDecay={0.4}
          width={width}
          height={height}
          ref={ref}
          graphData={graphData}
          warmupTicks={0}
          cooldownTicks={100}
          onEngineStop={() => ref.current.zoomToFit(400)}
          onNodeHover={(node: Node) => {
            dispatch(setErrorCell([-1, -1]))
            if(node && node.source) {
              dispatch(setRelatedCell(
                Array.isArray(node.source) ?
                  [node.source[1], node.source[0]] :
                  [-1, node.source ?? -1],
              ))
            } else {
              dispatch(setRelatedCell([-1, -1]))
            }
            setHover(!!node)
          }}
          onNodeClick={(node: Node) => {
            if(node && node.source) {
              dispatch(deferAction(jumpTo(node.source)))
            }
          }}
        />
      </div>
    </PortalWindow>
  )
  )
}

export default SimulationWindow
