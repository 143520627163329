import { shallowEqual } from 'react-redux'
import TimeNeedleImage, {
  deleteColumns,
  deleteRows,
  insertColumns,
  insertRows,
  ReadonlyTimeNeedleImage,
} from 'src/data/time-needle/time-needle-image'
// import usePrompt from 'src/editor/prompt'
import { UndoableSnapshotAction } from 'src/editor/time-needle/action'
import { setSelection } from 'src/editor/time-needle/slice'
import {
  useAppDispatch, useAppSelector, useUserEntry,
} from 'src/hooks'
import { AppDispatch } from 'src/store'
import { addUndoableAction } from 'src/undo'
import ContextMenuItem from './menu-item'

// hard-coded bound for Kniterate
// XXX extract that into a per-machine constraint
const MAX_PANEL_WIDTH = 252

// operation type
export enum ColRowOp {
  ADD_COLUMNS = 0,
  ADD_ROWS,
  DELETE_COLUMNS,
  DELETE_ROWS,
  INVALID,
}

/**
 * Apply a col-row operation onto an image
 *
 * @param dispatch the redux dispatch method
 * @param oldTNI the old time-needle image
 * @param type the type of action
 * @param idx the column/row index of the action
 * @param num the number of columns/rows
 */
export function applyColRowUpdate(
  dispatch: AppDispatch,
  oldTNI: ReadonlyTimeNeedleImage,
  type: ColRowOp,
  idx: number,
  num: number,
) {
  let newTNI: TimeNeedleImage
  switch(type) {
  case ColRowOp.ADD_COLUMNS:
    newTNI = insertColumns(oldTNI, idx, num)
    break
  case ColRowOp.ADD_ROWS:
    newTNI = insertRows(oldTNI, idx, num)
    break
  case ColRowOp.DELETE_COLUMNS:
    newTNI = deleteColumns(oldTNI, idx, num)
    break
  case ColRowOp.DELETE_ROWS:
    newTNI = deleteRows(oldTNI, idx, num)
    break
  default:
    throw `Invalid col-row operation ${type}`
  }

  // update the time-needle image
  const undoable = UndoableSnapshotAction.from(oldTNI, newTNI)
  if(undoable) {
    dispatch(addUndoableAction(undoable))
  }

  // update the selection
  if([ColRowOp.ADD_COLUMNS, ColRowOp.ADD_ROWS].includes(type)) {
    dispatch(setSelection(type === ColRowOp.ADD_COLUMNS ? [
      idx, 0, idx + num, newTNI.cdata.height,
    ] : [
      0, idx, newTNI.cdata.width, idx + num,
    ]))
  }
}

export default function ColRowItems() {
  // const showPrompt = usePrompt()
  const {
    selection: [x, y],
    tnimage,
    width,
    height,
  } = useAppSelector(({
    canvas: {
      selection, tnimage, width, height,
    },
  }) => ({
    selection, tnimage, width, height,
  }), shallowEqual)
  const dispatch = useAppDispatch()
  const colRowOp = (type: ColRowOp, idx: number, num: number) => {
    applyColRowUpdate(dispatch, tnimage, type, idx, num)
  }
  // insertion options
  const [insertRowsDefault, setInsertRowsDefault] = useUserEntry('insert-rows', '2 above')
  const [insertColsDefault, setInsertColsDefault] = useUserEntry('insert-cols', '2 right')
  return (
    <>
      <ContextMenuItem
        name="Insert Row ⇑"
        // shortcut={`(${ctrlStr}+Up)`}
        onClick={() => colRowOp(ColRowOp.ADD_ROWS, y + 1, 1)}
      />
      <ContextMenuItem
        name="Insert Row ⇓"
        // shortcut={`(${ctrlStr}+Down)`} // ⇓
        onClick={() => colRowOp(ColRowOp.ADD_ROWS, y + 0, 1)}
      />
      {/* <ContextMenuItem
        name="Insert Rows onclick"
        onClick={async () => {
          const what = await showPrompt({question: 'How many and where? e.g., 10 above, or 5 below', value: insertRowsDefault })
          const after = !what.toLowerCase().includes('below')
          const numRows = parseInt(what, 10)
          if(numRows > 0) {
            colRowOp(ColRowOp.ADD_ROWS, y + (after ? 1 : 0), numRows)
            setInsertRowsDefault(what)
          }
        }}
      /> */}
      <ContextMenuItem
        name="Insert Rows"
        prompt={['How many and where? e.g., 10 above, or 5 below', insertRowsDefault]}
        onInput={(what) => {
          const after = !what.toLowerCase().includes('below')
          const numRows = parseInt(what, 10)
          if(numRows > 0) {
            colRowOp(ColRowOp.ADD_ROWS, y + (after ? 1 : 0), numRows)
            setInsertRowsDefault(what)
          }
        }}
      />
      <ContextMenuItem
        name="Delete Row"
        onClick={() => colRowOp(ColRowOp.DELETE_ROWS, y + 0, 1)}
      />
      <ContextMenuItem
        name="Delete Rows Above"
        onClick={() => colRowOp(ColRowOp.DELETE_ROWS, y + 1, height - 1 - y)}
        disabled={y >= height - 1}
      />
      <ContextMenuItem
        name="Insert Column ⇒"
        // shortcut={`(${ctrlStr}+Right)`} // ⇒
        onClick={() => colRowOp(ColRowOp.ADD_COLUMNS, x + 1, 1)}
        disabled={width === MAX_PANEL_WIDTH}
      />
      <ContextMenuItem
        name="Insert Column ⇐"
        // shortcut={`(${ctrlStr}+Left)`} // ⇐
        onClick={() => colRowOp(ColRowOp.ADD_COLUMNS, x + 0, 1)}
        disabled={width === MAX_PANEL_WIDTH}
      />
      <ContextMenuItem
        name="Insert Columns"
        prompt={['How many and where? e.g., 10 on the left, or 5 on the right', insertColsDefault]}
        onInput={(what) => {
          const after = !what.toLowerCase().includes('left')
          const numCols = parseInt(what, 10)
          if(width + numCols > MAX_PANEL_WIDTH) {
            alert('Cannot exceed maximum width of 252 needles')
            return
          }
          if(numCols > 0) {
            colRowOp(ColRowOp.ADD_COLUMNS, x + (after ? 1 : 0), numCols)
            setInsertColsDefault(what)
          }
        }}
        disabled={width === MAX_PANEL_WIDTH}
      />
      <ContextMenuItem
        name="Delete Column"
        onClick={() => colRowOp(ColRowOp.DELETE_COLUMNS, x + 0, 1)}
      />
    </>
  )
}
