import { ReadonlyUint32Array } from 'src/common/types';
import { RFV4 } from 'src/common/math'
import { StitchCode } from './stitch-code';
import TimeNeedleImage, {
  copyTimeNeedleImage,
  createTimeNeedleImage, ReadonlyTimeNeedleImage,
} from './time-needle-image';
import { TimeNeedleCellWriter } from './cell'
import TimeNeedleSelector, {
  isNumberIterable,
  selectAll,
  selectArea,
  TimeNeedleSelectorBase,
} from './selector';
import {
  BedSide,
  BedSideOrBoth,
  RowOptions,
  UserDirection,
  YarnIndex,
  YarnIndexOrNone,
  setCarriageSpeed,
  setCarrier,
  setDirection,
  setRoller,
  setRowOptions,
  setRacking,
  setStitchSize,
  getRowOptions,
} from './options';
import { extractTimeNeedleRegion } from './region';

/**
 * Export cell types
 */
export { TimeNeedleCellReader, TimeNeedleCellWriter } from './cell'

/**
 * Time-needle transformer
 */
export default class TimeNeedleTransformer
  extends TimeNeedleSelectorBase<TimeNeedleImage, TimeNeedleCellWriter, TimeNeedleTransformer> {
  constructor(
    img: TimeNeedleImage,
    sel: ReadonlyUint32Array,
  ) {
    super(img, sel, TimeNeedleCellWriter.from)
  }

  /**
   * Create a transformer associated with a writeable time-needle image
   *
   * @param tni the time-needle image
   * @returns its wrapping transformer
   */
  static from(tni: TimeNeedleImage) {
    const length = tni.cdata.width * tni.cdata.height
    return new TimeNeedleTransformer(tni, selectAll(length))
  }

  /**
   * Wrap a selector / transformer as a transformer.
   *
   * If the argument is a selector, a time-needle image
   * copy is created to allow for write operations.
   * Otherwise, the underlying transformer is returned as is.
   *
   * @param s either a selector or a transformer
   * @returns the transformer
   */
  static fromSelector(s: SelectorLike): TimeNeedleTransformer {
    if(s instanceof TimeNeedleSelector) {
      const tni = copyTimeNeedleImage(s.image)
      return new TimeNeedleTransformer(tni, s.selection)
    }
    return s
  }

  /**
   * Create a transformer associated with a writeable time-needle image,
   * with a selection set to a specific sub-region of it.
   *
   * @param tni the time-needle image
   * @param area the sub-region to select
   * @returns its wrapping transformer
   */
  static fromRegion(tni: TimeNeedleImage, area: RFV4) {
    return new TimeNeedleTransformer(tni, selectArea(tni.cdata, area))
  }

  /**
   * Create a transformer associated with the crop of a time-needle image.
   * This explicitly computes a crop of the input image such that the modifications
   * applied by the transformer are not bound to the input.
   *
   * Thus the input can be a read-only image.
   * This is useful if the input is used to generate data
   * that will be used for some action (e.g., copy and pasting).
   *
   * @param tni the time-needle image, untouched
   * @param area the crop area
   * @returns the transformer associated with a separate crop
   */
  static fromRegionCrop(tni: ReadonlyTimeNeedleImage, area: RFV4) {
    const region = extractTimeNeedleRegion(tni, area)
    return TimeNeedleTransformer.from(region)
  }

  /**
   * Create a transformer associated with a blank time-needle image.
   *
   * This is useful for validating code execution with dummy data.
   *
   * @param w its width
   * @param h its height
   * @returns the transformer
   */
  static empty(w = 2, h = 2) {
    const panel = createTimeNeedleImage(w, h)
    return this.from(panel)
  }

  // implementation
  withSelection(selection: ArrayLike<number> | ArrayBufferLike | Iterable<number>) {
    if(selection instanceof Uint32Array) {
      return new TimeNeedleTransformer(this.image, selection)
    } if(isNumberIterable(selection)) {
      return new TimeNeedleTransformer(this.image, new Uint32Array(selection))
    }
    return new TimeNeedleTransformer(this.image, new Uint32Array(selection))
  }

  // per-cell actions ########################################################

  stitch(sc: StitchCode, halfRacking?: boolean) { return this.forEach((c) => c.code(sc, halfRacking)) }

  knit(side: BedSideOrBoth, yarn?: YarnIndex) { return this.forEach((c) => c.knit(side)).carrier(yarn) }

  tuck(side: BedSideOrBoth, yarn?: YarnIndex) { return this.forEach((c) => c.tuck(side)).carrier(yarn) }

  knitTuck(yarn?: YarnIndex) { return this.forEach((c) => c.knitTuck()).carrier(yarn) }

  tuckKnit(yarn?: YarnIndex) { return this.forEach((c) => c.tuckKnit()).carrier(yarn) }

  miss(yarn?: YarnIndexOrNone) { return this.forEach((c) => c.miss()).carrier(yarn) }

  xmiss(yarn?: YarnIndex) { return this.forEach((c) => c.xmiss()).carrier(yarn) }

  drop(side: BedSideOrBoth) { return this.forEach((c) => c.drop(side)).carrier(0) }

  split(from: BedSide, yarn?: YarnIndex) { return this.forEach((c) => c.split(from)).carrier(yarn) }

  transfer(from: BedSide, dx = 0) { return this.forEach((c) => c.transfer(from, dx)) }

  xfer(from: BedSide, dx = 0) { return this.transfer(from, dx) }

  mirrorBed() { return this.forEach((c) => c.mirrorBed()) }

  // per-row actions #########################################################

  stitchSize(size: number | [number, number], side: BedSideOrBoth = 2) {
    return this.forEachRow((row) => setStitchSize(this.image, row, size, side))
  }

  frontStitchSize(size: number | [number, number]) { return this.stitchSize(size, 0) }

  rearStitchSize(size: number | [number, number]) { return this.stitchSize(size, 1) }

  roller(roll: number) {
    return this.forEachRow((row) => setRoller(this.image, row, roll))
  }

  racking(rack: number) {
    return this.forEachRow((row) => setRacking(this.image, row, rack))
  }

  carriageSpeed(speed: number) {
    return this.forEachRow((row) => setCarriageSpeed(this.image, row, speed))
  }

  direction(dir: UserDirection = 2) {
    return this.forEachRow((row) => setDirection(this.image, row, dir))
  }

  carrier(yarn?: YarnIndexOrNone) {
    if(yarn === undefined) { return this }
    return this.forEachRow((row) => setCarrier(this.image, row, yarn))
  }

  options(opts: RowOptions) {
    this.forEachRow((row) => setRowOptions(this.image, row, opts))
    return this
  }

  perRowOptions(optFun: (row: number) => RowOptions) {
    this.forEachRow((row) => {
      const opts = optFun(row)
      this.setRowOptions(row, opts)
    })
    return this
  }

  setRowOptions(row: number, opts: RowOptions) {
    setRowOptions(this.image, row, opts)
    return this
  }

  // copy / swap #############################################################

  /**
   * Copy the content of the cells of another transformer.
   *
   * Both selections must have the same cardinality.
   * But they do not need to have the same underlying topology.
   *
   * However, similar topologies are easier to work with
   * because the underlying copy is done cell-wise using
   * the linear selection index.
   *
   * @param that the other transformer to copy data from
   * @param withYarn whether to copy the yarn carrier information
   * @returns this transformer
   * @see {@link TimeNeedleCellWriter.copy}
   */
  copy(that: SelectorLike, withYarn = true) {
    if(this.length !== that.length) {
      throw `Copy argument must have the same cardinality: expected ${this.length}, got ${that.length}`
    }
    for(let i = 0; i < this.selection.length; ++i) {
      const thisCell = this.getCell(i)
      const thatCell = that.getCell(i)
      thisCell.copy(thatCell, withYarn)
    }
    return this
  }

  /**
   * Copy the options of another transformer.
   * Both selections must have the same row cardinality.
   *
   * @param that the other transformer to copy the row options from
   * @returns this transformer
   */
  copyOptions(that: SelectorLike) {
    const thisRows = this.splitByRow()
    const thatRows = that.splitByRow()
    if(thisRows.length !== thatRows.length) {
      throw `Copy argument has different row cardinality: expected ${thisRows.length}, got ${thatRows.length}`
    }
    for(let i = 0; i < thisRows.length; ++i) {
      thisRows[i].first().options(thatRows[i].first().getOptions()[0])
    }
    return this
  }

  /**
   * Update the options of each rows given the current options
   *
   * @param map a map from options to updated options (per row)
   * @returns this transformer
   */
  remapOptions(map: (opt: RowOptions, row: number) => RowOptions) {
    return this.forEachRow((row) => {
      const opts = this.rowOptions(row)
      this.setRowOptions(row, map(opts, row))
    })
  }

  /**
   * Swap the content of the cells with another transformer.
   *
   * Both selections must have the same cardinality.
   * But they do not need to have the same underlying topology.
   *
   * Note that this method only swaps the code information.
   * None of the per-row options are swapped, including the yarn carrier.
   *
   * @param that the other transformer to swap data with
   * @returns this transformer
   * @see {@link copy}
   * @see {@link swapOptionsWith}
   */
  swapWith(that: TimeNeedleTransformer) {
    if(this.length !== that.length) {
      throw `Argument must have the same cardinality: expected ${this.length}, got ${that.length}`
    }
    for(let i = 0; i < this.selection.length; ++i) {
      const thisCell = this.getCell(i)
      const thatCell = that.getCell(i)
      thisCell.swapWith(thatCell, false)
    }
    return this
  }

  /**
   * Swap the options of the rows with another transformer.
   *
   * Both selections must have the same row cardinality.
   *
   * The options can be remapped completely or partially.
   * By default, all options are swapped.
   *
   * @param that the other transformer to swap data with
   * @param optMap an option mapping (e.g., to only swap some options)
   * @returns this transformer
   */
  swapOptionsWith(
    that: TimeNeedleTransformer,
    optMap: (opts: RowOptions) => RowOptions = (o) => o,
  ) {
    const theseRows = Array.from(this.getRowSet())
    const thoseRows = Array.from(that.getRowSet())
    if(theseRows.length !== thoseRows.length) {
      throw `Argument has different row cardinality: expected ${theseRows.length}, got ${thoseRows.length}`
    }
    for(const [i, thisRow] of theseRows.entries()) {
      const thatRow = thoseRows[i]
      const thisOpts = {...getRowOptions(this.image, thisRow)}
      const thatOpts = {...getRowOptions(this.image, thatRow)}
      setRowOptions(this.image, thisRow, optMap(thatOpts))
      setRowOptions(this.image, thatRow, optMap(thisOpts))
    }
    return this
  }
}

/**
 * Type union of the selector and transformer implementation.
 *
 * This is useful for enabling a transformer to be used instead
 * of a selector in a read-only way.
 *
 * The opposite is not possible since {@link TimeNeedleTransformer}
 * implement methods that are not available in {@link TimeNeedleSelector}.
 */
export type SelectorLike = TimeNeedleSelector | TimeNeedleTransformer

/**
 * Check whether an object is a selector or transformer
 */
export function isSelectorLike(o: any): o is SelectorLike {
  return o instanceof TimeNeedleSelector || o instanceof TimeNeedleTransformer
}
