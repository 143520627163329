/*
Functions to help with handling and creating images
 */

import { RFV2 } from 'src/common/math';
import { ReadonlyUint8Array, RFV4 } from 'src/common/types';

/**
 * Writeable canvas image
 */
export interface CanvasImage<NumChannels extends number = number> {
  data: Uint8Array
  width: number
  height: number
  channels: NumChannels
}

/**
 * Read-only canvas image
 */
export interface ReadonlyCanvasImage<NumChannels extends number = number> {
  readonly data: ReadonlyUint8Array
  readonly width: number
  readonly height: number
  readonly channels: NumChannels
}

/**
 * Check the validity of an image size layout
 */
export function checkCanvasImage<NC extends number>({
  width, height, channels, data,
}: ReadonlyCanvasImage<NC>) {
  console.assert(
    Number.isInteger(width) && width >= 0,
    'Invalid image width',
    width,
  )
  console.assert(
    Number.isInteger(height) && height >= 0,
    'Invalid image height',
    height,
  )
  console.assert(
    Number.isInteger(channels) && channels >= 0,
    'Invalid image channels',
    channels,
  )
  console.assert(
    data instanceof Uint8Array && data.length === width * height * channels,
    'Invalid image data: either wrong type or wrong cardinality',
    data,
  )
}

/**
 * Create a canvas image while checking that the data
 * and dimensions match and are valid.
 *
 * If data is provided, it should ideally be of type `Uint8Array`.
 * All other types are converted to it.
 * If no data is provided, the necessary array is allocated.
 *
 * @param width the image width
 * @param height the image height
 * @param channels the number of channels per pixel
 * @param data the image data if already existing (must match dimensions)
 * @returns
 */
export function createCanvasImage<NC extends number>(
  width: number,
  height: number,
  channels: NC,
  data: ArrayLike<number> = new Uint8Array(width * height * channels),
): CanvasImage<NC> {
  const img = {
    width,
    height,
    channels,
    data: data instanceof Uint8Array ? data : new Uint8Array(data),
  }
  checkCanvasImage(img)
  return img
}

/**
 * Return a deep copy of a canvas image.
 * This is computationally expensive: a new version of the underlying raster data is created.
 *
 * @see {@link copyCanvasImageByRef}
 */
export function copyCanvasImage<NC extends number>({
  data, width, height, channels,
}: ReadonlyCanvasImage<NC>): CanvasImage<NC> {
  return {
    data: data.slice(),
    width,
    height,
    channels,
  }
}

/**
 * Return a shallow copy of a canvas image.
 * This is computationally inexpensive: the underlying raster data is copied by reference.
 *
 * @see {@link copyCanvasImage}
 */
export function copyCanvasImageByRef<NC extends number>(img: CanvasImage<NC>): CanvasImage<NC>
export function copyCanvasImageByRef<NC extends number>(img: ReadonlyCanvasImage<NC>): ReadonlyCanvasImage<NC>
export function copyCanvasImageByRef<NC extends number>({
  data, width, height, channels,
}: CanvasImage<NC> | ReadonlyCanvasImage<NC>) {
  return {
    data, width, height, channels,
  }
}

/*
let a: CanvasImage<1>
let b: ReadonlyCanvasImage<1>
a = copyCanvasImageByRef(a) // valid
b = copyCanvasImageByRef(b) // valid
b = copyCanvasImageByRef(a) // valid
a = copyCanvasImageByRef(b) // INVALID!!!
*/

/**
 * Verify whether two images have the same content data-wise
 *
 * @param img1 the first image
 * @param img2 the second image
 * @returns whether they match data-wise (not reference-wise)
 */
export function sameCanvasImages<NC extends number>(
  img1: ReadonlyCanvasImage<NC>,
  img2: ReadonlyCanvasImage<NC>,
): boolean {
  return (
    img1.width === img2.width &&
    img1.height === img2.height &&
    img1.channels === img2.channels &&
    img1.data.every((v, i) => v === img2.data[i])
  )
}

// the old, slower version of {@see copyImageToImage}
export function legacyCopyImageToImage<NC extends number>(
  srcImage: CanvasImage<NC>,
  [srcLeft, srcBottom, srcRight, srcTop]: RFV4,
  trgImage: CanvasImage<NC>,
  [trgLeft, trgBottom, trgRight, trgTop]: RFV4,
): boolean {
  const numCols = srcRight - srcLeft
  const numRows = srcTop - srcBottom
  const CH = srcImage.channels;
  if(
    numCols !== trgRight - trgLeft ||
    numRows !== trgTop - trgBottom ||
    CH !== trgImage.channels
  ) {
    return false;
  }

  const sW = srcImage.width;
  const tW = trgImage.width;

  // remove loop over rows in the special case where all columns are covered
  for(let r = 0; r < numRows; ++r) {
    const sRO = CH * (sW * (r + srcBottom))
    const tRO = CH * (tW * (r + trgBottom))

    for(let c = 0; c < numCols; ++c) {
      const sO = sRO + CH * (c + srcLeft);
      const tO = tRO + CH * (c + trgLeft);

      trgImage.data.set(srcImage.data.slice(sO, sO + CH), tO);
    }
  }

  return true;
}

/**
 * Return the linear index of a pixel's first channel location
 * within an image data storage.
 *
 * @param image the source image
 * @param pixel the column (x) and row (y) index
 * @returns the linear index
 */
export function getPixelIndex<NC extends number>(
  { channels, width, height }: ReadonlyCanvasImage<NC>,
  [x, y]: RFV2,
) {
  console.assert(
    x >= 0 && x < width &&
    y >= 0 && y < height,
    'Pixel index out of bounds',
    x,
    y,
    width,
    height,
  )
  return channels * (y * width + x)
}

/**
 * Returns the linear index of a row's first pixel channel's location
 * within an image data storage.
 *
 * @param image the source image
 * @param row the row (y) index
 * @returns the linear index
 */
export function getRowIndex<NC extends number>(
  { channels, width, height }: ReadonlyCanvasImage<NC>,
  row: number,
) {
  console.assert(
    row >= 0 && row < height,
    'Row index out of bounds',
    row,
    height,
  )
  return channels * row * width
}

/**
 * Pixel index type, which can either be
 * - a `[x,y]` coordinate pair
 * - a numeric index associated with a specific image
 *
 * Beware that the latter numeric index is strictly bound to an image.
 * One should be careful when using it to access a different image.
 *
 * The numeric index can be computed with `getPixelIndex` or directly as
 * ```
 * channels * (width * y + x)
 * ```
 *
 * @see {@link getPixelIndex}
 */
export type PixelIndex = RFV2 | number

/**
 * Extract a pixel from an image as subarray reference
 *
 * @param image the source image
 * @param idx the pixel location (`[x,y]` or `channels * (width * y + x)`)
 * @returns a reference to the subarray corresponding to the pixel
 */
export function getPixelRef<NC extends number>(
  image: ReadonlyCanvasImage<NC>,
  idx: PixelIndex,
): ReadonlyUint8Array {
  if(typeof idx !== 'number') {
    idx = getPixelIndex(image, idx)
  }
  return image.data.subarray(idx, idx + image.channels)
}

/**
 * Extract a copy of a pixel from an image
 *
 * @param image the source image
 * @param idx the pixel location (`[x,y]` or `channels * (width * y + x)`)
 * @returns a copy of the pixel data
 */
export function getPixel<NC extends number>(
  image: ReadonlyCanvasImage<NC>,
  idx: PixelIndex,
): Uint8Array {
  if(typeof idx !== 'number') {
    idx = getPixelIndex(image, idx)
  }
  return image.data.slice(idx, idx + image.channels)
}

/**
 * Extract a pixel channel directly
 *
 * @param image the source image
 * @param idx the pixel location (`[x,y]` or `channels * (width * y + x)`)
 * @param c the channel
 * @returns the value of the channel at the corresponding pixel locaiton
 */
export function getPixelChannel<NC extends number>(
  image: ReadonlyCanvasImage<NC>,
  idx: PixelIndex,
  c: number,
) {
  console.assert(
    c >= 0 && c < image.channels,
    `Channel is out of bounds: got ${c}, for nc=${image.channels}`,
  )
  if(typeof idx !== 'number') {
    idx = getPixelIndex(image, idx)
  }
  return image.data[idx + c]
}

/**
 * Set the content of a specific image pixel.
 *
 * This assumes that `fill` has cardinality `channels`.
 *
 * @param image the image to modify
 * @param idx the pixel location `[x,y]` or its index `channels * (width * y + x)`
 * @param fill the new pixel content
 */
export function setPixel<NC extends number>(
  image: CanvasImage<NC>,
  idx: PixelIndex,
  fill: ArrayLike<number>,
) {
  if(typeof idx !== 'number') {
    idx = getPixelIndex(image, idx)
  }
  console.assert(fill.length === image.channels, 'Fill value does not match the number of channels')
  image.data.set(fill, idx)
}

/**
 * Fill an image with a given pixel data.
 *
 * @param image the image to modify
 * @param fill the new pixel content
 */
export function fillPixels<NC extends number>(
  image: CanvasImage<NC>,
  fill: ArrayLike<number>,
) {
  const { width, height, channels } = image
  console.assert(fill.length === channels, 'Fill value does not match the number of channels')
  for(let r = 0; r < height; ++r) {
    for(let c = 0; c < width; ++c) {
      const idx = getPixelIndex(image, [c, r])
      image.data.set(fill, idx)
    }
  }
}

/**
 * Set the content of a specific image pixel channel.
 *
 * @param image the image to modify
 * @param idx the pixel location `[x,y]` or its index `channels * (width * y + x)`
 * @param c the channel
 * @param value the new value
 */
export function setPixelChannel<NC extends number>(
  image: CanvasImage<NC>,
  idx: PixelIndex,
  c: number,
  value: number,
) {
  if(typeof idx !== 'number') {
    idx = getPixelIndex(image, idx)
  }
  image.data[idx + c] = value
}

/**
 * Set the content of a full image row.
 * This assumes that `fill` has the cardinality
 * matching that of a row (i.e., `width * channels`).
 *
 * @param image the image, modified
 * @param row the row index
 * @param fill the new row content
 */
export function setPixelRow<NC extends number>(
  image: CanvasImage<NC>,
  row: number,
  fill: ArrayLike<number>,
) {
  console.assert(
    fill.length === image.channels * image.width,
    'Fill value does not match the row cardinality',
  )
  const idx = getPixelIndex(image, [0, row])
  image.data.set(fill, idx)
}

/**
 * Check that a region is not out of bounds for a given image
 *
 * @param img the source image
 * @param L the left bound, inclusive
 * @param B the bottom bound, inclusive
 * @param R the right bound, exclusive
 * @param T the top bound, exclusive
 */
function checkRegionRange<NC extends number>(
  { width, height }: ReadonlyCanvasImage<NC>,
  L: number,
  B: number,
  R: number,
  T: number,
) {
  console.assert(L >= 0 && L < width, 'Left is out of bounds', L, width)
  console.assert(B >= 0 && B < height, 'Bottom is out of bounds', B, height)
  console.assert(R > 0 && R <= width, 'Right is out of bounds', R, width)
  console.assert(T > 0 && T <= height, 'Top is out of bounds', T, height)
}

/**
 * Copy a region of a source image into a target image.
 * The source is not modified, whereas the target is.
 *
 * The source and target images must have the same number of channels.
 * The source and target regions must have matching sizes.
 *
 * @param srcImage the source image, untouched
 * @param srcRect the source region
 * @param trgImage the target image, modified
 * @param trgRect the target region
 * @returns true if the copy was valid, else otherwise
 */
export function copyImageToImage<NC extends number>(
  srcImage: ReadonlyCanvasImage<NC>,
  [srcLeft, srcBottom, srcRight, srcTop]: RFV4,
  trgImage: CanvasImage<NC>,
  [trgLeft, trgBottom, trgRight, trgTop]: RFV4,
): boolean {
  const srcCols = srcRight - srcLeft
  const srcRows = srcTop - srcBottom
  const trgCols = trgRight - trgLeft
  const trgRows = trgTop - trgBottom
  const CH = srcImage.channels;
  if(
    srcCols !== trgCols ||
    srcCols < 0 ||
    srcRows !== trgRows ||
    srcRows < 0 ||
    CH !== trgImage.channels
  ) {
    return false
  }

  // catch degenerate cases
  if(srcRows === 0 || srcCols === 0) {
    console.warn('Copying an empty image region', srcRows, srcCols)
    return true
  }

  // validate indexing
  checkRegionRange(srcImage, srcLeft, srcBottom, srcRight, srcTop)
  checkRegionRange(trgImage, trgLeft, trgBottom, trgRight, trgTop)

  const sW = srcImage.width;
  const tW = trgImage.width;
  // check for special full-width case
  if(sW === tW && srcCols === sW) {
    // we can copy the whole block at once
    const srcOffStart = getPixelIndex(srcImage, [0, srcBottom])
    const trgOffStart = getPixelIndex(trgImage, [0, trgBottom])
    const len = CH * srcCols * srcRows
    trgImage.data.set(
      srcImage.data.subarray(srcOffStart, srcOffStart + len),
      trgOffStart,
    )
  } else {
    // we must copy blocks per row
    const len = CH * srcCols
    for(let r = 0; r < srcRows; ++r) {
      const srcOffStart = getPixelIndex(srcImage, [srcLeft, srcBottom + r])
      const trgOffStart = getPixelIndex(trgImage, [trgLeft, trgBottom + r])
      trgImage.data.set(
        srcImage.data.subarray(srcOffStart, srcOffStart + len),
        trgOffStart,
      )
    }
  }
  return true;
}

// benchmarking to check which copy method is the best
/*
const a = makeCanvasImage(new Uint8Array(1000 * 100 * 3), 100, 1000, 3)
const b = copyCanvasImage(a)
b.data.fill(1, 0)
const times = Array.from({ length: 100 }, (_, i) => {
  const h = 1 + i * 5
  const w = 5 * Math.ceil((1 + i) / 5)
  let t = Date.now()
  for(let i = 0; i < 1e3; ++i){
    copyImageToImage(
      a, [0, 0, w, h],
      b, [0, 100, w, 100 + h],
    )
  }
  const dt_new = Date.now() - t
  t = Date.now()
  for(let i = 0; i < 1e3; ++i){
    legacyCopyImageToImage(
      a, [0, 0, w, h],
      b, [0, 100, w, 100 + h],
    )
  }
  const dt_leg = Date.now() - t
  return `w=${w}, h=${h}, leg=${dt_leg}, new=${dt_new}`
})
console.log(times)
// */

/*
// Results:
w=5, h=1, leg=4, new=7
w=5, h=6, leg=4, new=6
w=5, h=11, leg=7, new=2 <-- threshold
w=5, h=16, leg=10, new=2
w=5, h=21, leg=12, new=3
w=10, h=26, leg=28, new=3
w=10, h=31, leg=42, new=4
w=10, h=36, leg=43, new=4
w=10, h=41, leg=43, new=4
w=10, h=46, leg=79, new=5
w=15, h=51, leg=95, new=7
w=15, h=56, leg=92, new=6
w=15, h=61, leg=99, new=6
w=15, h=66, leg=106, new=6
w=15, h=71, leg=112, new=6
w=20, h=76, leg=175, new=7
w=20, h=81, leg=166, new=8
w=20, h=86, leg=186, new=8
w=20, h=91, leg=193, new=10
w=20, h=96, leg=199, new=9
w=25, h=101, leg=269, new=9
w=25, h=106, leg=277, new=9
w=25, h=111, leg=288, new=11
w=25, h=116, leg=301, new=10
w=25, h=121, leg=312, new=12
w=30, h=126, leg=391, new=12
w=30, h=131, leg=408, new=12
w=30, h=136, leg=426, new=12
w=30, h=141, leg=439, new=14
w=30, h=146, leg=454, new=13
w=35, h=151, leg=546, new=13
w=35, h=156, leg=575, new=15
w=35, h=161, leg=607, new=15
w=35, h=166, leg=663, new=17
w=35, h=171, leg=645, new=17
w=40, h=176, leg=754, new=17
w=40, h=181, leg=741, new=16
w=40, h=186, leg=765, new=17
w=40, h=191, leg=917, new=18
w=40, h=196, leg=833, new=36
w=45, h=201, leg=1016, new=18
w=45, h=206, leg=953, new=19
w=45, h=211, leg=1001, new=22
w=45, h=216, leg=1095, new=20
w=45, h=221, leg=994, new=20
w=50, h=226, leg=1219, new=21
w=50, h=231, leg=1242, new=21
w=50, h=236, leg=1177, new=22
w=50, h=241, leg=1201, new=22
w=50, h=246, leg=1320, new=23
w=55, h=251, leg=1532, new=23
w=55, h=256, leg=1418, new=37
w=55, h=261, leg=1429, new=24
w=55, h=266, leg=1548, new=24
w=55, h=271, leg=1627, new=25
w=60, h=276, leg=1756, new=26
w=60, h=281, leg=1684, new=26
w=60, h=286, leg=1716, new=27
w=60, h=291, leg=1868, new=27
w=60, h=296, leg=1993, new=27
w=65, h=301, leg=2320, new=28
w=65, h=306, leg=2344, new=27
w=65, h=311, leg=2084, new=28
w=65, h=316, leg=2080, new=28
w=65, h=321, leg=2399, new=28
w=70, h=326, leg=2342, new=31
w=70, h=331, leg=2397, new=31
w=70, h=336, leg=2535, new=33
w=70, h=341, leg=2445, new=32
w=70, h=346, leg=2378, new=33
w=75, h=351, leg=2633, new=33
w=75, h=356, leg=2715, new=58
w=75, h=361, leg=2673, new=36
w=75, h=366, leg=2778, new=34
w=75, h=371, leg=2800, new=33
w=80, h=376, leg=3098, new=34
w=80, h=381, leg=3046, new=33
w=80, h=386, leg=3193, new=34
w=80, h=391, leg=3133, new=35
w=80, h=396, leg=3189, new=35
w=85, h=401, leg=3397, new=36
w=85, h=406, leg=3344, new=36
w=85, h=411, leg=3383, new=37
w=85, h=416, leg=3426, new=37
w=85, h=421, leg=3532, new=38
w=90, h=426, leg=3766, new=39
w=90, h=431, leg=3885, new=39
w=90, h=436, leg=3889, new=40
w=90, h=441, leg=3844, new=41
w=90, h=446, leg=3975, new=42
w=95, h=451, leg=4219, new=42
w=95, h=456, leg=4206, new=40
w=95, h=461, leg=4381, new=44
w=95, h=466, leg=4413, new=42
w=95, h=471, leg=4313, new=43
w=100, h=476, leg=4632, new=6
w=100, h=481, leg=4866, new=6
w=100, h=486, leg=5044, new=6
w=100, h=491, leg=5042, new=6
w=100, h=496, leg=4962, new=6
// */
