import { addRowsAboveTop, deleteRowsAbove } from 'src/data/time-needle/topology'
import {
  BedSide,
  getOpts,
  otherDir,
  PassOptions,
  SelectorLike,
  TimeNeedleTransformer,
  UserDirection,
  YarnIndex,
} from './common'

export interface BindoffOptions {
  side?: BedSide
  yarn?: YarnIndex
  direction?: Exclude<UserDirection, 2>
  transferOptions?: PassOptions
  knittingOptions?: PassOptions
  knitFirstStitch?: boolean
  deleteAbove?: boolean
  reverseKnit?: boolean
}

export function generateBindoff(s: SelectorLike, options: BindoffOptions = {}) {
  // resolve options
  const bindDir = options.direction ?? 0
  const side = options.side ?? 0
  const yarn = options.yarn ?? 2
  const knitFirst = options.knitFirstStitch ?? false
  const deleteAbove = options.deleteAbove ?? false
  const reverseKnit = options.reverseKnit ?? false
  const knitDir = reverseKnit ? otherDir(bindDir) : bindDir
  // pass options
  const xferOpts = {
    roller: 0,
    speed: 60,
    stitchSize: 4,
  }
  const knitOpts = (i: number) => ({
    roller: [250, 200, 150][i] || 150,
    speed: 300,
    direction: knitDir,
    stitchSize: 4,
  })

  // bindoff implementation
  const bindoffWidth = s.getExtents().width
  const numRows = bindoffWidth * 3 - (
    (deleteAbove ? 2 : 0) + // delete => skip last two transfers
    (knitFirst ? 0 : 1) // !knitFirst => skip first knit
  )

  // optionally delete rows above
  let t: TimeNeedleTransformer
  if(deleteAbove) {
    t = deleteRowsAbove(s)
  }
  // insert necessary rows
  let rows: TimeNeedleTransformer
  [t, rows] = addRowsAboveTop(t ?? s, numRows)
  // select initial binding off stitch cell
  const topRow = t.topmost()
  let st = bindDir === 0 ? topRow.first() : topRow.last()
  const dx = bindDir === 0 ? +1 : -1
  for(let i = 0; i < bindoffWidth && !st.isEmpty(); ++i) {
    // knit first
    if(i > 0 || knitFirst) {
      st = st.up().knit(side, yarn).options(
        getOpts(i, bindoffWidth, options.knittingOptions ?? {}, knitOpts),
      )
    }
    st = st.up().xfer(side, 0).options(
      getOpts(i, bindoffWidth, options.transferOptions ?? {}, xferOpts),
    ).up()
      .xfer(side === 0 ? 1 : 0, dx)
      .options(
        getOpts(i, bindoffWidth, options.transferOptions ?? {}, xferOpts),
      )
      .neighbor(dx, 0)
  }
  return rows.waleSet(s.getColumnSet())
}
