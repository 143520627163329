import {
  useRef,
  useState,
  ChangeEvent,
  PointerEvent,
} from 'react'
import classNames from 'classnames'

import CodeMirror from '@uiw/react-codemirror'
import { javascript } from '@codemirror/lang-javascript'
import noise from 'simplenoise'
import { useThrottledCallback } from 'use-debounce'
import TimeNeedleTransformer, { TimeNeedleCellWriter } from 'src/data/time-needle/transformer'
import { exportFile } from 'src/common/files'
import { evalCode } from 'src/common/scripts'
import { useApplyMacro } from 'src/hooks'

import 'src/styles/ribbon-scripts.scss'

interface PropDescriptor {
  name: string
  type: 'prop' | 'func'
  numArgs?: number
}

function getProp(obj: any, propName: string) {
  const { get, value } = Object.getOwnPropertyDescriptor(obj, propName)
  return (get || typeof value !== 'function' ? {
    name: propName,
    type: 'prop',
  } : {
    name: propName,
    type: 'func',
    numArgs: value.length as number,
  }) as PropDescriptor
}

function getProps(proto: any, instance: any): PropDescriptor[] {
  return Object.getOwnPropertyNames(proto).flatMap((propName) => {
    if(propName === 'constructor') {
      return []
    }
    return [getProp(proto, propName)]
  }).concat(Object.getOwnPropertyNames(instance).map((propName) => getProp(instance, propName)))
}
const pinst = TimeNeedleTransformer.empty()
const PanelProps = getProps(TimeNeedleTransformer.prototype, pinst).sort((p1, p2) => (p1.name < p2.name ? -1 : 1))
const CellProps = getProps(TimeNeedleCellWriter.prototype, pinst.first().asCell()).sort((p1, p2) => (p1.name < p2.name ? -1 : 1))

function Scripts({ title = '' }) {
  const fileRef = useRef<HTMLInputElement>()
  const applyMacro = useApplyMacro()
  const [showDocu, setShowDocu] = useState(false)
  const [script, setScript] = useState('// write script here')
  const [scriptError, setScriptError] = useState('')
  const [scriptResult, setScriptResult] = useState('')

  const downloadScript = (event: PointerEvent<HTMLAnchorElement>) => {
    exportFile('script.js', script, { link: event.target as HTMLAnchorElement })
  }

  const loadScript = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = () => {
      const srcText = reader.result as string
      console.log(`Loaded:\n${srcText}`)
      setScript(srcText)
    }
    console.log(`Loading file ${file}`)
    reader.readAsText(file)
  }

  const updateScript = useThrottledCallback((value: string) => {
    try {
      // we test the code as applied to an empty panel
      const p = TimeNeedleTransformer.empty()
      const s = p.empty()
      evalCode(value, {
        p, s, Math, noise,
      }, [], true)

      // no error was found
      setScriptError('')
    } catch(err) {
      setScriptError(err.toString())
    }

    setScript(value)
    setScriptResult('')
  }, 500)

  const applyScript = () => {
    const changed = applyMacro((s) => {
      try {
        const ret = evalCode(script, {
          p: s.all(), s, Math, noise,
        }, [], 'Applying script')
        if(ret instanceof TimeNeedleTransformer) {
          return ret
        }
      } catch(err) {
        setScriptError(err.toString())
      }
    })
    setScriptResult(changed ? 'Change applied' : 'No change')
  }

  return (
    <div className="scriptbox">
      <div className="script-panel">
        <div className="help" onClick={() => { setShowDocu(!showDocu) }} />
        <div className={classNames('documentation', { visible: showDocu })}>
          <h2>Arguments</h2>
          <ul className="args">
            <li><span className="name">p</span> the whole panel <span className="type">PanelTransformer</span></li>
            <li><span className="name">s</span> the panel selection <span className="type">PanelTransformer</span></li>
            <li><span className="name">Math</span> see <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math" target="_blank" rel="noreferrer">documentation</a></li>
            <li><span className="name">noise</span> see <a href="https://www.npmjs.com/package/simplenoise" target="_blank" rel="noreferrer">simplenoise</a></li>
          </ul>
          <h2>PanelTransformer</h2>
          <ul className="compact">
            {PanelProps.map(({ name, type, numArgs = 0 }: PropDescriptor, i) => (
              <li key={i} className={classNames(type, `args-${numArgs}`)}>
                <span className="name">{name}</span>
              </li>
            ))}
          </ul>
          <h2>PanelCell</h2>
          <ul className="compact">
            {CellProps.map(({ name, type, numArgs = 0 }: PropDescriptor, i) => (
              <li key={i} className={classNames(type, `args-${numArgs}`)}>
                <span className="name">{name}</span>
              </li>
            ))}
          </ul>
        </div>
        <CodeMirror
          value={script}
          height="400px"
          extensions={[javascript({ jsx: false, typescript: false })]}
          onChange={updateScript}
        />
        <div className={classNames('script-log', {
          error: scriptError.length > 0,
          result: scriptResult.length > 0,
        })}
        >{ scriptError || scriptResult }
        </div>
        <div className="script-actions">
          <a onClick={downloadScript}>Save file</a>
          <button onClick={() => fileRef.current?.click()}>Load file</button>
          <input ref={fileRef} type="file" hidden accept=".js" onChange={loadScript} />
          <button onClick={applyScript} disabled={scriptError.length > 0}>Apply</button>
        </div>
      </div>
    </div>
  )
}

export default Scripts
