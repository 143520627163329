import { ByteBuffer, Builder } from 'flatbuffers';
import TimeNeedleImage, {
  createTimeNeedleImage,
  ReadonlyTimeNeedleImage,
} from 'src/data/time-needle/time-needle-image';
import { getOptions, KniterateOptions, setOption } from 'src/data/time-needle/options';
import {
  getNeedleCodePair, getStitchCode, NeedleCode, StitchCode,
} from 'src/data/time-needle/stitch-code';
import { getPixelChannel, setPixelChannel } from 'src/data/image';
import { buck } from './buck_generated';

const Mode = buck.Mode
/*
NOTES:

1) If instead of creating a vector from an existing array you serialize elements individually one by one,
   take care to note that this happens in reverse order, as buffers are built back to front.
 */

/**
 * Mapping from needle code to buck mode
 */
const NeedleCodeToBuckMode = {
  // miss codes
  [NeedleCode.MISS]: Mode.Miss,
  [NeedleCode.XMISS]: Mode.XplctMiss,
  // base codes
  [NeedleCode.KNIT]: Mode.Knit,
  [NeedleCode.TUCK]: Mode.Tuck,
  [NeedleCode.SPLT]: Mode.Splt,
  [NeedleCode.DROP]: Mode.Drop,
  // transfer codes
  [NeedleCode.XFER]: Mode.Xfer,
  [NeedleCode.XFERL1]: Mode.XferL1,
  [NeedleCode.XFERL2]: Mode.XferL2,
  [NeedleCode.XFERL3]: Mode.XferL3,
  [NeedleCode.XFERL4]: Mode.XferL4,
  [NeedleCode.XFERR1]: Mode.XferR1,
  [NeedleCode.XFERR2]: Mode.XferR2,
  [NeedleCode.XFERR3]: Mode.XferR3,
  [NeedleCode.XFERR4]: Mode.XferR4,
  // special case
  [NeedleCode.INVALID]: Mode.Miss,
} as { [nc in NeedleCode]: buck.Mode }

function fromNeedleCodeToBuckMode(nc: NeedleCode) {
  return NeedleCodeToBuckMode[nc] ?? buck.Mode.Miss
}

const BuckModeToNeedleCode = Object.entries(NeedleCodeToBuckMode).reduce(
  (obj, [key, bm]) => {
    const nc = parseInt(key, 10) as NeedleCode
    if(nc === NeedleCode.INVALID) {
      return obj
    }
    return Object.assign(obj, { [bm]: nc })
  },
  {},
) as { [bm in buck.Mode]: NeedleCode }

function fromBuckModeToNeedleCode(bm: buck.Mode) {
  return BuckModeToNeedleCode[bm] ?? NeedleCode.INVALID
}

function toLegacyYarn(yarn: number) {
  return yarn === 0 ? 6 : yarn - 1
}

function fromLegacyYarn(yarn: number) {
  return yarn === 6 ? 0 : yarn + 1
}

function getNeedleCode(
  tni: ReadonlyTimeNeedleImage,
  x: number,
  y: number,
  side: 0 | 1,
  racking = 0,
) {
  // for the rear bed, we shift given the racking
  if(side === 1) {
    x = x + Math.floor(racking) // corresponding front offset
  }
  // due to the rear shift, we may be indexing outside of range
  if(x < 0 || x > tni.cdata.width - 1) {
    return NeedleCode.MISS // those have no rear information
  }
  // retrieve needle code
  const sc = getPixelChannel(tni.cdata, [x, y], 0)
  return getNeedleCodePair(sc as StitchCode)[side]
}

function setNeedleCode(
  tni: TimeNeedleImage,
  x: number,
  y: number,
  side: 0 | 1,
  nc: NeedleCode,
  racking = 0,
) {
  // for the rear bed, we shift given the racking
  if(side === 1) {
    x = x + Math.floor(racking)
    if(x < 0 || x > tni.cdata.width - 1) {
      return // out of data range, do nothing!
    }
    if(nc === NeedleCode.XMISS) {
      return // don't use rear information
    }
  }
  // retrieve current needle codes
  const oldSC = getPixelChannel(tni.cdata, [x, y], 0)
  const [fnc, rnc] = getNeedleCodePair(oldSC as StitchCode)

  // compute new stitch code (may be invalid)
  const newSC = getStitchCode(side === 0 ? [nc, rnc] : [fnc, nc])
  if(newSC === StitchCode.INVALID) {
    return true
  }
  // it's valid, so set it
  setPixelChannel(tni.cdata, [x, y], 0, newSC)
}

export function LegacySerialize(tni: ReadonlyTimeNeedleImage): Uint8Array {
  const builder = new Builder(1024); // Should be able to estimate this *exactly* once we know the vagaries of the format
  if(tni.type && tni.type !== 'kniterate') {
    throw `Legacy serialization does not support the following machine type: ${tni.type}`
  }

  /*
     We're going to implement the buck conversion as follows:

     1) Convert each row of the image into a pass of length panel.width
     2) Add image values for each row, add opt column to pass
     3) Emit program
    */

  const passes = new Array<number>()

  const { width: W, height: H } = tni.cdata
  for(let y = 0; y !== H; ++y) {
    // Front Bed
    const fYA = new Array<number>(W)
    const fSA = new Array<number>(W)
    const fMA = new Array<number>(W)

    // Rear Bed
    const rYA = new Array<number>(W)
    const rSA = new Array<number>(W)
    const rMA = new Array<number>(W)

    // read options
    const [
      yarnNum,
      speed,
      direction,
      fss,
      racking,
      rss,
      roller,
    ] = getOptions(
      tni.odata,
      y,
      KniterateOptions.carrier,
      KniterateOptions.carriageSpeed,
      KniterateOptions.direction,
      KniterateOptions.frontStitchSize,
      KniterateOptions.racking,
      KniterateOptions.rearStitchSize,
      KniterateOptions.roller,
    )

    // yarn translation
    const yarn = toLegacyYarn(yarnNum)

    for(let x = 0; x !== W; ++x) {
      fYA[x] = yarn
      fSA[x] = fss
      fMA[x] = fromNeedleCodeToBuckMode(getNeedleCode(tni, x, y, 0))

      rYA[x] = yarn
      rSA[x] = rss
      rMA[x] = fromNeedleCodeToBuckMode(getNeedleCode(tni, x, y, 1, racking))
    }

    const fY = buck.Bed.createYarnVector(builder, fYA)
    const fS = buck.Bed.createSizeVector(builder, fSA)
    const fM = buck.Bed.createModeVector(builder, fMA)

    buck.Bed.startBed(builder)
    buck.Bed.addYarn(builder, fY)
    buck.Bed.addSize(builder, fS)
    buck.Bed.addMode(builder, fM)
    const frontBed = buck.Bed.endBed(builder)

    const rY = buck.Bed.createYarnVector(builder, rYA)
    const rS = buck.Bed.createSizeVector(builder, rSA)
    const rM = buck.Bed.createModeVector(builder, rMA)

    // Rear Bed
    buck.Bed.startBed(builder)
    buck.Bed.addYarn(builder, rY)
    buck.Bed.addSize(builder, rS)
    buck.Bed.addMode(builder, rM)
    const rearBed = buck.Bed.endBed(builder)

    const beds = buck.Pass.createBedsVector(builder, [frontBed, rearBed])

    buck.Bedless.startBedless(builder)
    buck.Bedless.addRacking(builder, racking)
    buck.Bedless.addDir(builder, direction)
    buck.Bedless.addCarriageSpeed(builder, speed)
    buck.Bedless.addRollDuring(builder, roller)
    buck.Bedless.addRollAfter(builder, 0)
    buck.Bedless.addRollerSpeed(builder, 0)
    const bedless = buck.Bedless.endBedless(builder)

    // Pass
    buck.Pass.startPass(builder)
    buck.Pass.addBeds(builder, beds)
    buck.Pass.addBedless(builder, bedless)

    passes.push(buck.Pass.endPass(builder))
  }

  // Now, the Program
  const passesVec = buck.Program.createPassesVector(builder, passes);
  const prog = buck.Program.createProgram(builder, parseInt(process.env.BUCK_VERSION, 10), passesVec); // backward compat, so store latest version that wrote it.
  builder.finish(prog);
  return builder.asUint8Array();
}

export function getBuckVersion(buffer: Uint8Array) {
  const buf = new ByteBuffer(buffer)
  const prog = buck.Program.getRootAsProgram(buf)
  return prog.rev()
}

export const CURRENT_BUCK_VERSION = parseInt(process.env.BUCK_VERSION, 10)

// We need to reconstruct the Panel from the Input byte array
export function LegacyDeserialize(
  buffer: Uint8Array,
  issues: string[] = [],
): TimeNeedleImage {
  const buf = new ByteBuffer(buffer);
  const prog = buck.Program.getRootAsProgram(buf)

  if(prog.passesLength() === 0) {
    issues.push('Invalid buffer or some other issue')
    return null
  }
  const buckVersion = prog.rev()
  if(buckVersion > CURRENT_BUCK_VERSION) {
    // the app version that last saved the design being opened is newer than this version of the app:
    // it will almost certainly crash. So let's return a nice error
    issues.push('The app version that last saved this design is newer than this version of the app.')
  }
  const H = prog.passesLength()
  const W = prog.passes(0).beds(0).yarnLength()

  const tni = createTimeNeedleImage(W, H, 'kniterate', false)

  let hasJacquard = false
  let hasInvalidCode = false
  for(let y = 0; y !== H; ++y) {
    // The passes
    const pass = prog.passes(y)
    const bedless = pass.bedless()
    let racking = 0
    if(bedless) {
      setOption(tni.odata, y, KniterateOptions.direction, bedless.dir())
      racking = bedless.racking()
      setOption(tni.odata, y, KniterateOptions.racking, racking)
      setOption(tni.odata, y, KniterateOptions.carriageSpeed, bedless.carriageSpeed())
      setOption(tni.odata, y, KniterateOptions.roller, bedless.rollDuring())
    }
    const yarns = new Set<number>()
    let fss: number
    let rss: number
    for(let x = 0; x !== W; ++x) {
      // Front
      const front = pass.beds(0)
      const fy = fromLegacyYarn(front.yarn(x))
      yarns.add(fy)
      const fnc = fromBuckModeToNeedleCode(front.mode(x))
      if(setNeedleCode(tni, x, y, 0, fnc)) {
        hasInvalidCode = true
      }
      if(x === 0) fss = front.size(x)

      // Rear
      const rear = pass.beds(1)
      const ry = fromLegacyYarn(rear.yarn(x))
      yarns.add(ry)
      const rnc = fromBuckModeToNeedleCode(rear.mode(x))
      if(setNeedleCode(tni, x, y, 1, rnc, racking)) {
        hasInvalidCode = true
      }
      if(x === 0) rss = rear.size(x)
    }

    if(bedless) {
      setOption(tni.odata, y, KniterateOptions.frontStitchSize, fss ?? 0)
      setOption(tni.odata, y, KniterateOptions.rearStitchSize, rss ?? 0)

      // resolve yarn and Jacquard cases
      if(yarns.size > 1) {
        // some yarn is obviously going on
        // => remove empty carrier from potential list
        yarns.delete(0)
      }
      const [yarn, ...otherYarns] = [...yarns]
      if(otherYarns.length) {
        hasJacquard = true
      }
      setOption(tni.odata, y, KniterateOptions.carrier, yarn)
    }
  }

  if(hasJacquard) {
    issues.push('Jacquard rows cannot be represented anymore')
  }
  if(hasInvalidCode) {
    issues.push('Some stitch codes were invalid after deserialization')
  }

  return tni
}
