import { ReadonlyTimeNeedleImage } from 'src/data/time-needle/time-needle-image'
import { deleteRows } from 'src/data/time-needle/topology'
import {
  SelectorLike, TimeNeedleSelector, TimeNeedleTransformer, UserDirection, YarnIndex,
} from './common'

export function setYarn(s: TimeNeedleTransformer, Y: YarnIndex): void {
  // legacy: s.carrier(0).filter((c) => c.isCarrierAction()).carrier(Y)
  const rows = s.splitByRow()
  for(const row of rows) {
    if(row.some((c) => c.isCarrierAction())) {
      row.first().carrier(Y)
    } else {
      // check if there is an action somewhere else on the full row
      const fullRow = row.fullCourse(row.first().asCell().row)
      const hasCarrier = fullRow.some((c) => c.isCarrierAction())
      const isEmpty = fullRow.every((c) => c.isMiss())
      if(!hasCarrier && !isEmpty) {
        // some non-carrier action but no carrier action
        // => set as transfer row
        row.first().carrier(0)
      }
    }
  }
}

export function setDirection(s: TimeNeedleTransformer, direction: UserDirection, stitchOnly = true): void {
  if(stitchOnly) {
    s.filter((c) => c.isKnit() || c.isTuck() || c.isSplit()).options({
      direction,
    })
  } else {
    s.options({ direction })
  }
}

export function clearRows(s: TimeNeedleTransformer): void {
  s.extendLeft().extendRight().miss()
}

export function clearColumns(s: TimeNeedleTransformer): void {
  s.extendUp().extendDown().miss()
}

export function deleteTransferRows(
  s: SelectorLike,
  withinSelection = false,
) {
  const delSet = new Set<number>()
  for(const rowSel of s.splitByRow(false)) {
    const rowIdx = rowSel.first().asCell().row
    const row = withinSelection ? rowSel : s.fullCourse(rowIdx)
    if(row.every((c) => c.isMiss() || c.isTransfer())) {
      delSet.add(rowIdx)
    }
  }
  const ds = TimeNeedleSelector.fromRows(s.image, delSet)
  return deleteRows(ds)
}

export function alternateDirection(s: TimeNeedleTransformer, initDir: Exclude<UserDirection, 2>): void {
  const rows = s.splitByRow()
  let direction = initDir
  for(const row of rows) {
    if(row.some((c) => c.isCarrierAction())) {
      row.options({ direction })
      direction = direction === 0 ? 1 : 0
    } else if(row.some((c) => c.isTransfer())) {
      row.options({ direction: 2 })
    }
    // else it has no information so it may be out of the user selection
  }
}

export function testtest(x, y) {
  return x + y
}
