import { extendLaterallyToKnitted, extendVerticallyToKnitted } from 'src/macros'
import { useSelectMacro } from 'src/hooks'
import ContextMenuItem, { Separator } from './menu-item'

export default function ExtendMenu() {
  const selectMacro = useSelectMacro()
  return (
    <ContextMenuItem
      name="Extend selection"
      menu
    >
      <ContextMenuItem
        name="Course-wise"
        onClick={() => {
          selectMacro((s) => s.extendLeft().extendRight())
        }}
        chainable
      />
      <ContextMenuItem
        name="Course-wise to knit"
        onClick={() => {
          selectMacro((s) => extendLaterallyToKnitted(s))
        }}
        chainable
      />
      <ContextMenuItem
        name="Wale-wise"
        onClick={() => {
          selectMacro((s) => s.extendUp().extendDown())
        }}
        chainable
      />
      <ContextMenuItem
        name="Wale-wise to knit"
        onClick={() => {
          selectMacro((s) => extendVerticallyToKnitted(s))
        }}
        chainable
      />
      { /* */ }
      <Separator />
      { /* */ }
      <ContextMenuItem
        name="To top"
        onClick={() => {
          selectMacro((s) => s.extendUp())
        }}
        chainable
      />
      <ContextMenuItem
        name="To bottom"
        onClick={() => {
          selectMacro((s) => s.extendDown())
        }}
        chainable
      />
      <ContextMenuItem
        name="To left"
        onClick={() => {
          selectMacro((s) => s.extendLeft())
        }}
        chainable
      />
      <ContextMenuItem
        name="To right"
        onClick={() => {
          selectMacro((s) => s.extendRight())
        }}
        chainable
      />
      <ContextMenuItem
        name="To all"
        onClick={() => {
          selectMacro((s) => s.all())
        }}
        chainable
      />
    </ContextMenuItem>
  )
}
