import classNames from 'classnames'
import { useEffect } from 'react'
import { shallowEqual } from 'react-redux'
import usePrompt from 'use-prompt'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import Modal from 'src/editor/modal'
import { dismissNotification, NotificationType } from './slice'

const notificationTypeMapping = {
  [NotificationType.SUCCESS]: 'success',
  [NotificationType.INFO]: 'info',
  [NotificationType.WARNING]: 'warning',
  [NotificationType.ERROR]: 'error',
}

export default function Notifier() {
  const dispatch = useAppDispatch()
  const notification = useAppSelector((({ notify: { current }}) => current), shallowEqual)
  const [error, setError] = usePrompt()
  useEffect(() => {
    if(notification) {
      const { duration, type, message } = notification
      setError(({resolve}) => {
        setTimeout(resolve, duration)
        return (
          <Modal>
            <div
              className="notification"
              tabIndex={-1}
              onKeyDown={({ key }) => key == 'Escape' && resolve(false)}
              onClick={({ target, currentTarget }) => target === currentTarget && resolve(false)}
            >
              <div className={classNames('dialog', notificationTypeMapping[type])}>
                <button className="close" onClick={() => resolve(false)}>✕</button>
                {message}
              </div>
            </div>
          </Modal>
        )
      }).then(() => dispatch(dismissNotification()))
    }
  }, [notification])
  return <>{error}</>
}
