import {
  useRef,
  useState,
  useLayoutEffect,
  ChangeEvent,
  FocusEvent,
  MutableRefObject,
} from 'react'
import classNames from 'classnames'
import FocusTrap from 'focus-trap-react'
import { useAppSelector, useForceUpdate } from 'src/hooks'
import { KniterateOptions } from 'src/data/time-needle/options/kniterate'
import type OptionField from 'src/data/time-needle/options/option-field'
import { useSnapshot } from 'src/hooks/snapshot'
import { getOption, setOption } from 'src/data/time-needle/options'
import { CellIndex, useGridSelection } from './grid-selection'

// the expected state information for a cell
export interface CellState {
  // selection
  selected: boolean
  // input edit
  inputEdit: string
  hasInputEdit: boolean
  hasEmptyEdit: boolean
  // drag edit
  dragEdit: number
  hasDragEdit: boolean
  // paste edit
  pasteEdit: number
  hasPasteEdit: boolean
}

function rowFieldToProps({ min, step, max }: OptionField): { min: number, step: number, max: number } {
  return { min, step, max }
}

export enum FieldType {
  Racking = 0,
  Speed,
  Roll,
  FrontStitch,
  RearStitch,
  Direction,
  Carrier,
}

export interface CellEdit {
  row: number
  field: FieldType
  value: number
}

export const FieldToOptionField = {
  [FieldType.Racking]: KniterateOptions.racking,
  [FieldType.Speed]: KniterateOptions.carriageSpeed,
  [FieldType.Roll]: KniterateOptions.roller,
  [FieldType.FrontStitch]: KniterateOptions.frontStitchSize,
  [FieldType.RearStitch]: KniterateOptions.rearStitchSize,
  [FieldType.Direction]: KniterateOptions.direction,
  [FieldType.Carrier]: KniterateOptions.carrier,
} as const

const FieldProps = {
  [FieldType.Racking]: rowFieldToProps(KniterateOptions.racking),
  [FieldType.Speed]: rowFieldToProps(KniterateOptions.carriageSpeed),
  [FieldType.Roll]: rowFieldToProps(KniterateOptions.roller),
  [FieldType.FrontStitch]: rowFieldToProps(KniterateOptions.frontStitchSize),
  [FieldType.RearStitch]: rowFieldToProps(KniterateOptions.rearStitchSize),
  [FieldType.Direction]: rowFieldToProps(KniterateOptions.direction),
  [FieldType.Carrier]: rowFieldToProps(KniterateOptions.carrier),
} as const

interface InputProps {
  type: FieldType
  filter: number[]
  onCommit?: (field: FieldType) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent) => void
}

// basic input field for column-wise editing of the selection
const OptionsInput = function ({
  type, filter, onCommit, ...otherProps
}: InputProps) {
  const fieldProps = FieldProps[type]
  const disabled = !filter.includes(type + 2)
  return (
    <input
      data-field={type}
      type="number"
      disabled={disabled}
      onBlur={() => onCommit(type)}
      {...fieldProps}
      {...otherProps}
    />
  )
}

/**
 * Makes use of the generic grid selection
 * while providing options-column specific editing capabilities.
 *
 * This deals with all necessary handlers and returns
 * the necessary selection + edit components.
 */
export function useGridEditing(
  optColRef: MutableRefObject<HTMLElement>,
  numRows: number,
  cellClass: string,
) {
  const inputsRef = useRef<HTMLDivElement>()
  const tnimage = useAppSelector((state) => state.canvas.tnimage)
  const snapshot = useSnapshot() // ({ debounce: 1000 })
  const numColumns = 8
  const lastFieldType = FieldType.Carrier

  // use the grid selection mechanism
  const {
    // states for editing
    dragDataStartRow,
    dragDataEndRow,
    isDragOverwritten,
    isDragSelecting,
    isPasted,
    isSelected,
    isSelectionReady,
    pasteColumnStart,
    pasteData,
    pasteHeight,
    pasteSelection,
    pasteSourceRow,
    pasteWidth,
    selectedColumns,
    selection,

    // event registration
    setSelectionReadyHandler,
    setSelectionOverwriteHandler,
    setSelectionCopyHandler,
    setSelectionPasteHandler,

    // rest of selection parameters
    ...selectionProps

  } = useGridSelection(optColRef, numColumns, cellClass)

  // #########################################################################
  // ### local edit state ####################################################
  // #########################################################################

  // local edit state
  const [editedField, setEditedField] = useState<FieldType | -1>(-1)
  const [columnEdits, setColumnEdits] = useState<Array<string>>([])
  const [editedCells, setEditedCells] = useState<CellEdit[]>([])

  // #########################################################################
  // ### reset mechanism #####################################################
  // #########################################################################

  const resetColumnEdits = (columnSelection: typeof selection, activeField: FieldType) => {
    if(!inputsRef.current) throw 'Invalid call state, inputs ref is undefined'
    if(!selectedColumns.length) throw 'Invalid call state, no selected column'
    // compute appropriate set of column edits given new selection
    const updatedEdits = Array.from({ length: numColumns - 1 }, (_, field) => {
      if(!selectedColumns.includes(field + 2)) {
        return ''
      }
      // check if all values match
      const values = Array.from(columnSelection(field + 2), ({
        row: gridRow, column,
      }) => getOption(tnimage.odata, numRows - gridRow, FieldToOptionField[column - 2]))
      if(new Set(values).size !== 1) return '' // distinct values => no initial value
      return values[0].toString()
    })
    setColumnEdits(updatedEdits)

    // update value of active field input
    const input = inputsRef.current.children[activeField] as HTMLInputElement
    if(input.value !== updatedEdits[activeField]) {
      input.value = updatedEdits[activeField]
    }
    input.focus()
    input.select()
  }

  // #########################################################################
  // ### behavior when inserting input component #############################
  // #########################################################################

  // trigger with initial column edit upon new selection
  // = when the inputs reference become newly available because isSelectionReady changed
  useLayoutEffect(() => {
    if(!inputsRef.current) return;
    if(editedField === -1) {
      if(isSelectionReady && selectedColumns.length) {
        // start editing the first selected column
        const firstField = selectedColumns[0] - 2 as FieldType
        setEditedField(firstField)
        resetColumnEdits(selection, firstField)
      }
    } else if(!isSelectionReady || !selectedColumns.length) {
      // stop editing and deselect the current column
      setEditedCells([])
      setEditedField(-1)
      setColumnEdits([])
      // const input = inputsRef.current.children[editedField] as HTMLInputElement
      // input.blur()
    }
  }, [
    inputsRef, isSelectionReady, editedField,
    // setEditedField, setColumnEdits, // = stable in component life-cycle
  ])

  // #########################################################################
  // ### edit snapshot #######################################################
  // #########################################################################

  const applyCellEdits = (edits: CellEdit[]) => {
    // filter edits to those that change something
    edits = edits.filter(({ row, field, value }) => getOption(tnimage.odata, row, FieldToOptionField[field]) !== value)
    if(edits.length === 0) { return }
    // apply edits
    snapshot((tni) => {
      for(const { row, field, value } of edits) {
        const rfield = FieldToOptionField[field]
        setOption(tni.odata, row, rfield, value)
      }
    })
  }

  // #########################################################################
  // ### edit getter/setters #################################################
  // #########################################################################

  const setEditColumn = (e:FocusEvent) => {
    if(inputsRef.current && selectedColumns.length && editedField !== -1) {
      // get input
      const input = e.target as HTMLInputElement
      // update edited field
      const newField = Array.from(inputsRef.current.children).findIndex((input) => input === e.target)
      if(newField === -1) {
        throw 'Invalid new field'
      } else {
        setEditedField(newField)
        // update value if different from edit information
        if(input.value !== columnEdits[newField]) {
          input.value = columnEdits[newField]
        }
      }
      // select the full content
      input.select()
    }
  }
  const editColumn = (e:ChangeEvent<HTMLInputElement>) => {
    const field = parseInt(e.target.dataset.field, 10)
    const text = e.target.value
    const value = e.target.valueAsNumber
    const selectedColumn = field + 2

    // get list of cell edits
    const cells = !Number.isNaN(value) ? Array.from(selection(selectedColumn), ({ row: gridRow, column }) => ({
      row: numRows - gridRow,
      field: column - 2 as FieldType,
      value,
    })) : []
    setEditedCells(cells)

    // potentially update column edit information
    if(text !== columnEdits[field]) {
      const newEdits = columnEdits.slice()
      newEdits[field] = text
      setColumnEdits(newEdits)
    }
  }
  const applyColumnEdit = (field: FieldType) => {
    if(field === editedField) {
      applyCellEdits(editedCells)
    }
  }
  const getDragEdit = (idx: CellIndex): [number, boolean] => {
    if(!isDragOverwritten(idx)) return [-1, false]
    const { row: thisRow, column } = idx
    // get replicated value given start and end of data by repeating it with tiling (modulo operation on index)
    const dataDragRange = Math.abs(dragDataEndRow - dragDataStartRow) + 1 // /!\ beware of sign of delta
    const baseRow = dragDataStartRow + ((thisRow - dragDataStartRow) % dataDragRange) // /!\ because -i % N = -(i % N) in JS
    // /!\ from cell index to option index
    const fieldType = column - 2 as FieldType
    const optRow = numRows - baseRow
    return [
      getOption(tnimage.odata, optRow, FieldToOptionField[fieldType]),
      fieldType >= 0 && fieldType <= lastFieldType &&
        optRow >= 0 && optRow < numRows,
    ]
  }
  const getPasteEdit = (idx: CellIndex): [number, boolean] => {
    if(!isPasted(idx)) return [-1, false]
    const { row, column } = idx
    // note: indexing of paste data is bottom-top, then left-right => by row
    // /!\ pasteSourceRow is the top, not the bottom! because of Y inversion
    const dx = column - pasteColumnStart
    const srcDy = pasteSourceRow - row
    // at srcDy = 0 => dy = pasteHeight - 1
    // at srcDy = n => dy = pasteHeight - 1 - n
    const dy = pasteHeight - 1 - srcDy
    return pasteData(dy * pasteWidth + dx)
  }

  // #########################################################################
  // ### selection handlers ##################################################
  // #########################################################################

  // selection event handlers
  setSelectionReadyHandler((columnSelection: typeof selection) => {
    if(!inputsRef.current || !selectedColumns.length) return // skip first ready event (update happens in useLayoutEffect)
    resetColumnEdits(
      columnSelection,
      editedField !== -1 ? editedField : selectedColumns[0] - 2 as FieldType, // /!\ editedField can be -1 in edge cases
    )
  })
  setSelectionOverwriteHandler((dragEditSelection: typeof selection) => {
    // gather cell edits from drag-edit
    const edits = Array.from(dragEditSelection()).map((idx: CellIndex) => {
      const { row: gridRow, column } = idx
      const [editValue, validEdit] = getDragEdit(idx)
      console.assert(validEdit, 'Invalid drag edit value', gridRow, column, editValue)
      return {
        row: numRows - gridRow,
        field: column - 2 as FieldType,
        value: editValue,
      }
    }) as CellEdit[]
    // apply with snapshotting
    applyCellEdits(edits)
  })
  setSelectionCopyHandler((setCopyData) => {
    const data = Array.from(selection(), ({ row: gridRow, column }) => getOption(tnimage.odata, numRows - gridRow, FieldToOptionField[column - 2]))
    setCopyData(data)
  })
  setSelectionPasteHandler(() => {
    // gather cell edits from pasting
    const edits = Array.from(pasteSelection()).map((idx: CellIndex) => {
      const { row: gridRow, column } = idx
      const [pasteValue, validPaste] = getPasteEdit(idx)
      console.assert(validPaste, 'Invalid paste value', gridRow, column, pasteValue)
      return {
        row: numRows - gridRow,
        field: column - 2 as FieldType,
        value: pasteValue,
      }
    }) as CellEdit[]
    // apply with snapshotting
    applyCellEdits(edits)
  })

  // #########################################################################
  // ### JSX component(s) ####################################################
  // #########################################################################

  // /!\ wrap onDeactivate in a reference for FocusTrap
  // !!! so we can use it in focus trap with proper references
  // !!! otherwise all references are from the time of the
  // !!! instance creation (i.e., when the options are created)
  const onDeactivate = useRef<() => void>()
  onDeactivate.current = () => {
    if(editedField !== -1) applyColumnEdit(editedField)
    setEditedField(-1)
    setColumnEdits([])
    setEditedCells([])
  }

  const inputComponents = isSelectionReady ? (
    <FocusTrap focusTrapOptions={{
      allowOutsideClick: false,
      escapeDeactivates: false,
      onDeactivate: () => {
        onDeactivate.current?.()
      },
      setReturnFocus: () => optColRef.current || document.querySelector('.options-column') as HTMLElement,
    }}
    >
      <div
        className={classNames('selection-input', { ready: isSelectionReady })}
        ref={inputsRef}
      >
        <OptionsInput type={FieldType.Racking} filter={selectedColumns} onFocus={setEditColumn} onChange={editColumn} onCommit={applyColumnEdit} />
        <OptionsInput type={FieldType.Speed} filter={selectedColumns} onFocus={setEditColumn} onChange={editColumn} onCommit={applyColumnEdit} />
        <OptionsInput type={FieldType.Roll} filter={selectedColumns} onFocus={setEditColumn} onChange={editColumn} onCommit={applyColumnEdit} />
        <OptionsInput type={FieldType.FrontStitch} filter={selectedColumns} onFocus={setEditColumn} onChange={editColumn} onCommit={applyColumnEdit} />
        <OptionsInput type={FieldType.RearStitch} filter={selectedColumns} onFocus={setEditColumn} onChange={editColumn} onCommit={applyColumnEdit} />
        <OptionsInput type={FieldType.Direction} filter={selectedColumns} onFocus={setEditColumn} onChange={editColumn} onCommit={applyColumnEdit} />
        <OptionsInput type={FieldType.Carrier} filter={selectedColumns} onFocus={setEditColumn} onChange={editColumn} onCommit={applyColumnEdit} />
      </div>
    </FocusTrap>
  ) : null

  // #########################################################################
  // ### exposed data ########################################################
  // #########################################################################

  return {
    // per-cell state
    getCellState(idx: CellIndex): CellState {
      const type = idx.column - 2 as FieldType
      const selected = isSelected(idx)
      // data overwritten by column edit in selection

      const inputEdit = columnEdits[type]
      const hasInputEdit = selected && isSelectionReady && !isDragSelecting && editedField === type
      const hasEmptyEdit = hasInputEdit && !inputEdit.match(/[0-9\-+.]/) // XXX hasIncompleteEdit instead?
      // data overwritten by dragging mode (preview)
      const [dragEdit, hasDragEdit] = getDragEdit(idx)
      // data overwritten by pasting mode (preview)
      const [pasteEdit, hasPasteEdit] = getPasteEdit(idx)
      return {
        selected,
        inputEdit,
        hasInputEdit,
        hasEmptyEdit,
        dragEdit,
        hasDragEdit,
        pasteEdit,
        hasPasteEdit,
      }
    },

    // JSX component for input
    inputComponents,

    // remaining selection properties
    ...selectionProps,
  }
}
