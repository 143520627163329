import { DateTime } from 'luxon'
import { DesignLink } from 'src/api'

export enum QueryType {
  INCLUDE_STRING = 0,
  STARTS_WITH_STRING,
  INCLUDE_TOKENS,
}

export const QueryTypes = [
  ['Includes exactly', QueryType.INCLUDE_STRING],
  ['Starts with', QueryType.STARTS_WITH_STRING],
  ['Includes tokens', QueryType.INCLUDE_TOKENS],
] as const

export enum OrderType {
  BY_TIME = 0,
  BY_REV_TIME,
  BY_NAME,
  BY_REV_NAME,
}

export const OrderTypes = [
  ['From first to last', OrderType.BY_TIME],
  ['From last to first', OrderType.BY_REV_TIME],
  ['From A to Z', OrderType.BY_NAME],
  ['From Z to A', OrderType.BY_REV_NAME],
] as const

export interface FilterQuery {
  text?: string
  type?: QueryType
  order?: OrderType
}

function timestamp(d: DesignLink) {
  return DateTime.fromISO(d.lastModified).toMillis()
}

const DesignOrders = {
  [OrderType.BY_TIME]: (d1: DesignLink, d2: DesignLink) => timestamp(d1) - timestamp(d2),
  [OrderType.BY_REV_TIME]: (d1: DesignLink, d2: DesignLink) => timestamp(d2) - timestamp(d1),
  [OrderType.BY_NAME]: (d1: DesignLink, d2: DesignLink) => d1.name.localeCompare(d2.name),
  [OrderType.BY_REV_NAME]: (d1: DesignLink, d2: DesignLink) => d2.name.localeCompare(d1.name),
}

export function getDesignIndex(
  designs: DesignLink[],
  { order = OrderType.BY_TIME }: FilterQuery = {},
) {
  const sortFunc = DesignOrders[order]
  const index = designs.map((_, i) => i)
  return index.sort((i1, i2) => sortFunc(designs[i1], designs[i2]))
}

const DesignFilters = {
  [QueryType.INCLUDE_STRING]: (query: string) => (name: string) => name.includes(query),
  [QueryType.STARTS_WITH_STRING]: (query: string) => (name: string) => name.startsWith(query),
  [QueryType.INCLUDE_TOKENS]: (query: string) => {
    const tokens = query.split(' ')
    return (name: string) => tokens.every((token) => name.includes(token))
  },
}

/**
 * Get the design filter associated with a query
 *
 * @param query the user query parameters
 * @returns the design filter
 */
export function getDesignFilter({
  text = '',
  type = QueryType.INCLUDE_STRING,
}: FilterQuery = {}) {
  if(!text.length) {
    return () => true
  }
  return DesignFilters[type](text.toLowerCase())
}
