import {
  isKnownDirection,
  issue, Issue,
  KnitoutEntry,
  Needle,
  setProgramStage,
  ProgramStage,
  PanelLocation,
  isRackArguments,
  isKnitArguments,
  isDropArguments,
  isXferArguments,
  isSplitArguments,
  isCarriersArguments,
} from './common';
import { fromPanelToKnitout } from './panel-to-knitout';
import {
  indexedLoop, KnittingMachine, Loop, LoopAction, LoopGenerator,
} from './knitting-machine';
import { ReadonlyTimeNeedleImage } from '../time-needle/time-needle-image';

export interface SimulationBlock {
  commands: KnitoutEntry[]
  startState: KnittingMachine
  endState: KnittingMachine
}

export interface SimulationOutput {
  machine: KnittingMachine
  states: KnittingMachine[]
  issues: Issue[]
  blocks: SimulationBlock[]
}

export enum BlockLevel {
  ACTION = 0,
  ROW,
  PASS,
  PROGRAM,
}

export function simulate(
  input: ReadonlyTimeNeedleImage | KnitoutEntry[],
  loopGenerator: LoopGenerator = indexedLoop(0),
  blockLevel = BlockLevel.PASS,
): SimulationOutput {
  const issues = new Array<Issue>()

  // normalize input as a list of commands
  let commands: KnitoutEntry[]
  if(Array.isArray(input)) {
    commands = input
  } else {
    const {
      commands: cmds,
      issues: knitoutIssues,
      valid,
    } = fromPanelToKnitout(input, { storeOnly: true })
    commands = valid ? cmds : []
    issues.push(...knitoutIssues)
  }

  // simulate with machine
  setProgramStage(ProgramStage.SIMULATION)
  const states = new Array<KnittingMachine>()
  const machine = new KnittingMachine(true, loopGenerator)
  const blocks = new Array<SimulationBlock>()
  let lastSrc: PanelLocation
  const error = (msg: string) => {
    issues.push(
      issue('invalid-knitout').invalid(msg).at(lastSrc),
    )
    return {
      machine,
      states,
      issues,
      blocks,
    }
  }
  const commitBlock = (isLast = false) => {
    // transfer issues
    issues.push(...machine.popIssues())
    // create copy of state up to here
    const state = machine.copy()
    states.push(state)
    // clear machine loop information
    machine.clearLoopRange()
    // close current block
    blocks[blocks.length - 1].endState = state
    // create new block
    if(!isLast) {
      blocks.push({
        commands: [],
        startState: state,
        endState: machine,
      })
    }
  }
  blocks.push({
    commands: [],
    startState: machine.copy(),
    endState: machine,
  })
  let lastRow = 0
  for(const entry of commands) {
    const { cmd, args, src } = entry

    // row change
    const currRow = Array.isArray(src) ? src[0] : src
    if(currRow !== lastRow && blockLevel === BlockLevel.ROW) {
      commitBlock()
    }
    lastRow = currRow

    // store source information
    lastSrc = src
    machine.source = src

    // block information
    const lastBlock = blocks[blocks.length - 1]
    lastBlock.commands.push(entry)

    // process command
    if(cmd === 'x-end-pass' && blockLevel === BlockLevel.PASS) {
      // pass end
      const isLast = entry === commands[commands.length - 1]
      commitBlock(isLast)
      //
    } else {
      const failure = processCommand(machine, entry, error)
      if(failure) {
        return failure
      }
    } // endif x-end-pass else
  } // endfor [cmd, ...args]

  // post-state
  if(commands.length > 0 && commands[commands.length - 1].cmd !== 'x-end-pass') {
    commitBlock(true)
  }

  return {
    machine,
    states,
    issues,
    blocks,
  }
}

export interface ReplayCallbacks {
  commandCallback?: (state: KnittingMachine, cmd: KnitoutEntry) => void
  loopCallback?: (state: KnittingMachine, loop: Loop, cmd: KnitoutEntry) => void
}

export function replay(
  block: SimulationBlock,
  loopIndex: Loop[],
  issues: Issue[],
  {
    commandCallback,
    loopCallback,
  }: ReplayCallbacks,
) {
  const { startState, endState, commands } = block
  const { firstLoop, lastLoop } = endState
  if(!firstLoop || !lastLoop) {
    return // no action that is worth replaying
  }

  // callback state
  let machine: KnittingMachine
  let currEntry: KnitoutEntry

  // the replay generator reuses loop indices
  let loopIdx = firstLoop.index
  let replayIsValid = true
  const loopGen = (src: PanelLocation, act: LoopAction, n: Needle) => {
    const loop = loopIndex[loopIdx++] // /!\ post-increment as the first loop is firstLoop
    // prevent information to flow back into loop
    loop.lock()
    // if the replay makes sense, trigger the callback
    if(replayIsValid) {
      // check that the new loop information matches
      const loopMatches = loop.source === src && n && n.matches(loop.srcNeedle)
      if(loopMatches) {
        // trigger loop callback if any
        loopCallback && loopCallback(machine, loop, currEntry)
      } else {
        // replay does not seem to match information
        // /!\ only trigger issue once per replay
        replayIsValid = false
        issues.push(issue('simulation-replay').invalid(
          'Simulation replay is invalid',
        ).between(loop.source, src))
      }
    }
    return loop
  }
  machine = startState.relive(loopGen)
  machine.clearLoopRange()
  for(const entry of commands) {
    currEntry = entry // for the callback
    machine.source = entry.src // for the machine state information

    // trigger command callback if any
    commandCallback && commandCallback(machine, entry)

    // replay command
    const failure = processCommand(machine, entry, () => true)
    if(failure) {
      break
    }
  }
  if(endState.lastLoop.index !== loopIdx - 1) {
    issues.push(issue('simulation-replay').invalid(
      `Simulation replay ended with different loop index: expected ${endState.lastLoop.index}, got ${loopIdx - 1}`,
    ))
  }
}

export function replayLoops(
  block: SimulationBlock,
  loopIndex: Loop[],
  issues: Issue[],
  loopCallback: ReplayCallbacks['loopCallback'],
) {
  return replay(block, loopIndex, issues, { loopCallback })
}

export function replayCommands(
  block: SimulationBlock,
  loopIndex: Loop[],
  issues: Issue[],
  commandCallback: ReplayCallbacks['commandCallback'],
) {
  return replay(block, loopIndex, issues, { commandCallback })
}

function processCommand<T>(
  machine: KnittingMachine,
  { cmd, args }: KnitoutEntry,
  error: (msg: string) => T,
): T {
  switch(cmd) {
  // carrier management
  case 'in':
  case 'inhook':
  case 'releasehook':
  case 'out':
  case 'outhook':
    // $cmd ...cs
    if(isCarriersArguments(args)) {
      machine[cmd](...args)
    } else {
      return error(
        `Invalid carriers arguments: ${cmd} ${args.join(' ')}`,
      )
    }
    break

    // global state
  case 'rack':
    if(isRackArguments(args)) {
      const [r] = args
      machine.rack(r)
    } else {
      return error(
        `Invalid racking arguments: ${cmd} ${args.join(' ')}`,
      )
    }
    break

    // base actions
  case 'knit':
  case 'tuck':
  case 'miss':
    // $cmd dir n ...cs
    if(isKnitArguments(args)) {
      const [dir, n, ...cs] = args
      machine[cmd](dir, n, ...cs)
    } else {
      return error(
        `Invalid arguments: ${cmd} ${args.join(' ')}`,
      )
    }

    break

    // other main actions
  case 'drop':
    if(isDropArguments(args)) {
      const [n] = args
      machine.drop(n)
    } else {
      return error(`Invalid arguments: ${cmd} ${args.join(' ')}`)
    }
    break
  case 'xfer':
    if(isXferArguments(args)) {
      const [sn, tn] = args
      machine.xfer(sn, tn)
    } else {
      return error(`Invalid arguments: ${cmd} ${args.join(' ')}`)
    }
    break
  case 'split':
    if(isSplitArguments(args)) {
      const [dir, sn, tn, ...cs] = args
      machine.split(dir, sn, tn, ...cs)
    } else {
      return error(`Invalid arguments: ${cmd} ${args.join(' ')}`)
    }
    break

    // skip the rest
  }
}
