import { useEffect } from 'react';
import { useStore } from 'react-redux';
import { initLocalStorage } from 'src/common/local-copy';
import { getFastUnCoord, ProjectionMatrix, TransformData } from 'src/common/math';
import { useAppSelector } from 'src/hooks';
import { AppDispatch, RootState } from 'src/store';
import { clearDeferredActions, NumCellMargin, setAxisConfig } from './slice';
import { Source, createConfig, useCanvasState } from './state';
import TimeNeedleViewer from './viewer';
import { DOMHandlerProps } from './canvas';

// fully based on redux state
const editorConfig = createConfig({}, Source.REDUX)

export default function TimeNeedleEditor(hprops: DOMHandlerProps) {
  const store = useStore<RootState>()
  const [wprops, context, action] = useCanvasState(editorConfig, {}, updateAxes)

  // process deferred actions
  const deferredActions = useAppSelector((state) => state.canvas.deferredActions)
  useEffect(() => {
    if(deferredActions.length) {
      for(const action of deferredActions) {
        action(context)
      }
      context.dispatch(clearDeferredActions(deferredActions.length))
    }
  }, [deferredActions])

  // the local storage
  useEffect(() => {
    initLocalStorage()
  }, []) // only create once per canvas lifetime

  return (
    <TimeNeedleViewer
      id="CADToolCanvas"
      {...wprops}
      context={context}
      action={action}
      {...hprops}
    />
  )
}

/**
 * Updates the axis configs that are used by axis components
 * including the options column layout.
 *
 * The two transformation datas are used to validate that we
 * actually need an update to the axis configuration.
 * This is the case when the transform change, except
 * if the only change is relative to the translation.
 *
 * That is, we need a new axis configuration if either
 * - the zoom was updated
 * - the panel changed size
 * - the screen changed size
 * - the cell aspect ratio changed
 */
function updateAxes(
  lastTrans: TransformData,
  newTrans: TransformData,
  newM: ProjectionMatrix,
  dispatch: AppDispatch,
) {
  // need some page dimensions to configure axes
  const { pageDims } = newTrans
  if(!pageDims[0] || !pageDims[1]) {
    return // canot do anything yet
  }

  // check whether we need to update anything
  // <=> something changed beside the offset
  if(
    lastTrans.zoom === newTrans.zoom &&
    lastTrans.cellAR === newTrans.cellAR &&
    lastTrans.pageDims[0] === pageDims[0] &&
    lastTrans.pageDims[1] === pageDims[1] &&
    lastTrans.viewDims[0] === newTrans.viewDims[0] &&
    lastTrans.viewDims[1] === newTrans.viewDims[1]
  ) {
    return // no need to update the axis configs
  }
  // we must recompute the axis configurations
  const {
    offset,
    viewDims,
  } = newTrans

  // 1 = get cell size
  const fastUnCoord = getFastUnCoord(newM, pageDims)
  const cellBL = fastUnCoord([0, 0])
  const cellTR = fastUnCoord([1, 1])
  const cellSize = [
    cellTR[0] - cellBL[0],
    cellBL[1] - cellTR[1],
  ]

  // 2 = generate two axis configs
  const xConfig = {
    initOffset: offset[0],
    numCells: Math.ceil(viewDims[0] / cellSize[0]) + NumCellMargin * 2,
    cellSize: cellSize[0],
    cellPos: new Array<number>(pageDims[0]),
  }
  const yConfig = {
    initOffset: offset[1],
    numCells: Math.ceil(viewDims[1] / cellSize[1]) + NumCellMargin * 2,
    cellSize: cellSize[1],
    cellPos: new Array<number>(pageDims[1]),
  }
  // compute position of center of cells along both axes
  for(let i = 0, N = Math.max(...pageDims); i < N; ++i) {
    const [x, y] = fastUnCoord([
      i + 0.5,
      i + 0.5,
    ])
    if(i < pageDims[0]) xConfig.cellPos[i] = x
    if(i < pageDims[1]) yConfig.cellPos[i] = y
  }

  // update axis configurations
  dispatch(setAxisConfig([xConfig, yConfig]))
}
