import {
  iterativelySwapBoundaryRows, moveEmptyRowsToTop, swapBoundaryRows, swapMoveDown, swapMoveUp,
} from 'src/macros'
import { useApplyMacro, useAppSelector } from 'src/hooks'
import ContextMenuItem, { Separator } from './menu-item'

export default function SwapMenu() {
  const sel = useAppSelector((state) => state.canvas.selection)
  const selHeight = sel ? sel[3] - sel[1] : 0
  const applyMacro = useApplyMacro()
  return (
    <ContextMenuItem
      name="Swap ..."
      menu
      disabled={selHeight < 2}
    >
      <ContextMenuItem
        name="Swap end rows iteratively"
        onClick={() => {
          applyMacro((s) => iterativelySwapBoundaryRows(s))
        }}
      />
      <ContextMenuItem
        name="Swap end rows directly"
        onClick={() => {
          applyMacro((s) => swapBoundaryRows(s))
        }}
      />
      <ContextMenuItem
        name="Move top row to bottom"
        onClick={() => {
          applyMacro((s) => swapMoveDown(s))
        }}
      />
      <ContextMenuItem
        name="Move bottom row to top"
        onClick={() => {
          applyMacro((s) => swapMoveUp(s))
        }}
      />
      <Separator />
      <ContextMenuItem
        name="Move empty rows to top"
        onClick={() => {
          applyMacro((s) => moveEmptyRowsToTop(s))
        }}
      />
    </ContextMenuItem>
  )
}
