import { ChangeEvent, useRef } from 'react'
import { Serialize } from 'src/data/serialization'
import { exportImage } from 'src/data/time-needle/export-image'
import { ViewMode } from 'src/data/time-needle/view-mode'
import SubmitBug from 'src/editor/submit-bug'
import { exportFile } from 'src/common/files'
import { loadTimeNeedleImageData } from 'src/editor/time-needle/slice'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { useKnitoutExport, useKCodeExport } from './knitout'

import 'src/styles/ribbon-actions.scss'

const readFile = (file: Blob) => {
  const reader = new FileReader()
  const promise = new Promise<Uint8Array>((resolve) => {
    reader.onload = () => {
      const buckData = new Uint8Array(reader.result as ArrayBuffer)
      resolve(buckData)
    }
    reader.readAsArrayBuffer(file)
  })
  return promise
}

const UploadBuck = () => {
  const dispatch = useAppDispatch()
  const fileRef = useRef<HTMLInputElement>(null)

  const loadTNI = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files[0]
    console.log(`Loading buck file ${file.name}`)
    const buckData = await readFile(file)
    console.log(`Loaded ${file.name}: ${buckData.byteLength} bytes`)
    dispatch(loadTimeNeedleImageData(buckData))
  }

  return (
    <>
      <input
        type="file"
        accept=".buck"
        ref={fileRef}
        // style={{ display: 'none'}}
        onChange={loadTNI}
        hidden
      />
      <button onClick={() => fileRef.current.click()}>Load buck</button>
    </>
  )
}

function Actions({ title = '' }) {
  const tnimage = useAppSelector((state) => state.canvas.tnimage)
  const name = useAppSelector((state) => state.design.title)
  const exportKnitout = useKnitoutExport()
  const exportKCode = useKCodeExport()
  return (
    <ul className="action-list">
      <li className="action-item">
        <button onClick={() => exportFile(`${name}.buck`, Serialize(tnimage))}>Download buck</button>
      </li>
      <li className="action-item">
        <UploadBuck />
      </li>
      <li className="action-item">
        <button onClick={() => exportImage(tnimage, ViewMode.PATTERN, `${name}.png`)}>Download image</button>
      </li>
      <li className="action-item">
        <button onClick={() => exportKnitout(tnimage, `${name}.k`)}>Export Knitout</button>
      </li>
      <li className="action-item">
        <button onClick={() => exportKCode(tnimage, `${name}.kc`)}>Export KCode</button>
      </li>
      <li className="action-item">
        <SubmitBug />
      </li>
    </ul>
  )
}

export default Actions
