import {
  isSplitArguments,
  Issue,
  issue,
  isXferArguments,
  Needle,
  SimulationBlock,
} from 'src/data/compiler';

export function transfersShouldNotBeEmpty(
  blocks: SimulationBlock[],
  issues: Issue[],
) {
  for(const { commands, startState } of blocks) {
    for(const { cmd, args, src } of commands) {
      // only consider transfers (and splits)
      let sn: Needle
      if(cmd === 'xfer') {
        if(isXferArguments(args)) {
          [sn] = args
        } else {
          issues.push(issue('invalid-knitout').invalid(
            `Invalid xfer arguments: ${args.join(' ')}`,
          ).at(src))
        }
      } else if(cmd === 'split') {
        if(isSplitArguments(args)) {
          [, sn] = args
        } else {
          issues.push(issue('invalid-knitout').invalid(
            `Invalid split arguments: ${args.join(' ')}`,
          ).at(src))
        }
      } else {
        continue
      }

      // check that source needle has a loop
      if(startState.isEmpty(sn)) {
        issues.push(issue('empty-xfer').warn(
          'Transfer from an empty needle',
        ).at(src))
      }
    }
  }
}
