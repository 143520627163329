import { useEffect, useRef } from 'react'
import { useWindowResize } from 'src/hooks'
import { useThrottledCallback } from 'use-debounce'
import {
  DOMHandlerProps,
  LayerArgs, ShaderArgs, TimeNeedleCanvasProps,
} from './props'
import WebGLState, {
  clear, init, render, resize,
} from './webgl'

// expose properties
export {
  CursorMode,
  type DOMHandlerProps,
  type RenderCallback,
  type TimeNeedleCanvasProps,
  type WebGLDataProps,
  type WebGLHandlers,
} from './props'

// actual react component!
export default function TimeNeedleCanvas(
  {
    id,
    onInit,
    onResize,
    onRender,
    ...props
  }: TimeNeedleCanvasProps,
) {
  // the canvas element
  const canvasRef = useRef<HTMLCanvasElement>()

  // the allocated webgl data for this canvas element
  const stateRef = useRef<WebGLState>()

  // initialization of the state, only once per DOM element
  useEffect(() => {
    const state = init(canvasRef.current, onRender)
    stateRef.current = state
    if(state) {
      state.frameId = window.requestAnimationFrame(() => render(state))
      // update canvas size and viewport
      const { width, height } = resize(canvasRef.current, state)
      if(onInit) {
        onInit(width, height)
      }
    }
    return () => clear(state)
  }, [canvasRef])

  // update of the state from the properties
  // = should happen whenever a property changes
  useEffect(() => {
    const state = stateRef.current
    if(!state) { return }

    // update render callback
    state.renderCallback = onRender

    // update the state
    const { rendCtx } = state
    for(const argName of ShaderArgs) {
      if(rendCtx.hasArgument(argName)) {
        const propVal = props[argName]
        if(Array.isArray(propVal)) {
          // get old value or create if first time
          const data = rendCtx.getArgumentValue(argName) as Float32Array ?? new Float32Array(propVal.length)
          // update value from props
          data.set(propVal)
          // update argument
          rendCtx.setArgument(argName, data)
        } else {
          rendCtx.setArgument(argName, propVal)
        }
      }
    }

    // update the layers
    for(const layerName of LayerArgs) {
      state[layerName] = props[layerName]
    }

    // update the preview data
    state.previewData = props.previewData

    // ready when the page dimensions are non-trivial
    state.ready = (props.pageDims[0] * props.pageDims[1]) > 1
  }, [onRender, ...Object.values(props)])

  useWindowResize(useThrottledCallback(() => {
    if(canvasRef.current && stateRef.current) {
      const { width, height, rect } = resize(
        canvasRef.current,
        stateRef.current,
      )
      if(onResize) {
        onResize(width, height, rect)
      }
    }
  }, 1000 / 60), [canvasRef, onResize], !!canvasRef.current)

  const handlerProps = Object.entries(props).reduce((map, [name, f]) => {
    if(name.startsWith('on')) {
      return Object.assign(map, { [name]: f })
    }
    return map
  }, {}) as DOMHandlerProps

  return (
    <canvas
      // base properties
      id={id}
      ref={canvasRef}
      tabIndex={1}
      // delegated handlers
      {...handlerProps}
    />
  )
}
