import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type MousePos = { x: number, y: number}

const initialState = {
  mouse: { x: 0, y: 0 },
  mouseOnCanvas: false,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    mouseMove(state, { payload: { x, y }}: PayloadAction<MousePos>) {
      return {
        ...state,
        mouse: { x, y },
        mouseOnCanvas: true,
      }
    },
    mouseLeave(state) {
      return {
        ...state,
        mouseOnCanvas: false,
      }
    },
  },
})

export const {
  mouseMove,
  mouseLeave,
} = uiSlice.actions

export type UIState = typeof initialState

export default uiSlice.reducer
