import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  title: '',
  id: '',
}

const designSlice = createSlice({
  name: 'design',
  initialState,
  reducers: {
    setTitle(state, { payload }: PayloadAction<string>) {
      document.title = !payload ? 'Topologic' : `Topologic - ${payload}`
      return {
        ...state,
        title: payload,
      }
    },
    setDesignID(state, { payload }: PayloadAction<string>) {
      return {
        ...state,
        id: payload,
      }
    },
  },
})

export const {
  setTitle,
  setDesignID,
} = designSlice.actions

export type DesignState = typeof initialState

export default designSlice.reducer
