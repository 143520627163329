import {
  knitoutToPasses, passesToKCodePasses, kcodePassesToKCode, usingKnitoutConsole,
  CARRIAGE_MOVE_COMMENT,
  DECAYED_SOFT_MISS_COMMENT,
} from 'knitout-backend-kniterate'
import {
  Direction,
  issue,
  Issue, ProgramStage, setProgramStage,
} from './common'

export enum KCodePassType {
  KNIT_KNIT = 'Kn-Kn',
  KNIT_TUCK = 'Kn-Tu',
  TUCK_KNIT = 'Tu-Kn',
  TUCK_TUCK = 'Tu-Tu',
  XFER_RCVL = 'Tr-Rl',
  XFER_RCVR = 'Tr-Rr',
  RCVL_XFER = 'Rl-Tr',
  RCVR_XFER = 'Rr-Tr',
}

const KCodeXferPassTypes = [
  KCodePassType.XFER_RCVL,
  KCodePassType.XFER_RCVR,
  KCodePassType.RCVL_XFER,
  KCodePassType.RCVR_XFER,
]

export interface KCodePass {
  // bed information
  FRNT: string
  STIF: string
  REAR: string
  STIR: string
  RACK: number
  // carriage information
  direction: Direction
  carriageLeft: number
  carriageRight: number
  type: KCodePassType
  speed: number
  roller: number
  // carrier information
  carrier?: string
  carrierLeft?: number
  carrierRight?: number
  // additional information
  comment?: string
  // inclusive range of related knitout line indices
  source: [number, number]
}

export type KCodeMetaMapping = (pass: KCodePass) => string

export function isSoftMissPass(pass: KCodePass) {
  return pass.type === KCodePassType.TUCK_TUCK && pass.comment && pass.comment.includes(DECAYED_SOFT_MISS_COMMENT)
}

export function isXferPass(pass: KCodePass) {
  return KCodeXferPassTypes.includes(pass.type)
}

export function isCarrierPass(pass: KCodePass) {
  return 'carrier' in pass
}

export function isCarriageMove(pass: KCodePass) {
  return !isCarrierPass(pass) && pass.comment && pass.comment.includes(CARRIAGE_MOVE_COMMENT)
}

export function fromKnitoutToKCodePasses(knitout: string): KCodePass[] {
  const {headers, passes} = knitoutToPasses(knitout, 'knitout')
  return passesToKCodePasses(headers, passes)
}

export function ktok(knitout: string, fname: string, metaFunc?: KCodeMetaMapping): string {
  const kcodePasses = fromKnitoutToKCodePasses(knitout)
  return kcodePassesToKCode(kcodePasses, fname, metaFunc);
}

export interface KCodeOptions {
  fname?: string
  outputPasses?: boolean
  metaFunc?: KCodeMetaMapping
}

export interface CompilerOutput {
  output: string
  valid: boolean
  issues: Issue[]
  passes?: KCodePass[]
}

export function fromKnitoutToKCode(
  knitout: string,
  {
    fname = 'command.kc',
    outputPasses = false,
    metaFunc,
  }: KCodeOptions = {},
): CompilerOutput {
  // capture issues during Knitout-to-KCode compilation
  const issues = new Array<Issue>()

  // compilation to k-code
  setProgramStage(ProgramStage.KNITOUT_TO_KCODE)

  let output = ''
  let passes: KCodePass[]
  let valid = false
  usingKnitoutConsole({
    log(...args: any[]) {
      console.log(...args)
    },
    warn(...args: any[]) {
      issues.push(issue('compiler').warn(args.join(' ')))
      console.warn(...args)
    },
    assert(test: boolean, ...args: any[]) {
      if(!test) {
        issues.push(issue('compiler').invalid(args.join(' ')))
      }
      console.assert(test, ...args)
    },
  }, () => {
    try {
      if(outputPasses) {
        passes = fromKnitoutToKCodePasses(knitout)
        output = kcodePassesToKCode(passes, fname, metaFunc)
      } else {
        output = ktok(knitout, fname, metaFunc)
      }
      valid = true
    } catch(err) {
      issues.push(issue('compiler').invalid(err.toString()))
    }
  })
  return {
    output,
    valid,
    issues,
    passes,
  }
}
