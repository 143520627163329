import { addRows } from 'src/data/time-needle/topology'
import {
  getKnittingSides,
  KnittingSide,
  parseUserOptions,
  SelectorLike,
  ShapingSide,
  TimeNeedleTransformer,
} from './common'

export enum RackingType {
  ANY = 0,
  LEFT_ONLY,
  RIGHT_ONLY,
}

export interface DecreaseTransferOptions {
  margins: [number[], number[]]
  sides: ShapingSide
  layered: boolean
  rackingType: RackingType
}

export function parseDecreaseTransferOptions(tilingStr: string): DecreaseTransferOptions | string {
  // (SIDE) at (M1[, M2 ...]) [and N1[, N2 ...]] [layered] [left-racking] [right-racking]
  const opts: DecreaseTransferOptions = {
    margins: [[], []],
    sides: undefined,
    layered: false,
    rackingType: RackingType.ANY,
  }
  let mSide = 0
  const setSide = (side: ShapingSide) => {
    if(opts.sides === undefined) {
      opts.sides = side
    } else if(
      (opts.sides === ShapingSide.LEFT && side === ShapingSide.RIGHT) ||
      (opts.sides === ShapingSide.RIGHT && side === ShapingSide.LEFT)
    ) {
      opts.sides = ShapingSide.BOTH // merge into both sides
      if(opts.margins[0].length > 0) {
        if(side === ShapingSide.LEFT) {
          opts.margins.reverse() // 0 becomes 1
          mSide = 0
        } else {
          mSide = 1
        }
      }
    } else {
      return 'Invalid second side token'
    }
  }
  let multiply = false
  const addMargin = (m: number) => {
    if(opts.sides !== undefined) {
      const margins = opts.margins[mSide]
      if(multiply) {
        for(let i = 0; i < m - 1; ++i) {
          margins.push(margins[margins.length - 1])
        }
        multiply = false
      } else {
        margins.push(m)
      }
    } else {
      return 'Invalid margin token before side token'
    }
  }
  const error = parseUserOptions(tilingStr, {
    left: () => { setSide(ShapingSide.LEFT) },
    right: () => { setSide(ShapingSide.RIGHT) },
    both: () => { setSide(ShapingSide.BOTH) },
    for: () => { multiply = true },
    layered: () => { opts.layered = true },
    'left-racking': () => { opts.rackingType = RackingType.LEFT_ONLY },
    'right-racking': () => { opts.rackingType = RackingType.RIGHT_ONLY },
  }, {
    at: (token: string) => {
      const m = parseInt(token, 10)
      if(isNaN(m) || m < 0) {
        return `Invalid margin token ${token}`
      }
      return addMargin(m)
    },
  })
  if(typeof error === 'string') {
    return error
  }

  // validating the result
  if(opts.sides === undefined) {
    return 'Missing at least one side: left, right or both'
  } if(opts.margins[0].length === 0 && opts.margins[1].length === 0) {
    return 'No margin specified'
  } if(opts.margins[0].length === 0) {
    opts.margins[0] = opts.margins[1].slice()
  } else if(opts.margins[1].length === 0) {
    opts.margins[1] = opts.margins[0].slice()
  }
  return opts
}

function rowsMatch(src: SelectorLike, trg: SelectorLike): boolean {
  if(src.length !== trg.length) { return false }
  // get range
  let minIdx = src.length - 1
  let maxIdx = 0
  for(let i = 0; i < src.length; ++i) {
    const sc = src.getCell(i)
    const tc = trg.getCell(i)
    if(!sc.isMiss() || !tc.isMiss()) {
      minIdx = Math.min(minIdx, i)
      maxIdx = Math.max(maxIdx, i)
    }
  }
  // check matching of src and trg
  // /!\ except potentially for the leftmost and rightmost stitches
  // since those can be edge misses
  for(let i = minIdx; i <= maxIdx; ++i) {
    const sc = src.getCell(i)
    const scc = sc.getCode()
    const tc = trg.getCell(i)
    const tcc = tc.getCode()
    if(scc !== tcc) {
      if([minIdx, maxIdx].includes(i) && (sc.isMiss() || tc.isMiss() || sc.isExplicitMiss() || tc.isExplicitMiss())) {
        continue // valid edge case without matching
      }
      return false // don't match inside the range or invalid edge case without miss
    }
  }
  return true
}

function rackingDir(rtype: RackingType): -1 | 0 | 1 {
  if(rtype === RackingType.ANY) {
    return 0
  } if(rtype === RackingType.LEFT_ONLY) {
    return -1
  }
  return +1
}

export type IndexRange = [number, number]

export interface DecreaseContext {
  rowPairs: [SelectorLike, SelectorLike][]
  rowSides: [KnittingSide[], KnittingSide[]][]
  knitRngs: [IndexRange, IndexRange][]
  shapings: [number, number][]
}

export function getDecreaseContext(
  s: SelectorLike,
  shapingSide: ShapingSide,
): DecreaseContext {
  const rows = s.splitByRow()
  if(rows.length < 2) { return }
  const rowPairs = new Array<[SelectorLike, SelectorLike]>(rows.length - 1)
  const rowSides = new Array<[KnittingSide[], KnittingSide[]]>(rows.length - 1)
  const knitRngs = new Array<[IndexRange, IndexRange]>(rows.length - 1)
  const shapings = new Array<[number, number]>(rows.length - 1)
  const leftShaping = shapingSide === ShapingSide.LEFT_TO_RIGHT || shapingSide === ShapingSide.BOTH_SIDES
  const rightShaping = shapingSide === ShapingSide.RIGHT_TO_LEFT || shapingSide === ShapingSide.BOTH_SIDES
  for(let i = 0; i < rows.length - 1; ++i) {
    rowPairs[i] = [rows[i], rows[i + 1]]
    rowSides[i] = [
      getKnittingSides(rowPairs[i][0], 'below'),
      getKnittingSides(rowPairs[i][1], 'above'),
    ]
    const [srcSides, trgSides] = rowSides[i]
    let [srcMinIdx, srcMaxIdx] = srcSides.reduce(([lm, rm], ks, i) => (ks !== KnittingSide.NONE ? [Math.min(lm, i), Math.max(rm, i)] : [lm, rm]), [srcSides.length - 1, 0])
    let [trgMinIdx, trgMaxIdx] = trgSides.reduce(([lm, rm], ks, i) => (ks !== KnittingSide.NONE ? [Math.min(lm, i), Math.max(rm, i)] : [lm, rm]), [srcSides.length - 1, 0])
    if(rowsMatch(rows[i], rows[i + 1])) {
      const rng = [
        Math.min(srcMinIdx, trgMinIdx),
        Math.max(srcMaxIdx, trgMaxIdx),
      ] as IndexRange
      knitRngs[i] = [rng, rng] // same range
      shapings[i] = [0, 0] // no shaping
      //
    } else if(i === 0) {
      knitRngs[i] = [
        [srcMinIdx, srcMaxIdx],
        [trgMinIdx, trgMaxIdx],
      ]
      shapings[i] = [
        leftShaping ? trgMinIdx - srcMinIdx : 0,
        rightShaping ? srcMaxIdx - trgMaxIdx : 0,
      ]
      //
    } else {
      const [[prevSrcMinIdx, prevSrcMaxIdx]] = knitRngs[i - 1]
      const [prevLeftShift, prevRightShift] = shapings[i - 1]
      // account for previous shaping
      if(leftShaping) srcMinIdx = Math.max(srcMinIdx, prevSrcMinIdx + prevLeftShift)
      if(rightShaping) srcMaxIdx = Math.min(srcMaxIdx, prevSrcMaxIdx - prevRightShift)
      knitRngs[i] = [
        [srcMinIdx, srcMaxIdx],
        [trgMinIdx, trgMaxIdx],
      ]
      shapings[i] = [
        leftShaping ? trgMinIdx - srcMinIdx : 0,
        rightShaping ? srcMaxIdx - trgMaxIdx : 0,
      ]
    }
  }
  return {
    rowPairs,
    rowSides,
    knitRngs,
    shapings,
  }
}

export function generateDecreaseTransfers(
  s: SelectorLike,
  {
    sides: shapingSide,
    margins: [leftMargins, rightMargins],
    layered = false,
    rackingType = RackingType.ANY,
  }: DecreaseTransferOptions,
) {
  const racking = rackingDir(rackingType)
  const {
    rowPairs,
    rowSides,
    knitRngs,
    shapings,
  } = getDecreaseContext(s, shapingSide)

  // error information at end
  let valid = true

  // apply shaping transformation from top to bottom (since we may add rows in between)
  let baseSel: SelectorLike
  let numNewRows = 0
  for(let r = rowPairs.length - 1; r >= 0 && valid; --r) {
    const [srcRow] = rowPairs[r]
    const [srcSides, trgSides] = rowSides[r]
    console.assert(
      srcSides.length === trgSides.length,
      `Row pair sides have different cardinalities: from ${srcSides.length} to ${trgSides.length}`,
    )

    // measure knitting ranges
    const [[srcMinIdx, srcMaxIdx], [trgMinIdx, trgMaxIdx]] = knitRngs[r]
    if(srcMaxIdx - srcMinIdx < 0 || trgMaxIdx - trgMinIdx < 0) {
      continue // no knitting on one side, so no shaping to do
    }

    // shaping steps on each side
    if(!baseSel) {
      baseSel = srcRow // use first row as base row
    } else {
      baseSel = baseSel.withSelection(srcRow.selection)
    }
    const [leftDecr, rightDecr] = shapings[r]
    for(const side of [ShapingSide.LEFT_TO_RIGHT, ShapingSide.RIGHT_TO_LEFT]) {
      if(side !== shapingSide && shapingSide !== ShapingSide.BOTH_SIDES) {
        continue
      }
      const decr = side === ShapingSide.LEFT_TO_RIGHT ? leftDecr : rightDecr
      const margins = side === ShapingSide.LEFT_TO_RIGHT ? leftMargins : rightMargins
      if(decr < 0) {
        console.warn(`Invalid decrease, should be increase: decr(side=${ShapingSide[side]})=${decr}`)
        continue
      } else if(decr === 0) {
        continue // no need for shaping on that side for this pair
      } else if(decr > 1) {
        alert(`We only support shaping decrease by 1, got a decrease by ${decr}`)
        continue
      }
      const margin = margins[r % margins.length]
      // measure requires transfers assuming rear-to-front normalization
      const [x0, dx] = [
        [srcMinIdx, 1],
        [srcMaxIdx, -1],
      ][side]
      const postMargin = x0 + dx * margin

      // cases
      if(racking * dx <= 0) {
        // -------------------------------------------------------------------
        // default case
        // -------------------------------------------------------------------
        // => use direct diagonal transfer
        let preXfer = 0 // from source rear to normalized front
        for(let i = 0, x = x0; i < margin && preXfer < 2; ++i, x += dx) {
          if(preXfer === 0 && srcSides[x] === KnittingSide.REAR) {
            ++preXfer
          } else if(preXfer === 1 && srcSides[x] === KnittingSide.REAR && srcSides[x - dx] === KnittingSide.REAR) {
            ++preXfer
          }
        }
        let frontStack = 0 // from source front to rear before normalization
        if(layered && srcSides[postMargin] === KnittingSide.FRONT && trgSides[postMargin] === KnittingSide.FRONT) {
          frontStack = 1
        }
        let rearPreStack = 0 // from source front to rear before normalization
        let rearPostStack = 0 // from source front to rear after normalization
        if(srcSides[postMargin] === KnittingSide.FRONT && trgSides[postMargin] === KnittingSide.REAR) {
          if(layered) {
            ++rearPreStack
          } else {
            ++rearPostStack
          }
        }
        console.assert(
          frontStack + rearPreStack + rearPostStack <= 1,
          'Multiple stacking transfer rows',
        )
        let postXfer = 0 // from normalized rear to target front
        for(let i = 0, x = x0 + dx; i < margin && postXfer < 2; ++i, x += dx) {
          if(postXfer === 0 && trgSides[x] === KnittingSide.FRONT) {
            ++postXfer
          } else if(postXfer === 1 && trgSides[x] === KnittingSide.FRONT && trgSides[x - dx] === KnittingSide.FRONT) {
            ++postXfer
          }
        }
        const allocRows = (
          preXfer + frontStack + rearPreStack + 1 + rearPostStack + postXfer
        )
        const [baseRow, newBlk] = addRows(baseSel, 'above', allocRows)
        numNewRows += allocRows
        // set options of new rows
        {
          let ri = 0
          const newRows = newBlk.splitByRow()
          console.assert(newRows.length === allocRows, 'Invalid number of new rows')
          const xferOptions = { stitchSize: 5, speed: 60 }
          if(preXfer >= 1) newRows[ri++].options({ ...xferOptions, roller: 0 })
          if(preXfer >= 2) newRows[ri++].options({ ...xferOptions, roller: 0 })
          if(frontStack > 0 || rearPreStack) newRows[ri++].options({ ...xferOptions, roller: 0 })
          newRows[ri++].options({ ...xferOptions, roller: 30 })
          if(rearPostStack > 0) newRows[ri++].options({ ...xferOptions, roller: 0 })
          if(postXfer >= 1) newRows[ri++].options({ ...xferOptions, roller: 50 })
          if(postXfer >= 2) newRows[ri++].options({ ...xferOptions, roller: 50 })
        }

        // step 1 = transfers from rear sources to normalized front
        let dy = 1
        for(let i = 0, pass = 0, x = x0; i < margin; ++i, x += dx) {
          if(srcSides[x] === KnittingSide.REAR) {
            const c = baseRow.getCell(x).neighbor(0, dy + pass)
            c.transfer(1, 0) // rear to front without racking
            pass = 1 - pass
          } else {
            pass = 0
          }
        }
        dy += preXfer

        // step 2 = transfer from front to rear (frontStack or rearPreStack)
        if(frontStack > 0 || rearPreStack > 0) {
          const c = baseRow.getCell(postMargin).neighbor(0, dy)
          c.transfer(0, 0)
        }
        dy += frontStack + rearPreStack

        // step 3 = transfers with racking
        for(let i = 0, x = x0; i < margin; ++i, x += dx) {
          const c = baseRow.getCell(x).neighbor(0, dy)
          c.transfer(0, decr * dx) // front to rear with decr racking
        }
        dy += 1

        // step 4 = transfer from front to rear (rearPostStack)
        if(rearPostStack > 0) {
          // edge case front to rear
          const c = baseRow.getCell(postMargin).neighbor(0, dy)
          c.transfer(0, 0)
        }
        dy += rearPostStack

        // step 4 = transfers from normalized rear to front targets
        // note: we must check the targets, not the sources!
        // => goes one further in the decrease direction
        for(let i = 0, pass = 0, x = x0 + decr * dx; i < margin; ++i, x += dx) {
          if(trgSides[x] === KnittingSide.FRONT) {
            const c = baseRow.getCell(x).neighbor(0, dy + pass)
            c.transfer(1, 0) // rear to front without racking
            pass = 1 - pass
          } else {
            pass = 0
          }
        }
        dy += postXfer

        // switch base row to last row
        console.assert(dy === allocRows + 1, 'Invalid number of processed transfer rows')
        baseSel = baseRow.neighbor(0, allocRows)
      } else {
        // -------------------------------------------------------------------
        // jersey mode in two or four passes
        // -------------------------------------------------------------------

        // validate side
        const srcSide = srcSides[x0]
        for(let i = 1, x = x0 + dx; i < margin && valid; ++i, x += dx) {
          if(srcSides[x - dx] !== srcSides[x] && srcSides[x] !== trgSides[x]) {
            valid = false
          }
        }
        if(!valid) {
          break
        }

        // number of passes (2x1=2 or 2x2=4)
        const stepPasses = Math.min(2, margin)
        const allocRows = stepPasses * 2
        const [baseRow, newBlk] = addRows(baseSel, 'above', allocRows)
        numNewRows += allocRows
        // set options of new rows
        {
          let ri = 0
          const newRows = newBlk.splitByRow()
          console.assert(newRows.length === allocRows, 'Invalid number of new rows')
          const xferOptions = { stitchSize: 5, speed: 60 }
          newRows[ri++].options({ ...xferOptions, roller: 0 })
          if(stepPasses >= 2) newRows[ri++].options({ ...xferOptions, roller: 0 })
          newRows[ri++].options({ ...xferOptions, roller: 30, racking: dx })
          if(stepPasses >= 2) newRows[ri++].options({ ...xferOptions, roller: 30, racking: dx })
        }

        // step 1 = transfers from source to other side
        let dy = 1
        for(let i = 0, pass = 0, x = x0; i < margin; ++i, x += dx) {
          // source side
          const s = srcSide === KnittingSide.FRONT ? 0 : 1
          const c = baseRow.getCell(x).neighbor(0, dy + pass)
          // source to intermediate without racking
          c.transfer(s, 0)
          pass = 1 - pass
        }
        dy += stepPasses

        // step 2 = transfers with racking back to source side
        for(let i = 0, pass = 0, x = x0; i < margin; ++i, x += dx) {
          // intermediate side
          const s = srcSide === KnittingSide.FRONT ? 1 : 0
          // /!\ dx depends on side
          const c = baseRow.getCell(x).neighbor(s === 1 ? 0 : dx, dy + pass)
          // intermediate to source
          c.transfer(s, 0)
          pass = 1 - pass
        }
        dy += stepPasses

        // switch base row to last row
        console.assert(dy === allocRows + 1, 'Invalid number of processed transfer rows')
        baseSel = baseRow.neighbor(0, allocRows)
      }
    } // endfor side of [LTR, RTL]
  } // endfor 0 <= i < #rowPairs, downward

  if(!valid) {
    alert('Invalid left-racking or right-racking input: not jersey')
  }
  return baseSel?.withSelection(s.selection)?.extendUp(numNewRows)
}
