import { ReadonlyTimeNeedleImage } from '../time-needle/time-needle-image';
import { Issue } from './common';
import { fromKnitoutToKCode, KCodePass } from './compiler';
import { fromPanelToKnitout, KnitoutOptions, KNITOUT_PREAMBLE_NUM_LINES } from './panel-to-knitout';

export interface KCodeOutput {
  output: string
  issues: Issue[]
  knitoutIsValid: boolean
  kcodeIsValid: boolean
}

export function fromPanelToKCode(
  tni: ReadonlyTimeNeedleImage,
  opts: KnitoutOptions & { fname?: string } = {},
): KCodeOutput {
  const {
    knitout,
    commands,
    valid: knitoutIsValid,
    issues,
  } = fromPanelToKnitout(tni, {...opts, minimal: true, store: true})

  // shortcut if invalid knitout
  if(!knitoutIsValid) {
    return {
      knitoutIsValid: false,
      kcodeIsValid: false,
      output: '',
      issues,
    }
  }

  const {
    output,
    valid: kcodeIsValid,
    issues: compilerIssues,
  } = fromKnitoutToKCode(knitout, {
    fname: opts.fname ?? 'command.kc',
    metaFunc: (pass: KCodePass) => {
      const [firstRow, lastRow] = pass.source.map((kline) => {
        const { src } = commands[kline - KNITOUT_PREAMBLE_NUM_LINES]
        return Array.isArray(src) ? src[0] : src
      })
      return firstRow === lastRow ? `// row: ${firstRow}` : `// rows: ${firstRow}-${lastRow}`
    },
  })

  // append compiler issues
  if(compilerIssues.length) {
    issues.push(...compilerIssues)
  }

  return {
    knitoutIsValid,
    kcodeIsValid,
    output,
    issues,
  }
}
