import {
  CanvasImage, getRowIndex, ReadonlyCanvasImage, setPixelRow,
} from 'src/data/image';
import type MachineType from 'src/data/time-needle/machine-type';
import { getOptionsList, getOptionsBytes } from './map';
import OptionField, { asFieldBytes, fromFieldBytes } from './option-field';

/**
 * We pack options in a RGBA image, thus using 4 bytes per channel.
 */
export const NumOptionsChannels = 4
export type OptionsChannels = typeof NumOptionsChannels

/**
 * Image containing time needle options
 */
export type TimeNeedleOptions = CanvasImage<OptionsChannels>
export type ReadonlyTimeNeedleOptions = ReadonlyCanvasImage<OptionsChannels>

/**
 * Compute the number of pixels per row for the options image
 * associated with a given machine type.
 */
export function getPixelsPerOptionsRow(type: MachineType) {
  return Math.ceil(getOptionsBytes(type) / NumOptionsChannels)
}

/**
 * Create an array of bytes representing a row of default options
 * associated with a given machine type
 */
export function getDefaultOptionsRow(type: MachineType = 'kniterate') {
  return new Uint8Array(
    getOptionsList(type).flatMap(
      ({ defaultBytes }) => [...defaultBytes],
    ),
  )
}

/**
 * Create a new options image
 *
 * @param rows its number of rows
 * @param type the corresponding machine type
 * @param initialize whether to initialize it (`true` by default)
 * @returns the newly allocated options image
 */
export function createOptionsImage(
  rows: number,
  type: MachineType = 'kniterate',
  initialize = true,
): TimeNeedleOptions {
  const width = getPixelsPerOptionsRow(type)
  const img: TimeNeedleOptions = {
    width,
    height: rows,
    channels: NumOptionsChannels,
    data: new Uint8Array(width * rows * NumOptionsChannels),
  }
  if(initialize) {
    const rowBytes = getDefaultOptionsRow(type)
    for(let r = 0; r < rows; ++r) {
      setPixelRow(img, r, rowBytes)
    }
  }
  return img
}

/**
 * Return an option value within an option image
 *
 * @param opts the options image
 * @param row the row to read options from
 * @param field the option field to read
 * @returns its user-facing value
 */
export function getOption(
  opts: ReadonlyTimeNeedleOptions,
  row: number,
  field: OptionField,
) {
  const byteOffset = getRowIndex(opts, row) + field.offset
  const bytes = opts.data.subarray(byteOffset, byteOffset + field.byteLength)
  return fromFieldBytes(bytes, field)
}

/**
 * Return a list of option values from a row within an option image
 *
 * @param opts the options image
 * @param row the row to read options from
 * @param fields a list of option field to read
 * @returns the corresponding user-facing option values
 */
export function getOptions(
  opts: ReadonlyTimeNeedleOptions,
  row: number,
  ...fields: OptionField[]
): number[] {
  const rowOffset = getRowIndex(opts, row)
  return fields.map((f) => {
    const byteOffset = rowOffset + f.offset
    const bytes = opts.data.subarray(byteOffset, byteOffset + f.byteLength)
    return fromFieldBytes(bytes, f)
  })
}

/**
 * Update an option within an option image
 *
 * @param opts the options image
 * @param row the row to write an option to
 * @param field the option field
 * @param value the new value to store
 */
export function setOption(
  opts: TimeNeedleOptions,
  row: number,
  field: OptionField,
  value: number,
) {
  const byteOffset = getRowIndex(opts, row) + field.offset
  const bytes = asFieldBytes(value, field)
  opts.data.set(bytes, byteOffset)
}
