import {
  F2, RFV2, RFV4,
} from 'src/common/types';
import { clamp } from './math';

// basic projection matrix (without skewing)
/**
 * Projection matrix from screen domain [0;canvasWidth]x[0;canvasHeight]
 * into the shader domain such that [0;1]^2 corresponds to the inside
 * of the bitmap panel data.
 *
 * Note that the shader domain spans values outside [0;1]^2,
 * but only those within [0;1]^2 can be interacted with.
 */
export interface ProjectionMatrix {
  // scale
  kx: number
  ky: number
  // translation
  dx: number
  dy: number
}

/**
 * Transform from screen domain to shader domain.
 *
 * @see {@link ProjectionMatrix}
 * @see {@link makeProjectionMatrix}
 */
export function project([x, y]: RFV2, {
  dx, dy, kx, ky,
}: ProjectionMatrix) {
  return [
    kx * x + dx,
    ky * y + dy,
  ] as F2
}

/**
 * Transform from shader domain to screen domain.
 * This is the reciprocal of {@link project}.
 *
 * Note that
 * ```
 * unproject(p, M)
 * ```
 * can also be computed as a direction projection
 * using the reverse projection matrix:
 * ```
 * invM = makeReverseProjectionMatrix(M)
 * project(p, invM)
 * ```
 *
 * @see {@link project}
 * @see {@link makeReverseProjectionMatrix}
 */
export function unproject([x, y]: RFV2, {
  dx, dy, kx, ky,
}: ProjectionMatrix) {
  return [
    (x - dx) / kx,
    (y - dy) / ky,
  ] as F2
}

/**
 * From vertex shader:
 * - position in [-1;1]^2
 * - texcoord in [0;1]^2
 * using
 *   texcoord = (position + vec2(1.)) * 0.5
 *
 * From fragment shader:
 * - texcoord in [0;1]^2
 * - origCoord in [0;1]^2 within the page (panel)
 * using
 *    vec2 origCoord = vec2(
 *      viewAR * zoomScale * (texcoord.x + .5) - pageOffset.x,
 *      zoomScale * (texcoord.y + .5) - pageOffset.y
 *    )
 */

/**
 * Projection code from AppCanvasTransform:
 *
 *  projection = new Vec2(
 *    Px = s.zoom * getCombinedAR() * ((x / viewport.width + 0.5)) - offset.x,
 *    Py = s.zoom * ((1.0 - y / viewport.height) + 0.5) - offset.y,
 *  )
 *  with
 *    getCombinedAR() = viewAR * pageAR * cellAR
 *
 *  by manipulation:
 *    Px = [s.zoom * getCombinedAR() / viewport.width] * x + (s.zoom * getCombinedAR() * 0.5 - offset.x)
 *                          kx                         * x +            dx
 *    Py = [-s.zoom / viewport.height]                 * y + (s.zoom * 1.5 - offset.y)
 *                          ky                                          dy
 */

/**
 * The transformation data necessary to compute projections
 * between screen space and shader and page spaces.
 */
export interface TransformData {
  // interaction data
  offset: RFV2
  zoom: number
  // associated data
  pageDims: RFV2
  viewDims: RFV2
  cellAR: number
}

export function defaultTransform(cellAR: number): TransformData {
  return {
    offset: [0, 0],
    zoom: 1.0,
    viewDims: [1, 1],
    pageDims: [1, 1],
    cellAR,
  }
}

/**
 * Computes the combined aspect ratio `cellAR * pageAR * viewAR`
 * where
 * ```
 * pageAR = pageDims[1] / pageDims[0]
 * ```
 * and
 * ```
 * viewAR = viewDims[0] / viewDims[1]
 * ```
 * @returns `cellAR * pageAR * viewAR`
 */
export function combinedAR({
  viewDims,
  pageDims,
  cellAR,
}: TransformData) {
  const pageAR = pageDims[1] / pageDims[0]
  const viewAR = viewDims[0] / viewDims[1]
  return cellAR * pageAR * viewAR
}

/**
 * Compute the projection matrix associated with transformation data.
 * The project matrix transforms pixels to shader space.
 *
 * The shader space has bounds [0;1]^2 around the page / panel.
 *
 * @param t the transformation data
 * @returns the projection matrix
 */
export function makeProjectionMatrix(t: TransformData): ProjectionMatrix {
  const zoomTimesAR = t.zoom * combinedAR(t)
  return {
    kx: zoomTimesAR / t.viewDims[0],
    ky: -t.zoom / t.viewDims[1],
    dx: zoomTimesAR * 0.5 - t.offset[0],
    dy: t.zoom * 1.5 - t.offset[1],
  }
}

export function makeReverseProjectionMatrix(
  arg: TransformData | ProjectionMatrix,
): ProjectionMatrix {
  if('offset' in arg) {
    arg = makeProjectionMatrix(arg)
  }
  // unproject:
  //  (x - dx) / kx = 1/kx * x + (-dx/kx)
  //  (y - dy) / ky = 1/ky * y + (-dy/ky)
  const {
    kx, ky, dx, dy,
  } = arg
  return {
    kx: 1 / kx,
    ky: 1 / ky,
    dx: -dx / kx,
    dy: -dy / ky,
  }
}

export function resetOffset(
  t: TransformData,
  {
    kx, ky, dx, dy,
  }: ProjectionMatrix,
  offset: RFV2,
): [TransformData, ProjectionMatrix] {
  const newTrans = {
    ...t,
    offset: [offset[0], offset[1]] as F2,
  }
  const dox = offset[0] - t.offset[0] // the increase in offset[0]
  const doy = offset[1] - t.offset[1] // the increase in offset[1]
  // note: dx = zoomTimesAR * 0.5 - offset[0]
  //       dy = t.zoom * 1.5 - t.offset[1]
  // => the change in the transform has a reverse sign
  return [newTrans, {
    kx, ky, dx: dx - dox, dy: dy - doy,
  }]
}

/**
 * A rounding function
 *
 * @see {@link Math.floor}
 * @see {@link Math.ceil}
 * @see {@link Math.round}
 */
export type RoundingFunc = (x: number) => number
/**
 * The identity function (so as not to round)
 */
export const NoRounding: RoundingFunc = (x: number) => x

/**
 * Transform from screen pixels to cell coordinates.
 *
 * The screen space spans [0;canvasWidth]x[0;canvasHeight].
 *
 * The valid cell coordinates span [0;pageWidth];[0;pageHeight],
 * but note that a screen pixel may project outside of the panel page.
 */
export function getCoord(p: RFV2, M: ProjectionMatrix, pageDims: RFV2, rnd: RoundingFunc = NoRounding) {
  const [x, y] = project(p, M)
  return [
    rnd(x * pageDims[0]),
    rnd(y * pageDims[1]),
  ] as F2
}

/**
 * A different version of {@link getCoord}
 * that computes the projection matrix inline (slower!).
 *
 * @see {@link getCoord}
 * @see {@link makeProjectionMatrix}
 */
export function getTCoord(p: RFV2, t: TransformData, rnd: RoundingFunc = NoRounding) {
  const M = makeProjectionMatrix(t)
  return getCoord(p, M, t.pageDims, rnd)
}

/**
 * Transform from cell coordinates to screen pixels
 */
export function unCoord([x, y]: RFV2, M: ProjectionMatrix, pageDims: RFV2) {
  return unproject([
    x / pageDims[0],
    y / pageDims[1],
  ], M)
}

/**
 * Generate a faster version of unCoord
 * by factorizing the reverse project matrix
 *
 * @see {@link unCoord}
 */
export function getFastUnCoord(M: ProjectionMatrix, pageDims: RFV2) {
  const invM = makeReverseProjectionMatrix(M)
  return ([x, y]: RFV2) => project([
    x / pageDims[0],
    y / pageDims[1],
  ], invM)
}

/**
 * @see {@link unCoord}
 */
export function unTCoord(p: RFV2, t: TransformData) {
  const M = makeProjectionMatrix(t)
  return unCoord(p, M, t.pageDims)
}

/**
 * Validates whether a cell coordinate is within the panel page dimensions.
 */
export function isWithinPage(coord: RFV2, pageDims: RFV2) {
  return (
    coord[0] >= 0 && coord[0] <= pageDims[0] - 1 &&
    coord[1] >= 0 && coord[1] <= pageDims[1] - 1
  )
}

/**
 * Project a cell coordinate to the panel page so that it lays within it
 */
export function clampToPage(p: RFV2, pageDims: RFV2) {
  return [
    clamp(p[0], 0, pageDims[0] - 1),
    clamp(p[1], 0, pageDims[1] - 1),
  ] as F2
}

/**
 * Transform a screen pixel into a cell coordinates
 * while accounting for half-racking that shifts the rear of cells.
 *
 * @param getRacking a method for computing the racking associated with a row
 * @param useSafeRow whether to ensure that {@link getRacking} receives valid row values
 */
export function getRackedCell(
  p: RFV2,
  M: ProjectionMatrix,
  pageDims: RFV2,
  getRacking: (row: number) => number,
  useSafeRow = true,
) {
  // project in page space
  const [x, y] = project(p, M)

  // get cell row
  const cy = y * pageDims[1]
  const row = Math.floor(cy)
  const isRear = cy - row >= 0.5

  // look up racking value
  const rack = getRacking(
    useSafeRow ? clamp(row, 0, pageDims[1] - 1) : row,
  )

  // if in rear, shift by a potential (+/-)half-rack
  // /!\ note: we are providing the location of the front cell
  //     the racking is only used for adjusting the projection
  //     in the rear of half-racked cells
  const halfShift = Math.sign(rack) * (rack - Math.floor(rack))
  const dx = isRear ? halfShift : 0
  const col = Math.floor(x * pageDims[0] - dx)
  return [
    col,
    row,
  ] as F2
}

/**
 * Checks whether two points are equal.
 * This assumes that they have the same cardinality
 * and throws in case they do not.
 *
 * The default component equality {@link eq} is exact.
 *
 * @param eq the equality function to use between each components
 */
export function areEquals(
  p: ArrayLike<number>,
  q: ArrayLike<number>,
  eq = (a: number, b: number) => a === b,
) {
  if(p.length !== q.length) {
    throw 'Arrays have different cardinalities'
  }
  for(let i = 0; i < p.length; ++i) {
    if(!eq(p[i], q[i])) {
      return false
    }
  }
  return true
}

/**
 * Checks whether two rectangles overlap.
 * This assumes that both rectangles are inclusive of all extents.
 *
 * Note that AABB vectors are typically used as exclusive for integer
 * coordinates that use the `width` and `height` information.
 */
export function overlap(
  [l1, b1, r1, t1]: RFV4,
  [l2, b2, r2, t2]: RFV4,
) {
  // @see https://stackoverflow.com/questions/306316/determine-if-two-rectangles-overlap-each-other
  return !(
    r1 < l2 ||
    l1 > r2 ||
    t1 < b2 ||
    b1 > t2
  )
}
