import { shallowEqual } from 'react-redux'
import { redoAction, undoAction, UndoDomain } from 'src/undo'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { ctrlStr } from 'src/common/env'
import ContextMenuItem from './menu-item'

export default function UndoRedoMenu() {
  const {
    past, future,
  } = useAppSelector((state) => state.undo.editor, shallowEqual)
  const dispatch = useAppDispatch()
  const canUndo = past.length > 0
  const canRedo = future.length > 0
  return (
    <ContextMenuItem
      name="Undo / redo"
      disabled={!canUndo && !canRedo}
      menu
    >
      <ContextMenuItem
        name="Undo"
        shortcut={`(${ctrlStr}+Z)`}
        disabled={!canUndo}
        onClick={() => {
          dispatch(undoAction(UndoDomain.EDITOR))
        }}
      />
      <ContextMenuItem
        name="Redo"
        shortcut={`(${ctrlStr}+Y)`}
        disabled={!canRedo}
        onClick={() => {
          dispatch(redoAction(UndoDomain.EDITOR))
        }}
      />
    </ContextMenuItem>
  )
}
