import { createTimeNeedleImage } from 'src/data/time-needle/time-needle-image'
import MachineType from 'src/data/time-needle/machine-type'
import { setPixelChannel } from 'src/data/image'
import { StitchCode } from 'src/data/time-needle/stitch-code'
import { setCarrier } from 'src/data/time-needle/options'

export function createStarterImage(
  width = 64,
  height = 153,
  type: MachineType = 'kniterate',
) {
  const tni = createTimeNeedleImage(width, height, type)
  // set front-knit on all stitches
  for(let i = 0, n = width * height; i < n; ++i) {
    setPixelChannel(tni.cdata, i, 0, StitchCode.FRNT_KNIT)
  }
  for(let r = 0; r < height; ++r) {
    setCarrier(tni, r, 3)
  }
  return tni
}
