import { useRef } from 'react'
import TimeNeedleImage, { copyTimeNeedleImage, ReadonlyTimeNeedleImage, sameTimeNeedleImages } from 'src/data/time-needle/time-needle-image'
import { UndoableSnapshotAction } from 'src/editor/time-needle/action'
import { setTimeNeedleImage } from 'src/editor/time-needle/slice'
import { addUndoableAction, UndoableAction } from 'src/undo'
import { useAppDispatch, useAppSelector } from './redux'

/**
 * Snapshot options
 */
export interface SnapshotOptions {
  /**
   * If present, corresponds to the wait time in milliseconds
   * for 'debouncing' successive undoable snapshots.
   *
   * This is effectively the wait time between
   * any undoable snapshot and the last idle time
   * (i.e., without any snapshot attempt).
   *
   * Practically, it means that the snapshot mechanism
   * should not be called for at least such wait time
   * before triggering again.
   *
   * @see throttle
   */
  debounce?: number

  /**
   * If present, corresponds to the wait time in milliseconds
   * for 'throttling' successive undoable snapshots.
   *
   * This is effectively the wait time between
   * successive snapshots. Other snapshot attempts
   * can be made within that period.
   *
   * Practically, it means that the snapshot mechanism
   * will trigger at most as often as that wait time.
   *
   * @see debounce
   */
  throttle?: number

  /**
   * Flag to disable undoable snapshotting.
   */
  disabled?: boolean
}

/**
 * Hook returning a snapshot function that receives
 * the current read-only time-needle image.
 *
 * The return of the image function passed to the snapshot method
 * should correspond to the updated image.
 * It will only be applied if actually different from the current image.
 *
 * Options can be passed to control whether an undoable snapshot
 * should be created or not. Such options can either be passed
 * to this method itself, or to the individual snapshot calls.
 *
 * @param defaultOpts the default options to use for individual calls
 * @returns a method for generating configurable snapshot updates
 * @see useSnapshot
 */
export function useRawSnapshot(defaultOpts: SnapshotOptions = {}) {
  const curTNI = useAppSelector((state) => state.canvas.tnimage)
  const curUndo = useAppSelector((state) => state.undo.editor.past.slice(-1)[0])
  const dispatch = useAppDispatch()
  const lastTime = useRef<number>(0)
  const lastUndo = useRef<UndoableAction>()
  const lastTNI = useRef<ReadonlyTimeNeedleImage>()
  return (
    imgFunc: (tni: ReadonlyTimeNeedleImage) => ReadonlyTimeNeedleImage | void,
    opts: SnapshotOptions = defaultOpts,
  ) => {
    // apply image-based function
    const newTNI = imgFunc(curTNI)
    if(!newTNI) { return false }

    // check if changes happened
    const undoable = UndoableSnapshotAction.from(curTNI, newTNI)
    if(!undoable) { return false }

    // trigger update, but how depends on options
    const debounce = opts.debounce ?? 0
    const throttle = opts.throttle ?? 0
    const wait = debounce || throttle
    if(opts.disabled) {
      // never create undoable action when disabled
      dispatch(setTimeNeedleImage(newTNI))
    } else if(wait) {
      // only create undoable action past wait time
      if(debounce && throttle) {
        throw 'Invalid debounce and throttle options at the same time'
      }
      const currTime = Date.now()
      if(currTime - lastTime.current > wait) {
        // validate that we really need a snapshot
        // in case throttling / debouncing made this invalid
        if(
          lastUndo.current === curUndo &&
          lastTNI.current &&
          sameTimeNeedleImages(newTNI, lastTNI.current)
        ) {
          // /!\ this is a misfire due to throttling / debouncing!
          dispatch(setTimeNeedleImage(newTNI))
        } else {
          // partial check would be suspicious
          if(lastTNI.current && sameTimeNeedleImages(newTNI, lastTNI.current)) {
            console.warn('Suspicious snapshot')
          } else if(sameTimeNeedleImages(curTNI, newTNI)) {
            console.error('Invalid snapshot')
          }
          // proper snapshot
          dispatch(addUndoableAction(undoable))
          // remember last undo action and current time-needle image
          lastUndo.current = curUndo
          lastTNI.current = newTNI
        }
        // throttle timer update
        if(throttle) {
          // timer update after a successful snapshot
          // => will have to wait for an action
          //    after some wait time since another snapshot
          lastTime.current = currTime
        }
      } else {
        // update without undo capacity
        dispatch(setTimeNeedleImage(newTNI))
      }
      // debounce time update
      if(debounce) {
        // timer update after any action
        // => will have to wait for an action
        //    after some idle wait time
        lastTime.current = currTime
      }
    } else {
      // always update with undo capacity
      dispatch(addUndoableAction(undoable))
    }
  }
}

/**
 * Hook returning a snapshot function that receives a writeable
 * copy of the current time-needle image.
 *
 * The return of the image function passed to the snapshot method
 * should correspond to the updated image.
 * It will only be applied if actually different from the current image.
 *
 * Options can be passed to control whether an undoable snapshot
 * should be created or not. Such options can either be passed
 * to this method itself, or to the individual snapshot calls.
 *
 * This uses {@link useRawSnapshot} under the hood and basically
 * takes care of creating a writeable copy.
 * If you do not need a writeable copy or end up generating
 * a new image anyway (e.g., because of topology changes), then
 * you should probably use {@link useRawSnapshot}.
 *
 * @param defaultOpts the default options to use for individual calls
 * @returns a method for generating configurable snapshot updates
 * @see useRawSnapshot
 */
export function useSnapshot(defaultOpts: SnapshotOptions = {}) {
  const fun = useRawSnapshot(defaultOpts)
  return (
    imgFunc: (tni: TimeNeedleImage) => ReadonlyTimeNeedleImage | void,
    opts: SnapshotOptions = defaultOpts,
  ) => {
    fun((tni) => {
      const wtni = copyTimeNeedleImage(tni)
      return imgFunc(wtni) ?? wtni
    }, opts)
  }
}
