import { RFV4 } from 'src/common/math';

/*
The method to compute the page dimensions needs to be sensitive to the order of inputs.
For example, we may specify a width in stitches per 10cm which translates to an approximate
width for the canvas.  The user should be able to give preference to terms so we can
recompute the necessary relationships.

We need to add a start offset parameter to the Grid data in order to allow us to output
Uck with
 */

export interface GridData {
  stitchesPer10Cm: number
  rowsPer10Cm: number
  widthInCm: number
  heightInCm: number
  widthInCells: number
  heightInCells: number

  // The data flag indicates which parameters have been altered (by the user)
  dataFlag: {
    stitchesPer10Cm: boolean
    rowsPer10Cm: boolean
    widthInCm: boolean
    heightInCm: boolean
    widthInCells: boolean
    heightInCells: boolean
  }
}

export function makeGridDims(width: number, height: number, AR?: [number, number]): [RFV4, number, GridData] {
  const gd = {
    stitchesPer10Cm: AR ? AR[0] : 50,
    rowsPer10Cm: AR ? AR[1] : 60,
    widthInCm: 0,
    heightInCm: 0,
    widthInCells: width,
    heightInCells: height,

    dataFlag: {
      stitchesPer10Cm: false,
      rowsPer10Cm: false,
      widthInCm: false,
      heightInCm: false,
      widthInCells: true,
      heightInCells: true,
    },
  }

  const res = computeGridDimensions(gd);
  return [res[0], res[1], gd];
}

export function computeGridDimensions(gridData: GridData): [RFV4, number] {
  if(gridData.dataFlag.widthInCells || gridData.dataFlag.heightInCells ||
        ((!gridData.dataFlag.widthInCm || !gridData.dataFlag.widthInCm) &&
            (gridData.dataFlag.stitchesPer10Cm || gridData.dataFlag.rowsPer10Cm))) {
    if(gridData.widthInCells > 252) gridData.widthInCells = 252;

    const stitchWidth = 10 / gridData.stitchesPer10Cm;
    const rowHeight = 10 / gridData.rowsPer10Cm;

    // We need to pass on the corrected input data
    gridData.widthInCm = parseFloat((gridData.widthInCells * stitchWidth).toFixed(1));
    gridData.heightInCm = parseFloat((gridData.heightInCells * rowHeight).toFixed(1));

    return [
      [
        0,
        0,
        Math.floor(gridData.widthInCells),
        Math.floor(gridData.heightInCells),
      ],
      rowHeight / stitchWidth,
    ];
  }
  const stitchWidth = 10 / gridData.stitchesPer10Cm;
  const rowHeight = 10 / gridData.rowsPer10Cm;
  const stitchesPerCm = gridData.stitchesPer10Cm / 10;
  const rowsPerCm = gridData.rowsPer10Cm / 10;

  const width = gridData.widthInCm * stitchesPerCm;
  if(width > 252) {
    gridData.widthInCells = 252;
    gridData.dataFlag.widthInCells = true;
    return computeGridDimensions(gridData);
  }

  return [
    [
      0,
      0,
      Math.floor(width),
      Math.floor(gridData.heightInCm * rowsPerCm),
    ],
    rowHeight / stitchWidth,
  ];
}
