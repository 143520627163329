import { clamp } from 'src/common/math'
import { StitchCode } from 'src/data/time-needle/stitch-code'
import { TimeNeedleTransformer } from './common'

/**
   * Shift the rear needle codes and recompute stitch codes
   * by assembling them with the existing front needle codes.
   *
   * @param t the transformer
   * @param offset the shift to apply (not restricted to racking)
   * @param updateRacking whether to apply the shift to the rackin (`false` by default)
   */
export function shiftRearSymbols(
  t: TimeNeedleTransformer,
  offset: number,
  updateRacking = false,
) {
  // compute needle codes from direct shift
  const shiftedSrc = t.neighbor(offset, 0).neighbor(-offset, 0)
  const shifted = shiftedSrc.neighbor(offset, 0)
  const shiftedRearCodes = shiftedSrc.remap((c) => c.getNeedleCodes()[1])
  // compute needle codes from indirect shift
  const unshiftedSrc = t.neighbor(-offset, 0).neighbor(offset, 0)
  const unshiftedRearCodes = unshiftedSrc.neighbor(-offset, 0).remap((c) => c.getNeedleCodes()[1])

  // recombine rear needle codes
  // /!\ we replace invalid combinations with a miss symbol
  shifted.forEach((c, i) => {
    c.ncode(shiftedRearCodes[i], 1, StitchCode.MISS)
  })
  unshiftedSrc.forEach((c, i) => {
    c.ncode(unshiftedRearCodes[i], 1, StitchCode.MISS)
  })

  // optionally update racking
  if(updateRacking) {
    this.remapOptions(({ racking }) => ({
      racking: clamp(racking + offset, -4, 4),
    }))
  }
  return t
}
