import { useState, useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom';
// @see https://stackoverflow.com/questions/53595935/how-can-i-make-react-portal-work-with-react-hook
// @see https://medium.com/hackernoon/using-a-react-16-portal-to-do-something-cool-2a2d627b0202

export interface PortalWindowProps {
  width?: number | string
  height?: number | string
  left?: number | string
  top?: number | string
  onClose?: () => void
  children: ReactNode | ReactNode[]
}

export default function PortalWindow({
  width = 600, height = 400,
  left = 200, top = 200,
  onClose,
  children,
}: PortalWindowProps) {
  const [containerEl] = useState<HTMLDivElement>(document.createElement('div'));
  let externalWindow: Window

  useEffect(() => {
    externalWindow = window.open(
      '',
      '',
      `width=${width},height=${height},left=${left},top=${top}`,
    );

    externalWindow.document.body.appendChild(containerEl);
    externalWindow.addEventListener('beforeunload', () => {
      if(onClose) onClose()
    });
    // console.log('Created Popup Window');
    return function cleanup() {
      // console.log('Cleaned up Popup Window');
      externalWindow.close();
      externalWindow = null;
    };
  }, []);
  return ReactDOM.createPortal(children, containerEl);
}
