/*
Functions, classes, and data structures related to texture packing (option column, etc)
 */

import { clamp, fromBytes, toBytes } from 'src/common/math';
import { ReadonlyUint8Array } from 'src/common/types';
// the base description of a field
export interface OptionFieldDescriptor {
  readonly min: number
  readonly max: number
  readonly step: number
  readonly defaultValue: number
  readonly float?: boolean
}

export default interface OptionField extends OptionFieldDescriptor {
  readonly name: string
  readonly index: number
  readonly byteLength: number
  readonly offset: number
  readonly defaultBytes: ReadonlyUint8Array
}

/**
 * Convert a generic number into a compressed field value (unsigned integer)
 */
function compressFieldValue(
  val: number,
  {
    min, max, step,
  }: OptionFieldDescriptor,
  byteLength: number,
) {
  val = clamp(val, min, max)
  return Math.min(Math.floor((val - min) / step), 256 ** byteLength)
}

export function asFieldBytes(val: number, field: OptionField): ReadonlyUint8Array {
  const compressed = compressFieldValue(val, field, field.byteLength)
  return toBytes(compressed, field.byteLength)
}

/**
 * Convert a compressed field value (unsigned integer) into a generic number
 */
function decompressFieldValue(
  val: number,
  {
    min, step, max, float = false,
  }: OptionField,
) {
  if(float) {
    return clamp(min + val * step, min, max)
  }
  return clamp(Math.round(min + val * step), min, max)
}

export function fromFieldBytes(bytes: ReadonlyUint8Array, field: OptionField): number {
  const compressed = fromBytes(bytes)
  return decompressFieldValue(compressed, field)
}

export type OptionDescriptorMap = {
  [k: string]: OptionFieldDescriptor
}
export type OptionFieldMap<M extends OptionDescriptorMap> = {
  [K in keyof M]: OptionField
}

export function ofields<M extends OptionDescriptorMap>(
  dmap: M,
): [OptionFieldMap<M>, OptionField[], number] {
  const options = new Array<OptionField>()
  const map = {} as OptionFieldMap<M>
  let offset = 0
  for(const [name, desc] of Object.entries(dmap) as [keyof M & string, M[keyof M]][]) {
    const {
      min, max, step, defaultValue,
    } = desc
    const numValues = Math.ceil((max - min) / step)
    const numBits = Math.max(1, Math.ceil(Math.log2(numValues)))
    const byteLength = Math.ceil(numBits / 8)
    console.assert(byteLength > 0, 'Option has no byte length')
    // return annotated option field
    map[name] = {
      ...desc,
      name,
      index: options.length,
      byteLength,
      offset,
      defaultBytes: toBytes(
        compressFieldValue(defaultValue, desc, byteLength),
        byteLength,
      ),
    }
    offset += byteLength
    options.push(map[name])
  }
  return [map, options, offset]
}

/**
 * Generate all possible values of an option field,
 * from minimum value to maximum value, step by step.
 *
 * @param field the option field
 */
export function* fieldValues({ min, step, max }: OptionField) {
  for(let value = min; value <= max; value += step) {
    yield value
  }
}
