import {
  info, isCarriageMove, isCarrierPass, isSoftMissPass,
  Issue, isXferPass, KCodePass, KnitoutCommand, KnitoutEntry, KNITOUT_PREAMBLE_NUM_LINES, PanelLocation,
} from 'src/data/compiler';
import TimeNeedleSelector from 'src/data/time-needle/selector';

const IMPLICIT_KICKBACK = 'implicit-kickback'
const USER_KICKBACK = 'user-kickback'
const XFER_INTERLEAVE = 'xfer-interleave'
const CARRIAGE_MOVE = 'carriage-move'

export function implicitActions(
  s: TimeNeedleSelector,
  commands: KnitoutEntry[],
  passes: KCodePass[],
  issues: Issue[],
) {
  let lastSrc: PanelLocation = -1
  let xferStack = 0
  for(const pass of passes) {
    const [first, last] = pass.source.map((knitoutLineIdx) => knitoutLineIdx - KNITOUT_PREAMBLE_NUM_LINES)
    const firstCmd = commands[first]
    const firstLoc = firstCmd?.src
    const lastLoc = commands[last]?.src
    if(first === last) {
      const row = Array.isArray(firstLoc) ? firstLoc[0] : firstLoc
      if(isSoftMissPass(pass)) {
        // check type
        if(isNeedleCarrierAction(firstCmd?.cmd) && hasUserDirection(s, row) && isSingleActionRow(s, row)) {
          issues.push(info(
            USER_KICKBACK,
            `Directed '${firstCmd.cmd}' induces kickback`,
            true, // verbose information
          ).at(firstLoc))
          xferStack = 0
        } else if(isXferPass(pass) && !isCarrierPass(pass)) {
          if(xferStack === 1) {
            issues.push(info(
              XFER_INTERLEAVE,
              'Transfer interleaving induces a pass',
              true, // verbose information
            ).at(row))
          } else if(lastLoc === lastSrc) {
            ++xferStack
          } else {
            xferStack = 1
          }
        } else if(isCarrierPass(pass) && firstCmd?.cmd !== 'out') {
          const carrierDir = pass.direction === '+' ? 'right' : 'left'
          issues.push(info(
            IMPLICIT_KICKBACK,
            `'${firstCmd.cmd}' required an implicit kickback of carrier ${pass.carrier} to the ${carrierDir}`,
          ).between(firstLoc, lastSrc))
          xferStack = 0
        }
      } else if(isCarriageMove(pass)) {
        issues.push(info(
          CARRIAGE_MOVE,
          'Carriage movement inserted to connect actions',
          true, // verbose information
        ).between(firstLoc, lastSrc))
        xferStack = 0
      }
    } else {
      xferStack = 0
    }
    lastSrc = lastLoc
  }
}

function isNeedleCarrierAction(cmd: KnitoutCommand): boolean {
  return cmd === 'knit' || cmd === 'tuck' || cmd === 'split'
}

function hasUserDirection(s: TimeNeedleSelector, row: number): boolean {
  return s.rowOptions(row).direction !== 2
}

function isSingleActionRow(s: TimeNeedleSelector, row: number): boolean {
  return s.fullCourse(row).count((c) => !(c.isMiss() || c.isExplicitMiss())) === 1 // exactly one action that is not a miss
}
