import { Color } from 'src/common/math'
import { base64EncArr, base64DecToArr } from 'src/common/base64'

export interface DesignLink {
    id: string
    name: string
    lastModified: string
    thumbnail: string
}

export function makeDesignLink(
  id: string,
  name: string,
  lastModified: string,
  thumbnail: string,
): DesignLink {
  return {
    id,
    name,
    lastModified,
    thumbnail,
  }
}

export interface Design {
    id: string
    author?: string
    name?: string
    buck?: Uint8Array
    colors?: Array<Color>
    widthInCm?: number
    heightInCm?: number
    stitchesPerCm?: number
    rowsPerCm?: number
    thumbnail: string
}

export function makeDesign(
  name?: string,
  buck?: Uint8Array,
  colors?: Array<Color>,
  widthInCm?: number,
  heightInCm?: number,
  stitchesPerCm?: number,
  rowsPerCm?: number,
  thumbnail?: string,
): Design {
  return {
    id: '',
    author: '',
    name,
    buck,
    colors,
    widthInCm,
    heightInCm,
    stitchesPerCm,
    rowsPerCm,
    thumbnail,
  }
}

const API_ENDPOINT = new URL(process.env.REST_EP).origin
const API_VERSION = '/v1'
const CURR_ENDPOINT = API_ENDPOINT + API_VERSION

const buildURL = (resource: string) => CURR_ENDPOINT + resource

function GET(token: string): Object {
  return {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  }
}

function POST(body: string, token: string): Object {
  return {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body,
  }
}

function DELETE(token: string): Object {
  return {
    method: 'DELETE',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  }
}

function PUT(body: string, token: string): Object {
  return {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body,
  }
}

function logError(response: Response): Response {
  if(!response.ok) {
    console.error('HTTP Request failed with reason:', response.status, response.statusText)
    throw Error(response.statusText)
  }
  return response
}
interface JSONDesignLink {
  _id: string
  name: string
  lastModified: string
  thumbnail: string
}
export function parseDesignLink(json: JSONDesignLink): DesignLink {
  if(!json) return null

  const id = Object.prototype.hasOwnProperty.call(json, '_id') && json._id
  let name = Object.prototype.hasOwnProperty.call(json, 'name') && json.name
  const lastModified = Object.prototype.hasOwnProperty.call(json, 'lastModified') && json.lastModified
  const thumbnail = Object.prototype.hasOwnProperty.call(json, 'thumbnail') && json.thumbnail
  name = name === '' ? 'Untitled' : name
  return id && lastModified ?
    makeDesignLink(id, name, lastModified, thumbnail) :
    null
}

export function getDesignsList(token: string): Promise<Array<DesignLink>> {
  return new Promise(
    ((resolve, reject) => {
      fetch(buildURL('/designs'), GET(token))
        .then(logError)
        .then((res) => {
          if(res.status === 204) {
            resolve(new Array<DesignLink>())
            return { then: () => {} } // break promise chain
          }
          return res.json()
        })
        .then((res) => (Array.isArray(res) ? resolve(res.map(parseDesignLink).filter((x) => x)) : []))
        .catch((err) => {
          reject(`Error: ${err}`)
          console.log('Error:', err)
        })
    }),
  )
}

export function getDesign(oid: string, token: string): Promise<Design> {
  return new Promise(
    ((resolve, reject) => {
      fetch(buildURL(`/designs/${oid}`), GET(token))
        .then(logError)
        .then((res) => res.json())
        .then((res) => {
          const colours = Object.prototype.hasOwnProperty.call(res, 'colors') ?
            res.colors.map((v) => new Color(
              Object.prototype.hasOwnProperty.call(v, 'r') ? v.r : 0,
              Object.prototype.hasOwnProperty.call(v, 'g') ? v.g : 0,
              Object.prototype.hasOwnProperty.call(v, 'b') ? v.b : 0,
            )) :
            []

          const buck = Object.prototype.hasOwnProperty.call(res, 'buck') ?
            new Uint8Array(base64DecToArr(res.buck).buffer) :
            new Uint8Array(0)

          const design: Design = {
            id: Object.prototype.hasOwnProperty.call(res, '_id') ? res._id : '',
            author: Object.prototype.hasOwnProperty.call(res, 'author') ? res.author : '',
            name: Object.prototype.hasOwnProperty.call(res, 'name') ? res.name : '',
            buck,
            colors: colours,
            widthInCm: Object.prototype.hasOwnProperty.call(res, 'widthCm') ? res.widthCm : 0,
            heightInCm: Object.prototype.hasOwnProperty.call(res, 'heightCm') ? res.heightCm : 0,
            stitchesPerCm: Object.prototype.hasOwnProperty.call(res, 'stitchesPerCm') ? res.stitchesPerCm : 0,
            rowsPerCm: Object.prototype.hasOwnProperty.call(res, 'rowsPerCm') ? res.rowsPerCm : 0,
            thumbnail: Object.prototype.hasOwnProperty.call(res, 'thumbnail') ? res.thumbnail : '',
          }

          resolve(design)
        })
        .catch((err) => {
          reject(`Error: ${err}`)
          console.log('Error:', err)
        })
    }),
  )
}

export async function createDesign(input: Design, token: string): Promise<string> {
  const b64 = base64EncArr(input.buck)

  const colors = input.colors.map((v) => {
    const col = v.getC3()
    return {
      r: col[0],
      g: col[1],
      b: col[2],
    }
  })

  const design = {
    name: input.name,
    buck: b64,
    colors,
    widthCm: input.widthInCm,
    heightCm: input.heightInCm,
    stitchesPerCm: input.stitchesPerCm,
    rowsPerCm: input.rowsPerCm,
    thumbnail: input.thumbnail,
  }

  const body = JSON.stringify(design)
  const response = await fetch(buildURL('/designs'), POST(body, token))
  return response.json()
}

export async function updateDesign(oid: string, input: Design, token: string): Promise<Response> {
  const colors = input.colors.map((v) => {
    const col = v.getC3()
    return {
      r: col[0],
      g: col[1],
      b: col[2],
    }
  })

  const design = {
    name: input.name,
    colors,
    buck: base64EncArr(input.buck),
    widthCm: input.widthInCm,
    heightCm: input.heightInCm,
    stitchesPerCm: input.stitchesPerCm,
    rowsPerCm: input.rowsPerCm,
    thumbnail: input.thumbnail,
  }

  const body = JSON.stringify(design)
  return fetch(buildURL(`/designs/${oid}`), PUT(body, token))
}

export async function renameDesign(oid: string, name: string, token: string): Promise<Response> {
  return fetch(buildURL(`/designs/${oid}`), PUT(JSON.stringify({ name }), token))
}

export async function deleteDesign(oid: string, token: string): Promise<void> {
  await fetch(buildURL(`/designs/${oid}`), DELETE(token))
}

export async function submitBugReport(oid: string, token: string, input: Design, msg: string): Promise<void> {
  let bugReport
  if(input != null) {
    const b64 = base64EncArr(input.buck)

    const colors = input.colors.map((v) => {
      const col = v.getC3()
      return {
        r: col[0],
        g: col[1],
        b: col[2],
      }
    })

    const design = {
      name: input.name,
      buck: b64,
      colors,
      widthCm: input.widthInCm,
      heightCm: input.heightInCm,
      stitchesPerCm: input.stitchesPerCm,
      rowsPerCm: input.rowsPerCm,
      thumbnail: input.thumbnail,
    }

    bugReport = {
      msg,
      design,
    }
  } else {
    bugReport = {
      msg,
    }
  }
  const body = JSON.stringify(bugReport)
  await fetch(buildURL(`/designs/reportBug/${oid}`), POST(body, token)).then(logError)
}

export async function copyDesign(oid: string, token: string) {
  return fetch(buildURL(`/designs/copy/${oid}`), POST(null, token)).then(logError).then((res) => res.json())
}
