import { clamp } from 'src/common/math';
import { useApplyMacro } from 'src/hooks';
import { shiftRearSymbols } from 'src/macros';
import ContextMenuItem from './menu-item';

export default function RackingMenu() {
  const applyMacro = useApplyMacro()
  return (
    <ContextMenuItem
      name="Racking"
      menu
    >
      <ContextMenuItem
        name="Rack -1"
        onClick={() => {
          applyMacro((s) => s.remapOptions(({ racking }) => ({ racking: clamp(racking - 1, -4, 4) })))
        }}
      />
      <ContextMenuItem
        name="Rack +1"
        onClick={() => {
          applyMacro((s) => s.remapOptions(({ racking }) => ({ racking: clamp(racking + 1, -4, 4) })))
        }}
      />
      <ContextMenuItem
        name="Set racking"
        prompt={['Racking value in 0.5 increments', '0.0']}
        onInput={(rackStr) => {
          const racking = Math.round(2 * parseFloat(rackStr)) * 0.5
          if(!(racking >= -4 && racking <= 4 && racking.toString() === rackStr)) {
            alert(`Racking is either invalid or out of bounds: ${racking}`)
          }
          applyMacro((s) => s.options({ racking }))
        }}
      />
      <ContextMenuItem
        name="Shift rear symbols"
        prompt={['Shift integer (e.g., -4 or 4)', '1']}
        onInput={(shiftStr) => {
          const shift = parseInt(shiftStr, 10)
          if(!shift) { return }
          applyMacro((s) => shiftRearSymbols(s.extendLeft().extendRight(), shift))
        }}
      />
      <ContextMenuItem
        name="Shift local rear symbols"
        prompt={['Shift integer (e.g., -4 or 4)', '1']}
        onInput={(shiftStr) => {
          const shift = parseInt(shiftStr, 10)
          if(!shift) { return }
          applyMacro((s) => shiftRearSymbols(s, shift))
        }}
      />
    </ContextMenuItem>
  )
}
