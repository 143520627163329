import { SelectorLike } from './common'

export function extendLaterallyToKnitted(s: SelectorLike): SelectorLike {
  const sets = []
  // left expansion
  let left = s.leftmost().left()
  while(left.some((c) => c.isKnit())) {
    sets.push(left)
    left = left.left()
  }
  // right expansion
  let right = s.rightmost().right()
  while(right.some((c) => c.isKnit())) {
    sets.push(right)
    right = right.right()
  }
  return s.union(sets)
}

export function extendVerticallyToKnitted(s: SelectorLike): SelectorLike {
  const sets = []
  // top expansion
  let top = s.topmost().up()
  while(top.some((c) => c.isKnit())) {
    sets.push(top)
    top = top.up()
  }
  // bottom expansion
  let bottom = s.bottommost().down()
  while(bottom.some((c) => c.isKnit())) {
    sets.push(bottom)
    bottom = bottom.down()
  }
  return s.union(sets)
}

export function extendToKnitted(s: SelectorLike): SelectorLike {
  let ps: SelectorLike
  const maxIt = 200
  for(let i = 0; i < maxIt && s !== ps; ++i) {
    ps = s
    s = extendLaterallyToKnitted(extendVerticallyToKnitted(s))
  }
  return s
}
