import { RFV2, RFV4 } from 'src/common/types';

// clipping flags
const CLIP_NONE = 0
const CLIP_LEFT = 1 << 0
const CLIP_BOTTOM = 1 << 1
const CLIP_RIGHT = 1 << 2
const CLIP_TOP = 1 << 3

/**
 * Clip a line within a rectangular region.
 *
 * Note that simply truncating the line does not result
 * in correct behaviour. This implementation is based on
 * the classic Cohen-Sutherland line clipping algorithm.
 *
 * @param p1 the first line endpoint
 * @param p2 the second line endpoint
 * @param rect the clipping region
 * @returns the two endpoints clipped to region or `[null, null]` if the line does not intersect the region
 * @see https://en.wikipedia.org/wiki/Cohen%E2%80%93Sutherland_algorithm
 */
export default function clipLine(
  [x1, y1]: RFV2,
  [x2, y2]: RFV2,
  [left, bottom, right, top]: RFV4,
): [RFV2, RFV2] {
  // clipping flag computation given region extents
  const region = (x: number, y: number): number => {
    let code = CLIP_NONE
    if(y > top) code |= CLIP_TOP
    else if(y < bottom) code |= CLIP_BOTTOM
    if(x > right) code |= CLIP_RIGHT
    else if(x < left) code |= CLIP_LEFT
    return code
  }

  let code1 = region(x1, y1)
  let code2 = region(x2, y2)

  let finished = false;
  let accept = false;

  while(true) {
    if(!(code1 | code2)) {
      // both codes are zero => the line is in bounds
      return [
        [Math.round(x1), Math.round(y1)],
        [Math.round(x2), Math.round(y2)],
      ]
    } if(code1 & code2) {
      // both codes share an outside region => the line is fully outside
      return [null, null]
    }
    // at least one endpoint needs clipping => pick it
    let [x, y] = [0, 0]
    const codeOut = code1 || code2
    // find intersection using formulas:
    //   slope = (y1 - y0) / (x1 - x0)
    //   x = x0 + (1 / slope) * (ym - y0), where ym is ymin or ymax
    //   y = y0 + slope * (xm - x0), where xm is xmin or xmax
    // /!\ note: the outcode bit being tested guarantees that
    // the denominator is non-zero in each case
    if(codeOut & CLIP_TOP) {
      x = x1 + (x2 - x1) * (top - y1) / (y2 - y1)
      y = top
    } else if(codeOut & CLIP_BOTTOM) {
      x = x1 + (x2 - x1) * (bottom - y1) / (y2 - y1)
      y = bottom
    } else if(codeOut & CLIP_RIGHT) {
      y = y1 + (y2 - y1) * (right - x1) / (x2 - x1)
      x = right
    } else if(codeOut & CLIP_LEFT) {
      y = y1 + (y2 - y1) * (left - x1) / (x2 - x1)
      x = left
    }
    // update segment endpoint
    if(codeOut === code1) {
      x1 = x
      y1 = y
      code1 = region(x1, y1)
    } else {
      x2 = x
      y2 = y
      code2 = region(x2, y2)
    }
  }
}
