import TimeNeedleImage, { ReadonlyTimeNeedleImage } from '../time-needle/time-needle-image';
import { LegacyDeserialize, LegacySerialize } from './legacy-buck';

export enum SerializationType {
  BUCK = 0,
}

export function Serialize(
  img: ReadonlyTimeNeedleImage,
  stype: SerializationType = SerializationType.BUCK,
): Uint8Array {
  switch(stype) {
  case SerializationType.BUCK: return LegacySerialize(img)
  }
}

export function Deserialize(
  buffer: Uint8Array,
  issues: string[] = [],
  stype: SerializationType = SerializationType.BUCK,
): TimeNeedleImage {
  switch(stype) {
  case SerializationType.BUCK: return LegacyDeserialize(buffer, issues)
  }
}
