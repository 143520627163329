import { NominalType } from 'src/common/types'

// useful constants
export const DEF_FLOAT4 = [0, 0, 0, 0] as [number, number, number, number]
export const DEF_FLOAT3 = [0, 0, 0] as [number, number, number]
export const DEF_FLOAT2 = [0, 0] as [number, number]
export const DEF_FLOAT1 = [0] as [number]
export const DEF_BLEND_COLOR = new Float32Array([1.0, 1.0, 1.0, 1.0])
export const DEF_SOURCE_MODE = WebGLRenderingContext.SRC_ALPHA
export const DEF_TARGET_MODE = WebGLRenderingContext.ONE_MINUS_SRC_ALPHA

// constrained types
export enum GLDataType {
  DT_NONE = 0,
  DT_FLOAT = WebGL2RenderingContext.FLOAT,
  DT_FLOAT_VEC2 = WebGL2RenderingContext.FLOAT_VEC2,
  DT_FLOAT_VEC3 = WebGL2RenderingContext.FLOAT_VEC3,
  DT_FLOAT_VEC4 = WebGL2RenderingContext.FLOAT_VEC4,
  DT_INT = WebGL2RenderingContext.INT,
  DT_INT_VEC2 = WebGL2RenderingContext.INT_VEC2,
  DT_INT_VEC3 = WebGL2RenderingContext.INT_VEC3,
  DT_INT_VEC4 = WebGL2RenderingContext.INT_VEC4,
  DT_FLOAT_MAT2 = WebGL2RenderingContext.FLOAT_MAT2,
  DT_FLOAT_MAT3 = WebGL2RenderingContext.FLOAT_MAT3,
  DT_FLOAT_MAT4 = WebGL2RenderingContext.FLOAT_MAT4,
  DT_SAMPLER_2D = WebGL2RenderingContext.SAMPLER_2D,
  DT_SAMPLER_CUBE = WebGL2RenderingContext.SAMPLER_CUBE,
}

export function isTextureType(type: GLDataType): boolean {
  return type === GLDataType.DT_SAMPLER_2D || type === GLDataType.DT_SAMPLER_CUBE
}

export function GLDataTypeToString(dt: GLDataType): string {
  switch(dt) {
  case GLDataType.DT_FLOAT: return 'DT_FLOAT';
  case GLDataType.DT_FLOAT_VEC2: return 'DT_FLOAT_VEC2';
  case GLDataType.DT_FLOAT_VEC3: return 'DT_FLOAT_VEC3';
  case GLDataType.DT_FLOAT_VEC4: return 'DT_FLOAT_VEC4';
  case GLDataType.DT_INT: return 'DT_INT';
  case GLDataType.DT_INT_VEC2: return 'DT_INT_VEC2';
  case GLDataType.DT_INT_VEC3: return 'DT_INT_VEC3';
  case GLDataType.DT_INT_VEC4: return 'DT_INT_VEC4';
  case GLDataType.DT_FLOAT_MAT2: return 'DT_FLOAT_MAT2';
  case GLDataType.DT_FLOAT_MAT3: return 'DT_FLOAT_MAT3';
  case GLDataType.DT_FLOAT_MAT4: return 'DT_FLOAT_MAT4';
  case GLDataType.DT_SAMPLER_2D: return 'DT_SAMPLER_2D';
  case GLDataType.DT_SAMPLER_CUBE: return 'DT_SAMPLER_CUBE';
  default: return '';
  }
}

// note: GL types don't have proper signatures so far as they're "opaque" objects
// thus we use nominal typing to enforce better carrying of type information
// note: it needs to be cast initially since this is using a hack for nominal typing
// @see https://github.com/microsoft/TypeScript/issues/5855

// the webgl context type
export type WebGLXRenderingContext = NominalType<WebGLRenderingContext | WebGL2RenderingContext, 'glctx'>
// not: CanvasRenderingContext2D | ImageBitmapRenderingContext

// the webgl texture type
export type WebGLXTexture = NominalType<WebGLTexture, 'gltex'>

// the webgl shader type
export type WebGLXShader = NominalType<WebGLShader, 'glshdr'>

// the webgl buffer type
export type WebGLXBuffer = NominalType<WebGLBuffer, 'glbuff'>

// the webgl program type
export type WebGLXProgram = NominalType<WebGLProgram, 'glprog'>

// the webgl uniform type
export type WebGLXUniformLocation = NominalType<WebGLUniformLocation, 'gluniloc'>

// wrapper over uniform data
export interface Uniform {
  readonly name: string;
  readonly type: GLDataType;
  readonly size: number;
  readonly location: WebGLXUniformLocation;
}

export function makeUniform(name: string, type: GLDataType, size: number, location: WebGLXUniformLocation): Uniform {
  return {
    name,
    type,
    size,
    location,
  }
}

// wrapper over program argument
export interface Argument {
  readonly uniform: Uniform;
  value: any;
  dirty: boolean;
}

export function makeArgument(uniform: Uniform, value: any): Argument {
  return {
    uniform,
    value,
    dirty: true,
  }
}

export interface BlendParameters {
  sourceMode: number
  targetMode: number
  color: Float32Array | [number, number, number, number]
}
