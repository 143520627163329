import { addRowsBelowBottom, deleteRowsBelow } from 'src/data/time-needle/topology'
import {
  BedSide,
  SelectorLike,
  TimeNeedleTransformer,
  YarnIndex,
} from './common'

export interface WasteYarnOptions {
  wasteYarn?: YarnIndex
  drawYarn?: YarnIndex
  bodyYarns?: YarnIndex[]
  leftTrail?: boolean
}

enum YarnCategory {
  WASTE = 0,
  DRAW = 1,
  BODY = 2
}

export function parseWasteOptions(str: string): WasteYarnOptions | string {
  const opts = {} as WasteYarnOptions
  let yarnCat: YarnCategory
  for(const token of str.toLowerCase().split(/[^0-9a-z]/)) {
    if(['1', '2', '3', '4', '5', '6'].includes(token)) {
      switch(yarnCat) {
      case YarnCategory.WASTE:
        opts.wasteYarn = parseInt(token, 10) as YarnIndex
        break
      case YarnCategory.DRAW:
        opts.drawYarn = parseInt(token, 10) as YarnIndex
        break
      case YarnCategory.BODY:
        if(!opts.bodyYarns) {
          opts.bodyYarns = [parseInt(token, 10) as YarnIndex]
        } else {
          opts.bodyYarns.push(parseInt(token, 10) as YarnIndex)
        }
        break
      default:
        return 'Must specify yarn token "waste", "draw" or "body" before yarn'
      }
    } else if(['waste', 'draw', 'body'].includes(token)) {
      yarnCat = YarnCategory[token.toUpperCase()]
    } else if(token === 'local') {
      opts.leftTrail = false
    } else {
      return `Invalid token '${token}'`
    }
  }
  return opts
}

export function isEven(n: number): boolean { return n % 2 === 0 }
export function isZero(n: number): boolean { return n === 0 }
export function isOne(n: number): boolean { return n === 1 }

export function generateWaste(s: SelectorLike, {
  wasteYarn = 6, drawYarn = 1, bodyYarns = [], leftTrail = true,
}: WasteYarnOptions): TimeNeedleTransformer {
  const { left, right } = s.getExtents()
  // console.log('bot', bottom, 'left', left, 'right', right)
  let baseRow = deleteRowsBelow(s)

  // measure necessary waste rows
  const yarnIn = 5 + (leftTrail ? 1 : 0) // insert + 2x back-forth half tubular + drop
  const interlock = 80
  const tubular = 6 + 3 * bodyYarns.length // 3x tubular + #bodyYarns * (1 insert + 1 back-forth tubular)
  const draw = 2 + 3 // draw + insert and spacing of it
  const numRows = yarnIn + interlock + tubular + draw
  // console.log('numRows', numRows)
  let rows: TimeNeedleTransformer
  [baseRow, rows] = addRowsBelowBottom(baseRow, numRows)

  let row = 0
  // 1 = yarn in
  rows.fullCourses([0, yarnIn]).options({
    speed: 100, stitchSize: 4, roller: 440,
  })
  // - yarn insertion
  const leftSide = left % 2 as BedSide
  const leftInvSide = leftSide === 0 ? 1 : 0
  const rightSide = (right - 1) % 2 as BedSide
  const rightInvSide = rightSide === 0 ? 1 : 0
  const mainLeft = leftTrail ? left : left + 1
  const yarnInLeft = leftTrail ? 0 : left + 1
  const yarnInShift = yarnInLeft % 2
  const yarnLeftShift = mainLeft % 2
  rows.fullCourse(row++).wales([yarnInLeft, right]).tileMap(0b01 << yarnInShift, {
    0: (c) => c.frontKnit(wasteYarn),
    1: (c) => c.rearKnit(wasteYarn),
  }, 2)
  // - half-gauge tubular
  rows.fullCourses([row, row + 4]).wales([mainLeft, right]).tileMap(0b01 << yarnLeftShift, {
    0: (c) => (isEven(c.row - row) === isOne(rightSide) ? c.frontKnit(wasteYarn) : c.miss()),
    1: (c) => (isEven(c.row - row) === isZero(rightSide) ? c.rearKnit(wasteYarn) : c.miss()),
  }, 2)
  row += 4
  // - clearing left trail
  if(leftTrail) {
    rows.fullCourse(row++).wales([0, left]).tileMap(0b01, {
      0: (c) => c.miss(),
      1: (c) => c.rearDrop(),
    }, 2)
    // rows.cellAt(row++, mainLeft).frontDrop()
  }

  // 2 = interlock
  const interRows = rows.fullCourses([row, row + interlock]).perRowOptions((r: number) => ({
    speed: (r - row) < 4 ? 100 : (r - row) < 8 ? 200 : 300,
    stitchSize: 4,
    roller: (r - row) % 2 === 0 ? 440 : 0,
  })).wales([left, right]).tileMap(yarnLeftShift === 0 ? 0b1001 : 0b0110, {
    0: (c) => c.frontKnit(wasteYarn),
    1: (c) => c.rearKnit(wasteYarn),
  }, 2)
  // side misses
  interRows.filter((c) => (c.column === left && c.isKnit(leftInvSide)) || (c.column === right - 1 && c.isKnit(rightInvSide))).miss()
  row += interlock

  // 3 = tubular + body insertions
  const insertOpts = {
    speed: 100, roller: 400, stitchSize: 5,
  }
  const rightInvOpts = {
    speed: 200, roller: 200, stitchSize: (rightSide ? [4, 2] as const : [2, 4] as const),
  }
  const rightOpts = {
    speed: 200, roller: 200, stitchSize: (rightSide ? [2, 4] as const : [4, 2] as const),
  }
  for(const [i, yarn] of bodyYarns.entries()) {
    // - insertion
    rows.fullCourse(row++)
      .options(insertOpts)
      .tileMap(yarnLeftShift === (i % 2) ? 0b01 : 0b10, {
        0: (c) => c.frontKnit(yarn),
        1: (c) => c.rearKnit(yarn),
      }, 2)
    // - tubular clearing with draw
    rows.fullCourse(row++)
      .wales([left, right])
      .options(rightInvOpts)
      .knit(rightInvSide, wasteYarn)
    rows.fullCourse(row++)
      .wales([left, right])
      .options(rightOpts)
      .knit(rightSide, wasteYarn)
  }
  // remaining tubular
  rows.fullCourses([row, row + 6, 2])
    .wales([left, right])
    .options(rightInvOpts)
    .knit(rightInvSide, wasteYarn)
  rows.fullCourses([row + 1, row + 6, 2])
    .wales([left, right])
    .options(rightOpts)
    .knit(rightSide, wasteYarn)
  row += 6

  // 4 = draw insertion + tubular sandwich + draw tubular
  const drawOpts = { ...rightOpts, speed: 100}
  const drawInvOpts = { ...rightInvOpts, speed: 100}
  rows.fullCourse(row++)
    .options(insertOpts)
    .tileMap(yarnLeftShift === (bodyYarns.length % 2) ? 0b01 : 0b10, {
      0: (c) => c.frontKnit(drawYarn),
      1: (c) => c.rearKnit(drawYarn),
    }, 2)
  rows.fullCourse(row++)
    .wales([left, right])
    .options(rightInvOpts)
    .knit(rightInvSide, wasteYarn)
  rows.fullCourse(row++)
    .wales([left, right])
    .options(rightOpts)
    .knit(rightSide, wasteYarn)
  rows.fullCourse(row++)
    .wales([left, right])
    .options(drawOpts)
    .knit(rightSide, drawYarn)
  rows.fullCourse(row++)
    .wales([left, right])
    .options(drawInvOpts)
    .knit(rightInvSide, drawYarn)

  return rows // .union(baseRow)
}
