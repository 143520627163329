import {
  useState,
  KeyboardEvent,
  MutableRefObject,
  PointerEvent,
} from 'react'
import classNames from 'classnames'
import { KEYCODE_C, KEYCODE_ESC, KEYCODE_V } from 'src/common/keyboard'
import { makeAABB, RFV4 } from 'src/common/math'
import {
  createExternalCallback,
  createExternalHandler,
  usePointerDownOutside,
  usePointerUpOutside,
} from 'src/hooks'

// interaction mode
export enum SelectionMode {
  DEFAULT = 0,
  SELECTING,
  SELECTED,
  DRAGGING,
  PASTING,
}

function isModeWithSelection(mode: SelectionMode) {
  return mode === SelectionMode.SELECTED || mode === SelectionMode.DRAGGING
}

// cell indexing, 1-indexed like CSS grid-row and grid-column
export type CellIndex = { row: number, column: number }
export type PasteRange = { startColumn: number, endColumn: number, numRows: number }

// dragging mode
export enum DragMode {
  RESIZE = 0,
  OVERWRITE,
}

// constants
const HandleClasses = ['handle-up', 'handle-down']

function* selectionGenerator(selection: RFV4, column = 0, exceptRow = 0) {
  if(!selection) return
  const [left, bottom, right, top] = selection
  if(column) {
    if(!(left <= column && column <= right)) {
      throw `Invalid column: ${column} not within [${left};${right}]`
    }
    for(let row = bottom; row <= top; ++row) {
      if(row === exceptRow) continue
      else yield { row, column }
    }
  } else {
    for(let row = bottom; row <= top; ++row) {
      if(row === exceptRow) continue
      for(let col = left; col <= right; ++col) {
        yield { row, column: col }
      }
    }
  }
}
function selectionGeneratorFunction(selection: RFV4, exceptRow = 0) {
  return (column?: number) => selectionGenerator(selection, column || 0, exceptRow)
}
type SelectionGenerator = ReturnType<typeof selectionGenerator>
type SelectionGeneratorFunction = ReturnType<typeof selectionGeneratorFunction>

function getSelection(start: CellIndex, end: CellIndex, numColumns = 0): [RFV4, boolean] {
  const rowWise = numColumns > 0
  const selectingRows = rowWise && !!start && start.column === 1
  return [
    start && end ? makeAABB(
      selectingRows ? 2 : start.column, // left | right
      start.row, // bottom | top
      selectingRows ? numColumns : end.column, // right | left
      end.row, // top | bottom
    ) : void 0,
    selectingRows,
  ]
}

function storeCell(gridRef: MutableRefObject<HTMLElement>, { row, column }: CellIndex) {
  if(gridRef.current) {
    gridRef.current.dataset.row = row.toString()
    gridRef.current.dataset.column = column.toString()
  }
}

function getLastRow(gridRef: MutableRefObject<HTMLElement>): number {
  return gridRef.current ? parseInt(gridRef.current.dataset.row, 10) : 0
}

function isCellWithinSelection(
  { row, column }: CellIndex,
  [left, bottom, right, top]: RFV4,
): boolean {
  return (
    left <= column && column <= right // within column range
  ) && (
    bottom <= row && row <= top // within row range
  )
}

export function useGridSelection(
  gridRef: MutableRefObject<HTMLElement>,
  numColumns: number,
  cellClass: string,
) {
  // #########################################################################
  // ### local state #########################################################
  // #########################################################################

  const [selectionMode, setSelectionMode] = useState<SelectionMode>(SelectionMode.DEFAULT)
  // - selection state (for the SELECTING, SELECTED and DRAGGING modes)
  const [selectionStart, setSelectionStart] = useState<CellIndex>()
  const [selectionEnd, setSelectionEnd] = useState<CellIndex>()
  // - drag state (for the DRAGGING mode)
  const [dragMode, setDragMode] = useState<DragMode>(DragMode.RESIZE)
  const [dragDirection, setDragDirection] = useState<number>(0)
  const [dragSourceRow, setDragSourceRow] = useState<number>(0)
  const [dragTargetRow, setDragTargetRow] = useState<number>(0)
  // - copy/paste state (for the PASTING mode)
  const [[pasteColumnStart, pasteColumnEnd], setPasteColumnRange] = useState<[number, number]>([0, 0])
  const [pasteSourceRow, setPasteSourceRow] = useState<number>(0)
  const [pasteData, setPasteData] = useState<number[]>([])

  // validate sub-states given state mode
  switch(selectionMode) {
  case SelectionMode.DEFAULT:
    console.assert(
      !selectionStart && !selectionEnd &&
        !dragDirection && !dragSourceRow && !dragTargetRow,
      'Invalid default state',
    )
    break

  case SelectionMode.SELECTING:
  case SelectionMode.SELECTED:
    console.assert(
      selectionStart && selectionEnd &&
        !dragDirection && !dragSourceRow && !dragTargetRow,
      'Invalid selecting state',
    )
    break

  case SelectionMode.DRAGGING:
    console.assert(
      selectionStart && selectionEnd &&
        dragDirection && dragSourceRow && dragTargetRow,
      'Invalid dragging state',
    )
    break

  case SelectionMode.PASTING:
    console.assert(
      !selectionStart && !selectionEnd &&
        !dragDirection && !dragSourceRow && !dragTargetRow &&
        pasteColumnStart && pasteColumnEnd && pasteSourceRow && pasteData.length,
      'Invalid pasting state',
    )
    break
  }

  // state mode transition
  const updateSelectionMode = (newMode: SelectionMode) => {
    if(selectionMode === newMode) return
    switch(newMode) {
    case SelectionMode.DEFAULT:
      setSelectionStart(void 0);
      setSelectionEnd(void 0);
      setDragDirection(0)
      setDragSourceRow(0)
      setDragTargetRow(0)
      setDragMode(DragMode.RESIZE)
      // setPasteColumns(NoPasteColumns)
      // setPasteData([])
      // setPasteSourceRow(0)
      break

    case SelectionMode.SELECTING:
    case SelectionMode.SELECTED:
      setDragDirection(0)
      setDragSourceRow(0)
      setDragTargetRow(0)
      // setPasteColumns(NoPasteColumns)
      // setPasteData([])
      // setPasteSourceRow(0)
      break

    case SelectionMode.DRAGGING:
      // setPasteColumns(NoPasteColumns)
      // setPasteData([])
      // setPasteSourceRow(0)
      break

    case SelectionMode.PASTING:
      setSelectionStart(void 0)
      setSelectionEnd(void 0)
      setDragDirection(0)
      setDragSourceRow(0)
      setDragTargetRow(0)
      break

    default:
      throw `Invalid new selection mode ${newMode}`
    }
    setSelectionMode(newMode)
  }

  // #########################################################################
  // ### state derivatives ###################################################
  // #########################################################################

  // allocate selection event registration mechanism
  const [triggerSelectionReady, setSelectionReadyHandler] = createExternalHandler<SelectionGeneratorFunction>()
  const [triggerSelectionOverwrite, setSelectionOverwriteHandler] = createExternalHandler<SelectionGeneratorFunction>()
  const [triggerSelectionCopy, setSelectionCopyHandler] = createExternalHandler<typeof setPasteData>()
  const [triggerSelectionPaste, setSelectionPasteHandler] = createExternalCallback()

  // deal with left-right and top/bottom flipping cases using AABB selection
  const [selection, selectingRows] = getSelection(selectionStart, selectionEnd, numColumns)
  const [selLeft, selBottom, selRight, selTop] = selection ?? [0, 0, 0, 0]
  const isSelectingCells = selectionMode === SelectionMode.SELECTING && !selectingRows
  const isSelectingRows = selectionMode === SelectionMode.SELECTING && selectingRows

  // drag selection
  const isDragSelecting = selectionMode === SelectionMode.DRAGGING
  const dragSelection = isDragSelecting ? makeAABB(selLeft, dragSourceRow, selRight, dragTargetRow) : void 0
  const [dragLeft, dragBottom, dragRight, dragTop] = dragSelection ?? [0, 0, 0, 0]
  const [dragDataStartRow, dragDataEndRow] = !selection || dragDirection === 0 ? [
    0, 0,
  ] : dragDirection > 0 ? [
    selBottom, selTop, // going upward
  ] : [
    selTop, selBottom, // going downward
  ]

  // paste selection
  const pasteWidth = pasteColumnStart && pasteColumnEnd ? pasteColumnEnd - pasteColumnStart + 1 : 0
  const pasteHeight = pasteWidth && pasteData.length ? pasteData.length / pasteWidth : 0
  console.assert(pasteData.length === pasteWidth * pasteHeight, 'Invalid paste data')
  const pasteSelection = selectionMode === SelectionMode.PASTING ? makeAABB(
    pasteColumnStart,
    Math.max(1, pasteSourceRow - pasteHeight + 1),
    pasteColumnEnd,
    pasteSourceRow,
  ) : void 0
  const [pasteLeft, pasteBottom, pasteRight, pasteTop] = pasteSelection ?? [0, 0, 0, 0]

  // #########################################################################
  // ### non-local state updates #############################################
  // #########################################################################

  // de-selection
  usePointerDownOutside(gridRef, () => {
    updateSelectionMode(SelectionMode.DEFAULT)
  }, [selectionMode])

  // edge-case for end of selection
  usePointerUpOutside(gridRef, () => {
    switch(selectionMode) {
    case SelectionMode.DRAGGING:
      // cancel drag, reset to basic selection
      updateSelectionMode(SelectionMode.SELECTED)
      break

    case SelectionMode.SELECTING:
      // stop selecting, selection is now done
      updateSelectionMode(SelectionMode.SELECTED)
      // reset to default drag mode
      setDragMode(DragMode.RESIZE)
      // trigger selection ready handler
      triggerSelectionReady(selectionGeneratorFunction(selection))
      break
    }
  }, [
    selectionMode,
    selectionStart, selectionEnd, // both due to usage of selection
  ])

  // #########################################################################
  // ### event handlers ######################################################
  // #########################################################################

  const onKeyDown = (e: KeyboardEvent) => {
    const key = e.keyCode
    const ctrlPressed = e.ctrlKey || e.metaKey;
    if(ctrlPressed && key === KEYCODE_C) { // --------------------------------
      // potential copy action (if any selection ready)
      if(isModeWithSelection(selectionMode)) {
        // store column range information (because data is typed)
        // XXX can't we use the selection directly?
        const [[left, , right]] = getSelection(selectionStart, selectionEnd, numColumns)
        setPasteColumnRange([left, right])
        // trigger selection copy
        triggerSelectionCopy(setPasteData)

        // prevent any copy to happen in the selection input
        e.preventDefault()
      }
    } else if(ctrlPressed && key === KEYCODE_V && pasteData.length) { // -----
      // if copy exists, then create paste preview
      // note: only apply paste upon click in options column while paste preview is valid
      setPasteSourceRow(getLastRow(gridRef))
      updateSelectionMode(SelectionMode.PASTING)

      // prevent any pasting to happen in the selection input
      e.preventDefault()
      if(gridRef.current) gridRef.current.focus()
      //
    } else if(key === KEYCODE_ESC) { // --------------------------------------
      // stop any selection mode
      updateSelectionMode(SelectionMode.DEFAULT)
    }
  }

  // getting cell index from pointer target
  // /!\ first column can act as full-row selector
  const getSelectionTarget = (e:PointerEvent, allowFirstColumn = true): CellIndex => {
    const target = e.target as HTMLElement
    // this should happen on a dedicated cell class only!
    if(!target.classList.contains(cellClass)) return void 0; // throw 'Invalid selection target'
    // get row/col information from associated data of cell
    const row = parseInt(target.dataset.row, 10)
    const column = Math.max(allowFirstColumn ? 1 : 2, parseInt(target.dataset.column, 10))
    return { row, column }
  }

  // #########################################################################

  const onPointerDown = (e:PointerEvent) => {
    e.preventDefault() // prevent browser selection

    // check target type
    const target = e.target as Element
    const handleIdx = HandleClasses.findIndex((className) => target.classList.contains(className))
    // -----------------------------------------------------------------------
    if(isModeWithSelection(selectionMode) && handleIdx !== -1) { // ----------
      // start dragging mode with the given direction
      const handleDir = handleIdx * 2 - 1 // remap from [0, 1] to [-1, +1]
      setDragDirection(handleDir)
      // update drag source and target
      if(dragMode === DragMode.RESIZE) {
        // note: (selectionEnd.row - selectionStart.row) is the natural direction of the selection
        //       if it matches the handle, then we go from start to end, else we go the other way!
        const [srcRow, trgRow] = (selectionEnd.row - selectionStart.row) * handleDir >= 0 ? [
          selectionStart.row, selectionEnd.row,
        ] : [
          selectionEnd.row, selectionStart.row,
        ]
        setDragSourceRow(srcRow)
        setDragTargetRow(trgRow)
      } else {
        // note: overwrite should never reduce the range
        // => pick the end point as both source and target
        const baseRow = (selectionEnd.row - selectionStart.row) * handleDir >= 0 ? selectionEnd.row : selectionStart.row
        setDragSourceRow(baseRow)
        setDragTargetRow(baseRow)
      }
      updateSelectionMode(SelectionMode.DRAGGING)
      //
    } else if(selectionMode !== SelectionMode.PASTING) { // ------------------
      // restart selection
      const sel = getSelectionTarget(e)
      if(sel) {
        setSelectionStart(sel)
        setSelectionEnd(sel)
        updateSelectionMode(SelectionMode.SELECTING)
      } else {
        updateSelectionMode(SelectionMode.DEFAULT)
      }

      e.stopPropagation()
    }
  }

  // #########################################################################

  const onPointerUp = (e:PointerEvent) => {
    if(selectionMode === SelectionMode.SELECTING) { // -----------------------
      setDragMode(DragMode.RESIZE)

      // trigger selection ready handler
      triggerSelectionReady(selectionGeneratorFunction(selection))
      updateSelectionMode(SelectionMode.SELECTED)
      //
    } else if(selectionMode === SelectionMode.DRAGGING) { // -----------------
      console.assert(!!dragDirection, 'Invalid drag direction')

      // did we release on the handle?
      const handleIdx = (dragDirection + 1) / 2 // remap from [-1, +1] to [0, 1]
      const handleClass = HandleClasses[handleIdx]
      if((e.target as HTMLElement).classList.contains(handleClass)) {
        // special case: toggle drag mode
        setDragMode(1 - dragMode)
        //
      } else if(dragMode === DragMode.RESIZE) {
        // resize selection to match drag selection
        const newSelectionStart = { row: dragSourceRow, column: selectionStart.column }
        const newSelectionEnd = { row: dragTargetRow, column: selectionEnd.column }
        setSelectionStart(newSelectionStart)
        setSelectionEnd(newSelectionEnd)

        // trigger selection ready handler
        const [newSelection] = getSelection(newSelectionStart, newSelectionEnd)
        triggerSelectionReady(selectionGeneratorFunction(newSelection))
        //
      } else if(dragMode === DragMode.OVERWRITE) {
        // apply overwrite over drag selection
        triggerSelectionOverwrite(selectionGeneratorFunction(dragSelection, dragSourceRow))

        // rewrite selection to include any newly covered rows
        const newSelectionStart = {
          row: dragSourceRow === selectionStart.row ? selectionEnd.row : selectionStart.row, // the non-source selection row
          column: selectionStart.column,
        }
        const newSelectionEnd = { row: dragTargetRow, column: selectionEnd.column }
        setSelectionStart(newSelectionStart)
        setSelectionEnd(newSelectionEnd)

        // trigger selection ready handler
        const [newSelection] = getSelection(newSelectionStart, newSelectionEnd)
        triggerSelectionReady(selectionGeneratorFunction(newSelection))
      }

      // reset to selected state without dragging
      updateSelectionMode(SelectionMode.SELECTED)
      //
    } else if(selectionMode === SelectionMode.PASTING) { // ------------------
      // trigger actual pasting, only if over a cell
      // XXX could ${pasteSelection} not match the cell as its source row?
      const sel = getSelectionTarget(e)
      if(sel) {
        console.assert(
          sel.row === pasteSourceRow,
          `Invalid paste source row: expected ${pasteSourceRow}, but got ${sel.row}`,
        )
        triggerSelectionPaste()

        // go back to no selection nor pasting (but data is still available for pasting again)
        updateSelectionMode(SelectionMode.DEFAULT)

        // bring focus explicitly on grid element
        if(gridRef.current) gridRef.current.focus()
      }
    }
  }

  // #########################################################################

  const onPointerMove = (e:PointerEvent) => {
    // we need a target location
    const sel = getSelectionTarget(e, false)
    if(!sel) return // nothing can be done further

    // store selection target on grid
    storeCell(gridRef, sel)

    // only update selection / drag state if we have started a selection and we are dragging
    const isDragging = e.buttons !== 0
    // -----------------------------------------------------------------------
    if(selectionMode === SelectionMode.SELECTING && isDragging) { // ---------
      // we are creating the selection
      setSelectionEnd(sel)
    } else if(selectionMode === SelectionMode.DRAGGING && isDragging) { // ---
      // we are dragging a selection handle
      const { row } = sel;
      setDragTargetRow(dragDirection > 0 ?
        Math.max(dragSourceRow, row) :
        Math.min(dragSourceRow, row))
    } else if(selectionMode === SelectionMode.PASTING) { // ------------------
      // we are previewing a paste block => update source row
      setPasteSourceRow(sel.row)
    }
  }

  // #########################################################################
  // ### UI components #######################################################
  // #########################################################################

  // generate selection components for CSS grid as end cells that overlap
  const lastColSelected = !!selection && selRight === numColumns
  const selectionComponents = (
    <>
      <div
        className={classNames('selection-below', {
          empty: !selection,
          'to-last': lastColSelected,
          'drag-resize': dragMode === DragMode.RESIZE,
          'drag-overwrite': dragMode === DragMode.OVERWRITE,
        })}
        style={{
          // note: AABB uses Y axis from bottom to top whereas CSS goes the opposite way
          gridRow: selection ? `${selBottom} / ${selTop + 1}` : 'auto',
          gridColumn: selection ? `${selLeft} / ${selRight + 1}` : 'auto',
        }}
      />
      <div
        className={classNames('selection-above', {
          empty: !selection,
          'to-last': lastColSelected,
          'drag-resize': dragMode === DragMode.RESIZE,
          'drag-overwrite': dragMode === DragMode.OVERWRITE,
        })}
        style={{
          // note: AABB uses Y axis from bottom to top whereas CSS goes the opposite way
          gridRow: selection ? `${selBottom} / ${selTop + 1}` : 'auto',
          gridColumn: selection ? `${selLeft} / ${selRight + 1}` : 'auto',
        }}
      >
        <div className={classNames('handle-wrapper', { ready: isModeWithSelection(selectionMode) })}>
          <div className="handle-up">
            <div className="handle" />
          </div>
          <div className="handle-down">
            <div className="handle" />
          </div>
        </div>
      </div>
      <div
        className={classNames('selection-drag', {
          empty: !dragSelection,
          'to-last': lastColSelected,
          'drag-resize': dragMode === DragMode.RESIZE,
          'drag-overwrite': dragMode === DragMode.OVERWRITE,
        })}
        style={{
          gridRow: dragSelection ? `${dragBottom} / ${dragTop + 1}` : 'auto',
          gridColumn: dragSelection ? `${dragLeft} / ${dragRight + 1}` : 'auto',
        }}
      />
      <div
        className={classNames('selection-paste-below', {
          empty: !pasteSelection,
          'to-last': pasteSelection && pasteRight === numColumns,
        })}
        style={{
          gridRow: pasteSelection ? `${pasteBottom} / ${pasteTop + 1}` : 'auto',
          gridColumn: pasteSelection ? `${pasteLeft} / ${pasteRight + 1}` : 'auto',
        }}
      />
      <div
        className={classNames('selection-paste-above', {
          empty: !pasteSelection,
          'to-last': pasteSelection && pasteRight === numColumns,
        })}
        style={{
          gridRow: pasteSelection ? `${pasteBottom} / ${pasteTop + 1}` : 'auto',
          gridColumn: pasteSelection ? `${pasteLeft} / ${pasteRight + 1}` : 'auto',
        }}
      />
    </>
  )

  // #########################################################################
  // ### exposed data ########################################################
  // #########################################################################

  return {
    // pointer events
    onKeyDown,
    onPointerDown,
    onPointerMove,
    onPointerUp,

    // selecting states
    isSelectingCells,
    isSelectingRows,
    isSelectionReady: isModeWithSelection(selectionMode),
    selectedColumns: selection ? Array.from({ length: numColumns }, (_, i) => i + 1).flatMap((i) => (selLeft <= i && i <= selRight ? [i] : [])) : [],
    isDragSelecting,
    dragDataStartRow,
    dragDataEndRow,
    isPasting: selectionMode === SelectionMode.PASTING,
    pasteColumnStart,
    pasteColumnEnd,
    pasteSourceRow,
    pasteHeight,
    pasteWidth,
    pasteData(idx: number): [number, boolean] {
      return idx >= 0 && idx < pasteData.length ? [pasteData[idx], true] : [-1, false]
    },

    // state methods
    isSelected(cell: CellIndex): boolean {
      return selection && isCellWithinSelection(cell, selection)
    },
    isDragOverwritten(cell: CellIndex): boolean {
      return (
        !!dragSelection && dragMode === DragMode.OVERWRITE &&
        isCellWithinSelection(cell, dragSelection) && cell.row !== dragSourceRow // not the source row
      )
    },
    isPasted(cell: CellIndex): boolean {
      return !!pasteSelection && isCellWithinSelection(cell, pasteSelection)
    },

    // iterators
    selection: selectionGeneratorFunction(selection),
    dragOverwrites: selectionGeneratorFunction(dragSelection, dragSourceRow),
    pasteSelection: selectionGeneratorFunction(pasteSelection),

    // event handler registers
    setSelectionReadyHandler,
    setSelectionOverwriteHandler,
    setSelectionCopyHandler,
    setSelectionPasteHandler,

    // selection components
    selectionComponents,
  }
}
