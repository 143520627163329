import { EqualityFn, useDispatch, useSelector } from 'react-redux'
import type { AppDispatch } from 'src/store'
import type { RootStateWithUndoRedo } from 'src/undo'
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type DispatchArgType = Parameters<AppDispatch>[0]

export interface TypedUseSelectorHook<TState> {
  <TSelected>(selector: (state: TState) => TSelected, equalityFn?: EqualityFn<TSelected> | EqualityFn<any>): TSelected;
}

export const useAppSelector: TypedUseSelectorHook<RootStateWithUndoRedo> = useSelector
