import { F4, RFV2, RFV4 } from 'src/common/types';

export const EPSILON = 0.000001;

export function clamp(value: number, min: number, max: number): number {
  return value < min ? min : value > max ? max : value;
}

// Compare floating point numbers using eq and not ===
export function eq(A: number, B: number, epsilon: number = EPSILON): boolean {
  return Math.abs(B - A) < epsilon;
}

export function lerp(A: number, B: number, t: number): number {
  return (1.0 - t) * A + t * B;
}

export function randomInt(min = -1.0, max = +1.0): number {
  return Math.round(lerp(min, max, Math.random()));
}

export function randomFloat(min = -1.0, max = +1.0): number {
  return lerp(min, max, Math.random());
}

export function nextPowerOf2(value: number): number {
  return 2 ** Math.ceil(Math.log(value) / Math.log(2));
}

export function clampMouse(x: number, y: number, rect: DOMRect): [number, number] | null {
  x = Math.floor(x - rect.left);
  y = Math.floor(y - rect.top);
  return x >= 0 && x <= rect.width && y >= 0 && y <= rect.height ? [x, y] : null;
}

/**
 * Build an AABB short vector from two points.
 * The result is `[left, bottom, right, top]` such that
 * ```
 * left <= right && bottom <= top
 * ```
 *
 * @param p1 the first point to include in the rectangle
 * @param p2 the second point to include in the rectangle
 * @returns an AABB vector `[left, bottom, right, top]`
 */
export function buildAABB([x1, y1]: RFV2, [x2, y2]: RFV2): F4 {
  if(x1 > x2) [x1, x2] = [x2, x1]
  if(y1 > y2) [y1, y2] = [y2, y1]
  return [x1, y1, x2, y2]
}

/**
 * Create an AABB short vector from a sequence of coordinates.
 * The matching dimensions can be out of order:
 * - `x1` and `x2` may be swapped
 * - `y1` and `y2` may be swapped
 *
 * @param x1 the first x coordinate
 * @param y1 the first y coordinate
 * @param x2 the second x coordinate
 * @param y2 the second y coordinate
 * @returns an AABB vector `[left, bottom, right, top]` such that `left <= right` and `bottom <= top`
 */
export function makeAABB(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
): F4 {
  if(x1 > x2) [x1, x2] = [x2, x1]
  if(y1 > y2) [y1, y2] = [y2, y1]
  return [x1, y1, x2, y2]
}

/**
 * Compute the intersection between two rectangles,
 * which may be empty.
 *
 * @param rectA one rectangle
 * @param rectB another rectangle
 * @returns their intersection as a rectangle or `null` if empty
 */
export function intersectRects(
  [leftA, bottomA, rightA, topA]: RFV4,
  [leftB, bottomB, rightB, topB]: RFV4,
): F4 {
  const left = Math.max(leftA, leftB)
  const right = Math.min(rightA, rightB)
  const bottom = Math.max(bottomA, bottomB)
  const top = Math.min(topA, topB)
  if(left < right && bottom < top) {
    return [left, bottom, right, top]
  }
  return null // no intersection left
}

/**
 * Check whether a rectangle (`rectA`)
 * is fully contained within another (`rectB`).
 *
 * @param rectA the rectangle to check for containment
 * @param rectB the rectangle to check as container
 * @returns
 */
export function isRectWithinRect(
  [leftA, bottomA, rightA, topA]: RFV4,
  [leftB, bottomB, rightB, topB]: RFV4,
): boolean {
  return (
    leftA >= leftB &&
    rightA <= rightB &&
    bottomA >= bottomB &&
    topA <= topB
  )
}
