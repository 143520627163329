import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  defaultTransform, makeProjectionMatrix, resetOffset, resetZoomData, RFV2, RFV3, RFV4, ZoomData,
} from 'src/common/math'
import type { LocalTextureFunc, TextureData } from 'src/common/webgl/texture'
import { Deserialize } from 'src/data/serialization'
import type { Design } from 'src/api'
import type { ReadonlyTimeNeedleImage } from 'src/data/time-needle/time-needle-image'
import { StitchCode } from 'src/data/time-needle/stitch-code'
import { ViewMode } from 'src/data/time-needle/view-mode'
import {
  ActionContextFunction,
  ActionHandler,
  ActionTool,
  createHandler,
  defaultHandler,
} from './action'
import { CursorMode } from './canvas'

export { CursorMode }

export interface AxisConfig {
  initOffset: number
  numCells: number
  cellSize: number
  cellPos: number[]
}

export const NumCellMargin = 3

const initialState = {
  // data
  tnimage: null as ReadonlyTimeNeedleImage,
  width: 0,
  height: 0,

  // view state
  zoomData: {
    minZoom: Math.log(1.0),
    maxZoom: Math.log(10.0),
    zoomSteps: 50,
    zoomLevel: 38,
  } as ZoomData,
  offset: [0, 0] as RFV2,
  zoom: 1.0,

  // projection
  transform: defaultTransform(5 / 6),
  M: makeProjectionMatrix(defaultTransform(5 / 6)),

  // editor state
  mouseCoord: [0, 0, 0, 0] as RFV4,
  selection: [0, 0, 0, 0] as RFV4,
  selectMode: CursorMode.DEFAULT,
  configData: [1, -1, -1, 0] as RFV4,
  errorData: [-1, -1, -1, -1] as RFV4,
  markerData: [0, 0, 0] as RFV3,

  // texture data
  pasteData: null as TextureData,
  previewData: [ViewMode.PATTERN, null] as [ViewMode, LocalTextureFunc],

  // ui selection
  activeView: ViewMode.PATTERN,
  activeStitch: StitchCode.FRNT_KNIT,
  enabled: true,
  isEditing: false,

  // axis config
  axisConfig: [
    {
      initOffset: 0, numCells: 0, cellSize: 1, cellPos: [],
    },
    {
      initOffset: 0, numCells: 0, cellSize: 1, cellPos: [],
    },
  ] as readonly [AxisConfig, AxisConfig],

  // legacy
  // palette: new Palette(defaultPalette),
  thumbnails: [null, null, null] as readonly string[],

  // action handler
  action: defaultHandler() as ActionHandler,

  // deferred actions for main editor
  deferredActions: new Array<ActionContextFunction>(),
}

export type CanvasState = typeof initialState
export type CanvasStateEntry = {
  [K in keyof CanvasState]: [K, CanvasState[K]]
}[keyof CanvasState]

const canvasSlice = createSlice({
  name: 'canvas',
  initialState,
  reducers: {
    // generic setter
    setCanvasState(
      state,
      { payload: [key, val] }: PayloadAction<CanvasStateEntry>,
    ) {
      return {
        ...state,
        [key]: val,
      }
    },
    setActionHandler(state, { payload }: PayloadAction<ActionHandler | ActionTool>) {
      return {
        ...state,
        action: (
          typeof payload === 'number' ?
            createHandler(payload) :
            payload
        ),
      }
    },
    setOffset(state, { payload: offset }: PayloadAction<RFV2>) {
      const [transform, M] = resetOffset(state.transform, state.M, offset)
      return {
        ...state,
        offset,
        transform,
        M,
      }
    },
    setTimeNeedleImage(state, { payload: tnimage }: PayloadAction<ReadonlyTimeNeedleImage>) {
      // update zoom data
      const zoomData = resetZoomData(
        state.zoomData,
        tnimage.cdata.height,
      )
      return {
        ...state,
        tnimage,
        width: tnimage.cdata.width,
        height: tnimage.cdata.height,
        zoomData,
      }
    },
    loadTimeNeedleImageData(state, { payload }: PayloadAction<Uint8Array>) {
      const tnimage = Deserialize(payload)
      // update zoom data
      const zoomData = resetZoomData(
        state.zoomData,
        tnimage.cdata.height,
      )
      return {
        ...state,
        tnimage,
        width: tnimage.cdata.width,
        height: tnimage.cdata.height,
        zoomData,
      }
    },
    loadDesign(state, { payload }: PayloadAction<Design>) {
      // const palette = new Palette(d.colors.map((v) => v.colorU8));
      const tnimage = Deserialize(payload.buck)
      // update zoom data
      const zoomData = resetZoomData(
        state.zoomData,
        tnimage.cdata.height,
      )
      return {
        ...state,
        tnimage,
        width: tnimage.cdata.width,
        height: tnimage.cdata.height,
        zoomData,
      }
    },
    setActiveViewMode(state, { payload }: PayloadAction<ViewMode>) {
      return {
        ...state,
        activeView: payload,
      }
    },
    setActiveStitch(state, { payload }: PayloadAction<StitchCode>) {
      return {
        ...state,
        activeStitch: payload,
      }
    },
    deferAction(state, { payload }: PayloadAction<ActionContextFunction>) {
      return {
        ...state,
        deferredActions: [...state.deferredActions, payload],
      }
    },
    deferActions(state, { payload }: PayloadAction<ActionContextFunction[]>) {
      return {
        ...state,
        deferredActions: [...state.deferredActions, ...payload],
      }
    },
    clearDeferredActions(state, { payload }: PayloadAction<number>) {
      return {
        ...state,
        deferredActions: payload ? state.deferredActions.slice(payload) : [],
      }
    },
    setSelection(state, { payload }: PayloadAction<RFV4>) {
      return {
        ...state,
        selection: payload,
        selectMode: CursorMode.SELECTING,
      }
    },
    clearSelection(state) {
      return {
        ...state,
        selection: [0, 0, 0, 0],
        selectMode: CursorMode.DEFAULT,
      }
    },
    setMarker(state, { payload: [x, y] }: PayloadAction<RFV2>) {
      return {
        ...state,
        markerData: [1, x, y],
      }
    },
    unsetMarker(state) {
      return {
        ...state,
        markerData: [0, 0, 0],
      }
    },
    toggleMarker(state, { payload: [x, y] }: PayloadAction<RFV2>) {
      return {
        ...state,
        markerData: state.markerData[0] ? [0, x, y] : [1, x, y],
      }
    },
    enableCanvas(state) {
      return {
        ...state,
        enabled: true,
      }
    },
    disableCanvas(state) {
      return {
        ...state,
        enabled: false,
      }
    },
    setAxisConfig(state, { payload }: PayloadAction<[AxisConfig, AxisConfig]>) {
      return {
        ...state,
        axisConfig: payload,
      }
    },
    setEditing(state, { payload }: PayloadAction<boolean>) {
      return {
        ...state,
        isEditing: payload,
      }
    },
    setErrorCell(state, { payload }: PayloadAction<RFV2>) {
      return {
        ...state,
        errorData: [
          payload[0], payload[1],
          state.errorData[2], state.errorData[3],
        ],
      }
    },
    setErrorData(state, { payload }: PayloadAction<RFV4>) {
      return {
        ...state,
        errorData: payload,
      }
    },
    setRelatedCell(state, { payload }: PayloadAction<RFV2>) {
      return {
        ...state,
        errorData: [
          state.errorData[0], state.errorData[1],
          payload[0], payload[1],
        ],
      }
    },
    setThumbnails(state, { payload }: PayloadAction<string[]>) {
      return {
        ...state,
        thumbnails: state.thumbnails.map((t, i) => payload[i] ?? t),
      }
    },
  },
})

export function getDefaultState<K extends keyof CanvasState>(key: K) {
  const initVal = initialState[key]
  if(Array.isArray(initVal)) {
    return initVal.slice() as CanvasState[K]
  }
  return initVal
}

export const CanvasKeys = Object.keys(initialState) as Array<keyof CanvasState>

export const {
  clearDeferredActions,
  clearSelection,
  deferAction,
  deferActions,
  disableCanvas,
  enableCanvas,
  loadDesign,
  loadTimeNeedleImageData,
  setActionHandler,
  setActiveViewMode,
  setActiveStitch,
  setAxisConfig,
  setCanvasState,
  setEditing,
  setErrorCell,
  setErrorData,
  setMarker,
  setOffset,
  setTimeNeedleImage,
  setRelatedCell,
  setSelection,
  setThumbnails,
  toggleMarker,
  unsetMarker,
} = canvasSlice.actions

const reducer = canvasSlice.reducer
export default reducer
