import {
  useRef, useLayoutEffect, useState, ReactChild,
} from 'react'
import classnames from 'classnames'

import 'src/styles/ribbon.scss'

const MIN_TOP_GAP = 15;
const MIN_BOTTOM_GAP = 15;
const MIN_NEXT_BOTTOM_GAP = 15;
const ROW_GAP = 5;

type RibbonChild = ReactChild & {
  props: { title?: string }
}

interface RibbonProps {
  children: RibbonChild[]
}

function Ribbon({ children = [] }: RibbonProps) {
  const titles = children.map(({ props: { title }}, index) => title ?? index.toString()) as string[]
  const [isActive, setIsActive] = useState<readonly boolean[]>(titles.map(() => false))
  const ribbonContent = useRef<HTMLDivElement>()
  const ribbonIcons = useRef<HTMLDivElement>()
  const layout = () => {
    const { children: items } = ribbonContent.current
    const { children: icons } = ribbonIcons.current
    for(const [index, itemIsActive] of isActive.entries()) {
      const item = items[index] as HTMLDivElement
      const icon = icons[index] as HTMLDivElement
      item.style.marginTop = ''
      icon.style.marginTop = ''
      if(itemIsActive) {
        const contentRect = item.getBoundingClientRect()
        const iconRect = icon.getBoundingClientRect()

        if(contentRect.top > iconRect.top - MIN_TOP_GAP) {
          icon.style.marginTop = `${contentRect.top - iconRect.top + MIN_TOP_GAP}px`
        }

        if(contentRect.bottom < iconRect.bottom + MIN_BOTTOM_GAP) {
          item.style.marginTop = `${MIN_BOTTOM_GAP + iconRect.bottom - contentRect.bottom}px`
        }
      } else {
        item.style.marginTop = ''
        icon.style.marginTop = ''
        if(index > 0) {
          const prevRect = items[index - 1].getBoundingClientRect()
          const iconRect = icon.getBoundingClientRect()
          if(iconRect.top < prevRect.bottom) {
            icon.style.marginTop = `${MIN_NEXT_BOTTOM_GAP + ROW_GAP + prevRect.bottom - iconRect.top}px`
          }
        }
      }
    }
  }

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(layout)
    if(ribbonContent.current) {
      const { children: items } = ribbonContent.current
      for(const item of items) {
        resizeObserver.observe(item)
      }
      layout()
    }
    return () => resizeObserver.disconnect()
  }, [ribbonContent.current, isActive])

  return (
    <aside className="ribbon">
      <div className="ribbon-content" ref={ribbonContent}>
        {children.map((item, index) => (
          <div
            key={index}
            className={classnames({ item: true, active: isActive[index] })}
          >
            {isActive[index] && item}
          </div>
        ))}
      </div>
      <div className="ribbon-icons" ref={ribbonIcons}>
        {titles.map((title, index) => (
          <div
            key={index}
            onClick={() => {
              setIsActive(isActive.map((active, i) => (i === index ? !active : active)))
            }}
            className={classnames({ icon: true, [title]: true, active: isActive[index] })}
          >
            {title}
          </div>
        ))}
      </div>
    </aside>
  )
}

export default Ribbon

export { default as Actions } from './actions'
export { default as Checks } from './checks'
export { default as Layers } from './layers'
export { default as Scripts } from './scripts'
export { default as Settings } from './settings'
