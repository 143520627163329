import type {
  BedSide,
  RowOptions,
  UserDirection,
  YarnIndex,
} from 'src/data/time-needle/options'
import { TimeNeedleCellReader } from 'src/data/time-needle/cell'
import { SelectorLike } from 'src/data/time-needle/transformer'

// re-export types
export type {
  BedSide,
  BedSideOrBoth,
  BedSideOrNone,
  BedSidesOrNone,
  RowOptions,
  UserDirection,
  YarnIndex,
  YarnIndexOrNone,
} from 'src/data/time-needle/options'
export {
  isYarnIndex,
  isYarnIndexOrNone,
} from 'src/data/time-needle/options'
export {
  default as TimeNeedleSelector,
} from 'src/data/time-needle/selector'
export {
  default as TimeNeedleTransformer,
  type SelectorLike,
  TimeNeedleCellReader,
  TimeNeedleCellWriter,
} from 'src/data/time-needle/transformer'

export enum KnittingSide {
  NONE = -1,
  FRONT = 0,
  REAR = 1,
  BOTH = 2,
}

export function getCellKnittingSide(c: TimeNeedleCellReader): KnittingSide {
  if(c.isKnit(2)) { return KnittingSide.BOTH }
  if(c.isKnit(1)) { return KnittingSide.REAR }
  if(c.isKnit(0)) { return KnittingSide.FRONT }
  return KnittingSide.NONE
}

export function getKnittingSides(row: SelectorLike, dir: 'above' | 'below', lookAhead = 1): KnittingSide[] {
  return row.remap((c: TimeNeedleCellReader) => {
    for(let i = 0; i <= lookAhead && c; ++i) {
      const side = getCellKnittingSide(c)
      if(side !== KnittingSide.NONE) {
        return side
      }
      c = dir === 'above' ? c.up() : c.down()
    }
    return KnittingSide.NONE
  })
}

export function resolveMargin(margin: number[] | number | undefined, def: [number, number], side: BedSide): number {
  if(Array.isArray(margin)) {
    return side < margin.length ? margin[side] : margin[margin.length - 1]
  } if(typeof margin === 'number') {
    return margin
  }
  return def[side]
}

export type PassOptions = RowOptions | RowOptions[] | ((idx: number, len: number) => RowOptions)

export function otherDir(dir: Exclude<UserDirection, 2>): Exclude<UserDirection, 2> {
  return dir === 0 ? 1 : 0
}

export function resolveOpts(opts: PassOptions, i: number, n: number): RowOptions {
  if(Array.isArray(opts)) {
    const lastOpts = opts[opts.length - 1]
    return opts[i] || lastOpts
  } if(typeof opts === 'function') {
    return opts(i, n)
  }
  return opts
}

export function getOpts(i: number, n: number, opts: PassOptions, def: PassOptions): RowOptions {
  return { ...resolveOpts(def, i, n), ...resolveOpts(opts, i, n)}
}

export enum ShapingSide {
  LEFT_TO_RIGHT = 0,
  LEFT = 0,
  RIGHT_TO_LEFT = 1,
  RIGHT = 1,
  BOTH_SIDES = 2,
  BOTH = 2,
}

export type SingleShapingSide = Exclude<ShapingSide, ShapingSide.BOTH_SIDES>

export function parseShapingSide(side: string): ShapingSide | undefined {
  switch(side.toLowerCase().trim()) {
  case 'left': return ShapingSide.LEFT_TO_RIGHT
  case 'right': return ShapingSide.RIGHT_TO_LEFT
  case 'both': return ShapingSide.BOTH_SIDES
  default: return void 0
  }
}

export type KeywordOption = string
export type ArgumentOption = [string, string]
export type ParsedOption = KeywordOption | ArgumentOption

export function parseUserArguments(
  input: string,
  keywords: Set<string> | string[],
  contexts: Set<string> | string[],
  initialContext?: string,
): ParsedOption[] | string {
  if(Array.isArray(keywords)) {
    keywords = new Set(keywords)
  }
  if(Array.isArray(contexts)) {
    contexts = new Set(contexts)
  }
  const res = new Array<ParsedOption>()
  const tokens = input.toLowerCase().split(/[ ,]+/).filter((token) => token.length > 0)
  let context = initialContext
  for(const token of tokens) {
    const isKeyword = keywords.has(token)
    const isContext = contexts.has(token)
    if(isKeyword && isContext) {
      res.push(token)
      context = token
    } else if(isKeyword) {
      res.push(token)
    } else if(isContext) {
      context = token
    } else if(context === undefined) {
      // neither a context nor a keyword, and no context yet
      return `Invalid token ${token} without context`
    } else {
      // neither a context nor a keyword, but with a context already
      // => this is an argument for that context
      res.push([context, token])
    }
  }
  return res
}

export function parseUserOptions(
  input: string,
  keywordMap: { [keyword: string]: () => void | string },
  contextMap: { [context: string]: (token: string) => void | string },
  initContext?: string,
): undefined | string {
  const args = parseUserArguments(input, Object.keys(keywordMap), Object.keys(contextMap), initContext)
  if(typeof args === 'string') {
    return args
  }
  for(const parsed of args) {
    if(typeof parsed === 'string') {
      const res = keywordMap[parsed]()
      if(res) { return res }
    } else if(Array.isArray(parsed) && parsed.length === 2) {
      const [ctx, arg] = parsed
      const res = contextMap[ctx](arg)
      if(res) { return res }
    }
  }
  return undefined
}

const KNIT = (1 << 0)
const TUCK = (1 << 1)
const SPLIT = (1 << 2)
const XFER = (1 << 3)
const DROP = (1 << 4)
const XMISS = (1 << 5)

interface SelectionType {
  hasKnit: boolean
  hasTuck: boolean
  hasSplit: boolean
  hasTransfer: boolean
  hasDrop: boolean
  hasExplicitMiss: boolean
}

export function getSelectionType(s: SelectorLike): SelectionType {
  const data = s.reduce((data: number, c: TimeNeedleCellReader) => {
    if(c.isKnit()) {
      data |= KNIT
    } else if(c.isTuck()) {
      data |= TUCK
    } else if(c.isKnitTuck() || c.isTuckKnit()) {
      data |= KNIT | TUCK
    } else if(c.isTransfer()) {
      data |= XFER
    } else if(c.isSplit()) {
      data |= SPLIT
    } else if(c.isDrop()) {
      data |= DROP
    } else if(c.isExplicitMiss()) {
      data |= XMISS
    }
    return data
  }, 0)
  return {
    hasKnit: (data & KNIT) === KNIT,
    hasTuck: (data & TUCK) === TUCK,
    hasSplit: (data & SPLIT) === SPLIT,
    hasTransfer: (data & XFER) === XFER,
    hasDrop: (data & DROP) === DROP,
    hasExplicitMiss: (data & XMISS) === XMISS,
  }
}
