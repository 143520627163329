import { ReadonlyUint8Array } from '../types'

/**
 * Convert a number into an array of bytes MSB-to-LSB
 *
 * @param val the generic number
 * @param byteLength the assumed number of bytes
 * @returns its representation as an array of bytes
 */
export function toBytes(val: number, byteLength: number): ReadonlyUint8Array {
  const bytes = new Uint8Array(byteLength)
  for(let b = 0; b < byteLength; ++b) {
    bytes[byteLength - 1 - b] = val & 0xFF
    val >>>= 8
  }
  return bytes
}

/**
 * Convert an array of bytes MSB-to-LSB into a number
 *
 * @param bytes the array of bytes
 * @param byteOffset the byte start offset
 * @param byteLength the number of bytes to consider
 * @returns the corresponding number
 */
export function fromBytes(
  bytes: ReadonlyUint8Array,
  byteOffset = 0,
  byteLength = bytes.length,
): number {
  let value = 0
  for(let b = 0; b < byteLength; ++b) {
    value <<= 8
    value |= bytes[byteOffset + b] // & 0xFF
  }
  return value
}
