import { KEYCODE_E } from 'src/common/keyboard'
import { isWithinPage } from 'src/common/math'
import { mergePixelBuffers, PixelBuffer } from 'src/data/image'
import {
  addUndoableAction,
} from 'src/undo'
import { registerShortcut } from './base'
import { ActionContext, KeyOptions } from './common'
import DrawAction from './draw'
import { texturePixelUpdate, UndoablePixelAction } from './line'

export default class Pixel extends DrawAction {
  buffer: PixelBuffer<1>

  isIndirect(): boolean {
    return false // direct drawing type
  }

  onMouseDown(ctx: ActionContext, x: number, y: number, button: number): void {
    super.onMouseDown(ctx, x, y, button)
    if(this.drawing) {
      const { tnimage, updatePreviewData } = ctx
      // start concatenating pixel edits
      // dispatch(startUndoConcat(UndoDomain.EDITOR))
      // add undoable pixel action
      this.buffer = this.getPixel(ctx)
      updatePreviewData(texturePixelUpdate(this.buffer, tnimage.cdata.width))

      // dispatch(addUndoableAction(
      //   new UndoablePixelAction(pixel, tnimage.cdata.width),
      // ))
    }
  }

  onMouseMove(ctx: ActionContext, x: number, y: number, buttons: number): void {
    if(this.drawing) {
      const { tnimage, transform, updatePreviewData } = ctx
      const prevInBounds = this.inBounds
      const lastCoord = this.drawCoord
      this.drawCoord = this.getCoord(ctx, [x, y])
      this.inBounds = isWithinPage(this.drawCoord, transform.pageDims)
      if(this.drawCoord[0] === lastCoord[0] && this.drawCoord[1] === lastCoord[1]) {
        return // has not moved sufficiently to trigger again
      }

      // measure displacement
      const [dx, dy] = [
        this.drawCoord[0] - lastCoord[0],
        this.drawCoord[1] - lastCoord[1],
      ] as const
      const isLargeStep = Math.abs(dx) > 1 || Math.abs(dy) > 1

      // in case the displacement is big, we should then
      // draw a line path from the earlier position to the current one
      // so that we effectively act as if all locations in between had
      // triggered an event (even though they obviously did not)

      // multiple action cases
      let buffer: PixelBuffer<1>

      // case 1 = when going out of bounds
      if(!this.inBounds) {
        if(prevInBounds && isLargeStep) {
          // trace line segment
          const [A, B] = this.getBoundedSegment(ctx, lastCoord, this.drawCoord)
          if(A && B) {
            buffer = this.getLine(ctx, A, B)
          }
          // else there is no valid line inside the bounds
        }
        // else do nothing
      } else if(isLargeStep) {
        // trace line segment
        if(prevInBounds) {
          buffer = this.getLine(ctx, lastCoord, this.drawCoord)
        } else {
          const [A, B] = this.getBoundedSegment(ctx, lastCoord, this.drawCoord)
          if(A && B) {
            buffer = this.getLine(ctx, A, B)
          }
          // XXX this should probably never happen, but better be safe!
        }
      } else {
        // basic pixel action
        buffer = this.getPixel(ctx)
      }

      // if pixel buffer exists, trigger undoable action
      if(buffer) {
        if(this.buffer) {
          this.buffer = mergePixelBuffers(this.buffer, buffer)
        } else {
          this.buffer = buffer
        }
        updatePreviewData(texturePixelUpdate(this.buffer, tnimage.cdata.width))
      }
    } else {
      super.onMouseMove(ctx, x, y, buttons)
    }
  }

  onMouseUp(ctx: ActionContext, x: number, y: number, button: number): void {
    super.onMouseUp(ctx, x, y, button)
    if(this.buffer && this.buffer.length) {
      const { dispatch, tnimage, updatePreviewData } = ctx
      dispatch(addUndoableAction(
        new UndoablePixelAction(this.buffer, tnimage.cdata.width),
      ))
      updatePreviewData(null)
      this.buffer = null
    }
  }

  onKeyDown(ctx: ActionContext, key: number, opts: KeyOptions): void {
    super.onKeyDown(ctx, key, opts)
    // invalidate any current buffer to be safe
    // since key shortcuts could lead to undo/redo
    // => changes in image size
    if(this.buffer) {
      ctx.updatePreviewData(null)
      this.buffer = null
    }
  }
}
registerShortcut(KEYCODE_E, Pixel)
