import { ReadonlyUint8Array } from 'src/common/types';
import {
  CanvasImage,
  createCanvasImage,
  getPixelChannel,
  ReadonlyCanvasImage,
  setPixel,
} from 'src/data/image';
import { NumStitchCodes, StitchCode } from './codes';

/**
 * The distinct stitch code color names
 */
export enum ColorIndex {
  FRNT_KNIT = 0, // front
  FRNT_TUCK,
  FRNT_DROP,
  FRNT_SPLT,
  REAR_KNIT, // rear
  REAR_TUCK,
  REAR_DROP,
  REAR_SPLT,
  BOTH_KNIT, // both
  BOTH_TUCK,
  KNIT_TUCK,
  TUCK_KNIT,
  BOTH_DROP,
  XFER0, // xfer
  XFER1,
  XFER2,
  XFER3,
  XFER4,
  CLEAR, // special
  MISS, // explicit miss
  DISABLED,
  INVALID,
}

/**
 * The colors associated with the color index
 *
 * @see {@link ColorIndex}
 */
export const ColorIndexTable: ReadonlyUint8Array = new Uint8Array([
  175, 217, 255, // front
  108, 149, 222,
  195, 166, 160,
  53, 117, 227,
  255, 227, 151, // rear
  255, 139, 130,
  240, 228, 225,
  231, 86, 75,
  252, 192, 222, // both
  137, 112, 186,
  212, 247, 214,
  113, 194, 156,
  158, 128, 123,
  255, 255, 255, // xfer
  238, 238, 238,
  153, 153, 153,
  85, 85, 85,
  0, 0, 0,
  213, 219, 224, // clear
  175, 241, 195, // explicit miss
  63, 63, 63, // disabled (for invalid stitch combos)
  255, 0, 0, // invalid
]);
console.assert(ColorIndexTable.length % 3 === 0, 'Invalid color table size');

/**
 * Return the color associated with a color index
 */
export function getColorIndexColor(ci: ColorIndex): ReadonlyUint8Array {
  const offset = ci * 3
  return ColorIndexTable.subarray(offset, offset + 3)
}

const StitchCodeToColorIndex = {
  // misses
  [StitchCode.MISS]: ColorIndex.CLEAR,
  [StitchCode.XMISS]: ColorIndex.MISS,
  // both sides
  [StitchCode.BOTH_KNIT]: ColorIndex.BOTH_KNIT, // both
  [StitchCode.BOTH_TUCK]: ColorIndex.BOTH_TUCK,
  [StitchCode.FRNT_KNIT_REAR_TUCK]: ColorIndex.KNIT_TUCK,
  [StitchCode.FRNT_TUCK_REAR_KNIT]: ColorIndex.TUCK_KNIT,
  [StitchCode.BOTH_DROP]: ColorIndex.BOTH_DROP,
  // front side
  [StitchCode.FRNT_KNIT]: ColorIndex.FRNT_KNIT,
  [StitchCode.FRNT_TUCK]: ColorIndex.FRNT_TUCK,
  [StitchCode.FRNT_DROP]: ColorIndex.FRNT_DROP,
  [StitchCode.FRNT_SPLIT]: ColorIndex.FRNT_SPLT,
  // rear side
  [StitchCode.REAR_KNIT]: ColorIndex.REAR_KNIT,
  [StitchCode.REAR_TUCK]: ColorIndex.REAR_TUCK,
  [StitchCode.REAR_DROP]: ColorIndex.REAR_DROP,
  [StitchCode.REAR_SPLIT]: ColorIndex.REAR_SPLT,
  // transfers
  [StitchCode.FRNT_XFER]: ColorIndex.XFER0,
  [StitchCode.REAR_XFER]: ColorIndex.XFER0,
  [StitchCode.FRNT_XFERL1]: ColorIndex.XFER1,
  [StitchCode.FRNT_XFERR1]: ColorIndex.XFER1,
  [StitchCode.REAR_XFERL1]: ColorIndex.XFER1,
  [StitchCode.REAR_XFERR1]: ColorIndex.XFER1,
  [StitchCode.FRNT_XFERL2]: ColorIndex.XFER2,
  [StitchCode.FRNT_XFERR2]: ColorIndex.XFER2,
  [StitchCode.REAR_XFERL2]: ColorIndex.XFER2,
  [StitchCode.REAR_XFERR2]: ColorIndex.XFER2,
  [StitchCode.FRNT_XFERL3]: ColorIndex.XFER3,
  [StitchCode.FRNT_XFERR3]: ColorIndex.XFER3,
  [StitchCode.REAR_XFERL3]: ColorIndex.XFER3,
  [StitchCode.REAR_XFERR3]: ColorIndex.XFER3,
  [StitchCode.FRNT_XFERL4]: ColorIndex.XFER4,
  [StitchCode.FRNT_XFERR4]: ColorIndex.XFER4,
  [StitchCode.REAR_XFERL4]: ColorIndex.XFER4,
  [StitchCode.REAR_XFERR4]: ColorIndex.XFER4,
  // special
  [StitchCode.INVALID]: ColorIndex.INVALID,
} as { [sc in StitchCode]: ColorIndex }

/**
 * Compute the color index associated with a stitch code
 */
export function getColorIndex(sc: StitchCode): ColorIndex {
  return StitchCodeToColorIndex[sc] ?? ColorIndex.INVALID
}

/**
 * Compute the color associated with a stitch code
 */
export function getStitchCodeColor(sc: StitchCode): ReadonlyUint8Array {
  const ci = getColorIndex(sc)
  return getColorIndexColor(ci)
}

/**
 * Table image for mapping from stitch code to color
 */
export const StitchCodeToColorTexture: ReadonlyCanvasImage<3> = createCanvasImage(
  NumStitchCodes,
  1,
  3,
  Array.from({ length: NumStitchCodes }).flatMap(
    (_, sc) => [...getStitchCodeColor(sc as StitchCode)],
  ),
)

/**
 * Compute a RGB image representing a stitch code with each code
 * being replaced by its associated color.
 *
 * @param cimg the stitch code image
 * @param clearAsBlack whether to use clear actions as black instead of gray
 * @returns a RGB image of the same width/height as the input
 */
export function getStitchColorImage(
  cimg: ReadonlyCanvasImage<1>,
  clearAsBlack = true,
) {
  const { width, height } = cimg
  const img: CanvasImage<3> = createCanvasImage(width, height, 3)
  for(let r = 0; r < height; ++r) {
    for(let c = 0; c < width; ++c) {
      const sc = getPixelChannel(cimg, [c, r], 0) as StitchCode
      const ci = getColorIndex(sc)
      // if we use clear as black, then skip if clear
      // because the default color is black [0, 0, 0]
      if(ci === ColorIndex.CLEAR && clearAsBlack) { continue }
      // set pixel color from color index
      const color = getColorIndexColor(ci)
      setPixel(img, [c, r], color)
    }
  }
  return img
}
