import { useEffect } from 'react'
import { unstable_batchedUpdates as batchUpdates } from 'react-dom'

export const useDocumentWheel = function (
  callback: WheelEventCallback,
  deps: any[] = [],
  enabled = true,
) {
  useEffect(enabled ? () => {
    const onWheel = (e: WheelEvent) => {
      batchUpdates(() => callback(e))
    }
    document.addEventListener('wheel', onWheel)
    return () => document.removeEventListener('wheel', onWheel)
  } : () => {}, [enabled, ...deps])
}

export const useDocumentKeyDown = function (
  callback: KeyboardEventCallback,
  deps: any[] = [],
  enabled = true,
) {
  useEffect(enabled ? () => {
    const onKeyDown = (e: KeyboardEvent) => {
      batchUpdates(() => callback(e))
    }
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  } : () => {}, [enabled, ...deps])
}
