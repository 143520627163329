import { addRows } from 'src/data/time-needle/topology'
import {
  KnittingSide,
  getKnittingSides,
  SelectorLike,
  TimeNeedleTransformer,
} from './common'

export function generateSideTransfers(
  s: SelectorLike,
): SelectorLike {
  const rows = s.splitByRow()
  console.assert(rows.length > 1, 'Requires multiple rows')
  // measure transfers between each row pair
  const rowSides = new Array<[KnittingSide[], KnittingSide[]]>(rows.length - 1)
  for(let i = 0; i < rows.length - 1; ++i) {
    const srcRow = rows[i]
    const trgRow = rows[i + 1]
    // /!\ note: this assumes no shaping!
    rowSides[i] = [
      getKnittingSides(srcRow, 'below'),
      getKnittingSides(trgRow, 'above'),
    ]
  }
  // generate transfers from top to bottom given change of topology
  const isNone = (side: KnittingSide) => side === KnittingSide.NONE
  const isBoth = (side: KnittingSide) => side === KnittingSide.BOTH
  let srcRow: SelectorLike
  let numNewRows = 0
  for(let r = rowSides.length - 1; r >= 0; --r) {
    if(srcRow) {
      srcRow = srcRow.withSelection(rows[r].selection)
    } else {
      srcRow = rows[r]
    }
    const [srcSides, trgSides] = rowSides[r]
    // compute necessary transfers
    const xferSrcs = srcSides.map((srcSide, i) => {
      const trgSide = trgSides[i]
      if(srcSide === trgSide || isNone(srcSide) || isNone(trgSide) || isBoth(trgSide)) {
        return undefined
      }
      return trgSide === KnittingSide.FRONT ? KnittingSide.REAR : KnittingSide.FRONT
    })
    const numPasses = [0, 0]
    for(let i = 0; i < xferSrcs.length; ++i) {
      const src = xferSrcs[i]
      if(src !== undefined) {
        if(numPasses[src] === 0) {
          ++numPasses[src]
        } else if(numPasses[src] === 1 && xferSrcs[i - 1] === src) {
          ++numPasses[src]
        }
      }
    }
    const numRows = numPasses[0] + numPasses[1]
    if(numRows === 0) {
      continue // no necessary transfer
    }

    // genereate transfer rows
    const [baseRow, newRows] = addRows(srcRow, 'above', numRows)
    srcRow = baseRow
    numNewRows += numRows

    // set row options
    const xferOptions = { stitchSize: 5, speed: 60 }
    newRows.splitByRow().forEach((row, i, arr) => {
      row.options(xferOptions)
      row.options({
        roller: [
          [30],
          [0, 30],
          [0, 30, 30],
          [0, 30, 0, 30],
        ][numRows - 1][i],
      })
    })

    // generate transfers
    const sideDY = [1, 1 + numPasses[0]]
    const passes = [0, 0]
    for(let i = 0; i < xferSrcs.length; ++i) {
      const src = xferSrcs[i]
      if(src !== undefined) {
        // update pass number by looking at the past
        if(xferSrcs[i - 1] === src) {
          passes[src] = 1 - passes[src] // alternate side
          passes[1 - src] = 0 // reset other side
        } else {
          passes[0] = 0 // reset
          passes[1] = 0
        }
        // apply transfer
        const c = baseRow.getCell(i).neighbor(0, sideDY[src] + passes[src])
        c.transfer(src, 0)
      }
    } // endfor i < #xferSrcs
  } // endfor i

  return TimeNeedleTransformer.fromSelector(srcRow)
    .withSelection(s.selection)
    .extendUp(numNewRows)
}
