import { useState, useEffect } from 'react'
import { shallowEqual } from 'react-redux'
import { useBeforeunload } from 'react-beforeunload'

import { UserHeader } from 'src/auth'
import { useHistory, useParams, Prompt as Confirm } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import {
  makeDesign, updateDesign, createDesign, copyDesign,
} from 'src/api'

import { setTitle } from 'src/editor/reducers/design'
import { redoAction, undoAction, UndoDomain } from 'src/undo'
import { Serialize } from 'src/data/serialization'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import logo from 'src/assets/nav-logo.svg'
import Palette from 'src/data/time-needle/palette'
import { getHash } from 'src/common/hash'
import { getThumbnail } from 'src/data/time-needle/thumbnail'
import { ViewMode } from 'src/data/time-needle/view-mode'
import { useKCodeExport } from './ribbon/knitout'
import ViewSelector from './view-selector'
import { disableCanvas, enableCanvas } from './time-needle/slice'

import 'src/styles/header.scss'

const Header = function () {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { id: designId } = useParams()

  const {
    tnimage,
    past, future, name,
    columnsPerCm,
    rowsPerCm,
  } = useAppSelector(({
    canvas: { tnimage },
    design: { title: name },
    undo: { editor: { past, future } },
    settings: {
      columnsPerCm,
      rowsPerCm,
    },
  }) => ({
    tnimage,
    name,
    past,
    future,
    columnsPerCm,
    rowsPerCm,
  }), shallowEqual)
  const [localName, setName] = useState(name)
  const [savedHash, setSavedHash] = useState('')

  const [authToken, setAuthToken] = useState('')
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if(tnimage) {
      const newHash = getHash(tnimage)
      if(savedHash == '') {
        setSavedHash(newHash)
      }
    }
  }, [tnimage])

  useBeforeunload((event) => {
    const hash = tnimage ? getHash(tnimage) : ''
    if(hash !== savedHash) {
      event.preventDefault()
    }
  })

  useEffect(() => {
    if(name) {
      setName(name)
      // document.title = `Topologic - ${name}${dirty ? '*' : ''}`
    } else {
      setName('Untitled')
      document.title = 'Topologic - Untitled*'
    }
  }, [name])

  useEffect(() => {
    (async () => {
      const tok = isAuthenticated ? await getAccessTokenSilently() : null
      setAuthToken(tok)
    })()
  }, [isAuthenticated, getAccessTokenSilently])

  const duplicateDesign = async () => {
    if(window.confirm('Would you like to duplicate current design (this will also save changes)?')) {
      await saveDesign()
      dispatch(disableCanvas())
      const { CopyID: newId} = await copyDesign(designId, authToken)
      window.location = `/app/${newId}` as (string & Location)
    }
  }

  const saveDesign = async () => {
    dispatch(disableCanvas())
    const design = makeDesign(
      localName,
      Serialize(tnimage),
      Palette.default().toColorArray(),
      tnimage.cdata.width * columnsPerCm,
      tnimage.cdata.height * rowsPerCm,
      columnsPerCm,
      rowsPerCm,
      getThumbnail(tnimage, ViewMode.PATTERN),
    )

    setSavedHash(getHash(tnimage))
    if(designId) {
      await updateDesign(designId, design, authToken)
    } else {
      const designId = await createDesign(design, authToken)
      history.push(`/app/${designId}`)
    }

    dispatch(setTitle(localName))
    dispatch(enableCanvas())
  }
  const exportKCode = useKCodeExport()

  return (
    <header>
      <a className="logo" href="/"><img src={logo} alt="logo" /></a>
      <button className="undo" disabled={!past.length} onClick={() => dispatch(undoAction(UndoDomain.EDITOR))} data-tooltip="Undo" />
      <button className="redo" disabled={!future.length} onClick={() => dispatch(redoAction(UndoDomain.EDITOR))} data-tooltip="Redo" />
      <button className="designs-button" onClick={() => history.push('/')} data-tooltip="Designs page" />
      <input type="text" onChange={({ target: { value } }) => setName(value)} value={localName} className="design-name" />
      <button className="copy" onClick={duplicateDesign} data-tooltip="Duplicate design" />
      <button className="save" onClick={saveDesign} data-tooltip="Save design" />
      <ViewSelector />
      {/* <button className="share">Share</button> */}
      <button className="knit" onClick={() => exportKCode(tnimage)}>Knit!</button>
      <UserHeader />
      <Confirm
        message={() => ((tnimage ? getHash(tnimage) : '') !== savedHash ? 'Do you want to leave this page? Changes you made may not be saved.' : true)}
      />
    </header>
  )
}

export default Header
