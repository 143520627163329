import type MachineType from '../machine-type'
import { KniterateOptionsList, KniterateOptionsBytes, KniterateBedWidth } from './kniterate'
import type OptionField from './option-field'

/**
 * Map type to ensure we have values for all machine types
 */
type OptionMap<T> = {
  [type in MachineType]: T
}

/**
 * The mapping from machine type to option list
 */
const MachineTypeToOptionsList: OptionMap<readonly OptionField[]> = {
  kniterate: KniterateOptionsList,
}

/**
 * The mapping from machine type to options bytes
 */
const MachineTypeToOptionsBytes: OptionMap<number> = {
  kniterate: KniterateOptionsBytes,
}

/**
 * The mapping from machine type to bed width
 */
const MachineTypeToBedWidth: OptionMap<number> = {
  kniterate: KniterateBedWidth,
}

/**
 * Returns the list of options associated with a machine type
 */
export function getOptionsList(type: MachineType = 'kniterate') {
  return MachineTypeToOptionsList[type]
}

/**
 * Returns the number of bytes necessary
 * to represent the options of a machine type
 */
export function getOptionsBytes(type: MachineType = 'kniterate') {
  return MachineTypeToOptionsBytes[type]
}

/**
 * Returns the number of addressable needles
 * on a single bed of a given machine type
 */
export function getBedWidth(type: MachineType = 'kniterate') {
  return MachineTypeToBedWidth[type]
}
