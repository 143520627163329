import { toPNGDataUrl } from 'src/data/image/dom';
import { avgDownsample } from 'src/data/image/avg-downsample'
import type { ReadonlyTimeNeedleImage } from './time-needle-image';
import { getImage } from './get-image';
import { ViewMode } from './view-mode';

export function getThumbnail(
  img: ReadonlyTimeNeedleImage,
  view: ViewMode,
): string {
  const { width, height } = img.cdata

  const colImg = getImage(img, view)

  // Resize to about 64px across the largest dimension (2^6)
  let scale = 0;
  if(width >= height) scale = Math.max(Math.floor(Math.log(width) / Math.log(2)) - 6, 0);
  else scale = Math.max(Math.floor(Math.log(height) / Math.log(2)) - 6, 0);

  // downsample
  const thumbImg = avgDownsample(scale, colImg.data, width, height, colImg.channels)

  return toPNGDataUrl(thumbImg)
}
