/* jshint evil: true */

// special input argument
export const This = Symbol('this');

/**
 * Transformer encapsulating the result of an expression to return it
 */
export function returnExpr(code: string) {
  return `return (${code});`;
}

export type StringTransformer = (str: string) => string
export type VerboseFlag = string | boolean

/**
 * The main function creation mechanism
 */
function createFunction(
  code: string,
  argNames: string[],
  modifiers: StringTransformer[],
  verbose: VerboseFlag = false,
) {
  // normalize arguments
  if(!argNames) argNames = [];
  else if(!Array.isArray(argNames)) argNames = [argNames];
  if(!modifiers) modifiers = [];
  if(!Array.isArray(modifiers)) modifiers = [modifiers];
  // create preamble for all inputs and postamble
  const preamble = `"use strict"; return (function(${argNames.join(', ')}){ `;
  const postamble = '\n})'; // needs the \n in case last line is a line comment

  // syntactic sugar
  code = modifiers.reduce((str, map) => map(str), code);

  // verbose output
  if(verbose) {
    if(typeof verbose === 'string') console.log(verbose + preamble + code + postamble);
    else console.log(`Function: ${preamble}${code}${postamble}`);
  }
  // create function (= compile program)
  // eslint-disable-next-line no-new-func
  return new Function(preamble + code + postamble)();
}

/**
 * The main function mechanism, adapted to return a function that is safe to execute
 *
 * @see createFunction
 */
export function safeFunction(
  code: string,
  argNames: string[],
  defaultRet: any = undefined,
  modifiers: StringTransformer[] = [],
  verbose: VerboseFlag = false,
) {
  let func;
  try {
    func = createFunction(code, argNames, modifiers, verbose);
  } catch(err) {
    return null
  }
  return (...args) => {
    try {
      return func(...args);
    } catch(err) {
      return defaultRet
    }
  };
}

/**
 * Generate a function that evaluates an expression
 */
export function exprFunction(
  expr: string,
  argNames: string[],
  modifiers: StringTransformer[] = [],
  verbose: VerboseFlag = false,
) {
  if(!modifiers.includes(returnExpr)) modifiers = modifiers.concat([returnExpr]);
  return createFunction(expr, argNames, modifiers, verbose);
}

/**
 * Generate a function that safely evaluates an expression
 * returning a default value in case an exception is raised.
 *
 * @see exprFunction
 */
export function safeExprFunction(
  expr: string,
  argNames: string[],
  defaultRet: any,
  modifiers: StringTransformer[] = [],
  verbose: VerboseFlag = false,
): (...args:any[]) => any {
  let func;
  try {
    if(!modifiers.includes(returnExpr)) modifiers = modifiers.concat([returnExpr])
    func = createFunction(expr, argNames, modifiers, verbose)
  } catch(err) {
    return null
  }
  return (...args) => {
    try {
      return func(...args);
    } catch(err) {
      return defaultRet;
    }
  };
}

export interface InputType {
  [arg: string]: any
  [This]?: any
}

/**
 * Create a function evaluating code and evaluate it
 */
export function evalCode(
  code: string,
  inputs: InputType,
  modifiers: StringTransformer[] = [],
  verbose: VerboseFlag = false,
) {
  // normalize inputs
  if(!inputs) inputs = {};

  // create argument list
  const argNames = Object.keys(inputs); // note: This is a symbol => won't appear in keys
  const argValues = argNames.map((arg) => inputs[arg]);

  // create function (= compile program)
  const f = createFunction(
    code,
    argNames,
    modifiers,
    verbose && typeof verbose !== 'string' ? 'Evaluating: ' : false,
  );

  // apply program
  const base = This in inputs ? inputs[This] : null;
  const res = f.apply(base, argValues);

  // return output of program (if any)
  return res;
}

/**
 * Create a script function and evaluate it safely,
 * returning its value if all right, or a default value upon error.
 *
 * @see evalCode
 */
export function safeEvalCode(
  code: string,
  inputs: InputType,
  defaultValue: any,
  modifiers: StringTransformer[] = [],
  verbose: VerboseFlag = false,
) {
  try {
    const value = evalCode(code, inputs, modifiers, verbose);
    return value;
  } catch(e) {
    return defaultValue;
  }
}

/**
 * Evaluate an expression via a function returning its value
 *
 * @see evalCode
 */
function evalExpr(
  expr: string,
  inputs: InputType,
  modifiers: StringTransformer[] = [],
  verbose: VerboseFlag = false,
) {
  if(!modifiers) modifiers = [];
  if(!Array.isArray(modifiers)) modifiers = [modifiers];
  if(modifiers.indexOf(returnExpr) == -1) modifiers.push(returnExpr);
  return evalCode(expr, inputs, modifiers, verbose && typeof verbose !== 'string' ? 'Expr: ' : verbose);
}

/**
 * Safe evaluation of an expression,
 * returning the expression value if successful,
 * a provided default value otherwise
 *
 * @see evalExpr
 */
export function safeEvalExpr(
  expr: string,
  inputs: InputType,
  defaultValue: any,
  modifiers: StringTransformer[] = [],
  verbose: VerboseFlag = false,
) {
  try {
    const value = evalExpr(expr, inputs, modifiers, verbose);
    return value;
  } catch(e) {
    return defaultValue;
  }
}
