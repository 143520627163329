/**
 * Iterable and queryable range
 */
export default class Range {
  constructor(
    public readonly start: number,
    public readonly end: number = start,
    public readonly incr: number = 1,
    checkRange = true,
  ) {
    if(incr === 0 || isNaN(incr)) {
      throw `Invalid range increment ${incr}`
    } else if(checkRange && (end - start) * incr < 0) {
      throw `The range bounds do not match the increment sign: from ${start} to ${end} by ${incr}`
    }
  }

  static from(max: number, ...range: number[]): Range {
    // special case for empty range information
    if(range.length === 0) {
      return new Range(0, max, 1)
    }
    // other cases = 1 to 3 arguments
    let [start, end = start, incr = 1] = range
    if(start < 0) {
      start = max + start
    }
    if(end < 0) {
      end = max + end
    }
    return new Range(start, end, incr)
  }

  get compareToEnd(): (n: number) => boolean {
    const {end} = this
    return this.incr > 0 ? (n: number) => n <= end : (n: number) => n >= end
  }

  * [Symbol.iterator]() {
    const { start, compareToEnd, incr } = this
    for(let i = start; compareToEnd(i); i += incr) {
      yield i
    }
  }

  includes(n: number): boolean {
    const { start, incr, end } = this
    if(incr > 0) {
      return start <= n && n < end && ((n - start) % incr) === 0
    }
    return end < n && n <= start && ((start - n) % (-incr)) === 0
  }
}
