// import { composeWithDevTools } from "redux-devtools-extension"
import {
  createStore, compose, combineReducers, AnyAction,
} from 'redux'
import { createAction } from '@reduxjs/toolkit'
import ui, { UIState } from 'src/editor/reducers/ui'
import simulation, { SimulationState } from 'src/editor/simulation/data'
import canvas, { CanvasState } from 'src/editor/time-needle/slice'
import notify, { NotificationState } from 'src/editor/notification/slice'
import settings, { SettingsState } from 'src/editor/settings'
import design, { DesignState } from 'src/editor/reducers/design'
import userEntry, { UserEntryState } from 'src/hooks/user-entry'
import { RootStateWithUndoRedo, undoable } from './undo'

/**
 * The base redux state
 */
export interface RootState {
  canvas: CanvasState
  userEntry: UserEntryState
  design: DesignState
  notify: NotificationState
  settings: SettingsState
  simulation: SimulationState
  ui: UIState
}

const baseReducer = combineReducers<RootState>({
  canvas,
  userEntry,
  design,
  notify,
  settings,
  simulation,
  ui,
})

/**
 * Action to reset the redux state
 */
export const resetState = createAction('reset')

const undoableReducer = undoable(baseReducer)

const rootReducer = (state: RootStateWithUndoRedo, action: AnyAction) => {
  // special reset action
  if(resetState.match(action)) {
    return undoableReducer(undefined, action)
  }
  // delegate to actual reducer
  return undoableReducer(state, action)
}

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, composeEnhancers())

/**
 * The typed version of the application dispatch function
 */
export type AppDispatch = typeof store.dispatch

/**
 * The redux store
 */
export default store
