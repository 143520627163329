import {
  CanvasImage,
  copyImageToImage,
  createCanvasImage,
  ReadonlyCanvasImage,
  setPixel,
  setPixelRow,
} from './image'

// ###########################################################################
// ### Filling methods #######################################################
// ###########################################################################

/**
 * Fill a range of columns in an image
 *
 * @param image the image to update
 * @param colIdx the starting column to fill
 * @param numCols the number of columns to fill
 * @param fill the per-pixel filling content
 */
export function fillColumns<NC extends number>(
  image: CanvasImage<NC>,
  colIdx: number,
  numCols = 1,
  fill: ArrayLike<number>,
) {
  const { width, height } = image
  if(colIdx < 0 || colIdx >= width || colIdx + numCols > width) {
    throw `Column index out of bounds: from ${colIdx} to ${colIdx + numCols}, width=${width}`
  } else if(numCols <= 0) {
    throw `The number of columns to fill must be positive: got ${numCols}`
  }
  for(let r = 0; r < height; ++r) {
    for(let c = colIdx, cn = colIdx + numCols; c < cn; ++c) {
      setPixel(image, [c, r], fill)
    }
  }
}

/**
 * Fill a range of rows in an image
 *
 * @param image the image to update
 * @param rowIdx the starting row to fill
 * @param numRows the number of rows to fill
 * @param fill either the per-pixel or per-row filling content
 */
export function fillRows<NC extends number>(
  image: CanvasImage<NC>,
  rowIdx: number,
  numRows = 1,
  fill: ArrayLike<number>,
) {
  const { width, height, channels } = image
  if(rowIdx < 0 || rowIdx >= height || rowIdx + numRows > height) {
    throw `Row index out of bounds: from ${rowIdx} to ${rowIdx + numRows}, height=${height}`
  } else if(numRows <= 0) {
    throw `The number of rows to add must be positive: got ${numRows}`
  }
  if(fill.length === channels) { // either pixel-by-pixel
    for(let r = rowIdx, nr = rowIdx + numRows; r < nr; ++r) {
      for(let c = 0; c < width; ++c) {
        setPixel(image, [c, r], fill)
      }
    }
  } else if(fill.length === width * channels) { // or row-by-row
    for(let r = rowIdx, nr = rowIdx + numRows; r < nr; ++r) {
      setPixelRow(image, r, fill)
    }
  } else { // else the fill is invalid
    throw `Invalid fill cardinality: got ${fill.length}, expected ${channels} or ${channels * width}`
  }
}

// ###########################################################################
// ### Insert methods ########################################################
// ###########################################################################

/**
 * Create a new image that inserts a number of columns
 * at a given column index of the source image.
 *
 * The column index corresponds to the location
 * new columns are added from. The previous column
 * at that given index ends up being pushed to the right.
 *
 * The range of values for `colIdx` goes from:
 * - `0` (add to the left of the image) to
 * - `width` (add to the right of the image)
 *
 * @param srcImage the source image, untouched
 * @param colIdx the column index to insert before
 * @param numCols the number of columns to add
 * @param fill the pixel content to use for filling
 * @returns the new image
 */
export function insertColumns<NC extends number>(
  srcImage: ReadonlyCanvasImage<NC>,
  colIdx: number,
  numCols = 1,
  fill?: ArrayLike<number>,
): CanvasImage<NC> {
  const { width, height, channels } = srcImage
  if(colIdx < 0 || colIdx > width) {
    throw `Column index out of bounds: ${colIdx}, width=${width}`
  } else if(numCols <= 0) {
    throw `The number of columns to add must be positive: got ${numCols}`
  }
  // allocate new image
  const newWidth = width + numCols
  const newImage = createCanvasImage(newWidth, height, channels)
  // potentially fill new columns
  if(fill) {
    fillColumns(newImage, colIdx, numCols, fill)
  }
  // columns before
  if(colIdx > 0) {
    const srcRectBefore = [0, 0, colIdx, height] as const
    if(!copyImageToImage(srcImage, srcRectBefore, newImage, srcRectBefore)) {
      console.error('Failed to copy source image to target image')
    }
  }
  // columns after
  if(colIdx < width) {
    const srcRectAfter = [colIdx, 0, width, height] as const
    const trgRectAfter = [colIdx + numCols, 0, newWidth, height] as const
    if(!copyImageToImage(srcImage, srcRectAfter, newImage, trgRectAfter)) {
      console.error('Failed to copy source image to target image')
    }
  }
  return newImage
}

/**
 * Create a new image that inserts a number of rows
 * at a given row index of the source image.
 *
 * The row index corresponds to the location
 * new rows are added from. The previous row
 * at that given index ends up being pushed above.
 *
 * The range of values for `rowIdx` goes from:
 * - `0` (add to the bottom of the image) to
 * - `height` (add to the top of the image)
 *
 * @param srcImage the source image, untouched
 * @param rowIdx the row index to insert before
 * @param numRows the number of rows to add
 * @param fill either the pixel content to use for filling, or a full row content
 * @returns the new image
 */
export function insertRows<NC extends number>(
  srcImage: ReadonlyCanvasImage<NC>,
  rowIdx: number,
  numRows = 1,
  fill?: ArrayLike<number>,
): CanvasImage<NC> {
  const { width, height, channels } = srcImage
  if(rowIdx < 0 || rowIdx > height) {
    throw `Row index out of bounds: ${rowIdx}, height=${height}`
  } else if(numRows <= 0) {
    throw `The number of rows to add must be positive: got ${numRows}`
  }
  // allocate new image
  const newHeight = height + numRows
  const newImage = createCanvasImage(width, newHeight, channels)
  // potentially fill new rows
  if(fill) {
    fillRows(newImage, rowIdx, numRows, fill)
  }
  // rows before
  if(rowIdx > 0) {
    const srcRectBefore = [0, 0, width, rowIdx] as const
    if(!copyImageToImage(srcImage, srcRectBefore, newImage, srcRectBefore)) {
      console.error('Failed to copy source image to target image')
    }
  }
  // rows after
  if(rowIdx < height) {
    const srcRectAfter = [0, rowIdx, width, height] as const
    const trgRectAfter = [0, rowIdx + numRows, width, newHeight] as const
    if(!copyImageToImage(srcImage, srcRectAfter, newImage, trgRectAfter)) {
      console.error('Failed to copy source image to target image')
    }
  }
  return newImage
}

// ###########################################################################
// ### Insert set methods ####################################################
// ###########################################################################

/**
 * Compute an ordered insertion sequence.
 *
 * When `allowRepeat=true`, repeated entries
 * are merged together into a super insertion
 * that encompasses all repeated insertions.
 *
 * When `allowRepeat=false`, repeated entries
 * raise an error.
 *
 * @param insSet a set of insertion entries `[idx, num]`
 * @param allowRepeat whether to allow repeated index entries and sum-merge them
 * @returns the ordered insertion sequence with repeated entries merged (or failed)
 */
export function getInsertionSeq(
  insSet: Iterable<readonly [number, number]>,
  allowRepeat: boolean,
): (readonly [number, number])[] {
  const map = new Map<number, number>()
  for(const [idx, num] of insSet) {
    if(map.has(idx)) {
      // not the first entry
      // => if allowed, sum numbers
      if(allowRepeat) {
        const sum = map.get(idx) + num
        map.set(idx, sum)
      } else {
        // repeated entries are not allowed!
        throw `Insert index ${idx} appeared multiple times`
      }
    } else {
      map.set(idx, num) // first entry
    }
  }
  return Array.from(map.entries()).sort(([a], [b]) => a - b)
}

/**
 * Insert a set of columns
 *
 * @param srcImage the source image, untouched
 * @param colSet the set of column `[colIdx, numCols]` insertion entries
 * @param fill an optional per-pixel filling for the new columns
 * @param allowRepeat whether to allow repeated entries for multi-insertions
 * @returns the resulting image after insertion
 */
export function insertColumnSet<NC extends number>(
  srcImage: ReadonlyCanvasImage<NC>,
  colSet: Iterable<readonly [number, number]>,
  fill?: ArrayLike<number>,
  allowRepeat = true,
): CanvasImage<NC> {
  const { width, height, channels } = srcImage
  const colSeq = getInsertionSeq(colSet, allowRepeat)
  if(colSeq.length === 0) {
    throw 'The set of column insertions is empty'
  }
  for(const [colIdx, numCols] of colSeq) {
    if(colIdx < 0 || colIdx > width) {
      throw `Column index out of bounds: ${colIdx}, width=${width}`
    } else if(numCols <= 0) {
      throw `The number of columns to add must be positive: got ${numCols}`
    }
  }
  // allocate new image
  const totalNumCols = colSeq.reduce((sum, [_, num]) => sum + num, 0)
  const newWidth = width + totalNumCols
  const newImage = createCanvasImage(newWidth, height, channels)
  // optional filling and columns before each insertion point
  let colShift = 0
  for(const [i, [colIdx, numCols]] of colSeq.entries()) {
    // update column shift from previous accummulated columns
    const [prevIdx, prevNum] = i > 0 ? colSeq[i - 1] : [0, 0]
    colShift += prevNum
    // optional filling
    if(fill) {
      fillColumns(newImage, colIdx + colShift, numCols, fill)
    }
    // copy old column to the left, up to the previous insertion
    if(colIdx > prevIdx) {
      const srcRectBefore = [
        prevIdx, 0,
        colIdx, height,
      ] as const
      const trgRectBefore = [
        prevIdx + colShift, 0,
        colIdx + colShift, height,
      ] as const
      if(!copyImageToImage(srcImage, srcRectBefore, newImage, trgRectBefore)) {
        console.error('Failed to copy source image to target image')
      }
    }
  }
  // columns after last insertion point
  const [lastColIdx, lastNumCols] = colSeq[colSeq.length - 1]
  if(lastColIdx < width) {
    console.assert(
      totalNumCols === colShift + lastNumCols,
      'The accummulated column width does not match',
    )
    console.assert(
      lastColIdx + lastNumCols + colShift < newWidth,
      'The last column range information seems invalid',
      lastColIdx,
      lastNumCols,
      colShift,
      newWidth,
    )
    const srcRectAfter = [
      lastColIdx, 0,
      width, height,
    ] as const
    const trgRectAfter = [
      lastColIdx + lastNumCols + colShift, 0,
      newWidth, height,
    ] as const
    if(!copyImageToImage(srcImage, srcRectAfter, newImage, trgRectAfter)) {
      console.error('Failed to copy source image to target image')
    }
  }
  return newImage
}

/**
 * Insert a set of rows
 *
 * @param srcImage the source image, untouched
 * @param rowSet the set of rows `[rowIdx, numRows]` insertion entries
 * @param fill an optional per-pixel or per-row filling for the new rows
 * @param allowRepeat whether to allow repeated entries for multi-insertions
 * @returns the resulting image after insertion
 */
export function insertRowSet<NC extends number>(
  srcImage: ReadonlyCanvasImage<NC>,
  rowSet: Iterable<readonly [number, number]>,
  fill?: ArrayLike<number>,
  allowRepeat = true,
): CanvasImage<NC> {
  const { width, height, channels } = srcImage
  const rowSeq = getInsertionSeq(rowSet, allowRepeat)
  if(rowSeq.length === 0) {
    throw 'The set of row insertions is empty'
  }
  for(const [rowIdx, numRows] of rowSeq) {
    if(rowIdx < 0 || rowIdx > height) {
      throw `Row index out of bounds: ${rowIdx}, height=${height}`
    } else if(numRows <= 0) {
      throw `The number of rows to add must be positive: got ${numRows}`
    }
  }
  // allocate new image
  const totalNumRows = rowSeq.reduce((sum, [_, num]) => sum + num, 0)
  const newHeight = height + totalNumRows
  const newImage = createCanvasImage(width, newHeight, channels)
  // optional filling and rows before each insertion point
  let rowShift = 0
  for(const [i, [rowIdx, numRows]] of rowSeq.entries()) {
    // update row shift from previous accummulated rows
    const [prevIdx, prevNum] = i > 0 ? rowSeq[i - 1] : [0, 0]
    rowShift += prevNum
    // optional filling
    if(fill) {
      fillRows(newImage, rowIdx + rowShift, numRows, fill)
    }
    // copy old column to the left, up to the previous insertion
    if(rowIdx > prevIdx) {
      const srcRectBefore = [
        0, prevIdx,
        width, rowIdx,
      ] as const
      const trgRectBefore = [
        0, prevIdx + rowShift,
        width, rowIdx + rowShift,
      ] as const
      if(!copyImageToImage(srcImage, srcRectBefore, newImage, trgRectBefore)) {
        console.error('Failed to copy source image to target image')
      }
    }
  }
  // columns after last insertion point
  const [lastRowIdx, lastNumRows] = rowSeq[rowSeq.length - 1]
  if(lastRowIdx < height) {
    console.assert(
      totalNumRows === rowShift + lastNumRows,
      'The accummulated row height does not match',
    )
    console.assert(
      lastRowIdx + lastNumRows + rowShift < newHeight,
      'The last row range information seems invalid',
      lastRowIdx,
      lastNumRows,
      rowShift,
      newHeight,
    )
    const srcRectAfter = [
      0, lastRowIdx,
      width, height,
    ] as const
    const trgRectAfter = [
      0, lastRowIdx + lastNumRows + rowShift,
      width, newHeight,
    ] as const
    if(!copyImageToImage(srcImage, srcRectAfter, newImage, trgRectAfter)) {
      console.error('Failed to copy source image to target image')
    }
  }
  return newImage
}

// ###########################################################################
// ### Delete methods ########################################################
// ###########################################################################

/**
 * Create a new image that deletes a range of columns
 * from a given column index of the source image.
 *
 * The column index corresponds to the location
 * old columns are removed from.
 *
 * The range of values goes from:
 * - 0 (delete from the left of the image) to
 * - `width - numCols` (delete on the right of the image)
 *
 * @param srcImage the source image, untouched
 * @param colIdx the column index to delete from
 * @param numCols the number of columns to remove
 * @returns the new image
 */
export function deleteColumns<NC extends number>(
  srcImage: ReadonlyCanvasImage<NC>,
  colIdx: number,
  numCols = 1,
): CanvasImage<NC> {
  const { width, height, channels } = srcImage
  if(colIdx < 0 || colIdx >= width) {
    throw `Column index out of bounds: ${colIdx}, width=${width}`
  } else if(numCols <= 0) {
    throw `The number of columns to remove must be positive: got ${numCols}`
  } else if(colIdx + numCols > width) {
    throw `Cannot delete that many columns: from ${colIdx} to ${colIdx + numCols}: width=${width}`
  }
  // allocate new image
  const newWidth = width - numCols
  const newImage = createCanvasImage(newWidth, height, channels)
  // columns before
  if(colIdx > 0) {
    const srcRectBefore = [0, 0, colIdx, height] as const
    if(!copyImageToImage(srcImage, srcRectBefore, newImage, srcRectBefore)) {
      console.error('Failed to copy source image to target image')
    }
  }
  // columns after
  if(colIdx + numCols < width) {
    const srcRectAfter = [colIdx + numCols, 0, width, height] as const
    const trgRectAfter = [colIdx, 0, newWidth, height] as const
    if(!copyImageToImage(srcImage, srcRectAfter, newImage, trgRectAfter)) {
      console.error('Failed to copy source image to target image')
    }
  }
  return newImage
}

/**
 * Create a new image that deletes a set of rows
 * from a given row index of the source image.
 *
 * The row index corresponds to the location
 * new rows are deleted from.
 *
 * The range of values goes from:
 * - `0` (delete from the bottom of the image) to
 * - `height - numRows` (delete to the top of the image)
 *
 * @param srcImage the source image, untouched
 * @param rowIdx the row index to delete from
 * @param numRows the number of rows to delete
 * @returns the new image
 */
export function deleteRows<NC extends number>(
  srcImage: ReadonlyCanvasImage<NC>,
  rowIdx: number,
  numRows = 1,
): CanvasImage<NC> {
  const { width, height, channels } = srcImage
  if(rowIdx < 0 || rowIdx >= height) {
    throw `Row index out of bounds: ${rowIdx}, height=${height}`
  } else if(numRows <= 0) {
    throw `The number of rows to add must be positive: got ${numRows}`
  } else if(rowIdx + numRows > height) {
    throw `Cannot delete that many rows: from ${rowIdx} to ${rowIdx + numRows}: height=${height}`
  }
  const newHeight = height - numRows
  const newImage = createCanvasImage(width, newHeight, channels)
  // rows before
  if(rowIdx > 0) {
    const srcRectBefore = [0, 0, width, rowIdx] as const
    if(!copyImageToImage(srcImage, srcRectBefore, newImage, srcRectBefore)) {
      console.error('Failed to copy source image to target image')
    }
  }
  // rows after
  if(rowIdx + numRows < height) {
    const srcRectAfter = [0, rowIdx + numRows, width, height] as const
    const trgRectAfter = [0, rowIdx, width, newHeight] as const
    if(!copyImageToImage(srcImage, srcRectAfter, newImage, trgRectAfter)) {
      console.error('Failed to copy source image to target image')
    }
  }
  return newImage
}

// ###########################################################################
// ### Delete set methods ####################################################
// ###########################################################################

/**
 * Compute a deletion sequence from a set of indices
 *
 * @param set the set of indices (can be repeated)
 * @returns an ordered sequence of deletion steps `[idx, num]`
 */
export function getDeletionSeq(set: Iterable<number>): [number, number][] {
  const seq = [...new Set(set)].sort((a, b) => a - b)
  const rngSeq = new Array<[number, number]>()
  for(const idx of seq) {
    if(rngSeq.length === 0) {
      rngSeq.push([idx, 1])
    } else {
      const rng = rngSeq[rngSeq.length - 1]
      const [start, num] = rng
      if(start + num === idx) {
        rng[1] = num + 1 // extend by 1
      } else {
        rngSeq.push([idx, 1]) // create new range
      }
    }
  }
  return rngSeq
}

/**
 * Delete a set of columns from an image
 *
 * @param srcImage the source image, untouched
 * @param colSet a set of column indices (can be repeated)
 * @returns the updated image
 */
export function deleteColumnSet<NC extends number>(
  srcImage: ReadonlyCanvasImage<NC>,
  colSet: Iterable<number>,
): CanvasImage<NC> {
  const { width, height, channels } = srcImage
  const delSeq = getDeletionSeq(colSet)
  for(const [idx, num] of delSeq) {
    if(idx < 0 || idx >= width) {
      throw `Column index out of bounds: ${idx}, width=${width}`
    } else if(num <= 0) {
      throw `The number of columns to remove must be positive: got ${num}`
    } else if(idx + num > width) {
      throw `Cannot delete that many columns: from ${idx} to ${idx + num}: width=${width}`
    }
  }
  if(delSeq.length === 0) {
    throw 'The set of column deletions is empty'
  }
  // allocate new image
  const totalNumDel = delSeq.reduce((sum, [_, num]) => sum + num, 0)
  const newWidth = width - totalNumDel
  const newImage = createCanvasImage(newWidth, height, channels)
  if(newWidth === 0) {
    return newImage // nothing to copy
  }
  // columns before each deletion point
  let delShift = 0
  for(const [i, [currIdx]] of delSeq.entries()) {
    // update shift from previous accummulated deletions
    const [prevIdx, prevNum] = i > 0 ? delSeq[i - 1] : [0, 0]
    delShift += prevNum
    // copy old column to the left, up to the previous deletion end
    if(prevIdx + prevNum < currIdx) {
      const srcRectBefore = [
        prevIdx + prevNum, 0,
        currIdx, height,
      ] as const
      const trgRectBefore = [
        prevIdx + prevNum - delShift, 0,
        currIdx - delShift, height,
      ] as const
      if(!copyImageToImage(srcImage, srcRectBefore, newImage, trgRectBefore)) {
        console.error('Failed to copy source image to target image')
      }
    }
  }
  // columns after last deletion point
  const [lastIdx, lastNum] = delSeq[delSeq.length - 1]
  if(lastIdx + lastNum < width) {
    console.assert(
      totalNumDel === delShift + lastNum,
      'The accummulated column width does not match',
    )
    console.assert(
      lastIdx - delShift < newWidth,
      'The last column range information seems invalid',
      lastIdx,
      delShift,
      newWidth,
    )
    const srcRectAfter = [
      lastIdx + lastNum, 0,
      width, height,
    ] as const
    const trgRectAfter = [
      lastIdx - delShift, 0, // /!\ delShift does not contain lastNum
      newWidth, height,
    ] as const
    if(!copyImageToImage(srcImage, srcRectAfter, newImage, trgRectAfter)) {
      console.error('Failed to copy source image to target image')
    }
  }
  return newImage
}

/**
 * Delete a set of rows from an image
 *
 * @param srcImage the source image, untouched
 * @param rowSet a set of row indices (can be repeated)
 * @returns the updated image
 */
export function deleteRowSet<NC extends number>(
  srcImage: ReadonlyCanvasImage<NC>,
  rowSet: Iterable<number>,
): CanvasImage<NC> {
  const { width, height, channels } = srcImage
  const delSeq = getDeletionSeq(rowSet)
  for(const [idx, num] of delSeq) {
    if(idx < 0 || idx >= height) {
      throw `Row index out of bounds: ${idx}, height=${height}`
    } else if(num <= 0) {
      throw `The number of columns to remove must be positive: got ${num}`
    } else if(idx + num > height) {
      throw `Cannot delete that many columns: from ${idx} to ${idx + num}: height=${height}`
    }
  }
  if(delSeq.length === 0) {
    throw 'The set of column deletions is empty'
  }
  // allocate new image
  const totalNumDel = delSeq.reduce((sum, [_, num]) => sum + num, 0)
  const newHeight = height - totalNumDel
  const newImage = createCanvasImage(width, newHeight, channels)
  if(newHeight === 0) {
    return newImage // nothing to copy
  }
  // rows before each deletion point
  let delShift = 0
  for(const [i, [currIdx]] of delSeq.entries()) {
    // update shift from previous accummulated deletions
    const [prevIdx, prevNum] = i > 0 ? delSeq[i - 1] : [0, 0]
    delShift += prevNum
    // copy old column to the left, up to the previous deletion end
    if(prevIdx + prevNum < currIdx) {
      const srcRectBefore = [
        0, prevIdx + prevNum,
        width, currIdx,
      ] as const
      const trgRectBefore = [
        0, prevIdx + prevNum - delShift,
        width, currIdx - delShift,
      ] as const
      if(!copyImageToImage(srcImage, srcRectBefore, newImage, trgRectBefore)) {
        console.error('Failed to copy source image to target image')
      }
    }
  }
  // rows after last deletion point
  const [lastIdx, lastNum] = delSeq[delSeq.length - 1]
  if(lastIdx + lastNum < height) {
    console.assert(
      totalNumDel === delShift + lastNum,
      'The accummulated row height does not match',
    )
    console.assert(
      lastIdx - delShift < newHeight,
      'The last row range information seems invalid',
      lastIdx,
      delShift,
      newHeight,
    )
    const srcRectAfter = [
      0, lastIdx + lastNum,
      width, height,
    ] as const
    const trgRectAfter = [
      0, lastIdx - delShift, // /!\ delShift does not contain lastNum
      width, newHeight,
    ] as const
    if(!copyImageToImage(srcImage, srcRectAfter, newImage, trgRectAfter)) {
      console.error('Failed to copy source image to target image')
    }
  }
  return newImage
}
