// base on @see https://github.com/xionluhnis/knitsketching/blob/main/src/knitout/simulation.js

import {
  Bed, BedSide, bedSideOf, Direction, FRONT, FRONT_SLIDER, isSliderSide, issue, Issue, LEFT, Needle, PanelLocation, REAR, REAR_SLIDER, RIGHT, SliderSide, sliderSideOf,
} from './common';

// ###########################################################################
// ##### Loop ################################################################
// ###########################################################################

export type LoopAndNeedle = [Needle, Loop]

export enum LoopAction {
  NONE = 0,
  KNIT,
  TUCK,
  SPLIT,
}

export class Loop {
  private cs: string[] = []

  private par: LoopAndNeedle[] = []

  private prev: LoopAndNeedle[] = []

  private locked = false

  constructor(
    public readonly index: number,
    public readonly source: PanelLocation,
    public readonly action: LoopAction,
    public readonly srcNeedle: Needle,
  ) {}

  get carriers() { return this.cs.slice() }

  get numCarriers() { return this.cs.length }

  get parents() { return this.par.slice() }

  get numParents() { return this.par.length }

  get parentLoops() { return this.par.map(([, l]) => l) }

  get previous() { return this.prev.slice() }

  get numPrevious() { return this.prev.length }

  get previousLoops() { return this.prev.map(([, l]) => l) }

  lock() { this.locked = true }

  addCarrier(...cnames: string[]) {
    if(!this.locked) {
      this.cs.push(...cnames)
    }
  }

  setParents(...par: LoopAndNeedle[]) {
    if(!this.locked) {
      this.par = par
    }
  }

  addPrevious(...prev: LoopAndNeedle[]) {
    if(!this.locked) {
      this.prev.push(...prev)
    }
  }

  hasSource() {
    return Array.isArray(this.source) || typeof this.source === 'number'
  }
}

function offsetOf(arg: Needle | number) {
  if(arg instanceof Needle) {
    return arg.offset
  }
  return arg
}

function needleOf(arg: Needle | number, side: Bed) {
  if(arg instanceof Needle) {
    return arg
  }
  return new Needle(side, arg)
}

export type LoopPredicate = (l: Loop, bed: NeedleBed) => boolean
export type LoopGenerator = (src: PanelLocation, act: LoopAction, n: Needle) => Loop

export function indexedLoop(startId = 0): LoopGenerator {
  let i = startId
  return (src: PanelLocation, act: LoopAction, n: Needle) => new Loop(i++, src, act, n)
}

export function indexedLoopWithIndex(startId = 0): [LoopGenerator, Loop[]] {
  const index = new Array<Loop>()
  let i = startId
  return [
    (src: PanelLocation, act: LoopAction, n: Needle) => {
      const loop = new Loop(i++, src, act, n)
      index.push(loop)
      return loop
    },
    index,
  ]
}

// ###########################################################################
// ##### Needle bed ##########################################################
// ###########################################################################

export class NeedleBed {
  private offsetToLoops: Map<number, Loop[]>

  private loopToOffset: Map<Loop, number>

  constructor(
    public readonly side: Bed,
  ) {
    this.offsetToLoops = new Map()
    this.loopToOffset = new Map()
  }

  // tests

  matches(n: Needle) { return n.bed === this.side }

  isEmpty(idx?: number | Needle) {
    if(idx === undefined) {
      return this.offsetToLoops.size === 0
    } if(idx instanceof Needle) {
      return !this.offsetToLoops.has(idx.offset)
    }
    return !this.offsetToLoops.has(idx)
  }

  hasLoop(loop: Loop) { return this.loopToOffset.has(loop) }

  // generic getter / setters
  getLoops(idx: number | Needle) { return this.offsetToLoops.get(offsetOf(idx)) || [] }

  setLoops(idx: number | Needle, loops: Loop[]) {
    const offset = offsetOf(idx)

    // remove past loop information
    for(const loop of this.offsetToLoops.get(offset) || []) this.loopToOffset.delete(loop)

    // set new loop information
    this.offsetToLoops.set(offset, loops)
    for(const loop of loops) this.loopToOffset.set(loop, offset)
  }

  getOffset(loop: Loop) { return this.loopToOffset.get(loop) }

  getNeedle(loop: Loop) { return this.hasLoop(loop) ? new Needle(this.side, this.getOffset(loop)) : null }

  // semantic setters

  knit(idx: number | Needle, loop: Loop) {
    // get previous loop to store as parents of the new loop
    const loops = this.getLoops(idx)
    loop.setParents(...loops.map((l) => [needleOf(idx, this.side), l] as LoopAndNeedle))

    // set new loops on needle
    this.setLoops(idx, [loop])

    // return the past loops
    return loops
  }

  tuck(idx: number | Needle, firstLoop: Loop, ...remLoops: Loop[]) {
    const offset = offsetOf(idx);

    // add in offset mapping
    if(this.offsetToLoops.has(offset)) this.offsetToLoops.get(offset).push(firstLoop, ...remLoops)
    else this.offsetToLoops.set(offset, [firstLoop].concat(remLoops))

    // add in loop mapping
    this.loopToOffset.set(firstLoop, offset)
    for(const loop of remLoops) this.loopToOffset.set(loop, offset)
  }

  drop(idx: number | Needle) {
    const offset = offsetOf(idx)
    // retrieve loops
    const loops = this.offsetToLoops.get(offset) || []

    // remove from both mappings
    this.offsetToLoops.delete(offset)
    for(const loop of loops) this.loopToOffset.delete(loop)

    // return old loops
    return loops
  }

  copy() {
    const nb = new NeedleBed(this.side)
    for(const [offset, loops] of this.offsetToLoops) {
      if(loops.length === 0) {
        throw 'Empty list of loops should not exist'
      }
      nb.offsetToLoops.set(offset, loops.slice())
      for(const loop of loops) nb.loopToOffset.set(loop, offset)
    }
    return nb
  }

  offsetEntries() { return this.offsetToLoops.entries() }

  offsets() { return this.offsetToLoops.keys() }

  loops() { return this.loopToOffset.keys() }

  * needleEntries() {
    for(const [off, loops] of this.offsetEntries()) yield [new Needle(this.side, off), loops] as const
  }

  * needleKeys() {
    for(const off of this.offsets()) yield new Needle(this.side, off)
  }

  sortedNeedles(sortFunc: (a: Needle, b: Needle) => number) {
    return Array.from(this.needleKeys()).sort(sortFunc);
  }

  findLoop(predicate: (l: Loop, bed: NeedleBed) => boolean) {
    for(const loop of this.loops()) {
      if(predicate(loop, this)) return loop
    }
    return null
  }

  findLoopNeedle(predicate: LoopPredicate) {
    const loop = this.findLoop(predicate)
    return loop ? this.getNeedle(loop) : null
  }

  * filterLoopNeedles(predicate: LoopPredicate) {
    for(const loop of this.loops()) {
      if(predicate(loop, this)) yield this.getNeedle(loop)
    }
  }

  toString(min = Infinity, max = -Infinity) {
    if(!Number.isFinite(min) || !Number.isFinite(max)) {
      for(const offset of this.offsets()) {
        min = Math.min(min, offset);
        max = Math.max(max, offset);
      }
    }
    const cells = new Array<string>();
    for(let off = min; off <= max; ++off) {
      const loops = this.offsetToLoops.get(off) || []
      cells.push(loops.length ? 'o' : '-')
    }
    return cells.join('')
  }
}

// ###########################################################################
// ##### Carrier #############################################################
// ###########################################################################

export class YarnCarrier {
  constructor(
    public name: string,
    public machine: KnittingMachine,
    public inBed = false,
    public active = false,
    public released = false,
    public needle = new Needle(FRONT, -Infinity),
    public side: Direction = LEFT,
    public lastLoop: Loop = null,
  ) {}

  get delta() { return this.side === LEFT ? -1 : 1 }

  getLoopNeedle() {
    if(!this.lastLoop) {
      return null
    }
    return this.machine.getLoopNeedle(this.lastLoop)
  }

  conflictsDirectlyWith(
    n: Needle,
    racking = this.machine.racking,
    threshold = 10,
  ) {
    const delta = n.frontOffset(racking) - this.needle.frontOffset(racking);
    if(this.side === RIGHT) return delta > 0 && delta < threshold // within possible range

    return -threshold < delta && delta < 0 // within possible range
  }

  conflictsIndirectlyWith(
    n: Needle,
    racking = this.machine.racking,
    threshold = 10,
  ) {
    const ln = this.getLoopNeedle()
    if(!ln) return false

    // if n is between this carrier and the needle of the last loop
    // then we have an indirect conflict (yarn is in between)
    const loopOffset = ln.frontOffset(racking)
    const carrierOffset = this.needle.frontOffset(racking) + this.delta * threshold
    const nOffset = n.frontOffset(racking)

    // note: if at loop needle, we do not consider as conflict
    return (nOffset - carrierOffset) * (nOffset - loopOffset) < 0;
  }

  conflictsWith(
    n: Needle,
    racking = this.machine.racking,
    threshold = 10,
  ) {
    return (
      this.conflictsDirectlyWith(n, racking, threshold) ||
      this.conflictsIndirectlyWith(n, racking)
    )
  }

  copy(machine: KnittingMachine) {
    return new YarnCarrier(
      this.name,
      machine,
      this.inBed,
      this.active,
      this.released,
      this.needle,
      this.side,
      this.lastLoop,
    )
  }

  insert() {
    this.inBed = true
    return this
  }

  activate() {
    this.active = true
    return this
  }

  release() {
    this.released = true
    return this
  }

  remove() {
    this.inBed = false
    this.active = false
    this.released = false
    return this
  }

  atNeedleSide(
    needle: Needle,
    side = this.side,
    loop: Loop = null,
  ) {
    this.active = true
    const lastNeedle = this.needle
    this.needle = needle
    this.side = side
    if(loop) {
      if(this.lastLoop) loop.addPrevious([lastNeedle, this.lastLoop])
      loop.addCarrier(this.name)
      this.lastLoop = loop;
    }
    return this
  }
}

// ###########################################################################
// ##### Knitting machine ####################################################
// ###########################################################################

export interface KnittingMachineOptions {
  hasSliders: boolean
  numCarriers: number
}

const KniterateOptions: KnittingMachineOptions = {
  hasSliders: false,
  numCarriers: 6,
}

const HookSides = [FRONT, REAR] as BedSide[]
const SliderSides = [FRONT_SLIDER, REAR_SLIDER] as SliderSide[]
const AllSides = (HookSides as Bed[]).concat(SliderSides)

export type CarrierMapping<R> = (cs: YarnCarrier) => R
export type CarrierPredicate = CarrierMapping<boolean>

export class KnittingMachine {
  public beds: Map<Bed, NeedleBed>

  public carriers: Map<string, YarnCarrier>

  public lastCarriers: string[] = []

  public racking = 0

  public issues: Issue[] = []

  public source: PanelLocation

  public firstLoop: Loop = null

  public lastLoop: Loop = null

  constructor(
    public readonly live: boolean = false,
    private loopProvider: LoopGenerator = indexedLoop(0),
    protected options: KnittingMachineOptions = KniterateOptions,
  ) {
    this.beds = new Map((options.hasSliders ? AllSides : HookSides).map((side) => [side, new NeedleBed(side)]))
    this.carriers = new Map(
      Array.from({ length: options.numCarriers }, (_, i) => [(i + 1).toString(), new YarnCarrier((i + 1).toString(), this)]),
    )
  }

  isLive() { return this.live }

  protected copyTo(machine: KnittingMachine) {
    // copy beds
    for(const [side, bed] of this.beds) {
      machine.beds.set(side, bed.copy())
    }

    // copy carriers
    for(const [cname, cs] of this.carriers) {
      machine.carriers.set(cname, cs.copy(machine))
    }
    machine.lastCarriers = this.lastCarriers.slice()

    // copy racking
    machine.racking = this.racking

    // copy loop information
    machine.firstLoop = this.firstLoop
    machine.lastLoop = this.lastLoop
  }

  copy() {
    const machine = new KnittingMachine(false, () => {
      throw 'Can only generate loop in live machine'
    }, this.options)
    // transfer information to copy
    this.copyTo(machine)
    // return copy
    return machine
  }

  relive(loopProvider: LoopGenerator) {
    const machine = new KnittingMachine(true, loopProvider, this.options)
    // transfer information to copy
    this.copyTo(machine)
    // return copy
    return machine
  }

  popIssues() {
    const issues = this.issues
    this.issues = []
    return issues
  }

  clearLoopRange() {
    this.firstLoop = null
    this.lastLoop = null
  }

  asCopy() {
    if(this.isLive()) return this.copy()
    return this
  }

  getBed(side: Bed | Needle) {
    if(side instanceof Needle) {
      side = side.bed
    }
    if(!this.beds.has(side)) {
      throw `Machine does not have side ${side}`
    }
    return this.beds.get(side)
  }

  getHookBed(side: Bed) { return this.getBed(isSliderSide(side) ? bedSideOf(side) : side) }

  getSliderBed(side: Bed) { return this.getBed(isSliderSide(side) ? side : sliderSideOf(side)) }

  hookBeds() { return HookSides.map((side) => this.getBed(side)) }

  sliderBeds() { return SliderSides.map((side) => this.getBed(side)) }

  getNeedleLoops(n: Needle) { return this.beds.get(n.bed).getLoops(n.offset) }

  isEmpty(n?: Needle) {
    if(n) {
      return this.beds.get(n.bed).isEmpty(n.offset)
    }
    for(const bed of this.beds.values()) {
      if(!bed.isEmpty()) {
        return false
      }
    }
    // all beds are empty
    return true
  }

  hasPendingSliders() {
    for(const nb of this.sliderBeds()) {
      if(!nb.isEmpty()) return true
    }
    return false
  }

  findLoop(predicate: LoopPredicate) {
    for(const nb of this.beds.values()) {
      const loop = nb.findLoop(predicate)
      if(loop !== null) return [nb, loop]
    }
    return null
  }

  findLoopNeedle(predicate: LoopPredicate) {
    for(const nb of this.beds.values()) {
      const n = nb.findLoopNeedle(predicate)
      if(n) return n
    }
    return null
  }

  * filterLoopNeedles(predicate: LoopPredicate) {
    for(const nb of this.beds.values()) yield* nb.filterLoopNeedles(predicate)
  }

  hasLoop(loop: Loop) {
    for(const nb of this.beds.values()) {
      if(nb.hasLoop(loop)) return true
    }
    return false;
  }

  getLoopNeedle(loop: Loop) {
    for(const [side, nb] of this.beds) {
      if(nb.hasLoop(loop)) return new Needle(side, nb.getOffset(loop))
    }
    return null
  }

  * allCarriers() { yield* this.carriers.values() }

  * activeCarriers() { yield* this.filterCarriers((c) => c.active) }

  * filterCarriers(pred: CarrierPredicate) {
    for(const carrier of this.carriers.values()) {
      if(pred(carrier)) yield carrier
    }
  }

  * needles() {
    for(const nb of this.beds.values()) yield* nb.needleKeys()
  }

  * needleEntries() {
    for(const nb of this.beds.values()) yield* nb.needleEntries()
  }

  mapCarrier<R>(name: string, cFunc: CarrierMapping<R>, defResult?: R) {
    const carrier = this.carriers.get(name)
    if(carrier) {
      return cFunc(carrier) ?? defResult
    }
    this.assert(false, `Invalid carrier "${name}"`)
    return defResult
  }

  mapCarriers<R>(names: string[], cFunc: CarrierMapping<R>, defResult?: R) {
    return names.map((name) => this.mapCarrier(name, cFunc, defResult))
  }

  isCarrierActive(name: string) { return this.mapCarrier(name, (c) => c.active, false) }

  isCarrierReleased(name: string) { return this.mapCarrier(name, (c) => c.released, false) }

  isCarrierInBed(name: string) { return this.mapCarrier(name, (c) => c.inBed, false) }

  getCarrierConflicts(n: Needle) {
    const cs = new Array<string>()
    for(const [cname, carrier] of this.carriers) {
      if(carrier.conflictsWith(n, this.racking)) cs.push(cname)
    }
    return cs
  }

  hasCarrierConflict(n: Needle) {
    for(const carrier of this.carriers.values()) {
      if(carrier.conflictsWith(n, this.racking)) return true
    }
    return false
  }

  toStrings(min = Infinity, max = -Infinity) {
    if(!Number.isFinite(min) || !Number.isFinite(max)) {
      for(const nb of this.beds.values()) {
        for(const offset of nb.offsets()) {
          min = Math.min(min, offset);
          max = Math.max(max, offset);
        }
      }
    }
    return (this.beds.size === 2 ? ['b', 'f'] : ['b', 'bs', 'fs', 'f']).map((s: Bed) => this.beds.get(s).toString(min, max))
  }

  toString(compact = false) { return compact ? this.toCompactString() : this.toStrings().join('\n') }

  toCompactString() {
    if(this.beds.size === 2) {
      const [b, f] = this.toStrings()
      return Array.from(b, (bc, i) => {
        const fc = f[i]
        const hasF = fc !== '-'
        const hasB = bc !== '-'
        if(hasF && hasB) {
          return 'o'
        } if(hasF) {
          return 'v'
        } if(hasB) {
          return '^'
        }
        return '-'
      }).join('')
    }
  }

  assert(test: boolean, message: string) {
    if(!test) {
      this.issues.push(
        issue('simulation').invalid(message).at(this.source),
      )
    }
    return this
  }

  in(...cs: string[]) {
    this.lastCarriers = cs.slice()
    this.mapCarriers(cs, (c) => {
      this.assert(!c.inBed && !c.released, `Carrier ${c.name} is already on the bed`)
      c.insert().release()
    })
    return this
  }

  inhook(...cs: string[]) {
    this.lastCarriers = cs.slice()
    this.mapCarriers(cs, (c) => {
      this.assert(!c.inBed, `Carrier ${c.name} is already on the bed`)
      c.insert()
    })
    return this
  }

  releasehook(...cs: string[]) {
    this.lastCarriers = cs.slice()
    this.mapCarriers(cs, (c) => {
      this.assert(!c.released, `Carrier ${c.name} is already released`)
      c.release()
    })
    return this
  }

  outhook(...cs: string[]) {
    // like out, but require that the carrier has been released from the hook
    this.mapCarriers(cs, (c) => {
      this.assert(c.released, `Carrier ${c.name} should be released before bringing back to hook`)
    })
    return this.out(...cs)
  }

  out(...cs: string[]) {
    this.lastCarriers = cs.slice()
    this.mapCarriers(cs, (c) => {
      this.assert(c.inBed, `Carrier ${c.name} is not yet on the bed`)
      c.remove()
    })
    return this
  }

  rack(racking: number) {
    this.racking = racking
    return this
  }

  // knit d n cs
  knit(dir: Direction, n: Needle, ...cs: string[]) {
    this.assert(cs.length > 0, 'Knit without carrier should be a drop')
    return this.loop(LoopAction.KNIT, dir, n, ...cs)
  }

  // tuck d n cs
  tuck(dir: Direction, n: Needle, ...cs: string[]) {
    this.assert(cs.length > 0, 'Tuck without carrier')
    return this.loop(LoopAction.TUCK, dir, n, ...cs)
  }

  protected loop(
    type: LoopAction.KNIT | LoopAction.TUCK,
    dir: Direction,
    n: Needle,
    ...cs: string[]
  ) {
    const nb = this.getBed(n)
    const loop = this.loopProvider(this.source, type, n)
    if(type === LoopAction.KNIT) {
      nb.knit(n, loop)
    } else if(type === LoopAction.TUCK) {
      nb.tuck(n, loop)
    } else {
      this.assert(false, `Invalid loop type ${type}`)
    }
    // update carriers
    this.lastCarriers = cs.slice()
    this.mapCarriers(cs, (c) => c.atNeedleSide(n, dir, loop))
    // memorize first / last
    if(!this.firstLoop) this.firstLoop = loop
    this.lastLoop = loop
    return this
  }

  // drop n
  drop(n: Needle) {
    const nb = this.getBed(n)
    nb.drop(n)
    return this
  }

  // xfer n n2
  xfer(sn: Needle, tn: Needle) {
    // note: the direction doesn't get used since there is no carrier
    return this.xsplit(LoopAction.NONE, RIGHT, sn, tn)
  }

  // split d n n2 cs
  split(dir: Direction, sn: Needle, tn: Needle, ...cs: string[]) {
    this.assert(cs.length > 0, 'Split without carrier should be a transfer')
    return this.xsplit(LoopAction.SPLIT, dir, sn, tn, ...cs)
  }

  protected xsplit(type: LoopAction.NONE | LoopAction.SPLIT, dir: Direction, sn: Needle, tn: Needle, ...cs: string[]) {
    this.assert(
      sn.frontOffset(this.racking) === tn.frontOffset(this.racking),
      `Transfer needle offsets do not match: sn=${sn.toString()} tn=${tn.toString()}`,
    )
    this.assert(sn.side !== tn.side, 'Transfers should happen across bed sides')
    const snb = this.getBed(sn)
    const tnb = this.getBed(tn)

    // knit or drop on source
    const isSplit = type === LoopAction.SPLIT
    const loop = isSplit ? this.loopProvider(this.source, LoopAction.SPLIT, sn) : void 0
    const prevLoops = isSplit ? snb.knit(sn, loop) : snb.drop(sn)

    // move old loops to target (if any)
    if(prevLoops.length > 0) {
      const [l0, ...ltail] = prevLoops
      tnb.tuck(tn, l0, ...ltail)
    }

    // prepare carrier updates
    const updates = [] as [string, Needle, Direction][]
    for(const [name, c] of this.carriers) {
      if(cs.includes(name)) {
        updates.push([name, sn, dir]) // in cs => always update
      } else if(c.needle.matches(sn)) {
        updates.push([name, tn, c.side]) // not in cs, but matching n
      }
    }
    // apply carrier updates
    for(const [name, n, d] of updates) {
      this.mapCarrier(name, (c) => c.atNeedleSide(n, d, loop))
    }

    // memorize first / last loop / last carriers
    if(isSplit) {
      this.lastCarriers = cs.slice()
      if(!this.firstLoop) this.firstLoop = loop
      this.lastLoop = loop
    }
    return this
  }

  miss(dir: Direction, n: Needle, ...cs: string[]) {
    // XXX this currently removes the needle information so there is no valid anchor data
    // just update carriers, there is no new loop or needle action
    this.mapCarriers(cs, (c) => c.atNeedleSide(n, dir))
    return this
  }
}
