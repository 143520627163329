import { Select } from 'src/editor/time-needle/action'
import { deferAction, setActionHandler } from 'src/editor/time-needle/slice'
import { duplicate } from 'src/macros'
import {
  useAppDispatch, useAppSelector, useRawMacro, useUserEntry,
} from 'src/hooks'
import { extractTimeNeedleRegion } from 'src/data/time-needle/region'
import { TimeNeedleSelector } from 'src/macros/common'
import ContextMenuItem from './menu-item'

export default function TileItems() {
  const dispatch = useAppDispatch()
  const sel = useAppSelector((state) => state.canvas.selection)
  const tnimage = useAppSelector((state) => state.canvas.tnimage)
  const rawMacro = useRawMacro()
  const [duplicateDefault, setDuplicateDefault] = useUserEntry('duplicate', '1 above')
  const [tilingDefault, setTilingDefault] = useUserEntry('tiling', '2 by 2')
  return (
    <>
      <ContextMenuItem
        name="Duplicate rows"
        prompt={['Copies (e.g., 2 above, 3 below', duplicateDefault]}
        onInput={(repeatStr) => {
          const after = !repeatStr.includes('below')
          const repeat = parseInt(repeatStr, 10)
          if(isNaN(repeat) || repeat <= 0) {
            return
          }
          setDuplicateDefault(repeatStr)
          rawMacro((tni, sel) => duplicate(
            TimeNeedleSelector.fromRegion(tni, sel),
            repeat,
            after,
          ))
        }}
      />
      <ContextMenuItem
        name="Tile selection"
        prompt={['Repeat x and y (e.g, 1 by 2)', tilingDefault]}
        onInput={(repeatStr) => {
          const tile = repeatStr.split('by')
          if(tile.length !== 2) {
            alert('Invalid input, should be: x by y')
            return
          }
          const tileX = parseInt(tile[0], 10)
          const tileY = parseInt(tile[1], 10)
          if(!(tileX >= 1 && tileY >= 1)) {
            return
          }
          setTilingDefault(repeatStr)
          const tiles = extractTimeNeedleRegion(tnimage, sel, void 0, tileX, tileY)

          // go into select mode and trigger paste preview
          const [select, pastePreview] = Select.withPaste(tiles)
          dispatch(setActionHandler(select))
          dispatch(deferAction(pastePreview))
        }}
      />
    </>
  )
}
