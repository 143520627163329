import { KEYCODE_R } from 'src/common/keyboard'
import { ChannelCount, RegionDataFunc } from 'src/common/webgl/texture'
import { addUndoableAction, UndoableAction, UndoDomain } from 'src/undo'
import { RootState } from 'src/store'
import { copyTimeNeedleImage } from 'src/data/time-needle/time-needle-image'
import { ReadonlyRectBuffer, RectBuffer, replaceRectBuffer } from 'src/data/image'
import { ActionContext } from './common'
import IndirectDrawAction from './idraw'
import { registerShortcut } from './base'

export default class Rect extends IndirectDrawAction {
  preview(ctx: ActionContext) {
    const { updatePreviewData } = ctx
    const buffer = this.getRect(ctx, this.startCoord, this.drawCoord)
    // update preview
    updatePreviewData(textureRectUpdate(buffer))
  }

  commit(ctx: ActionContext) {
    const { dispatch } = ctx
    const buffer = this.getRect(ctx, this.startCoord, this.drawCoord)
    // trigger undoable rect action
    dispatch(addUndoableAction(new UndoableRectAction(buffer)))
  }
}
registerShortcut(KEYCODE_R, Rect)

/**
 * Texture update with a rectangular buffer
 *
 * @param buffer the rectangular buffer
 * @returns the texture update function
 */
export function textureRectUpdate<NC extends ChannelCount>(
  buffer: RectBuffer<NC>,
) {
  return (updateRegion: RegionDataFunc) => {
    updateRegion(buffer)
  }
}

/**
 * Undoable action that updates the time-needle image's code
 * with a given rectangular buffer.
 */
export class UndoableRectAction
implements UndoableAction {
  readonly domain = UndoDomain.EDITOR

  constructor(
    protected readonly buffer: ReadonlyRectBuffer<1>,
  ) {}

  applyTo(state: RootState): [RootState, UndoableAction] {
    const tnimage = copyTimeNeedleImage(state.canvas.tnimage)
    const undoBuffer = replaceRectBuffer(tnimage.cdata, this.buffer)
    const undo = new UndoableRectAction(undoBuffer)
    return [
      {
        ...state,
        canvas: {
          ...state.canvas,
          tnimage,
        },
      },
      undo,
    ]
  }
}
