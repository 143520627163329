import {
  CanvasImage,
  copyCanvasImage,
  createCanvasImage,
  deleteColumnSet,
  getPixelChannel,
  insertColumnSet,
  ReadonlyCanvasImage,
  setPixelChannel,
} from 'src/data/image';
import { addRowsAboveTop } from 'src/data/time-needle/topology';
import TimeNeedleTransformer, { SelectorLike } from 'src/data/time-needle/transformer'
import { isYarnIndex, parseUserOptions, YarnIndex } from './common';

export enum Algorithm {
  MINIMAL = 0,
  FULL,
}

export enum Alignment {
  LEFT = 0,
  CENTER,
  RIGHT,
}

export enum Layering {
  NONE = 0,
  LOCAL,
  GLOBAL,
}

export type YarnPreferences = [YarnIndex, YarnIndex, YarnIndex, ...YarnIndex[]]

export interface BirdseyeOptions {
  algorithm: Algorithm
  alignment: Alignment
  twoColorInvert: boolean
  expandBorders?: boolean
  closeBorders?: boolean
  keepYarnOrder?: boolean
  yarnPreference?: YarnPreferences
  yarnOrder?: YarnIndex[]
  inline?: boolean
  fullNeedle?: boolean
  layering?: Layering
}

export function parseBirdseyeOptions(input: string): BirdseyeOptions | string {
  const opts: BirdseyeOptions = {
    algorithm: Algorithm.FULL,
    alignment: Alignment.LEFT,
    twoColorInvert: false,
    expandBorders: false,
    closeBorders: false,
    keepYarnOrder: true,
    yarnPreference: [3, 4, 2],
    inline: false,
    fullNeedle: false,
    layering: Layering.NONE,
  }
  const yarns = [] as YarnIndex[]
  const err = parseUserOptions(input, {
    'invert-two': () => { opts.twoColorInvert = true },
    close: () => { opts.closeBorders = true },
    expand: () => { opts.expandBorders = true },
    inline: () => { opts.inline = true },
    unordered: () => { opts.keepYarnOrder = false },
    'full-needle': () => { opts.fullNeedle = true },
    layered: () => {
      opts.layering = Layering.GLOBAL
      opts.closeBorders = true
    },
  }, {
    align: (token: string) => {
      if(token.toUpperCase() in Alignment) {
        opts.alignment = Alignment[token.toUpperCase()]
      } else {
        return `Invalid alignment ${token}`
      }
    },
    algo: (token: string) => {
      if(token.toUpperCase() in Algorithm) {
        opts.algorithm = Algorithm[token.toUpperCase()]
      } else {
        return `Invalid algorithm ${token}`
      }
    },
    yarns: (token: string) => {
      const yarn = parseInt(token, 10)
      if(isYarnIndex(yarn)) {
        if(yarns.includes(yarn)) {
          return `Yarn ${yarn} used multiple times`
        }
        yarns.push(yarn)
      } else {
        return `Invalid yarn ${token}`
      }
    },
    order: (token: string) => {
      const yarn = parseInt(token, 10)
      if(isYarnIndex(yarn)) {
        if(opts.yarnOrder) {
          opts.yarnOrder.push(yarn)
        } else {
          opts.yarnOrder = [yarn]
        }
      } else {
        return `Invalid yarn ${token}`
      }
    },
    layered: (token: string) => {
      switch(token.toLowerCase()) {
      case 'local': opts.layering = Layering.LOCAL; break
      case 'global': opts.layering = Layering.GLOBAL; break
      default: return `Invalid layering ${token}`
      }
    },
  })
  if(err) { return err }
  // if the yarn order is provided but not the yarn preference
  if(yarns.length === 0 && opts.yarnOrder?.length) {
    // use yarn order to populate yarn preference
    yarns.push(...opts.yarnOrder)
  }
  const baseYarns = [3, 4, 2] as YarnIndex[]
  while(yarns.length < 3) {
    const yarn = baseYarns.shift()
    if(!yarns.includes(yarn)) {
      yarns.push(yarn)
    }
  }
  console.assert(
    yarns.length === 3,
    'Could not resolve the yarn preference',
  )
  // we know that there are at least 3 components
  opts.yarnPreference = yarns as YarnPreferences
  return opts
}

/**
 * Compute the birdseye backing image corresponding to a front image
 *
 * @param front the front image
 * @param opts the birdseye options
 * @returns the rear image
 */
export function birdseyeBacking(
  front: ReadonlyCanvasImage<1>,
  {
    algorithm,
    twoColorInvert,
    fullNeedle = false,
    keepYarnOrder = false,
    yarnPreference = [3, 4, 2],
  }: BirdseyeOptions,
): CanvasImage<1> {
  const { width, height } = front
  if(width * height === 0) {
    return createCanvasImage(0, 0, 1)
  }

  // step 1 = compute ordered color set

  // colors sorted by frequency from least frequent to most frequent
  const alloverColors = front.data.reduce<number[]>((numFYs, yarn) => {
    if(!isYarnIndex(yarn)) {
      throw `Invalid front image with non-yarn value ${yarn}`
    } else {
      numFYs[yarn - 1] += 1
    }
    return numFYs
  }, [0, 0, 0, 0, 0, 0])
    .map((v, i) => [v, i + 1])
    .filter((a) => a[0] > 0)
    .sort((a, b) => a[0] - b[0])
    .map((a) => a[1] as YarnIndex)

  // order according to preference
  if(keepYarnOrder) {
    const yarnRank = Array.from({ length: 6 }, (_, y) => {
      const idx = yarnPreference.indexOf(y as YarnIndex)
      return idx === -1 ? 6 : idx
    })
    alloverColors.sort((a, b) => yarnRank[a] - yarnRank[b])
  }

  // add user preference of yarns if necessary, to get at least 3 colors in the pattern
  if(algorithm === Algorithm.FULL) {
    for(const col of yarnPreference) {
      if(alloverColors.length < 3 && !alloverColors.includes(col)) alloverColors.push(col)
    }
  }

  // step 2 = color collection per row

  // compute the front yarns at each row
  const rowFrontColors = Array.from({ length: height }, (_, row) => new Set(front.data.subarray(row * width, (row + 1) * width)) as Set<YarnIndex>)

  // gather colors used in each row and augment as necessary depending on algorithm
  const additionalYarns = alloverColors.concat(yarnPreference.filter((y) => !alloverColors.includes(y)))
  const rowColors = rowFrontColors.map((colSet) => {
    const list = [...colSet]
    if(colSet.size <= 2 && twoColorInvert) {
      return list.sort() // just keep as-is
    }
    // we must ensure we have at least three colors
    for(const col of additionalYarns) {
      if(list.length < 3 && !colSet.has(col)) {
        list.push(col)
      }
    }
    return list.sort()
  })
  // console.log(`allover yarns: ${alloverColors} | additional yarns: ${additionalYarns}`)

  // step 3 = generate backing
  const back = createCanvasImage(width, height, 1)
  let perm = 0 // index for color permutation in the back
  for(let row = 0; row < height; ++row) {
    // update per-row color information
    const colors = (
      algorithm === Algorithm.MINIMAL ||
      (rowColors[row].length <= 2 && twoColorInvert)
    ) ? rowColors[row] : alloverColors

    // update per-row permutation
    perm = (perm + 1) % Math.max(2, colors.length)

    // actual backing
    for(let col = 0; col < width; ++col) {
      // The multi-color behaviour
      const fyarn = getPixelChannel(front, [col, row], 0) as YarnIndex
      let ryarn: YarnIndex
      if(twoColorInvert && colors.length <= 2) {
        // 2-colors case
        if(colors.length == 1) {
          ryarn = alloverColors[alloverColors[0] === fyarn ? 1 : 0] // pick second yarn from set of all
        } else {
          console.assert(colors.length === 2, 'Color count does not match', colors.length)
          ryarn = colors[colors[0] === fyarn ? 1 : 0] // use non-front yarn for the back
        }
      } else {
        // multiple colors that use tiling with some permutation
        ryarn = colors[(col + perm) % colors.length]

        // special case when matching the front yarn
        // = instead of using the same color in frnt and rear, permute one further for the rear
        if(ryarn === fyarn && !fullNeedle) {
          ryarn = colors[(col + 1 + perm) % colors.length]
        }
      }
      setPixelChannel(back, [col, row], 0, ryarn)
    }
  }
  return back
}

/**
 * Extract the yarns used for a row
 *
 * @param front the front image
 * @param back the rear image
 * @param row the row of interest
 * @returns the set of yarns
 */
function getRowYarns(
  front: ReadonlyCanvasImage<1>,
  back: ReadonlyCanvasImage<1>,
  row: number,
): Set<YarnIndex> {
  const { width, height } = front
  if(!(row >= 0 && row < height)) {
    throw `Row is out of bounds: ${row}, height=${height}`
  }
  const fyarns = new Set(front.data.subarray(row * width, (row + 1) * width))
  const ryarns = new Set(back.data.subarray(row * width, (row + 1) * width))
  const yarns = new Set([...fyarns, ...ryarns])
  // we validate that all yarns are valid yarn indices
  for(const yarn of yarns) {
    if(!isYarnIndex(yarn)) {
      throw `Invalid yarn index ${yarn}`
    }
  }
  return yarns as Set<YarnIndex>
}

/**
 * Update a front/back Jacquard design to close borders
 *
 * @param front the front image
 * @param back the rear image
 * @param yarnPref the yarn preference
 * @param layering the type of yarn layering to enforce
 */
export function makeBordersClosed(
  front: CanvasImage<1>,
  back: CanvasImage<1>,
  yarnPref: YarnPreferences,
  layering = Layering.NONE,
) {
  const { width, height } = front
  if(back.width !== width || back.height !== height) {
    throw `The front and back images have difference sizes: front=(${width} by ${height}), rear=(${back.width} by ${back.height})`
  }
  if(width < 4) {
    throw 'Border closing does not make sense when the width is smaller than 4 stitches'
  }
  // possibly update left + right borders
  for(let row = 0; row < height; ++row) {
    const rowYarns = getRowYarns(front, back, row)
    const rowStart = row * width
    const rowEnd = (row + 1) * width
    for(const [outerIdx, innerIdx] of [
      [rowStart, rowStart + 1], // left border
      [rowEnd - 1, rowEnd - 2], // right border
    ] as const) {
      // gather yarn on borders
      const fyo = getPixelChannel(front, outerIdx, 0) // front outer
      const fyi = getPixelChannel(front, innerIdx, 0) // front inner
      const ryo = getPixelChannel(back, outerIdx, 0) // back outer
      const ryi = getPixelChannel(back, innerIdx, 0) // back inner

      // layered mode
      if(layering !== Layering.NONE) {
        // layered enforcement = edge carrier must be layered
        const yarns = (
          layering === Layering.GLOBAL ?
            [...rowYarns] :
            [fyo, fyi, ryo, ryi]
        )
        const fyarno = Math.min(...yarns) as YarnIndex
        const ryarno = Math.max(...yarns) as YarnIndex
        if(fyo !== fyarno) setPixelChannel(front, outerIdx, 0, fyarno)
        if(ryo !== ryarno) setPixelChannel(back, outerIdx, 0, ryarno)

        // closed => enforce criss-crossing at inner location
        const fyarni = [fyi, fyo, ryo, ryi, ...yarnPref].find((y) => y !== fyarno)
        const ryarni = [ryi, ryo, fyo, fyi, ...yarnPref].find((y) => y !== ryarno)
        if(fyi !== fyarni) setPixelChannel(front, innerIdx, 0, fyarni)
        if(ryi !== ryarni) setPixelChannel(back, innerIdx, 0, ryarni)
      } else {
        // default closing = flip outer yarn to differ from inner one
        const fyarn = [fyo, ryi, ...yarnPref].find((y) => y !== fyi)
        const ryarn = [ryo, fyi, ...yarnPref].find((y) => y !== ryi)
        if(fyo !== fyarn) setPixelChannel(front, outerIdx, 0, fyarn)
        if(ryo !== ryarn) setPixelChannel(back, outerIdx, 0, ryarn)
      }
    }
  }
}

// ### legacy comment ###
// birdseye creates a back pattern for a given front
// it carries the active front yarns around in the back, and connects the front and rear.
// for rows using more than 2 yarns, it forces the compiler to knit the pattern in an order such that
// "locking" rows (those that knit the leftmost or rightmost two stitches) happen as the last two rows of any pass,
// thus locking the previous yarns under the outermost stitches, and binding the edges of the piece together.
// ######################

/**
 * Apply a birdseye block
 *
 * @param s the selector to apply the block above
 * @param opts the birdseye options
 * @param front the front image
 * @param issues an optional list to gather issues
 * @returns the resulting transformer
 */
export function birdseyeBlock(
  s: SelectorLike,
  opts: BirdseyeOptions,
  front: ReadonlyCanvasImage<1>,
  issues?: string[],
): TimeNeedleTransformer {
  const {
    alignment,
    closeBorders,
    expandBorders,
    inline,
    layering,
    yarnPreference = [3, 4, 2],
    yarnOrder,
  } = opts
  if(!issues) {
    issues = []
  }

  // 1 = get backing and expand or crop as necessary
  let back = birdseyeBacking(front, opts)

  // 2 = expand backing / crop as necessary
  const { height } = front
  const {
    left: selLeft, right: selRight, width: selWidth, top: selTop,
  } = s.getExtents()
  let left: number
  let right: number
  if(front.width === selWidth) {
    // use image directly
    left = selLeft
    right = selRight
    // use front/back as is
  } else if(front.width < selWidth) {
    // either expand or use alignment
    if(expandBorders) {
      left = selLeft
      right = selRight
      // update front + rear images
      const colSet = new Array<readonly [number, number]>()
      if(alignment === Alignment.LEFT) {
        colSet.push([front.width, selWidth - front.width])
      } else if(alignment === Alignment.RIGHT || selWidth - front.width === 1) {
        colSet.push([0, selWidth - front.width])
      } else {
        const leftPad = Math.floor((selWidth - front.width) / 2)
        const rightPad = selWidth - front.width - leftPad
        colSet.push(
          [0, leftPad],
          [front.width, rightPad],
        )
      }
      const newFront = insertColumnSet(front, colSet)
      const newBack = insertColumnSet(front, colSet)
      // fill borders
      for(let y = 0; y < height; ++y) {
        let srcX = 0
        for(let x = 0; x < selWidth; ++x) {
          if(getPixelChannel(front, [x, y], 0) === 0) {
            // needs to be filled
            const frontFill = getPixelChannel(front, [srcX, y], 0)
            setPixelChannel(newFront, [x, y], 0, frontFill)
            const backFill = getPixelChannel(back, [srcX, y], 0)
            setPixelChannel(newBack, [x, y], 0, backFill)
          } else if(srcX === 0) {
            // passed the left => the filling source is on the right
            srcX = front.width - 1
          }
        }
      }
      // swap images
      front = newFront
      back = newBack
    } else if(alignment === Alignment.LEFT) {
      left = selLeft
      right = left + front.width
    } else if(alignment === Alignment.CENTER) {
      left = selLeft + Math.floor((selWidth - front.width) / 2)
      right = left + front.width
    }
  } else {
    // front.width > selWidth
    // => we crop the image
    left = selLeft
    right = selRight
    // update front + rear images
    let colSet: number[]
    if(alignment === Alignment.LEFT) {
      colSet = Array.from({ length: front.width - selWidth }, (_, i) => front.width - 1 - i)
    } else if(alignment === Alignment.RIGHT || selWidth - front.width === 1) {
      colSet = Array.from({ length: front.width - selWidth }, (_, i) => i)
    } else {
      const leftCut = Math.floor((front.width - selWidth) / 2)
      const rightCut = front.width - selWidth - leftCut
      colSet.push(
        ...Array.from({ length: leftCut}, (_, i) => front.width - 1 - i),
        ...Array.from({ length: rightCut }, (_, i) => i),
      )
    }
    front = deleteColumnSet(front, colSet)
    back = deleteColumnSet(back, colSet)
  }

  // 3 = closing of borders
  if(closeBorders) {
    const editableFront = copyCanvasImage(front)
    makeBordersClosed(editableFront, back, yarnPreference, layering)
    front = editableFront
  }

  // gather yarns per row
  const yarnsPerRow = Array.from({ length: height }, (_, row) => getRowYarns(front, back, row))

  // 4 = compute the carrier pass sequence
  const passes = new Array<{ row: number, yarn: YarnIndex }>()
  if(yarnOrder) {
    // complete yarn order in case it is not complete
    const orderedYarns = new Set(yarnOrder)
    for(const yarns of yarnsPerRow) {
      for(const yarn of yarns) {
        if(!orderedYarns.has(yarn)) {
          yarnOrder.push(yarn)
          orderedYarns.add(yarn)
          issues.push(`Yarn order is incomplete: missing yarn ${yarn}`)
        }
      }
    }

    // while following the yarn sequence as many times as needed
    // we go over the yarns of each row and register their passes
    let yarnIdx = 0 // of yarnOrder
    for(const [row, yarns] of yarnsPerRow.entries()) {
      // given a row, take care of each yarn while using
      // the continuous yarn sequence to pick the yarn order
      //
      // /!\ note: the loop below will terminate because
      // we made sure `yarnOrder` contains all yarns of each row
      // => yarns.delete will eventually make yarns empty
      while(yarns.size > 0) {
        const yarn = yarnOrder[yarnIdx++]
        if(yarns.has(yarn)) {
          yarns.delete(yarn)
          // trigger yarn pass
          passes.push({ row, yarn })
        }
      }
    }
  } else {
    for(const [row, yarns] of yarnsPerRow.entries()) {
      // generate necessary carrier passes
      // XXX the last two passes should be the ones that reach the bordermost needles (front + rear)
      // => the direction matters for the edges
      passes.push(...[...yarns].map((yarn) => ({ row, yarn })))
    }
  }

  // 5 = insert rows for the necessary passes
  let newRows: TimeNeedleTransformer
  if(!inline) {
    // default insertion (inline=false)
    [, newRows] = addRowsAboveTop(s, passes.length)
  } else if(selTop + passes.length <= s.height) {
    newRows = TimeNeedleTransformer.fromSelector(s)
      .fullCourses([selTop, selTop + passes.length])
      .miss() // clear
  } else {
    // we need to insert a few rows
    const topBlock = s.reselect([left, right], [selTop, s.height])
    const numNewRows = passes.length - (s.height - selTop)
    if(numNewRows <= 0) {
      throw 'Invalid insertion case: expected new rows'
    }
    const [, addRows] = addRowsAboveTop(topBlock, numNewRows)
    newRows = addRows.union(topBlock)
  }
  newRows = newRows.wales([left, right]) // restrict selection

  // 6 = actuall write passes
  const rows = newRows.splitByRow()
  const layered = layering !== Layering.NONE
  for(const [passIdx, t] of rows.entries()) {
    const { row, yarn } = passes[passIdx]
    // default options
    t.first().options({
      roller: 450,
      speed: 400,
      racking: 0, // may become 0.5 if we have both-knit
      stitchSize: 7,
    })
    // jacquard pass
    t.forEach((c, ix) => {
      const fy = getPixelChannel(front, [ix, row], 0)
      const ry = getPixelChannel(back, [ix, row], 0)
      if(fy === yarn && ry === yarn) {
        c.bothKnit(yarn)
      } else if(fy === yarn) {
        c.frontKnit(yarn)
      } else if(ry === yarn) {
        c.rearKnit(yarn)
      } else if(layered && [left, right - 1].includes(c.column)) {
        c.xmiss(yarn) // only necessary at borders
      }
      // else {
      //   c.miss()
      // }
    })
  }
  return newRows
}
