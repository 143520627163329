import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { Issue, IssueLevel, PanelLocation } from 'src/data/compiler';
import { check } from 'src/data/checks';
import { FV4 } from 'src/common/math';
import { deferAction, setErrorData } from 'src/editor/time-needle/slice';
import { jumpTo } from 'src/editor/time-needle/action';

import 'src/styles/ribbon-checks.scss'

function locStr(loc: PanelLocation) {
  if(Array.isArray(loc)) {
    const [row, col] = loc
    return `(r=${row + 1}, c=${col + 1})`
  }
  return `(row ${loc + 1})`
}

interface IssueBlockProps {
  index: number
  onClick: (idx: number) => void
  children: ReactNode[]
}
function IssueBlock({ index, onClick, children }: IssueBlockProps) {
  if(children.length > 4) {
    return (
      <>
        {children[0]}
        <li
          className="block"
          onClick={() => onClick(index)}
          title="Click to expand"
        >
          <span className="count">{children.length - 2}</span> more
        </li>
        {children[children.length - 1]}
      </>
    )
  }
  return <>{ children }</>
}

enum SplitType {
  ISSUES = 0,
  KICKS,
  MOVES,
  ALL,
}

const SplitFilter = {
  [SplitType.ALL]: () => true,
  [SplitType.ISSUES]: (iss: Issue) => iss.level >= IssueLevel.WARNING,
  [SplitType.KICKS]: (iss: Issue) => iss.level === IssueLevel.INFO,
  [SplitType.MOVES]: (iss: Issue) => iss.level === IssueLevel.VERBOSE,
}

interface IndexedIssue {
  index: number
  issue: Issue
  stageStart: boolean
}

function getIndexedIssues(issues: Issue[], filter: (i: Issue) => boolean): IndexedIssue[] {
  return issues.flatMap((iss, index) => (filter(iss) ? [{ index, issue: iss, stageStart: false }] : []))
}

function computeBlocks(issues: IndexedIssue[]): IndexedIssue[][] {
  const blocks = [[issues[0]]]
  for(let i = 1; i < issues.length; ++i) {
    const lastBlk = blocks[blocks.length - 1]
    const iss = issues[i]
    if(lastBlk[0].issue.context === iss.issue.context) {
      // same context => in same block
      lastBlk.push(iss)
    } else {
      // different context => new block
      blocks.push([iss])
    }
  }
  return blocks
}

function Checks({ title = '' }) {
  const dispatch = useAppDispatch()
  // panel state
  const tnimage = useAppSelector((state) => state.canvas.tnimage)
  const settings = useAppSelector((state) => state.settings, shallowEqual)
  // local state
  const [split, setSplit] = useState<SplitType>(SplitType.ISSUES)
  const [[numIssues, numKicks, numMoves], setNumbers] = useState<[number, number, number]>([0, 0, 0])
  const [isLive, setLive] = useState<boolean>(false)
  const [rawIssues, setRawIssues] = useState<Issue[]>([])
  const [issueBlocks, setIssueBlocks] = useState<IndexedIssue[][]>([])
  const [selected, setSelected] = useState<number>(-1)

  // state updates
  const setIssues = (issues: Issue[], splitType = split) => {
    setRawIssues(issues)

    // count different types
    const nums = issues.reduce((nums, iss) => {
      nums[IssueLevel.WARNING - Math.min(iss.level, IssueLevel.WARNING)] += 1
      return nums
    }, [0, 0, 0] as [number, number, number])
    setNumbers(nums)

    // filter to only type of interest
    const iissues = getIndexedIssues(issues, SplitFilter[splitType])

    // set stage start
    for(const [i, iiss] of iissues.entries()) {
      iiss.stageStart = i === 0 || iissues[i - 1].issue.stage !== iiss.issue.stage
    }

    // compute blocks
    if(iissues.length > 0) {
      // select the first one if any
      setSelected(iissues[0].index)
      updateCanvas(iissues[0].issue)

      // update blocks
      const blocks = computeBlocks(iissues)
      setIssueBlocks(blocks)
    } else {
      setIssueBlocks([])
    }
  }
  const breakBlock = (blkIdx: number) => {
    const newBlocks = issueBlocks.flatMap((blk, idx) => (idx === blkIdx ? blk.map((iss) => [iss]) : [blk]))
    setIssueBlocks(newBlocks)
  }
  const updateCanvas = (arg: number | Issue) => {
    if(arg === -1) {
      dispatch(setErrorData([-1, -1, -1, -1]))
    } else {
      const iss = typeof arg === 'number' ? rawIssues[arg] : arg
      const getXY = (loc: PanelLocation) => (Array.isArray(loc) ? [loc[1], loc[0]] : [-1, loc ?? -1])
      const edata = [
        ...getXY(iss.source),
        ...getXY(iss.related),
      ] as FV4
      dispatch(setErrorData(edata))
    }
  }
  const select = (iidx: number, rel = false) => {
    // update selection
    setSelected(iidx)

    // select in canvas
    updateCanvas(iidx)

    // move canvas to location of interest
    const iss = rawIssues[iidx]
    if(iss) {
      const loc = rel ? iss.related : iss.source
      dispatch(deferAction(jumpTo(loc)))
    }
  }
  const resetSelected = () => {
    setSelected(-1)
    updateCanvas(-1)
  }
  const updateSplit = (type: SplitType) => {
    resetSelected()
    setSplit(type)
    setIssues(rawIssues, type)
  }

  // action upon update
  useEffect(() => {
    if(tnimage && isLive) {
      resetSelected()
      // update issues
      const currIssues = check(tnimage, settings)
      setIssues(currIssues)
    }
  }, [tnimage, isLive, settings])
  const numRawIssues = rawIssues.length
  const numSplitIssues = [numIssues, numKicks, numMoves, numRawIssues][split]
  const splitting = split !== SplitType.ALL
  const splitName = SplitType[split].toLowerCase()
  return (
    <div className="checksbox">
      <div className="check-panel">
        { splitting ? (
          <div className={classNames('count', splitName)}>
            <span className="issues" onClick={() => updateSplit(SplitType.ISSUES)}>{numIssues} issues</span>
            <span className="kicks" onClick={() => updateSplit(SplitType.KICKS)}>{numKicks} kicks</span>
            <span className="moves" onClick={() => updateSplit(SplitType.MOVES)}>{numMoves} moves</span>
          </div>
        ) : numRawIssues > 0 && <div className="count">{numRawIssues} entries</div>}
        <ul className={classNames(splitName, { empty: numSplitIssues === 0 })} data-count={numSplitIssues}>
          { issueBlocks.map((blk, blkIdx) => (
            <IssueBlock
              key={`blk-${blkIdx}`}
              index={blkIdx}
              onClick={breakBlock}
            >{
                blk.map(({ index: num, issue: iss, stageStart }, idx) => {
                  const {
                    stage,
                    context,
                    message,
                    source,
                    related,
                    level,
                  } = iss
                  return (
                    <li
                      key={`issue-${num}`}
                      className={classNames(
                        'issue',
                        IssueLevel[level].toLowerCase(), // verbose | info | warning | danger | invalid
                        `stage-${stage}`, // stage-knitout|-panel|-kcode|-simulation
                        {
                          header: stageStart,
                          selected: selected === num,
                        },
                      )}
                      onClick={() => {
                        if(selected === num) {
                          setSelected(-1)
                          updateCanvas(-1)
                        } else {
                          setSelected(num)
                          updateCanvas(num)
                        }
                      }}
                    >
                      <span className="context">{context}</span>
                      <span className="message">{message}</span>
                      { source && (
                        <a
                          className="source"
                          onClick={(event) => {
                            select(num)
                            event.stopPropagation()
                          }}
                        >{locStr(source)}
                        </a>
                      )}
                      { related && (
                        <a
                          className="related"
                          onClick={(event) => {
                            select(num, true)
                            event.stopPropagation()
                          }}
                        >{locStr(related)}
                        </a>
                      )}
                    </li>
                  )
                })
              }
            </IssueBlock>
          ))}
        </ul>
        <div className="check-actions">
          <label>
            Split
            <input
              type="checkbox"
              className="split"
              onChange={() => {
                updateSplit(split === SplitType.ALL ? SplitType.ISSUES : SplitType.ALL)
              }}
              checked={split !== SplitType.ALL}
            />
          </label>
          {/* <label>
            Live
            <input
              type="checkbox"
              className="update"
              onChange={(event) => {
                setLive(event.currentTarget.checked)
              }}
            />
          </label> */}
          <button
            onClick={() => {
              resetSelected()
              const currIssues = check(tnimage, settings)
              setIssues(currIssues)
            }}
            disabled={!tnimage}
          >Check
          </button>
        </div>
      </div>
    </div>
  )
}

export default Checks
