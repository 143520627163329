import { useAppDispatch, useAppSelector } from 'src/hooks'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Dictionary = {
  [entry: string]: string
}

const initialState = {} as Dictionary

const userEntrySlice = createSlice({
  name: 'userEntry',
  initialState,
  reducers: {
    setEntry(state, { payload: [entryName, value] }: PayloadAction<[string, string]>) {
      return {
        ...state,
        [entryName]: value,
      }
    },
  },
})

export const { setEntry } = userEntrySlice.actions
export default userEntrySlice.reducer
export type UserEntryState = typeof initialState

export function useUserEntry(entryName: string, initValue: string): [string, (newEntry: string) => void] {
  const lastValue = useAppSelector((state) => state.userEntry[entryName] ?? initValue)
  const dispatch = useAppDispatch()
  return [
    lastValue,
    (newValue: string) => dispatch(setEntry([entryName, newValue])),
  ]
}
