import { clamp } from 'src/common/math';
import {
  ReadonlyInt8Array, ReadonlyUint8Array, RFV2, RFV4,
} from 'src/common/types';
import {
  getPixelIndex, getPixelRef, PixelIndex, ReadonlyCanvasImage,
} from './image';

export function flipY(
  arr: ReadonlyUint8Array,
  width: number,
  height: number,
  channels = 3,
): Uint8Array {
  const flipped = new Uint8Array(arr.length);
  const idx = (c: number, r: number): number => channels * (r * width + c);

  for(let r = 0; r !== height; ++r) {
    const h = height - r - 1;
    for(let c = 0; c !== width; ++c) {
      const offsetS = idx(c, r);
      const offsetT = idx(c, h);
      for(let i = 0; i !== channels; ++i) flipped[offsetT + i] = arr[offsetS + i];
    }
  }

  return flipped;
}

// Alter brightness in place, Parameter is -1.0 -> +1.0
export function brightness(
  arr: Uint8Array,
  width: number,
  height: number,
  channels: number,
  parameter: number,
): Uint8Array {
  const total = width * height * channels;
  const parm = Math.round(255 * parameter);
  if(channels !== 4) {
    for(let i = 0; i !== total; ++i) {
      arr[i] = clamp(arr[i] + parm, 0, 255);
    }
  } else {
    for(let i = 0; i !== total; ++i) {
      arr[i] = clamp(arr[i] + parm, 0, 255);
    }
  }

  return arr;
}

// Alter contrast in place, -1.0 -> +1.0
export function contrast(
  arr: Uint8Array,
  width: number,
  height: number,
  channels: number,
  parameter: number,
): Uint8Array {
  const total = width * height * channels;
  const parm = Math.round(255 * parameter);
  const factor = (259 * (parm + 255)) / (255 * (259 - parm));
  if(channels !== 4) {
    for(let i = 0; i !== total; ++i) {
      arr[i] = clamp(factor * (arr[i] - 128) + 128, 0, 255);
    }
  } else {
    for(let i = 0; i !== total; ++i) {
      arr[i] = clamp(factor * (arr[i] - 128) + 128, 0, 255);
    }
  }

  return arr;
}

// Combined brightness/contrast pass (use only when both have been modified)
export function adjustBC(
  arr: Uint8Array,
  width: number,
  height: number,
  channels: number,
  brightness: number,
  contrast: number,
) {
  const total = width * height * channels;
  const br = Math.round(255 * brightness);
  const co = Math.round(255 * contrast);
  const factor = (259 * (co + 255)) / (255 * (259 - co));
  for(let i = 0; i !== total; ++i) {
    arr[i] = clamp(Math.round(factor * (arr[i] + br - 128) + 128), 0, 255);
  }
}

export function convertToGrayscale(
  arr: Uint8Array,
  width: number,
  height: number,
  channels: number,
) {
  if(channels < 3) {
    console.log('Expected convertToGrayscale channels >= 3');
    return;
  }

  // Use luminosity factors
  const factors = [0.299, 0.587, 0.114];

  const total = width * height * channels;
  for(let i = 0; i !== total; i += channels) {
    const px = arr.slice(i, i + channels);
    const gs = factors[0] * px[0] + factors[1] * px[1] + factors[2] * px[2];
    channels === 3 ?
      arr.set([gs, gs, gs], i) :
      arr.set([gs, gs, gs, px[3]], i);
  }
}

/**
 * Apply a reduction function on the pixel of an image
 *
 * @param img the source image
 * @param red the reduction function
 * @param init an initial value for the reduction
 * @returns the reduced value
 */
export function reduceImage<NC extends number, R = number>(
  img: ReadonlyCanvasImage<NC>,
  red: (r: R, px: ReadonlyUint8Array, i: RFV2, ii: number) => R,
  init?: R,
): R {
  let r = init
  for(let y = 0; y < img.height; ++y) {
    for(let x = 0; x < img.width; ++x) {
      const i = [x, y] as const
      const ii = getPixelIndex(img, i)
      r = red(r, getPixelRef(img, ii), i, ii)
    }
  }
  return r
}

/**
 * Compute the maximum extents of a valid region in an image.
 *
 * By default, a pixel is considered valid
 * if some of its components is non-zero.
 *
 * @param img the source image
 * @param isValid the per-pixel validity function
 * @returns the extents of the
 */
export function getImageExtents<NC extends number>(
  img: ReadonlyCanvasImage<NC>,
  isValid = (px: ReadonlyUint8Array) => px.some((n) => !!n),
) {
  return reduceImage(img, (ext: RFV4, px, [x, y]) => {
    if(isValid(px)) {
      const [left, bottom, right, top] = ext
      return [
        Math.min(left, x),
        Math.min(bottom, y),
        Math.max(right, x + 1),
        Math.max(top, y + 1),
      ] as const
    }
    return ext
  }, [img.width, img.height, 0, 0] as const)
}
