import { YarnIndexOrNone } from 'src/data/time-needle/options';
import TimeNeedleSelector from 'src/data/time-needle/selector';
import { ReadonlyTimeNeedleImage } from 'src/data/time-needle/time-needle-image';
import { isSelectorLike, SelectorLike } from '../time-needle/transformer';
import {
  CarrierFlag,
  Direction,
  DirectionOrXfer,
  XFER_DIRECTION,
  otherDir,
  LEFT,
  RIGHT,
  DirectionOrUnknown,
  UNKNOWN,
} from './common';

function getRows(arg: ReadonlyTimeNeedleImage | SelectorLike | SelectorLike[]): SelectorLike[] {
  if(Array.isArray(arg)) {
    return arg
  } if(isSelectorLike(arg)) {
    return arg.splitByRow()
  }
  return TimeNeedleSelector.from(arg).splitByRow()
}

export interface TraceOptions {
  activeOut?: boolean
}

export interface PanelTrace {
  rows: SelectorLike[]
  directions: DirectionOrXfer[]
  userDirections: DirectionOrUnknown[]
  carrierIns: CarrierFlag<boolean>[]
  carrierOuts: CarrierFlag<boolean>[]
  rowCarriers: YarnIndexOrNone[]
  lastCarrierRow: CarrierFlag<number>
}

export function tracePanel(
  arg: ReadonlyTimeNeedleImage | SelectorLike | SelectorLike[],
  {
    activeOut = false,
  }: TraceOptions = {},
): PanelTrace {
  const rows = getRows(arg)
  const directions = new Array<DirectionOrXfer>(rows.length)
  const userDirections = new Array<DirectionOrUnknown>(rows.length)
  const carrierIns = Array.from({ length: rows.length }, () => [false, false, false, false, false, false] as CarrierFlag<boolean>)
  const carrierOuts = carrierIns.map((flags) => flags.slice() as CarrierFlag<boolean>)

  // simulated state
  const csIsIn = [false, false, false, false, false, false] as CarrierFlag<boolean>
  const csDirs = [LEFT, LEFT, LEFT, LEFT, LEFT, LEFT] as CarrierFlag<Direction> // first introduction will change direction

  // yarn information over rows
  const rowCarriers = rows.map((row) => row.first().getOptions()[0].carrier)

  // last row a carrier appears in
  const lastCarrierRow = carrierIns.map((_, i) => {
    for(let r = rowCarriers.length - 1; r >= 0; --r) {
      if(rowCarriers[r] === i + 1) {
        return r // last row it was used on
      }
    }
    return -1 // carrier is never used
  }) as CarrierFlag<number>

  // compute carrier information over each row
  // /!\ this assumes a single carrier per row
  let lastDir: Direction = LEFT // start on the left
  for(const [i, row] of rows.entries()) {
    const carrier = rowCarriers[i]
    const noCarrier = carrier === 0

    // introduce yarn carrier if not in yet
    if(!noCarrier && !csIsIn[carrier - 1]) {
      csIsIn[carrier - 1] = true
      carrierIns[i][carrier - 1] = true
    }

    // read options
    const opts = row.first().getOptions()[0]

    // store user direction
    userDirections[i] = opts.direction === 2 ? UNKNOWN : opts.direction === 0 ? RIGHT : LEFT

    // compute this row's direction
    let dir: Direction
    if(!noCarrier) {
      if(opts.direction !== 2) {
        dir = opts.direction === 0 ? RIGHT : LEFT
      } else {
        dir = otherDir(csDirs[carrier - 1]) // reverse carrier direction by default
      }
      csDirs[carrier - 1] = dir // update state
      directions[i] = dir
    } else {
      dir = otherDir(lastDir) // reverse carriage direction
      directions[i] = XFER_DIRECTION
    }
    lastDir = dir // remember last direction

    // XXX trace per-needle action to check for floats

    // active carrier removal
    if(activeOut && lastCarrierRow[carrier - 1]) {
      csIsIn[carrier - 1] = false
      carrierOuts[i][carrier - 1] = true
    }
  }

  // take active carriers out at the end
  for(let carrier = 0; carrier < csIsIn.length; ++carrier) {
    if(csIsIn[carrier]) {
      csIsIn[carrier] = false
      carrierOuts[rows.length - 1][carrier] = true
    }
  }

  return {
    rows,
    directions,
    userDirections,
    carrierIns,
    carrierOuts,
    rowCarriers,
    lastCarrierRow,
  }
}
