import TimeNeedleSelector from 'src/data/time-needle/selector';
import { getNeedleTransferShift, isNeedleTransferCode, NeedleCode } from 'src/data/time-needle/stitch-code';
import { ReadonlyTimeNeedleImage } from 'src/data/time-needle/time-needle-image';
import { isSelectorLike, SelectorLike } from '../time-needle/transformer';
import {
  BedSide,
  CarrierFlag,
  Direction,
  KnitoutCommand,
  Needle,
  XFER_DIRECTION,
  FRONT,
  REAR,
  RIGHT,
  LEFT,
  KnitoutArguments,
  Issue,
  ProgramStage,
  setProgramStage,
  issue,
  KnitoutEntry,
  UNKNOWN,
  PanelLocation,
} from './common';
import { TraceOptions, tracePanel } from './trace-panel';

// knitout options
export interface KnitoutSpecificOptions {
  minimal?: boolean
  verbose?: boolean
  store?: boolean
  storeOnly?: boolean
  outputPasses?: boolean
}
export type KnitoutOptions = KnitoutSpecificOptions & TraceOptions

const csNames = ['1', '2', '3', '4', '5', '6'] as CarrierFlag<string>

// issue contexts
const START_DIRECTION = 'start-direction'
const UNSUPPORTED_STITCH = 'stitch-type'
const HAS_DIR_OR_CARRIER = 'dir-carrier'
const NO_DIR_NOR_CARRIER = 'no-dir-carrier'
const HALF_RACKING = 'half-racking'
const NEEDLE_OUT_OF_RANGE = 'out-of-range'

// output interface
export interface KnitoutOutput {
  knitout: string
  lines: string[]
  commands: KnitoutEntry[]
  valid: boolean
  issues: Issue[]
}

// fixed preamble lines
const knitoutPreamble = `;!knitout-2
;;Machine: kniterate
;;Carriers: 1 2 3 4 5 6
;;Position: Keep
x-stitch-number 5
x-speed-number 100
x-vis-color #000000 1
x-vis-color #ffffff 2
x-vis-color #00e4ff 3
;background color: 2
`.split('\n')

export const KNITOUT_PREAMBLE_NUM_LINES = knitoutPreamble.length

export function fromPanelToKnitout(
  p: SelectorLike | ReadonlyTimeNeedleImage,
  options: KnitoutOptions = {},
): KnitoutOutput {
  if(!(isSelectorLike(p))) {
    p = TimeNeedleSelector.from(p)
  }
  // extract knitout options
  const {
    store = false,
    storeOnly = false,
    outputPasses = false,
    minimal = true,
    verbose = false,
  } = options
  const {
    rows,
    directions,
    userDirections,
    carrierIns,
    carrierOuts,
    rowCarriers,
  } = tracePanel(p, options)
  const lines = knitoutPreamble.slice()
  const commands = new Array<KnitoutEntry>()
  const issues = new Array<Issue>()
  const comment = minimal ? () => {} : (str: string) => {
    lines.push(`; ${str}`)
  }
  let lastRowId = 0
  let lastColId = -1
  const out = (cmd: KnitoutCommand, ...args: KnitoutArguments) => {
    if(!storeOnly) {
      lines.push(fromEntryToLine({ cmd, args }))
    }
    if(store || storeOnly) {
      commands.push({
        cmd, args, src: lastColId === -1 ? lastRowId : [lastRowId, lastColId],
      })
    }
  }
  const knit = (d: Direction, n: Needle, ...cs: string[]) => {
    out('knit', d, n, ...cs)
  }
  const tuck = (d: Direction, n: Needle, ...cs: string[]) => {
    out('tuck', d, n, ...cs)
  }
  const miss = (d: Direction, n: Needle, ...cs: string[]) => {
    out('miss', d, n, ...cs)
  }
  const xfer = (n1: Needle, n2: Needle) => {
    out('xfer', n1, n2)
  }
  const drop = (n: Needle) => {
    out('drop', n)
  }
  const split = (d: Direction, n1: Needle, n2: Needle, ...cs: string[]) => {
    out('split', d, n1, n2, ...cs)
  }

  // the current program stage
  setProgramStage(ProgramStage.PANEL_TO_KNITOUT)

  // we're valid unless proven otherwise
  let valid = true

  // issue mechanisms
  const invalid = (context: string, message: string) => {
    valid = false
    const i = issue(context).invalid(message)
    issues.push(i)
    return i
  }
  const danger = (context: string, message: string) => {
    const i = issue(context).danger(message)
    issues.push(i)
    return i
  }
  const warn = (context: string, message: string) => {
    const i = issue(context).warn(message)
    issues.push(i)
    return i
  }

  // generate knitout from each row
  for(const [i, row] of rows.entries()) {
    const carrier = rowCarriers[i]
    const cname = csNames[carrier - 1]
    const dir = directions[i]
    const userDir = userDirections[i]
    const noCarrier = carrier === 0
    const isLastRow = i === rows.length - 1

    if(i > 0 && outputPasses) {
      out('x-end-pass')
    }

    // update row/col information
    lastRowId = i
    lastColId = -1

    comment(`Row ${i + 1}`)

    // introduce yarn if needed
    if(carrierIns[i][carrier - 1]) {
      out('in', cname)

      // check that the carrier is going left-to-right
      // since this is an expectation of the knitout compiler
      if(dir !== RIGHT) {
        danger(
          START_DIRECTION,
          'The initial direction of a carrier must be left-to-right',
        ).at(i)
      }
    }

    // read options
    const opts = row.first().getOptions()[0]

    // store per-line option information
    out('x-speed-number', opts.speed)
    out('x-roller-advance', opts.roller)
    out('rack', opts.racking)
    let racking = opts.racking // can be changed by racked transfer operations

    // lazy per-action stitch size
    const hasSize = [false, false]
    const stitchSize = ({ side }: Needle, xfer = false) => {
      const sideIdx = side === FRONT ? 0 : 1
      if(!verbose && hasSize[sideIdx]) {
        return
      }
      if(xfer) {
        out('x-xfer-stitch-number', side === FRONT ? opts.frontStitchSize : opts.rearStitchSize)
        hasSize[1 - sideIdx] = true // /!\ size applies to both sides
      } else {
        out('x-stitch-number', side === FRONT ? opts.frontStitchSize : opts.rearStitchSize)
      }
      hasSize[sideIdx] = true
    }

    // map from needle stitch type
    const oriRacking = opts.racking
    const intRacking = Math.floor(opts.racking)
    const fracRacking = oriRacking - intRacking // 0.0 or 0.5

    // needle traversal
    const xmin = Math.max(0, intRacking) // inclusive
    const xmax = row.length - 1 + Math.min(0, intRacking) // inclusive
    const [xs, xe, dx] = dir === RIGHT ? [
      0, // inclusive
      row.length, // exclusive
      1,
    ] : [
      row.length - 1, // inclusive
      -1, // exclusive
      -1,
    ]
    let isXferRowWithDir = false
    const sides: [BedSide, BedSide] = dir === LEFT ? [REAR, FRONT] : [FRONT, REAR]
    for(let x = xs, lineIsValid = true; lineIsValid && x !== xe; x += dx) {
      const c = row.getCell(x)
      const [fnc, rnc] = c.getNeedleCodes()
      const fmiss = fnc === NeedleCode.MISS
      const rmiss = rnc === NeedleCode.MISS
      if(!fmiss && !rmiss) {
        // two-sided action => should be in half-racking
        if(fracRacking === 0.0) {
          invalid(HALF_RACKING, 'Two-sided needle actions require half-racking').at([i, x])
          break
        }
      } else if((!fmiss || !rmiss) && (x < xmin || x > xmax)) {
        // some action in unsafe range
        // XXX should we allow the ones that can be physically performed in the unsafe range?
        const err = fmiss ? danger : invalid // rear action => always invalid in this situation
        err(NEEDLE_OUT_OF_RANGE, 'Action on needle beyond safe range due to racking').at([i, x])
      }
      lastColId = x
      for(const side of sides) {
        // get actuated needle and its action (())
        const sn: Needle = new Needle(side, side === REAR ? x - intRacking : x)
        const nc = side === FRONT ? fnc : rnc
        if(nc === NeedleCode.MISS) {
          continue // skip empty action
        }
        // set stitch size of the given side
        if(isNeedleTransferCode(nc)) {
          if(dir !== XFER_DIRECTION || !noCarrier) {
            // find some carrier location to use in error reporting
            const carrierCell = row.find((c) => c.isCarrierAction())
            const relLoc = carrierCell ? [i, carrierCell.column] as PanelLocation : void 0
            invalid(HAS_DIR_OR_CARRIER, 'Transfer row with carrier / direction').between([i, x], relLoc)
            lineIsValid = false
            break
          } else if(userDir !== UNKNOWN && !isXferRowWithDir) {
            warn(HAS_DIR_OR_CARRIER, 'Transfer row with user direction').at(i)
            isXferRowWithDir = true // no need to repeat on each transfer operation
          }
          // transfer action
          const trgBed = side === FRONT ? 1 : 0
          const shift = getNeedleTransferShift(nc)
          // /!\ racking moves the rear bed only => the racking value depends on the target bed
          // * rear-to-front = move the rear bed like the shift
          // * front-to-rear = move the rear bed like the opposite of the shift
          const rearShift = trgBed === 0 ? shift : -shift
          // update racking if necessary
          if(rearShift !== 0 && rearShift !== racking) {
            racking = rearShift
            out('rack', racking)
          }
          const tn = sn.otherSide(racking)
          stitchSize(sn, true)
          stitchSize(tn, true)
          xfer(sn, tn)
        } else if(nc === NeedleCode.XMISS) {
          // only miss on the front (to not have to worry about racking)
          if(sn.side === FRONT) {
            if(dir === XFER_DIRECTION || noCarrier) {
              invalid(NO_DIR_NOR_CARRIER, 'Missing without carrier / direction').at([i, x])
              lineIsValid = false
              break
            }
            stitchSize(sn)
            miss(dir, sn, cname)
          }
        } else if(nc === NeedleCode.DROP) {
          stitchSize(sn)
          drop(sn)
        } else if(nc === NeedleCode.KNIT) {
          if(dir === XFER_DIRECTION || noCarrier) {
            invalid(NO_DIR_NOR_CARRIER, 'Knitting without carrier / direction').at([i, x])
            lineIsValid = false
            break
          }
          stitchSize(sn)
          knit(dir, sn, cname)
        } else if(nc === NeedleCode.TUCK) {
          if(dir === XFER_DIRECTION || noCarrier) {
            invalid(NO_DIR_NOR_CARRIER, 'Tucking without carrier / direction').at([i, x])
            lineIsValid = false
            break
          }
          stitchSize(sn)
          tuck(dir, sn, cname)
        } else if(nc === NeedleCode.SPLT) {
          if(dir === XFER_DIRECTION || noCarrier) {
            invalid(NO_DIR_NOR_CARRIER, 'Splitting without carrier / direction').at([i, x])
            lineIsValid = false
            break
          }
          const tn = sn.otherSide(racking) // shiftNeedle(otherSide(sn), side === FRONT ? -racking : racking)
          stitchSize(sn) // XXX should this be with xfer=true?
          stitchSize(tn) // XXX
          split(dir, sn, tn, cname)
        } else {
          comment(`Unsupported needle code ${nc} at n=${sn}`)
          warn(UNSUPPORTED_STITCH, `Unsupported needle code ${nc}`).at([
            i, x,
          ])
        }
      }
    }
    // line spacing between passes
    if(!minimal) lines.push('')

    // resetting column
    lastColId = -1

    // active carrier removal
    if(carrierOuts[i][carrier - 1] && !isLastRow) {
      comment(`Done knitting with carrier ${cname}`)
      out('out', cname)
    }
  }

  // take active carriers out
  comment('Taking carriers out')
  for(let carrier = 0; carrier < csNames.length; ++carrier) {
    if(carrierOuts[rows.length - 1][carrier]) {
      out('out', csNames[carrier])
    }
  }

  return {
    get knitout() { return valid ? lines.join('\n') : '' },
    lines,
    commands,
    valid,
    issues,
  }
}

export function fromEntryToLine({ cmd, args }: KnitoutEntry): string {
  if(cmd === 'x-stitch-number' || cmd === 'x-xfer-stitch-number') {
    // special case for stitch numbers that end up converted to HEX for KCODE purposes
    const stitchNumber = typeof args[0] === 'number' ? '0123456789ABCDEF'[args[0]] : '?'
    return `${cmd} ${stitchNumber}`
  }
  // general case is simple
  return `${cmd} ${args.join(' ')}`
}
