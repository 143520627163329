/*
The RenderContext contains all state necessary to render a Program.  The render
context contains the uniform settings and matching data, the render state containing
all the WebGL state setting (blend parameters, depth testing, etc.).  These all
combine to form the context.  The context should (eventually) manage dirty data
flags and update the WebGL state automatically using minimal state transitions.

The render context also contains the associated textures used by the program and
provides binding and unbinding methods accordingly.
 */

import {
  Argument, makeArgument, Uniform, makeUniform, GLDataType, isTextureType, WebGLXRenderingContext, WebGLXTexture, WebGLXProgram, WebGLXUniformLocation,
} from './types';
import { getUniformLocation } from './utils';

export default class RenderContext {
  private readonly gl: WebGLXRenderingContext

  private readonly prog: WebGLXProgram

  private readonly arguments: Map<string, Argument>

  private readonly textures: Array<WebGLXTexture>;

  private constructor(
    gl: WebGLXRenderingContext,
    prog: WebGLXProgram,
  ) {
    this.gl = gl
    this.prog = prog
    this.arguments = new Map<string, Argument>()
    this.textures = new Array<WebGLXTexture>()
  }

  static from(gl: WebGLXRenderingContext, prog: WebGLXProgram) {
    const ctx = new RenderContext(gl, prog)
    ctx.initialize()
    return ctx
  }

  private initialize(): void {
    const { gl, prog } = this

    // build argument table while
    // - creating arguments
    // - allocating texture units
    const count = gl.getProgramParameter(prog, gl.ACTIVE_UNIFORMS)
    for(let i = 0; i < count; ++i) {
      const info = gl.getActiveUniform(prog, i)
      // wrap uniform data
      const uniform = makeUniform(
        info.name,
        info.type as GLDataType,
        info.size,
        getUniformLocation(gl, prog, info.name),
      )
      if(isTextureType(uniform.type)) {
        this.textures.push(null) // allocate location
      }
      // create argument entry
      this.arguments.set(info.name, makeArgument(uniform, null))
    }
  }

  public getGLContext(): WebGLXRenderingContext {
    return this.gl
  }

  public bind(): void { // TODO: Implement minimal state transition
    const { gl } = this

    // use the corresponding shader program
    gl.useProgram(this.prog)

    for(const arg of this.arguments.values()) {
      if(arg.dirty) {
        this.setUniform(
          arg.uniform.type,
          arg.uniform.size,
          arg.uniform.location,
          arg.value,
          arg.uniform.name,
        )
        arg.dirty = false
      }
    }

    for(let i = 0; i < this.textures.length; ++i) {
      gl.activeTexture(gl.TEXTURE0 + i)
      gl.bindTexture(gl.TEXTURE_2D, this.textures[i])
    }
  }

  public release(): void {
    const { gl } = this
    for(let i = 0; i < this.textures.length; ++i) {
      gl.activeTexture(gl.TEXTURE0 + i)
      gl.bindTexture(gl.TEXTURE_2D, null)
    }
  }

  public destroy() {
    // XXX release allocated resources (e.g., textures)
  }

  private setUniform(
    type: GLDataType,
    size: number,
    location: WebGLXUniformLocation,
    value: any,
    name: string,
  ): void {
    const { gl } = this;

    try {
      switch(type) {
      case GLDataType.DT_FLOAT:
        size === 1 ? gl.uniform1f(location, value) : gl.uniform1fv(location, value);
        break;
      case GLDataType.DT_FLOAT_VEC2:
        gl.uniform2fv(location, value);
        break;
      case GLDataType.DT_FLOAT_VEC3:
        gl.uniform3fv(location, value);
        break;
      case GLDataType.DT_FLOAT_VEC4:
        gl.uniform4fv(location, value);
        break;
      case GLDataType.DT_INT:
        size === 1 ? gl.uniform1i(location, value) : gl.uniform1iv(location, value);
        break;
      case GLDataType.DT_INT_VEC2:
        gl.uniform2iv(location, value);
        break;
      case GLDataType.DT_INT_VEC3:
        gl.uniform3iv(location, value);
        break;
      case GLDataType.DT_INT_VEC4:
        gl.uniform4iv(location, value);
        break;
      case GLDataType.DT_FLOAT_MAT2:
        gl.uniformMatrix2fv(location, false, value);
        break;
      case GLDataType.DT_FLOAT_MAT3:
        gl.uniformMatrix3fv(location, false, value);
        break;
      case GLDataType.DT_FLOAT_MAT4:
        gl.uniformMatrix4fv(location, false, value);
        break;
      case GLDataType.DT_SAMPLER_2D:
        gl.uniform1i(location, value);
        break;
      case GLDataType.DT_SAMPLER_CUBE:
        gl.uniform1i(location, value);
        break;
      default:
        console.error('RenderContext.setUniform unknown data type');
      }
    } catch(err) {
      console.error('Failed to bind uniform:', name, 'with value:', value);
    }
  }

  public setArgument(name: string, value: any): boolean {
    const arg = this.arguments.get(name)
    if(arg) {
      arg.value = value
      arg.dirty = true
      return true
    }
    console.error('Warning: setArgument could not locate uniform:', name)
    return false
  }

  // setTexture sets the argument to the unit, and sets the unit to the texture
  public setTexture(name: string, texture: WebGLXTexture): boolean {
    const arg = this.getArgument(name)
    if(!arg) {
      return false
    }
    const unit = typeof arg.value === 'number' ? arg.value : this.textures.findIndex((tex) => !tex)
    if(unit === -1) {
      console.error('Could not find an available texture unit', name)
      return false
    }

    // set the texture at its chosen unit
    this.textures[unit] = texture
    // remember the unit within the argument data
    this.setArgument(name, unit)
    return true;
  }

  public get numTextures() {
    return this.textures.length
  }

  public hasTexture(unit: number) {
    return !!this.textures[unit]
  }

  public getArgument(name: string): Argument {
    return this.arguments.get(name)
  }

  public hasArgument(name: string): boolean {
    return this.arguments.has(name)
  }

  public getTexture(name: string): WebGLXTexture {
    const arg = this.getArgument(name);
    return this.textures[arg.value as number];
  }

  public getUniform(name: string): Uniform {
    return this.getArgument(name)?.uniform
  }

  public getArgumentValue(name: string): any {
    const arg = this.getArgument(name);
    return arg ? arg.value : null;
  }
}
