// automatically generated by the FlatBuffers compiler, do not modify

import {
  ByteBuffer, Builder, Offset, Encoding,
} from 'flatbuffers'
/**
 * @enum {number}
 */
export namespace buck{
export enum Mode{
  Miss= 0,
  Knit= 1,
  Drop= 2,
  Tuck= 3,
  Recv= 4,
  Xfer= 5,
  Splt= 6,
  XferL1= 7,
  XferL2= 8,
  XferL3= 9,
  XferL4= 10,
  XferR1= 11,
  XferR2= 12,
  XferR3= 13,
  XferR4= 14,
  XplctMiss= 15
}}

/**
 * @constructor
 */
export namespace buck{
export class Bed {
  bb: ByteBuffer|null = null;

  bb_pos = 0;

  /**
 * @param number i
 * @param ByteBuffer bb
 * @returns Bed
 */
  __init(i:number, bb:ByteBuffer):Bed {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  /**
 * @param ByteBuffer bb
 * @param Bed= obj
 * @returns Bed
 */
  static getRootAsBed(bb:ByteBuffer, obj?:Bed):Bed {
    return (obj || new Bed()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  /**
 * @param ByteBuffer bb
 * @param Bed= obj
 * @returns Bed
 */
  static getSizePrefixedRootAsBed(bb:ByteBuffer, obj?:Bed):Bed {
    return (obj || new Bed()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  /**
 * @param number index
 * @returns number
 */
  size(index: number):number|null {
    let offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
  }

  /**
 * @returns number
 */
  sizeLength():number {
    let offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  /**
 * @returns Uint8Array
 */
  sizeArray():Uint8Array|null {
    let offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
  }

  /**
 * @param number index
 * @returns buck.Mode
 */
  mode(index: number):buck.Mode|null {
    let offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? /**  */ (this.bb!.readInt8(this.bb!.__vector(this.bb_pos + offset) + index)) : /**  */ (0);
  }

  /**
 * @returns number
 */
  modeLength():number {
    let offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  /**
 * @returns Int8Array
 */
  modeArray():Int8Array|null {
    let offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? new Int8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
  }

  /**
 * @param number index
 * @returns number
 */
  yarn(index: number):number|null {
    let offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
  }

  /**
 * @returns number
 */
  yarnLength():number {
    let offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  /**
 * @returns Uint8Array
 */
  yarnArray():Uint8Array|null {
    let offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
  }

  /**
 * @param Builder builder
 */
  static startBed(builder:Builder) {
    builder.startObject(3);
  }

  /**
 * @param Builder builder
 * @param  Offset sizeOffset
 */
  static addSize(builder:Builder, sizeOffset: Offset) {
    builder.addFieldOffset(0, sizeOffset, 0);
  }

  /**
 * @param Builder builder
 * @param Array.<number> data
 * @returns  Offset
 */
  static createSizeVector(builder:Builder, data:number[] | Uint8Array): Offset {
    builder.startVector(1, data.length, 1);
    for(let i = data.length - 1; i >= 0; i--) {
      builder.addInt8(data[i]);
    }
    return builder.endVector();
  }

  /**
 * @param Builder builder
 * @param number numElems
 */
  static startSizeVector(builder:Builder, numElems:number) {
    builder.startVector(1, numElems, 1);
  }

  /**
 * @param Builder builder
 * @param  Offset modeOffset
 */
  static addMode(builder:Builder, modeOffset: Offset) {
    builder.addFieldOffset(1, modeOffset, 0);
  }

  /**
 * @param Builder builder
 * @param Array.<buck.Mode> data
 * @returns  Offset
 */
  static createModeVector(builder:Builder, data:buck.Mode[]): Offset {
    builder.startVector(1, data.length, 1);
    for(let i = data.length - 1; i >= 0; i--) {
      builder.addInt8(data[i]);
    }
    return builder.endVector();
  }

  /**
 * @param Builder builder
 * @param number numElems
 */
  static startModeVector(builder:Builder, numElems:number) {
    builder.startVector(1, numElems, 1);
  }

  /**
 * @param Builder builder
 * @param  Offset yarnOffset
 */
  static addYarn(builder:Builder, yarnOffset: Offset) {
    builder.addFieldOffset(2, yarnOffset, 0);
  }

  /**
 * @param Builder builder
 * @param Array.<number> data
 * @returns  Offset
 */
  static createYarnVector(builder:Builder, data:number[] | Uint8Array): Offset {
    builder.startVector(1, data.length, 1);
    for(let i = data.length - 1; i >= 0; i--) {
      builder.addInt8(data[i]);
    }
    return builder.endVector();
  }

  /**
 * @param Builder builder
 * @param number numElems
 */
  static startYarnVector(builder:Builder, numElems:number) {
    builder.startVector(1, numElems, 1);
  }

  /**
 * @param Builder builder
 * @returns  Offset
 */
  static endBed(builder:Builder): Offset {
    let offset = builder.endObject();
    return offset;
  }

  static createBed(builder:Builder, sizeOffset: Offset, modeOffset: Offset, yarnOffset: Offset): Offset {
    Bed.startBed(builder);
    Bed.addSize(builder, sizeOffset);
    Bed.addMode(builder, modeOffset);
    Bed.addYarn(builder, yarnOffset);
    return Bed.endBed(builder);
  }
}
}
/**
 * @constructor
 */
export namespace buck{
export class Bedless {
  bb: ByteBuffer|null = null;

  bb_pos = 0;

  /**
 * @param number i
 * @param ByteBuffer bb
 * @returns Bedless
 */
  __init(i:number, bb:ByteBuffer):Bedless {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  /**
 * @param ByteBuffer bb
 * @param Bedless= obj
 * @returns Bedless
 */
  static getRootAsBedless(bb:ByteBuffer, obj?:Bedless):Bedless {
    return (obj || new Bedless()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  /**
 * @param ByteBuffer bb
 * @param Bedless= obj
 * @returns Bedless
 */
  static getSizePrefixedRootAsBedless(bb:ByteBuffer, obj?:Bedless):Bedless {
    return (obj || new Bedless()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  /**
 * @returns number
 */
  dir():number {
    let offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.readUint8(this.bb_pos + offset) : 0;
  }

  /**
 * @returns number
 */
  racking():number {
    let offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
  }

  /**
 * @returns number
 */
  carriageSpeed():number {
    let offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.readInt16(this.bb_pos + offset) : 0;
  }

  /**
 * @returns number
 */
  rollDuring():number {
    let offset = this.bb!.__offset(this.bb_pos, 10);
    return offset ? this.bb!.readInt16(this.bb_pos + offset) : 0;
  }

  /**
 * @returns number
 */
  rollAfter():number {
    let offset = this.bb!.__offset(this.bb_pos, 12);
    return offset ? this.bb!.readInt16(this.bb_pos + offset) : 0;
  }

  /**
 * @returns number
 */
  rollerSpeed():number {
    let offset = this.bb!.__offset(this.bb_pos, 14);
    return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
  }

  /**
 * @param flatbuffers.Encoding= optionalEncoding
 * @returns string|Uint8Array|null
 */
  pauseMsg():string|null

  pauseMsg(optionalEncoding: Encoding):string|Uint8Array|null

  pauseMsg(optionalEncoding?:any):string|Uint8Array|null {
    let offset = this.bb!.__offset(this.bb_pos, 16);
    return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
  }

  /**
 * @param Builder builder
 */
  static startBedless(builder:Builder) {
    builder.startObject(7);
  }

  /**
 * @param Builder builder
 * @param number dir
 */
  static addDir(builder:Builder, dir:number) {
    builder.addFieldInt8(0, dir, 0);
  }

  /**
 * @param Builder builder
 * @param number racking
 */
  static addRacking(builder:Builder, racking:number) {
    builder.addFieldFloat32(1, racking, 0.0);
  }

  /**
 * @param Builder builder
 * @param number carriageSpeed
 */
  static addCarriageSpeed(builder:Builder, carriageSpeed:number) {
    builder.addFieldInt16(2, carriageSpeed, 0);
  }

  /**
 * @param Builder builder
 * @param number rollDuring
 */
  static addRollDuring(builder:Builder, rollDuring:number) {
    builder.addFieldInt16(3, rollDuring, 0);
  }

  /**
 * @param Builder builder
 * @param number rollAfter
 */
  static addRollAfter(builder:Builder, rollAfter:number) {
    builder.addFieldInt16(4, rollAfter, 0);
  }

  /**
 * @param Builder builder
 * @param number rollerSpeed
 */
  static addRollerSpeed(builder:Builder, rollerSpeed:number) {
    builder.addFieldFloat32(5, rollerSpeed, 0.0);
  }

  /**
 * @param Builder builder
 * @param  Offset pauseMsgOffset
 */
  static addPauseMsg(builder:Builder, pauseMsgOffset: Offset) {
    builder.addFieldOffset(6, pauseMsgOffset, 0);
  }

  /**
 * @param Builder builder
 * @returns  Offset
 */
  static endBedless(builder:Builder): Offset {
    let offset = builder.endObject();
    return offset;
  }

  static createBedless(builder:Builder, dir:number, racking:number, carriageSpeed:number, rollDuring:number, rollAfter:number, rollerSpeed:number, pauseMsgOffset: Offset): Offset {
    Bedless.startBedless(builder);
    Bedless.addDir(builder, dir);
    Bedless.addRacking(builder, racking);
    Bedless.addCarriageSpeed(builder, carriageSpeed);
    Bedless.addRollDuring(builder, rollDuring);
    Bedless.addRollAfter(builder, rollAfter);
    Bedless.addRollerSpeed(builder, rollerSpeed);
    Bedless.addPauseMsg(builder, pauseMsgOffset);
    return Bedless.endBedless(builder);
  }
}
}
/**
 * @constructor
 */
export namespace buck{
export class Pass {
  bb: ByteBuffer|null = null;

  bb_pos = 0;

  /**
 * @param number i
 * @param ByteBuffer bb
 * @returns Pass
 */
  __init(i:number, bb:ByteBuffer):Pass {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  /**
 * @param ByteBuffer bb
 * @param Pass= obj
 * @returns Pass
 */
  static getRootAsPass(bb:ByteBuffer, obj?:Pass):Pass {
    return (obj || new Pass()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  /**
 * @param ByteBuffer bb
 * @param Pass= obj
 * @returns Pass
 */
  static getSizePrefixedRootAsPass(bb:ByteBuffer, obj?:Pass):Pass {
    return (obj || new Pass()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  /**
 * @param buck.Bedless= obj
 * @returns buck.Bedless|null
 */
  bedless(obj?:buck.Bedless):buck.Bedless|null {
    let offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? (obj || new buck.Bedless()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
  }

  /**
 * @param number index
 * @param buck.Bed= obj
 * @returns buck.Bed
 */
  beds(index: number, obj?:buck.Bed):buck.Bed|null {
    let offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? (obj || new buck.Bed()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
  }

  /**
 * @returns number
 */
  bedsLength():number {
    let offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  /**
 * @param Builder builder
 */
  static startPass(builder:Builder) {
    builder.startObject(2);
  }

  /**
 * @param Builder builder
 * @param  Offset bedlessOffset
 */
  static addBedless(builder:Builder, bedlessOffset: Offset) {
    builder.addFieldOffset(0, bedlessOffset, 0);
  }

  /**
 * @param Builder builder
 * @param  Offset bedsOffset
 */
  static addBeds(builder:Builder, bedsOffset: Offset) {
    builder.addFieldOffset(1, bedsOffset, 0);
  }

  /**
 * @param Builder builder
 * @param Array.< Offset> data
 * @returns  Offset
 */
  static createBedsVector(builder:Builder, data: Offset[]): Offset {
    builder.startVector(4, data.length, 4);
    for(let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]);
    }
    return builder.endVector();
  }

  /**
 * @param Builder builder
 * @param number numElems
 */
  static startBedsVector(builder:Builder, numElems:number) {
    builder.startVector(4, numElems, 4);
  }

  /**
 * @param Builder builder
 * @returns  Offset
 */
  static endPass(builder:Builder): Offset {
    let offset = builder.endObject();
    return offset;
  }

  static createPass(builder:Builder, bedlessOffset: Offset, bedsOffset: Offset): Offset {
    Pass.startPass(builder);
    Pass.addBedless(builder, bedlessOffset);
    Pass.addBeds(builder, bedsOffset);
    return Pass.endPass(builder);
  }
}
}
/**
 * @constructor
 */
export namespace buck{
export class Program {
  bb: ByteBuffer|null = null;

  bb_pos = 0;

  /**
 * @param number i
 * @param ByteBuffer bb
 * @returns Program
 */
  __init(i:number, bb:ByteBuffer):Program {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  /**
 * @param ByteBuffer bb
 * @param Program= obj
 * @returns Program
 */
  static getRootAsProgram(bb:ByteBuffer, obj?:Program):Program {
    return (obj || new Program()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  /**
 * @param ByteBuffer bb
 * @param Program= obj
 * @returns Program
 */
  static getSizePrefixedRootAsProgram(bb:ByteBuffer, obj?:Program):Program {
    return (obj || new Program()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  /**
 * @returns number
 */
  rev():number {
    let offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.readUint8(this.bb_pos + offset) : 0;
  }

  /**
 * @param number index
 * @param buck.Pass= obj
 * @returns buck.Pass
 */
  passes(index: number, obj?:buck.Pass):buck.Pass|null {
    let offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? (obj || new buck.Pass()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
  }

  /**
 * @returns number
 */
  passesLength():number {
    let offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  /**
 * @param Builder builder
 */
  static startProgram(builder:Builder) {
    builder.startObject(2);
  }

  /**
 * @param Builder builder
 * @param number rev
 */
  static addRev(builder:Builder, rev:number) {
    builder.addFieldInt8(0, rev, 0);
  }

  /**
 * @param Builder builder
 * @param  Offset passesOffset
 */
  static addPasses(builder:Builder, passesOffset: Offset) {
    builder.addFieldOffset(1, passesOffset, 0);
  }

  /**
 * @param Builder builder
 * @param Array.< Offset> data
 * @returns  Offset
 */
  static createPassesVector(builder:Builder, data: Offset[]): Offset {
    builder.startVector(4, data.length, 4);
    for(let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]);
    }
    return builder.endVector();
  }

  /**
 * @param Builder builder
 * @param number numElems
 */
  static startPassesVector(builder:Builder, numElems:number) {
    builder.startVector(4, numElems, 4);
  }

  /**
 * @param Builder builder
 * @returns  Offset
 */
  static endProgram(builder:Builder): Offset {
    let offset = builder.endObject();
    return offset;
  }

  static createProgram(builder:Builder, rev:number, passesOffset: Offset): Offset {
    Program.startProgram(builder);
    Program.addRev(builder, rev);
    Program.addPasses(builder, passesOffset);
    return Program.endProgram(builder);
  }
}
}
