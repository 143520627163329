import classNames from 'classnames'
import { ReactNode, useContext } from 'react'
import { Context as DialogContext } from '../dialog'

export function Separator() {
  return <div className="separator" onClick={(event) => { event.stopPropagation() }} />
}

export function defaultParser(input: string): string {
  return input?.toLowerCase()?.trim()
}

export interface ContextMenuItemProps {
  name: string
  shortcut?: string
  disabled?: boolean
  chainable?: boolean
  children?: ReactNode
  menu?: boolean
  header?: boolean
}

// /!\ about `never`
// = used to forbid such property
// and needs to be written as `prop?: never`,
// otherwise it requires a `never`-typed value (which is typically not intended)
// @see https://www.zhenghao.io/posts/ts-never

export interface BasePromptItemProps {
  prompt: [message: string | ReactNode, defValue: string]
  promptRequired?: boolean
  onCancel?: () => void
  onClick?: never
}

export interface PromptItemProps extends BasePromptItemProps{
  onInput: (value: string) => void
  onParse?: never
  parser?: never
}

export interface ParsedItemProps<O> extends BasePromptItemProps {
  onInput?: never
  onParse: (value: O, inputStr: string) => void
  parser: (input: string) => O | string
}

export interface ClickableProps {
  prompt?: never
  promptRequired?: never
  onCancel?: never
  onClick?: () => void
  onInput?: never
  onParse?: never
  parser?: never
}

export function BasicContextMenuItem({
  name,
  shortcut,
  disabled,
  chainable,
  children,
  menu = false,
  header = false,
  onClick,
}: ContextMenuItemProps & { onClick?: () => void }) {
  return (
    <div
      className={classNames('context-menu-item', { disabled, header })}
      onClick={onClick ? (event) => {
        onClick()
        if(chainable) {
          // prevent default menu hiding
          event.stopPropagation()
        }
      } : (event) => {
        // no action = stop propagation directly to prevent default menu hiding
        // /!\ but only if this is the actual event target
        if(event.target === event.currentTarget) {
          event.stopPropagation()
        }
      }}
    >
      <div className="name">{name}</div>
      { shortcut && shortcut.length && <div className="shortcut">{shortcut}</div> }
      { menu && (
        <>
          <div className={classNames('context-menu-subitem', { disabled })} />
          { !disabled && (
            <div className="context-menu-submenu">
              { children }
            </div>
          )}
        </>
      ) || children}
    </div>
  )
}

export function ContextMenuPrompt({
  resolve, question, value,
}: { resolve: (res: any) => void, question: ReactNode, value: string }) {
  return (
    <form
      className="form cm-form prompt"
      onSubmit={(event) => {
        event.preventDefault()
        resolve(event.currentTarget.inputValue.value)
      }}
    >
      <span>{question}</span>
      <input type="text" name="inputValue" defaultValue={value} ref={(el) => setTimeout(() => el?.focus())} />
      <input type="submit" value="OK" />
    </form>
  )
}

export default function ContextMenuItem<O = string>(
  props: ContextMenuItemProps & (ParsedItemProps<O> | PromptItemProps | ClickableProps),
) {
  const showDialog = useContext(DialogContext)
  return props.prompt ? (
    <BasicContextMenuItem
      {...props}
      onClick={async () => {
        const {
          prompt: [question, value],
          promptRequired,
          onCancel,
          onInput,
          parser,
          onParse,
        } = props

        const rawValue = await showDialog(({resolve}) => <ContextMenuPrompt resolve={resolve} question={question} value={value} />)
        if(typeof rawValue === 'string' && (!promptRequired || rawValue)) {
          if(parser && onParse) {
            const value = parser(defaultParser(rawValue))
            if(typeof value === 'string') {
              alert(value)
            } else if(value) {
              onParse(value, rawValue)
            }
          } else if(onInput) {
            const value = defaultParser(rawValue)
            onInput(value)
          }
        } else if(onCancel) {
          onCancel()
        }
      }}
    />
  ) : <BasicContextMenuItem {...props} />
}
