import { SettingsState } from 'src/editor/settings';
import {
  Issue,
  fromPanelToKnitout,
  fromKnitoutToKCode,
  simulate,
  indexedLoopWithIndex,
} from 'src/data/compiler';
import { ReadonlyTimeNeedleImage } from 'src/data/time-needle/time-needle-image';
import TimeNeedleSelector from 'src/data/time-needle/selector';
import { implicitActions } from './implicit-actions';
import { loopConnectivityShouldBeLimited } from './limited-loop-connectivity';
import { transfersShouldNotBeEmpty } from './non-empty-transfers';
import { floatDistancesShouldBeSmall } from './small-float-distance';
import { pickUpStitchesShouldBeStable } from './stable-pick-up-stitches';

// - transfer operations must have source loops (valid sources)
// = implicit during simulation (unless x-unsafe-xfer)

// - up to one stitch can stay on the bed by the end?
// = check last state (what about user clearing / trailing yarn?)

// - local tension should not go beyond some depth
// = compute stitch graph, do topological sorting over time, and check for time difference threshold across wales

// - pick up stitches must be stably anchored
// = for each state, check that new loops are either stable, or pick-up stitches with stable adjacency
// /!\ pick-up stitches have unstable adjacency if they have a directly adjacent neighbor that is a pick-up stitch on the same bed

// - yarn floats must be within some threshold distance
// = for each loop, check distance from initial needle to previous anchoring

export function check(
  tni: ReadonlyTimeNeedleImage,
  {
    maxFloatDistance = 7,
  }: Partial<SettingsState> = {},
): Issue[] {
  // transform to knitout
  const s = TimeNeedleSelector.from(tni)
  const {
    knitout,
    commands,
    issues,
    valid: isKnitoutValid,
  } = fromPanelToKnitout(s, {
    store: true,
    outputPasses: true,
  })

  // transform to kcode
  if(isKnitoutValid) {
    const {
      issues: compilerIssues,
      passes,
    } = fromKnitoutToKCode(knitout, {
      outputPasses: true,
    })

    // store compiler issues
    issues.push(...compilerIssues)

    // compute implicit actions
    implicitActions(s, commands, passes, issues)
  } else {
    return issues
  }

  // simulate knitout
  const [loopGen, loopIndex] = indexedLoopWithIndex()
  const {
    states,
    blocks,
    issues: simulationIssues,
  } = simulate(commands, loopGen)

  // store simulation issues
  issues.push(...simulationIssues)

  // basic validation checks
  transfersShouldNotBeEmpty(blocks, issues)
  floatDistancesShouldBeSmall(blocks, issues, maxFloatDistance)
  pickUpStitchesShouldBeStable(blocks, loopIndex, issues)
  loopConnectivityShouldBeLimited(loopIndex, issues)

  // validation checks based on stitch graph
  // const [stitchGraph, crsIndex, stitchToCrs] = getStitchGraph(loopIndex, issues)
  // loopDependenciesShouldBeShort(stitchGraph, crsIndex, stitchToCrs, issues)

  return issues
}
