import classNames from 'classnames'
import {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react'

import { resetState } from 'src/store'
import { useAppDispatch, useAppSelector } from 'src/hooks'

import {
  deleteDesign as serverDeleteDesign,
  getDesignsList,
  copyDesign as serverCopyDesign,
  renameDesign as serverRenameDesign,
} from 'src/api'

import type { DesignLink } from 'src/api'

import usePrompt from 'src/editor/prompt'

import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'
import Loading from 'src/common/loading'
import { DateTime } from 'luxon'
import Header from 'src/editor/designs-header'
import { FilterQuery, getDesignFilter, getDesignIndex } from './designs-query'
import 'src/styles/designs.scss'

export default function Designs() {
  const dispatch = useAppDispatch()
  const menuRef = useRef(null)
  const tnimage = useAppSelector((state) => state.canvas.tnimage)
  const [query, setQuery] = useState<FilterQuery>({ text: '' })
  const [menu, setMenu] = useState<string | boolean>(false)
  const [authToken, setAuthToken] = useState('')
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [refreshing, setRefreshing] = useState(false)
  const [designs, setDesigns] = useState<DesignLink[]>([])
  const [designIndex, setDesignIndex] = useState<number[]>([])
  const showPrompt = usePrompt()
  document.title = 'Topologic - Designs'

  // reset state when coming from editor
  useEffect(() => {
    if(tnimage) {
      // reset app state
      dispatch(resetState())
    }
  }, [tnimage])

  // obtain access token
  useEffect(() => {
    (async () => {
      const tok = isAuthenticated ? await getAccessTokenSilently() : null
      setAuthToken(tok)
    })()
  }, [isAuthenticated, getAccessTokenSilently])

  // get list of designs
  useEffect(() => {
    if(!isAuthenticated || authToken == '') { return }

    (async () => {
      setDesigns(await getDesignsList(authToken))
    })()
  }, [isAuthenticated, authToken])

  // set position of menu
  useLayoutEffect(() => {
    if(menuRef.current) {
      const viewPortHeight = document.documentElement.clientHeight
      const box = menuRef.current.getBoundingClientRect()
      if(box.bottom > viewPortHeight) {
        menuRef.current.classList.add('upper')
      }
    }
  }, [menu, menuRef.current])

  // order designs
  useEffect(() => {
    if(designs && designs.length) {
      setDesignIndex(getDesignIndex(designs, query))
    }
  }, [designs, query])

  const deleteDesign = (idx: number) => {
    if(window.confirm(`You are about to delete "${designs[idx].name}" confirm?`)) {
      const [{ id }] = designs.splice(idx, 1)
      serverDeleteDesign(id, authToken)
    }
  }

  const copyDesign = async (idx: number) => {
    setRefreshing(true)
    await serverCopyDesign(designs[idx].id, authToken)
    setDesigns(await getDesignsList(authToken))
    setRefreshing(false)
  }

  const renameDesign = async (idx: number) => {
    const originalDesign = designs[idx]
    const newName = await showPrompt({placeholder: 'New design name', question: 'Please rename the design:', value: originalDesign.name})
    if(newName) {
      serverRenameDesign(originalDesign.id, newName, authToken)
      originalDesign.name = newName
      setDesigns([...designs])
    }
  }
  const filter = getDesignFilter(query)
  return (
    <>
      <Header query={query} onChange={setQuery} />
      {(isLoading || refreshing) ? <Loading /> :
        (
          <main>
            <div className="designs">
              <div className="design new">
                <Link to="/app" style={{ textDecoration: 'none' }}><div className="preview" /></Link>
                <div className="name"><Link to="/app">New Design</Link></div>
              </div>
              {designIndex.map((idx) => {
                const {
                  id, name, lastModified, thumbnail,
                } = designs[idx]
                return (
                  <div
                    key={id}
                    className={classNames('design', {
                      hidden: !filter(name.toLowerCase()),
                    })}
                  >
                    <div className="preview"><Link to={`/app/${id}`}><img src={thumbnail} alt="preview panel" /></Link></div>
                    <div className="name"><Link to={`/app/${id}`}>{name}</Link></div>
                    <div className="lastUpdated">{DateTime.fromISO(lastModified).toRelativeCalendar()}</div>
                    <div className="action-icons">
                      {/* <div data-tooltip="Collaborators" className="icon colab-icon" /> */}
                      {/* <div data-tooltip="Share" className="icon share-icon" /> */}
                      <div className="icon more-icon more-menu" onClick={() => setMenu(id !== menu ? id : false)}>
                        {id == menu &&
                      (
                        <div className="menu" ref={menuRef}>
                          <div className="rename-action" onClick={() => renameDesign(idx)}>Rename</div>
                          <div className="copy-action" onClick={() => copyDesign(idx)}>Copy</div>
                          <div className="delete-action" onClick={() => deleteDesign(idx)}>Delete</div>
                        </div>
                      )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </main>
        )}
    </>
  )
}
