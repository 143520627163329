import { addRows } from 'src/data/time-needle/topology'
import {
  BedSide, getKnittingSides, KnittingSide, SelectorLike, TimeNeedleTransformer,
} from './common'

export function generateTransfersToSide(
  s: SelectorLike,
  trgSide: BedSide,
): TimeNeedleTransformer {
  const topRow = s.topmost()
  const sides = getKnittingSides(topRow, 'below')
  const opSide = trgSide === 0 ? 1 : 0
  const needsXfer = sides.map((side) => side === opSide || side === KnittingSide.BOTH)
  // transfer pass count
  let xferPass = 0
  for(let i = 0; i < sides.length && xferPass < 2; ++i) {
    if(xferPass === 0 && needsXfer[i]) {
      ++xferPass
    } else if(xferPass === 1 && needsXfer[i] && needsXfer[i - 1]) {
      ++xferPass
    }
  }
  if(xferPass === 0) {
    return // nothing to do
  }

  // add rows for transfer passes
  const [baseRow, newRows] = addRows(topRow, 'above', xferPass)

  // set row options
  const xferOptions = { stitchSize: 5, speed: 60 }
  newRows.splitByRow().forEach((row, i) => row.options(xferOptions).options({ roller: [0, 30][i] }))

  // generate transfers
  for(let i = 0, pass = 0; i < sides.length; ++i) {
    if(needsXfer[i]) {
      const c = baseRow.getCell(i).neighbor(0, 1 + pass)
      c.transfer(opSide, 0)
      pass = 1 - pass
    } else {
      pass = 0
    }
  }
  return baseRow.union(newRows)
}
