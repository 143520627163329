import classNames from 'classnames'
import { shallowEqual } from 'react-redux'
import { unproject } from 'src/common/math'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { NumCellMargin, setOffset } from 'src/editor/time-needle/slice'

import 'src/styles/horizontal-ruler.scss'

const HorizontalRuler = function () {
  const dispatch = useAppDispatch()
  const {
    xConfig,
    M,
    offset,
    viewWidth,
    zoomLevel,
  } = useAppSelector(({
    canvas: {
      axisConfig: [xConfig],
      M, offset, transform: { viewDims },
      zoomData: { zoomLevel },
    },
  }) => ({
    xConfig,
    M,
    offset,
    viewWidth: viewDims[0],
    zoomLevel,
  }), shallowEqual)

  const numEntries = xConfig?.numCells || 0
  const offsetInit = unproject([xConfig.initOffset, 0], M)
  const offsetNew = unproject(offset, M)
  const delta = offsetNew[0] - offsetInit[0]
  const margin = NumCellMargin * xConfig.cellSize

  const firstValidIdx = xConfig.cellPos.findIndex((pos:number) => {
    const newPos = pos + delta
    return (-margin) < newPos && newPos < viewWidth + margin
  })

  if(firstValidIdx === -1) {
    return null // nothing to display
  }

  const marginLeft = (
    xConfig.cellPos[firstValidIdx] + // the leftmost cell's X position
    delta - // the current delta due to panning
    xConfig.cellSize * 0.5 - // shift from center of cell to its left
    20 // undoing of CSS x shift of parent container
  )
  const numInvalidEntries = Math.max(0, firstValidIdx + numEntries - xConfig.cellPos.length)
  const numValidEntries = numEntries - numInvalidEntries

  const lastValidIdx = firstValidIdx + numValidEntries - 1
  const marginRight = viewWidth - (
    xConfig.cellPos[lastValidIdx] + // the rightmost cell's X position
    delta + // the current delta due to panning
    xConfig.cellSize * 0.5 // shift from center of cell to its right
  ) - 20 // undoing of CSS x shift of parent

  const onWheel = ({ deltaX, deltaY }) => {
    if(marginRight > 0 && (deltaY < 0 || deltaX < 0) || marginLeft > 0 && (deltaY > 0 || deltaX > 0)) {
      return;
    }
    const [x, y] = offset
    const scale = 0.00001 * Math.max(zoomLevel, 2)
    dispatch(setOffset([x + scale * (deltaX || deltaY), y]))
  }

  return (
    <div
      className="horizontal-ruler-wrapper"
      onWheel={onWheel}
      style={{
        marginLeft: marginLeft > 0 ? marginLeft - 5 : 0,
        marginRight: marginRight > 0 ? marginRight - 5 : 0,
      }}
    >
      <div
        className="horizontal-ruler"
        style={{
          marginLeft: marginLeft > 0 ? 5 : marginLeft,
          marginRight: marginRight > 0 ? 5 : marginRight,
          gridAutoColumns: xConfig.cellSize,
        }}
      >
        {
          Array.from({ length: numValidEntries }, (_, i) => {
            const col = firstValidIdx + i
            return (
              <div
                className={classNames(
                  'horizontal-ruler-entry',
                  { highlighted: (col + 1) % 10 === 0 },
                )}
                key={`entry-${col + 1}`}
              >
                <div className="cell cell-index">{col + 1}</div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default HorizontalRuler
