import {
  KEYCODE_C, KEYCODE_ESC, KEYCODE_SHIFT, KEYCODE_SPACE, KEYCODE_V, KEYCODE_X,
} from 'src/common/keyboard'
import { isCopyDataValid, queryCopyData, setCopyData } from 'src/common/local-copy'
import {
  clamp, getCoord, isWithinPage, overlap, RFV2, RFV4,
} from 'src/common/math'
import { extractRectBuffer } from 'src/data/image'
import {
  cropTimeNeedleRegion, extractTimeNeedleRegion, ReadonlyTimeNeedleRegion, replaceTimeNeedleRegion,
} from 'src/data/time-needle/region'
import { copyTimeNeedleImage, isEmptyTimeNeedleImage } from 'src/data/time-needle/time-needle-image'
import { RootState } from 'src/store'
import { addUndoableAction, UndoableAction, UndoDomain } from 'src/undo'
import { BaseAction, registerShortcut } from './base'
import { ActionContext, KeyOptions } from './common'
import { UndoableRectAction } from './rect'

enum SelectState {
  NONE = 0,
  SELECTING,
  EDITING,
  PASTING,
}

type EdgeEdit = readonly [RFV4, 0 | 1 | 2 | 3]

export default class Select extends BaseAction {
  protected state = SelectState.NONE

  protected edgeEdit = [[0, 0, 0, 0], 0] as EdgeEdit

  protected selStart = [0, 0] as RFV2

  protected clearSel = false

  protected copyAABB = [0, 0, 0, 0] as RFV4

  // note: the` copyImage` property is necessary because
  // the data from `queryCopyData()` may be invalid
  // in case the copy data is bigger than allowed by `localStorage`
  constructor(
    protected copyImage = null as ReadonlyTimeNeedleRegion,
  ) {
    super()
  }

  isIdle() { return this.state === SelectState.NONE }

  isPasting() { return this.state === SelectState.PASTING }

  isSelecting() { return this.state === SelectState.SELECTING }

  isEditing() { return this.state === SelectState.EDITING }

  onMouseDown(ctx: ActionContext, x: number, y: number, button: number): void {
    const {
      selection, transform, M, updateSelection, clearSelection,
    } = ctx
    if(button === 0) {
      this.selStart = getCoord([x, y], M, transform.pageDims, Math.floor)
      if(this.isPasting()) {
        // stay in pasting mode until we paste or abort <Esc>
        return
      }
      // decompose current selection
      const [selLeft, selBottom, selRight, selTop] = selection
      const selWidth = (selRight - selLeft)
      const selHeight = (selTop - selBottom)
      const selArea = selWidth * selHeight
      const hasNonTrivialSelection = selArea > 1
      // if it doesn't have a selection yet, start selecting
      if(!hasNonTrivialSelection || this.clearSel) {
        // switch to selecting
        this.state = SelectState.SELECTING
        if(isWithinPage(this.selStart, transform.pageDims)) {
          const [sx, sy] = this.selStart
          // start with selection of the cell
          updateSelection([sx, sy, sx + 1, sy + 1])
        } else {
          // clear selection
          clearSelection()
        }
      } else if(
        this.selStart[0] === selLeft ||
        this.selStart[0] === selRight - 1 ||
        this.selStart[1] === selBottom ||
        this.selStart[1] === selTop - 1
      ) {
        // check that we're inside the selection
        // and do nothing otherwise
        if(
          this.selStart[0] < selLeft ||
          this.selStart[0] >= selRight ||
          this.selStart[1] < selBottom ||
          this.selStart[1] >= selTop
        ) {
          this.state = SelectState.NONE
          return
        }
        // switch to editing the selection
        // check if we're editing a corner or edge
        for(const [[editX, editY], otherPt] of [
          [[selLeft, selBottom], [selRight - 1, selTop - 1]],
          [[selLeft, selTop - 1], [selRight - 1, selBottom]],
          [[selRight - 1, selBottom], [selLeft, selTop - 1]],
          [[selRight - 1, selTop - 1], [selLeft, selBottom]],
        ] as const) {
          // if we match a corner, then treat as a new selection
          // while using the other corner as starting point
          if(this.selStart[0] === editX && this.selStart[1] === editY) {
            this.state = SelectState.SELECTING
            this.selStart = otherPt
            return
          }
        }
        // else we're editing from within an edge
        const edges = [
          selLeft, selBottom, selRight - 1, selTop - 1,
        ] as const
        for(const [coord, edgeIdx] of [
          [this.selStart[0], 0],
          [this.selStart[1], 1],
          [this.selStart[0], 2],
          [this.selStart[1], 3],
        ] as const) {
          // check if this is the edge
          if(coord === edges[edgeIdx]) {
            this.state = SelectState.EDITING
            this.edgeEdit = [edges, edgeIdx]
            return
          }
        }
        // should not reach here since we must have found
        // at least an edge given the initial condition
        console.error('Neither a corner nor an edge')
      } else {
        this.state = SelectState.NONE
      }
    } else {
      super.onMouseDown(ctx, x, y, button)
    }
  }

  onMouseMove(ctx: ActionContext, x: number, y: number, buttons: number): void {
    if(this.isSelecting()) {
      const { transform, M, updateSelection } = ctx
      const [sx, sy] = this.selStart
      const [cx, cy] = getCoord([x, y], M, transform.pageDims, Math.floor)
      const [left, right] = cx < sx ? [cx, sx + 1] : [sx, cx + 1]
      const [bottom, top] = cy < sy ? [cy, sy + 1] : [sy, cy + 1]
      updateSelection([
        clamp(left, 0, transform.pageDims[0] - 1),
        clamp(bottom, 0, transform.pageDims[1] - 1),
        clamp(right, 1, transform.pageDims[0]),
        clamp(top, 1, transform.pageDims[1]),
      ])
    } else if(this.isEditing()) {
      const { transform, M, updateSelection } = ctx
      const [edges, edgeIdx] = this.edgeEdit
      const [mx, my] = getCoord([x, y], M, transform.pageDims, Math.floor)
      let [sx, sy, cx, cy] = edges
      switch(edgeIdx) {
      case 0: sx = mx; break // left
      case 1: sy = my; break // bottom
      case 2: cx = mx; break // right
      case 3: cy = my; break // top
      default: throw `Invalid edge index ${edgeIdx}`
      }
      const [left, right] = cx < sx ? [cx, sx + 1] : [sx, cx + 1]
      const [bottom, top] = cy < sy ? [cy, sy + 1] : [sy, cy + 1]
      updateSelection([
        clamp(left, 0, transform.pageDims[0] - 1),
        clamp(bottom, 0, transform.pageDims[1] - 1),
        clamp(right, 1, transform.pageDims[0]),
        clamp(top, 1, transform.pageDims[1]),
      ])
    } else {
      super.onMouseMove(ctx, x, y, buttons)
    }
  }

  onMouseUp(ctx: ActionContext, x: number, y: number, button: number): void {
    if(this.isPasting()) {
      const { transform, M } = ctx
      const endPos = getCoord([x, y], M, transform.pageDims, Math.floor)
      // if we just did a click (press and release at same coord)
      // then this trigger the pasting action
      if(this.selStart[0] === endPos[0] && this.selStart[1] === endPos[1]) {
        this.paste(ctx, endPos)
        this.state = SelectState.NONE
      }
    }
    this.state = SelectState.NONE
    this.dragging = false
  }

  onKeyDown(ctx: ActionContext, key: number, opts: KeyOptions): void {
    // switch to clear-select mode
    if(key === KEYCODE_SHIFT) {
      this.clearSel = true;
      return
    }
    const { ctrl, preventDefault } = opts// get selection data
    const { selection, updatePasteData, clearSelection } = ctx
    const [selLeft = 0, selBottom = 0, selRight = 0, selTop = 0] = selection
    const hasSelection = (selRight - selLeft) * (selTop - selBottom) > 0
    // special selection clear
    if(!ctrl && key === KEYCODE_SPACE && hasSelection) {
      preventDefault()
      clearSelection() // clear any selection
      updatePasteData(null)
      this.state = SelectState.NONE // abort any pasting
      return
    }
    super.onKeyDown(ctx, key, opts)
    // clearing the seleciton
    if(key === KEYCODE_ESC && this.isPasting()) {
      // clearSelection()
      preventDefault()
      updatePasteData(null)
      this.state = SelectState.NONE // abort any pasting
      return
    }
    // copy / cut / paste actions
    if(!opts.ctrl) { return }
    if(key === KEYCODE_C) {
      // copy
      preventDefault()
      if(hasSelection) this.copy(ctx, false)
    } else if(key === KEYCODE_X) {
      // cut
      preventDefault()
      if(hasSelection) this.copy(ctx, true)
    } else if(key === KEYCODE_V) {
      // paste
      preventDefault()
      this.pastePreview(ctx)
    }
  }

  onKeyUp(ctx: ActionContext, key: number, opts: KeyOptions): void {
    if(key === KEYCODE_SHIFT) {
      this.clearSel = false
    } else {
      super.onKeyUp(ctx, key, opts)
    }
  }

  protected copy(
    ctx: ActionContext,
    cutting = false,
    copyImage?: ReadonlyTimeNeedleRegion,
  ) {
    const { selection, tnimage } = ctx
    if(!copyImage) {
      this.copyImage = extractTimeNeedleRegion(ctx.tnimage, selection)
    } else {
      this.copyImage = copyImage
    }
    this.copyAABB = selection.slice() as RFV4

    // store in edit plugin
    setCopyData(this.copyImage)

    // if cutting, then trigger code clearing as an empty rect action
    if(cutting) {
      const { dispatch } = ctx
      // note: pixel [0] clears to StitchCode.MISS as default value
      const buffer = extractRectBuffer(tnimage.cdata, selection, [0])
      dispatch(addUndoableAction(new UndoableRectAction(buffer)))
    }
  }

  copyAction(cutting = false, clearSel = false) {
    return (ctx: ActionContext) => {
      this.copy(ctx, cutting)
      if(clearSel) ctx.clearSelection()
    }
  }

  setCopyAction(
    copyImage: ReadonlyTimeNeedleRegion,
    cutting = false,
    clearSel = false,
  ) {
    return (ctx: ActionContext) => {
      this.copy(ctx, cutting, copyImage)
      if(clearSel) ctx.clearSelection()
    }
  }

  protected getPasteData() {
    if(isCopyDataValid()) {
      const ci = queryCopyData()
      if(ci) {
        return ci
      }
    } else {
      return this.copyImage
    }
  }

  hasPasteData() {
    return !!this.getPasteData()
  }

  pastePreview({
    clearSelection,
    updatePasteData,
  }: ActionContext) {
    clearSelection()
    // check whether we have anything to paste
    const pasteData = this.getPasteData()
    if(!pasteData) {
      return // nothing to do
    }
    updatePasteData(pasteData.cdata)
    this.state = SelectState.PASTING
  }

  get pastePreviewAction() {
    return (ctx: ActionContext) => this.pastePreview(ctx)
  }

  protected paste({
    dispatch,
    transform: { pageDims },
    updatePasteData,
  }: ActionContext, [x, y]: RFV2) {
    // check that the paste overlaps the panel
    const pasteData = this.getPasteData()
    const { width, height } = pasteData.cdata
    const pasted = [
      x, y,
      x + width - 1,
      y + height - 1,
    ] as const
    const page = [0, 0, pageDims[0] - 1, pageDims[1] - 1] as const

    // /!\ overlap requires inclusive rectangles, not exclusive as usual
    if(overlap(pasted, page)) {
      // crop the paste data to fit in the page
      const croppedData = cropTimeNeedleRegion(
        { ...pasteData, x, y },
        pageDims[0],
        pageDims[1],
      )

      // then we can trigger an action
      dispatch(addUndoableAction(new UndoablePasteAction(croppedData)))
    }
    // we remove the paste data anyway
    updatePasteData(null)
  }

  pasteAction([x, y]: RFV2) {
    return (ctx: ActionContext) => {
      this.paste(ctx, [x, y])
    }
  }

  static withPaste(
    image: ReadonlyTimeNeedleRegion,
  ) {
    const sel = new Select(image)
    setCopyData(image)
    return [
      sel,
      (ctx: ActionContext) => { sel.pastePreview(ctx) },
    ] as const
  }

  onActionChange({ updatePasteData }: ActionContext): void {
    updatePasteData(null) // clear the paste data to be safe
  }
}
registerShortcut(KEYCODE_SPACE, Select)

/**
 * Undoable paste action of a time-needle region onto a time-needle image
 */
export class UndoablePasteAction
implements UndoableAction {
  readonly domain = UndoDomain.EDITOR

  constructor(
    protected readonly region: ReadonlyTimeNeedleRegion,
  ) {
    if(isEmptyTimeNeedleImage(region)) {
      throw 'Pasting an empty time-needle region'
    }
  }

  applyTo(state: RootState): [RootState, UndoableAction] {
    const tnimage = copyTimeNeedleImage(state.canvas.tnimage)
    const undoRegion = replaceTimeNeedleRegion(tnimage, this.region)
    const undo = new UndoablePasteAction(undoRegion)
    return [
      {
        ...state,
        canvas: {
          ...state.canvas,
          tnimage,
        },
      },
      undo,
    ]
  }
}
