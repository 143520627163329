import { TimeNeedleTransformer } from './common'

export function swapRows(row1: TimeNeedleTransformer, row2: TimeNeedleTransformer): void {
  // swap stitch
  row1.swapWith(row2)
  // swap options
  row1.swapOptionsWith(row2)
}

export function swapBoundaryRows(s: TimeNeedleTransformer): TimeNeedleTransformer {
  const topRow = s.topmost()
  const bottomRow = s.bottommost()
  swapRows(topRow, bottomRow)
  return s
}

export function swapMoveUp(s: TimeNeedleTransformer): TimeNeedleTransformer {
  const rows = s.splitByRow()
  for(let i = 1; i < rows.length; ++i) {
    swapRows(rows[i - 1], rows[i])
  }
  return s
}

export function swapMoveDown(s: TimeNeedleTransformer): TimeNeedleTransformer {
  const rows = s.splitByRow()
  for(let i = rows.length - 1; i > 0; --i) {
    swapRows(rows[i - 1], rows[i])
  }
  return s
}

export function iterativelySwapBoundaryRows(s: TimeNeedleTransformer): TimeNeedleTransformer {
  const rows = s.splitByRow()
  // bottom to top
  for(let i = 1; i < rows.length; ++i) {
    swapRows(rows[i - 1], rows[i])
  }
  // (top-1) to bottom
  for(let i = rows.length - 2; i > 0; --i) {
    swapRows(rows[i - 1], rows[i])
  }
  return s
}

function isEmptyRow(s: TimeNeedleTransformer): boolean {
  return s.every((c) => c.isMiss())
}

export function moveEmptyRowsToTop(s: TimeNeedleTransformer): TimeNeedleTransformer {
  const rows = s.splitByRow()
  // get index of top non-empty row
  let topRow = rows.length - 1
  while(topRow > 0 && isEmptyRow(rows[topRow])) {
    --topRow
  }

  // go from bottom to non-empty top (excluded)
  for(let r = 0; r <= topRow;) {
    if(isEmptyRow(rows[r])) {
      // move empty row to top
      for(let i = r + 1; i <= topRow; ++i) {
        swapRows(rows[i - 1], rows[i])
      }
      // the top non-empty row is one row below now
      --topRow
    } else {
      ++r
    }
  }
  return s.empty().union(rows.slice(0, topRow + 1))
}
