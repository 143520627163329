import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { version as release } from 'src/common/env'

export default function init() {
  Sentry.init({
    release,
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1, // low to be safe, can adjust based on traffic
  })
}
