import { isYarnIndex, YarnIndexOrNone } from 'src/data/time-needle/options'
import {
  setDirection, setYarn,
  localOptions, parseLocalOptions,
  localBlockOptions, parseLocalBlockOptions,
  localStepOptions, parseLocalStepOptions,
  localGradedOptions, parseLocalGradedOptions,
} from 'src/macros'
import { useApplyMacro, useAppSelector, useUserEntry } from 'src/hooks'
import ContextMenuItem from './menu-item'

export default function SetterMenu() {
  const applyMacro = useApplyMacro()
  const sel = useAppSelector((state) => state.canvas.selection)
  const [lastYarn, setLastYarn] = useUserEntry('yarn', '3')
  const [lastOpts, setLastOpts] = useUserEntry('opts', 'xfer roller 100 stitch 8 speed 100')
  const [lastBlockOpts, setLastBlockOpts] = useUserEntry('blkopts', 'xfer roller 10 5 0 ... 0 25 25')
  const [lastStepOpts, setLastStepOpts] = useUserEntry('stpopts', 'knit roller 250, 200, 150 over 5, 100')
  const [lastGradedOpts, setLastGradedOpts] = useUserEntry('grdopts', 'xfer roller 250 to 100')
  return (
    <ContextMenuItem
      name="Set ..."
      menu
    >
      <ContextMenuItem
        name={`Set yarn (${lastYarn})`}
        prompt={['Yarn number [1 to 6]', lastYarn]}
        onInput={(yarnStr) => {
          const yarn = parseInt(yarnStr, 10)
          if(!isYarnIndex(yarn)) {
            alert(`Invalid yarn number ${yarnStr}`)
            return
          }
          setLastYarn(yarnStr)
          applyMacro((s) => setYarn(s, yarn))
        }}
      />
      <ContextMenuItem
        name="Set direction"
        prompt={['Direction (ltr, rtl or any)', 'any']}
        onInput={(dirStr) => {
          const dir = dirStr.startsWith('ltr') ? 0 : dirStr.startsWith('rtl') ? 1 : 2
          applyMacro((s) => setDirection(s, dir, dir !== 2))
        }}
      />
      <ContextMenuItem
        name="Set options"
        prompt={['Options: (knit|xfer|tuck|split) [roller R] [stitch S] [speed S] [dir D] ... [local]', lastOpts]}
        parser={parseLocalOptions}
        onParse={(opts, optStr) => {
          setLastOpts(optStr)
          applyMacro((s) => localOptions(s, opts))
        }}
      />
      <ContextMenuItem
        name="Set options by block"
        prompt={['Options: (knit|xfer) [roller R1 R2 ... RN] [stitch ...] [speed ...] [dir ...]', lastBlockOpts]}
        parser={parseLocalBlockOptions}
        onParse={(opts, optStr) => {
          setLastBlockOpts(optStr)
          applyMacro((s) => localBlockOptions(s, opts))
        }}
      />
      <ContextMenuItem
        name="Set options by steps"
        prompt={['Options: (knit|xfer) [roller R1, R2, R3 over N3, ...] [stitch ...] [speed ...] [dir ...]', lastStepOpts]}
        parser={parseLocalStepOptions}
        onParse={(opts, optStr) => {
          setLastStepOpts(optStr)
          applyMacro((s) => localStepOptions(s, opts))
        }}
      />
      <ContextMenuItem
        name="Set graded options"
        prompt={['Options: (knit|xfer) [roller R1 to RN] [stitch S1 to SN] [speed ...]', lastGradedOpts]}
        parser={parseLocalGradedOptions}
        onParse={(opts, optStr) => {
          setLastGradedOpts(optStr)
          applyMacro((s) => localGradedOptions(s, opts))
        }}
      />
      <ContextMenuItem
        name="Set selection"
        prompt={['New selection: left bottom right top', sel.join(' ')]}
        onInput={(selStr) => {
          const newSel = selStr.split(/[ ,]+/).flatMap((token) => (token.length > 0 ? [parseInt(token, 10)] : []))
          if(newSel.length !== 4) {
            alert('Selection requires 4 values: left bottom right top')
            return
          } if(newSel.some((n) => n < 0 || isNaN(n))) {
            alert(`Invalid selection ${newSel.join(' ')}`)
            return
          }
          const [left, bottom, right, top] = newSel
          applyMacro((s) => s.reselect([bottom, top], [left, right]))
        }}
        chainable
      />
    </ContextMenuItem>
  )
}
