import { RFV4 } from 'src/common/math'
import { addRows } from 'src/data/time-needle/topology'
import {
  parseUserOptions, SelectorLike, ShapingSide, SingleShapingSide, TimeNeedleCellReader, TimeNeedleCellWriter, TimeNeedleTransformer,
} from './common'

export interface DecreaseTilingOptions {
  repeat: number
  period: number
  margins: [number[], number[]]
  sides: ShapingSide | [SingleShapingSide, SingleShapingSide]
  insert: boolean
  round: (x: number) => number
}
type PartialTilingOptions = {
  [K in keyof DecreaseTilingOptions]?: DecreaseTilingOptions[K]
}

export function parseDecreaseOptions(tilingStr: string, sel: RFV4): DecreaseTilingOptions | string {
  // two forms:
  //    repeat (N) decreasing each (P) (SIDES) at (M1[, M2, ...]) [insert] [RND]
  //    decrease by (D) over (N) (SIDES) at (M1[, M2, ...]) [insert] [RND]
  // note: multi-margins:
  //    decrease by D over N left at M1, M2 ..., right at Ma, Mb ... insert
  //    decrease by D over N left, right at M1, M2 ...
  const opts: PartialTilingOptions = {
    margins: [[], []],
  }
  const setSide = (side: ShapingSide) => {
    if(opts.sides === undefined) {
      opts.sides = side
    } else if(opts.sides === ShapingSide.BOTH) {
      return 'Invalid secondary side token when the first was both'
    } else if(!Array.isArray(opts.sides)) {
      if(opts.sides === side) {
        return 'Cannot specify same side token twice'
      } if(side === ShapingSide.BOTH) {
        return 'Invalid secondary side token both'
      }
      opts.sides = [opts.sides, side]
    } else {
      return 'Invalid third side token'
    }
  }
  let multiply = false
  const addMargin = (m: number) => {
    if(Array.isArray(opts.sides)) {
      const margins = opts.margins[1]
      if(multiply) {
        for(let i = 0; i < m - 1; ++i) {
          margins.push(margins[margins.length - 1])
        }
        multiply = false
      } else {
        margins.push(m)
      }
    } else if(opts.sides !== undefined) {
      const margins = opts.margins[0]
      if(multiply) {
        for(let i = 0; i < m - 1; ++i) {
          margins.push(margins[margins.length - 1])
        }
        multiply = false
      } else {
        margins.push(m)
      }
    } else {
      return 'Invalid margin token before side token'
    }
  }
  let decr: number
  let over: number
  const error = parseUserOptions(tilingStr, {
    left: () => setSide(ShapingSide.LEFT),
    right: () => setSide(ShapingSide.RIGHT),
    both: () => setSide(ShapingSide.BOTH),
    for: () => { multiply = true },
    decreasing: () => {},
    insert: () => { opts.insert = true },
    ...['round', 'ceil', 'floor'].reduce((props, key) => ({ ...props, [key]: () => { opts.round = Math[key] } }), {}),
  }, {
    repeat: (token: string) => {
      opts.repeat = parseInt(token, 10)
    },
    each: (token: string) => {
      opts.period = parseFloat(token)
    },
    at: (token: string) => {
      const m = parseInt(token, 10)
      if(isNaN(m) || m < 0) {
        return `Invalid margin token ${token}`
      }
      return addMargin(m)
    },
    decrease: (token: string) => {
      if(token !== 'by') {
        decr = parseInt(token, 10)
      }
    },
    over: (token: string) => {
      over = parseInt(token, 10)
    },
  })
  if(typeof error === 'string') {
    return error
  }

  // validating the result
  if(opts.sides === undefined) {
    return 'Missing at least one side: left, right or both'
  }

  // from decrease / over to repeat / period
  const hasDecr = typeof decr === 'number'
  const hasOver = typeof over === 'number'
  if(hasDecr || hasOver) {
    if(!hasDecr) {
      return 'Over token without decrease token'
    } if(!hasOver) {
      return 'Decrease token without over token'
    } if(typeof opts.repeat === 'number' || typeof opts.period === 'number') {
      return 'Cannot mix "decrease over" and "repeat each" forms'
    }
    // make sure length can be represented
    const tileH = sel[3] - sel[1]
    let length = Math.max(over, decr * tileH)
    length += length % tileH
    // comptue repeat and period values
    opts.repeat = length / tileH
    opts.period = opts.repeat / decr
    console.log(`Using repeat=${opts.repeat}, period=${opts.period}`)
  } else {
    // validate repeat form
    const hasRepeat = typeof opts.repeat === 'number'
    const hasPeriod = typeof opts.period === 'number'
    if(!hasRepeat && !hasPeriod) {
      return 'Neither a repeat nor a decrease form'
    } if(!hasRepeat) {
      return 'Missing repeat token'
    } if(!hasPeriod) {
      return 'Missing each token'
    }
  }

  // normalize margins
  const [m0, m1] = opts.margins
  if(m0.length === 0 && m1.length === 0) {
    opts.margins = [[0], [0]]
  } else if(m0.length === 0) {
    opts.margins = [m1.slice(), m1]
  } else if(m1.length === 0) {
    opts.margins = [m0, m0.slice()]
  }

  return {
    repeat: opts.repeat,
    period: opts.period,
    margins: opts.margins,
    sides: opts.sides,
    insert: !!opts.insert,
    round: opts.round ?? Math.ceil,
  }
}

export function generateDecreaseTiling(s: SelectorLike, {
  repeat, period, margins: [leftMargins, rightMargins], sides, insert, round,
}: DecreaseTilingOptions) {
  const {
    left, right, top,
    width: tileW,
    height: tileH,
  } = s.filter((c) => c.isKnit() || c.isTuck()).getExtents()
  const tile = s.wales([left, right])
  const tileRows = tile.splitByRow()
  const numRows = tileH * repeat
  let topRow: TimeNeedleTransformer
  if(insert) {
    [topRow] = addRows(tileRows[tileRows.length - 1], 'above', numRows) // insert rows above
  } else {
    topRow = TimeNeedleTransformer.fromSelector(tileRows[tileRows.length - 1])
    // clear block above
    const t = TimeNeedleTransformer.fromSelector(s)
    t.fullCourses([top, top + numRows]).miss() // clear rows above
  }
  console.assert(topRow.length === tileW, 'The row selection size is invalid')
  // generate decrease tiling in rows above topRow
  const across = Array.isArray(sides)
  const leftDecreasing = sides !== ShapingSide.RIGHT
  const rightDecreasing = sides !== ShapingSide.LEFT
  const getShift = (side: SingleShapingSide, [prevDecrease, currDecrease]: [number, number], tileRow: number): number => {
    if((!leftDecreasing && side === ShapingSide.LEFT) || (!rightDecreasing && side === ShapingSide.RIGHT)) {
      return 0
    } if(!across) {
      return currDecrease
    }
    // stepped transition
    if(tileRow === 0) {
      return side === sides[0] ? currDecrease : prevDecrease
    }
    return currDecrease
  }
  for(let ty = 0, mi = 0; ty < repeat; ++ty) {
    // compute left and right shifts
    const decreasePair = [
      round(ty / period), // previous decrease value
      round((ty + 1) / period), // current decrease value
    ] as [number, number]
    for(let ry = 0; ry < tileH; ++ry, ++mi) {
      const tileRow = tileRows[ry]
      const newRow = topRow.neighbor(0, 1 + ty * tileH + ry)
      // copy options
      const opts = tileRow.first().getOptions()[0]
      newRow.options(opts)

      // compute left and right shifts
      const leftShift = getShift(ShapingSide.LEFT, decreasePair, ry)
      const rightShift = getShift(ShapingSide.RIGHT, decreasePair, ry)

      // resolve margins
      const leftMargin = leftMargins[mi % leftMargins.length]
      const rightMargin = rightMargins[mi % rightMargins.length]

      // copy tile pattern + stitch while taking shifts into account
      //
      // target:
      // --------LLLLLLCCCCCCCCCCCCCCCRRRRRR--------
      // |      |     |               |     |      |
      // leftShift    |               |   rightShift
      //              |               |
      // source tile: |               |
      // LLLLLLccccccccCCCCCCCCCCCCCCCccccccccRRRRRR
      // |    |                               |    |
      // leftMargin                      rightMargin
      // |                                         |
      // +---------------- tileW ------------------+
      //
      for(let x = leftShift; x < tileW - rightShift; ++x) {
        // three regions
        let src: TimeNeedleCellReader
        if(x < leftShift + leftMargin) {
          // left margin (L)
          const tileX = x - leftShift
          src = tileRow.getCell(tileX)
          //
        } else if(x >= tileW - rightShift - rightMargin) {
          // right margin (R)
          // const dxFromRight = tileW - rightShift - x
          // const tileX = tileW - dxFromRight
          const tileX = x + rightShift
          src = tileRow.getCell(tileX)
        } else {
          // center
          src = tileRow.getCell(x)
        }
        const trg = newRow.getCell(x)
        trg.copy(src)
      }
    }
  }
  // extend selection to contain whole block + starting selection
  return topRow.extendUp(numRows).union(s)
}
