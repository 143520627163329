interface ExportOptions {
  type?: string
  link?: HTMLAnchorElement
}

export function exportFile(fname: string, data: BlobPart, { type = 'octet/stream', link }: ExportOptions = {}) {
  // @see https://stackoverflow.com/questions/13405129/javascript-create-and-save-file#30832210
  const fromLink = !!link;
  const blob = data instanceof Blob ? data : new Blob([data], { type });
  const url = URL.createObjectURL(blob);
  link = exportDataURL(fname, url, { link });

  // revoke url after click
  setTimeout(() => {
    if(!fromLink) document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }, 100);
}

export function exportDataURL(fname: string, url: string, { link }: ExportOptions = {}) {
  // @see https://stackoverflow.com/questions/13405129/javascript-create-and-save-file#30832210
  const fromLink = !!link;
  if(!link) link = document.createElement('a');
  link.href = url;
  link.download = fname;
  // add link to body if not from link
  if(!fromLink) {
    document.body.appendChild(link);
    link.click(); // trigger press
  }
  return link;
}
