import { ReadonlyUint8Array, RFV4 } from 'src/common/types';
import {
  copyRectBufferToImage,
  cropRectBuffer,
  extractRectBuffer,
  getImageExtents,
  isEmptyRectBuffer,
} from 'src/data/image';
import MachineType from './machine-type';
import TimeNeedleImage, {
  emptyTimeNeedleImage,
  isEmptyTimeNeedleImage,
  ReadonlyTimeNeedleImage,
} from './time-needle-image';

/**
 * A region of a time-needle image
 */
export interface TimeNeedleRegion extends TimeNeedleImage {
  x: number
  y: number
}

/**
 * Read-only variant of the time-needle region
 */
export interface ReadonlyTimeNeedleRegion extends ReadonlyTimeNeedleImage {
  readonly x: number
  readonly y: number
}

/**
 * Creates an empty time-needle region
 */
export function emptyTimeNeedleRegion(type: MachineType = 'kniterate', x = 0, y = 0): TimeNeedleRegion {
  return { ...emptyTimeNeedleImage(type), x, y }
}

export function optRange(img: ReadonlyTimeNeedleImage, [left, bottom, right, top]: RFV4) {
  return [
    0, // left of options data
    bottom,
    img.odata.width, // right of options data
    top,
  ] as const
}

/**
 * Extract a time-needle region from a time-needle image.
 *
 * The filling value `fill` can be one of three types:
 * - `undefined` (or missing), then the data from `img` is used
 * - a single pixel array, then the code is replaced with this, but not the options
 * - two pixel arrays, then the first is for the code filling, and the second corresponds to a row of options
 *
 * @param img the source image, untouched
 * @param rect the region to extract
 * @param fill an optional filling value to use instead of the source image pixels
 * @param tileX the number of repetitions along the X axis (1 by default)
 * @param tileY the number of repetitions along the Y axis (1 by default)
 * @returns a new region
 */
export function extractTimeNeedleRegion(
  img: ReadonlyTimeNeedleImage,
  rect: RFV4,
  fill?: ReadonlyUint8Array | [ReadonlyUint8Array, ReadonlyUint8Array],
  tileX = 1,
  tileY = 1,
): TimeNeedleRegion {
  const cdata = extractRectBuffer(img.cdata, rect, Array.isArray(fill) ? fill[0] : fill, tileX, tileY)
  const odata = extractRectBuffer(img.odata, optRange(img, rect), Array.isArray(fill) ? fill[1] : void 0, 1, tileY)
  const [x, y] = rect
  return {
    cdata,
    odata,
    sdata: [], // XXX we may want to capture the strings related to that region?
    type: img.type,
    x,
    y,
  }
}

/**
 * Crop a time-needle region so that its extents fit
 * within a maximum workspace size.
 *
 * This can lead to three cases similarly to {@link cropRectBuffer}:
 * - the workspace is large enough: the source region is returned
 * - the workspace partially overlaps: a new cropped region is returned
 * - the workspace does not overlap: an empty region is returned
 *
 * In all situations, the returned region keeps the same anchor (`x` and `y`).
 *
 * @param region the source region
 * @param maxWidth the maximum workspace width
 * @param maxHeight the maximum workspace height
 * @see {@link cropRectBuffer}
 */
export function cropTimeNeedleRegion(
  region: ReadonlyTimeNeedleRegion,
  maxWidth: number,
  maxHeight: number,
): ReadonlyTimeNeedleRegion {
  const { x, y, cdata } = region
  const codeBuffer = { ...cdata, x, y }
  const croppedCode = cropRectBuffer(codeBuffer, maxWidth, maxHeight)
  // if cropping the code is an identity
  // then cropping the region is also an identity
  if(croppedCode === codeBuffer) {
    return region
  }
  // check if empty
  if(isEmptyRectBuffer(croppedCode)) {
    return emptyTimeNeedleRegion(region.type)
  }
  // otherwise, we need to cut things, but which?
  // if the height did not change, then only the code needed a change
  const { odata, type } = region
  if(croppedCode.height === codeBuffer.height) {
    console.assert(
      croppedCode.y === y,
      'Height did not change, but the y coordinate changed',
    )
    return {
      cdata: croppedCode,
      odata,
      sdata: [],
      type,
      x: croppedCode.x,
      y,
    }
  }
  // otherwise we need to crop the options too
  const croppedOpts = cropRectBuffer({ ...odata, x: 0, y }, odata.width, maxHeight)
  console.assert(
    croppedCode.y === croppedOpts.y,
    'Code and options were cropped differently in height',
  )
  return {
    cdata: croppedCode,
    odata: croppedOpts,
    sdata: [],
    type,
    x: croppedCode.x,
    y: croppedCode.y,
  }
}

/**
 * Copy the content of a region onto a time-needle image.
 *
 * @param srcRegion the source region
 * @param trgImg the target image
 */
export function copyRegionToImage(
  srcRegion: ReadonlyTimeNeedleRegion,
  trgImg: TimeNeedleImage,
) {
  const {
    x, y, cdata, odata,
  } = srcRegion
  // copy cdata
  copyRectBufferToImage({
    ...cdata, x, y,
  }, trgImg.cdata)
  // copy odata
  copyRectBufferToImage({
    ...odata, x: 0, y,
  }, trgImg.odata)
}

/**
 * Transfer the content of a region onto an image while returning
 * a copy of the region that was modified, before modification.
 *
 * The region must be fully contained in the image.
 *
 * @param img source image to be modified
 * @param region the target region to transfer onto the image
 * @returns a copy of the source region at the target region, before transfer
 */
export function replaceTimeNeedleRegion(
  img: TimeNeedleImage,
  region: ReadonlyTimeNeedleRegion,
): TimeNeedleRegion {
  const { x, y } = region
  // for empty regions, do nothing
  if(isEmptyTimeNeedleImage(region)) {
    return emptyTimeNeedleRegion(img.type, x, y)
  }

  // extract copy of current region
  const { width, height } = region.cdata
  const prevRegion = extractTimeNeedleRegion(img, [x, y, x + width, y + height])

  // copy data to the image
  copyRegionToImage(region, img)

  return prevRegion
}

/**
 * Trim empty borders of an image
 *
 * @param tni the source image
 * @param isNonEmpty an optional per-pixel validity function
 * @returns either a trimmed image region or `null` if the image is full
 * @see {@link getImageExtents}
 */
export function trim(
  tni: ReadonlyTimeNeedleImage,
  isNonEmpty?: (px: ReadonlyUint8Array) => boolean,
): TimeNeedleRegion | null {
  const [left, bottom, right, top] = getImageExtents(tni.cdata, isNonEmpty)

  // empty extents => empty image
  if(left >= right || bottom >= top) {
    return emptyTimeNeedleRegion(tni.type)
  }

  // are the extents the full image?
  if(
    left === 0 && bottom === 0 &&
    right === tni.cdata.width &&
    top === tni.cdata.height
  ) {
    return null // nothing to trim
  }

  // otherwise we trim by extracting the image region
  return extractTimeNeedleRegion(tni, [left, bottom, right, top])
}
