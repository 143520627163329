import {
  isKnitArguments,
  isSplitArguments,
  Issue,
  issue,
  Needle,
  SimulationBlock,
} from 'src/data/compiler';

export function floatDistancesShouldBeSmall(
  blocks: SimulationBlock[],
  issues: Issue[],
  maxFloatDist: number,
) {
  for(const { commands, startState, endState } of blocks) {
    const needles = [] as Needle[]
    for(const { cmd, args, src } of commands) {
      // only consider actions that create loops
      if(cmd === 'knit' || cmd === 'tuck') {
        if(isKnitArguments(args)) {
          const [, n] = args
          needles.push(n)
        } else {
          issues.push(issue('invalid-knitout').invalid(
            `Invalid arguments: ${args.join(' ')}`,
          ).at(src))
        }
      } else if(cmd === 'split') {
        if(isSplitArguments(args)) {
          const [, sn] = args
          needles.push(sn)
        } else {
          issues.push(issue('invalid-knitout').invalid(
            `Invalid split arguments: ${args.join(' ')}`,
          ).at(src))
        }
      } else {
        continue
      }
    } // endfor { cmd, args, src } of commnads

    // verify the float at the targetted needles
    for(const n of needles) {
      const loops = endState.getNeedleLoops(n)
      for(const loop of loops) {
        if(loop.previous.length === 0) {
          // yarn is introduced => no float within bed
          continue
        }
        const loopRow = Array.isArray(loop.source) ? loop.source[0] : loop.source
        for(const [pn, prevLoop] of loop.previous) {
          const noff = n.frontOffset(endState.racking)
          const poff = pn.frontOffset(startState.racking)
          const floatDist = Math.abs(noff - poff)
          if(floatDist > maxFloatDist) {
            issues.push(issue('max-float-dist').warn(
              `Float distance is too large: ${floatDist} > ${maxFloatDist} (max)`,
            ).between(
              loop.source,
              typeof loopRow === 'number' ? [loopRow, poff] : prevLoop.source,
            ))
          }
        } // endfor [pn, prevLoop] of loop.previous
      } // endfor loop of loops
    } // endfor n of needles
  } // endfor { commands, startState, endState } of blocks
}
