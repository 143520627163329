import { useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { Design, getDesign, makeDesign } from 'src/api'
import Palette from 'src/data/time-needle/palette'
import { Deserialize, Serialize } from 'src/data/serialization'
import { setTitle } from 'src/editor/reducers/design'
import { makeGridDims } from 'src/common/grid-dims'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from 'src/common/loading'
import { addNotification, NotificationType } from 'src/editor/notification'
import { setAspectRatio } from 'src/editor/settings'
import Editor from 'src/editor'
import {
  deferActions, enableCanvas, setActiveViewMode, setTimeNeedleImage,
} from 'src/editor/time-needle/slice'
import { centerHome, updateThumbnails } from 'src/editor/time-needle/action'
import { getThumbnail } from 'src/data/time-needle/thumbnail'
import { createTimeNeedleImage, ReadonlyTimeNeedleImage } from 'src/data/time-needle/time-needle-image'
import { ViewMode } from 'src/data/time-needle/view-mode'

export default function DesignLoader() {
  const { id: paramId } = useParams()
  const [authToken, setAuthToken] = useState('')
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    (async () => {
      const tok = isAuthenticated ? await getAccessTokenSilently() : null
      setAuthToken(tok)
    })()
  }, [isAuthenticated, getAccessTokenSilently])

  if(isLoading) {
    return <Loading />
  }

  const {
    designId, tnimage, columnsPerCm, rowsPerCm, designName,
  } = useAppSelector(({
    canvas: { tnimage },
    design: { title, id },
    settings: {
      columnsPerCm, rowsPerCm,
    },
  }) => ({
    designId: id, tnimage, columnsPerCm, rowsPerCm, designName: title,
  }), shallowEqual)

  const [design, setDesign] = useState<Design>(null)
  const dispatch = useAppDispatch()

  const fetchDesign = async (id: string, token: string) => {
    if(token && token === '' || !token) return

    const design = await getDesign(id, token)
    setDesign(design)
  }

  useEffect(() => {
    if(designId === paramId) {
      const dsgn = makeDesign(
        designName,
        Serialize(tnimage),
        Palette.default().toColorArray(),
        tnimage.cdata.width * columnsPerCm,
        tnimage.cdata.height * rowsPerCm,
        columnsPerCm,
        rowsPerCm,
        getThumbnail(tnimage, ViewMode.PATTERN),
      )
      setDesign(dsgn)
    } else {
      fetchDesign(paramId, authToken)
    }
  }, [authToken])

  const updateData = (
    tni: ReadonlyTimeNeedleImage,
  ) => {
    setTimeout(() => {
      dispatch(setTimeNeedleImage(tni))
      dispatch(deferActions([
        centerHome,
        updateThumbnails(),
      ]))
    })
  }

  useEffect(() => {
    if(design !== null) {
      if(design.buck.length === 0) {
        // then this design is empty, and we need to populate a default one
        // canvas.ts has already set initialState in redux
        const tni = createTimeNeedleImage(
          64,
          153,
        )

        updateData(tni)
      } else {
        const gridDims = makeGridDims(design.widthInCm, design.heightInCm, [design.stitchesPerCm * 10, design.rowsPerCm * 10])
        const issues = [] as string[]
        const p = Deserialize(design.buck, issues)
        for(const issue of issues) {
          dispatch(addNotification(issue, NotificationType.ERROR, 3000));
        }

        updateData(p)
        const newColumnsPerCm = gridDims[2].stitchesPer10Cm / 10.0
        const newRowsPerCm = gridDims[2].rowsPer10Cm / 10.0
        if(newColumnsPerCm !== columnsPerCm || newRowsPerCm !== rowsPerCm) {
          console.warn(
            'Design changed the aspect ratio (colsPerCm, rowsPerCm):',
            newColumnsPerCm,
            newRowsPerCm,
          )
          dispatch(setAspectRatio([
            newColumnsPerCm,
            newRowsPerCm,
          ]))
        }
        dispatch(setActiveViewMode(ViewMode.PATTERN))
      }

      dispatch(setTitle(design.name))
      dispatch(enableCanvas())
    }
  }, [design])

  // usePrompt()
  if(design === null) {
    return <Loading />
  }

  return <Editor />
}
