import { exportPNG } from 'src/data/image/dom'
import { getImage } from './get-image'
import { ReadonlyTimeNeedleImage } from './time-needle-image'
import { ViewMode } from './view-mode'

export function exportImage(
  img: ReadonlyTimeNeedleImage,
  view: ViewMode = ViewMode.PATTERN,
  fname = 'download.png',
) {
  const { data, width, height } = getImage(img, view)
  exportPNG(data, width, height, 3, fname)
}
