import { KEYCODE_ESC, KEYCODE_T } from 'src/common/keyboard'
import { BaseAction, registerShortcut } from './base'

export default class Translate extends BaseAction {
  isDragButton(button: number): boolean {
    return button !== 2 // all but the context menu
  }
}
registerShortcut(KEYCODE_T, Translate)
// also register escape to switch back to translate
registerShortcut(KEYCODE_ESC, Translate)
