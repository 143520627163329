import { useAppSelector } from 'src/hooks'
import ContextMenuItem, { Separator } from './menu-item'
import UndoRedoMenu from './menu-undo-redo'
import CopyPasteMenu from './menu-copy-paste'
import ClearMenu from './menu-clear'
import DeleteMenu from './menu-delete'
import ExtendMenu from './menu-extend'
import GenerateMenu from './menu-generate'
import InsertMenu from './menu-insert'
import MarkerMenu from './menu-marker'
import ParallelMenu from './menu-parallel'
import QueryMenu from './menu-query'
import RackingMenu from './menu-racking'
import SetterMenu from './menu-setter'
import SimulationMenu from './menu-simulation'
import SwapMenu from './menu-swap'
import TileItems from './items-tile'
import { ImageMenu } from './menu-image'

export default function SelectMenu() {
  const [left, bottom, right, top] = useAppSelector((state) => state.canvas.selection)
  return (
    <>
      <ContextMenuItem
        name={`Cols: ${left + 1}-${right}, Rows: ${bottom + 1}-${top}`}
        disabled
        header
      />
      <Separator />
      { /* basic actions */ }
      <UndoRedoMenu />
      <CopyPasteMenu />
      <MarkerMenu />
      <RackingMenu />
      <ImageMenu />
      <Separator />
      { /* modifier actions */ }
      <SetterMenu />
      <SwapMenu />
      <Separator />
      { /* query actions */ }
      <ExtendMenu />
      <QueryMenu />
      <SimulationMenu />
      <Separator />
      { /* data actions */ }
      <GenerateMenu />
      <ParallelMenu />
      <Separator />
      <TileItems />
      <Separator />
      <InsertMenu />
      <DeleteMenu />
      <ClearMenu />
    </>
  )
}
