import { createContext } from 'src/common/webgl/create'
import RenderContext from 'src/common/webgl/render-context'
import {
  LocalTextureFunc, TextureData, updateTexture, updateTextureRegions,
} from 'src/common/webgl/texture'
import { WebGLXBuffer, WebGLXRenderingContext } from 'src/common/webgl/types'
import {
  checkGLError, clearViewport, createProgram, createQuadVertexBuffer, createVertexShader, renderQuadVertexBuffer, resetProgram, setViewport,
} from 'src/common/webgl/utils'
import vtxShdrSrc from 'src/common/webgl/shaders/default-quad.vs.glsl'
import tniShdr from 'src/common/webgl/shaders/time-needle-image.fs.glsl'
import { allocateTextures, emptyData, TextureMap } from './textures'
import { RenderCallback } from './props'

export default interface WebGLState extends TextureMap {
  // animation loop
  frameId: number
  prevTime: number
  currTime: number

  // animation elements
  gl: WebGLXRenderingContext
  vtxBuff: WebGLXBuffer
  rendCtx: RenderContext

  // layers
  cdata?: TextureData
  odata?: TextureData
  pasteData?: TextureData
  previewData?: LocalTextureFunc

  // user data
  renderCallback?: RenderCallback
  ready: boolean
}

export function init(canvas: HTMLCanvasElement, cb: RenderCallback): WebGLState {
  const gl = createContext(canvas)
  if(!gl) { return }

  // create two shader render contexts
  const vtxShdr = createVertexShader(gl, vtxShdrSrc)
  const vtxBuff = createQuadVertexBuffer(gl)
  const rendProg = createProgram(gl, vtxShdr, tniShdr)
  const rendCtx = RenderContext.from(gl, rendProg)

  // texture allocation
  const textures = allocateTextures(gl)

  // link the allocated texture with the shader context
  for(const texName in textures) {
    if(rendCtx.hasArgument(texName)) {
      rendCtx.setTexture(texName, textures[texName])
    }
  }

  // check that all texture units have been filled
  for(let u = 0; u < rendCtx.numTextures; ++u) {
    if(!rendCtx.hasTexture(u)) {
      console.error(
        'Some textures were not allocated for the time-needle shader',
      )
      break
    }
  }

  if(!checkGLError(gl)) {
    return {
      // loop
      frameId: -1,
      prevTime: Date.now(),
      currTime: Date.now(),
      // animation
      gl,
      vtxBuff,
      rendCtx,
      // user
      renderCallback: cb,
      ready: false,
      // textures
      ...textures,
    }
  }
}

export function render(state: WebGLState) {
  state.prevTime = state.currTime
  state.currTime = Date.now()

  // XXX update general textures

  // bind the program
  const {
    gl, ready, vtxBuff, renderCallback,
  } = state

  // update paste data
  const {
    rendCtx,
    pasteTex,
    pasteData,
  } = state
  updateTexture(
    gl,
    pasteTex,
    pasteData ?? emptyData(),
    'sync-paste',
  )

  // render callback
  if(renderCallback) renderCallback(rendCtx)

  // update stitch data
  const { stitchTex, cdata } = state
  updateTexture(
    gl,
    stitchTex,
    cdata ?? emptyData(),
    'sync-stitch-layer',
  )

  // update option data
  const { optColTex, odata } = state
  updateTexture(
    gl,
    optColTex,
    odata ?? emptyData(),
    'sync-optcol-layer',
  )

  // preview data
  const { previewData } = state
  if(previewData) {
    updateTextureRegions(
      gl,
      stitchTex,
      previewData,
      'sync-preview',
    )
  }

  // update uniforms and bind program
  if(rendCtx.hasArgument('time')) {
    rendCtx.setArgument('time', Date.now())
  }
  rendCtx.bind()

  // draw basic quad
  // but only if there is something to draw
  if(ready) {
    renderQuadVertexBuffer(gl, vtxBuff)
  } else {
    clearViewport(gl)
  }

  // unbind program to be safe
  resetProgram(gl)

  // request refresh again
  state.frameId = requestAnimationFrame(render.bind(null, state))
}

export function resize(
  canvas: HTMLCanvasElement,
  { gl }: WebGLState,
) {
  // update canvas
  canvas.width = canvas.clientWidth
  canvas.height = canvas.clientHeight
  const rect = canvas.getBoundingClientRect()
  // update renderer viewport
  const width = Math.floor(rect.width)
  const height = Math.floor(rect.height)
  setViewport(gl, width, height)

  return { width, height, rect }
}

export function clear({
  frameId,
}: WebGLState) {
  window.cancelAnimationFrame(frameId)
}
