import css from 'src/styles/loading.module.scss'

export default function Loading() {
  return (
    <div className={css.loading}>
      <i />
      <i />
      <i />
      <i />
      <i />
    </div>
  )
}
