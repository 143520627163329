import { copyImageToImage } from 'src/data/time-needle/time-needle-image'
import { addRowsAboveTop, addRowsBelowBottom } from 'src/data/time-needle/topology'
import TimeNeedleTransformer, { SelectorLike } from 'src/data/time-needle/transformer'

export function duplicate(s: SelectorLike, repeat: number, above = true) {
  const {
    left, bottom, right, top, height,
  } = s.getExtents()
  if(height === 0) { return TimeNeedleTransformer.fromSelector(s) }

  // allocate new rows to hold duplicates of selection
  const [t, rows] = above ? addRowsAboveTop(s, repeat * height) : addRowsBelowBottom(s, repeat * height)

  // copy region to each duplicate region
  const y0 = above ? bottom + height : bottom
  const srcRect = [left, bottom, right, top] as const
  for(let r = 0, y = y0; r < repeat; ++r, y += height) {
    copyImageToImage(
      s.image,
      srcRect,
      t.image,
      [left, y, right, y + height],
    )
  }
  return t.union(rows).wales([left, right])
}
