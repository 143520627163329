import { useEffect, useRef, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { getPixelChannel } from 'src/data/image'
import { getCarrier } from 'src/data/time-needle/options'
import {
  getNeedleCodePair, isNeedleCarrierCode, NeedleCode, StitchCode,
} from 'src/data/time-needle/stitch-code'
import { ReadonlyTimeNeedleImage } from 'src/data/time-needle/time-needle-image'
import Modal from 'src/editor/modal'
import { useAppSelector } from 'src/hooks'

import 'src/styles/canvas-tooltip.scss'

const SHOW_DELAY = 500
const MOUSE_OFFSET = 10

function getCellData(
  img: ReadonlyTimeNeedleImage,
  x: number,
  y: number,
) {
  if(img) {
    const { width, height } = img.cdata
    if(
      x >= 0 && x < width &&
      y >= 0 && y < height
    ) {
      const sc = getPixelChannel(img.cdata, [x, y], 0) as StitchCode
      const [fnc, rnc] = getNeedleCodePair(sc)
      const yarn = getCarrier(img, y)
      const ncc = isNeedleCarrierCode(fnc) || isNeedleCarrierCode(rnc)
      return {
        inBounds: true,
        yarn: yarn && ncc ? yarn : 0,
        code: sc,
        needles: [fnc, rnc],
      } as const
    }
  }
  return {
    inBounds: false,
    yarn: 0,
    code: 0,
    needles: [NeedleCode.MISS, NeedleCode.MISS],
  } as const
}

const CanvasTooltip = function ({ hide = false}) {
  const {
    mouse: {x, y},
    mouseOnCanvas,
    tnimage,
    mouseCoord: [, , cellX, cellY],
    markerData: [hasMarker, markerX, markerY],
  } = useAppSelector(({
    ui: { mouse, mouseOnCanvas },
    canvas: { tnimage, mouseCoord, markerData },
  }) => ({
    mouse, mouseOnCanvas, tnimage, mouseCoord, markerData,
  }), shallowEqual)

  // const dir = (
  //   dirs[cellY] === 0 ? '->' :
  //     dirs[cellY] === 1 ? '<-' :
  //       dirs[cellY] === 2 ? '<->' : ''
  // )

  const {
    inBounds,
    yarn,
    code,
    needles: [front, rear],
  } = getCellData(tnimage, cellX, cellY)

  const [visible, setVisible] = useState<boolean>(!!inBounds)
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    setVisible(false)
    if(timeout.current) {
      clearTimeout(timeout.current)
    }

    timeout.current = setTimeout(() => {
      inBounds && mouseOnCanvas && setVisible(true)
    }, SHOW_DELAY)
  }, [x, y, mouseOnCanvas])
  return ((!hide && visible) && (
    <Modal>
      <div
        className="canvas-tooltip"
        style={{
          display: visible ? 'block' : 'none',
          top: y + MOUSE_OFFSET,
          left: x + MOUSE_OFFSET,
        }}
      >
        <div className="tooltip-head">{`{ ${cellX + 1}, ${cellY + 1} }`}</div>
        <div className="tooltip-main">
          <div>Yarn: { yarn || 'none' }</div>
          <div>Code: { StitchCode[code] }</div>
          <div>Front: { NeedleCode[front] }</div>
          <div>Rear: { NeedleCode[rear] }</div>
          {/* {dir !== '' && <div>Direction {dir}</div>} */}
          {hasMarker > 0 && (
            <>
              <div>Cols to marker: {Math.abs(markerX - cellX) + 1}</div>
              <div>Rows to marker: {Math.abs(markerY - cellY) + 1}</div>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
  )
}

export default CanvasTooltip
