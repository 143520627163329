import { sameCanvasImages } from '../image';
import { ReadonlyTimeNeedleImage } from './time-needle-image';

/**
 * Generic snapshot data type
 */
export type TimeNeedleSnapshot = Partial<ReadonlyTimeNeedleImage>

/**
 * Empty snapshot data type
 */
export type EmptySnapshot = {
  [key in keyof TimeNeedleSnapshot]: undefined
}

/**
 * Check whether a snapshot is equivalent to a time-needle image
 */
export function isFullSnapshot(snap: TimeNeedleSnapshot): snap is ReadonlyTimeNeedleImage {
  return !!snap.cdata && !!snap.odata && !!snap.sdata
}

/**
 * Check whether a snapshot has no data
 */
export function isEmptySnapshot(snap: TimeNeedleSnapshot): snap is EmptySnapshot {
  return !snap.cdata && !snap.odata && !snap.sdata && !snap.type
}

/**
 * The result of applying a snapshot onto an image,
 * which contains both the new time-needle image `tnimage`
 * as well as a snapshot to `undo` the application
 */
export interface SnapshotApplication {
  /**
   * The new time-needle image
   */
  tnimage: ReadonlyTimeNeedleImage
  /**
   * The snapshot for undoing this snapshot application
   */
  undo: TimeNeedleSnapshot
}

/**
 * Apply a snapshot onto an image
 *
 * @param tni the source image
 * @param snap the snapshot to apply
 * @returns the application result including the new image and undo snapshot
 */
export function applySnapshot(
  tni: ReadonlyTimeNeedleImage,
  snap: TimeNeedleSnapshot,
): SnapshotApplication {
  // special case for full snapshots
  if(isFullSnapshot(snap)) {
    // can use previous image as undo image
    // while the new image is directly the applied snapshot
    return {
      tnimage: snap,
      undo: tni,
    }
  }
  // else, we only transform what needs to be transformed
  return {
    tnimage: {
      cdata: snap.cdata ? snap.cdata : tni.cdata,
      odata: snap.odata ? snap.odata : tni.odata,
      sdata: snap.sdata ? snap.sdata : tni.sdata,
      type: snap.type ?? tni.type,
    },
    undo: {
      cdata: snap.cdata ? tni.cdata : undefined,
      odata: snap.odata ? tni.odata : undefined,
      sdata: snap.sdata ? tni.sdata : undefined,
      type: tni.type,
    },
  }
}

/**
 * Compute the snapshot that transforms one image into another
 *
 * @param oldImg the old version of an image
 * @param newImg the new version of an image
 * @param emptyIsNull whether to return `null` in case the snapshot is empty
 * @returns the corresponding snapshot or `null`
 */
export function getSnapshot(
  oldImg: ReadonlyTimeNeedleImage,
  newImg: ReadonlyTimeNeedleImage,
  emptyIsNull = true,
): TimeNeedleSnapshot {
  const snap = {
    cdata: oldImg.cdata !== newImg.cdata && !sameCanvasImages(oldImg.cdata, newImg.cdata) ? newImg.cdata : undefined,
    odata: oldImg.odata !== newImg.odata && !sameCanvasImages(oldImg.odata, newImg.odata) ? newImg.odata : undefined,
    sdata: oldImg.sdata.length !== newImg.sdata.length || oldImg.sdata.some((s, i) => s !== newImg.sdata[i]) ? newImg.sdata : undefined,
    type: oldImg.type !== newImg.type ? newImg.type : undefined,
  }
  return emptyIsNull && isEmptySnapshot(snap) ? null : snap
}
