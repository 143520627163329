import {
  getSelectionType,
  isYarnIndexOrNone,
  parseUserOptions,
  RowOptions,
  TimeNeedleTransformer,
  UserDirection,
} from './common'

export interface LocalOptions {
  knitOpts?: RowOptions
  xferOpts?: RowOptions
  tuckOpts?: RowOptions
  splitOpts?: RowOptions
  local?: boolean
}

export function parseLocalOptions(input: string): LocalOptions | string {
  const knitOpts = {} as RowOptions
  const xferOpts = { carrier: 0 } as RowOptions
  const tuckOpts = {} as RowOptions
  const spltOpts = {} as RowOptions
  const res = { knit: knitOpts, local: false } as LocalOptions
  let opts: RowOptions = knitOpts
  const error = parseUserOptions(input, {
    knit: () => { opts = knitOpts; res.knitOpts = opts },
    xfer: () => { opts = xferOpts; res.xferOpts = opts },
    transfer: () => { opts = xferOpts; res.xferOpts = opts },
    tuck: () => { opts = tuckOpts; res.tuckOpts = opts },
    split: () => { opts = spltOpts; res.splitOpts = opts },
    local: () => { res.local = true },
    global: () => { res.local = false },
  }, {
    carrier: (token: string) => {
      const yarn = parseInt(token, 10)
      if(!isYarnIndexOrNone(yarn)) {
        return `Invalid yarn index ${token}`
      }
      if(opts === knitOpts) {
        if(yarn) {
          opts.carrier = yarn
        } else {
          return 'Knit carrier cannot be empty (0)'
        }
      } else if(yarn === 0) {
        opts.carrier = 0
      } else {
        return 'Transfer rows cannot have non-empty carrier'
      }
    },
    dir: (token: string) => {
      if(opts === knitOpts) {
        if(['0', '1', '2'].includes(token)) {
          opts.direction = parseInt(token, 10) as UserDirection
        } else {
          return `Invalid direction token ${token}`
        }
      } else if(token === '2') {
        opts.direction = 2
      } else {
        return 'Cannot set direction for transfers'
      }
    },
    racking: (token: string) => {
      opts.racking = parseFloat(token)
    },
    roller: (token: string) => {
      opts.roller = parseInt(token, 10)
    },
    speed: (token: string) => {
      opts.speed = parseInt(token, 10)
    },
    stitch: (token: string) => {
      if(opts.stitchSize === undefined) {
        opts.stitchSize = parseInt(token, 10)
      } else if(typeof opts.stitchSize === 'number') {
        opts.stitchSize = [opts.stitchSize, parseInt(token, 10)]
      } else {
        return `Invalid third stitch token ${token}`
      }
    },
  }, null)
  if(error) {
    return error
  }
  return res
}

export function localOptions(s: TimeNeedleTransformer, {
  knitOpts,
  xferOpts,
  tuckOpts,
  splitOpts,
  local = false,
}: LocalOptions) {
  const [start, end] = s.getRowExtents() as [number, number]
  const rows = local ? s.splitByRow() : Array.from({ length: end - start + 1 }, (_, i) => s.fullCourse(i + start))
  for(const row of rows) {
    // measure row content
    const {
      hasKnit,
      hasTuck,
      hasTransfer,
      hasSplit,
    } = getSelectionType(row)
    // from more restrictive to least restrictive cases
    // - transfer
    if(hasTransfer) {
      if(xferOpts) {
        row.first().options(xferOpts)
      } else {
        continue
      }
    }
    // - split
    if(hasSplit) {
      if(splitOpts) {
        row.first().options(splitOpts)
      } else {
        continue
      }
    }
    // - tuck
    if(hasTuck) {
      if(tuckOpts) {
        row.first().options(tuckOpts)
      } else {
        continue
      }
    }
    // - knit
    if(hasKnit) {
      if(knitOpts) {
        row.first().options(knitOpts)
      } else {
        continue
      }
    }
  }
}
