import { UserDirection } from 'src/data/time-needle/options'
import {
  alternateDirection,
  generateInterleaving,
  generateSmartInterleaving,
  parseInterleavingOptions,
  removeInterleaving,
} from 'src/macros'
import {
  useApplyMacro,
  useAppSelector, useRawMacro, useUserEntry,
} from 'src/hooks'
import { TimeNeedleSelector, TimeNeedleTransformer } from 'src/macros/common'
import ContextMenuItem from './menu-item'

export default function ParallelMenu() {
  const applyMacro = useApplyMacro()
  const rawMacro = useRawMacro()
  const [left, bottom, right, top] = useAppSelector((state) => state.canvas.selection)
  const [interDefault, setInterDefault] = useUserEntry('pinter', 'left left right right both both')
  const [smartDefault, setSmartDefault] = useUserEntry('smartinter', 'from left inside, max 2')
  const hasWidth = right - left > 1
  const hasHeight = top - bottom > 1
  return (
    <ContextMenuItem
      name="Interleaving"
      menu
      disabled={!hasWidth}
    >
      <ContextMenuItem
        name="Manual interleaving"
        prompt={['Interleave tiling: l,l,r,r,x,x,...', interDefault]}
        onInput={(interStr) => {
          const tokens = interStr.toLowerCase().split(/[^a-z0-9]+/)
          const sides = [] as UserDirection[]
          for(const token of tokens) {
            const num = parseInt(token, 10)
            if(isNaN(num) || sides.length === 0) {
              // side token
              sides.push(token.startsWith('l') ? 0 : token.startsWith('r') ? 1 : 2)
            } else {
              // multiplier => repeat N-1 times (since already there once)
              for(let i = 0; i < num - 1; ++i) {
                sides.push(sides[sides.length - 1])
              }
            }
          }
          const numLeft = sides.filter((d) => d === 0).length
          const numRight = sides.filter((d) => d === 1).length
          if(numLeft !== numRight) {
            alert(`Interleaving is unbalanced: #left=${numLeft} vs #right=${numRight}`)
            return
          }
          setInterDefault(interStr)
          rawMacro((tni, sel) => generateInterleaving(TimeNeedleSelector.fromRegion(tni, sel), sides))
        }}
        disabled={!hasHeight}
      />
      <ContextMenuItem
        name="Smart interleaving"
        prompt={['Interleaving: from BRNCH START [max MAXPULL] [increasing] [directed] [switch START] [skip]', smartDefault]}
        parser={parseInterleavingOptions}
        onParse={(opts, smartStr) => {
          setSmartDefault(smartStr)
          rawMacro((tni, sel) => generateSmartInterleaving(TimeNeedleSelector.fromRegion(tni, sel), opts))
        }}
        disabled={!hasHeight}
      />
      <ContextMenuItem
        name="De-interleaving"
        onClick={() => {
          applyMacro((t) => removeInterleaving(t))
        }}
        disabled={!hasHeight}
      />
      <ContextMenuItem
        name="Alternating direction"
        prompt={['Initial knit direction: ltr or rtl', 'ltr']}
        onInput={(initStr) => {
          const ltr = !initStr.trim().toLowerCase().includes('rtl')
          applyMacro((t) => alternateDirection(t, ltr ? 0 : 1))
        }}
        disabled={!hasHeight}
      />
    </ContextMenuItem>
  )
}
