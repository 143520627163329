import {
  KEYCODE_7, KEYCODE_DOWN, KEYCODE_LEFT, KEYCODE_RIGHT, KEYCODE_SPACE, KEYCODE_UP, KEYCODE_Y, KEYCODE_Z,
} from 'src/common/keyboard'
import { redoAction, undoAction, UndoDomain } from 'src/undo'
import {
  computeSafeZoom, FV2, getCoord, getDetailLevel, getNextZoomLevel, getScrollSpeed, getZoomFromLevel, makeProjectionMatrix, project, ProjectionMatrix, resetOffset, RFV4, TransformData, ZoomData,
} from 'src/common/math'
import { ActionContext, ActionHandler, KeyOptions } from './common'

const KeyShiftDir = -1 // reverse

const KeyToShift = {
  [KEYCODE_LEFT]: [-1, 0] as FV2,
  [KEYCODE_DOWN]: [0, -1] as FV2,
  [KEYCODE_RIGHT]: [1, 0] as FV2,
  [KEYCODE_UP]: [0, 1] as FV2,
}
const EXPECTED_FPS = 60
const REFRESH_MILLIS = 1000 / EXPECTED_FPS

/**
 * Type for shortcut registration
 */
export interface ActionConstructor {
  new (): ActionHandler
}

// the registered shortcuts
const actionShortcuts = new Map<number, ActionConstructor>()

/**
 * Registers an action handler constructor
 * for a given key used as shortcut to switch between
 * different action handlers.
 */
export function registerShortcut(
  key: number,
  ac: ActionConstructor,
): void {
  if(actionShortcuts.has(key)) {
    throw `Registering shortcut for ${key} multiple times`
  } else {
    actionShortcuts.set(key, ac)
  }
}

export class BaseAction implements ActionHandler {
  protected lastMovePos = [-1, -1] as FV2

  protected lastPressPos = [-1, -1] as FV2

  protected dragging = false

  protected dragOrigin = [0, 0] as FV2

  protected dragStart = [0, 0] as FV2

  protected ctrlPressed = false

  protected shiftPressed = false

  protected renderShiftX = 0

  protected renderShiftY = 0

  protected lastShiftTime = 0

  // render shift
  hasRenderShift() {
    return !!this.renderShiftX || !!this.renderShiftY
  }

  onActionChange(ctx: ActionContext, nextAction: ActionHandler) {}

  isDragButton(button: number) {
    return button === 1 // drag by default on middle-button
  }

  onMouseDown(
    { transform, M }: ActionContext,
    x: number,
    y: number,
    button: number,
  ) {
    this.lastPressPos = [x, y]
    this.dragging = this.isDragButton(button)
    if(this.dragging) {
      this.dragOrigin = transform.offset.slice() as FV2
      this.dragStart = project([x, y], M)
    }
  }

  onMouseMove({
    transform, M, updateTransform,
  }: ActionContext, x: number, y: number, buttons: number) {
    this.lastMovePos = [x, y]
    if(this.dragging) {
      // default dragging behavior
      const [dragTrans, dragM] = resetOffset(transform, M, this.dragOrigin)
      // const dragM2 = makeProjectionMatrix({
      //   ...this.ctx.transform,
      //   offset: this.dragOrigin.slice() as FV2,
      // })
      const currPos = project([x, y], dragM)
      const [newTrans, newM] = resetOffset(dragTrans, dragM, [
        this.dragOrigin[0] + currPos[0] - this.dragStart[0],
        this.dragOrigin[1] + currPos[1] - this.dragStart[1],
      ])
      updateTransform(newTrans, newM)
    }
  }

  onMouseUp(ctx: ActionContext, x: number, y: number, button: number) {
    this.dragging = false
  }

  onWheel({
    transform, M, zoomData, updateZoomData, updateTransform,
  }: ActionContext, deltaY: number, deltaMode: number, mouseX: number, mouseY: number) {
    // default zoom behavior
    const start = project([mouseX, mouseY], M)
    const newLevel = getNextZoomLevel(zoomData, deltaY)
    const detailLevel = zoomData.detailLevel ?? getDetailLevel(transform.pageDims[1])
    const newZoomData = {
      ...zoomData,
      zoomLevel: newLevel,
      detailLevel,
    }
    updateZoomData(newZoomData)

    // new safe zoom value
    const newZoom = getZoomFromLevel(newZoomData)
    const safeZoom = computeSafeZoom(newZoom, transform.viewDims[1], transform.pageDims[1], detailLevel)

    // scaled transformation
    const scaledTrans: TransformData = {
      ...transform,
      zoom: safeZoom,
    }
    const scaledM = makeProjectionMatrix(scaledTrans)

    // updated transform with adjusted offset
    const end = project([mouseX, mouseY], scaledM)
    updateTransform({
      ...scaledTrans,
      offset: [
        scaledTrans.offset[0] + end[0] - start[0],
        scaledTrans.offset[1] + end[1] - start[1],
      ],
    })
  }

  onKeyDown({
    dispatch, transform, M, toggleMarker, setAction,
  }: ActionContext, key: number, { ctrl, shift, preventDefault }: KeyOptions) {
    const hasRenderShift = this.hasRenderShift()
    if(ctrl && key === KEYCODE_Z) {
      preventDefault()
      dispatch(undoAction(UndoDomain.EDITOR)) // undo
    } else if(ctrl && key === KEYCODE_Y) {
      preventDefault()
      dispatch(redoAction(UndoDomain.EDITOR)) // redo
    } else if(ctrl && [KEYCODE_7, KEYCODE_SPACE].includes(key)) {
      preventDefault()
      const cell = getCoord(this.lastMovePos, M, transform.pageDims, Math.floor)
      toggleMarker(cell)
    } else if(!ctrl && [KEYCODE_LEFT, KEYCODE_DOWN, KEYCODE_RIGHT, KEYCODE_UP].includes(key)) {
      if(!hasRenderShift) {
        this.lastShiftTime = Date.now()
      }
      const [dx, dy] = KeyToShift[key]
      if(dx) this.renderShiftX = dx
      if(dy) this.renderShiftY = dy
    } else if(!ctrl && actionShortcuts.has(key)) {
      preventDefault()
      const ActionCon = actionShortcuts.get(key)
      const newAction = new ActionCon()
      setAction(newAction)
    }

    // update key states
    this.ctrlPressed = ctrl
    this.shiftPressed = shift
  }

  onKeyUp(ctx: ActionContext, key: number, { ctrl, shift }: KeyOptions) {
    // stop render shift
    if([KEYCODE_LEFT, KEYCODE_DOWN, KEYCODE_RIGHT, KEYCODE_UP].includes(key)) {
      const [dx, dy] = KeyToShift[key]
      if(dx) this.renderShiftX = 0
      if(dy) this.renderShiftY = 0
    }
    // update pressed state
    this.ctrlPressed = ctrl
    this.shiftPressed = shift
  }

  onRender({ transform, zoomData, updateTransform }: ActionContext) {
    if(this.hasRenderShift()) {
      const [dx, dy] = [this.renderShiftX, this.renderShiftY]
      const dt = Date.now() - this.lastShiftTime
      const [x, y] = transform.offset
      const scale = 2 * Math.min(dt, REFRESH_MILLIS) / EXPECTED_FPS * Math.max(2, zoomData.zoomLevel)
      const [sx, sy] = getScrollSpeed(
        transform.pageDims[0],
        transform.pageDims[1],
      )

      // translation
      updateTransform({
        ...transform,
        offset: [
          x + scale * dx * sx * KeyShiftDir,
          y + scale * dy * sy * KeyShiftDir,
        ],
      })
    }
  }
}
