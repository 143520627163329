import { useEffect, MutableRefObject } from 'react'
import { unstable_batchedUpdates as batchUpdates } from 'react-dom'

type PointerEventCallback = (e:PointerEvent) => void

export const usePointerDownOutside = function<T extends Element> (
  ref: MutableRefObject<T>,
  callback: PointerEventCallback,
  otherDeps: any[] = [],
  enabled = true,
) {
  useEffect(enabled ? () => {
    const onPointerDown = (e:PointerEvent) => {
      if(ref.current && !ref.current.contains(e.target as Element)) {
        // @see https://stackoverflow.com/questions/48563650/does-react-keep-the-order-for-state-updates/48610973#48610973
        batchUpdates(() => callback(e))
      }
    }
    document.addEventListener('pointerdown', onPointerDown)
    return () => document.removeEventListener('pointerdown', onPointerDown)
  } : () => {}, [enabled, ref, ...otherDeps])
}

export const usePointerUpOutside = function<T extends Element> (
  ref: MutableRefObject<T>,
  callback: PointerEventCallback,
  otherDeps: any[] = [],
  enabled = true,
) {
  useEffect(enabled ? () => {
    const onPointerUp = (e:PointerEvent) => {
      if(ref.current && !ref.current.contains(e.target as Element)) {
        // @see https://stackoverflow.com/questions/48563650/does-react-keep-the-order-for-state-updates/48610973#48610973
        batchUpdates(() => callback(e))
      }
    }
    document.addEventListener('pointerup', onPointerUp)
    return () => document.removeEventListener('pointerup', onPointerUp)
  } : () => {}, [enabled, ref, ...otherDeps])
}

export const useClickOutside = function<T extends Element> (
  ref: MutableRefObject<T>,
  callback: PointerEventCallback,
  otherDeps: any[] = [],
  enabled = true,
) {
  useEffect(enabled ? () => {
    const onClick = (e:PointerEvent) => {
      if(ref.current && !ref.current.contains(e.target as Element)) {
        // @see https://stackoverflow.com/questions/48563650/does-react-keep-the-order-for-state-updates/48610973#48610973
        batchUpdates(() => callback(e))
      }
    }
    document.addEventListener('click', onClick)
    return () => document.removeEventListener('click', onClick)
  } : () => {}, [enabled, ref, ...otherDeps])
}

export const useDocumentContextMenu = function (
  callback: PointerEventCallback,
  deps: any[] = [],
  enabled = true,
) {
  useEffect(enabled ? () => {
    const onContextMenu = (e: PointerEvent) => {
      batchUpdates(() => callback(e))
    }
    document.addEventListener('contextmenu', onContextMenu)
    return () => document.removeEventListener('contextmenu', onContextMenu)
  } : () => {}, [enabled, ...deps])
}
