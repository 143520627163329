import { useState } from 'react'
import { useMenuState } from '@szhsin/react-menu'

import { useAppSelector, useMousePosition } from 'src/hooks'
import ContextMenu from 'src/editor/menu'
import CanvasTooltip from 'src/editor/canvas-tooltip'
import TimeNeedleEditor from 'src/editor/time-needle/editor'

const Viewer = () => {
  const [ref] = useMousePosition()
  const [menuProps, toggleMenu] = useMenuState()
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })
  const [left, bottom, right, top] = useAppSelector((state) => state.canvas.selection)
  return (
    <div className="viewer" id="contentDiv" ref={ref}>
      <TimeNeedleEditor onContextMenu={(e) => {
        e.preventDefault()
        // only trigger if there is a non-empty selection
        if((right - left) * (top - bottom) === 0) {
          toggleMenu(false)
        } else {
          setAnchorPoint({ x: e.clientX, y: e.clientY })
          toggleMenu(true)
        }
      }}
      />
      <CanvasTooltip />
      {/* <CanvasTooltip hide={menuProps.state && menuProps.state !== 'closed'} /> */}
      <ContextMenu {...menuProps} anchorPoint={anchorPoint} onClose={() => toggleMenu(false)} />
    </div>
  )
}

export default Viewer
