import { KEYCODE_ESC } from 'src/common/keyboard'
import { areEquals, FV2 } from 'src/common/math'
import { ActionContext, KeyOptions } from './common'
import DrawAction from './draw'

export default abstract class IndirectDrawAction extends DrawAction {
  protected previewing = false

  protected nextIsClick = false

  protected startCoord = [0, 0] as FV2

  abstract preview(ctx: ActionContext, p: FV2, q: FV2): void

  abstract commit(ctx: ActionContext, p: FV2, q: FV2): void

  isIndirect(): boolean {
    return true // indirect type (2 clicks to action)
  }

  onMouseDown(ctx: ActionContext, x: number, y: number, button: number): void {
    super.onMouseDown(ctx, x, y, button)
    if(this.drawing && !this.nextIsClick) {
      this.startCoord = this.drawCoord
      this.previewing = true
    }
  }

  onMouseMove(ctx: ActionContext, x: number, y: number, buttons: number): void {
    if(this.previewing) {
      this.drawCoord = this.getBoundedCoord(ctx, [x, y])
      // preview drawing
      this.preview(ctx, this.startCoord, this.drawCoord)
    } else {
      super.onMouseMove(ctx, x, y, buttons)
    }
  }

  onMouseUp(ctx: ActionContext, x: number, y: number, button: number): void {
    if(this.isDrawButton(button) && this.previewing) {
      this.drawing = false
      if(this.nextIsClick) {
        // potential action on "click"
        if(areEquals(this.downCoord, this.drawCoord)) {
          // this event is a "click"
          if(!areEquals(this.startCoord, this.drawCoord)) {
            // non-trivial data => apply
            this.commit(ctx, this.startCoord, this.drawCoord)
          }
          // reset whether valid or not
          this.nextIsClick = false
          this.previewing = false
          ctx.updatePreviewData(null)
        }
        // else the action continues
      } else {
        // next one is a click
        this.nextIsClick = true
      }
    } else {
      super.onMouseUp(ctx, x, y, button)
    }
  }

  onKeyDown(ctx: ActionContext, key: number, opts: KeyOptions): void {
    if(this.previewing && key === KEYCODE_ESC) {
      this.drawing = false // abort action
      this.previewing = false
      this.nextIsClick = false
      opts.preventDefault()
      ctx.updatePreviewData(null)
    } else {
      super.onKeyDown(ctx, key, opts)
    }
  }

  onActionChange({ updatePreviewData }: ActionContext): void {
    updatePreviewData(null)
  }
}
