import {
  BlendParameters, DEF_BLEND_COLOR, DEF_SOURCE_MODE, DEF_TARGET_MODE, GLDataType, GLDataTypeToString, WebGLXBuffer, WebGLXProgram, WebGLXRenderingContext, WebGLXShader, WebGLXUniformLocation,
} from './types';

// local flags
const DUMP_UNIFORMS = false; // show uniforms after creating a program
const RENDERER_DEBUG_MODE = false; // enable error reporting

export function createQuadVertexBuffer(gl: WebGLXRenderingContext) {
  const vertices: Float32Array = new Float32Array([
    -1.0, +1.0,
    -1.0, -1.0,
    +1.0, +1.0,
    +1.0, -1.0,
  ])

  const vtxBuffer = gl.createBuffer() as WebGLXBuffer
  gl.bindBuffer(gl.ARRAY_BUFFER, vtxBuffer)
  gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW)
  gl.bindBuffer(gl.ARRAY_BUFFER, null)
  return vtxBuffer
}

export function renderQuadVertexBuffer(gl: WebGLXRenderingContext, vtxBuffer: WebGLXBuffer) {
  gl.bindBuffer(gl.ARRAY_BUFFER, vtxBuffer)
  gl.enableVertexAttribArray(0)
  gl.vertexAttribPointer(0, 2, gl.FLOAT, false, 0, 0)

  gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4)

  gl.disableVertexAttribArray(0)
  gl.bindBuffer(gl.ARRAY_BUFFER, null)
}

export function createShader(gl: WebGLXRenderingContext, type: GLenum, source: string) {
  const shdr = gl.createShader(type) as WebGLXShader
  gl.shaderSource(shdr, source)
  gl.compileShader(shdr)
  if(gl.getShaderParameter(shdr, gl.COMPILE_STATUS)) {
    return shdr
  }
  console.log(gl.getShaderInfoLog(shdr))
  gl.deleteShader(shdr)
  return null
}

export function createVertexShader(gl: WebGLXRenderingContext, source: string) {
  return createShader(gl, gl.VERTEX_SHADER, source)
}

export function createFragmentShader(gl: WebGLXRenderingContext, source: string) {
  return createShader(gl, gl.FRAGMENT_SHADER, source)
}

export function createProgram(
  gl: WebGLXRenderingContext,
  vtxShdr: WebGLXShader | string,
  frgShdr: WebGLXShader | string,
): WebGLXProgram {
  const vshdr = typeof vtxShdr === 'string' ? createVertexShader(gl, vtxShdr) : vtxShdr
  const fshdr = typeof frgShdr === 'string' ? createFragmentShader(gl, frgShdr) : frgShdr

  const prog = gl.createProgram() as WebGLXProgram
  gl.attachShader(prog, vshdr)
  gl.attachShader(prog, fshdr)
  gl.linkProgram(prog)
  if(gl.getProgramParameter(prog, gl.LINK_STATUS)) {
    if(DUMP_UNIFORMS) {
      const count = gl.getProgramParameter(prog, gl.ACTIVE_UNIFORMS)
      for(let i = 0; i !== count; ++i) {
        const info = gl.getActiveUniform(prog, i)
        console.log(
          'name:',
          info.name,
          'type:',
          GLDataTypeToString(info.type as GLDataType),
          'size:',
          info.size,
        )
      }
    }

    // Shaders aren't necessary after being linked into a program, delete
    gl.deleteShader(vshdr);
    gl.deleteShader(fshdr);
    return prog
  }
  // Clean up when everything fails...
  console.log(gl.getProgramInfoLog(prog))
  gl.deleteProgram(prog)
  gl.deleteShader(vshdr)
  gl.deleteShader(fshdr)
  return null
}

export function resetProgram(gl: WebGLXRenderingContext) {
  gl.useProgram(null)
}

export function setBlending(gl: WebGLXRenderingContext, params: BlendParameters | boolean) {
  if(!params) {
    gl.disable(gl.BLEND)
  } else {
    gl.enable(gl.BLEND)

    // unwrap "true" params as default values for blending
    if(typeof params === 'boolean') {
      params = {
        sourceMode: DEF_SOURCE_MODE,
        targetMode: DEF_TARGET_MODE,
        color: DEF_BLEND_COLOR,
      }
    }

    const { sourceMode, targetMode, color } = params
    gl.blendFunc(sourceMode, targetMode)
    gl.blendColor(color[0], color[1], color[2], color[3])
  }
}

export function clearViewport(
  gl: WebGLXRenderingContext,
  mask = gl.COLOR_BUFFER_BIT,
) {
  gl.clear(mask)
}

export function checkGLError(gl: WebGLXRenderingContext, msgPrefix?: string): boolean {
  const err: GLenum = RENDERER_DEBUG_MODE ? gl.getError() : gl.NO_ERROR;
  let msg: string | null = null;
  switch(err) {
  case gl.NO_ERROR:
    return false; // no error
  case gl.INVALID_ENUM:
    msg = 'OpenGL Error - INVALID_ENUM'
    break;
  case gl.INVALID_VALUE:
    msg = 'OpenGL Error - INVALID_VALUE'
    break;
  case gl.INVALID_OPERATION:
    msg = 'OpenGL Error - INVALID_OPERATION'
    break;
  case gl.INVALID_FRAMEBUFFER_OPERATION:
    msg = 'OpenGL Error - INVALID_FRAMEBUFFER_OPERATION'
    break;
  case gl.OUT_OF_MEMORY:
    msg = 'OpenGL Error - OUT_OF_MEMORY'
    break;
  case gl.CONTEXT_LOST_WEBGL:
    msg = 'OpenGL Error - CONTEXT_LOST_WEBGL'
    break;
  default:
    msg = 'OpenGL Error - UNKNOWN'
  }

  console.error(msgPrefix ? `${msgPrefix}: ${msg}` : msg)
  return true
}

export function getUniformLocation(
  gl: WebGLXRenderingContext,
  prog: WebGLXProgram,
  name: string,
): WebGLXUniformLocation {
  return gl.getUniformLocation(prog, name) as WebGLXUniformLocation
}

export function setViewport(
  gl: WebGLXRenderingContext,
  width: number,
  height: number,
  x = 0,
  y = 0,
) {
  gl.viewport(x, y, width, height)
}

export function getRendererInfo(gl: WebGLXRenderingContext) {
  const debugInfo = gl.getExtension('WEBGL_debug_renderer_info')
  const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL)
  const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
  return {
    debugInfo,
    vendor,
    renderer,
  }
}
