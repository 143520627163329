import {
  combinedAR,
  computeSafeZoomFromLevel,
  getHomeLevel,
  getNextZoomLevel,
  makeProjectionMatrix,
  project,
  resetOffset,
  unCoord,
} from 'src/common/math'
import { getThumbnail } from 'src/data/time-needle/thumbnail'
import { ViewMode, ViewModes } from 'src/data/time-needle/view-mode'
import { setThumbnails } from '../slice'
import { ActionContext } from './common'

export function centerHome({
  zoomData,
  transform,
  updateTransform,
  updateZoomData,
}: ActionContext) {
  // update zoom data
  const newZoomData = {
    ...zoomData,
    zoomLevel: getHomeLevel(zoomData),
  }
  updateZoomData(newZoomData)

  // update zoom and offset
  const { viewDims, pageDims } = transform
  const zoom = computeSafeZoomFromLevel(newZoomData, viewDims[1], pageDims[1])
  updateTransform({
    ...transform,
    zoom,
    offset: [
      zoom * combinedAR(transform) - 0.5,
      zoom - 0.5,
    ],
  })
}

export function getCenterZoom(dir: number) {
  return ({
    zoomData,
    transform,
    M,
    updateZoomData,
    updateTransform,
  }: ActionContext) => {
    // mouse at center
    const viewCenter = [
      transform.viewDims[0] / 2,
      transform.viewDims[1] / 2,
    ] as const

    // compute new zoom data
    const newZoomData = {
      ...zoomData,
      zoomLevel: getNextZoomLevel(zoomData, dir),
    }
    updateZoomData(newZoomData)

    // compute new transform
    const start = project(viewCenter, M)
    const zoom = computeSafeZoomFromLevel(newZoomData, transform.viewDims[1], transform.pageDims[1])
    const zoomTrans = {
      ...transform,
      zoom,
    }
    const zoomM = makeProjectionMatrix(zoomTrans)
    const end = project(viewCenter, zoomM)
    const [newTrans, newM] = resetOffset(zoomTrans, zoomM, [
      zoomTrans.offset[0] + end[0] - start[0],
      zoomTrans.offset[1] + end[1] - start[1],
    ])
    updateTransform(newTrans, newM)
  }
}

export function zoomTo(
  x: number | ((ctx: ActionContext) => number),
  y: number | ((ctx: ActionContext) => number),
) {
  return (ctx: ActionContext) => {
    // resolve functional arguments
    if(typeof x === 'function') x = x(ctx)
    if(typeof y === 'function') y = y(ctx)
    // actual fixed zoomTo
    const {
      M, transform,
      updateTransform,
    } = ctx
    const pos = unCoord([x + 0.5, y + 0.5], M, transform.pageDims)
    const proj = project(pos, M)
    const [newTrans, newM] = resetOffset(transform, M, [
      transform.zoom * combinedAR(transform) - proj[0],
      transform.zoom - proj[1],
    ])
    updateTransform(newTrans, newM)
  }
}

export function jumpTo(loc: [number, number] | number) {
  if(Array.isArray(loc)) {
    // zoom to cell
    const [y, x] = loc
    return zoomTo(x, y)
  }
  // zoom to row
  return zoomTo(
    ({ transform }) => transform.pageDims[0] / 2,
    loc,
  )
}

export function updateThumbnails(...modes: ViewMode[]) {
  return ({
    dispatch, tnimage,
  }: ActionContext) => {
    // by default, update all view modes
    const modeSet = new Set(modes.length === 0 ? ViewModes : modes)
    const thumbnails = ViewModes.map((mode) => (modeSet.has(mode) ? getThumbnail(tnimage, mode) : null))
    if(thumbnails.some((str) => str)) {
      dispatch(setThumbnails(thumbnails))
    }
  }
}
