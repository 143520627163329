import TimeNeedleImage, { ReadonlyTimeNeedleImage } from '../time-needle-image'
import { getOption, getOptions, setOption } from './image'
import { KniterateOptions } from './kniterate'

/**
 * User direction from left to right
 */
export type LeftToRight = 0

/**
 * User direction from right to left
 */
export type RightToLeft = 1

/**
 * Default automatic user direction
 */
export type DirDontCare = 2

/**
 * User direction type
 */
export type UserDirection = LeftToRight | RightToLeft | DirDontCare

/**
 * Yarn carrier index
 */
export type YarnIndex = 1 | 2 | 3 | 4 | 5 | 6

// type guard
export function isYarnIndex(y: number): y is YarnIndex {
  return !Number.isNaN(y) && y >= 1 && y <= 6
}

/**
 * Yarn carrier index, including the no-carrier index
 */
export type YarnIndexOrNone = 0 | YarnIndex

// type guard
export function isYarnIndexOrNone(y: number): y is YarnIndexOrNone {
  return isYarnIndex(y) || y === 0
}

/**
 * Single bed side
 */
export type BedSide = 0 | 1

/**
 * Either a bed side or the signal of none
 */
export type BedSideOrNone = BedSide | -1

/**
 * Either a bed side or both at the same time
 */
export type BedSideOrBoth = BedSide | 2

/**
 * Either a bed side, or both at the same time, or the signal of none
 */
export type BedSidesOrNone = BedSideOrBoth | -1

/**
 * The basic options associated with any row
 */
export interface RowOptions {
  direction?: UserDirection
  carrier?: YarnIndexOrNone
  racking?: number
  speed?: number
  roller?: number
  stitchSize?: readonly [number, number] | number
  frontStitchSize?: number
  rearStitchSize?: number
}

// XXX should likely use an indirection or some abstraction
// so that we can deal with different machine types
const {
  carriageSpeed: SpeedField,
  carrier: CarrierField,
  direction: DirectionField,
  frontStitchSize: FrontStitchSizeField,
  racking: RackingField,
  rearStitchSize: RearStitchSizeField,
  roller: RollerField,
} = KniterateOptions

// options column modifiers
export function setStitchSize({ odata }: TimeNeedleImage, row: number, ss: number | [number, number], side: BedSideOrBoth = 2) {
  switch(side) {
  case 0:
    setOption(odata, row, FrontStitchSizeField, Array.isArray(ss) ? ss[0] : ss)
    return this
  case 1:
    setOption(odata, row, RearStitchSizeField, Array.isArray(ss) ? ss[1] : ss)
    return this
  case 2:
    if(Array.isArray(ss)) {
      setOption(odata, row, FrontStitchSizeField, ss[0])
      setOption(odata, row, RearStitchSizeField, ss[1])
    } else {
      setOption(odata, row, FrontStitchSizeField, ss)
      setOption(odata, row, RearStitchSizeField, ss)
    }
    return this
  default:
    throw `Invalid side argument: ${side} must be 0, 1 or 2`
  }
}
export function setRacking({ odata }: TimeNeedleImage, row: number, rack: number) {
  setOption(odata, row, RackingField, rack)
}
export function setRoller({ odata }: TimeNeedleImage, row: number, roll: number) {
  setOption(odata, row, RollerField, roll)
}
export function setCarriageSpeed({ odata }: TimeNeedleImage, row: number, speed: number) {
  setOption(odata, row, SpeedField, speed)
}
export function setDirection({ odata }: TimeNeedleImage, row: number, dir: UserDirection) {
  setOption(odata, row, DirectionField, dir)
}
export function setCarrier({ odata }: TimeNeedleImage, row: number, yarn: YarnIndexOrNone) {
  setOption(odata, row, CarrierField, yarn)
}
export function setRowOptions(img: TimeNeedleImage, row: number, opts: RowOptions) {
  // simple options
  const {
    carrier, direction, racking, roller, speed,
  } = opts
  if(typeof carrier === 'number') setCarrier(img, row, carrier)
  if(typeof direction === 'number') setDirection(img, row, direction)
  if(typeof racking === 'number') setRacking(img, row, racking)
  if(typeof roller === 'number') setRoller(img, row, roller)
  if(typeof speed === 'number') setCarriageSpeed(img, row, speed)
  // stitch sizes
  const [fss, hasFSS] = resolveStitchSize(opts, 0)
  const [rss, hasRSS] = resolveStitchSize(opts, 1)
  if(hasFSS) setStitchSize(img, row, fss, 0)
  if(hasRSS) setStitchSize(img, row, rss, 1)
}

// options column getters
export function getFrontStitchSize({ odata }: ReadonlyTimeNeedleImage, row: number) {
  return getOption(odata, row, FrontStitchSizeField)
}
export function getRearStitchSize({ odata }: ReadonlyTimeNeedleImage, row: number) {
  return getOption(odata, row, RearStitchSizeField)
}
export function getStitchSize({ odata }: ReadonlyTimeNeedleImage, row: number): [number, number] {
  return getOptions(odata, row, FrontStitchSizeField, RearStitchSizeField) as [number, number]
}
export function getRacking({ odata }: ReadonlyTimeNeedleImage, row: number) {
  return getOption(odata, row, RackingField)
}
export function getRoller({ odata }: ReadonlyTimeNeedleImage, row: number) {
  return getOption(odata, row, RollerField)
}
export function getCarriageSpeed({ odata }: ReadonlyTimeNeedleImage, row: number) {
  return getOption(odata, row, SpeedField)
}
export function getDirection({ odata }: ReadonlyTimeNeedleImage, row: number) {
  return getOption(odata, row, DirectionField) as UserDirection
}
export function getCarrier({ odata }: ReadonlyTimeNeedleImage, row: number) {
  return getOption(odata, row, CarrierField) as YarnIndexOrNone
}

export function resolveStitchSize(opts: RowOptions, side: BedSide) {
  const { stitchSize } = opts
  if(typeof stitchSize === 'number') {
    return [stitchSize, true] as [number, boolean]
  } if(Array.isArray(stitchSize)) {
    return [stitchSize[side], true] as [number, boolean]
  } if(side === 0) {
    const { frontStitchSize } = opts
    return (
      typeof frontStitchSize === 'number' ? [frontStitchSize, true] : [-1, false]
    ) as [number, boolean]
  }
  const { rearStitchSize } = opts
  return (
      typeof rearStitchSize === 'number' ? [rearStitchSize, true] : [-1, false]
    ) as [number, boolean]
}

export function getRowOptions(tni: ReadonlyTimeNeedleImage, row: number): RowOptions {
  return {
    get carrier() { return getCarrier(tni, row) },
    get direction() { return getDirection(tni, row) },
    get racking() { return getRacking(tni, row) },
    get roller() { return getRoller(tni, row) },
    get speed() { return getCarriageSpeed(tni, row) },
    get stitchSize() { return getStitchSize(tni, row) },
    get frontStitchSize() { return getFrontStitchSize(tni, row) },
    get rearStitchSize() { return getRearStitchSize(tni, row) },
  }
}
