import {
  CanvasImage, getPixel, getPixelChannel, createCanvasImage, setPixel,
} from 'src/data/image';
import { getCarrier } from './options';
import { DefaultPaletteTexture } from './palette';
import {
  ColorIndex,
  getColorIndexColor,
  getNeedleCodePair,
  getStitchColorImage,
  isNeedleYarnCode,
  isStitchYarnCode,
  StitchCode,
} from './stitch-code';
import { ReadonlyTimeNeedleImage } from './time-needle-image';
import { ViewMode } from './view-mode';

/**
 * Compute a RGB image representation of a time-needle image
 *
 * @param img the time-needle image
 * @param view the view type
 * @param clearAsBlack whether clear pixels should be black instead of gray
 * @returns the RGB image
 */
export function getImage(
  img: ReadonlyTimeNeedleImage,
  view: ViewMode,
  clearAsBlack = true,
) {
  // different cases depending on the view type
  switch(view) {
  case ViewMode.STITCH: return getStitchColorImage(img.cdata, clearAsBlack)
  case ViewMode.PATTERN: return getCarrierColorImage(img, clearAsBlack)
  }
}

// pre-computed colors
const ClearGray = getColorIndexColor(ColorIndex.CLEAR)
const InvalidRed = getColorIndexColor(ColorIndex.INVALID)
const BlackPixel = new Uint8Array([0, 0, 0])

/**
 * Generate a carrier color image that shows yarn carrier colors
 * on top of pixels that correspond to yarn actions.
 *
 * Rear-only actions are displayed with halved intensities.
 *
 * @param tni the time-needle image
 * @param clearAsBlack whether non-yarn pixels should be black instead of gray
 */
export function getCarrierColorImage(
  tni: ReadonlyTimeNeedleImage,
  clearAsBlack = true,
) {
  const { width, height } = tni.cdata
  const img: CanvasImage<3> = createCanvasImage(width, height, 3)
  for(let r = 0; r < height; ++r) {
    const carrier = getCarrier(tni, r)
    const baseCol = carrier ? getPixel(DefaultPaletteTexture, [carrier - 1, 0]) : BlackPixel
    for(let c = 0; c < width; ++c) {
      const sc = getPixelChannel(tni.cdata, [c, r], 0) as StitchCode
      // skip codes that do not involve the yarn
      if(!isStitchYarnCode(sc)) {
        // if not clear black, then use default clear gray
        if(!clearAsBlack) {
          setPixel(img, [c, r], ClearGray)
        }
      } else if(!carrier) {
        // invalid action on non-carrier row = invalid RED
        setPixel(img, [c, r], InvalidRed)
      } else {
        // check whether the front is active
        const [fnc] = getNeedleCodePair(sc)
        const yarnCol = isNeedleYarnCode(fnc) ? baseCol : baseCol.map((n) => Math.floor(n / 2))
        setPixel(img, [c, r], yarnCol)
      }
    }
  }
  return img
}
