import { useLayoutEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

function usePortal(id) {
  const portal = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const parent = document.getElementById(id)
    parent.appendChild(portal.current)
    return () => portal.current.remove()
  }, [id])

  const createPortal = () => {
    if(!portal.current) {
      portal.current = document.createElement('div')
    }
    return portal.current
  }

  return createPortal()
}

export default function Modal({ children }) {
  const modal = usePortal('portal-root')
  return createPortal(children, modal)
}
