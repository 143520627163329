import { issue, Issue, Loop } from 'src/data/compiler';

export function loopConnectivityShouldBeLimited(loops: Loop[], issues: Issue[]) {
  for(const loop of loops) {
    if(loop.numParents > 2) {
      // issue should highlight the oldest loop as related
      // since this is likely the most suspicious loop interaction
      const oldestSrc = findOldestLoopWithSource(loop.parentLoops).source
      issues.push(issue('loop-connectivity').danger(
        `Loop has more than 2 parents (${loop.numParents}), which is dangerous`,
      ).between(loop.source, oldestSrc))
    }
  }
}

function findOldestLoopWithSource(loops: Loop[]): Loop {
  let oldest = loops[0]
  for(let i = 1; i < loops.length; ++i) {
    const loop = loops[i]
    const oldHasSrc = oldest.hasSource()
    const newHasSrc = loop.hasSource()
    if(!oldHasSrc && newHasSrc) {
      oldest = loop // may not be oldest, but has source information
    } else if(oldHasSrc && newHasSrc && loop.index < oldest.index) {
      oldest = loop // both loops have sources, and this one is older
    }
  }
  return oldest
}
