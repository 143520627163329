//
// /!\ Historical note
// XXX this `EditPlugin` was historically designed in such a way
// that there would be the possibility of multiple plugins
// even though there never was any other plugin using the local storage
//
// this plugin serves the purpose of transferring copy data across tabs
// note that this happens through localStorage, which has a low size limit
//

import {
  ReadonlyTimeNeedleRegion,
  TimeNeedleRegion,
} from 'src/data/time-needle/region';
import {
  base64DecToArr,
  base64EncArr,
} from './base64'

export const appStorage = checkLocalStorage() ? window.localStorage : null;

const PLUGIN = 'EditPlugin';

type JSONizedType<Type> = {
  [Key in keyof Type]: Type[Key] extends (infer U)[]
    ? JSONizedType<U>[]
    : Type[Key] extends Uint8Array
      ? string
      : Type[Key] extends object
        ? JSONizedType<Type[Key]>
        : Type[Key]
}

type JSONRegion = JSONizedType<TimeNeedleRegion>

interface EditCopyData {
  connections: number // The number of tabs connected to the localStorage system
  currentRegion: JSONRegion // The last copied region
}

const defaultEditCopyData = {
  connections: 1, // Whoever creates the first instance, there will be 1...
  currentRegion: null,
};
let caughtException = false

// This function returns true if localStorage is available in the browser
export function checkLocalStorage(type = 'localStorage'): boolean {
  let storage: any
  try {
    storage = window[type]
    const key = '__test__'
    storage.setItem(key, key)
    const test = storage.getItem(key) === key
    storage.removeItem(key)
    return test
  } catch(e) {
    return e instanceof DOMException && (
      e.code === 22 || e.code === 1024 || e.name === 'QuotaExceededError' ||
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            (storage && storage.length !== 0)
  }
}

export function initLocalStorage(): boolean {
  if(!appStorage) {
    console.error('Failed to initialize localStorage. Copying between tabs disabled')
    return false
  }

  const value = appStorage.getItem(PLUGIN)
  try {
    if(value) {
      const data = JSON.parse(value) as EditCopyData
      data.connections += 1
      appStorage.setItem(PLUGIN, JSON.stringify(data))
    } else {
      defaultEditCopyData.connections = 1
      appStorage.setItem(PLUGIN, JSON.stringify(defaultEditCopyData))
    }
  } catch(e) {
    console.error('Failed to set item data in localStorage: ', e)
    caughtException = true
    return false
  }

  // Setup the unregister function
  window.addEventListener('beforeunload', (e) => {
    const value = appStorage.getItem(PLUGIN)
    if(value) {
      const data = JSON.parse(value) as EditCopyData
      data.connections -= 1
      if(data.connections === 0) {
        appStorage.clear() // Remove everything
      } else {
        try {
          appStorage.setItem(PLUGIN, JSON.stringify(data))
        } catch(e) {
          console.error(e)
          caughtException = true
        }
      }
    }
  });

  return true;
}

export function fromJSONToRegion(region: JSONRegion): TimeNeedleRegion {
  // validate the data as it may come from a different app version
  if(
    (region?.cdata?.data?.length ?? 0) === 0 ||
    (region?.odata?.data?.length ?? 0) === 0
  ) {
    return null
  }
  // it has the whole data structure for the code part
  // so we'll assume it has the rest (option data)
  return {
    ...region,
    cdata: {
      ...region.cdata,
      data: base64DecToArr(region.cdata.data),
    },
    odata: {
      ...region.odata,
      data: base64DecToArr(region.odata.data),
    },
  }
}
export function fromRegionToJSON(region: ReadonlyTimeNeedleRegion): JSONRegion {
  return {
    ...region,
    cdata: {
      ...region.cdata,
      data: base64EncArr(region.cdata.data),
    },
    odata: {
      ...region.odata,
      data: base64EncArr(region.odata.data),
    },
    sdata: [...region.sdata],
  }
}

export function setCopyData(copy: ReadonlyTimeNeedleRegion): boolean {
  if(!appStorage) {
    return false
  }

  const value = appStorage.getItem(PLUGIN)
  if(value) {
    const data = JSON.parse(value) as EditCopyData
    data.currentRegion = fromRegionToJSON(copy)
    try {
      appStorage.setItem(PLUGIN, JSON.stringify(data))
      return true
    } catch(e) {
      caughtException = true
      return false
    }
  }
  return false
}

export function isCopyDataValid(): boolean {
  // XXX ideally we should be able to recover (but that is non-trivial)
  return !!appStorage && !caughtException
}

export function queryCopyData(): ReadonlyTimeNeedleRegion {
  if(!appStorage) {
    return null
  }

  const value = appStorage.getItem(PLUGIN)
  if(value) {
    const data = JSON.parse(value) as EditCopyData
    if(data.currentRegion === null) {
      return null
    }
    return fromJSONToRegion(data.currentRegion)
  }

  return null
}
