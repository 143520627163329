import {
  useRef,
  useState,
  Fragment,
  WheelEvent,
  useEffect,
} from 'react'
import classNames from 'classnames'
import { shallowEqual } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { tracePanel } from 'src/data/compiler'
import { unproject } from 'src/common/math'
import { NumCellMargin, setOffset } from 'src/editor/time-needle/slice'
import { getOption, ReadonlyTimeNeedleOptions } from 'src/data/time-needle/options'
import type { CellIndex } from './grid-selection'
import {
  CellState,
  FieldType,
  FieldToOptionField,
  useGridEditing,
} from './grid-editing'

import 'src/styles/options-column.scss'

const fieldClasses = [
  'option-racking',
  'option-speed',
  'option-roll',
  'option-fs',
  'option-rs',
  'option-dir',
  'option-carrier',
]

type DirectionDataType = ReturnType<typeof tracePanel>['directions']

interface FieldProps {
  type: FieldType
  row: number
  odata: ReadonlyTimeNeedleOptions
  getCellState: (idx: CellIndex) => CellState
  dirs?: DirectionDataType
}

function formatField(value: number, type: FieldType): string {
  if(typeof value !== 'number') {
    return '?' // invalid temporary state at end of options that were deleted
  }
  return type === FieldType.Racking ? (
    value > 0 ? `+${value.toFixed(1)}` : value.toFixed(1)
  ) : value.toString()
}

const OptionsField = function ({
  type, row, odata, getCellState, dirs,
}: FieldProps) {
  // get field value from options column
  const fieldValue = getOption(odata, row, FieldToOptionField[type]) // getoptColData.getByType(type, row)
  const numRows = odata.height
  const gridRow = numRows - row // Y inversion and 1-indexing
  const gridColumn = type + 2 // first field start on 2nd column, 1-indexed
  const cell = { row: gridRow, column: gridColumn }
  const hasDir = type === FieldType.Direction && dirs
  const isYarn = type === FieldType.Carrier

  // get cell state information
  const {
    selected,
    inputEdit,
    hasInputEdit,
    hasEmptyEdit,
    dragEdit,
    hasDragEdit,
    pasteEdit,
    hasPasteEdit,
  } = getCellState(cell)

  return (
    <div
      className={classNames('cell-wrapper', {
        selected,
        editing: hasInputEdit,
        'editing-empty': hasEmptyEdit,
        'drag-edit': hasDragEdit && dragEdit !== fieldValue, // only if it differs!
        'paste-edit': hasPasteEdit && pasteEdit !== fieldValue, // only if it differs!
      })}
      data-row={gridRow}
      data-column={gridColumn}
      style={{ gridRow, gridColumn }}
    >
      <div className={classNames('cell', fieldClasses[type], {
        left: hasDir && dirs[row] === '-',
        right: hasDir && dirs[row] === '+',
        'no-yarn': hasDir && dirs[row] === '=',
        [`y${fieldValue}`]: isYarn,
      })}
      >
        {
          hasInputEdit && !hasEmptyEdit ? inputEdit : formatField(
            hasDragEdit ?
              dragEdit : (
                hasPasteEdit ?
                  pasteEdit :
                  fieldValue
              ),
            type,
          )
        }
      </div>
    </div>
  )
}

const OptionsColumn = function () {
  const dispatch = useAppDispatch()
  const optColRef = useRef<HTMLDivElement>()
  const {
    yConfig,
    offset,
    M,
    tnimage,
    viewHeight,
    zoomLevel,
  } = useAppSelector(({
    canvas: {
      axisConfig: [, yConfig],
      offset, M, tnimage,
      transform: { viewDims },
      zoomData: { zoomLevel },
    },
  }) => ({
    yConfig,
    offset,
    M,
    tnimage,
    viewHeight: viewDims[1],
    zoomLevel,
  }), shallowEqual)

  const numRows = yConfig?.cellPos.length ?? 0

  const {
    // event handlers
    onKeyDown,
    onPointerDown,
    onPointerMove,
    onPointerUp,

    // selection + edit states
    getCellState,
    isPasting,
    isSelectingCells,
    isSelectingRows,

    // JSX components
    inputComponents,
    selectionComponents,

  } = useGridEditing(optColRef, numRows, 'cell-wrapper')

  const [inferredDirections, setInferredDirections] = useState<DirectionDataType>([])
  useEffect(() => {
    if(tnimage) {
      const dirs = tracePanel(tnimage).directions
      setInferredDirections(dirs)
    } else if(inferredDirections.length > 0) {
      setInferredDirections([]) // clear
    }
  }, [tnimage])
  // const mouseRow = useAppSelector((state) => {
  //   const my = state.ui.mouse.y
  //   if(!transform) {
  //     return -1
  //   }
  //   const [coord] = transform.getCoord(0, my, Math.floor)
  //   const y = coord.y()
  //   return y >= 0 && y < numRows ? y : -1
  // })
  const numEntries = yConfig.numCells
  const offsetInit = unproject([0, yConfig.initOffset], M)
  const offsetNew = unproject(offset, M)
  const delta = (offsetNew[1] - offsetInit[1])
  const margin = NumCellMargin * yConfig.cellSize

  // find first row entry that is within viewport (+/- cell margin)
  const firstValidIdx = yConfig.cellPos.findIndex((pos:number) => {
    const newPos = pos + delta
    return -margin < newPos && newPos < viewHeight + margin
  })

  if(firstValidIdx === -1) {
    return null // nothing to display
  }

  // const topEntryIdx = Math.min(firstValidIdx + numEntries - 1, numRows - 1)
  const marginTop = (
    yConfig.cellPos[numRows - 1] + // the topmost cell's Y position in the complete grid
    delta - // the current delta due to panning
    yConfig.cellSize * 0.5 - 5// shift from center of cell to its top
  )
  const numInvalidEntries = Math.max(0, firstValidIdx + numEntries - numRows)
  const numValidEntries = numEntries - numInvalidEntries

  const marginBottom =
    viewHeight - (
      yConfig.cellPos[firstValidIdx] + // the bottommost cell's Y position
      delta + // the current delta due to panning
      yConfig.cellSize * 0.5 // shift from center of cell to its bottom
    ) - (
      5 + // undoing of CSS y shift of parent
      35 // height of headers as buffer
    )
  const onWheel = ({ deltaY, currentTarget, clientY }: WheelEvent<HTMLDivElement>) => {
    if(marginTop > 0 && deltaY < 0 || marginBottom > 0 && deltaY > 0) {
      return;
    }
    const [x, y] = offset
    const scale = 0.00001 * Math.max(zoomLevel, 2)
    // scaling from center (default speed) to edges (max speed)
    const t = Math.max(0, Math.min(1, (clientY - currentTarget.offsetTop) / currentTarget.clientHeight))
    const d = 3; // spline degree
    const spline = t >= 0.5 ? (2 * t - 1) ** d : 1 - (2 * t) ** d // spline that is 1 at edges and 0 at center
    const stretch = 5 // max scrolling speed
    const factor = 1 + (stretch - 1) * spline // graded speed
    dispatch(setOffset([x, y + scale * deltaY * factor]))
  }

  return (
    <div
      className={classNames('options-column-wrapper', {
        'with-direction': true,
      })}
      onWheel={onWheel}
      onKeyDown={onKeyDown}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerMove={onPointerMove}
      style={{
        marginTop: marginTop > 5 ? marginTop - 5 : 0,
        marginBottom: marginBottom > 5 ? marginBottom - 5 : 0,
      }}
    >
      <div
        ref={optColRef}
        className={classNames('options-column', {
          'selecting-cells': isSelectingCells,
          'selecting-rows': isSelectingRows,
          pasting: isPasting,
        }, `rh-${Math.round(yConfig.cellSize)}`)}
        style={{
          marginTop: marginTop > 5 ? 5 : marginTop,
          gridAutoRows: yConfig.cellSize,
        }}
        // to properly recapture the focus from FocusTrap
        tabIndex={2} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
      >
        {Array.from({ length: numValidEntries }, (_, i) => {
          // note: firstValidIdx is the index of the first data row to show
          //       (numEntires-1-i) reverse the order because of reverse Y indexing
          const row = firstValidIdx + numValidEntries - 1 - i
          const gridRow = numRows - row
          return (
            <Fragment key={`entry-${row}`}>
              <div
                className="cell-wrapper"
                data-row={gridRow}
                data-column="1"
                style={{
                  gridRow,
                  gridColumn: 1,
                }}
              >
                <div className={classNames('cell', 'cell-index', { highlighted: (row + 1) % 10 === 0 })}>
                  {row + 1}
                </div>
              </div>
              <OptionsField type={FieldType.Racking} row={row} odata={tnimage.odata} getCellState={getCellState} />
              <OptionsField type={FieldType.Speed} row={row} odata={tnimage.odata} getCellState={getCellState} />
              <OptionsField type={FieldType.Roll} row={row} odata={tnimage.odata} getCellState={getCellState} />
              <OptionsField type={FieldType.FrontStitch} row={row} odata={tnimage.odata} getCellState={getCellState} />
              <OptionsField type={FieldType.RearStitch} row={row} odata={tnimage.odata} getCellState={getCellState} />
              <OptionsField type={FieldType.Direction} row={row} odata={tnimage.odata} getCellState={getCellState} dirs={inferredDirections} />
              <OptionsField type={FieldType.Carrier} row={row} odata={tnimage.odata} getCellState={getCellState} />
            </Fragment>
          )
        })}
        {/* <div
          className="mouse-row"
          style={{
            display: mouseRow !== -1 ? 'block' : 'none',
            gridRow: numRows - mouseRow - 1,
          }}
        /> */}
        { selectionComponents }
      </div>
      <div className="cell-headers" style={marginBottom > 5 ? { bottom: marginBottom <= 15 ? 5 + 15 - marginBottom : 5 } : {}}>
        <div />
        <div>Rack</div>
        <div>Speed</div>
        <div>Roll</div>
        <div>S/F</div>
        <div>S/R</div>
        <div>Dir</div>
        <div>Yarn</div>
      </div>
      { inputComponents }
    </div>
  )
}

export default OptionsColumn
