import { shallowEqual } from 'react-redux'
import { getDetailLevel, isDetailLevel } from 'src/common/math'
import { ViewMode } from 'src/data/time-needle/view-mode'
import { useAppSelector } from 'src/hooks'
import HorizontalRuler from './horizontal-ruler'
import OptionsColumn from './options-column'
import VerticalRuler from './vertical-ruler'

export interface AxesProps {
  activeView?: ViewMode
}

export default function Axes({ activeView }) {
  if(activeView === undefined) {
    activeView = useAppSelector((state) => state.canvas.activeView)
  }
  const {
    showOptionsColumn,
    visible,
  } = useAppSelector(({
    canvas: {
      height,
      zoom,
      zoomData: { detailLevel = getDetailLevel(height) },
    },
    settings: { showOptionsColumn },
  }) => ({
    showOptionsColumn,
    visible: isDetailLevel(zoom, detailLevel),
  }), shallowEqual)

  return visible && (
    <>
      {showOptionsColumn && activeView == ViewMode.STITCH && <OptionsColumn /> || <VerticalRuler />}
      <HorizontalRuler />
    </>
  )
}
