import { useEffect } from 'react'
import { unstable_batchedUpdates as batchUpdates } from 'react-dom'

export type WindowResizeCallback = (e: UIEvent) => void

export const useWindowResize = function (
  callback: WindowResizeCallback,
  otherDeps: any[] = [],
  enabled = true,
) {
  useEffect(enabled ? () => {
    const onWindowResize = (e: UIEvent) => {
      // @see https://stackoverflow.com/questions/48563650/does-react-keep-the-order-for-state-updates/48610973#48610973
      batchUpdates(() => callback(e))
    }
    window.addEventListener('resize', onWindowResize)
    return () => document.removeEventListener('resize', onWindowResize)
  } : () => {}, [enabled, ...otherDeps])
}
