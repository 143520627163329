import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom'

import Loading from 'src/common/loading'
import ErrorBoundary from 'src/common/ErrorBoundary'
import DesignLoader from 'src/editor/design-loader'
import Designs from 'src/editor/designs'

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import Editor from 'src/editor'

import 'src/styles/viewer.scss'
import 'src/styles/sidebar.scss'

export const MakeMainRoutes = function () {
  const { isLoading } = useAuth0()
  if(isLoading) {
    return <Loading />
  }

  return (
    <Router>
      <ErrorBoundary>
        <Switch>
          <Route
            path="/app/:id"
            component={withAuthenticationRequired(() => <DesignLoader />)}
          />
          <Route
            path="/app"
            component={withAuthenticationRequired(() => <Editor />)}
          />
          <Route
            exact
            path="/"
            location={{ pathName: 'test' }}
            component={withAuthenticationRequired(Designs)}
          />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </ErrorBoundary>
    </Router>
  )
}
