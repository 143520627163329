import { NominalType } from 'src/common/types'
import { clamp, lerp } from './math'

// special nominal type for the detail level
export type DetailLevel = NominalType<number, 'dtlvl'>
export function getDetailLevel(pageHeight: number) {
  return (64 / pageHeight) as DetailLevel
}
export function isDetailLevel(zoom: number, detailLevel: DetailLevel) {
  return zoom < detailLevel
}

export function computeSafeZoom(
  zoom: number,
  viewHeight: number,
  pageHeight: number,
  detLevel = getDetailLevel(pageHeight),
) {
  if(!isDetailLevel(zoom, detLevel)) {
    return zoom // always safe when not at the detail level
  }

  // safe zoom <=> its corresponding cell height is an integer
  // cell height:
  // const ph = pageHeight
  // const vh = viewHeight
  // const dh = unCoord(0, 1, NoRounding).y() - unCoord(0, 1, NoRounding).y()
  //
  // => delta of unProject @y=0 and y=1/ph
  // const offsetY = this.transform.offset.y()
  // const uy0 = ((offsetY / zoom - 0.5) - 1) * vh
  // const uy1 = (((1/ph + offsetY) / zoom - 0.5) - 1) * vh
  // we want dh = (uy1 - uy0)
  // const dh = ((1/ph) / zoom) * vh
  //
  // note: dh = (vh / ph) / zoom
  const vhByPh = viewHeight / pageHeight
  const currHeight = vhByPh / zoom // height corresponding to argument zoom
  const intHeight = Math.max(1, Math.round(currHeight)) // get closest strictly positive positive integer
  // zoom = (vh / ph) / dh
  return vhByPh / intHeight
}

export interface ZoomRange {
  minZoom: number
  maxZoom: number
  zoomSteps?: number
}

export interface ZoomConfig extends ZoomRange {
  detailLevel?: DetailLevel
}

export interface ZoomData extends ZoomConfig {
  zoomLevel: number
}

export const ZOOM_STEPS = 50; // The zoom steps between .1 and 10. (logarithmic)
export function getZoomFromLevel({
  zoomLevel,
  minZoom,
  maxZoom,
  zoomSteps = ZOOM_STEPS,
}: ZoomData) {
  const lgZoom = lerp(minZoom, maxZoom, zoomLevel / zoomSteps)
  return Math.exp(lgZoom)
}

export function computeSafeZoomFromLevel(zoomData: ZoomData, viewHeight: number, pageHeight: number) {
  const expZoom = getZoomFromLevel(zoomData)
  return computeSafeZoom(expZoom, viewHeight, pageHeight, zoomData.detailLevel)
}

export function getZoomRange(pageHeight: number): ZoomRange {
  const minZoom = Math.log(0.03 * (512 / pageHeight))
  const maxZoom = Math.log(10.0 * (512 / pageHeight))
  const dist = (1.5 - minZoom) / (maxZoom - minZoom)
  const zoomSteps = Math.round(dist * ZOOM_STEPS)
  return {
    minZoom,
    maxZoom,
    zoomSteps,
  }
}

export function setZoomRange(
  data: ZoomConfig,
  pageHeight: number,
) {
  const { minZoom, maxZoom, zoomSteps } = getZoomRange(pageHeight)
  data.minZoom = minZoom
  data.maxZoom = maxZoom
  data.zoomSteps = zoomSteps
}

export function resetZoomData({
  zoomLevel,
}, pageHeight: number): ZoomData {
  const zr = getZoomRange(pageHeight)
  return {
    ...zr,
    zoomLevel: clamp(zoomLevel, 0, zr.zoomSteps),
    detailLevel: getDetailLevel(pageHeight),
  }
}

export function getHomeLevel({
  minZoom,
  maxZoom,
  zoomSteps = ZOOM_STEPS,
}: ZoomRange) {
  const dist = (0.0 - minZoom) / (maxZoom - minZoom);
  return Math.round(dist * zoomSteps);
}

export function getNextZoomLevel(
  {
    zoomLevel,
    zoomSteps = ZOOM_STEPS,
  }: ZoomData,
  dir: number,
): number {
  const sign = Math.sign(dir)
  return clamp(zoomLevel + sign, 0, Math.min(zoomSteps, ZOOM_STEPS))
}

const SCROLL_SPEED = 0.001; // Scroll speed for canvas (note: FPS dependent but should work on 98% of modern computers)
export function getScrollSpeed(pageWidth: number, pageHeight: number) {
  return [
    SCROLL_SPEED * 512 / pageWidth,
    SCROLL_SPEED * 512 / pageHeight,
  ] as const
}
