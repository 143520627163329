import { buildAABB } from 'src/common/math'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { setMarker, setSelection, unsetMarker } from 'src/editor/time-needle/slice'
import { ctrlStr } from 'src/common/env'
import ContextMenuItem, { Separator } from './menu-item'

export default function MarkerMenu() {
  const dispatch = useAppDispatch()
  const [x, y] = useAppSelector((state) => state.canvas.selection)
  const markerData = useAppSelector((state) => state.canvas.markerData)
  return (
    <ContextMenuItem
      name="Marker"
      menu
    >
      <ContextMenuItem
        name="Set marker"
        onClick={() => dispatch(setMarker([x, y]))}
        shortcut={`${ctrlStr}+Space`}
      />
      <ContextMenuItem
        name="Unset marker"
        onClick={() => dispatch(unsetMarker())}
        shortcut={`${ctrlStr}+Space`}
        disabled={!markerData[0]}
      />
      <Separator />
      <ContextMenuItem
        name="Selection to marker"
        onClick={() => {
          const [l, b, r, t] = buildAABB(
            [markerData[1], markerData[2]],
            [x, y],
          )
          dispatch(setSelection([l, b, r + 1, t + 1]))
        }}
        disabled={!markerData[0]}
      />
    </ContextMenuItem>
  )
}
