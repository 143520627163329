import {
  issue, Issue, Loop, replayLoops, SimulationBlock,
} from 'src/data/compiler';

export function pickUpStitchesShouldBeStable(blocks: SimulationBlock[], loopIndex: Loop[], issues: Issue[]) {
  for(const block of blocks) {
    const { firstLoop, lastLoop } = block.endState
    if(!firstLoop) {
      continue // no loop created in this block
    }
    if(!lastLoop) {
      issues.push(issue('simulation').invalid(
        'Knitting machine state has a first loop but no last loop',
      ))
      continue // dangerous to compute anything given the invalid state
    }

    // replay actions and check whether the loops are stable at creation time
    replayLoops(block, loopIndex, issues, (state, loop) => {
      // XXX here we assume a single carrier is creating the loop
      //     what about cases with multiple carriers anchored at different locations?
      const n = loop.srcNeedle
      if(!state.isEmpty(n) || loop.numPrevious === 0 || loop.numParents !== 0) {
        // either not a pick-up stitch,
        // or a starting loop (treated as stable)
        return
      }
      const [pn, prevLoop] = loop.previous[0]
      if(
        n.side === pn.side && // previous was on the same bed side
        Math.abs(n.offset - pn.offset) <= 1 && // distance to previous is 1 or 0
        prevLoop.numParents === 0 // previous is also a pick-up stitch
      ) {
        // row information if any
        const srcRow = Array.isArray(loop.source) ? loop.source[0] : loop.source
        // unstable pick-up stitch
        issues.push(issue('unstable-pick-up').danger(
          'Pick-up stitch is not stable',
        ).between(
          loop.source,
          typeof srcRow === 'number' ? [srcRow, pn.offset] : void 0,
        ))
      }
    })
  }
}
