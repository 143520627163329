import { useAppSelector } from 'src/hooks'
import ColRowItems from './items-col-row'
import RackingMenu from './menu-racking'
import CopyPasteMenu from './menu-copy-paste'
import ContextMenuItem, { Separator } from './menu-item'
import MarkerMenu from './menu-marker'
import UndoRedoMenu from './menu-undo-redo'
import { ImageMenu } from './menu-image'

export default function SingleMenu() {
  const [cellX, cellY] = useAppSelector((state) => state.canvas.selection)
  const cellStr = `Column: ${cellX + 1}, Row: ${cellY + 1}`;
  return (
    <>
      <ContextMenuItem
        name={cellStr}
        disabled
        header
      />
      <Separator />
      <UndoRedoMenu />
      <CopyPasteMenu />
      <MarkerMenu />
      <RackingMenu />
      <ImageMenu />
      <Separator />
      <ColRowItems />
    </>
  )
}
