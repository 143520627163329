import {
  buildAABB,
  clampToPage,
  clipLine,
  FV2,
  getCoord,
  getRackedCell,
  isWithinPage,
  RFV2,
} from 'src/common/math'
import { ViewMode } from 'src/data/time-needle/view-mode'
import { getRacking } from 'src/data/time-needle/options'
import {
  extractLineToPixelBuffer,
  extractPixelAsPixelBuffer,
  extractRectBuffer,
} from 'src/data/image'
import { BaseAction } from './base'
import { ActionContext } from './common'

export default abstract class DrawAction extends BaseAction {
  protected drawing = false

  protected downCoord = [0, 0] as FV2

  protected drawCoord = [0, 0] as FV2

  protected inBounds = false

  protected yarnPixel = new Uint8Array(1) // channels = 1

  protected stitchPixel = new Uint8Array(1) // channels = 1

  abstract isIndirect(): boolean

  isDrawButton(button: number) {
    return button === 0
  }

  protected getCoord(
    {
      transform, M,
      tnimage,
      activeView,
    }: ActionContext,
    p: FV2,
  ) {
    if(activeView === ViewMode.STITCH) {
      // need to address racked rear locations
      return getRackedCell(p, M, transform.pageDims, (row: number) => getRacking(tnimage, row))
    }
    // direct coordinates
    return getCoord(p, M, transform.pageDims, Math.floor)
  }

  protected getBoundedCoord(ctx: ActionContext, p: FV2) {
    const coord = this.getCoord(ctx, p)
    return clampToPage(coord, ctx.transform.pageDims)
  }

  protected getDrawPixel({ activeStitch }: ActionContext) {
    // if(activeView === ViewMode.STITCH) {
    this.stitchPixel.set([activeStitch])
    return this.stitchPixel
    // }
    // this.yarnPixel[0] = activeYarn
    // return this.yarnPixel
  }

  protected getBoundedSegment({ transform: { pageDims }}: ActionContext, A: FV2, B: FV2) {
    return clipLine(A, B, [0, 0, pageDims[0] - 1, pageDims[1] - 1])
  }

  protected getPixel(
    ctx: ActionContext,
    coord = this.drawCoord,
    pixel = this.getDrawPixel(ctx),
  ) {
    return extractPixelAsPixelBuffer(ctx.tnimage.cdata, coord, pixel)
  }

  protected getLine(
    ctx: ActionContext,
    A: RFV2,
    B: RFV2,
    pixel = this.getDrawPixel(ctx),
  ) {
    return extractLineToPixelBuffer(
      ctx.tnimage.cdata,
      A,
      B,
      pixel,
    )
  }

  protected getRect(
    ctx: ActionContext,
    A: FV2,
    B: FV2,
    pixel = this.getDrawPixel(ctx),
  ) {
    const [left, bottom, right, top] = buildAABB(A, B)
    // /!\ rect buffers assume that [x0, y0] and [x1, y1] form an AABB
    // => the largest of each dimension is exclusive (not part of the region)
    return extractRectBuffer(
      ctx.tnimage.cdata,
      [left, bottom, right + 1, top + 1],
      pixel,
    )
  }

  onMouseDown(ctx: ActionContext, x: number, y: number, button: number): void {
    const {
      transform, // clearSelection,
    } = ctx
    this.drawing = false
    if(this.isDrawButton(button)) {
      // clearSelection()
      const indirect = this.isIndirect()
      if(indirect) {
        this.drawCoord = this.getBoundedCoord(ctx, [x, y])
        this.inBounds = true
      } else {
        this.drawCoord = this.getCoord(ctx, [x, y])
        this.inBounds = isWithinPage(this.drawCoord, transform.pageDims)
        if(!this.inBounds) {
          // not switching to draw mode
          return
        }
      }
      // remember where we pressed down (for click information)
      this.downCoord = this.drawCoord
      this.drawing = true
    } else {
      super.onMouseDown(ctx, x, y, button)
    }
  }

  onMouseUp(ctx: ActionContext, x: number, y: number, button: number): void {
    super.onMouseUp(ctx, x, y, button)
    this.drawing = false
  }
}
