import { shallowEqual } from 'react-redux'

import { mirror } from 'src/macros'
import { ctrlStr } from 'src/common/env'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { deferAction } from 'src/editor/time-needle/slice'
import { Select } from 'src/editor/time-needle/action'
import { ReadonlyTimeNeedleRegion } from 'src/data/time-needle/region'
import { TimeNeedleTransformer } from 'src/macros/common'
import { sameTimeNeedleImages } from 'src/data/time-needle/time-needle-image'
import ContextMenuItem from './menu-item'

export default function CopyPasteMenu() {
  const dispatch = useAppDispatch()
  const {
    action,
    markerData,
    selection,
    tnimage,
  } = useAppSelector(({
    canvas: {
      action, markerData, selection, tnimage,
    },
  }) => ({
    action,
    selection,
    tnimage,
    markerData,
  }), shallowEqual)
  const selWidth = selection[2] - selection[0]
  const selHeight = selection[3] - selection[1]

  const isSelect = action instanceof Select
  const canCopy = isSelect && (selWidth > 1 || selHeight > 1)
  const canPaste = isSelect && action.hasPasteData()
  const copyOrCut = (mode: 'copy' | 'cut') => {
    dispatch(deferAction(
      (action as Select).copyAction(mode === 'cut'),
    ))
  }
  const copyData = (img: ReadonlyTimeNeedleRegion) => {
    dispatch(deferAction(
      (action as Select).setCopyAction(img, false, false),
    ))
  }
  return (
    <ContextMenuItem
      name="Copy &amp; paste"
      disabled={!canCopy && !canPaste}
      menu
    >
      <ContextMenuItem
        name="Copy"
        shortcut={`(${ctrlStr}+C)`}
        disabled={!canCopy}
        onClick={() => copyOrCut('copy')}
      />
      <ContextMenuItem
        name="X-mirrored copy"
        disabled={!canCopy}
        onClick={() => {
          // extract region
          const s = TimeNeedleTransformer.fromRegionCrop(tnimage, selection)

          // create copy for identity validation
          const original = s.imageCopy()

          // apply mirroring
          mirror(s)

          // copy selection data afer mirroring
          const data = { ...s.imageCopy(), x: 0, y: 0 }

          // mirror content back
          mirror(s)

          // verify that it matches the original image
          if(sameTimeNeedleImages(original, s.image)) {
            copyData(data)
          } else {
            // the double inplace mirroring was not valid
            console.error('Double x-mirroring failed')
          }
        }}
      />
      <ContextMenuItem
        name="Bed-mirrored copy"
        disabled={!canCopy}
        onClick={() => {
          // extract region
          const s = TimeNeedleTransformer.fromRegionCrop(tnimage, selection)

          // create copy for identity validation
          const original = s.imageCopy()

          // apply mirroring
          s.mirrorBed()

          // copy selection data afer mirroring
          const data = { ...s.imageCopy(), x: 0, y: 0 }

          // mirror content back
          s.mirrorBed()

          // verify that it matches the original image
          if(sameTimeNeedleImages(original, s.image)) {
            copyData(data)
          } else {
            // the double inplace mirroring was not valid
            console.error('Double bed-mirroring failed')
          }
        }}
      />
      <ContextMenuItem
        name="Cut"
        shortcut={`(${ctrlStr}+X)`}
        disabled={!canCopy}
        onClick={() => copyOrCut('cut')}
      />
      <ContextMenuItem
        name="Paste"
        shortcut={`(${ctrlStr}+V)`}
        disabled={!canPaste}
        onClick={() => {
          dispatch(deferAction((action as Select).pastePreviewAction))
        }}
      />
      <ContextMenuItem
        name="Paste at marker"
        disabled={!canPaste || !markerData[0]}
        onClick={() => {
          dispatch(deferAction((action as Select).pasteAction([
            markerData[1], markerData[2],
          ])))
        }}
      />
    </ContextMenuItem>
  )
}
