import hash from 'object-hash'
import { Serialize } from 'src/data/serialization'
import { ReadonlyTimeNeedleImage } from 'src/data/time-needle/time-needle-image'

export function getHash(tni: ReadonlyTimeNeedleImage): string {
  // get serialized data
  const serPanel = Serialize(tni)

  // /!\ wrap typed arrays into buffers to have better, faster computation in object-hash => ~40x speedup
  // note: object-hash treats Buffer objects as direct byte streams and TypedArray as a generic objects
  const serData = [
    Buffer.from(serPanel), // panel as buffer
    // Buffer.from(serPalette), // palette as buffer
  ] // array leads to negligible overhead

  // hash buffers with default sha1 algorithm
  return hash(serData)
}
