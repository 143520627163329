import {
  BedSideOrBoth,
  KnittingSide,
  TimeNeedleTransformer,
  YarnIndex,
} from './common'

export function halfKnitFromRight(
  rows: TimeNeedleTransformer,
  row: number,
  [left, right]: [number, number],
  sides: KnittingSide[],
  yarn: YarnIndex,
) {
  let hasBothKnit = false
  for(let x = right - 1; x >= left; x -= 2) {
    const side = sides[x - left]
    if(side !== KnittingSide.NONE) {
      rows.cellAt(row, x).knit(side as BedSideOrBoth, yarn)
      if(side === KnittingSide.BOTH) hasBothKnit = true
    }
  }
  if(hasBothKnit) rows.setRowOptions(row, { racking: 0.5 })
}

export function halfKnitFromRight2(
  rows: TimeNeedleTransformer,
  row: number,
  [left, right]: [number, number],
  sides: KnittingSide[],
  yarn: YarnIndex,
) {
  let hasBothKnit = false
  for(let x = left + ((right - left) % 2); x < right; x += 2) {
    const side = sides[x - left]
    if(side !== KnittingSide.NONE) {
      rows.cellAt(row, x).knit(side as BedSideOrBoth, yarn)
      if(side === KnittingSide.BOTH) hasBothKnit = true
    }
  }
  if(hasBothKnit) rows.setRowOptions(row, { racking: 0.5 })
}

export function fullKnit(
  rows: TimeNeedleTransformer,
  row: number,
  [left, right]: [number, number],
  sides: KnittingSide[],
  yarn: YarnIndex,
) {
  let hasBothKnit = false
  for(let x = left; x < right; ++x) {
    const side = sides[x - left]
    if(side !== KnittingSide.NONE) {
      rows.cellAt(row, x).knit(side as BedSideOrBoth, yarn)
      if(side === KnittingSide.BOTH) hasBothKnit = true
    }
  }
  if(hasBothKnit) rows.setRowOptions(row, { racking: 0.5 })
}

export function dropRest(
  rows: TimeNeedleTransformer,
  row: number,
  [left, right]: [number, number],
  sides: KnittingSide[],
  dropTrail = true,
) {
  let hasDropBoth = false
  for(let x = 0; x < right; ++x) {
    const c = rows.cellAt(row, x)
    if(x < left) {
      if(dropTrail) c.rearDrop() // to ease removal = keep only front
    } else {
      const side = sides[x - left]
      if(side === KnittingSide.NONE) {
        hasDropBoth = true
        c.bothDrop()
      } else if(side !== KnittingSide.BOTH) {
        c.drop(side === KnittingSide.FRONT ? 1 : 0)
      }
    }
  }
  if(hasDropBoth) {
    rows.setRowOptions(row, { racking: 0.5 })
  }
}
