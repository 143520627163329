// A list of required (but common) WebGL extensions
// The renderer will check each of these and load the extension as the value,

import { WebGLXRenderingContext } from './types';
import {
  checkGLError, clearViewport, getRendererInfo, setBlending, setViewport,
} from './utils';

// replacing the extension name or setting it to null if failed.
const extensions = {
  // still useful for WebGL 2
  halfFloat: 'EXT_color_buffer_half_float',

  // only necessary for WebGL 1
  // standardDerivatives: 'OES_standard_derivatives',
  // textureFloat: 'OES_texture_float',
  // vertexArrayObject: 'OES_vertex_array_object',
  // colorBufExtension: 'WEBGL_color_buffer_float',
  // depthTexExtension: 'WEBGL_depth_texture',
  // shaderTextureLod: 'EXT_shader_texture_lod',

  // for testing purposes
  fakeExtension: 'OES_should_not_exist',
}

export function createContext(
  canvas: HTMLCanvasElement,
  config: WebGLContextAttributes = {
    antialias: false,
    alpha: false,
    depth: false,
    stencil: false,
    premultipliedAlpha: false,
  },
): WebGLXRenderingContext {
  // get context from canvas
  const gl = canvas.getContext('webgl2', config) as WebGLXRenderingContext

  // shortcut if getting the context failed
  if(!gl) { return gl }

  // try getting the renderer debug information
  getRendererInfo(gl)

  // load necessary extensions
  // /!\ note: most of them are unnecessary in WebGL 2
  // the only remaining one is for half-float capabilities
  for(const extName of [
    // for WebGL 1 + 2
    'EXT_color_buffer_half_float',

    // for WebGL 1 only
    // 'OES_standard_derivatives',
    // 'EXT_shader_texture_lod',
    // 'OES_texture_float',
    // 'OES_vertex_array_object',
    // 'WEBGL_color_buffer_float',
    // 'WEBGL_depth_texture',

    // for testing undefined behavior
    'OES_should_not_exist',
  ] as const) {
    gl.getExtension(extName)
  }

  // renderer-wide states
  gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1) // most general, least efficient

  // default blending
  setBlending(gl, false)

  // set initial color
  // const clearColor = gl.getParameter(gl.COLOR_CLEAR_VALUE)
  gl.clearColor(213 / 255, 219 / 255, 224 / 255, 1.0)
  clearViewport(gl)

  // actual blending later on
  // note: argument `true` is to match the historical value
  // but should it actually be false so we don't do additional blending?
  setBlending(gl, true)

  // viewport
  setViewport(
    gl,
    Math.floor(canvas.width),
    Math.floor(canvas.height),
  )

  // only return context if there are no error to now
  // because those errors are likely going to mess things later anyway
  const error = checkGLError(gl, 'createContext')
  return !error ? gl : null
}
