import { KEYCODE_L } from 'src/common/keyboard'
import {
  PixelBuffer, pixelEntries, replacePixelBuffer,
} from 'src/data/image'
import { ChannelCount, RegionDataFunc } from 'src/common/webgl/texture'
import { addUndoableAction, UndoableAction, UndoDomain } from 'src/undo'
import { RootState } from 'src/store'
import { copyTimeNeedleImage } from 'src/data/time-needle/time-needle-image'
import IndirectDrawAction from './idraw'
import { ActionContext } from './common'
import { registerShortcut } from './base'

export default class Line extends IndirectDrawAction {
  preview(ctx: ActionContext) {
    const { tnimage, updatePreviewData } = ctx
    const buffer = this.getLine(ctx, this.startCoord, this.drawCoord)
    updatePreviewData(texturePixelUpdate(buffer, tnimage.cdata.width))
  }

  commit(ctx: ActionContext) {
    const { dispatch, tnimage } = ctx
    const buffer = this.getLine(ctx, this.startCoord, this.drawCoord)
    // trigger undoable line action
    dispatch(addUndoableAction(
      new UndoablePixelAction(buffer, tnimage.cdata.width),
    ))
  }
}
registerShortcut(KEYCODE_L, Line)

/**
 * Texture update with a pixel buffer
 *
 * @param buffer the pixel buffer
 * @param dataWidth the width of the underlying image
 * @returns the texture update function
 */
export function texturePixelUpdate<NC extends ChannelCount>(
  buffer: PixelBuffer<NC>,
  dataWidth: number,
) {
  const { channels } = buffer
  return (updateRegion: RegionDataFunc) => {
    for(const [idx, data] of pixelEntries(buffer)) {
      const [x, y] = [
        idx % dataWidth,
        Math.floor(idx / dataWidth),
      ]
      updateRegion({
        x, y, channels, data,
      })
    }
  }
}

/**
 * Undoable action that updates the time-needle image's code
 * with a given pixel buffer.
 */
export class UndoablePixelAction
implements UndoableAction {
  readonly domain = UndoDomain.EDITOR

  constructor(
    protected readonly buffer: PixelBuffer<1>,
    protected readonly imageWidth: number,
  ) {}

  checkWidth(state: RootState) {
    if(state.canvas.width !== this.imageWidth) {
      throw `Invalid pixel action onto an image of different width: expected ${this.imageWidth}, got ${state.canvas.width}`
    }
  }

  applyTo(state: RootState): [RootState, UndoableAction] {
    this.checkWidth(state)
    const tnimage = copyTimeNeedleImage(state.canvas.tnimage)
    const undoBuffer = replacePixelBuffer(tnimage.cdata, this.buffer)
    const undo = new UndoablePixelAction(
      undoBuffer,
      this.imageWidth,
    )
    return [
      {
        ...state,
        canvas: {
          ...state.canvas,
          tnimage,
        },
      },
      undo,
    ]
  }
}
