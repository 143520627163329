/*
ModelDefaults is the set of values used to initialise the basic design-app panel data model.
 */

import { ofields } from './option-field';

export const [
  KniterateOptions,
  KniterateOptionsList,
  KniterateOptionsBytes,
] = ofields({
  racking: {
    min: -4,
    max: 4,
    step: 0.5,
    defaultValue: 0.0,
    float: true,
  },
  carriageSpeed: {
    min: 10,
    max: 800,
    step: 10,
    defaultValue: 300,
  },
  roller: {
    min: -32768, // 0 for uint16, -32768 for int16
    max: 32767, // 65535 for uint16, 32767 for int16
    step: 1,
    defaultValue: 450,
  },
  frontStitchSize: {
    min: 0,
    max: 15,
    step: 1,
    defaultValue: 6,
  },
  rearStitchSize: {
    min: 0,
    max: 15,
    step: 1,
    defaultValue: 6,
  },
  direction: {
    min: 0,
    max: 2,
    step: 1,
    defaultValue: 2,
  },
  carrier: {
    min: 0,
    max: 6,
    step: 1,
    defaultValue: 0,
  },
})

export const KniterateBedWidth = 252
