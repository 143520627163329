import * as Sentry from '@sentry/browser';
import { Component } from 'react';
// this code from Sentry react quickstart
// Sentry.init has to be called in the somewhere before
// Sentry.init();

interface Props {
    children: any;
}

interface ErrorBoundaryState {
    error: any;
}

class ErrorBoundary extends Component<Props, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    error: null,
  };

  public componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  public render() {
    if(this.state.error) {
      // render fallback UI
      return (
        <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>
      );
    }
    // when there's not an error, render children untouched
    return this.props.children;
  }
}
export default ErrorBoundary;
