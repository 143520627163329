import { Range, RFV4 } from 'src/common/math';
import { ReadonlyUint32Array } from 'src/common/types';
import TimeNeedleImage, { copyTimeNeedleImage, ReadonlyTimeNeedleImage } from './time-needle-image';
import { TimeNeedleCellReader } from './cell'
import { RowOptions, getRowOptions } from './options';

/**
 * A value representing `false`
 */
export type Falsy = null | undefined | false

/**
 * A function used for sorting a collection of a given type
 */
export type SortFunction<T = number> = (a: T, b: T) => number

export interface ThresholdFunc<T, CellType> {
  (px: T, c: () => CellType): boolean
}

/**
 * An index mapping
 */
export type IndexMapping = (idx: number) => number

/**
  * Identity index mapping
  */
export const IdentityMapping = (idx: number) => idx

/**
 * Creates a grid from the bits of a number
 */
export function bitGrid(num: number, width = 0) {
  const grid = num.toString(2).split('').map((c) => parseInt(c, 10))
  if(width === 0) {
    return grid
  }
  // pad on the left with 0 until width matches
  while(grid.length % width) grid.unshift(0)
  return grid;
}

export function isNumberIterable(type: any): type is Iterable<number> {
  return typeof type === 'object' && typeof type[Symbol.iterator] === 'function'
}

export function selectAll(N: number) {
  const sel = new Uint32Array(N)
  for(let i = 0; i < N; ++i) {
    sel[i] = i
  }
  return sel
}

export interface Size {
  width: number
  height: number
}
export function selectArea(
  { width, height }: Size,
  [left, bottom, right, top]: RFV4,
) {
  if(left > right || bottom > top) {
    throw 'Invalid area bounds: invalid order'
  }
  const maxX = Math.min(right, width)
  const maxY = Math.min(top, height)
  const minX = Math.max(0, left)
  const minY = Math.max(0, bottom)
  const index = new Array<number>()
  for(let y = minY; y < maxY; ++y) {
    for(let x = minX; x < maxX; ++x) {
      index.push(y * width + x)
    }
  }
  return new Uint32Array(index)
}

export interface TimeNeedleSelectorLike {
  readonly image: ReadonlyTimeNeedleImage
  readonly selection: ReadonlyUint32Array
}

export abstract class TimeNeedleSelectorBase<
  ImageType extends ReadonlyTimeNeedleImage,
  CellType extends TimeNeedleCellReader,
  FilteredType extends TimeNeedleSelectorLike,
> implements TimeNeedleSelectorLike {
  public readonly width: number

  public readonly height: number

  constructor(
    public readonly image: ImageType,
    public readonly selection: ReadonlyUint32Array,
    private readonly makeCell: (img: ImageType, idx: number) => CellType,
  ) {
    this.width = image.cdata.width
    this.height = image.cdata.height
  }

  get area() { return this.width * this.height }

  get length() { return this.selection.length }

  // checks
  /**
   * Check whether the selection is empty
   */
  isEmpty() { return this.selection.length === 0 }

  /**
   * Check whether another selector matches this one
   *
   * @param that the other selector
   * @param strictOrder whether the seleciton order matters or not
   * @returns whether the other selector's selection matches
   */
  matches(
    that: TimeNeedleSelectorBase<ImageType, CellType, FilteredType>,
    strictOrder = false,
  ): boolean {
    if(that.length !== this.length) { return false }
    // in strict mode, the order must be the same
    if(strictOrder) {
      return that.selection.every((sel, i) => this.selection[i] === sel)
    }
    // else the order does not matter
    const selSet = new Set(this.selection)
    return that.selection.every((sel) => selSet.has(sel))
  }

  // getters
  cell(idx: number): CellType {
    if(idx >= 0 && idx < this.area) {
      return this.makeCell(this.image, idx)
    }
    return null
  }

  cellAt(row: number, column: number, safe = true) {
    return this.cell(this.indexAt(row, column, safe))
  }

  getCell(idx: number): CellType {
    if(idx >= 0 && idx < this.selection.length) {
      return this.cell(this.selection[idx])
    }
    return null
  }

  indexAt(row: number, column: number, safe = true): number {
    if(safe) {
      if(!(row >= 0 && row < this.height) ||
      !(column >= 0 && column < this.width)) {
        throw `Invalid indexing: row=${row}, col=${column}, width=${this.width}, height=${this.height}`
      }
    }
    return this.width * row + column
  }

  abstract withSelection(sel: ArrayLike<number> | ArrayBufferLike | Iterable<number>): FilteredType

  // generic selectors
  protected self() { return this.withSelection(this.selection) }

  remapped(map: IndexMapping) {
    return this.withSelection(this.selection.map(map))
  }

  all() { return this.withSelection(selectAll(this.area)) }

  empty() { return this.withSelection([]) }

  filter(pred: (c:CellType) => boolean) {
    return this.withSelection(this.selection.filter((cellIdx) => pred(this.cell(cellIdx))))
  }

  count(pred: (c:CellType)=> boolean): number {
    return this.selection.reduce((num: number, cellIdx: number) => (pred(this.cell(cellIdx)) ? num + 1 : num), 0)
  }

  map(map: (c:CellType) => TimeNeedleCellReader | Falsy) {
    const index = new Set<number>()
    for(const idx of this.selection) {
      const newCell = map(this.cell(idx))
      if(newCell) {
        index.add(newCell.index)
      }
    }
    return this.withSelection(index)
  }

  remap<T>(map: (c: CellType) => T) {
    return Array.from(this.selection, (cellIdx) => map(this.cell(cellIdx)))
  }

  reduce<T>(red: (accu: T, el: CellType) => T, init: T): T {
    return this.selection.reduce((prev, idx) => red(prev, this.cell(idx)), init)
  }

  some(pred: (c: CellType) => boolean): boolean {
    return this.selection.some((cellIdx) => pred(this.cell(cellIdx)))
  }

  every(pred: (c: CellType) => boolean): boolean {
    return this.selection.every((cellIdx) => pred(this.cell(cellIdx)))
  }

  find(pred: (c: CellType) => boolean): CellType {
    const selIdx = this.selection.findIndex((cellIdx) => pred(this.cell(cellIdx)))
    return selIdx === -1 ? null : this.cell(selIdx)
  }

  // row/column selectors
  wales(range: number[]) {
    const wrg = Range.from(this.width, ...range)
    return this.filter((c: CellType) => wrg.includes(c.column))
  }

  waleSet(colSet: Set<number>) {
    return this.filter((c: CellType) => colSet.has(c.column))
  }

  courses(range: number[]) {
    const crg = Range.from(this.height, ...range)
    return this.filter((c: CellType) => crg.includes(c.row))
  }

  courseSet(rowSet: Set<number>) {
    return this.filter((c: CellType) => rowSet.has(c.row))
  }

  fullCourse(row: number) {
    return this.withSelection(Array.from({ length: this.width }, (_, column) => this.indexAt(row, column)))
  }

  fullCourses(range: number[]) {
    const crg = Range.from(this.height, ...range)
    const rows = Array.from({ length: this.height }, (_, i) => i).filter((row) => crg.includes(row))
    return this.withSelection(rows.flatMap((row) => Array.from({ length: this.width }, (_, column) => this.indexAt(row, column, true))))
  }

  select(courseRange: number[], waleRange: number[]) {
    const wrg = Range.from(this.width, ...waleRange)
    const crg = Range.from(this.height, ...courseRange)
    return this.filter((c: CellType) => crg.includes(c.row) && wrg.includes(c.column))
  }

  reselect(courseRange: number[], waleRange: number[]) {
    const wrg = Range.from(this.width, ...waleRange)
    const crg = Range.from(this.height, ...courseRange)
    return this.withSelection(selectAll(this.area).filter((idx) => {
      const c = this.cell(idx)
      return crg.includes(c.row) && wrg.includes(c.column)
    }))
  }

  // actions
  forEach(f: (c:CellType, i: number) => void) {
    for(const [i, idx] of this.selection.entries()) {
      f(this.cell(idx), i)
    }
    return this.self()
  }

  // neighbor mappings
  neighbor(dx: number, dy: number) { return this.map((c) => c.neighbor(dx, dy)) }

  left() { return this.map((c) => c.left()) }

  right() { return this.map((c) => c.right()) }

  up() { return this.map((c) => c.up()) }

  down() { return this.map((c) => c.down()) }

  extendBy(dx: number, dy: number) {
    if(dx === 0 && dy === 0) return this;
    const sdx = Math.sign(dx)
    const sdy = Math.sign(dy)
    const index = new Set<number>()
    for(const idx of this.selection) {
      index.add(idx)
      const { x: xs, y: ys } = this.cell(idx)
      if(dx === 0) {
        // dy !== 0
        const ye = dy > 0 ? Math.min(ys + dy + 1, this.height) : Math.max(ys + dy - 1, -1)
        for(let y = ys; y !== ye; y += sdy) {
          const nidx = this.indexAt(y, xs)
          index.add(nidx)
        }
      } else if(dy === 0) {
        // dx !== 0
        const xe = dx > 0 ? Math.min(xs + dx + 1, this.width) : Math.max(xs + dx - 1, -1)
        for(let x = xs; x !== xe; x += sdx) {
          const nidx = this.indexAt(ys, x)
          index.add(nidx)
        }
      } else {
        // dx !== 0 && dy !== 0
        const ye = dy > 0 ? Math.min(ys + dy + 1, this.height) : Math.max(ys + dy - 1, -1)
        const xe = dx > 0 ? Math.min(xs + dx + 1, this.width) : Math.max(xs + dx - 1, -1)
        for(let y = ys; y !== ye; y += sdy) {
          for(let x = xs; x !== xe; x += sdx) {
            const nidx = this.indexAt(y, x)
            index.add(nidx)
          }
        }
      }
    }
    return this.withSelection(index)
  }

  extendUp(n = this.height) { return this.extendBy(0, n) }

  extendDown(n = this.height) { return this.extendBy(0, -n) }

  extendRight(n = this.width) { return this.extendBy(n, 0) }

  extendLeft(n = this.width) { return this.extendBy(-n, 0) }

  // merging
  /**
   * Compute the selection set-union: `this or arg`
   * If the argument is a list of selectors,
   * then each of those are added to the union with the current selection.
   *
   * @param arg a selector or a list of selectors
   * @returns the selector union
   */
  union(arg: TimeNeedleSelectorLike | TimeNeedleSelectorLike[]) {
    if(Array.isArray(arg)) {
      // preallocate full index
      const index = new Uint8Array(this.width * this.height)
      for(const idx of this.selection) {
        index[idx] = 1
      }
      for(const s of arg) {
        for(const idx of s.selection) {
          index[idx] = 1
        }
      }
      // keep any index that is non-zero
      const len = index.reduce((n, b) => (b ? n + 1 : n), 0)
      const selection = new Uint32Array(len)
      for(let i = 0, j = 0; i < index.length; ++i) {
        if(index[i]) {
          selection[j] = i
          ++j
        }
      }
      return this.withSelection(selection)
    }
    const index = new Set<number>(this.selection)
    for(const idx of arg.selection) {
      index.add(idx)
    }
    return this.withSelection(index)
  }

  /**
   * Compute the selection set-intersection: `this and arg`
   * If the argument is a list of selectors,
   * then each of those are intersected with the current selection,
   * which is equivalent to intersecting with their intersection recursively
   * .
   * @param arg a selector or a list of selectors
   * @returns the selector insertection
   */
  intersect(arg: TimeNeedleSelectorLike | TimeNeedleSelectorLike[]) {
    if(Array.isArray(arg)) {
      // preallocate full index
      const index = new Uint8Array(this.width * this.height)
      for(const idx of this.selection) {
        index[idx] = 1
      }
      for(const s of arg) {
        for(const idx of s.selection) {
          index[idx] += 1
        }
      }
      // only keep indices that intersect all => value is #sets
      const N = 1 + arg.length
      const len = index.reduce((n, c) => (c === N ? n + 1 : n), 0)
      const selection = new Uint32Array(len)
      for(let i = 0, j = 0; i < index.length; ++i) {
        if(index[i] === N) {
          selection[j] = i
          ++j
        }
      }
      return this.withSelection(selection)
    }
    const index = new Set<number>(arg.selection)
    return this.withSelection(this.selection.filter((idx) => index.has(idx)))
  }

  /**
   * Compute the selection set-difference: `this \ arg`
   * If the argument is a list of selectors,
   * then each of those are subtracted from the current selection,
   * which is equivalent to subtracting their union.
   *
   * @param arg a selector, or a list of selectors
   * @returns the selector difference
   */
  minus(arg: TimeNeedleSelectorLike | TimeNeedleSelectorLike[]) {
    if(Array.isArray(arg)) {
      // preallocate full index
      const index = new Uint8Array(this.width * this.height)
      for(const s of arg) {
        for(const idx of s.selection) {
          index[idx] += 1
        }
      }
      return this.withSelection(this.selection.filter((idx) => index[idx] === 0))
    }
    const index = new Set<number>(arg.selection)
    return this.withSelection(this.selection.filter((idx) => !index.has(idx)))
  }

  // corners
  /**
   * Return a selection of the bottom-left image cell
   */
  bl() { return this.withSelection([0]) }

  /**
   * Return a selection of the bottom-right image cell
   */
  br() { return this.withSelection([this.width - 1]) }

  /**
   * Return a selection of the top-left image cell
   */
  tl() { return this.withSelection([this.width * (this.height - 1)]) }

  /**
   * Return a selection of the top-right image cell
   */
  tr() { return this.withSelection([this.area - 1]) }

  /**
   * Return a subselection of all cells that have minimum column index
   */
  leftmost() {
    const left = this.reduce((col, c) => Math.min(col, c.column), this.width)
    return this.filter((c) => c.column === left)
  }

  /**
   * Return a subselection of all cells that have maximum column index
   */
  rightmost() {
    const right = this.reduce((col, c) => Math.max(col, c.column), 0)
    return this.filter((c) => c.column === right)
  }

  /**
   * Return a subselection of all cells that have minimum row index
   */
  bottommost() {
    const bottom = this.reduce((row, c) => Math.min(row, c.row), this.height)
    return this.filter((c) => c.row === bottom)
  }

  /**
   * Return a subselection of all cells that have maximum row index
   */
  topmost() {
    const top = this.reduce((row, c) => Math.max(row, c.row), 0)
    return this.filter((c) => c.row === top)
  }

  // index-based

  /**
   * Return a selection that only keeps the smallest index
   */
  first() {
    if(this.isEmpty()) {
      return this.self()
    }
    // only keep the smallest index
    return this.withSelection([
      this.selection.reduce((minIdx, idx) => Math.min(minIdx, idx), Infinity),
    ])
  }

  /**
   * Return a selection that only keeps the largest index
   */
  last() {
    if(this.isEmpty()) {
      return this.self()
    }
    // only keep the greatest index
    return this.withSelection([
      this.selection.reduce((maxIdx, idx) => Math.max(maxIdx, idx), -Infinity),
    ])
  }

  /**
   * Return the cell associated with the first selection.
   * This assumes that the selection corresponds to a single index.
   */
  asCell() {
    if(this.selection.length !== 1) throw 'Can only call asCell on a single stitch selection'
    return this.cell(this.selection[0])
  }

  // grid-based
  filterGrid(pred: (x: number, y: number, w: number, h: number, c: () => CellType) => boolean) {
    // compute positions and range
    const xs = new Uint16Array(this.selection.length)
    const ys = new Uint16Array(this.selection.length)
    let minX = this.width
    let maxX = -1
    let minY = this.height
    let maxY = -1
    for(const [i, idx] of this.selection.entries()) {
      const { x, y } = this.cell(idx)
      xs[i] = x
      ys[i] = y
      minX = Math.min(minX, x)
      maxX = Math.max(maxX, x)
      minY = Math.min(minY, y)
      maxY = Math.max(maxY, y)
    }
    const w = maxX - minX + 1
    const h = maxY - minY + 1
    const indices = this.selection.filter((idx: number, i: number) => pred(
      xs[i] - minX,
      ys[i] - minY,
      w,
      h,
      () => this.cell(idx),
    ))
    return this.withSelection(indices)
  }

  /**
   * Filtering predicate based on a grid being tiled over the selection
   */
  tile<T = number>(grid: number | number[] | T[] | T[][], gridWidth: number, threshFunc: ThresholdFunc<T, CellType> = (px) => !!px) {
    // special grid as a number => use its bits
    if(typeof grid === 'number' && gridWidth) {
      grid = bitGrid(grid, gridWidth)
    }
    if(!Array.isArray(grid)) {
      throw 'Grid argument is not an array, nor a number with a given grid width argument'
    }
    if(gridWidth > 0) {
      const imgW = gridWidth;
      console.assert(grid.length % gridWidth == 0, 'Invalid grid width w.r.t. the grid argument');
      const imgH = grid.length / imgW;
      // array containing 2d grid
      return this.filterGrid((x, y, _1, _2, c) => {
        const gX = x % imgW;
        const gY = y % imgH;
        const val = grid[(gY * imgW + gX)] as T;
        return threshFunc(val, c);
      });
    }
    // array of arrays
    const ggrid = grid as T[][]
    const len = grid.length
    return this.filterGrid((x, y, _1, _2, c) => {
      const gY = y % len;
      const row = ggrid[gY];
      const gX = x % row.length;
      const val = row[gX];
      return threshFunc(val, c);
    });
  }

  tileMap<T = number>(grid: number | number[] | T[] | T[][], map: { [key: string]: (c: CellType) => void }, gridWidth = 0) {
    return this.tile<T>(grid, gridWidth, (v, c) => {
      const f = map[v.toString()]
      if(f) {
        const cell = c()
        f(cell)
      }
      // else, do nothing
      return true // no filtering => can chain operations
    })
  }

  //
  // per-row information
  //

  forEachRow(f: (row: number) => void) {
    for(const row of this.getRowSet()) f(row)
    return this.self()
  }

  mapRows<T>(f: (row: number) => T): T[] {
    return Array.from(this.getRowSet(), f)
  }

  findRow(pred: (row: number) => boolean): number {
    for(const row of this.getRowSet()) {
      if(pred(row)) {
        return row
      }
    }
    return -1
  }

  getColumnSet() {
    const cols = new Set<number>()
    for(const idx of this.selection) {
      cols.add(this.cell(idx).column)
    }
    return cols
  }

  getRowSet() {
    const rows = new Set<number>()
    for(const idx of this.selection) {
      rows.add(this.cell(idx).row)
    }
    return rows
  }

  getRowExtents() {
    if(this.isEmpty()) {
      return [] as number[]
    }
    return Array.from(this.getRowSet()).reduce(([min, max], row) => [Math.min(min, row), Math.max(max, row)] as [number, number], [Infinity, -Infinity] as [number, number])
  }

  /**
   * Split the current selector into a sequence of selectors,
   * each of which corresponds to a specific row.
   *
   * A sorting function can be used to ensure the row selectors
   * have a known order (by default ascending order).
   * The default ascending order uses
   * ```
   * sortFun = (a: number, b: number) => a - b
   * ```
   *
   * @param sortFun a sorting function or a falsy value (`false`, `undefined` or `null`) to not sort
   * @returns a list of selectors
   */
  splitByRow(
    sortFun: SortFunction | Falsy = (a, b) => a - b,
  ) {
    const rowToSel = new Map<number, Set<number>>()
    for(const idx of this.selection) {
      const c = this.cell(idx)
      const { row } = c
      if(rowToSel.has(row)) {
        rowToSel.get(row).add(idx)
      } else {
        rowToSel.set(row, new Set([idx]))
      }
    }
    const rowEntries = Array.from(rowToSel.entries())
    if(sortFun) {
      rowEntries.sort(([r1], [r2]) => sortFun(r1, r2))
    }
    return rowEntries.map(([, sel]) => this.withSelection(sel))
  }

  /**
   * Split the current selector into a sequence of selectors,
   * each of which corresponds to a specific column.
   *
   * A sorting function can be used to ensure the column selectors
   * have a known order (by default ascending order).
   * The default ascending order uses
   * ```
   * sortFun = (a: number, b: number) => a - b
   * ```
   *
   * @param sortFun a sorting function or a falsy value (`false`, `undefined` or `null`) to not sort
   * @returns a list of selectors
   * @see splitByRow
   */
  splitByColumn(
    sortFun: SortFunction | Falsy = (a, b) => a - b,
  ) {
    const colToSel = new Map<number, Set<number>>()
    for(const idx of this.selection) {
      const c = this.cell(idx)
      const { column } = c
      if(colToSel.has(column)) {
        colToSel.get(column).add(idx)
      } else {
        colToSel.set(column, new Set([idx]))
      }
    }
    const colEntries = Array.from(colToSel.entries())
    if(sortFun) {
      colEntries.sort(([r1], [r2]) => sortFun(r1, r2))
    }
    return colEntries.map(([, sel]) => this.withSelection(sel))
  }

  /**
   * Compute the extents information for the current selector,
   * which includes both sides (`left`, `bottom`, `right`, `top`)
   * and dimensions (`width` and `height`).
   */
  getExtents() {
    if(this.isEmpty()) {
      return {
        left: 0,
        right: 0,
        width: 0,
        bottom: 0,
        top: 0,
        height: 0,
      }
    }
    const [left, bottom, right, top] = Array.from(
      this.selection,
      (idx) => this.cell(idx),
    ).reduce(([l, b, r, t], c) => {
      const { x, y } = c
      return [
        Math.min(l, x),
        Math.min(b, y),
        Math.max(r, x + 1),
        Math.max(t, y + 1),
      ]
    }, [Infinity, Infinity, -Infinity, -Infinity])
    return {
      left,
      right,
      width: right - left,
      bottom,
      top,
      height: top - bottom,
    }
  }

  getOptions(): RowOptions[] {
    return this.mapRows((row: number) => this.rowOptions(row))
  }

  findOptionsRow(pred: (opts: RowOptions, rowIdx: number) => boolean): number {
    return this.findRow((row: number) => {
      const opts = this.rowOptions(row)
      return pred(opts, row)
    })
  }

  rowOptions(row: number) {
    return getRowOptions(this.image, row)
  }

  imageCopy(): TimeNeedleImage {
    return copyTimeNeedleImage(this.image)
  }
}

export default class TimeNeedleSelector extends TimeNeedleSelectorBase<
  ReadonlyTimeNeedleImage,
  TimeNeedleCellReader,
  TimeNeedleSelector
> {
  constructor(
    tni: ReadonlyTimeNeedleImage,
    sel: ReadonlyUint32Array,
  ) {
    super(tni, sel, TimeNeedleCellReader.from)
  }

  static from(
    tni: ReadonlyTimeNeedleImage,
    sel: ReadonlyUint32Array = selectAll(tni.cdata.width * tni.cdata.height),
  ) {
    return new TimeNeedleSelector(tni, sel)
  }

  /**
   * Create a selector associated with a readable time-needle image,
   * with a selection set to a specific sub-region of it.
   *
   * @param tni the time-needle image
   * @param area the sub-region to select
   * @returns its wrapping transformer
   */
  static fromRegion(tni: ReadonlyTimeNeedleImage, area: RFV4) {
    return new TimeNeedleSelector(tni, selectArea(tni.cdata, area))
  }

  /**
   * Create a selector that gather a given set of rows
   *
   * @param tni the time-needle image
   * @param rows a set of rows to select
   * @param full whether to select all columns of each row (`true`), or just the first one (`false`, default)
   * @returns the corresponding selector
   */
  static fromRows(
    tni: ReadonlyTimeNeedleImage,
    rows: Iterable<number>,
    full = false,
  ) {
    const { width } = tni.cdata
    if(full) {
      // all columns of each row
      return new TimeNeedleSelector(tni, new Uint32Array(
        Array.from(rows).flatMap((row) => Array.from({ length: tni.cdata.width }, (_, c) => row * width + c)),
      ))
    }
    // the first column of each row
    return new TimeNeedleSelector(tni, new Uint32Array(
      Array.from(rows, (row) => row * width),
    ))
  }

  withSelection(selection: ArrayLike<number> | ArrayBufferLike | Iterable<number>) {
    if(selection instanceof Uint32Array) {
      return new TimeNeedleSelector(this.image, selection)
    } if(isNumberIterable(selection)) {
      return new TimeNeedleSelector(this.image, new Uint32Array(selection))
    }
    return new TimeNeedleSelector(this.image, new Uint32Array(selection))
  }
}

export { TimeNeedleCellReader }
