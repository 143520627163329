import {
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react'

import { useAppDispatch } from 'src/hooks'
import { mouseLeave, mouseMove } from 'src/editor/reducers/ui'

export default function useMousePosition<T extends HTMLElement>() {
  const dispatch = useAppDispatch()
  const ref = useRef<T>()
  const [mousePosition, setMousePosition] = useState({
    left: 0,
    top: 0,
  })
  const [mouseInside, setMouseInside] = useState(false)

  useEffect(() => {
    if(mouseInside) {
      dispatch(mouseMove({x: mousePosition.left, y: mousePosition.top}))
    } else {
      dispatch(mouseLeave())
    }
  }, [mousePosition, mouseInside])

  const onPointerMove = useCallback((e: PointerEvent) => {
    setMousePosition({
      left: e.pageX,
      top: e.pageY, // - ref.current.offsetParent.offsetTop,
    })
    if(!mouseInside) setMouseInside(true)
  }, [])
  const onPointerLeave = useCallback(() => setMouseInside(false), [])

  const callbackRef = useCallback(
    (node) => {
      if(ref.current) {
        ref.current.removeEventListener('pointermove', onPointerMove)
        ref.current.removeEventListener('pointerleave', onPointerLeave)
      }

      ref.current = node

      if(ref.current) {
        ref.current.addEventListener('pointermove', onPointerMove)
        ref.current.addEventListener('pointerleave', onPointerLeave)
      }
    },
    [onPointerMove, onPointerLeave],
  )

  return [
    callbackRef,
    mousePosition,
    mouseInside,
  ] as [
    typeof callbackRef, typeof mousePosition, boolean
  ]
}
