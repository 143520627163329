import { Action, createAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

/**
 * Separate undo/redo domain
 */
export enum UndoDomain {
  EDITOR = 'editor',
  TEMPLATE = 'template',
}

/**
 * Generic undoable action that acts on the root state
 */
export interface UndoableAction {
  /**
   * Undo domain
   */
  readonly domain: UndoDomain

  /**
   * Apply a modificaton to the root state, thus producing
   * a new state together with an undoable action to undo
   * this undoable action itself.
   *
   * @param state the state as readonly input
   * @returns the new state and an undoable action for this update itself
   */
  applyTo(state: RootState): [RootState, UndoableAction]
}

/**
 * Special generic action that triggers a sequence of actions
 */
export class UndoableActionList implements UndoableAction {
  constructor(
    public readonly domain: UndoDomain,
    protected readonly actions: readonly UndoableAction[],
  ) {}

  isEmpty() { return this.actions.length === 0 }

  applyTo(state: RootState): [RootState, UndoableAction] {
    // apply actions from rear to front
    // while recording the undo sequence
    const undos = new Array<UndoableAction>()
    for(let i = this.actions.length - 1; i >= 0; --i) {
      let undo: UndoableAction
      [state, undo] = this.actions[i].applyTo(state)
      undos.push(undo)
    }
    return [state, new UndoableActionList(this.domain, undos)]
  }
}

/**
 * Special generic action that supports the concatenation of actions.
 */
export class UndoableConcatAction extends UndoableActionList {
  constructor(
    domain: UndoDomain,
    actions: readonly UndoableAction[] = [],
  ) {
    super(domain, actions)
  }

  concat(...undoActions: UndoableAction[]) {
    return new UndoableConcatAction(this.domain, this.actions.concat(undoActions))
  }

  end() { return new UndoableActionList(this.domain, this.actions) }
}

/**
 * Action triggering the undo action for a given undo domain
 */
export const undoAction = createAction<UndoDomain>('undo/undo')

/**
 * Action triggering the redo action for a given undo domain
 */
export const redoAction = createAction<UndoDomain>('undo/redo')

/**
 * Action that modifies the state while registering an undo action
 * for a specific undo domain.
 */
export const addUndoableAction = createAction<UndoableAction>('undo/add')

/**
 * Action that starts an action concatenation list
 */
export const startUndoConcat = createAction<UndoDomain>('undo/start-concat')

/**
 * Action that ends an action concatenation list
 */
export const endUndoConcat = createAction<UndoDomain>('undo/end-concat')

/**
 * The undo state per undo domain
 */
export interface PerDomainUndoRedoState {
  past: UndoableAction[]
  future: UndoableAction[]
  concat?: boolean
}

/**
 * The full undo state
 */
export type UndoRedoState = {
  [domain in UndoDomain]: PerDomainUndoRedoState
}

/**
 * The root state, extended with the undo state
 */
export interface RootStateWithUndoRedo extends RootState {
  undo: UndoRedoState
}

function initialPerDomainState(): PerDomainUndoRedoState {
  return { past: [], future: [], concat: false }
}
function initialState(): UndoRedoState {
  return {
    [UndoDomain.EDITOR]: initialPerDomainState(),
    [UndoDomain.TEMPLATE]: initialPerDomainState(),
  }
}

/**
 * Extend a sequence of past actions with a new action.
 *
 * The base case consists simply in `[...past, undo]`,
 * but taking `concat` into consideration, this becomes more complex:
 * - if concatenating, then we want the last action to be of `UndoableConcatAction`
 * - if it is the case, then the new action is concatenated with it
 * - else we wrap the new action in a new instance of `UndoableConcatAction`
 *
 * @param past the current past actions
 * @param undo the new undoable action
 * @param concat whether the action concatenation mode is on
 * @returns the updated past actions
 */
function concatPast(past: UndoableAction[], undo: UndoableAction, concat: boolean) {
  if(!concat) {
    return past.concat([undo])
  }
  if(!past.length) {
    return [new UndoableConcatAction(undo.domain, [undo])]
  }
  const lastAction = past[past.length - 1]
  if(lastAction instanceof UndoableConcatAction) {
    return [...past.slice(0, -1), lastAction.concat(undo)]
  }
  return past.concat([
    new UndoableConcatAction(undo.domain, [undo]),
  ])
}

/**
 * Filter a list of actions to only keep non-empty actions
 *
 * @param actions the list of actions to filter
 * @returns the non-empty list of actions
 */
function filterActions(actions: UndoableAction[]): UndoableAction[] {
  return actions.filter((undo) => {
    if(undo instanceof UndoableActionList) {
      return !undo.isEmpty()
    }
    return true
  })
}

/**
 * Transforms a root reducer into one that supports undo/redo actions
 *
 * @param rootReducer the root reducer to extend
 * @returns the root reducer extended with undo capability
 */
export function undoable(
  rootReducer: (root: RootState, action?: Action) => RootState,
) {
  return (
    state: RootStateWithUndoRedo,
    action: Action,
  ) => {
    // initial state (and RESET)
    if(state === undefined) {
      return {
        ...rootReducer(state, action),
        undo: initialState(),
      }
    }

    // split state
    const {
      undo = initialState(),
      ...rootState
    } = state

    // undo action -----------------------------------------------------------
    if(undoAction.match(action)) {
      const domain = action.payload
      const {
        past,
        future,
      } = state.undo[domain] ?? initialPerDomainState()
      // can we undo at all?
      if(past.length === 0) {
        // we cannot => return state directly
        return {
          ...state,
          undo: {
            ...state.undo,
            [domain]: { past, future },
          },
        }
      }
      const lastAction = past[past.length - 1]
      const farPast = past.slice(0, -1)
      // apply undo action
      try {
        const [newRootState, redoAction] = lastAction.applyTo(rootState)
        return {
          ...newRootState,
          undo: {
            ...state.undo,
            [domain]: {
              past: farPast,
              future: [redoAction, ...future],
            },
          },
        }
      } catch(err) {
        console.warn('An undo action triggered an error')
        console.error(err)
      }
      // something bad happened
      // let's return the state as-is
      return {
        ...state,
        undo: {
          ...state.undo,
          [domain]: { past, future },
        },
      }
    }

    // redo action -----------------------------------------------------------
    if(redoAction.match(action)) {
      const domain = action.payload
      const {
        past,
        future,
      } = state.undo[domain] ?? initialPerDomainState()
      // can we redo at all?
      if(future.length === 0) {
        // we cannot => return state directly
        return {
          ...state,
          undo: {
            ...state.undo,
            [domain]: { past, future },
          },
        }
      }
      const [nextAction, ...farFuture] = future
      // apply redo action
      try {
        const [newRootState, undoAction] = nextAction.applyTo(rootState)
        return {
          ...newRootState,
          undo: {
            ...state.undo,
            [domain]: {
              past: [...past, undoAction],
              future: farFuture,
            },
          },
        }
      } catch(err) {
        console.warn('A redo action triggered an error')
        console.error(err)
      }
      // something bad happened
      // let's return the state as-is
      return {
        ...state,
        undo: {
          ...state.undo,
          [domain]: { past, future },
        },
      }
    }

    // start-concat ----------------------------------------------------------
    if(startUndoConcat.match(action)) {
      const domain = action.payload
      const { past } = undo[domain]
      return {
        ...rootState,
        undo: {
          ...state.undo,
          [domain]: {
            past: [
              ...filterActions(past),
              new UndoableConcatAction(domain),
            ],
            future: [],
            concat: true,
          },
        },
      }
    }

    // end-concat ------------------------------------------------------------
    if(endUndoConcat.match(action)) {
      const domain = action.payload
      const { past, concat } = undo[domain]
      // must have been concatenating for this to happen
      if(!concat) {
        console.warn('Ending undo concat without starting', domain)
        return state
      }
      // check if the most recent past action is a concat one
      if(past.length) {
        const farPast = past.slice(0, -1)
        const lastAction = past[past.length - 1]
        if(lastAction instanceof UndoableConcatAction) {
          // it is a concat action => end it
          return {
            ...rootState,
            undo: {
              ...state.undo,
              [domain]: {
                past: filterActions([...farPast, lastAction.end()]),
                future: [],
              },
            },
          }
        }
      }
      // in all other cases, we just unset the concat field
      return {
        ...rootState,
        undo: {
          ...state.undo,
          [domain]: {
            past: filterActions(past),
            future: [],
          },
        },
      }
    }

    // adding an undoable action ---------------------------------------------
    if(addUndoableAction.match(action)) {
      const undoableAction = action.payload
      const { domain } = undoableAction
      // note: the future gets removed by triggering this action
      const { past, concat = false } = undo[domain] ?? initialPerDomainState()
      try {
        const [newRootState, undoAction] = undoableAction.applyTo(rootState)
        return {
          ...newRootState,
          undo: {
            ...state.undo,
            [domain]: {
              // merge undo with past if possible, else just concatenate
              past: concatPast(past, undoAction, concat),
              future: [], // cleared from applying this action
              concat,
            },
          },
        }
      } catch(err) {
        console.warn('An undoable action triggered an error')
        console.error(err)
      }
      return {
        ...state,
        undo: {
          ...state.undo,
          [domain]: { past, future: [] },
          concat,
        },
      }
    }

    // delegate to root reducer ----------------------------------------------
    return {
      ...rootReducer(rootState, action),
      undo,
    }
  }
}
