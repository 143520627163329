import { RFV2 } from 'src/common/math'
import { ReadonlyUint32Array, ReadonlyUint8Array } from 'src/common/types'
import {
  CanvasImage, getPixel, getPixelIndex, PixelIndex, ReadonlyCanvasImage, setPixel,
} from './image'

/**
 * General pixel buffer.
 *
 * The channels are fixed upon creation,
 * whereas the indices and pixels storages are pre-allocated
 * with some initial capacity before being filled.
 *
 * The underlying `indices` and `pixels` may change as the length increases.
 *
 * @see {@link ReadonlyPixelBuffer}
 */
export interface PixelBuffer<NC extends number = number> {
  readonly channels: NC
  length: number
  indices: Uint32Array
  pixels: Uint8Array
}

/**
 * Read-only version of a pixel buffer
 *
 * @see {@link PixelBuffer}
 */
export interface ReadonlyPixelBuffer<NC extends number = number> {
  readonly channels: NC
  readonly length: number
  readonly indices: ReadonlyUint32Array
  readonly pixels: ReadonlyUint8Array
}

/**
 * Allocate an empty pixel buffer (`length=0`)
 * with a fixed number of channels and an initial data capacity.
 *
 * The capacity should be ideally chosen to be the smallest one
 * that can fit all the necessary data.
 * Note that if the length of the buffer ends up going above it,
 * the underlying data is automatically reallocated to afford more data.
 *
 * @param channels the number of channels of the corresponding image
 * @param capacity the initial capacity
 * @returns
 */
export function allocatePixelBuffer<NC extends number>(
  channels: NC,
  capacity = 1,
): PixelBuffer<NC> {
  return {
    channels,
    length: 0,
    indices: new Uint32Array(capacity),
    pixels: new Uint8Array(capacity * channels),
  }
}

/**
 * Create a deep copy of a pixel buffer
 *
 * @param minimal whether to copy only the necessary data (`false` by default)
 */
export function copyPixelBuffer<NC extends number>(
  {
    channels, length, indices, pixels,
  }: ReadonlyPixelBuffer<NC>,
  minimal = false,
): PixelBuffer<NC> {
  return minimal ? {
    channels,
    length,
    indices: indices.slice(0, length),
    pixels: pixels.slice(0, length * channels),
  } : {
    channels,
    length,
    indices: indices.slice(),
    pixels: pixels.slice(),
  }
}

/**
 * Check whether a pixel buffer is full
 */
function isPixelBufferFull<NC extends number>(
  { length, indices }: ReadonlyPixelBuffer<NC>,
) {
  return length === indices.length
}

/**
 * Append an instance of pixel data at the end of a pixel buffer.
 *
 * The size of the pixel data `px` must match the number of `channels`
 * of the pixel buffer.
 *
 * If the buffer is full, the underlying storage is automatically
 * reallocated (by expanding it by 50%) to afford the new pixel data.
 *
 * @param buffer the pixel buffer to add to
 * @param idx the pixel index
 * @param px the pixel data
 */
export function pushPixel<NC extends number>(
  buffer: PixelBuffer<NC>,
  idx: number,
  px: ArrayLike<number>,
): void {
  console.assert(
    px.length === buffer.channels,
    'Pixel cardinality does not match buffer channels',
  )
  if(isPixelBufferFull(buffer)) {
    // Re-allocate, increase by 50% for now
    const capacity = Math.max(
      Math.floor(1.5 * buffer.indices.length),
      buffer.length + 1,
    )
    const indices = new Uint32Array(capacity)
    const pixels = new Uint8Array(buffer.channels * capacity)
    indices.set(buffer.indices)
    pixels.set(buffer.pixels)
    buffer.indices = indices
    buffer.pixels = pixels
  }

  // push new pixel at the end
  buffer.indices[buffer.length] = idx
  buffer.pixels.set(px, buffer.channels * buffer.length)
  buffer.length += 1
}

// local out-of-bound index checking
function checkIndex(idx: number, length: number) {
  console.assert(
    idx >= 0 && idx < length,
    'Pixel buffer entry index ouf of bounds',
    idx,
    length,
  )
}

/**
 * Extract a pixel from a pixel buffer
 *
 * @param buffer the pixel buffer to read from
 * @param idx the index (`0 <= idx < buffer.length`)
 * @param asRef whether to return a subarray reference or copy (default)
 */
export function readPixel<NC extends number>(
  { channels, length, pixels }: ReadonlyPixelBuffer<NC>,
  idx: number,
  asRef = false,
): ReadonlyUint8Array {
  checkIndex(idx, length)
  const start = idx * channels
  const end = start + channels
  return asRef ? pixels.subarray(start, end) : pixels.slice(start, end)
}

/**
 * Extract an index from a pixel buffer
 *
 * @param buffer the pixel buffer to read from
 * @param idx the index (`0 <= idx < buffer.length`)
 */
export function readIndex<NC extends number>(
  { indices, length }: ReadonlyPixelBuffer<NC>,
  idx: number,
) {
  checkIndex(idx, length)
  return indices[idx]
}

/**
 * Extract a pixel entry from a pixel buffer
 *
 * @param buffer the pixel buffer to read from
 * @param idx the index (`0 <= idx < buffer.length`)
 * @param asRef whether to return a subarray ref for the pixel
 */
export function readEntry<NC extends number>(
  buffer: ReadonlyPixelBuffer<NC>,
  idx: number,
  asRef = false,
) {
  return [
    buffer.indices[idx],
    readPixel(buffer, idx, asRef),
  ] as const
}

/**
 * Returns a generator that goes over all pixels in a pixel buffer
 *
 * @param buffer the pixel buffer to traverse
 * @param asRef whether pixels should be returned as subarry references or copy
 */
export function* pixelEntries<NC extends number>(
  buffer: ReadonlyPixelBuffer<NC>,
  asRef = false,
) {
  for(let i = 0; i < buffer.length; ++i) {
    yield readEntry(buffer, i, asRef)
  }
}

/**
 * Update the pixel data of an entry
 *
 * @param buffer the pixel buffer to update
 * @param idx the index `0 <= idx < buffer.length`
 * @param px the updated pixel data
 */
export function writePixel<NC extends number>(
  { channels, length, pixels }: PixelBuffer<NC>,
  idx: number,
  px: ArrayLike<number>,
) {
  checkIndex(idx, length)
  console.assert(
    px.length === channels,
    'Pixel cardinality does not match buffer channels',
  )
  pixels.set(px, idx * channels)
}

/**
 * Update the pixel index of an entry
 *
 * @param buffer the pixel buffer to update
 * @param idx the index `0 <= idx < buffer.length`
 * @param newIndex the updated index data
 */
export function writeIndex<NC extends number>(
  { indices, length }: PixelBuffer<NC>,
  idx: number,
  newIndex: number,
) {
  checkIndex(idx, length)
  indices[idx] = newIndex
}

/**
 * Update a pixel entry (index + pixel data)
 *
 * @param buffer the pixel buffer to update
 * @param idx the index `0 <= idx < buffer.length`
 * @param entry the updated entry data
 */
export function writeEntry<NC extends number>(
  buffer: PixelBuffer<NC>,
  idx: number,
  [i, px]: readonly [number, ArrayLike<number>],
) {
  buffer.indices[idx] = i
  writePixel(buffer, idx, px)
}

/**
 * Merge two pixel buffers into a new pixel buffer
 *
 * @param bufA the first pixel buffer
 * @param bufB the second pixel buffer
 * @returns the new pixel buffer containing the data of both arguments
 */
export function mergePixelBuffers<NC extends number>(
  bufA: PixelBuffer<NC>,
  bufB: PixelBuffer<NC>,
): PixelBuffer<NC> {
  if(bufA.channels !== bufB.channels) {
    throw `Cannot merge pixel buffers with different channels: ${bufA.channels} and ${bufB.channels}`
  }
  // allocate enough space to hold both buffers
  const buffer = allocatePixelBuffer(bufA.channels, bufA.length + bufB.length)
  // transfer the indices (A first, then B behind)
  buffer.indices.set(
    bufA.indices.subarray(0, bufA.length),
  )
  buffer.indices.set(
    bufB.indices.subarray(0, bufB.length),
    bufA.length, // after indices of bufA
  )
  // transfer the pixels (A first, then B behind)
  buffer.pixels.set(
    bufA.pixels.subarray(0, bufA.channels * bufA.length),
  )
  buffer.pixels.set(
    bufB.pixels.subarray(0, bufB.channels * bufB.length),
    bufA.channels * bufA.length,
  )
  // set full length
  buffer.length = bufA.length + bufB.length
  return buffer
}

/**
 * Reverse a pixel buffer in place
 *
 * @param buffer the pixel buffer to reverse
 */
export function reversePixelBuffer<NC extends number>(
  buffer: PixelBuffer<NC>,
) {
  const { length } = buffer
  for(let i = 0, n = Math.floor(length / 2); i < n; ++i) {
    const frnt = readEntry(buffer, i, false)
    const rear = readEntry(buffer, length - 1 - i, false)
    writeEntry(buffer, i, rear)
    writeEntry(buffer, length - 1 - i, frnt)
  }
  return buffer
}

/**
 * Pack a pixel buffer to have minimal data size
 *
 * If the pixel buffer is full, then it is returned as is.
 * Else, a new buffer is generated with the minimal required size.
 *
 * @see {@link isPixelBufferFull}
 */
export function packPixelBuffer<NC extends number>(
  buffer: PixelBuffer<NC>,
): PixelBuffer<NC>
export function packPixelBuffer<NC extends number>(
  buffer: ReadonlyPixelBuffer<NC>,
): ReadonlyPixelBuffer<NC>
export function packPixelBuffer<NC extends number>(
  buffer: PixelBuffer<NC> | ReadonlyPixelBuffer<NC>,
): PixelBuffer<NC> | ReadonlyPixelBuffer<NC> {
  if(isPixelBufferFull(buffer)) {
    return buffer
  }
  // otherwise create a slim version of the buffer
  const {
    channels, length, indices, pixels,
  } = buffer
  return {
    channels,
    length,
    indices: indices.slice(0, length),
    pixels: pixels.slice(0, channels * length),
  }
}

/**
 * Extract a sequence of pixels from an image as a pixel buffer
 *
 * @param srcImage the source image to extract pixels from
 * @param index the sequence of pixel indices
 * @param fill an optional filling value to use instead of the image pixels
 * @returns a pixel buffer corresponding to the pixel index
 */
export function extractPixelBuffer<NC extends number>(
  srcImage: ReadonlyCanvasImage<NC>,
  index: ArrayLike<number>,
  fill: ReadonlyUint8Array = null,
): PixelBuffer<NC> {
  // check the cardinality of the fill
  console.assert(
    !fill || fill.length === srcImage.channels,
    'The pixel fill size does not match the channels of the source image',
  )
  // allocate the pixel buffer
  const buffer = allocatePixelBuffer(srcImage.channels, index.length)
  // fill the pixel buffer
  for(let i = 0; i < index.length; ++i) {
    const idx = index[i]
    pushPixel(buffer, idx, fill ?? getPixel(srcImage, idx))
  }
  // optionally compact the buffer
  return buffer
}

/**
 * Extract a single pixel from an image as a pixel buffer
 *
 * @param srcImage the source image to extract the pixel from
 * @param index the pixel location `[x,y]` or its numeric index `channels * (width * y + x)`
 * @param fill an optional filling value to use instead of the image pixel
 * @returns a pixel buffer holding the single pixel
 */
export function extractPixelAsPixelBuffer<NC extends number>(
  srcImage: ReadonlyCanvasImage<NC>,
  index: PixelIndex,
  fill?: ReadonlyUint8Array,
): PixelBuffer<NC> {
  if(typeof index !== 'number') {
    index = getPixelIndex(srcImage, index)
  }
  return extractPixelBuffer(
    srcImage,
    [index],
    fill ?? getPixel(srcImage, index),
  )
}

/**
 * Apply a pixel buffer to an image
 */
export function copyPixelBufferToImage<NC extends number>(
  srcBuffer: ReadonlyPixelBuffer<NC>,
  trgImage: CanvasImage<NC>,
) {
  for(const [idx, px] of pixelEntries(srcBuffer, true)) {
    setPixel(trgImage, idx, px)
  }
}

/**
 * Apply a pixel buffer to an image while returning
 * a copy of the region that was modified, before modification.
 *
 * @param img the source image to be modified
 * @param buffer the target pixel buffer to apply to the image
 * @returns a copy of the image region at to the rect buffer before application
 * @see {@link extractRectBuffer}
 */
export function replacePixelBuffer<NC extends number>(
  img: CanvasImage<NC>,
  buffer: ReadonlyPixelBuffer<NC>,
): PixelBuffer<NC> {
  // get old data as pixel buffer
  const prev = extractPixelBuffer(img, buffer.indices)
  // apply new buffer onto image
  copyPixelBufferToImage(buffer, img)
  return prev
}

/**
 * Compute the pixel indices for a line segment in an image.
 *
 * The indices include the image channel as multipler.
 * Thus, such line index must be adapted when applied to an image
 * with a different number of pixel channels.
 * This can be done with the following:
 * ```
 * const srcIndex = computeLineIndex(srcImage, p1, p2)
 * const trgIndex = srcIndex.map(idx => (idx / srcImage.channels) * trgImage.channels)
 * ```
 *
 * @param srcImage the source image
 * @param p1 the first endpoint of the line segment
 * @param p2 the last endpoint of the line segment
 * @returns the sequence of pixel indices from start to end (both included)
 * @see {@link https://en.wikipedia.org/wiki/Bresenham%27s_line_algorithm}
 */
export function computeLineIndex<NC extends number>(
  srcImage: ReadonlyCanvasImage<NC>,
  [x0, y0]: RFV2,
  [x1, y1]: RFV2,
): number[] {
  // round the pixel locations to be safe
  x0 = Math.floor(x0)
  y0 = Math.floor(y0)
  x1 = Math.floor(x1)
  y1 = Math.floor(y1)

  // constant direction information
  const dx = Math.abs(x1 - x0)
  const sx = x0 < x1 ? 1 : -1
  const dy = -Math.abs(y1 - y0)
  const sy = y0 < y1 ? 1 : -1
  let err = dx + dy

  // marching steps of Bresenham's algorithm
  const indices = []
  while(true) {
    indices.push(getPixelIndex(srcImage, [x0, y0]))

    if(x0 === x1 && y0 === y1) { break }

    const e2 = 2 * err
    if(e2 >= dy) {
      if(x0 === x1) { break }
      err += dy
      x0 += sx
    }
    if(e2 <= dx) {
      if(y0 === y1) { break }
      err += dx
      y0 += sy
    }
  }
  return indices
}

/**
 * Copy a line of pixels from an image
 *
 * @param srcImage the source image to copy a line through
 * @param p1 the first endpoint of the line segment
 * @param p2 the last endpoint of the line segment
 * @param fill an optional filling value to use instead of the image pixels
 * @returns a pixel buffer corresponding to the input line
 * @see {@link extractPixelBuffer}
 * @see {@link computeLineIndex}
 */
export function extractLineToPixelBuffer<NC extends number>(
  srcImage: ReadonlyCanvasImage<NC>,
  p1: RFV2,
  p2: RFV2,
  fill: ReadonlyUint8Array = null,
): PixelBuffer<NC> {
  // compute the pixel index for the line
  const index = computeLineIndex(srcImage, p1, p2)
  // generate corresponding pixel buffer
  return extractPixelBuffer(srcImage, index, fill)
}

/**
 * Apply a line onto an image while returning
 * a copy of the region that was modified, before modification.
 *
 * This method is similar to extracting a line buffer
 * with a given fill and then replacing it in the image:
 * ```
 * const buffer = extractLineToPixelBuffer(img, p1, p2, fill)
 * const prev = replacePixelBuffer(img, buffer)
 * ```
 * except it does so without allocating the intermediate `buffer` object.
 *
 * @param img the source image to be modified
 * @param p1 the first endpoint of the line segment
 * @param p2 the last endpoint of the line segment
 * @param fill the pixel to fill along the line segment
 * @returns a copy of the image region at to the line segment before application
 * @see {@link extractLineToPixelBuffer}
 * @see {@link replacePixelBuffer}
 * @see {@link setPixel}
 */
export function replaceLine<NC extends number>(
  img: CanvasImage<NC>,
  p1: RFV2,
  p2: RFV2,
  fill: ReadonlyUint8Array,
): PixelBuffer<NC> {
  // get old data as pixel buffer
  const prev = extractLineToPixelBuffer(img, p1, p2)
  // apply fill value to line on image
  for(let i = 0; i < prev.length; ++i) {
    const idx = prev.indices[i]
    setPixel(img, idx, fill)
  }
  return prev
}
