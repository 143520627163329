import { ActionHandler } from './common'
import Select from './select'
import Translate from './translate'
import Pixel from './pixel'
import Line from './line'
import Rect from './rect'

/**
 * The default action tools
 */
export enum ActionTool {
  TRANSLATE = 0,
  SELECT,
  PIXEL,
  LINE,
  RECT,
}

/**
 * Create an action handler instance for a given action tool
 */
export function createHandler(tool: ActionTool) {
  switch(tool) {
  case ActionTool.TRANSLATE: return new Translate()
  case ActionTool.SELECT: return new Select()
  case ActionTool.PIXEL: return new Pixel()
  case ActionTool.LINE: return new Line()
  case ActionTool.RECT: return new Rect()
    // XXX implement the remaining tools
  default:
    console.warn(`Tool ${ActionTool[tool]} not implemented`)
    return new Select()
  }
}

/**
 * Create a default action handler
 *
 * @see {@link ActionTool.SELECT}
 * @see {@link createHandler}
 */
export function defaultHandler() {
  return createHandler(ActionTool.TRANSLATE)
}

/**
 * Attempt to figure out the action tool corresponding to an action handler
 *
 * @param action the action handler
 * @param def a default tool in case the handler does not match any default tool
 * @returns the matched tool or the default input if none found
 */
export function getTool(action: ActionHandler, def?: ActionTool) {
  if(action instanceof Select) {
    return ActionTool.SELECT
  } if(action instanceof Translate) {
    return ActionTool.TRANSLATE
  } if(action instanceof Pixel) {
    return ActionTool.PIXEL
  } if(action instanceof Line) {
    return ActionTool.LINE
  } if(action instanceof Rect) {
    return ActionTool.RECT
  }
  return def
}

// exports from index file of exposed children

export type {
  ActionContext,
  ActionContextFunction,
  ActionHandler,
  KeyOptions,
} from './common'

export {
  centerHome,
  getCenterZoom,
  jumpTo,
  updateThumbnails,
  zoomTo,
} from './util'

export {
  Select, Translate, Pixel, Line, Rect,
}

export {
  texturePixelUpdate,
  UndoablePixelAction,
} from './line'

export {
  textureRectUpdate,
  UndoableRectAction,
} from './rect'

export { UndoablePasteAction } from './select'

export { UndoableSnapshotAction } from './snapshot'
