import { useState } from 'react'
import classnames from 'classnames'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import Palette from 'src/data/time-needle/palette'

// XXX should we support a non-default one?
const palette = Palette.default()

// XXX should we change to YarnList (since it's just for display now)

export default function YarnSelector() {
  const [open, setOpen] = useState(true)
  const dispatch = useAppDispatch()
  return (
    <div
      className={classnames('yarnbox', { open })}
      title="Yarn color codes. Use the options column to edit the carrier data."
    >
      <div
        className="title"
      >
        Yarn
      </div>
      {palette.rgbString.map((rgb, i) => (
        <div
          key={`feeder-${i}`}
          style={{ backgroundColor: rgb }}
          // onDoubleClick={(event) => {
          //   const { left, top } = (event.target as Element).getBoundingClientRect()
          //   dispatch(configColor([i, [left + 40, top + 20]]))
          // }}
          className={classnames('yarnselector', { selected: false /* i === activeYarn - 1 */ })}
        >
          {i + 1}
        </div>
      ))}
    </div>
  )
}
