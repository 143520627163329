import { addRowsAboveTop, deleteRows } from 'src/data/time-needle/topology'
import {
  otherDir,
  parseUserOptions,
  SelectorLike,
  TimeNeedleTransformer,
  UserDirection,
} from './common'

export enum BranchSide {
  LEFT = 0,
  RIGHT = 1,
  BOTH = 2,
}

export type SingleBranchSide = Exclude<BranchSide, BranchSide.BOTH>

export enum ParallelShapingSide {
  INWARD = 0,
  OUTWARD = 1,
  BOTH = 2,
}

export type SingleParallelShapingSide = Exclude<ParallelShapingSide, ParallelShapingSide.BOTH>

export enum StartSide {
  NONE = 0,
  LEFT,
  RIGHT,
  INSIDE,
  OUTSIDE,
}

export interface InterleavingSplit {
  width: number
  height: number
  left: number
  bottom: number
  right: number
  top: number
  lRange: [number, number]
  rRange: [number, number]
}

type ColumnCluster<S extends SelectorLike = SelectorLike> = S[]

export function clusterColumns(s: SelectorLike) {
  const cols = s.splitByColumn()
  if(!cols.length) { return [] as const }
  const clusters = [[]] as ColumnCluster[]
  for(let i = 0; i < cols.length; ++i) {
    const lastCluster = clusters[clusters.length - 1]
    const colIsEmpty = cols[i].every((c) => c.isMiss())
    if(colIsEmpty) {
      if(lastCluster.length) {
        // introduce new empty cluster
        clusters.push([])
      }
      // else, do nothing since the last cluster is still empty
    } else {
      lastCluster.push(cols[i])
    }
  }
  if(clusters[clusters.length - 1].length === 0) {
    // remove potential empty last cluster
    clusters.pop()
  }
  console.assert(
    clusters.every((clu) => clu.length > 0),
    'Empty cluster remaining',
  )
  return clusters
}

export function split(s: SelectorLike): InterleavingSplit {
  const {
    left, bottom, right, top, width, height,
  } = s.getExtents()
  // find splitting column
  let midX: number
  const clusters = clusterColumns(s)
  if(clusters.length === 2) {
    const [leftCLU, rightCLU] = clusters
    const leftCol = leftCLU[leftCLU.length - 1].first().asCell().column
    const rightCol = rightCLU[0].first().asCell().column
    midX = Math.ceil((leftCol + rightCol) / 2)
    console.assert(midX > leftCol, 'Splitting is invalid', midX, leftCol)
  } else {
    const halfWidth = Math.floor(width / 2)
    midX = left + halfWidth
  }
  return {
    width,
    height,
    left,
    bottom,
    right,
    top,
    lRange: [left, midX],
    rRange: [midX, right],
  }
}

export function generateInterleaving(
  s: SelectorLike,
  sides: UserDirection[],
  inPlace = false,
): TimeNeedleTransformer {
  // split sides
  const {
    bottom, height, lRange, rRange,
  } = split(s)

  // generate source information
  const trgData = new Array<{ side: UserDirection, src: [number, number] }>()
  let leftSrc = 0
  let rightSrc = 0
  while(leftSrc < height && rightSrc < height) {
    for(const side of sides) {
      const moreLeft = leftSrc < height
      const moreRight = rightSrc < height
      if(moreLeft && (side === 0 || !moreRight)) {
        trgData.push({ side: 0, src: [bottom + leftSrc++, -1] })
      } else if(moreRight && (side === 1 || !moreLeft)) {
        trgData.push({ side: 1, src: [-1, bottom + rightSrc++] })
      } else if(moreLeft || moreRight) {
        trgData.push({
          side: moreLeft && moreRight ? 2 : moreRight ? 1 : 0,
          src: [
            moreLeft ? bottom + leftSrc++ : -1,
            moreRight ? bottom + rightSrc++ : -1,
          ],
        })
      } else {
        break
      }
    }
  }

  // add necessary rows above
  const numNewRows = Math.max(0, trgData.length - height)
  let t: TimeNeedleTransformer
  if(!inPlace && numNewRows > 0) {
    [t] = addRowsAboveTop(s, numNewRows)
  } else {
    t = TimeNeedleTransformer.fromSelector(s)
  }

  // generate interleaving
  for(let tr = trgData.length - 1; tr >= 0; --tr) {
    const { side, src: [leftSrc, rightSrc] } = trgData[tr]
    const trgRowIdx = bottom + tr
    const trgRow = t.fullCourse(bottom + tr)
    // set options from source
    // unless it's the same row
    const srcRowIdx = leftSrc !== -1 ? leftSrc : rightSrc
    if(trgRowIdx !== srcRowIdx) {
      const opts = s.rowOptions(srcRowIdx)
      trgRow.options(opts)
    }
    // copy values from source row(s)
    // and clear other side if necessary
    switch(side) {
    case 0: {
      const srcRow = s.fullCourse(leftSrc)
      trgRow.wales(lRange).copy(srcRow.wales(lRange))
      trgRow.wales(rRange).miss()
    } break
    case 1: {
      const srcRow = s.fullCourse(rightSrc)
      trgRow.wales(rRange).copy(srcRow.wales(rRange))
      trgRow.wales(lRange).miss()
    } break
    case 2: {
      const srcLeftRow = s.fullCourse(leftSrc)
      trgRow.wales(lRange).copy(srcLeftRow.wales(lRange))
      const srcRightRow = s.fullCourse(rightSrc)
      trgRow.wales(rRange).copy(srcRightRow.wales(rRange))
    }
    default:
      throw `Invalid side ${side}, must be 0, 1 or 2`
    } // end switch side
  } // endfor 0 <= tr < #trgData reverse

  return t.extendUp(numNewRows)
}

export function removeInterleaving(t: TimeNeedleTransformer): TimeNeedleTransformer {
  // split sides
  const {
    left, bottom, right, top,
  } = t.getExtents()
  const { lRange, rRange } = split(t)
  const rows = t.splitByRow()

  // generate source information
  let lastLeftSlotRow = -1
  let lastRightSlotRow = -1
  let leftSlot = rows[0].empty()
  let rightSlot = rows[0].empty()
  for(const [i, row] of rows.entries()) {
    const rowNum = bottom + i
    const rowLeft = row.wales(lRange)
    const rowRight = row.wales(rRange)
    // check if something non-empty
    if(rowLeft.some((c) => !c.isMiss())) {
      if(!leftSlot.isEmpty()) {
        // copy to slot
        leftSlot.copy(rowLeft)
        leftSlot = leftSlot.up()
        ++lastLeftSlotRow
      }
      // else we don't have any empty slot yet
    } else if(leftSlot.isEmpty()) {
      // first empty slot on the left
      leftSlot = rowLeft
      lastLeftSlotRow = rowNum
    }
    // same on the right side
    if(rowRight.some((c) => !c.isMiss())) {
      if(!rightSlot.isEmpty()) {
        // copy to slot
        rightSlot.copy(rowRight)
        rightSlot = rightSlot.up()
        ++lastRightSlotRow
      }
      // else we don't have any empty slot yet
    } else if(rightSlot.isEmpty()) {
      // first empty slot on the right
      rightSlot = rowRight
      lastRightSlotRow = rowNum
    }
  }

  // remove rows from last slot row available
  // to the end of the selection
  let lastSlotRow = Math.max(lastLeftSlotRow, lastRightSlotRow)
  if(lastSlotRow < top) {
    t = deleteRows(t.fullCourses([lastSlotRow, top]))
    t = t.reselect([bottom, lastSlotRow], [left, right])
  }

  return t
}

export type Side = 0 | 1

function isKnitRow(s: SelectorLike): boolean {
  return s.some((c) => c.isKnit() || c.isTuck() || c.isKnitTuck() || c.isTuckKnit() || c.isSplit())
}

function isSingleTuckRow(s: SelectorLike): boolean {
  return (
    s.count((c) => c.isTuck()) === 1 &&
    s.count((c) => c.isKnit() || c.isKnitTuck() || c.isTuckKnit() || c.isSplit()) === 0
  )
}

function isSingleKnitOrSplitRow(s: SelectorLike): boolean {
  return (
    s.count((c) => c.isTuck() || c.isKnitTuck() || c.isTuckKnit()) === 0 &&
    s.count((c) => c.isKnit() || c.isSplit()) === 1
  )
}

function isEmptyRow(s: SelectorLike): boolean {
  return s.every((c) => c.isMiss())
}

function isXferRow(s: SelectorLike): boolean {
  return s.some((c) => c.isTransfer())
}

function otherBranch(branch: SingleBranchSide): SingleBranchSide {
  return (1 - branch) as SingleBranchSide
}

export interface InterleavingOptions {
  startBranch: SingleBranchSide
  startSide: StartSide
  maxBranchPull?: number
  switchSide?: StartSide
  increaseMode?: boolean
  applyDirs?: boolean
  skipEmpty?: boolean
}

export function parseInterleavingOptions(input: string): InterleavingOptions | string {
  const opts: InterleavingOptions = {
    startBranch: null,
    startSide: StartSide.NONE,
    maxBranchPull: 2,
    increaseMode: false,
    switchSide: StartSide.NONE,
    applyDirs: false,
    skipEmpty: false,
  }
  // Interleave: from BRNCH START [max MAXPULL] [increasing] [directed] [switch START] [skip]
  const error = parseUserOptions(input, {
    increasing: () => { opts.increaseMode = true },
    dirs: () => { opts.applyDirs = true },
    skip: () => { opts.skipEmpty = true },
  }, {
    // contexts
    // - starting branch + side
    from: (token: string) => {
      if(opts.startBranch === null) {
        if(['left', 'right'].includes(token)) {
          opts.startBranch = BranchSide[token.toUpperCase()]
        } else {
          return `Invalid branch token ${token}`
        }
      } else if(opts.startSide === StartSide.NONE) {
        if(['left', 'right', 'inside', 'outside'].includes(token)) {
          opts.startSide = StartSide[token.toUpperCase()]
        } else {
          return `Invalid side token ${token}`
        }
      } else {
        return 'Start argument only accept two values: branch and side'
      }
    },
    // - maximum (knit) pull
    max: (token: string) => {
      opts.maxBranchPull = parseInt(token, 10)
    },
    // - switching side
    switch: (token: string) => {
      if(opts.switchSide === StartSide.NONE) {
        if(['left', 'right', 'inside', 'outside'].includes(token)) {
          opts.switchSide = StartSide[token.toUpperCase()]
        } else {
          return `Invalid switch token ${token}`
        }
      } else {
        return 'Switch argument only accept a single value from: left, right, inside and outside'
      }
    },
  }, 'from')
  if(error) {
    return error
  }

  // validate start
  if(opts.startBranch === null) {
    return 'Missing start branch (left or right)'
  }
  if(opts.startSide === StartSide.NONE) {
    return 'Missing start side (inside, outside, left or right)'
  }

  return opts
}

export function matchesStartSide([side, branch]: [SingleBranchSide, SingleBranchSide], target: StartSide) {
  const isLeftSide = side === BranchSide.LEFT
  const isLeftBranch = branch === BranchSide.LEFT
  switch(target) {
  case StartSide.LEFT: return isLeftSide
  case StartSide.RIGHT: return !isLeftSide
  case StartSide.INSIDE: return isLeftSide === !isLeftBranch
  case StartSide.OUTSIDE: return isLeftSide === isLeftBranch
  default: return false
  }
}

export function findInterleaving(
  sideRows: [SelectorLike, SelectorLike],
  {
    startBranch,
    startSide,
    maxBranchPull = 2,
    switchSide = StartSide.NONE,
    skipEmpty = false,
    increaseMode = false,
  }: InterleavingOptions,
  numSteps: number,
  minSteps = numSteps,
): [Side[], UserDirection[]] {
  const interSides = new Array<Side>()
  const interDirs = new Array<UserDirection>()
  let dirs = [0, 0] as [0|1, 0|1]
  switch(startSide) {
  case StartSide.LEFT: dirs = [0, 0]; break
  case StartSide.RIGHT: dirs = [1, 1]; break
  case StartSide.INSIDE: dirs = [1, 0]; break
  case StartSide.OUTSIDE: dirs = [0, 1]; break
  }
  let knitPull = 0
  for(let i = 0, branch = startBranch; i < numSteps; ++i) {
    const row = sideRows[branch]
    sideRows[branch] = row.up() // update for next steps
    const isKnit = isKnitRow(row)
    // const isXMiss = !isKnit && isExplicitMissRow(row)
    const isEmpty = !isKnit && isEmptyRow(row)
    const nextXfer = isXferRow(sideRows[branch]) // XXX this won't work if the next row is empty and we skip it
    // const prevXfer = isXferRow(row.down())
    if(i === 0) {
      // special start case
      interSides.push(branch)
      if(isKnit) {
        interDirs.push(dirs[branch])
        // knitting => the general solution is to toggle direction
        // except when tucking for short-rows (single-tuck on row)
        // /!\ note: single-knit/split are used for kickback and split increases but they typically
        //     all benefit from being done in the reverse direction (and this leads to proper next direction)
        if(!isSingleTuckRow(row)) {
          dirs[branch] = otherDir(dirs[branch])
          // for increase mode, encountering a single knit/pull
          // corresponds to an increase => reduce pull to allow next end of knit
          if(increaseMode && isSingleKnitOrSplitRow(row)) {
            --knitPull
          } else {
            ++knitPull
          }
        }
        // else no need to increase pull anyway
      } else {
        interDirs.push(2)
      }
    } else if(isKnit) {
      // general knitting case
      interSides.push(branch)
      interDirs.push(dirs[branch])
      // check if single-tuck action or not
      if(!isSingleTuckRow(row)) {
        dirs[branch] = otherDir(dirs[branch])
        ++knitPull

        // check if the switch side matches
        const canSwitch = switchSide === StartSide.NONE || matchesStartSide(
          [dirs[branch] === 0 ? BranchSide.LEFT : BranchSide.RIGHT, branch],
          switchSide,
        )

        // if pull reached max, the next is not a transfer and we can switch
        // then
        if(knitPull >= maxBranchPull && !nextXfer && canSwitch) {
          // switch to other branch
          knitPull = 0
          branch = otherBranch(branch)
        }
      }
    } else if(!skipEmpty && isEmpty && i >= minSteps) {
      // this branch is done
      // => move to other side
      branch = otherBranch(branch)
    } else {
      // xfer row or miss row to keep
      interSides.push(branch)
      interDirs.push(2)
    }
  }

  return [
    interSides,
    interDirs,
  ]
}

export function generateSmartInterleaving(
  s: SelectorLike,
  opts: InterleavingOptions,
): TimeNeedleTransformer {
  const {
    height, bottom, lRange, rRange,
  } = split(s)
  const numSteps = 2 * height
  const [interSides, interDirs] = findInterleaving(
    [
      s.select([bottom, bottom + 1], lRange),
      s.select([bottom, bottom + 1], rRange),
    ],
    opts,
    numSteps,
  )

  // create interleaving
  const t = generateInterleaving(s, interSides)

  // apply interleaving directions
  if(opts.applyDirs) {
    // /!\ generate interleaving (inPlace=false) adds new rows
    let baseRow = t.reselect([bottom, bottom + 1], [0, 1])
    for(const direction of interDirs) {
      baseRow.options({ direction })
      baseRow = baseRow.up()
    }
  }

  return t
}
