/**
 * A viewing mode of a time-needle image
 */
export enum ViewMode {
  STITCH = 0,
  PATTERN,
  // SIZE,
}

/**
 * The list of view modes
 */
export const ViewModes = [
  ViewMode.STITCH,
  ViewMode.PATTERN,
  // ViewMode.SIZE,
] as const
