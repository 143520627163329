import {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react'
import { KEYCODE_ESC } from 'src/common/keyboard'
import {
  useAppSelector,
  useDocumentContextMenu,
  useDocumentKeyDown,
  useDocumentWheel,
  usePointerDownOutside,
} from 'src/hooks'
import { shallowEqual } from 'react-redux'
import { getCoord, isWithinPage } from 'src/common/math'
import SelectMenu from './menu-select'
import SingleMenu from './menu-single'

import 'src/styles/context-menu.scss'

const margin = 20

function alignmentSubMenus(menuEl: HTMLDivElement, viewWidth: number, viewHeight: number) {
  for(const subEl of Array.from(menuEl.querySelectorAll('.context-menu-submenu'))) {
    const itemEl = subEl.parentElement
    const {
      right, bottom, width, height,
    } = itemEl.getBoundingClientRect()
    const { width: menuWidth, height: menuHeight } = subEl.getBoundingClientRect()
    const fromLeft = right + Math.max(width, menuWidth) + margin <= viewWidth
    const fromTop = bottom + Math.max(height, menuHeight) + margin <= viewHeight
    itemEl.classList.toggle('from-left', fromLeft)
    itemEl.classList.toggle('from-top', fromTop)
    itemEl.classList.toggle('from-right', !fromLeft)
    itemEl.classList.toggle('from-bottom', !fromTop)
  }
}

const ContextMenu = function () {
  const menuRef = useRef<HTMLDivElement>()
  const {
    selection: sel,
    transform: { pageDims, viewDims }, M,
  } = useAppSelector(({ canvas: { M, selection, transform }}) => (
    { M, selection, transform }
  ), shallowEqual)
  const selArea = (sel[2] - sel[0]) * (sel[3] - sel[1])
  const [isVisible, setVisible] = useState(false)
  const [{ left, top }, setPosition] = useState({ left: 0, top: 0 }) // left: -70, top: -8
  const [resetPosition, setResetPosition] = useState(false)

  // reset position with reference
  useLayoutEffect(() => {
    const menuEl = menuRef.current
    if(menuEl && resetPosition) {
      const { width, height } = menuEl.getBoundingClientRect()
      const [W, H] = viewDims
      const newPos = {
        left: Math.min(left, W - width),
        top: Math.min(top, H - height),
      }
      setPosition(newPos)
      setResetPosition(false)
      alignmentSubMenus(menuRef.current, W, H)
    }
  }, [menuRef, isVisible, resetPosition, left, top])

  usePointerDownOutside(menuRef, () => {
    setVisible(false)
  }, [], isVisible)

  useDocumentWheel(() => setVisible(false), [], isVisible)
  useDocumentKeyDown(({ keyCode }: KeyboardEvent) => {
    if(keyCode === KEYCODE_ESC) {
      setVisible(false)
    }
  }, [], isVisible)

  useDocumentContextMenu((event: PointerEvent) => {
    event.preventDefault()

    // check that we're computed on the proper
    const onCanvas = (event.target as HTMLElement).id === 'CADToolCanvas'

    // get queried location to filter out when clicking outside of panel
    const { x, y } = event
    const q = getCoord([x, y], M, pageDims)
    if(!onCanvas || !isWithinPage(q, pageDims)) {
      setVisible(false)
      return
    }

    const [W, H] = viewDims

    const newPos = {
      left: menuRef.current ? Math.min(x, W - menuRef.current.clientWidth) : x,
      top: menuRef.current ? Math.min(y, H - menuRef.current.clientHeight) : y,
    }
    // set position and make visible
    setPosition(newPos)
    setVisible(selArea !== 0) // !sel.isEmpty())
    if(!menuRef.current) {
      setResetPosition(true)
    } else {
      alignmentSubMenus(menuRef.current, W, H)
    }
  }, [sel, menuRef, viewDims, pageDims, M])

  // close context menu if the selection becomes empty
  useEffect(() => {
    if(isVisible && selArea === 0) {
      setVisible(false)
    }
  }, [isVisible, sel])

  // mechanism for hiding the context menu
  // to be passed to the children for their local actions
  const hide = () => setVisible(false)
  const single = <SingleMenu />
  const select = <SelectMenu />
  return isVisible ? (
    <div
      className="context-menu"
      style={{ left, top: top - 65 }}
      ref={menuRef}
      onClick={(event) => {
        if(!event.isPropagationStopped() && event.target !== menuRef.current) {
          hide()
        }
      }}
    >
      { selArea === 1 ? single : select}
    </div>
  ) : null
}
export default ContextMenu
