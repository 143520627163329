import classnames from 'classnames'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { setActionHandler } from 'src/editor/time-needle/slice'
import { ActionTool, getTool } from 'src/editor/time-needle/action'
import { ViewMode } from 'src/data/time-needle/view-mode'
import ImageUpload from './image-upload'

import 'src/styles/toolbox.scss'

const ToolClass = {
  [ActionTool.SELECT]: 'selection',
  [ActionTool.TRANSLATE]: 'move',
  [ActionTool.LINE]: 'line',
  [ActionTool.PIXEL]: 'pencil',
  [ActionTool.RECT]: 'rectangle',
}
const ToolText = {
  [ActionTool.SELECT]: 'Select',
  [ActionTool.TRANSLATE]: 'Move',
  [ActionTool.LINE]: 'Line',
  [ActionTool.PIXEL]: 'Pencil',
  [ActionTool.RECT]: 'Rectangle',
}

interface ToolButtonProps {
  tool: ActionTool
}
function ToolButton({ tool }: ToolButtonProps) {
  const dispatch = useAppDispatch()
  const selectTool = () => {
    dispatch(setActionHandler(tool))
  }
  const action = useAppSelector((state) => state.canvas.action)
  const current = getTool(action)

  return (
    <div
      role="button"
      tabIndex={0}
      aria-label={`${ToolText[tool]} tool`}
      className={classnames('tool', ToolClass[tool], {
        active: current === tool,
      })}
      onClick={() => selectTool()}
      onKeyDown={() => selectTool()}
      data-tooltip={`${ToolText[tool]} tool`}
    />
  )
}

export default function Toolbox() {
  const isStitch = useAppSelector((state) => state.canvas.activeView === ViewMode.STITCH)
  return (
    <div>
      <div className="toolbox">
        <ToolButton tool={ActionTool.TRANSLATE} />
        <ToolButton tool={ActionTool.SELECT} />
        <ToolButton tool={ActionTool.PIXEL} />
        <ToolButton tool={ActionTool.LINE} />
        <ToolButton tool={ActionTool.RECT} />
        { !isStitch && <ImageUpload /> /* XXX this component is not supported yet */ }
      </div>
    </div>
  )
}
