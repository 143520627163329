export interface RGBA {
    r: number;
    g: number;
    b: number;
    a: number;
}

/*
export function isColor(color: any): color is Color {
    return typeof(color) === "object" && color.hasOwnProperty("colorU8");
}
*/

// Stored as RGB in a float (uint does work in GLSL in WebGL 1.0)
export function packColor(red: number, green: number, blue: number): number {
  return ((red & 0xFF) << 16) | ((green & 0xFF) << 8) | (blue & 0xFF);
}

export function parseRGBString(rgb: string): Uint8Array {
  if(!rgb) return null;
  const match = rgb.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i);
  if(match) {
    return new Uint8Array([
      parseInt(match[1], 10),
      parseInt(match[2], 10),
      parseInt(match[3], 10),
    ]);
  }
  return null;
}

export function parseHexString(hex: string): string {
  if(!hex) return null;
  const match = hex.match(/^#([0-9a-f]{2})([0-9a-z]{2})([0-9a-z]{2})/i);
  if(match) {
    return `rgb(${
      parseInt(match[1], 16)},${
      parseInt(match[2], 16)},${
      parseInt(match[3], 16)
    })`;
  }
  return null;
}

export function unpackColor(color: number): number[] {
  const red = Math.floor(color / 65536.0);
  const green = Math.floor((color - red * 65536.0) / 256.0);
  const blue = Math.floor(color - red * 65536.0 - green * 256.0);
  return [red / 255.0, green / 255.0, blue / 255.0];
}

export function pad(value: string, len: number): string {
  const s = `0000000000${value}`;
  return s.substr(s.length - len);
}

export function colorToRGBString(color: Uint8Array): string {
  return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
}

export function colorToRGBAString(color: Uint8Array, alpha = 1.0): string {
  return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${alpha})`;
}

export function unpackColorString(color: number): string {
  const red = Math.floor(color / 65536.0);
  const green = Math.floor((color - red * 65536.0) / 256.0);
  const blue = Math.floor(color - red * 65536.0 - green * 256.0);
  return `#${pad(red.toString(16), 2)
  }${pad(green.toString(16), 2)
  }${pad(blue.toString(16), 2)}`;
}

export function unpackColorByte(color: number): number[] {
  const col = unpackColor(color);
  return [Math.floor(255 * col[0]), Math.floor(255 * col[1]), Math.floor(255 * col[2])];
}

enum Channels {
    RED = 0,
    GREEN,
    BLUE,
    ALPHA,
}

export default class Color {
  public color: RGBA;

  public colorU8: Uint8Array;

  public constructor(first: any, green?: number, blue?: number, alpha?: number) {
    if(typeof (first) === 'object' && first !== null && 'r' in first && 'g' in first && 'b' in first) {
      this.color = {
        r: first.r,
        g: first.g,
        b: first.b,
        a: 'a' in first ? first.a : 1.0,
      };
    } else if(Array.isArray(first) && first !== null && first.length > 2) {
      this.color = {
        r: first[Channels.RED],
        g: first[Channels.GREEN],
        b: first[Channels.BLUE],
        a: first.length > 3 ? first[Channels.ALPHA] : 1.0,
      };
    } else if(first instanceof Uint8Array && first.length > 2) {
      this.color = {
        r: first[Channels.RED],
        g: first[Channels.GREEN],
        b: first[Channels.BLUE],
        a: first.length > 3 ? first[Channels.ALPHA] : 1.0,
      };
    } else {
      this.color = {
        r: first as number,
        g: green,
        b: blue,
        a: alpha || 1.0,
      };
    }
    this.colorU8 = new Uint8Array([this.color.r, this.color.g, this.color.b, this.color.a]);
  }

  public getC3(): Uint8Array {
    return this.colorU8.slice(0, 3);
  }

  public getC4(): Uint8Array {
    return this.colorU8;
  }

  public getColor3(): [number, number, number] {
    return [this.color.r, this.color.g, this.color.b];
  }

  public getColor4(): [number, number, number, number] {
    return [this.color.r, this.color.g, this.color.b, this.color.a];
  }
}
