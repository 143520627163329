import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type {
  Loop, LoopAction, Needle,
} from 'src/data/compiler'

export type SimulationNode = object & {
  id: number
  source: [number, number]
  action: LoopAction
  needle: Needle
  carriers: string[]
}

export enum LinkType {
  COURSE = 0,
  WALE,
}

export type SimulationLink = object & {
  source: number
  target: number
  type: LinkType
}

const initialState = {
  dataId: 0,
  nodes: [] as SimulationNode[],
  links: [] as SimulationLink[],
}

const simulationSlice = createSlice({
  name: 'simulation',
  initialState,
  reducers: {
    clearData(state) {
      return {
        ...initialState,
        dataId: state.dataId + 1,
      }
    },
    resetData(state, { payload: [nodes, links] }: PayloadAction<[SimulationNode[], SimulationLink[]]>) {
      return {
        ...state,
        nodes,
        links,
        dataId: state.dataId + 1,
      }
    },

    resetFromLoops(state, { payload: loops }: PayloadAction<Loop[]>) {
      const nodes = loops.map((l) => {
        const [y, x] = l.source as [number, number]
        return {
          id: l.index,
          source: l.source as [number, number],
          action: l.action,
          needle: l.srcNeedle,
          carriers: l.carriers,
        }
      })
      const links = [] as SimulationLink[]
      for(const l of loops) {
        for(const cl of l.previousLoops) {
          links.push({
            source: l.index,
            target: cl.index,
            type: LinkType.COURSE,
          })
        }
        for(const wl of l.parentLoops) {
          links.push({
            source: l.index,
            target: wl.index,
            type: LinkType.WALE,
          })
        }
      }
      return {
        ...state,
        nodes,
        links,
        dataId: state.dataId + 1,
      }
    },
  },
})

export const { clearData, resetData, resetFromLoops } = simulationSlice.actions
export default simulationSlice.reducer
export type SimulationState = typeof initialState
