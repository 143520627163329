import { RFV4, intersectRects, isRectWithinRect } from 'src/common/math';
import { ReadonlyUint8Array } from 'src/common/types';
import {
  CanvasImage,
  ReadonlyCanvasImage,
  copyCanvasImage,
  copyImageToImage,
  fillPixels,
  createCanvasImage,
} from './image';

/**
 * A rectangular image buffer with anchor position (`x` and `y`).
 *
 * @see {@link CanvasImage}
 */
export interface RectBuffer<NC extends number = number> extends CanvasImage<NC> {
  x: number
  y: number
}

/**
 * A read-only version of a rectangular image buffer
 *
 * @see {@link RectBuffer}
 */
export interface ReadonlyRectBuffer<NC extends number = number> extends ReadonlyCanvasImage<NC> {
  readonly x: number
  readonly y: number
}

/**
 * Checks whether a rect buffer is empty
 */
export function isEmptyRectBuffer<NC extends number>({ width, height, channels }: ReadonlyRectBuffer<NC>) {
  return width === 0 || height === 0 || channels === 0
}

/**
 * Creates an empty rect buffer
 *
 * @param channels the number of fake channels (to carry proper type information)
 * @param x the column anchoring (to carry location information)
 * @param y the row anchoring (to carry location information)
 * @return an empty rect buffer
 */
export function emptyRectBuffer<NC extends number = number>(channels: NC, x = 0, y = 0) {
  return {
    data: new Uint8Array(), width: 0, height: 0, channels, x, y,
  }
}

/**
 * Creates a deep copy of a rect buffer
 *
 * @see {@link copyCanvasImage}
 */
export function copyRectBuffer<NC extends number>(
  buffer: ReadonlyRectBuffer<NC>,
): RectBuffer<NC> {
  return {
    ...copyCanvasImage(buffer),
    x: buffer.x,
    y: buffer.y,
  }
}

/**
 * Crop a rect buffer so that its extents fit
 * within maximum workspace extents:
 * ```
 * workspace = [0, 0, maxWidth, maxHeight]
 * ```
 *
 * This can lead to three cases:
 * - the buffer is contained in the workspace: it is returned
 * - the buffer partially overlaps: a new cropped buffer is returned
 * - the buffer does not overlap: an empty buffer is returned
 *
 * @param buffer the source buffer
 * @param maxWidth the maximum workspace width
 * @param maxHeight the maximum workspace height
 */
export function cropRectBuffer<NC extends number>(
  buffer: ReadonlyRectBuffer<NC>,
  maxWidth: number,
  maxHeight: number,
): ReadonlyRectBuffer<NC> {
  const {
    width, height, channels, x, y,
  } = buffer
  // deletion from left / bottom
  const delLeft = x < 0 ? -x : 0
  const delBottom = y < 0 ? -y : 0
  // deletion from right / top
  const curRight = x + width
  const delRight = Math.max(0, curRight - maxWidth)
  const curTop = y + height
  const delTop = Math.max(0, curTop - maxHeight)
  // total deletion
  const delWidth = delLeft + delRight
  const delHeight = delBottom + delTop
  // if the size does not change, return directly
  if(delWidth === 0 && delHeight === 0) {
    return buffer
  }
  const newWidth = Math.max(0, width - delWidth)
  const newHeight = Math.max(0, height - delHeight)
  // if the size collapses, return an empty buffer
  if(newWidth === 0 || newHeight === 0) {
    return emptyRectBuffer<NC>(channels, x, y)
  }
  // otherwise, create the new buffer and copy necessary content
  const newBuffer = {
    ...createCanvasImage(newWidth, newHeight, channels),
    x: x + delLeft,
    y: y + delBottom,
  }
  const srcRect = [
    delLeft, delBottom,
    delLeft + newWidth, delBottom + newHeight,
  ] as const
  const trgRect = [0, 0, newWidth, newHeight] as const
  if(!copyImageToImage(buffer, srcRect, newBuffer, trgRect)) {
    console.error('Could not copy image data during cropping')
  }
  return newBuffer
}

/**
 * Extract a rectangular section of an image.
 *
 * The region of interest must be contained in the source image.
 *
 * The rectangular section can furthermore be tiled
 * so that the output is a fixed multiple of the input size.
 *
 * @param img the source image, untouched
 * @param rect the region to extract
 * @param fill an optional filling value to use instead of image pixels
 * @param tileX the number of repetitions on the X axis (1 by default)
 * @param tileY the number of repetations on the Y axis (1 by default)
 * @returns a new rect buffer
 * @see cropRectBuffer
 */
export function extractRectBuffer<NC extends number>(
  img: ReadonlyCanvasImage<NC>,
  rect: RFV4,
  fill?: ArrayLike<number>,
  tileX = 1,
  tileY = 1,
): RectBuffer<NC> {
  if(tileX < 1 || !Number.isInteger(tileX)) {
    throw `tileX must be a positive integer, got ${tileX}`
  }
  if(tileY < 1 || !Number.isInteger(tileY)) {
    throw `tileY must be a positive integer, got ${tileY}`
  }
  const workspace = [0, 0, img.width, img.height] as const
  if(!isRectWithinRect(rect, workspace)) {
    throw `The region [${rect}] is not within the image workspace [${workspace}]`
  }
  const [left, bottom, right, top] = rect
  const tileWidth = right - left
  const tileHeight = top - bottom
  const width = tileWidth * tileX
  const height = tileHeight * tileY
  // check if the region matches the image
  if(
    left === 0 &&
    right === 0 &&
    width === img.width &&
    height === img.height &&
    tileX === 1 &&
    tileY === 1
  ) {
    return { ...copyCanvasImage(img), x: 0, y: 0 }
  }
  // we must create a new rect buffer,
  const buffer = {
    ...createCanvasImage(width, height, img.channels),
    x: left,
    y: bottom,
  }

  // use base filling
  if(fill) {
    fillPixels(buffer, fill)
    return buffer
  }

  // target tile copies
  for(let ty = 0; ty < height; ty += tileHeight) {
    for(let tx = 0; tx < width; tx += tileWidth) {
      const trgRect = [tx, ty, tx + tileWidth, ty + tileHeight] as const
      if(!copyImageToImage(img, rect, buffer, trgRect)) {
        console.error('Could not copy image region durring rect buffer extraction')
      }
    }
  }
  return buffer
}

/**
 * Copy the content of a rect buffer onto an image.
 *
 * The buffer **must** be fully contained in the image.
 *
 * @param srcBuffer the source rect buffer
 * @param trgImage the target image
 * @see {@link cropRectBuffer}
 */
export function copyRectBufferToImage<NC extends number>(
  srcBuffer: ReadonlyRectBuffer<NC>,
  trgImage: CanvasImage<NC>,
) {
  if(isEmptyRectBuffer(srcBuffer)) { return }
  const {
    x, y, width, height,
  } = srcBuffer
  const { width: imgWidth, height: imgHeight } = trgImage
  console.assert(
    x >= 0 && x < imgWidth && y >= 0 && y < imgHeight,
    'The start of the rect buffer lies outside the image',
  )
  const right = x + width
  const top = y + height
  console.assert(
    right > 0 && right <= imgWidth && top > 0 && top <= imgHeight,
    'The end of the rect buffer lies outside the image',
  )
  copyImageToImage(
    srcBuffer,
    [0, 0, width, height],
    trgImage,
    [x, y, right, top],
  )
}

/**
 * Transfer the content of a rect buffer onto an image while returning
 * a copy of the region that was modified, before modification.
 *
 * The buffer **must** be fully contained in the image.
 *
 * @param img the source image to be modified
 * @param buffer the target rect buffer to transfer onto the image
 * @returns a copy of the image region at the rect buffer before transfer
 * @see {@link extractRectBuffer}
 * @see {@link copyRectBufferToImage}
 * @see {@link cropRectBuffer}
 */
export function replaceRectBuffer<NC extends number>(
  img: CanvasImage<NC>,
  buffer: ReadonlyRectBuffer<NC>,
): RectBuffer<NC> {
  // for empty buffers, do nothing
  if(isEmptyRectBuffer(buffer)) {
    return emptyRectBuffer<NC>(buffer.channels, buffer.x, buffer.y)
  }

  // extract copy of current data
  const {
    x, y, width, height,
  } = buffer
  const prevRect = extractRectBuffer(img, [x, y, x + width, y + height])

  // copy data to image
  copyRectBufferToImage(buffer, img)

  return prevRect
}
