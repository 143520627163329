import classNames from 'classnames'
import logo from 'src/assets/nav-logo.svg'
import { UserHeader } from 'src/auth'
import {
  FilterQuery, OrderType, OrderTypes, QueryType, QueryTypes,
} from './designs-query'

import 'src/styles/header.scss'

export interface HeaderProps {
  query?: FilterQuery
  onChange?: (query: FilterQuery) => void
}

export default function Header({
  query = {},
  onChange = (() => {}),
}: HeaderProps = {}) {
  const {
    text = '',
    type = QueryType.INCLUDE_STRING,
    order = OrderType.BY_TIME,
  } = query
  return (
    <header>
      <a className="logo" href="/"><img src={logo} alt="logo" /></a>
      <span className="filter-group">
        <span className={classNames('filter', { nonempty: text.length > 0 })}>
          <input
            type="text"
            placeholder="Filter designs"
            onChange={(event) => onChange({
              ...query,
              text: event.currentTarget.value,
            })}
            value={text}
          />
        </span>
        <select
          className="filter-type"
          onChange={(event) => onChange({
            ...query,
            type: QueryTypes[event.currentTarget.selectedIndex][1],
          })}
          value={query.type}
        >
          {QueryTypes.map(([text, type], i) => <option value={type} key={`type-${i}`}>{text}</option>)}
        </select>
        <select
          className="sort-type"
          onChange={(event) => onChange({
            ...query,
            order: OrderTypes[event.currentTarget.selectedIndex][1],
          })}
          value={query.order}
        >
          {OrderTypes.map(([text, type], i) => <option value={type} key={`order-${i}`}>{text}</option>)}
        </select>
      </span>
      <UserHeader />
    </header>
  )
}
