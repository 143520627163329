import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import Modal from 'src/editor/modal'
import { version } from 'src/common/env'
import 'src/styles/auth.scss'

export { default as ProtectedRoute } from './protected-route'

const Avatar = function ({ img, alt }) {
  return (
    <div className="avatar">
      <img src={img} alt={alt} />
    </div>
  )
}

export const UserHeader = function () {
  const { user: { name, picture }, logout } = useAuth0()
  const [userDialog, setUserDialog] = useState(false)
  return (
    <>
      <button onClick={() => setUserDialog(true)} className="user">
        <Avatar img={picture} alt={name} /> {name}
      </button>
      {userDialog && (
        <Modal>
          <div
            className="overlay"
            tabIndex={-1}
            ref={(el) => setTimeout(() => el?.focus())}
            onKeyDown={({ key }) => key == 'Escape' && setUserDialog(false)}
            onClick={({ target, currentTarget }) => target === currentTarget && setUserDialog(false)}
          >
            <div className="dialog">
              <button className="close" onClick={() => setUserDialog(false)}>✕</button>
              {name}
              <button onClick={() => logout({ returnTo: window.location.origin })}>logout</button>
              Topologic editor version: {version}
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export const Auth0ProviderWithHistory = function ({ children }) {
  const history = useHistory()

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  }

  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN}
      audience={process.env.AUTH0_AUDIENCE}
      clientId={process.env.AUTH0_CLIENT}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  )
}
