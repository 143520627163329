import { addNotification, NotificationType } from 'src/editor/notification'
import { resetFromLoops } from 'src/editor/simulation/data'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import {
  fromPanelToKnitout, indexedLoopWithIndex, KnitoutEntry, simulate,
} from 'src/data/compiler'
import ContextMenuItem from './menu-item'

export default function SimulationMenu() {
  const dispatch = useAppDispatch()
  const tnimage = useAppSelector((state) => state.canvas.tnimage)
  const sel = useAppSelector((state) => state.canvas.selection)
  const genSimulation = (cmdFilter: (e: KnitoutEntry) => boolean) => {
    const {
      commands,
    } = fromPanelToKnitout(tnimage, { storeOnly: true })
    // filter to specific list of commands
    const entries = commands.filter(cmdFilter)

    // if no entries, then either the selection is singular
    // or the panel data has some invalid data
    if(entries.length === 0) {
      dispatch(addNotification(
        'Either the selection is empty, or the panel is invalid',
        NotificationType.WARNING,
      ))
      // do not try simulating nothing
      return
    }

    // create indexed loop generator with index
    const [loopGen, loopIndex] = indexedLoopWithIndex()
    // simulate loops
    simulate(entries, loopGen)

    // update the simulation data from the loop data
    dispatch(resetFromLoops(loopIndex))
  }

  return (
    <ContextMenuItem
      name="Simulate ..."
      menu
    >
      <ContextMenuItem
        name="Simulate rows"
        onClick={() => {
          const [, bottom, , top] = sel
          genSimulation(({ src }) => Array.isArray(src) && bottom <= src[0] && src[0] < top)
        }}
      />
      <ContextMenuItem
        name="Simulate selection"
        onClick={() => {
          const [left, bottom, right, top] = sel
          genSimulation(({ src }) => Array.isArray(src) && bottom <= src[0] && src[0] < top && left <= src[1] && src[1] < right)
        }}
      />
    </ContextMenuItem>
  )
}
