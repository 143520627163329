import { importImage } from 'src/data/image/dom'
import { addRows } from 'src/data/time-needle/topology'
import { useAppSelector, useRawMacro, useUserEntry } from 'src/hooks'
import { birdseyeBlock, parseBirdseyeOptions } from 'src/macros/birdseye-block'
import { TimeNeedleSelector } from 'src/macros/common'
import ContextMenuItem from './menu-item'

export default function InsertMenu() {
  const rawMacro = useRawMacro()
  const sel = useAppSelector((state) => state.canvas.selection)
  const selHeight = sel[3] - sel[1]
  const [defaultRowsBetween, setDefaultRowsBetween] = useUserEntry('rows', '1')
  const [defaultBirdseye, setDefaultBirdseye] = useUserEntry('birdseye', 'align left, algo full')
  return (
    <ContextMenuItem
      name="Insert ..."
      menu
    >
      <ContextMenuItem
        name="Rows in between"
        prompt={['Row pattern: N', defaultRowsBetween]}
        onInput={(rowsBetweenStr) => {
          const insertPattern = rowsBetweenStr.split(/[^0-9]+/).flatMap((token) => (token.length ? [parseInt(token, 10)] : []))
          if(!insertPattern.length || insertPattern.some((num) => isNaN(num))) {
            return
          }
          setDefaultRowsBetween(rowsBetweenStr)
          rawMacro((tni, sel) => {
            const s = TimeNeedleSelector.fromRegion(tni, sel)
            const baseRows = s.minus(s.topmost())
            const [t, rows] = addRows(baseRows, 'above', insertPattern.length > 1 ? insertPattern : insertPattern[0])
            return t.union(rows).extendUp(1).wales([sel[0], sel[2]])
          })
        }}
        disabled={selHeight < 2}
      />
      <ContextMenuItem
        name="Birdseye block"
        prompt={['Birdseye options: [align left|center|right] [algo full|minimal] [order Ys...] [expand] [close] [full-needle] [layered [local|global]] [inline] [yarns Y1, Y2, Y3] [invert-two] [unordered]', defaultBirdseye]}
        parser={parseBirdseyeOptions}
        onParse={async (opts, birdseyeStr) => {
          try {
            const img = await importImage(1, { fromPalette: true })
            setDefaultBirdseye(birdseyeStr)
            rawMacro((tni, sel) => {
              const s = TimeNeedleSelector.fromRegion(tni, sel)
              return birdseyeBlock(s, opts, img)
            })
          } catch(err) {
            alert(err)
          }
        }}
      />
    </ContextMenuItem>
  )
}
