import { useDialog } from './dialog'

export default function usePrompt() {
  const showDialog = useDialog()
  return function showPrompt({question, placeholder, value}: {question: any, placeholder?: string, value: any}): Promise<string> {
    return showDialog(({resolve}) => (
      <form
        className="form"
        onSubmit={(event) => {
          event.preventDefault()
          resolve(event.currentTarget.inputValue.value.trim())
        }}
      >
        <label className="title">{question}</label>
        <input type="text" name="inputValue" placeholder={placeholder} defaultValue={value} ref={(el) => setTimeout(() => el?.focus())} />
        <input type="submit" value="OK" />
      </form>
    ))
  }
}
