export function isValidCarrier(yarn: number): boolean {
  return yarn >= 0 && yarn < 6
}

// knitout actions
export type CarrierCommand = 'in' | 'inhook' | 'releasehook' | 'out' | 'outhook'
export type ActionCommand = 'knit' | 'tuck' | 'miss' | 'split' | 'xfer' | 'drop' | 'amiss'
export type KniterateExtension = 'x-roller-advance' | 'x-roller-add-advance' | 'x-carrier-spacing' | 'x-carrier-stopping-distance' | 'x-xfer-style'
export type GeneralExtension = 'x-stitch-number' | 'x-xfer-stitch-number' | 'x-speed-number' | 'x-end-pass' | 'x-vis-color'
export type ExtensionCommand = KniterateExtension | GeneralExtension
export type KnitoutCommand = CarrierCommand | ActionCommand | 'rack' | 'pause' | 'stitch' | ExtensionCommand
export type KnitoutArgumentType = Direction | number | Needle | string
export type KnitoutArguments = KnitoutArgumentType[]
export interface KnitoutEntry {
  cmd: KnitoutCommand
  args: KnitoutArguments
  src?: PanelLocation
}

// main knitout argument types
export type KnitArguments = [Direction, Needle, ...string[]]
export type TuckArguments = KnitArguments
export type MissArguments = KnitArguments
export type XferArguments = [Needle, Needle]
export type DropArguments = [Needle]
export type AmissArguments = DropArguments
export type SplitArguments = [Direction, Needle, Needle, ...string[]]
export type RackArguments = [number]
export type CarriersArguments = [string, ...string[]]

export function isKnitArguments(args: KnitoutArguments): args is KnitArguments {
  if(args.length < 3) { return false }
  const [d, n, ...cs] = args
  return (
    isKnownDirection(d) &&
    n instanceof Needle &&
    cs.every((name) => typeof name === 'string')
  )
}
export const isTuckArguments = isKnitArguments
export const isMissArguments = isKnitArguments

export function isXferArguments(args: KnitoutArguments): args is XferArguments {
  return args.length === 2 && args.every((n) => n instanceof Needle)
}

export function isDropArguments(args: KnitoutArguments): args is DropArguments {
  return args.length === 1 && args[0] instanceof Needle
}
export const isAmissArguments = isDropArguments

export function isSplitArguments(args: KnitoutArguments): args is SplitArguments {
  if(args.length < 4) { return false }
  const [d, sn, tn, ...cs] = args
  return (
    isKnownDirection(d) &&
    sn instanceof Needle &&
    tn instanceof Needle &&
    cs.every((name) => typeof name === 'string')
  )
}

export function isRackArguments(args: KnitoutArguments): args is RackArguments {
  return args.length === 1 && typeof args[0] === 'number'
}

export function isCarriersArguments(args: KnitoutArguments): args is CarriersArguments {
  return args.length > 0 && args.every((c) => typeof c === 'string')
}

// direction
export type Direction = '-' | '+'
export const LEFT = '-'
export const RIGHT = '+'
export const otherDir = (dir: Direction): Direction => (dir === '-' ? '+' : '-')

// orientation
export type Orientation = -1 | 1
export const CCW = 1
export const CW = -1
export const otherOrient = (ori: Orientation): Orientation => (-ori) as Orientation

// unknown direction
export type UnknownDirection = '?'
export type DirectionOrUnknown = Direction | UnknownDirection
export const UNKNOWN = '?'
export function isKnownDirection(dir: any): dir is Direction {
  return dir === LEFT || dir === RIGHT
}

// transfer direction
export const XFER_DIRECTION = '='
export type DirectionOrXfer = Direction | '='

// 6-carriers flags
export type CarrierFlag<T = boolean> = [T, T, T, T, T, T]

// bed side
export type BedSide = 'f' | 'b'
export type SliderSide = 'fs' | 'bs'
export type Bed = BedSide | SliderSide
export const FRONT = 'f'
export const REAR = 'b'
export const FRONT_SLIDER = 'fs'
export const REAR_SLIDER = 'bs'
const AllBeds = [FRONT, REAR, FRONT_SLIDER, REAR_SLIDER] as Bed[]
export function isSliderSide(bed: Bed): bed is SliderSide {
  return bed.length === 2 && bed.charAt(1) === 's'
}
export function sliderSideOf(b: BedSide): SliderSide {
  return b === FRONT ? FRONT_SLIDER : REAR_SLIDER
}
export function bedSideOf(s: SliderSide): BedSide {
  return s.charAt(0) as BedSide
}
export function otherBedSide(bs: BedSide): BedSide {
  return bs === FRONT ? REAR : FRONT
}
export function otherSliderSide(ss: SliderSide): SliderSide {
  return ss === FRONT_SLIDER ? REAR_SLIDER : FRONT_SLIDER
}
export function otherBed(bed: Bed): Bed {
  if(isSliderSide(bed)) {
    return otherSliderSide(bed)
  }
  return otherBedSide(bed)
}

// needle
export const NEEDLE_SIDE_MASK = 0x00000001
export const NEEDLE_SLIDER_MASK = 0x00000002
export const NEEDLE_BED_MASK = NEEDLE_SIDE_MASK | NEEDLE_SLIDER_MASK
export const NEEDLE_OFFSET_MASK = 0xFFFFFFFC
export const NEEDLE_OFFSET_SHIFT = 2
export class Needle {
  constructor(
    public readonly bed: Bed,
    public readonly offset: number,
  ) {}

  get side(): BedSide { return this.bed === FRONT_SLIDER ? FRONT : this.bed === REAR_SLIDER ? REAR : this.bed }

  matches(n: Needle) { return n && n.bed === this.bed && n.offset === this.offset }

  matchesBed(n: Needle) { return n && this.bed === n.bed }

  matchesSide(n: Needle) { return n && this.side === n.side }

  matchesOffset(n: Needle) { return n && this.offset === n.offset }

  static from(arg: string | [Bed, number] | [number, Bed] | Needle): Needle {
    if(arg instanceof Needle) {
      return arg
    } if(Array.isArray(arg)) {
      return typeof arg[0] === 'string' ? new Needle(arg[0], arg[1] as number) : new Needle(arg[1] as Bed, arg[0])
    }
    arg = arg.toLowerCase()
    for(const bed of ['fs', 'bs', 'f', 'b'] as Bed[]) {
      if(arg.startsWith(bed)) {
        const offsetString = arg.substring(bed.length).trim();
        const normOffsetString = offsetString.startsWith('+') ? offsetString.substring(1) : offsetString;
        const offset = parseInt(normOffsetString, 10)
        return new Needle(bed, offset)
      }
    }
    throw `Invalid needle string ${arg}`
  }

  toB32(): number {
    return AllBeds.indexOf(this.bed) | (this.offset << NEEDLE_OFFSET_SHIFT)
  }

  static fromB32(b32: number): Needle {
    const sb = b32 & NEEDLE_BED_MASK
    if(!(sb >= 0 && sb < AllBeds.length)) {
      throw `Invalid bed bits: ${sb} from ${b32}`
    }
    const offset = b32 >> NEEDLE_OFFSET_SHIFT
    return new Needle(AllBeds[sb], offset)
  }

  // transformations
  shiftedBy(shift: number) {
    return shift !== 0 ? new Needle(this.bed, this.offset + shift) : this
  }

  shiftedTo(offset: number) {
    return new Needle(this.bed, offset)
  }

  toHook() { return isSliderSide(this.bed) ? new Needle(bedSideOf(this.bed), this.offset) : this }

  toSlider() { return isSliderSide(this.bed) ? this : new Needle(sliderSideOf(this.bed), this.offset) }

  otherSide(racking = 0) { return new Needle(otherBed(this.bed), this.offset + (this.inFront() ? -racking : +racking)); }

  otherHook(racking = 0) { return this.toHook().otherSide(racking); }

  otherSlider(racking = 0) { return this.toSlider().otherSide(racking); }

  otherSides(racking = 0) {
    if(this.inFront()) return [new Needle(REAR, this.offset - racking), new Needle(REAR_SLIDER, this.offset - racking)];
    return [new Needle(FRONT, this.offset + racking), new Needle(FRONT_SLIDER, this.offset + racking)];
  }

  // queries
  inFront() { return this.side === FRONT }

  inFrontHook() { return this.bed === FRONT }

  onFrontSlider() { return this.bed === FRONT_SLIDER }

  inBack() { return this.side === REAR }

  inBackHook() { return this.bed === REAR }

  onBackSlider() { return this.bed === REAR_SLIDER }

  inHook() { return !this.onSlider() }

  onSlider() { return isSliderSide(this.bed) }

  rackingTo(n: Needle) {
    if(this.inFront()) {
      if(!n.inFront()) {
        // racking from front to rear
        return this.offset - n.offset
      }
      throw 'Both needles are in the front'
    } else if(n.inFront()) {
      // racking from rear to front
      return n.offset - this.offset
    } else {
      throw 'Both needles are on the rear'
    }
  }

  dirTo(n: Needle): DirectionOrUnknown {
    if(n.offset < this.offset) return LEFT;
    if(n.offset > this.offset) return RIGHT;
    return UNKNOWN;
  }

  frontOffset(racking: number) {
    if(this.inFront()) {
      return this.offset
    }
    // /!\ Positive racking = right offset of the back bed
    // <=> racking = frontOffset - backOffset
    //  &  backOffset = this.offset
    //  => frontOffset = backOffset + racking
    return this.offset + racking
  }

  backOffset(racking: number) {
    if(this.inBack()) return this.offset
    return this.offset - racking
  }

  orientToDir(ori: Orientation): Direction {
    if(this.inFront()) {
      return ori === CCW ? RIGHT : LEFT // direct
    }
    return ori === CCW ? LEFT : RIGHT // reverse
  }

  dirToOrient(dir: Direction): Orientation {
    if(this.inFront()) {
      return dir === RIGHT ? CCW : CW // direct
    }
    return dir === RIGHT ? CW : CCW // reverse
  }

  toString() { return this.bed + this.offset }
}

// knitout options
// @see https://stackoverflow.com/questions/56113411/ts-how-to-get-constant-array-element-type
export const AllowedCarrierSpacings = [
  1, 2, 3, 4, 5, 6, 7, 8, 9,
] as const
export const AllowedStoppingDistances = [
  0.5, 1.5, 2.5, 3.5, 4.5, 5.5, 6.5, 7.5, 8.5, 9.5,
] as const
export type CarrierSpacing = typeof AllowedCarrierSpacings[number]
export type StoppingDistance = typeof AllowedStoppingDistances[number]
export type TransferStyle = 'four-pass' | 'two-pass'

export type CellLocation = [number, number]
export type RowLocation = number
export type PanelLocation = CellLocation | RowLocation

export const enum ProgramStage {
  PANEL = 'panel',
  PANEL_TO_KNITOUT = 'knitout',
  KNITOUT_TO_KCODE = 'kcode',
  SIMULATION = 'simulation',
}

export enum IssueLevel {
  VERBOSE = 0,
  INFO,
  WARNING,
  DANGER,
  INVALID,
}

export interface Issue {
  stage: ProgramStage
  context: string
  source?: PanelLocation
  related?: PanelLocation
  message: string
  level: IssueLevel
}

export class IssueManipulator implements Issue {
  public source: PanelLocation

  public related: PanelLocation

  constructor(
    public readonly stage: ProgramStage,
    public context: string = '',
    public message: string = '',
    public level: IssueLevel = IssueLevel.WARNING,
  ) {}

  msg(message: string, level: IssueLevel = this.level) {
    this.message = message
    this.level = level
    return this
  }

  warn(msg: string) {
    return this.msg(msg, IssueLevel.WARNING)
  }

  danger(msg: string) {
    return this.msg(msg, IssueLevel.DANGER)
  }

  invalid(msg: string) {
    return this.msg(msg, IssueLevel.INVALID)
  }

  as(ctx: string) {
    this.context = ctx
    return this
  }

  at(loc: PanelLocation) {
    this.source = loc
    return this
  }

  between(src: PanelLocation, dst: PanelLocation) {
    this.source = src
    this.related = dst
    return this
  }
}

let progStage: ProgramStage = ProgramStage.PANEL
export function setProgramStage(stage: ProgramStage) {
  progStage = stage
}
export function issue(ctx?: string) {
  return new IssueManipulator(progStage, ctx)
}
export function info(ctx?: string, msg?: string, verbose = false) {
  return new IssueManipulator(progStage, ctx, msg, verbose ? IssueLevel.VERBOSE : IssueLevel.INFO)
}

export function isInfo(issue: Issue, orVerbose = false): boolean {
  if(orVerbose) {
    return issue.level <= IssueLevel.INFO
  }
  return issue.level === IssueLevel.INFO
}
