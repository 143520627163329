import { useAppDispatch } from 'src/hooks';
import { centerHome, getCenterZoom } from './time-needle/action';
import { deferAction } from './time-needle/slice';

import 'src/styles/view-control.scss'

const ViewControl = function () {
  const dispatch = useAppDispatch()

  return (
    <div className="view-control">
      <div
        className="item zoom-in"
        onClick={() => {
          dispatch(deferAction(getCenterZoom(-1)))
        }}
      />
      <div
        className="item zoom-out"
        onClick={() => {
          dispatch(deferAction(getCenterZoom(1)))
        }}
      />
      <div
        className="item zoom-center"
        onClick={() => {
          dispatch(deferAction(centerHome))
        }}
      />
    </div>
  )
}

export default ViewControl
