export const KEYCODE_SHIFT = 16
export const KEYCODE_CONTROL = 17
export const KEYCODE_COMMAND = 91
export const KEYCODE_BACKSPC = 8
export const KEYCODE_CR = 13
export const KEYCODE_TAB = 9
export const KEYCODE_SPACE = 32
export const KEYCODE_C = 67
export const KEYCODE_E = 69
export const KEYCODE_L = 76
export const KEYCODE_M = 77
export const KEYCODE_N = 78
export const KEYCODE_O = 79
export const KEYCODE_P = 80
export const KEYCODE_Q = 81
export const KEYCODE_R = 82
export const KEYCODE_S = 83
export const KEYCODE_T = 84
export const KEYCODE_U = 85
export const KEYCODE_V = 86
export const KEYCODE_X = 88
export const KEYCODE_Y = 89
export const KEYCODE_Z = 90
export const KEYCODE_0 = 48
export const KEYCODE_1 = 49
export const KEYCODE_2 = 50
export const KEYCODE_3 = 51
export const KEYCODE_4 = 52
export const KEYCODE_5 = 53
export const KEYCODE_6 = 54
export const KEYCODE_7 = 55
export const KEYCODE_8 = 56
export const KEYCODE_9 = 57
export const KEYCODE_ESC = 27
export const KEYCODE_DOWN = 40
export const KEYCODE_UP = 38
export const KEYCODE_LEFT = 37
export const KEYCODE_RIGHT = 39
export const KEYCODE_PLUS = 187
export const KEYCODE_MINUS = 189
export const KEYCODE_MINUS_ALT = 173
export const KEYCODE_FULLSTOP = 190
export const KEYCODE_MINUS2 = 109
export const KEYCODE_PLUS2 = 107
export const KEYCODE_FULLSTOP2 = 110
