import {
  getSelectionType,
  isYarnIndexOrNone,
  parseUserOptions,
  RowOptions,
  TimeNeedleTransformer,
  UserDirection,
  YarnIndexOrNone,
} from './common'

export type BlockSideOptions = {
  [P in Exclude<keyof RowOptions, 'stitchSize'>]: RowOptions[P][]
}

export interface BlockOptions {
  start: BlockSideOptions
  end: BlockSideOptions
}

export function createBlockSideOptions(): BlockSideOptions {
  return {
    carrier: new Array<YarnIndexOrNone>(),
    direction: new Array<UserDirection>(),
    racking: new Array<number>(),
    speed: new Array<number>(),
    roller: new Array<number>(),
    frontStitchSize: new Array<number>(),
    rearStitchSize: new Array<number>(),
  }
}

export interface LocalBlockOptions {
  knit: BlockOptions
  xfer: BlockOptions
  local?: boolean
}

export function parseLocalBlockOptions(input: string): LocalBlockOptions | string {
  const knitBlock = { start: createBlockSideOptions(), end: createBlockSideOptions() } as BlockOptions
  const xferBlock = { start: createBlockSideOptions(), end: createBlockSideOptions() } as BlockOptions
  const res: LocalBlockOptions = {
    knit: knitBlock,
    xfer: xferBlock,
    local: false,
  }
  let block: BlockOptions = knitBlock
  let side: 'start' | 'end' = 'start'
  const pushArg = <T = number>(arr: T[], val: T) => {
    if(side === 'start') {
      arr.push(val)
    } else {
      arr.unshift(val)
    }
  }
  const error = parseUserOptions(input, {
    knit: () => { block = knitBlock; side = 'start' },
    xfer: () => { block = xferBlock; side = 'start' },
    local: () => { res.local = true },
    global: () => { res.local = false },
    '...': () => {
      if(side === 'start') {
        side = 'end'
      } else {
        return 'Invalid token ...'
      }
    },
  }, {
    carrier: (token: string) => {
      const yarn = parseInt(token, 10)
      if(!isYarnIndexOrNone(yarn)) {
        return `Invalid yarn index ${token}`
      }
      if(block === knitBlock) {
        if(yarn) {
          pushArg<YarnIndexOrNone>(block[side].carrier, yarn)
        } else {
          return 'Knit carrier cannot be empty (0)'
        }
      } else if(yarn === 0) {
        pushArg<YarnIndexOrNone>(block[side].carrier, yarn)
      } else {
        return 'Transfer rows cannot have non-empty carrier'
      }
    },
    dir: (token: string) => {
      if(block === knitBlock) {
        if(['0', '1', '2'].includes(token)) {
          pushArg<UserDirection>(block[side].direction, parseInt(token, 10) as UserDirection)
        } else {
          return `Invalid direction token ${token}`
        }
      } else if(token === '2') {
        block.start.direction = [2]
      } else {
        return 'Cannot set direction for transfers'
      }
    },
    racking: (token: string) => {
      pushArg(block[side].racking, parseFloat(token))
    },
    roller: (token: string) => {
      pushArg(block[side].roller, parseInt(token, 10))
    },
    speed: (token: string) => {
      pushArg(block[side].speed, parseInt(token, 10))
    },
    stitch: (token: string) => {
      const size = parseInt(token, 10)
      pushArg(block[side].frontStitchSize, size)
      pushArg(block[side].rearStitchSize, size)
    },
    frontStitch: (token: string) => {
      pushArg(block[side].frontStitchSize, parseInt(token, 10))
    },
    rearStitch: (token: string) => {
      pushArg(block[side].rearStitchSize, parseInt(token, 10))
    },
  }, null)
  if(error) {
    return error
  }
  return res
}

export function localBlockOptions(s: TimeNeedleTransformer, { knit, xfer, local = false }: LocalBlockOptions) {
  const [start, end] = s.getRowExtents() as [number, number]
  // only keep rows that have a knit or transfer
  const baseRows = (
    local ? s.splitByRow() : Array.from({ length: end - start + 1 }, (_, i) => s.fullCourse(i + start))
  ).filter((row) => row.some((c) => c.isKnit() || c.isTransfer()))
  if(!baseRows.length) {
    return // no row => no block
  }
  // group as blocks of rows of the same type
  const getType = (row: TimeNeedleTransformer) => {
    const { hasKnit, hasTransfer } = getSelectionType(row)
    return hasKnit ? 0 : hasTransfer ? 1 : 2
  }
  const setOpt = (opts: RowOptions, key: keyof BlockSideOptions, blockSide: BlockSideOptions, i: number) => {
    if(key === 'direction') {
      const dirs = blockSide[key]
      opts.direction = dirs[i] ?? dirs[dirs.length - 1]
    } else if(key === 'carrier') {
      const yarn = blockSide[key]
      opts.carrier = yarn[i] ?? yarn[yarn.length - 1]
    } else {
      const vals = blockSide[key]
      opts[key] = vals[i] ?? vals[vals.length - 1]
    }
  }
  const blocks = [{
    type: getType(baseRows[0]), rows: new Array<TimeNeedleTransformer>(),
  }] as { type: 0|1|2, rows: TimeNeedleTransformer[] }[]
  for(const row of baseRows) {
    const type = getType(row)
    const lastBlock = blocks[blocks.length - 1]
    if(lastBlock.type === type) {
      lastBlock.rows.push(row) // add to last block
    } else {
      blocks.push({ type, rows: [row] }) // create new block
    }
  }

  // apply options to each block
  for(const { type, rows } of blocks) {
    if(type === 2) {
      continue
    }
    const blockOpts = type === 0 ? knit : xfer
    for(let i = 0; i < rows.length; ++i) {
      // generate per-row options
      const opts = {} as RowOptions
      for(const key of [
        'direction', 'speed', 'roller', 'racking', 'frontStitchSize', 'rearStitchSize',
      ] as (keyof BlockSideOptions)[]) {
        const start = blockOpts.start[key]
        const end = blockOpts.end[key]
        if(start.length + end.length === 0) {
          continue // no option to set
        }
        const startIdx = i
        const endIdx = rows.length - 1 - i
        if((!!start.length && i < start.length) || !end.length) {
          setOpt(opts, key, blockOpts.start, startIdx)
        } else if((!!end.length && endIdx < end.length) || !start.length) {
          setOpt(opts, key, blockOpts.end, endIdx)
        } else {
          // pick closest side
          // note: both sides are non-empty in this branch
          const fromStart = startIdx - start.length
          const fromEnd = endIdx - end.length
          if(fromStart <= fromEnd) {
            setOpt(opts, key, blockOpts.start, startIdx)
          } else {
            setOpt(opts, key, blockOpts.end, endIdx)
          }
        }
      } // endfor key of keyof BlockSideOptions

      // set options
      rows[i].first().options(opts)
    }
  }
}
