import type { DOMAttributes } from 'react'
import type {
  DetailLevel, RFV2, RFV3, RFV4,
} from 'src/common/math'
import type RenderContext from 'src/common/webgl/render-context'
import type { LocalTextureFunc, TextureData } from 'src/common/webgl/texture'
import { ViewMode } from 'src/data/time-needle/view-mode'

export type RenderCallback = (ctx: RenderContext) => void

export enum CursorMode {
  DEFAULT = 0,
  SELECTING,
  PASTING,
}

// delegated DOM event handlers
export type DOMHandlerProps = Omit<
  DOMAttributes<HTMLCanvasElement>,
  'children' | 'dangerouslySetInnerHTML'
>

// uniforms and textures passed to webgl
export interface WebGLDataProps {
  // shader uniforms
  viewMode: ViewMode
  viewZoom: number
  pageOffset: RFV2
  pageDims: RFV2
  selectAABB: RFV4
  selectMode: CursorMode
  mouseCoord: RFV4
  configData: RFV4
  errorData: RFV4
  markerData: RFV3
  combinedAR: number
  detailLevel: DetailLevel

  // texture data
  cdata: TextureData
  odata: TextureData
  pasteData: TextureData
  previewData: LocalTextureFunc
}

// webgl lifecycle handlers
export interface WebGLHandlers {
  onInit?: (width: number, height: number) => void
  onResize?: (width: number, height: number, rect: DOMRect) => void
  onRender?: RenderCallback
}

// combined properties
export interface TimeNeedleCanvasProps extends DOMHandlerProps, WebGLDataProps, WebGLHandlers {
  id?: string
}

export const ShaderArgs = [
  'configData',
  'errorData',
  'markerData',
  'mouseCoord',
  'pageOffset',
  'pageDims',
  'viewDims',
  'viewMode',
  'viewZoom',
  'selectAABB',
  'selectMode',
  'combinedAR',
  'detailLevel',
] as const

export const LayerArgs = [
  'cdata',
  'odata',
  'pasteData',
] as const
