import { render } from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { DialogProvider } from 'src/editor/dialog'
import { Auth0ProviderWithHistory as Auth0Provider } from './auth'
import store from './store'
import { MakeMainRoutes } from './routes'
import { version } from './common/env'

import initSentry from './common/sentry'

import './styles/index.scss'
import './styles/app.scss'

if(process.env.ENV !== 'development') {
  initSentry()
}
console.log('Topologic editor version: ', version)

render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <Auth0Provider>
        <DialogProvider>
          <MakeMainRoutes />
        </DialogProvider>
      </Auth0Provider>
    </BrowserRouter>
  </ReduxProvider>,
  document.getElementById('root'),
);
