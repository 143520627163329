import classNames from 'classnames'
import { shallowEqual } from 'react-redux'
import { unproject } from 'src/common/math'
import { NumCellMargin, setOffset } from 'src/editor/time-needle/slice'
import { useAppDispatch, useAppSelector } from 'src/hooks'

import 'src/styles/vertical-ruler.scss'

const VerticalRuler = function () {
  const dispatch = useAppDispatch()
  const {
    yConfig,
    M,
    offset,
    viewHeight,
    zoomLevel,
  } = useAppSelector(({
    canvas: {
      axisConfig: [, yConfig],
      M,
      offset,
      transform: { viewDims },
      zoomData: { zoomLevel },
    },
  }) => ({
    yConfig,
    M,
    offset,
    viewHeight: viewDims[1],
    zoomLevel,
  }), shallowEqual)

  const numEntries = yConfig?.numCells || 0
  const offsetInit = unproject([0, yConfig.initOffset], M)
  const offsetNew = unproject(offset, M)
  const delta = (offsetNew[1] - offsetInit[1])
  const margin = NumCellMargin * yConfig.cellSize

  // find first row entry that is within viewport (+/- cell margin)
  const firstValidIdx = yConfig.cellPos.findIndex((pos:number) => {
    const newPos = pos + delta
    return -margin < newPos && newPos < viewHeight + margin
  })

  if(firstValidIdx === -1) {
    return null // nothing to display
  }

  // get index of topmost entry (reverse Y)
  const cellLength = yConfig.cellPos.length
  const topEntryIdx = Math.min(firstValidIdx + numEntries - 1, cellLength - 1)
  const marginTop = (
    yConfig.cellPos[topEntryIdx] + // the topmost cell's Y position
    delta - // the current delta due to panning
    yConfig.cellSize * 0.5 - 5// shift from center of cell to its top
  )
  const numInvalidEntries = Math.max(0, firstValidIdx + numEntries - cellLength)
  const numValidEntries = numEntries - numInvalidEntries

  // const botEntryIdx = topEntryIdx + numValidEntries - 1
  const marginBottom = viewHeight - (
    yConfig.cellPos[firstValidIdx] + // the bottmmost cell's Y position
    delta + // the current delta due to panning
    yConfig.cellSize * 0.5 // shift from center of cell to its bottom
  ) - (
    5
  )
  const onWheel = ({ deltaY }) => {
    if(marginTop > 0 && deltaY < 0 || marginBottom > 0 && deltaY > 0) {
      return;
    }
    const [x, y] = offset
    const scale = 0.00001 * Math.max(zoomLevel, 2)
    dispatch(setOffset([x, y + scale * deltaY]))
  }

  return (
    <div
      className="vertical-ruler-wrapper"
      onWheel={onWheel}
      style={{
        marginTop: marginTop > 5 ? marginTop - 5 : 0,
        marginBottom: marginBottom > 5 ? marginBottom - 5 : 0,
      }}
    >
      <div
        className="vertical-ruler"
        style={{
          marginTop: marginTop > 5 ? 5 : marginTop,
          gridAutoRows: yConfig.cellSize,
        }}
      >
        {Array.from({ length: numValidEntries }, (_, i) => {
          // note: firstValidIdx is the index of the first data row to show
          //       (numEntires-1-i) reverse the order because of reverse Y indexing
          const row = firstValidIdx + numValidEntries - 1 - i
          return (
            <div
              className={classNames('vertical-ruler-row', { highlighted: (row + 1) % 10 === 0 })}
              key={`entry-${row + 1}`}
            >
              <div className="cell cell-index">{row + 1}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default VerticalRuler
