/*
The Palette stores the fixed table of colours used to define the pattern layer.  Note that two different palettes are
required.  One for the pattern layer, and one for the stitch layer.  The stitch layer palette may be much larger and
will use a more complex mapping than the pattern layer which is currently limited to six colours, with white being
defined as Yarn 7 in Uck.  We may need to algorithmically select a colour other than white if white is already in the
palette.
 */

import Color, {
  colorToRGBString, packColor, parseRGBString, unpackColorByte,
} from 'src/common/math/Color';
import { ReadonlyUint8Array } from 'src/common/types';
import { ReadonlyCanvasImage } from 'src/data/image';

export const DefaultPaletteData = new Uint8Array([
  226, 115, 0,
  115, 216, 255,
  252, 220, 0,
  253, 161, 255,
  0, 98, 177,
  104, 188, 0,
]) as ReadonlyUint8Array

export const DefaultPaletteTexture: ReadonlyCanvasImage<3> = {
  width: 6,
  height: 1,
  channels: 3,
  data: DefaultPaletteData,
}

// These are the palette colours for the pattern layers

export const defaultPalette = [
  'rgb(226,115,0)',
  'rgb(115,216,255)',
  'rgb(252,220,0)',
  'rgb(253,161,255)',
  'rgb(0,98,177)',
  'rgb(104,188,0)',
];

// TODO: configure globally at top; push value to FADE_COLOR in CanvasFragShader.glsl
const INVALID_COLOR = packColor(38, 38, 38);

export interface RGBTriple {
  r: number
  g: number
  b: number
}

export default class Palette {
  public rgbString: string[];

  public rgbaU32: Uint32Array;

  public rgbU8: Uint8Array[];

  public texture: Uint8ClampedArray;

  public length: number;

  public readonly INVALID: number = 6;

  // Due to the unique way in which the colours need to be assigned to the spools, we
  // allow null values for colours in the palette - this indicates to the system that
  // the colour in the palette is unused.  So, for a two colour palette, the colours
  // will be like this:
  // [null, null, 'rgb(0,0,0)', 'rgb(255,255,255)', null, null]

  public constructor(color: string[] | Uint32Array | Uint8Array[]) {
    this.length = color.length;
    if(color instanceof Uint32Array) {
      if(color[color.length - 1] !== INVALID_COLOR) {
        this.rgbaU32 = new Uint32Array(color.length + 1);
        this.rgbaU32.set(color.slice(), 0);
        this.rgbaU32[color.length] = INVALID_COLOR;
      } else {
        this.rgbaU32 = color.slice();
      }
      this.rgbString = new Array<string>(color.length);
      this.rgbU8 = new Array<Uint8Array>(color.length);
      this.texture = new Uint8ClampedArray(color.length * 3);

      for(let i = 0; i !== color.length; ++i) {
        this.rgbU8[i] = new Uint8Array(unpackColorByte(color[i]));
        this.rgbString[i] = colorToRGBString(this.rgbU8[i]);
        this.texture.set(this.rgbU8[i], i * 3)
      }
      return;
    } if(this.length > 0) {
      // We must check if any of the elements are strings because we now allow rearranging of the
      // empty feeders
      if((color as Array<string>).some((v) => v && typeof v === 'string')) {
        this.rgbString = (color as string[]).slice();
        this.rgbU8 = new Array<Uint8Array>(color.length);
        this.rgbaU32 = new Uint32Array(color.length + 1);
        this.texture = new Uint8ClampedArray(color.length * 3);

        for(let i = 0; i !== color.length; ++i) {
          if(this.rgbString[i] === null) {
            this.rgbU8[i] = null;
            this.rgbaU32[i] = 0; // the blackest black
            this.texture.set([0, 0, 0], i * 3);
          } else {
            this.rgbU8[i] = parseRGBString(this.rgbString[i]);
            this.rgbaU32[i] = packColor(this.rgbU8[i][0], this.rgbU8[i][1], this.rgbU8[i][2]);
            this.texture.set(this.rgbU8[i], i * 3)
          }
        }

        // Add white as the terminal colour
        this.rgbaU32[color.length] = INVALID_COLOR;
        return;
      } if((color as Array<Uint8Array>).some((v) => v && v instanceof Uint8Array)) {
        this.rgbU8 = (color as Uint8Array[]).slice();
        this.rgbString = new Array<string>(color.length);
        this.rgbaU32 = new Uint32Array(color.length);
        this.texture = new Uint8ClampedArray(color.length * 3);

        for(let i = 0; i !== color.length; ++i) {
          if(this.rgbU8[i] === null) {
            this.rgbString[i] = null;
            this.rgbaU32[i] = 0;
            this.texture.set([0, 0, 0], i * 3);
          } else {
            this.rgbString[i] = colorToRGBString(this.rgbU8[i]);
            this.rgbaU32[i] = packColor(this.rgbU8[i][0], this.rgbU8[i][1], this.rgbU8[i][2]);
            this.texture.set(this.rgbU8[i].slice(0, 3), i * 3)
          }
        }

        this.rgbaU32[color.length] = INVALID_COLOR;
        return;
      }
    }

    console.error('Unrecognized Color Palette Input', color);
  }

  public equals(palette: Palette): boolean {
    if(this === palette) return true;

    if(this.length !== palette.length) return false;

    for(let i = 0; i !== this.rgbaU32.length; ++i) {
      if(this.rgbaU32[i] !== palette.rgbaU32[i]) return false;
    }

    return true;
  }

  public setColorString(index: number, color: string) {
    if(this.rgbString.length <= index) return;
    this.rgbString[index] = color;
    this.rgbU8[index] = color ? parseRGBString(this.rgbString[index]) : null;
    this.rgbaU32[index] = color ? packColor(this.rgbU8[index][0], this.rgbU8[index][1], this.rgbU8[index][2]) : null;
    this.texture.set(this.rgbU8[index], index * 3);
  }

  public findU8(color: Uint8Array): number {
    const buf = this.rgbU8;
    for(let i = 0; i !== buf.length; ++i) {
      if(buf[i] && buf[i][0] === color[0] && buf[i][1] === color[1] && buf[i][2] === color[2]) return i;
    }
    return 6; // Always return Yarn 6 for invalid
  }

  public findU32(color: number): number {
    const idx = this.rgbaU32.indexOf(color);
    return idx === -1 ? 6 : idx;
  }

  public findRGBString(color: string): number {
    const idx = this.rgbString.indexOf(color);
    return idx === -1 ? 6 : idx;
  }

  public toColorArray(): Color[] {
    return Array.from(this.rgbaU32).map((v) => {
      const u = unpackColorByte(v);
      return new Color(u[0], u[1], u[2]);
    }).slice(0, 6);
  }

  public copy(): Palette {
    return new Palette(this.rgbString)
  }

  public static default() {
    return new Palette(defaultPalette)
  }
}

// TODO: move out
export function testColorStuff() {
  const stringColors = [
    'rgb(213, 94, 0)',
    'rgb(86, 180, 233)',
    'rgb(240, 228, 66)',
    'rgb(204, 121, 167)',
    'rgb(0, 114, 178)',
    'rgb(0, 158, 115)',
  ];

  const uint8Colors = [
    new Uint8Array([213, 94, 0]),
    new Uint8Array([86, 180, 233]),
    new Uint8Array([240, 228, 66]),
    new Uint8Array([204, 121, 167]),
    new Uint8Array([0, 114, 178]),
    new Uint8Array([0, 158, 115]),
  ];

  const uint32Colors = new Uint32Array([
    packColor(213, 94, 0),
    packColor(86, 180, 233),
    packColor(240, 228, 66),
    packColor(204, 121, 167),
    packColor(0, 114, 178),
    packColor(0, 158, 115),
  ]);

  const deepCompare = (x, y) => {
    for(let i = 0; i !== x.length; ++i) {
      if(x[i] !== y[i]) return false;
    }
    return true;
  };

  for(let i = 0; i !== stringColors.length; ++i) {
    if(deepCompare(colorToRGBString(uint8Colors[i]), stringColors[i])) {
      console.log('Match u8->str');
    } else {
      console.log('No match u8->str');
    }

    if(deepCompare(unpackColorByte(uint32Colors[i]), uint8Colors[i])) {
      console.log('Match u32->u8');
    } else {
      console.log('No match u32->u8', unpackColorByte(uint32Colors[i]), uint8Colors[i]);
    }

    if(deepCompare(parseRGBString(stringColors[i]), uint8Colors[i])) {
      console.log('Match str->u8');
    } else {
      console.log('No match str->u89');
    }
  }

  const strings = new Palette(stringColors);
  const u8s = new Palette(uint8Colors);
  const u32s = new Palette(uint32Colors);

  console.log('Strings', JSON.stringify(strings));
  console.log('U8s', JSON.stringify(u8s));
  console.log('U32s', JSON.stringify(u32s));

  let failed = false;
  for(let i = 0; i !== strings.length; ++i) {
    if(strings.rgbaU32[i] !== u8s.rgbaU32[i] || strings.rgbaU32[i] !== u32s.rgbaU32[i]) {
      console.log('Fail u32');
      failed = true;
    }
    if(strings.rgbString[i] !== u8s.rgbString[i] || strings.rgbString[i] !== u32s.rgbString[i]) {
      console.log('Fail str');
      failed = true;
    }
    if(!deepCompare(strings.rgbU8[i], u8s.rgbU8[i]) || !deepCompare(strings.rgbU8[i], u32s.rgbU8[i])) {
      console.log('Fail u8');
      failed = true;
    }
  }

  if(!failed) {
    console.log('No failure detected');
  }
}
