import { getImageExtents } from 'src/data/image';
import { getBedWidth } from 'src/data/time-needle/options';
import { trim } from 'src/data/time-needle/region';
import { insertColumns } from 'src/data/time-needle/time-needle-image';
import { useRawMacro } from 'src/hooks';
import ContextMenuItem from './menu-item';

export function ImageMenu() {
  const rawMacro = useRawMacro()
  return (
    <ContextMenuItem
      name="Modify image"
      menu
    >
      <ContextMenuItem
        name="Center content on bed"
        onClick={() => {
          rawMacro((tni) => {
            const [l, b, r, t] = getImageExtents(tni.cdata)
            if(l >= r || b >= t) {
              alert('No content found')
            } else {
              const curWidth = r - l
              const maxWidth = getBedWidth(tni.type)
              if(curWidth >= maxWidth) {
                alert(`The content is too wide: max is ${maxWidth}`)
              } else if(curWidth === maxWidth) {
                return trim(tni)
              }
              const leftMargin = Math.floor((maxWidth - curWidth) / 2)
              // compare to existing margin
              if(leftMargin <= l) {
                alert('Trim the content first')
              } else {
                // pad the left side
                return insertColumns(tni, 0, leftMargin - l)
              }
            }
          })
        }}
      />
      <ContextMenuItem
        name="Trim empty borders"
        onClick={() => {
          rawMacro((tni) => trim(tni))
        }}
      />
    </ContextMenuItem>
  )
}
